import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AWSOperationSelector from "../../aws-operation-selector";
import { TextInput, Accordion, AccordionSection } from "@components/ui";

import { InputGroup } from ".";
import { ActionNodeOption } from "../../input-selector/option-actions/from-previous-step";
import SnippetHeader from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/SnippetHeader/SnippetHeader";

function WaitForResourceInput(props) {
  const { activeNode, runbookNode, notifyRunbookUpdate } = props;

  // eslint-disable-next-line no-unused-vars
  const [operationDetailsReady, setOperationDetailsReady] = useState(false);

  // can we remove this? it is getting set but not read. Smells like a bug.
  // eslint-disable-next-line no-unused-vars
  const [optionFilterId, setOptionFilterId] = useState("none");

  const [resultJSONPath, setResultJSONPath] = useState(
    runbookNode.result_jsonpath,
  );
  const [desiredState, setDesiredState] = useState(runbookNode.desired_state);
  const [count, setCount] = useState(runbookNode.count);
  const [waitSeconds, setWaitSeconds] = useState(runbookNode.wait_seconds);

  const inputs = runbookNode.parameterInputs || [];
  const requiredInputs = inputs.filter(input => input.required);
  const optionalInputs = inputs.filter(input => !input.required);

  useEffect(() => {
    if (resultJSONPath !== runbookNode.result_jsonpath) {
      runbookNode.result_jsonpath = resultJSONPath;
      notifyRunbookUpdate(true);
    }
  }, [resultJSONPath, notifyRunbookUpdate, runbookNode.result_jsonpath]);

  useEffect(() => {
    if (desiredState !== runbookNode.desired_state) {
      runbookNode.desired_state = desiredState;
      notifyRunbookUpdate(true);
    }
  }, [desiredState, notifyRunbookUpdate, runbookNode.desired_state]);

  useEffect(() => {
    if (count !== runbookNode.count) {
      runbookNode.count = count;
      notifyRunbookUpdate(true);
    }
  }, [count, notifyRunbookUpdate, runbookNode.count]);

  useEffect(() => {
    if (waitSeconds !== runbookNode.wait_seconds) {
      runbookNode.wait_seconds = waitSeconds;
      notifyRunbookUpdate(true);
    }
  }, [waitSeconds, notifyRunbookUpdate, runbookNode.wait_seconds]);

  useEffect(() => {
    if (
      props.selectedService &&
      props.selectedService !== runbookNode.service
    ) {
      runbookNode.setService(props.selectedService);
      setOperationDetailsReady(false);
    }
  }, [props.selectedService, notifyRunbookUpdate, runbookNode]);

  useEffect(() => {
    if (
      props.selectedOperation &&
      props.selectedOperation !== runbookNode.operation
    ) {
      runbookNode.setOperation(props.selectedOperation);
      setOperationDetailsReady(false);
    }
  }, [props.selectedOperation, notifyRunbookUpdate, runbookNode]);

  const handleOperationDetailsReady = () => {
    if (props.operationDetails) {
      runbookNode.setOperationDetails(props.operationDetails);
      setOperationDetailsReady(true);
    }
  };
  //   const requiredInputs = inputs && inputs.filter(input => input.required);

  const toggleInputOption = ofId => {
    if (typeof ofId === "object") {
      const obj = ofId.target;
      if (obj.classList.contains("checkmark")) {
        this.timer = setTimeout(() => {
          setOptionFilterId(ofId);
        }, 100);
      } else {
        if (obj.type === "text") {
          return;
        }

        setOptionFilterId("none");
      }
    } else {
      setOptionFilterId(ofId);
    }
  };

  const onChangeJSONPath = () => {
    console.log(`I don't think this is ever called`);
  };

  return (
    <React.Fragment>
      <SnippetHeader snippetDef={runbookNode.snippetDef} />
      <div
        className="editor-detail-panel editor-detail-panel-column"
        style={{ marginBottom: "0px" }}
      >
        <div className="editor-terraform-container">
          <AWSOperationSelector
            runbookNode={runbookNode}
            handleOperationDetailsReady={handleOperationDetailsReady}
            notifyRunbookUpdate={notifyRunbookUpdate}
          />
        </div>
      </div>
      <Accordion isExpanded={true} useArrow={true}>
        <AccordionSection>
          <div className="editor-detail-panel editor-detail-panel-column">
            <div className="editor-terraform-container">
              <div
                className="mt-20-px pb-20-px"
                style={{
                  borderTop: "1px solid #303030",
                  width: "calc(100% - 8px)",
                }}
              ></div>
              <div className="label" style={{ color: "#f3f3f3" }}>
                Value To Test:
              </div>
              <ActionNodeOption
                updateHandler={setResultJSONPath}
                step={activeNode}
                name="Value to check"
                runbookObj={runbookNode.runbook}
                onChangeCallback={onChangeJSONPath}
                notifyRunbookUpdate={notifyRunbookUpdate}
                selector={resultJSONPath}
                onlyCaptureSelector={true}
              />
              <div
                className="mt-20-px pb-20-px"
                style={{
                  borderTop: "1px solid #303030",
                  width: "calc(100% - 8px)",
                }}
              ></div>
              <TextInput
                name="desired_state"
                id="desired_state"
                label="Desired value"
                labelClassName="label"
                labelPosition="top"
                className="rule-input"
                value={desiredState}
                onBlur={props.onBlur}
                onChange={newValue => {
                  console.log(`onChange(${newValue})`);
                  setDesiredState(newValue);
                }}
                onClick={event => {
                  console.log(`onClick`);
                }}
              />
              <div
                className="mt-20-px pb-20-px"
                style={{
                  borderTop: "1px solid #303030",
                  width: "calc(100% - 8px)",
                }}
              ></div>

              <div className="label mb-20-px" style={{ color: "#f3f3f3" }}>
                Waiter Loop Parameters:
              </div>
              <TextInput
                name="count"
                id="count"
                label="Number of iterations"
                labelClassName="label"
                labelPosition="top"
                className="rule-input"
                value={count}
                style={{ width: "100%" }}
                onBlur={props.onBlur}
                onChange={setCount}
                onClick={event => {
                  console.log(`onClick`);
                }}
              />
              <div className="mb-20-px"></div>

              <TextInput
                name="wait_seconds"
                id="wait_seconds"
                label="Wait seconds"
                labelClassName="label"
                labelPosition="top"
                className="rule-input"
                value={waitSeconds}
                style={{ width: "100%" }}
                onBlur={props.onBlur}
                onChange={setWaitSeconds}
                onClick={event => {
                  console.log(`onClick`);
                }}
              />
            </div>
          </div>
        </AccordionSection>

        <InputGroup
          groupName="Required Inputs"
          inputs={requiredInputs}
          optionFilterId={props.optionFilterId}
          toggleInputOption={toggleInputOption}
          selectedAwsOperation={props.selectedOperation}
        />
        <div className="pb-10-px"></div>
        <InputGroup
          groupName="Optional Inputs"
          inputs={optionalInputs}
          optionFilterId={props.optionFilterId}
          toggleInputOption={toggleInputOption}
          selectedAwsOperation={props.selectedOperation}
        />
      </Accordion>
    </React.Fragment>
  );
}

const mapState = ({ actionNodeReducer }) => {
  return {
    operations: actionNodeReducer.operations,
    services: actionNodeReducer.services,
    selectedService: actionNodeReducer.selectedService,
    selectedOperation: actionNodeReducer.selectedOperation,
    operationDetails: actionNodeReducer.operationDetails,
  };
};

export default connect(mapState)(WaitForResourceInput);
