import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { Output, Details, LoopForEachInput } from ".";
import { hideGlobalLoader } from "@redux/actions/globalLoader.actions";

function LoopForEachPanel(props) {
  const { activeNode } = props;
  const runbookEditorContext = useContext(RunbookEditorContext);
  const { notifyRunbookUpdate } = runbookEditorContext;

  useEffect(() => {
    if (!props.loaderFlag) {
      props.hideGlobalLoader();
    }
  }, [props]);

  return (
    <TabCollection
      active="input"
      activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
      inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
      contentTop={50}
      tabs={["input", "output", "details"]}
    >
      <Tab title="Input" name="input">
        <TabContent>
          <LoopForEachInput
            activeNode={activeNode}
            runbookNode={activeNode.extras.runbookNode}
            notifyRunbookUpdate={notifyRunbookUpdate}
          />
        </TabContent>
      </Tab>
      <Tab title="Output" name="output">
        <TabContent>
          <Output />
        </TabContent>
      </Tab>
      <Tab title="Details" name="details">
        <TabContent>
          <Details />
        </TabContent>
      </Tab>
    </TabCollection>
  );
}

const mapState = ({ actionNodeReducer, globalLoaderReducer }) => ({
  operations: actionNodeReducer.operations,
  loaderFlag: globalLoaderReducer.loaderFlag,
});

const mapDispatch = dispatch => ({
  hideGlobalLoader: () => dispatch(hideGlobalLoader()),
});

export default connect(mapState, mapDispatch)(LoopForEachPanel);
