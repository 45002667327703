import React, { useContext, useEffect } from "react";
import {
  ActionNodePanel,
  ConditionalPanel,
  DatadogConnectorPanel,
  JiraExistingIssuePanel,
  LoopForEachPanel,
  SlackConnectorPanel,
  SnippetNoneSelected,
  SnippetPanel,
  TerraformUpdateVarsConnectorPanel,
  WaitTimePanel,
  JSONPathPanel,
} from "./snippet-tabs";
import {
  inputType,
  resizeRightPanel,
} from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.helpers";

import { RunbookEditorContext } from "../../runbook-editor-lib/runbook-editor.context";
import { ControlNames } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/strings";
import InstanaConnectorPanel from "./snippet-tabs/connector-panel/Instana/instana-connector-panel";
import { Switch } from "@components/ui";
import UnconfiguredMessage from "./snippet-tabs/unconfigured-message/unconfigured-message";
import WaitForResourcePanel from "./snippet-tabs/wait-for-resource-panel/wait-for-resource-panel";
import Webhook from "./snippet-tabs/connector-panel/Webhook";
import { isCurrentNodeConfigured } from "@lib/utils";
import ApprovalNode from "./snippet-tabs/ApprovalNodePanel/ApprovalNode";
import StringTransformers from "./snippet-tabs/connector-panel/StringTransformers/StringTransformers";
import TriggerPanel from "./snippet-tabs/TriggerPanel/TriggerPanel";

const UNCONFIGURED_NODE = "UnconfiguredNode";

const EditorRightPanel = props => {
  const runbookEditorContext = useContext(RunbookEditorContext);
  const { activeNode, notifyRunbookUpdate } = runbookEditorContext;

  const isNewSnippetDef =
    activeNode?.extras?.runbookNode?.snippetDef?.version === "2.0.0";
  useEffect(() => {
    resizeRightPanel();
  }, []);

  const getType = activeNode => {
    return isCurrentNodeConfigured(
      activeNode?.extras?.runbookNode?.name?.toLowerCase(),
      props.unconfiguredNodes,
    )
      ? inputType(activeNode)
      : UNCONFIGURED_NODE;
  };

  return (
    <div
      className={`editor-detail-wrap ${
        !isNewSnippetDef ? "scroll-y-auto" : ""
      }`}
      data-tut="reactour__rightPanel"
    >
      <div className="editor-right-panel-resize" />
      <Switch value={getType(activeNode)}>
        <SnippetPanel
          inputOptions={true}
          activeNode={activeNode}
          caseValue="SnippetAction"
        />
        <ConditionalPanel
          activeNode={activeNode}
          rerenderEditor={props.rerenderEditor}
          addLinkToStep={props.addLinkToStep}
          removeLinkToStep={props.removeLinkToStep}
          caseValue="ConditionalAction"
        />
        <SnippetPanel activeNode={activeNode} caseValue="SSMStepAction" />
        <LoopForEachPanel activeNode={activeNode} caseValue="LoopForEach" />
        <WaitForResourcePanel
          activeNode={activeNode}
          caseValue="WaitForResource"
        />
        <ActionNodePanel
          activeNode={activeNode}
          caseValue="ActionNode"
          rerenderEditor={props.rerenderEditor}
        />
        <DatadogConnectorPanel
          activeNode={activeNode}
          caseValue={ControlNames.DatadogAlert}
        />
        <InstanaConnectorPanel
          caseValue="Instana_Alert"
          activeNode={activeNode}
        />

        <TerraformUpdateVarsConnectorPanel
          activeNode={activeNode}
          caseValue="TerraformUpdateVarsNode"
        />
        <SlackConnectorPanel
          activeNode={activeNode}
          caseValue="Slack_Send_Message"
        />

        <JiraExistingIssuePanel
          activeNode={activeNode}
          fetchJiraDetails={props.fetchJiraDetails}
          notifyRunbookUpdate={notifyRunbookUpdate}
          caseValue="Jira_Existing_Issue"
        />
        <SnippetNoneSelected inputOptions={false} caseValue="default" />
        <WaitTimePanel
          notifyRunbookUpdate={notifyRunbookUpdate}
          caseValue="WaitTimeNode"
        />

        <JSONPathPanel
          activeNode={activeNode}
          caseValue={ControlNames.JSONPathStep}
          notifyRunbookUpdate={notifyRunbookUpdate}
        />
        <UnconfiguredMessage caseValue={UNCONFIGURED_NODE} />
        <Webhook caseValue="Webhook" />
        <ApprovalNode
          caseValue={ControlNames.Approval}
          activeNode={activeNode}
        />
        <StringTransformers
          caseValue={ControlNames.StringTransformers}
          activeNode={activeNode}
        />
        <TriggerPanel
          activeNode={activeNode}
          caseValue={ControlNames.Trigger}
        />
      </Switch>
    </div>
  );
};

export default EditorRightPanel;
