import { ParameterSet } from "../ssm/parameters";
import { Step } from "../ssm/steps";
import { InvokeLambdaStep } from "../ssm/invokelambdastep";
import { SnippetStep } from "../neuropssteps/snippetstep";
import { SnippetStepNew } from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/snippetstep.js";
import { SSMDocument } from "../ssm/ssmdocument";
import { SSMActionNames } from "../ssm/strings";
import { StepTypeChecker } from "../neuropssteps/steptypechecker";
import { BranchStep } from "../ssm/branchstep";
import {
  ConditionalStep,
  NeurOpsCustomConditionalStep,
} from "../neuropssteps/conditionalstep";
import { NeurOpsRunbook } from "./runbook";
import { WaitStep, NeurOpsStatusStep } from "../neuropssteps/waitstep";
import { SleepStep } from "../ssm/sleepstep";
import { isEmpty } from "@lib/utils";
import { ActionNodeStep } from "../neuropssteps/actionnodestep";
import { PauseStep } from "../ssm/pausestep";
import { DatadogConnectorStep } from "../neuropssteps/DatadogConnectorStep";
import SlackConnectorStep from "../neuropssteps/slackConnectorStep";
import JiraConnectorStep from "./../neuropssteps/jiraConnectorStep";
import { LoopStep } from "../neuropssteps/loopstep";
import { WaitForResourceStep } from "../neuropssteps/waitforresourcestep";
import InstanaStep from "../neuropssteps/InstanaStep";
import JSONPathStep from "../neuropssteps/JSONPathStep";
import ApprovalNodeStep from "../neuropssteps/ApprovalNodeStep";
import TriggerStep from "../neuropssteps/TriggerStep";
import { getParsedPayload } from "@containers/RunbookEditor/runbook-editor-lib/ssm/util.js";
import { ControlNames } from "../neuropssteps/strings";
// SSM Document reader

export class RunbookReader {
  constructor(snippetLibrary) {
    this.snippetLibrary = snippetLibrary;
    this.typeChecker = new StepTypeChecker(snippetLibrary);
  }

  readAPIResponse(apiResponse) {
    const body =
      typeof apiResponse.Content === "string"
        ? JSON.parse(apiResponse.Content)
        : apiResponse.Content;
    const ssmdoc = this.readDocument(body, apiResponse.Tags);
    return new NeurOpsRunbook(
      ssmdoc,
      apiResponse.Name,
      apiResponse.Tags,
      apiResponse.DocumentVersion,
    );
  }

  readDocument(ssm, tags) {
    if (isEmpty(ssm)) {
      return;
    }
    const { description } = ssm;
    const parameters = new ParameterSet(ssm.parameters);
    const mainSteps = ssm.mainSteps
      .map(step => this.readStep(step, tags))
      .filter(step => !!step);
    return new SSMDocument(description, parameters, mainSteps);
  }

  readStep(stepJSON) {
    StepTypeChecker.assertIsSSMStep(stepJSON);

    if (this.lastLoopStep && StepTypeChecker.isLoopSupportStep(stepJSON)) {
      addSupportStepToLoop(this.lastLoopStep, stepJSON);
      return null;
    } else {
      this.lastLoopStep = undefined;
    }

    if (StepTypeChecker.isApprovalNodeSupportStep(stepJSON)) {
      if (/^Pause_Approval/.test(stepJSON.name)) {
        _addTimeoutToApprovalNode(this.lastApprovalStep, stepJSON);
      }
      return null;
    }
    switch (stepJSON.action) {
      case SSMActionNames.INVOKE_LAMBDA:
        let stepName = stepJSON.name;
        let splitName = stepName.split("_");
        try {
          let type = typeof JSON.parse(splitName[splitName.length - 1]);
          if (type === "number") stepName = splitName.slice(0, -1).join("_");
        } catch (e) {
          console.log(e);
        }

        const isNewSnippet =
          !isEmpty(this.snippetLibrary.snippets) &&
          this.snippetLibrary.snippets.hasOwnProperty(stepName) &&
          this.snippetLibrary.snippets[stepName].version === "2.0.0";
        if (this.typeChecker.isSnippetStep(stepJSON)) {
          if (isNewSnippet) {
            /**
             * Handle case for Unified Trigger Node:
             * Create a selectedTrigger variable to be passed
             * to SnippetStepNew
             */
            const parsedPayload = getParsedPayload(stepJSON.inputs.Payload);
            let selectedTrigger = null;
            if (
              parsedPayload.hasOwnProperty("display_name") &&
              parsedPayload.hasOwnProperty("integration")
            ) {
              let lastUnderscore = stepName.lastIndexOf("_");
              let snippetName = stepName.substring(0, lastUnderscore);
              selectedTrigger = {
                name: parsedPayload["integration"],
                display_name: parsedPayload["display_name"],
                snippet_name: snippetName,
              };
              /**************/
            }
            return new SnippetStepNew(
              stepJSON,
              this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
              selectedTrigger,
            );
          }
          // Special Cases
          if (stepName === ControlNames.SlackConnector) {
            return new SlackConnectorStep(
              stepJSON,
              this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
            );
          }

          if (stepName === ControlNames.DatadogAlert) {
            return new DatadogConnectorStep(
              stepJSON,
              this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
            );
          }
          if (stepName === ControlNames.JiraUpdateConnector) {
            return new JiraConnectorStep(
              stepJSON,
              this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
            );
          }
          if (stepName === ControlNames.Webhook) {
            return new TriggerStep(
              stepJSON,
              this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
            );
          }
          // Default Case
          return new SnippetStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        } else if (StepTypeChecker.isActionNodeStep(stepJSON)) {
          return new ActionNodeStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForActionNode(),
          );
        } else if (StepTypeChecker.isLoopStep(stepJSON)) {
          const loopStep = new LoopStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForLoop(),
          );
          this.lastLoopStep = loopStep;
          return loopStep;
        } else if (StepTypeChecker.isInstanaAlertStep(stepJSON)) {
          return new InstanaStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        } else if (StepTypeChecker.isJSONPathStep(stepJSON)) {
          return new JSONPathStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        } else if (StepTypeChecker.isApprovalNodeStep(stepJSON)) {
          const approvalStep = new ApprovalNodeStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForApprovalNode(),
          );
          this.lastApprovalStep = approvalStep;
          return approvalStep;
        }

        return new InvokeLambdaStep(stepJSON);
      case SSMActionNames.BRANCH:
        if (this.typeChecker.isConditionalStep(stepJSON)) {
          if (this.typeChecker.isNeurOpsCustomConditionalStep(stepJSON)) {
            return new NeurOpsCustomConditionalStep(
              stepJSON,
              this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
            );
          }
          return new ConditionalStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        }
        return new BranchStep(stepJSON);
      case SSMActionNames.SLEEP:
        if (this.typeChecker.isNeurOpsStatusStep(stepJSON)) {
          return new NeurOpsStatusStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        }
        if (this.typeChecker.isWaitStep(stepJSON)) {
          return new WaitStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        }

        /**
         * Handle case for Manual Trigger Node
         */
        let selectedTrigger = null;

        if (stepJSON.name.startsWith(ControlNames.Manual)) {
          selectedTrigger = {
            name: "manual",
            display_name: "Manual Trigger",
            snippet_name: ControlNames.Manual,
          };
        }

        let lastUnderscore = stepJSON.name.lastIndexOf("_");
        let snippetName = stepJSON.name.substring(0, lastUnderscore);
        return new SleepStep(
          stepJSON,
          this.snippetLibrary.snippets.hasOwnProperty(snippetName) &&
            this.snippetLibrary.snippets[snippetName],
          selectedTrigger,
        );
      case SSMActionNames.PAUSE:
        return new PauseStep(
          stepJSON,
          this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
        );
      case SSMActionNames.EXECUTE_STATE_MACHINE:
        if (StepTypeChecker.isWaitForResourceStep(stepJSON)) {
          return new WaitForResourceStep(
            stepJSON,
            this.snippetLibrary.waitForResourceStepSnippetDefinition(),
          );
        } else {
          return new Step(stepJSON);
        }
      case SSMActionNames.EXECUTE_AWS_API:
      default:
        return new Step(stepJSON);
    }
  }
}

function _addTimeoutToApprovalNode(lastApprovalStep, stepJSON) {
  lastApprovalStep.setTimeoutForPauseNode(stepJSON.timeoutSeconds);
  let timeoutUnit = stepJSON.inputs.Message
    ? JSON.parse(stepJSON.inputs.Message).timeout_unit
    : "Seconds";
  lastApprovalStep.setTimeoutUnitForPauseNode(timeoutUnit);
  lastApprovalStep.nextStep = stepJSON.nextStep;
}

function addSupportStepToLoop(loopStep, supportStep) {
  // precondition - loop support steps immediately follow
  // the loop step
  if (/^([\w-]*)LoopPost[Pp]rocess*$/.test(supportStep.name)) {
    loopStep.nextStep = supportStep.nextStep;
  }
}
