import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { fetchAccountPlanInfo } from "@redux/actions/accountPlan.actions";

type AccountPlanProps = {};

const AccountPlan: React.FC<AccountPlanProps> = props => {
  const dispatch = useDispatch();
  const quotas = useSelector(
    (state: RootStateOrAny) => state.accountPlanReducer.quotas,
  );

  useEffect(() => {
    dispatch(fetchAccountPlanInfo());
  }, [dispatch]);

  const capitalize = s => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const contactSales = () => {
    window.open("https://www.fylamynt.com/contact", "_blank");
  };

  const viewPlanOptions = () => {
    window.open("https://www.fylamynt.com/pricing", "_blank");
  };

  const workflowsWidth =
    (quotas?.workflows?.current_usage / quotas?.workflows?.limit) * 100;
  const executionsWidth =
    (quotas?.executions?.current_usage / quotas?.executions?.limit) * 100;

  return (
    <div className="d-flex">
      <div className="account-details">
        <div className="account-details-plan-header">
          <h2>Plan Details</h2>
        </div>
        <div className="account-details-usage">
          <div className="account-details-usage-plan-meta">
            <div className="meta-title">Plan</div>
            <h4>
              {quotas?.current_tier ? capitalize(quotas?.current_tier) : "NA"}
            </h4>
          </div>
          <div className="account-details-usage-plan-meta">
            <div className="meta-title">Workflows</div>
            <h4>{quotas?.workflows?.limit || "NA"}</h4>
          </div>
          <div className="account-details-usage-plan-meta">
            <div className="meta-title">Runs/mo</div>
            <h4>{quotas?.executions?.limit || "NA"}</h4>
          </div>
          {/* <div className="account-details-usage-plan-meta">
            <div className="meta-title">Cost</div>
            <h4>{`$0/month`}</h4>
          </div> */}
        </div>
        <div className="account-details-plan-usage">
          <div className="meta-title__bold">Plan Usage</div>
          <div className="meta-title__fw">{`Usage for Runs will reset at the beginning of each month.`}</div>
          <div className="account-details-plan-usage__usage-wrap">
            <div className="account-details-plan-usage__usage-wrap__top">
              <h4>Workflows</h4>
              <h4>
                {quotas?.workflows?.current_usage} / {quotas?.workflows?.limit}
              </h4>
            </div>
            <div className="account-details-plan-usage__bar-wrap">
              <div
                className={`account-details-plan-usage__bar-wrap__bar-use ${
                  workflowsWidth === 100 && "red"
                }`}
                style={{
                  width: `${workflowsWidth}%`,
                }}
              ></div>
            </div>
          </div>
          <div className="account-details-plan-usage__usage-wrap">
            <div className="account-details-plan-usage__usage-wrap__top">
              <h4>Runs</h4>
              <h4>
                {quotas?.executions?.current_usage} /{" "}
                {quotas?.executions?.limit}
              </h4>
            </div>
            <div className="account-details-plan-usage__bar-wrap">
              <div
                className={`account-details-plan-usage__bar-wrap__bar-use ${
                  executionsWidth === 100 && "red"
                }`}
                style={{
                  width: `${executionsWidth}%`,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="account-details-usage account-details-usage__margin-top">
          <div className="account-details-usage-plan-benefits">
            <div className="meta-title">Plan Benefits:</div>
            <div className="account-details-usage-plan-benefits__plan-checklist">
              <div className="account-details-usage-plan-benefits__plan-checklist__checkmark"></div>
              <div>{`${quotas?.workflows?.limit || "NA"} Workflows`}</div>
            </div>
            <div className="account-details-usage-plan-benefits__plan-checklist">
              <div className="account-details-usage-plan-benefits__plan-checklist__checkmark"></div>
              <div>{`${quotas?.executions?.limit || "NA"} Runs per month`}</div>
            </div>
            <div className="account-details-usage-plan-benefits__plan-checklist">
              <div className="account-details-usage-plan-benefits__plan-checklist__checkmark"></div>
              <div>{`${
                quotas?.current_tier === "enterprise"
                  ? "Enterprise Support"
                  : "Basic Support"
              }`}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="account-actions">
        <div className="account-details-plan-header">
          <h2 className="pb-30-px">Change Plan</h2>
          <div className="meta-title__fw">
            To upgrade or downgrade your current plan:
          </div>
        </div>
        <button
          className="account-actions-btn account-actions-btn__primary"
          onClick={contactSales}
          type="button"
        >
          Contact Sales
        </button>
        <button
          type="button"
          className="account-actions-btn"
          onClick={viewPlanOptions}
        >
          View Plan Options
        </button>
      </div>
    </div>
  );
};

export default AccountPlan;
