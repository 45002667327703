import { hasKeys } from "@lib/utils";
import { StepTypeChecker } from "./neuropssteps/steptypechecker";
import { ParameterType } from "./ssm/strings";
import difference from "lodash/difference";

export const isRequired = node => {
  if (!node) {
    return false;
  }
  if (hasKeys(node, "extras.runbookNode.snippetDef.required_inputs")) {
    return true;
  }
  return false;
};

export const inputType = node => {
  return StepTypeChecker.getType(node);
};

export const getInputOptions = node => ({
  required: _getRequiredInputs(node),
  optional: _getOptionalInputs(node),
});

const _getOptionalInputs = node => {
  if (hasKeys(node, "extras.runbookNode.parameterInputs")) {
    const nodes = node.extras.runbookNode.parameterInputs.filter(
      pi => !pi.required,
    );
    return nodes;
  } else {
    return [];
  }
};

const _getRequiredInputs = node => {
  if (hasKeys(node, "extras.runbookNode.parameterInputs")) {
    return node.extras.runbookNode.parameterInputs.filter(pi => pi.required);
  } else {
    return [];
  }
};

export function convertPayloadSpecToJSON(payload) {
  if (!payload) {
    return;
  }
  const replaced =
    payload &&
    payload.replace(/("\w+":\s*)({{\s*[\w.]+\s*}})(\s*,?)/g, '$1"$2"$3');
  return JSON.parse(replaced);
}

export const getSelectedOption = input => {
  return (input && input.source && input.source.type) || "userProvided";
};

function typesCanBeConverted(sourceType, targetType) {
  return (
    sourceType === targetType ||
    (sourceType === ParameterType.String &&
      targetType === ParameterType.StringList)
  );
}

export function previousStepOptions(runbookNode, type) {
  const predecessorOutputs = runbookNode
    .predecessors()
    .flatMap(step => step.outputs)
    .filter(output => !!output);
  return type
    ? predecessorOutputs.filter(
        out => out && typesCanBeConverted(out.type, type),
      )
    : predecessorOutputs;
}

let startX;
let startWidth;

export function resizeRightPanel() {
  const div = document.querySelector(".editor-right-panel-resize");
  const parentDiv = document.querySelector(".editor-detail-wrap");
  const parentRect = parentDiv.getBoundingClientRect();
  parentDiv.style.width = parentRect.width + "px";
  const editorToolbarDiv = document.querySelector(".editor-toolbar-wrap");

  div.onmousedown = e => {
    init(e);
  };
  window.onmouseup = () => {
    window.removeEventListener("mousemove", mouseMove, false);
    makeSelectable(parentDiv, true);
  };

  const init = e => {
    startX = e.clientX;
    startWidth = parseInt(parentDiv.style.width, 10);
    // window.addEventListener("mousemove", mouseMove, false);
    // makeSelectable(parentDiv, false);
  };

  const mouseMove = e => {
    // p.style.width = (startWidth + e.clientX - startX) + 'px';
    parentDiv.style.width = startWidth - e.clientX + startX + "px";
    editorToolbarDiv.style.paddingRight =
      startWidth - e.clientX + startX + "px";
  };
}

function makeSelectable(node, unselectable) {
  const _selectable = unselectable ? "off" : "on";
  if (node.nodeType === 1) {
    node.setAttribute("unselectable", _selectable);
    if (unselectable) {
      node.classList.remove("unselectable");
    } else {
      node.classList.add("unselectable");
    }
  }
  var child = node.firstChild;
  while (child) {
    makeSelectable(child, unselectable);
    child = child.nextSibling;
  }
}

export function updateLinks(
  activeNode,
  runbookStep,
  addLinkToStep,
  removeLinkToStep,
  rfStore,
) {
  const { nodes } = rfStore?.getState();

  if (!nodes.length) return;

  const linkIds = [
    ...new Set(
      nodes.find(item => item.id === activeNode.extras.editorNodeId)?.outPort,
    ),
  ];

  const linkTargetIds = linkIds.map(
    item => nodes.find(node => node.id === item)?.data?.uniqueId,
  );

  const newTargets = runbookStep.nextSteps();

  const addLinks = difference(newTargets, linkTargetIds);
  const removeLinks = difference(linkTargetIds, newTargets);

  for (let target of removeLinks) {
    removeLinkToStep(activeNode?.extras?.runbookNode?.name, target);
  }

  for (let targetNodeName of addLinks) {
    addLinkToStep(activeNode?.extras?.runbookNode?.name, targetNodeName);
  }
}

// force the runbook to have default values for runtime-params
export const shouldForceDefaultValues = (runbookObj, force = false) => {
  const found = (runbookObj?.mainSteps || []).find(step => {
    if (!step) return false;

    /**
     * Prompt user to set default values in case
     * Trigger node is included in the WF
     */
    if (step?.snippetDef?.type === "TRIGGER") {
      return true;
    }
    return false;
  });
  if (found || force) {
    const ssm = runbookObj.toSSM();
    let missingDefault = false;
    Object.values(ssm.parameters).forEach(param => {
      if (param.description.includes("(Required") && !param.default) {
        missingDefault = true;
      }
    });
    return missingDefault;
  }
  return false;
};
