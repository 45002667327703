import {
  HIDE_LOADING,
  SHOW_LOADING,
  FETCH_ERROR,
  FETCH_APP_CONFIG_SUCCESS,
} from "@redux/types";

const INITIAL_STATE = {
  show: false,
  snippets: null,
  fetchError: null,
  appConfig: {},
  appInfo: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case HIDE_LOADING:
      return {
        ...state,
        show: false,
      };
    case SHOW_LOADING:
      return {
        ...state,
        show: true,
      };
    case FETCH_ERROR:
      return {
        ...state,
        fetchError: action.payload,
      };
    case FETCH_APP_CONFIG_SUCCESS:
      return {
        ...state,
        appConfig: action.payload,
      };

    default:
      return state;
  }
}
