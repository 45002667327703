import { hasKeys } from "@lib/utils";

/* These methods are specifically used by ModalSelectOutput.js */

/* runbookNode should look like this: runbookNode.ssm.inputs.Payload */
export function getServiceOps(runbookNode) {
  if (!runbookNode) {
    return;
  }

  const { service, operation } = runbookNode;
  return { service, operation };
}

export function updateOutput(key, value) {
  let newOutputItem = key.replace(/\[(.?)\]/, `[${value}]`);
  let reg = new RegExp("\\[(.?)\\]");
  const keyWithoutArray = key.replace(reg, "");
  let replaceItem = new RegExp(keyWithoutArray + "\\[(.?)\\]");
  this.outputString = this.outputString.replace(replaceItem, newOutputItem);
}

export function outputToArray(value) {
  const arr = value.split(".");
  arr.splice(0, 2);
  return arr;
}

export function _isArray(item) {
  const reg = new RegExp(/\[(.*)\]/);
  return reg.test(item);
}

export async function fetchOutputsAndSetComponent(service, operation) {
  const serviceOpsKey = `${service}-${operation}`;
  const { outputs, fetchAwsOutputs } = this.props;
  if (!outputs || !outputs.hasOwnProperty(serviceOpsKey)) {
    await this.setLoading(true);
    this.setComponent("Loading");
    fetchAwsOutputs(service, operation);
  } else {
    this.setOutputs(outputs[serviceOpsKey]);
    await this.setLoading(false);
    return;
  }
}

export function getSourceStep(step) {
  let defaultObject = {
    selector: "",
    type: "",
    name: "",
    sourceStep: {
      name: "",
      stepType: "",
    },
  };
  if (hasKeys(step, "source.sourceValue.sourceStep.name")) {
    let returnObject = { ...defaultObject, ...step.source.sourceValue };
    if (returnObject.sourceStep.stepType === "ActionNodeStep") {
      returnObject.sourceStep.stepType = "ActionNode";
    }
    return returnObject;
  } else {
    return defaultObject;
  }
}

export function getSourceStepName(step) {
  if (hasKeys(step, "source.sourceValue.sourceStep.name")) {
    return step.source.sourceValue.sourceStep.name;
  }
}

export function getSourceInfo(runbook) {}
