import React from "react";
import { connect } from "react-redux";
import { Consumer } from "../../../../runbook-editor-lib/runbook-editor.context";
import EditorVersionItem from "./EditorVersionItem.js";
import { draftStatus } from "@lib/utils/constants";
import { showGlobalLoader } from "@redux/actions/globalLoader.actions";

type EditorVersionsState = {
  numberChosen: string | null;
};

type EditorVersionsProps = {
  loaderFlag: boolean;
  showGlobalLoader: () => void;
};

class EditorVersions extends React.Component<
  EditorVersionsProps,
  EditorVersionsState
> {
  state = {
    numberChosen: null,
  };

  toggle = (event, numberChosen, documentVersion, callback, setActivePanel) => {
    if (
      !event.target.classList.contains("editor-accordion-row__dropdown-icon")
    ) {
      this.closeSubMenu();
      setActivePanel();

      if (documentVersion && documentVersion !== draftStatus) {
        callback(documentVersion);
      }
    }
    if (numberChosen === this.state.numberChosen) {
      this.closeSubMenu();
    } else {
      this.setState({
        numberChosen,
      });
    }
  };

  closeSubMenu = () => {
    this.setState({
      numberChosen: null,
    });
  };

  render() {
    return (
      <Consumer>
        {({
          versions,
          loadVersion,
          setActivePanel,
          activeRunbookVersion,
          deleteRunbookVersion,
          runbook,
          runbookVersionDeleteStatus,
          updateDefaultVersion,
          isVersionUpdateSuccess,
          isVersionUpdateFailed,
          isFetchingVersion,
          runbookSchedule,
          unsetNode,
        }) => (
          <div className="editor-slideout-content-container-area">
            {versions &&
              versions.map((version, i) => {
                const className =
                  this.state.numberChosen === i
                    ? "editor-slideout-content-container-area-version-dropdown__open"
                    : "editor-slideout-content-container-area-version-dropdown__closed";
                return (
                  <EditorVersionItem
                    key={version.DocumentVersion}
                    version={version}
                    name={runbook.Name}
                    defaultVersion={runbook.DefaultVersion}
                    className={className}
                    activeRunbookVersion={activeRunbookVersion}
                    active={activeRunbookVersion === version.DocumentVersion}
                    onClick={(event, newVersionToLoad) => {
                      event.stopPropagation();
                      unsetNode();
                      // Reset loader flag
                      if (this.props.loaderFlag) {
                        this.props.showGlobalLoader();
                      }
                      this.toggle(
                        event,
                        i,
                        newVersionToLoad === undefined
                          ? version.DocumentVersion
                          : newVersionToLoad,
                        loadVersion,
                        setActivePanel,
                      );
                    }}
                    onClose={this.closeSubMenu}
                    deleteRunbookVersion={deleteRunbookVersion}
                    runbookVersionDeleteStatus={runbookVersionDeleteStatus}
                    updateDefaultVersion={updateDefaultVersion}
                    isVersionUpdateSuccess={isVersionUpdateSuccess}
                    isVersionUpdateFailed={isVersionUpdateFailed}
                    isFetchingVersion={isFetchingVersion}
                    runbookSchedule={runbookSchedule}
                  />
                );
              })}
          </div>
        )}
      </Consumer>
    );
  }
}

const mapState = ({ globalLoaderReducer }) => ({
  loaderFlag: globalLoaderReducer.loaderFlag,
});

const mapDispatch = dispatch => ({
  showGlobalLoader: () => dispatch(showGlobalLoader()),
});

export default connect(mapState, mapDispatch)(EditorVersions);
