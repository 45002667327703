import React from "react";
import "./Tour.scss";

const goToPrevStep = (goTo, step, closeTour, toggleWelcomeDialog) => {
  if (step === 1) {
    closeTour();
    toggleWelcomeDialog();
  } else {
    goTo(step - 2);
  }
};
const goToNextStep = (goTo, step, steps, closeTour) => {
  if (step === steps.length) {
    closeTour();
    goTo(0);
  } else goTo(step);
};
export const getTourContainer = (
  goTo,
  step,
  steps,
  closeTour,
  toggleWelcomeDialog,
  title,
  content,
) => (
  <div className="tour-container">
    <div className="tour-container-header">{title}</div>
    <div className="tour-container-content">{content}</div>
    <div className="tour-container-footer">
      <button
        className="tour-container-footer-btn tour-container-footer-btn-prev"
        onClick={() => goToPrevStep(goTo, step, closeTour, toggleWelcomeDialog)}
      >
        Previous
      </button>
      <button
        className="tour-container-footer-btn tour-container-footer-btn-next"
        onClick={() => goToNextStep(goTo, step, steps, closeTour)}
      >
        {`${step === steps.length ? "Finish" : "Next"}`}
      </button>
    </div>
  </div>
);
