import produce from "immer";
import {
  IS_FETCHING_A_SNIPPET,
  FETCHING_A_SNIPPET_SUCCESS,
  FETCHING_A_SNIPPET_ERROR,
  SET_SELECTED_SNIPPET,
} from "@redux/types";

const INITIAL_STATE = {
  snippetDetails: {},
  isFetchingAsnippet: true,
  snippetDetailsError: false,
  selectedSnippet: null,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCHING_A_SNIPPET_SUCCESS:
        draft.snippetDetails = action.payload;
        draft.snippetDetailsError = false;
        break;

      case IS_FETCHING_A_SNIPPET:
        draft.isFetchingAsnippet = action.payload;
        break;

      case FETCHING_A_SNIPPET_ERROR:
        draft.snippetDetails = {};
        draft.snippetDetailsError = action.payload;
        break;

      case SET_SELECTED_SNIPPET:
        draft.snippetDetails = {};
        draft.selectedSnippet = action.payload;
        break;

      default:
        break;
    }
  });
