import React from "react";
import { previousStepOptions } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.helpers";
import {
  getNodeByName,
  getType,
  getSnippetDisplayName,
} from "../../lib/input-selector.lib";
import { getSourceStepName } from "../../lib/output.lib";
import {
  ActionNodeOption,
  ControlNodeOption,
  SnippetActionOption,
  SSMStepAction,
} from "./";
import { connect } from "react-redux";
import { SET_SELECTED_OUTPUT } from "@redux/types";
import { hasKeys } from "@lib/utils";
import { StepTypes } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/strings";
import { ControlNames } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/strings";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import Header from "@components/ui/InputTypes/Layouts/Header/Header";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";

class FromPreviousStep extends React.Component {
  state = {
    stepType: null,
    title: "Choose a step",
    step: null,
    previousStepSourceValue: "",
  };

  componentDidMount() {
    this._init();
  }

  _init = async () => {
    if (!this.props.resetTitle) {
      this._initActionNode();
      this._initSnippetOutputNode();
    }
  };

  _initActionNode = () => {
    const stepName = getSourceStepName(this.props.input);

    if (stepName) {
      this._setPreviousStepInfo(stepName, this.props.runbookObj);
      this.props.updateSelectedOutput(
        this.props.input.source.sourceValue.selector,
      );
      return true;
    }
  };

  _initSnippetOutputNode = () => {
    if (hasKeys(this.props.input, "source.sourceValue.snippetAction.name")) {
      let stepName = this.props.input.source.sourceValue.snippetAction?.name;
      this.setState({
        previousStepSourceValue: this.props.input.source.sourceValue.name,
      });
      this._setPreviousStepInfo(stepName, this.props.runbookObj);
    } else if (
      hasKeys(this.props.input, "source.sourceValue.sourceStep.name")
    ) {
      let stepName = this.props.input.source.sourceValue.sourceStep?.name;
      this.setState({
        previousStepSourceValue: "output",
      });
      this._setPreviousStepInfo(stepName, this.props.runbookObj);
    } else if (this.props.custom_item) {
      this.setState({ title: this.props.custom_item });
    }
  };

  _previousStepOptions(type) {
    const runbookNode = this.props.input.snippetAction;
    return previousStepOptions(runbookNode, type);
  }

  _setPreviousStepInfo = (newValue, runbookObj) => {
    if (newValue === "") {
      this.setState({
        stepType: null,
        title: "Choose a step",
        step: null,
        previousStepSourceValue: "",
      });
      this.props.updateInputControl(null);
      return;
    }

    const node = getNodeByName(newValue, runbookObj);
    const type = getType(node);
    this.setState({
      stepType: type,
      step: node,
      title: newValue,
    });
    if (this.props?.readSelectedStep) {
      this.props.readSelectedStep(node || newValue);
    }
  };

  getOptions = (runbookNode, previousStepNames) => {
    let options = [];

    if (this.props.custom_item) {
      options.push({ name: this.props.custom_item });
    }
    if (runbookNode) {
      options.push(...previousStepNames);
    }
    return options;
  };

  setPreviousStepSourceValue = previousStepSourceValue => {
    this.setState({
      previousStepSourceValue,
    });
  };

  getPreviousStepOutputOptions = (stepType, step, title) => {
    switch (stepType) {
      case "SSMStepAction":
        return <SSMStepAction />;
      case "SnippetControl":
        return <ControlNodeOption />;
      case "ActionNode":
        return (
          <>
            <SnippetActionOption
              step={step}
              consumingInput={this.props.input}
              name={title}
              runbookObj={this.props.runbookObj}
              onChangeCallBack={this.props.onChangeCallBack}
              updateInputControl={this.props.updateInputControl}
              notifyRunbookUpdate={this.props.notifyRunbookUpdate}
              previousStepSourceValue={this.state.previousStepSourceValue}
              setPreviousStepSourceValue={this.setPreviousStepSourceValue}
            />
            {this.state.previousStepSourceValue &&
              this.state.previousStepSourceValue !== "execution_status" &&
              this.props.showOutputPath && (
                <ActionNodeOption
                  step={step}
                  consumingInput={this.props.input}
                  name={title}
                  runbookObj={this.props.runbookObj}
                  onChangeCallBack={this.props.onChangeCallBack}
                  updateInputControl={this.props.updateInputControl}
                  previousStepSourceValue={this.state.previousStepSourceValue}
                  notifyRunbookUpdate={this.props.notifyRunbookUpdate}
                  updateHandler={this.props.onChangeCallBack}
                />
              )}
          </>
        );
      /**
       * Add the snippets whose outputs we want to show in the Previous Step Output dropdown
       */
      case "SnippetStep":
      case "SnippetAction":
      case "TerraformPlanNode":
      case "Instana_Alert":
      case "TerraformUpdateVarsNode":
      case ControlNames.SplunkSearch:
      case ControlNames.DatadogAlert:
      case ControlNames.JSONPathStep:
      case ControlNames.Webhook:
      case ControlNames.PagerDuty:
      case ControlNames.Container:
      case ControlNames.TwilioSendSMS:
      case ControlNames.Opsgenie:
      case ControlNames.PickS3Bucket:
      case ControlNames.CloudFormationRunTemplate:
      case ControlNames.Pulumi:
      case ControlNames.TerraformCLI:
      case ControlNames.SplunkOnCallAlert:
      case ControlNames.HumioAlert:
      case ControlNames.HumioSearch:
      case ControlNames.PrometheusAlert:
      case ControlNames.CloudwatchAlert:
      case ControlNames.EKSActions:
      case ControlNames.Approval:
      case ControlNames.SplunkOnCallResolveIncidents:
      case ControlNames.SplunkOnCallRerouteIncidents:
      case ControlNames.StringTransformers:
      case ControlNames.SlackConnector:
      case "Jira_Existing_Issue":
        return (
          <SnippetActionOption
            step={step}
            consumingInput={this.props.input}
            name={title}
            runbookObj={this.props.runbookObj}
            onChangeCallBack={this.props.onChangeCallBack}
            updateInputControl={this.props.updateInputControl}
            notifyRunbookUpdate={this.props.notifyRunbookUpdate}
            previousStepSourceValue={this.state.previousStepSourceValue}
            setPreviousStepSourceValue={this.setPreviousStepSourceValue}
          />
        );
      case ControlNames.Trigger:
        return (
          <>
            <SnippetActionOption
              step={step}
              consumingInput={this.props.input}
              name={title}
              runbookObj={this.props.runbookObj}
              onChangeCallBack={this.props.onChangeCallBack}
              updateInputControl={this.props.updateInputControl}
              notifyRunbookUpdate={this.props.notifyRunbookUpdate}
              previousStepSourceValue={this.state.previousStepSourceValue}
              setPreviousStepSourceValue={this.setPreviousStepSourceValue}
            />
            {this.state.previousStepSourceValue &&
              this.state.previousStepSourceValue !== "execution_status" &&
              this.state.previousStepSourceValue !== "output_json" &&
              this.props.showOutputPath && (
                <ActionNodeOption
                  step={step}
                  consumingInput={this.props.input}
                  name={title}
                  runbookObj={this.props.runbookObj}
                  onChangeCallBack={this.props.onChangeCallBack}
                  updateInputControl={this.props.updateInputControl}
                  previousStepSourceValue={this.state.previousStepSourceValue}
                  notifyRunbookUpdate={this.props.notifyRunbookUpdate}
                  updateHandler={this.props.onChangeCallBack}
                  setPreviousStepSourceValue={this.setPreviousStepSourceValue}
                />
              )}
          </>
        );
      default:
        return;
    }
  };

  handleOptionHover = (input, isHovered) => {
    this.props.runbookObj.mainSteps.forEach(step => {
      if (
        input.substring(input.lastIndexOf("#") + 1) &&
        step.name.substring(step.name.lastIndexOf("_") + 1) ===
          input.substring(input.lastIndexOf("#") + 1) &&
        isHovered
      ) {
        step.isHovered = true;
      } else {
        step.isHovered = false;
      }
    });
    this.props.runbookObj.mouseLeave = false;
    if (!isHovered) {
      this.props.runbookObj.mouseLeave = true;
    }

    this.props.runbookObj.hoverMutation = true;
    this.props.runbookObj.updateRunbookObj(this.props.runbookObj);
    this.context.handlePrevStepOptionHover();
  };

  render() {
    const runbookNode = this.props.input.snippetAction || this.props.input;
    //const typeFilter = input.choice ? null : input.type;
    const previousStepNames = runbookNode
      .predecessors(runbookNode)
      .filter(
        step =>
          step?.stepType === "ActionNodeStep" ||
          step?.stepType === "SnippetStep" ||
          step?.stepType === "ActionNode" ||
          step?.stepType === StepTypes.InstanaAlertConnectorStep ||
          step?.stepType === StepTypes.DatadogConnectorStep ||
          step?.stepType === StepTypes.JSONPathStep ||
          step?.stepType === StepTypes.JiraConnectorStep ||
          (step?.snippetDef?.service.includes("Trigger") &&
            !step?.name?.includes(ControlNames.Manual)) ||
          step?.stepType === StepTypes.ApprovalNodeStep ||
          step?.stepType === StepTypes.SlackConnectorStep,
      )
      .map(step => {
        return {
          name: step?.name,
          stepType: step?.stepType,
        };
      });
    const { title, stepType, step } = this.state;

    const style = {
      width: "100%",
      padding: "0",
    };
    return (
      <React.Fragment>
        <Header
          title={this.props.label}
          description={this.props.inputDef?.description}
          helpText={this.props.inputDef?.help_text}
        />
        <div className="editor-previous-step-container" style={style}>
          <ReactSelect
            id="previous-step"
            name="previous-step"
            isSearchable={false}
            required
            value={{
              label: getSnippetDisplayName(
                title,
                stepType,
                this.props.snippetDisplayNameMap,
              ),
              value: title,
            }}
            handleChange={data => {
              if (data === null) {
                this._setPreviousStepInfo("", this.props.runbookObj);
              } else if (data.value) {
                this._setPreviousStepInfo(data.value, this.props.runbookObj);
              }
              this.handleOptionHover("", false);
            }}
            selectOptions={(
              this.getOptions(runbookNode, previousStepNames) || []
            ).map(item => {
              return {
                value: item.name,
                label: getSnippetDisplayName(
                  item.name,
                  item.stepType,
                  this.props.snippetDisplayNameMap,
                ),
              };
            })}
            handleOptionHover={this.handleOptionHover}
            customControlClass="mt-2-px"
          />
          {this.state.title !== "Choose a step" &&
            this.state.title !== "Custom JSON" &&
            this.state.title !== "" && (
              <div className="editor-right-panel-previous-container">
                {this.getPreviousStepOutputOptions(stepType, step, title)}
              </div>
            )}
        </div>
      </React.Fragment>
    );
  }
}

FromPreviousStep.defaultProps = {
  showOutputPath: true,
};

const mapState = ({ actionNodeReducer, snippetsReducer }) => ({
  selectedOutput: actionNodeReducer.selectedOutput,
  snippetDisplayNameMap: snippetsReducer.snippetDisplayNameMap,
});

const mapDispatch = dispatch => ({
  updateSelectedOutput: output => {
    dispatch({
      type: SET_SELECTED_OUTPUT,
      payload: output,
    });
  },
});
FromPreviousStep.contextType = RunbookEditorContext;
export default connect(mapState, mapDispatch)(FromPreviousStep);
