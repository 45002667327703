import  flatMapDeep  from "lodash/flatMapDeep";

// SSM Document
export class SSMDocument {
  constructor(description, parameters, mainSteps) {
    this.description = description;
    this.parameters = parameters;
    this.mainSteps = mainSteps;
  }
  toSSM() {
    this.clean();
    return {
      description: this.description,
      assumeRole: "{{AutomationAssumeRole}}",
      schemaVersion: "0.3",
      parameters: this.parameters.toSSM(),
      mainSteps: flatMapDeep(this.mainSteps.map(step => step.toSSM())),
    };
  }
  clean() {
    const mainSteps = [];
    this.mainSteps.forEach(step => {
      if (step !== undefined) {
        mainSteps.push(step);
      }
    });
    this.mainSteps = mainSteps;
  }
}
