import React, { useState, useEffect } from "react";
import { DropDown, DropDownOption, Modal, Button } from "@components/ui";
import { Consumer } from "../runbook-details-lib/runbook-details.context";

const footer_BTN_STYLE = { width: "50%" };

const GetFooter = props => {
  return (
    <div className="modal-buttons-footer">
      <Button
        text="Cancel"
        style={footer_BTN_STYLE}
        buttonStyle="secondary"
        onClick={() => {
          props.setShowConfirmModal(false);
        }}
        isLoading={props.isFetchingVersion || props.isDeletingVersion}
        type="button"
      />
      <Button
        text="Confirm"
        style={footer_BTN_STYLE}
        buttonStyle="warning"
        size="large"
        isLoading={props.isFetchingVersion || props.isDeletingVersion}
        onClick={e => {
          if (props.action === "default")
            props.onUpdateVersion(props.runbookName, props.version);
          if (props.action === "delete")
            props.onDeleteVerion(props.runbookName, props.version);
        }}
      />
    </div>
  );
};

export default props => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState("");
  const [confirmModalMessage, setConfirmModalMessage] = useState("");
  const [confirmModalResponse, setConfirmModalResponse] = useState("");
  const [action, setAction] = useState("");

  const onSetDefaultVersion = (runbookName, version) => {
    setAction("default");
    setConfirmModalTitle("SET AS DEFAULT");
    setConfirmModalMessage(
      `You are about to set version (${version}) of ${runbookName} as Default.`,
    );
    setConfirmModalResponse("");
    setShowConfirmModal(true);
  };

  const onDeleteRunbookVersion = (runbookName, version, runbookSchedules) => {
    setAction("delete");
    setConfirmModalTitle("DELETE VERSION");

    let msg = (
      <div>
        You are about to delete version ({version}) of {runbookName}
      </div>
    );
    let versionSchedule = runbookSchedules.find(s => {
      const workflowSplit = s.workflow.split("/");
      return (
        Number(workflowSplit[workflowSplit.length - 1]) === parseInt(version)
      );
    });
    if (versionSchedule) {
      msg = (
        <div>
          {msg}
          This will also delete "{versionSchedule.schedule_expression}"
          scheduler
        </div>
      );
    }
    setConfirmModalMessage(msg);
    setConfirmModalResponse("");
    setShowConfirmModal(true);
  };

  const isDefaultVersion = () => {
    let defaultVersion =
      props.runbook &&
      props.runbook.Versions &&
      props.runbook.Versions.find(version => {
        return (
          version.DocumentVersion === props.activeRunbookVersion &&
          version.IsDefaultVersion
        );
      });
    return !!defaultVersion;
  };

  useEffect(() => {
    switch (action) {
      case "default":
        if (!props.isFetchingVersion && props.isVersionUpdateSuccess) {
          setTimeout(() => {
            setShowConfirmModal(false);
          }, 300);
        }
        if (!props.isFetchingVersion && props.isVersionUpdateFailed) {
          setConfirmModalResponse(
            `Version ${props.activeRunbookVersion} could not be set as Default successfully`,
          );
        }
        break;

      case "delete":
        if (
          !props.isDeleting &&
          props.runbookVersionDeleteStatus[
            `${props.runbook.Name}-${props.activeRunbookVersion}`
          ]
        ) {
          setConfirmModalResponse(
            `Version ${props.activeRunbookVersion} deleted successfully for workflow ${props.runbook.Name}`,
          );
          const newVersion = props.versions.find(
            version => version.DocumentVersion !== props.activeRunbookVersion,
          );
          newVersion && props.setActiveVersion(newVersion.DocumentVersion);
          setTimeout(() => {
            setShowConfirmModal(false);
          }, 1000);
        }
        if (
          !props.isDeleting &&
          props.runbookVersionDeleteStatus.hasOwnProperty(
            `${props.runbook.Name}-${props.activeRunbookVersion}`,
          ) &&
          !props.runbookVersionDeleteStatus[
            `${props.runbook.Name}-${props.activeRunbookVersion}`
          ]
        ) {
          setConfirmModalResponse(
            `Version ${props.activeRunbookVersion} could not be deleted successfully`,
          );
        }
        break;

      default:
        break;
    }
  }, [props, action]);

  return (
    <Consumer>
      {({ setActiveVersion, onChangeVersionAction, runbookSchedule }) => {
        let title = "Versions";
        // eslint-disable-next-line
        for (let i in props.versions) {
          let v = props.versions[i];
          // check if a different version other than default was selected and put it as title
          if (!props.activeVersion) {
            if (v.IsDefaultVersion) {
              title = beatifyVersionNumber(v);
            }
          } else if (props.activeVersion === v.DocumentVersion) {
            title = beatifyVersionNumber(v);
          }
        }

        return (
          <div className="d-flex">
            <DropDown
              title={title}
              onChange={(text, value) => {
                setActiveVersion(value);
              }}
              width={248}
              style={{ maxWidth: "248px" }}
              selectedValue={props.activeVersion}
            >
              {props.versions &&
                props.versions.map(version => (
                  <DropDownOption
                    value={version.DocumentVersion}
                    text={beatifyVersionNumber(version)}
                    key={version.CreatedDate}
                  />
                ))}
            </DropDown>
            {showConfirmModal && (
              <Modal
                title={confirmModalTitle}
                showClose={true}
                onClose={() => {
                  setShowConfirmModal(false);
                }}
                footer={
                  <GetFooter
                    action={action}
                    onUpdateVersion={props.updateDefaultVersion}
                    runbookName={props.runbook.Name}
                    version={props.activeRunbookVersion}
                    setShowConfirmModal={setShowConfirmModal}
                    isFetchingVersion={props.isFetchingVersion}
                    isVersionUpdateSuccess={props.isVersionUpdateSuccess}
                    isVersionUpdateFailed={props.isVersionUpdateFailed}
                    setConfirmModalResponse={setConfirmModalResponse}
                    isDeletingVersion={props.isDeleting}
                    onDeleteVerion={props.deleteRunbookVersion}
                  />
                }
              >
                {confirmModalMessage}
                <br />
                <br />
                {confirmModalResponse}
                <span className="error">{}</span>
              </Modal>
            )}
            {!isDefaultVersion() && (
              <DropDown
                className="d-flex manage-version"
                changeAble={false}
                onChange={onChangeVersionAction}
                icon="icon-gear"
                tooltip="Manage Versions"
                tooltipPosition="right"
              >
                <DropDownOption
                  text="Set as Default"
                  value={
                    props.runbook &&
                    (props.runbook?.Name || props.runbook?.Description)
                  }
                  version={props.activeRunbookVersion}
                  handleClick={onSetDefaultVersion}
                />

                <DropDownOption
                  text="Delete Version"
                  handleClick={e => {
                    onDeleteRunbookVersion(
                      props.runbook.Name,
                      props.activeRunbookVersion,
                      runbookSchedule,
                    );
                  }}
                  value={
                    props.runbook &&
                    (props.runbook?.Name || props.runbook?.Description)
                  }
                  version={props.activeRunbookVersion}
                />
              </DropDown>
            )}
          </div>
        );
      }}
    </Consumer>
  );
};

/**
 * return an array of dom elements as the version number:
 * 1.0.0 - (Published|Draft) [default]
 * @param {dictionary} version
 */
const beatifyVersionNumber = version => {
  let text = [parseFloat(version.DocumentVersion).toFixed(1)]; // ssm only has 1,2,3 ... etc. versioning, so just hardcoded last decimal value
  // if (version.Status === 'Active') {
  //   text.push(" - Published")
  // } else {
  //   text.push(" - Draft")
  // }
  if (version.IsDefaultVersion) {
    text.push(
      <span key={version.CreatedDate} className="default-version">
        default
      </span>,
    );
  }
  return text;
};
