import produce from "immer";
import {
  FETCH_WORKSPACE_USERS_LIST_SUCCESS,
  FETCH_COLLABORATION_DETAILS_SUCCESS,
} from "@redux/types";

const INITIAL_STATE = {
  workspaceUsersList: [],
  workspaceUsersMap: {},
  jiraProjectsList: [],
  issueTypesList: [],
  collaborationDetails: {},
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_WORKSPACE_USERS_LIST_SUCCESS:
        let users =
          typeof action.response === "string"
            ? JSON.parse(action.response).users
            : action.response.users;
        let tempUsers = [];
        for (const key in users) {
          tempUsers.push({ label: users[key], value: key });
        }
        draft.workspaceUsersList = tempUsers;
        draft.workspaceUsersMap = users;
        break;
      case FETCH_COLLABORATION_DETAILS_SUCCESS:
        draft.collaborationDetails = action.response;
        break;
      default:
        break;
    }
  });
