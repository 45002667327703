import React from "react";
import { connect } from "react-redux";
import { TabCollection, Tab, TabContent } from "@components/ui/tabs";
import Panels from "./runbooks-panels/panels";
import { runbookOwners } from "@config/constants";
import { Consumer } from "../runbooks-lib/runbooks.context";
import {
  resetLoadingState,
  fetchRunbooks,
} from "@redux/actions/runbooks.actions";
import ReactTooltip from "react-tooltip";
import IconRefresh from "@assets/images/icons/icon-refresh.svg";
class RunbooksPanelsContainer extends React.Component {
  getResult = runbookListInput => {
    return runbookListInput && runbookListInput?.length
      ? runbookListInput.length
      : 0;
  };

  getRunbooksLength = (
    searchTerm,
    sortType,
    owner,
    activeOwner,
    categorySelected,
  ) => {
    if (searchTerm || sortType || categorySelected?.length) {
      if (activeOwner === owner) {
        return this.getResult(this.props.runbookListFiltered[owner]);
      } else if (categorySelected?.length) {
        return this.getResult(this.props.runbookListFiltered[owner]);
      } else return this.getResult(this.props.runbookList[owner]);
    } else {
      return this.getResult(this.props.runbookList[owner]);
    }
  };

  componentWillUnmount() {
    if (this.props.resetLoadingState) {
      this.props.resetLoadingState();
    }
  }

  render() {
    return (
      <Consumer>
        {props => {
          return (
            <div className="runbook-tabs mt-40-px ml-2 position-relative">
              <TabCollection
                tabChange={props.handleChange}
                tabs={["my-workflows", "fylamynt-workflows"]}
              >
                <Tab
                  title="My Workflows"
                  size={this.getRunbooksLength(
                    props.runbookSearchTerm,
                    props.sortType,
                    runbookOwners.self,
                    props.activeTabOwner,
                    props.categorySelected[runbookOwners.self],
                  )}
                  isWorkflow={this.props.isWorkflow}
                  name="my-workflows"
                >
                  <TabContent>
                    {!this.props.isLoadingRunbooks && (
                      <Panels isMultiSelectFilterEnable={false} />
                    )}
                  </TabContent>
                </Tab>
                <Tab
                  title="Fylamynt Workflows"
                  size={this.getRunbooksLength(
                    props.runbookSearchTerm,
                    props.sortType,
                    runbookOwners.fylamynt,
                    props.activeTabOwner,
                    props.categorySelected[runbookOwners.fylamynt],
                  )}
                  isWorkflow={this.props.isWorkflow}
                  name="fylamynt-workflows"
                >
                  <TabContent>
                    {!this.props.isLoadingRunbooks && (
                      <Panels isMultiSelectFilterEnable={true} />
                    )}
                  </TabContent>
                </Tab>
              </TabCollection>
              {props.activeTabOwner === "Self" && (
                <>
                  <ReactTooltip
                    id="refresh-workflows"
                    place="left"
                    effect="solid"
                    type="light"
                    className="wf-refresh-tooltip"
                  />
                  <div
                    className="workflows-refresh-icon"
                    data-for="refresh-workflows"
                    data-tip="Refresh Workflows"
                    onClick={() => {
                      this.props.fetchRunbooks(props.activeTabOwner);
                    }}
                  >
                    <img
                      src={IconRefresh}
                      alt="refreshIcon"
                      height="16"
                      width="16"
                    />
                  </div>
                </>
              )}
            </div>
          );
        }}
      </Consumer>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoadingRunbooks: state.runbooksReducer.isLoadingRunbooks,
    runbookList: state.runbooksReducer.runbookList,
    runbookListFiltered: state.runbooksReducer.runbookListFiltered,
  };
};

const mapDispatch = dispatch => ({
  resetLoadingState: () => dispatch(resetLoadingState()),
  fetchRunbooks: owner => dispatch(fetchRunbooks(owner)),
});

export default connect(mapStateToProps, mapDispatch)(RunbooksPanelsContainer);
