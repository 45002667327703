import produce from "immer";
import { SCHEDULED_RUNBOOKS, IS_FETCHING_SCHEDULES } from "@redux/types";

const INITIAL_STATE = {
  scheduledRunbooks: [],
  isFetchingSchedules: true,
  message: "",
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SCHEDULED_RUNBOOKS:
        let data = action.payload.data;
        if (action.payload.isSingle) {
          let i;
          let found = false;
          for (i in state.scheduledRunbooks) {
            let item = state.scheduledRunbooks[i];
            if (
              item["workflow"] === data[0]["workflow"]
            ) {
                draft.scheduledRunbooks[i] = data[0];
                found = true;
            }
          }
          if (!found) {
              draft.scheduledRunbooks = data;
          }
        } else {
          draft.scheduledRunbooks = data;
        }
        break;
      case IS_FETCHING_SCHEDULES:
        draft.isFetchingSchedules = action.payload;
        draft.message = action.message
        break;

      default:
        break;
    }
  });
