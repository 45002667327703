import React, { useRef, useState } from "react";
import TickIcon from "@assets/images/icons/icon-tick.svg";
import { generateId } from "@lib/utils";
import { InputChoices } from "./Constants";
import useOutsideClickDetector from "../../../../../hooks/useOutsideClickDetector";

const InputChoiceSelector = ({
  selectedChoice,
  setSelectedInputChoice,
  isStart = false,
}) => {
  const [showChoices, setShowChoices] = useState(false);
  let targetRef = useRef(null);
  useOutsideClickDetector(targetRef, () => setShowChoices(false));

  return (
    <>
      <div className="editor-gear-icon-wrap" style={{ top: "0" }}>
        <div
          className="editor-gear-icon"
          onClick={() => setShowChoices(!showChoices)}
        />
      </div>
      {showChoices && (
        <nav
          className="editor-dropdown editor-dropdown-list editor-dropdown-container"
          id="w-dropdown-toggle-9"
          ref={targetRef}
          style={{ top: 0 }}
        >
          {InputChoices.map((option, idx) => {
            const id = idx + "_" + generateId();
            return !isStart || option.choice !== "actionNode" ? (
              <li
                key={id}
                className="editor-dropdown-link"
                onClick={() => {
                  setSelectedInputChoice(option.choice);
                  setShowChoices(false);
                }}
              >
                <img
                  className={`dropdown-tick-icon ${
                    selectedChoice !== option.choice ? "visibility-hidden" : ""
                  }`}
                  src={TickIcon}
                  alt="tick-icon"
                />
                {option.label}
              </li>
            ) : null;
          })}
        </nav>
      )}
    </>
  );
};

export default InputChoiceSelector;
