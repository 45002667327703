import Api from "@lib/api/api";
import {
  DATADOG_MONITOR_LIST_SET,
  DATADOG_MONITOR_LIST_FETCHING,
  DATADOG_MONITOR_SET,
  DATADOG_MONITOR_FETCHING,
  DATADOG_MONITOR_ERROR,
  SET_WAIT_MESSAGE,
  SHOW_WAIT,
} from "../types";

export const getDatadogMonitorList = () => {
  return async dispatch => {
    _isFetchingDatadogMonitorList(dispatch, true);
    dispatch(setMessage(`Fetching list of Datadog monitors`));
    const data = await Api.getDatadogMonitorList().catch(e => {
      dispatch(_datadogMonitorError(e));
    });
    dispatch(setMessage(""));
    if (data && data.ERROR) {
      dispatch(_datadogMonitorError(data.ERROR));
    } else if (!data) {
      dispatch(
        _datadogMonitorError("No data returned when fetching Datadog monitors"),
      );
    } else if (data) {
      console.log(data);
      dispatch({ type: DATADOG_MONITOR_LIST_SET, payload: data });
      _isFetchingDatadogMonitorList(dispatch, false);
    }
  };
};

export const getDatadogMonitor = id => {
  return async dispatch => {
    _isFetchingDatadogMonitor(dispatch, true);
    dispatch(setMessage(`Fetching list of Datadog monitors`));
    const data = await Api.getDatadogMonitor(id).catch(e => {
      dispatch(_datadogMonitorError(e));
    });

    if (data && data.ERROR) {
      dispatch(_datadogMonitorError(data.ERROR));
    } else if (!data) {
      dispatch(
        _datadogMonitorError("No data returned when fetching Datadog monitor"),
      );
    } else if (data) {
      console.log(data);
      dispatch({ type: DATADOG_MONITOR_SET, payload: data });
      _isFetchingDatadogMonitor(dispatch, false);
    }
  };
};
export function _datadogMonitorError(error) {
  const message = `ERROR: ${error} `;
  return async dispatch => {
    dispatch({ type: DATADOG_MONITOR_ERROR, payload: error });
    dispatch(setMessage(message));
    setTimeout(() => {
      _isFetchingDatadogMonitorList(dispatch, false);
      _isFetchingDatadogMonitor(dispatch, false);
    }, 1000);
  };
}

function _isFetchingDatadogMonitorList(dispatch, fetching) {
  return dispatch({
    type: DATADOG_MONITOR_LIST_FETCHING,
    payload: fetching,
  });
}

function _isFetchingDatadogMonitor(dispatch, fetching) {
  return dispatch({
    type: DATADOG_MONITOR_FETCHING,
    payload: fetching,
  });
}

export function setMessage(message = "", showWait = true) {
  return async dispatch => {
    dispatch({
      type: SET_WAIT_MESSAGE,
      payload: message,
    });
    if (!message) {
      showWait = false;
    }
    dispatch({
      type: SHOW_WAIT,
      payload: showWait,
    });
  };
}
