import React from "react";
import { OverflowMenu } from "@components/ui";

export default onDelete => [
  {
    name: "Account Alias",
    selector: "alias",
    grow: 2,
  },
  {
    name: "Name",
    selector: "name",
    grow: 2,
  },
  {
    name: "AssumeRole ARN",
    selector: "arn",
    grow: 4,
  },

  {
    name: "",
    selector: "options",
    cell: row => (
      <OverflowMenu>
        <div className="button-delete" onMouseDown={() => onDelete(row)}>
          Delete
        </div>
      </OverflowMenu>
    ),
  },
];
