import React from "react";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import SnippetHeader from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/SnippetHeader/SnippetHeader";
import ReactSelect from "@components/ui/React-Select/ReactSelect";

export default class DatadogConnectorInput extends React.Component {
  state = {
    monitors: [],
    selectedMonitor: this.props.activeNode.extras.runbookNode.monitorInfo,
  };

  componentDidMount() {
    if (Object.keys(this.props.datadogMonitorList).length === 0) {
      this.props.getDatadogMonitorList();
    } else {
      this.updateDatadogMonitorList({});
    }
  }

  componentDidUpdate(prevProps) {
    this.updateDatadogMonitorList(prevProps);
  }

  updateDatadogMonitorList(prevProps) {
    if (
      this.props.datadogMonitorList &&
      this.datadogMonitorListUpdated(prevProps)
    ) {
      const { runbookNode } = this.props.activeNode.extras;
      const selectedID = runbookNode && runbookNode.monitorID;
      const selectedMonitor = this.props.datadogMonitorList.find(
        monitor => `${monitor.id}` === `${selectedID}`,
      );
      const monitors = this.props.datadogMonitorList;
      this.setState({ monitors, selectedMonitor });
    }
  }

  addSelectedMonitorToList(selected, monitors) {
    if (!monitors.find(monitor => monitor.id === selected.id)) {
      monitors.append(selected);
    }
    return [...monitors];
  }

  datadogMonitorListUpdated(prevProps) {
    const listAppeared =
      !prevProps.datadogMonitorList && this.props.datadogMonitorList;
    const listChanged =
      prevProps.datadogMonitorList !== this.props.datadogMonitorList;
    const listGrew =
      listLen(prevProps.datadogMonitorList) <
      listLen(this.props.datadogMonitorList);
    return listAppeared || listChanged || listGrew;
  }

  updateMonitor(newValue) {
    const selectedMonitor = this.state.monitors.find(
      monitor => monitor.name === newValue,
    );

    this.props.activeNode.extras.runbookNode.setMonitorInfo(selectedMonitor);
    this.setState({ selectedMonitor });
  }

  render() {
    const { activeNode } = this.props;
    const { monitors, selectedMonitor } = this.state;
    return (
      <React.Fragment>
        <SnippetHeader snippetDef={activeNode.extras.runbookNode.snippetDef} />
        <React.Fragment>
          <Accordion isExpanded={true} useArrow={true}>
            <AccordionLabel className="editor-accordion-label mt-10-px">
              Required Inputs
            </AccordionLabel>
            <AccordionSection>
              <div className="editor-detail-panel editor-detail-panel-column">
                <label className="label">Monitor</label>
                <ReactSelect
                  id="datadog-select"
                  name="datadog-select"
                  value={{
                    value: selectedMonitor ? selectedMonitor.name : null,
                    label: selectedMonitor ? selectedMonitor.name : null,
                  }}
                  handleChange={data => {
                    if (data === null) {
                      this.updateMonitor("");
                    } else if (data.value) {
                      this.updateMonitor(data.value);
                    }
                  }}
                  required
                  selectOptions={(monitors || []).map(item => ({
                    label: item.name,
                    value: item.name,
                  }))}
                />
              </div>
            </AccordionSection>
          </Accordion>
          {selectedMonitor && (
            <Accordion isExpanded={true} useArrow={true}>
              <AccordionLabel className="editor-accordion-label mt-10-px">
                Monitor Details
              </AccordionLabel>
              <AccordionSection
                style={{
                  minHeight: "150px",
                  maxHeight: "300px",
                  overflow: "hidden",
                  width: "100%",
                  wordBreak: "break-all",
                }}
              >
                <div style={{ margin: 10 }}>
                  <p style={{ fontSize: "12px" }}>
                    <strong style={{ color: "#ddd" }}>Description:</strong>{" "}
                    {selectedMonitor.description}
                  </p>
                  <p style={{ fontSize: "12px" }}>
                    <strong style={{ color: "#ddd" }}>Rule:</strong>{" "}
                    {selectedMonitor.rule}
                  </p>
                  <p style={{ fontSize: "12px" }}>
                    <strong style={{ color: "#ddd" }}>Message:</strong>{" "}
                    {selectedMonitor.message}
                  </p>
                </div>
              </AccordionSection>
            </Accordion>
          )}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

function listLen(l) {
  return l ? l.length : 0;
}
