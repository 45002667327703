import produce from "immer";
import {
  WEBSOCKET_CONNECTION_ID,
  WEBSOCKET_EXECUTION_STEPS,
  WEBSOCKET_EXECUTION_DETAILS,
} from "@redux/types";

const INITIAL_STATE = {
  connectionId: "",
  executionSteps: {},
  executionFinalStatus: {},
};
export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case WEBSOCKET_CONNECTION_ID:
        draft.connectionId = action.payload;
        break;
      case WEBSOCKET_EXECUTION_STEPS:
        if (state.executionSteps[action.payload.ExecutionId]) {
          draft.executionSteps[action.payload.ExecutionId][
            action.payload.StepName
          ] = action.payload;
        } else {
          draft.executionSteps[action.payload.ExecutionId] = {
            [action.payload.StepName]: action.payload,
          };
        }
        break;
      case WEBSOCKET_EXECUTION_DETAILS:
        draft.executionFinalStatus[action.payload.detail.ExecutionId] =
          action.payload;
        break;
      default:
        break;
    }
  });
