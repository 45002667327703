import React, { useEffect, useState } from "react";
import "./Signup.scss";
import { Formik } from "formik";
import Auth from "@aws-amplify/auth";
import { Link, useHistory } from "react-router-dom";
import Button from "@components/ui/button/button";
import PasswordStrength from "@components/authentication/utils/PasswordStrength";
import visibleIcon from "@assets/images/icons/eye-icon__allowed.svg";
import invisibleIcon from "@assets/images/icons/icon-eye__not-allowed.svg";
import { getValidationSchema } from "@components/authentication/Signup/utils/ValidationSchema";
import { loginSuccess } from "@redux/actions/user.actions";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Wait from "@components/ui/wait";
import { isFetching } from "@redux/actions/settingsPanel.action";
import fylamyntLogo from "@assets/images/icons/fylamynt-logo__signup.svg";
import messagePoint from "@assets/images/icons/icon-success-filled__white.svg";
import {
  keyUpHandler,
  setCustomIdentityId,
  trackIdentity,
} from "../utils/helpers";

type SignupProps = {};

const initialValues = {
  username: "",
  email: "",
  company: "",
  agreeTerm: false,
  confirmPassword: "",
  password: "",
  activationCode: "",
};

const Signup: React.FC<SignupProps> = () => {
  const [currTab, setCurrTab] = useState("tab1");
  const history = useHistory();
  const submitBtnStyle = { width: "100%" };
  const [passwordVisible, togglePasswordVisibility] = useState<Boolean>(false);
  const [confirmPasswordVisible, toggleConfirmPasswordVisible] = useState(
    false,
  );
  const validationSchema = getValidationSchema(currTab);
  const dispatch = useDispatch();
  const loadingMessage = useSelector(
    (state: RootStateOrAny) => state.runbooksReducer.message,
  );
  const [isPasswordStrong, setStrongPasswordFlag] = useState(false);
  const isLoggedIn = useSelector(
    (state: RootStateOrAny) => state.userReducer.isLoggedIn,
  );
  const changePasswordVisibility = () =>
    togglePasswordVisibility(!passwordVisible);

  const changeConfirmPasswordVisibility = () =>
    toggleConfirmPasswordVisible(!confirmPasswordVisible);

  useEffect(() => {
    isLoggedIn && history.push("/workflows");
  }, [isLoggedIn, history]);

  const sendConfirmationCode = async (user, actions, resend = false) => {
    actions.setStatus({ formSubmitMessage: "" });
    actions.setSubmitting(true);
    dispatch(isFetching(true, "Sending Confirmation Code"));
    try {
      if (resend) {
        await Auth.resendSignUp(user.email);
      } else {
        await Auth.signUp({
          username: user.email,
          password: user.password,
          attributes: {
            name: user.username, // optional
            "custom:company": user.company,
          },
        });
        setCurrTab("tab3");
      }
      dispatch(isFetching(false, ""));
      actions.setStatus({ formSubmitMessage: "" });
    } catch (error) {
      dispatch(isFetching(false, ""));
      actions.setStatus({ formSubmitMessage: error.message });
    }
    actions.setSubmitting(false);
  };

  const handleSubmit = async (user, actions) => {
    switch (currTab) {
      case "tab1":
        actions.setTouched({});
        actions.setStatus({ formSubmitMessage: "" });
        setCurrTab("tab2");
        return;
      case "tab2":
        sendConfirmationCode(user, actions);
        return;
      case "tab3":
        actions.setSubmitting(true);
        dispatch(isFetching(true, "Confirming User"));
        try {
          await Auth.confirmSignUp(user.email, user.activationCode);
          actions.setStatus({ formSubmitMessage: "" });
          dispatch(isFetching(true, "Logging In"));
          try {
            let authData = await Auth.signIn(user.email, user.password);
            await Promise.all([
              setCustomIdentityId(),
              trackIdentity(user, true),
            ]);
            actions.setStatus({ formSubmitMessage: "" });
            dispatch(loginSuccess(authData));
          } catch (error) {
            dispatch(isFetching(false, ""));
            actions.setStatus({ formSubmitMessage: error.message });
          }
        } catch (error) {
          dispatch(isFetching(false, ""));
          actions.setStatus({ formSubmitMessage: error.message });
        }
        actions.setSubmitting(false);
        return;
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {formik => (
        <form>
          <div className="signup-container">
            {loadingMessage && <Wait text={loadingMessage} />}
            <div className="signup-left">
              <div className="signup-card">
                {currTab === "tab1" && (
                  <>
                    <div className="three-dots">
                      <div className="dot-outer">
                        <div className="dot-active"></div>
                      </div>
                      <div className="dot-outer"></div>
                      <div className="dot-outer"></div>
                    </div>
                    <h1 className="signup-card-header">Create an account</h1>
                    <div className="signup-form-input">
                      <label className="signup-form-input-label">
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="username"
                        placeholder="John Doe"
                        className={`signup-form-input-text ${
                          formik.errors.username &&
                          formik.touched.username &&
                          "error"
                        }`}
                        aria-describedby="basic-addon1"
                        {...formik.getFieldProps("username")}
                        onKeyUp={e =>
                          keyUpHandler(
                            e,
                            formik.handleSubmit,
                            formik.isSubmitting,
                          )
                        }
                        autoComplete="off"
                        autoFocus
                      />
                      {formik.errors.username && formik.touched.username ? (
                        <div className="input-feedback">
                          {formik.errors.username}
                        </div>
                      ) : null}
                    </div>
                    <div className="signup-form-input">
                      <label className="signup-form-input-label">
                        Company Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        placeholder="johndoe@acme.com"
                        className={`signup-form-input-text ${
                          formik.errors.email && formik.touched.email && "error"
                        }`}
                        aria-describedby="basic-addon1"
                        {...formik.getFieldProps("email")}
                        onKeyUp={e =>
                          keyUpHandler(
                            e,
                            formik.handleSubmit,
                            formik.isSubmitting,
                          )
                        }
                        autoComplete="off"
                      />
                      {formik.errors.email && formik.touched.email ? (
                        <div className="input-feedback">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="signup-form-input">
                      <label className="signup-form-input-label">
                        Company Name
                      </label>
                      <input
                        type="text"
                        id="company"
                        placeholder="Acme Corp"
                        className={`signup-form-input-text ${
                          formik.errors.company &&
                          formik.touched.company &&
                          "error"
                        }`}
                        aria-describedby="basic-addon1"
                        {...formik.getFieldProps("company")}
                        onKeyUp={e =>
                          keyUpHandler(
                            e,
                            formik.handleSubmit,
                            formik.isSubmitting,
                          )
                        }
                        autoComplete="off"
                      />
                      {formik.errors.company && formik.touched.company ? (
                        <div className="input-feedback">
                          {formik.errors.company}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-5-px terms-conditions">
                      <div
                        className={`mr-5-px pointer h-18 ${
                          formik.touched.agreeTerm &&
                          formik.errors.agreeTerm &&
                          "border-red"
                        }`}
                      >
                        <input
                          type="checkbox"
                          className="pointer agree-check"
                          checked={formik.values.agreeTerm}
                          onChange={() => {
                            let checked = formik.values.agreeTerm;
                            formik.setFieldValue("agreeTerm", !checked);
                          }}
                          id={`tnc_checkbox`}
                        />
                      </div>
                      <label
                        className="label-for-check-box"
                        htmlFor={`dropdown-option-checkbox-default-target-account`}
                      >
                        I agree to the{" "}
                        <a
                          target="_blank"
                          href="https://www.fylamynt.com/terms-of-service"
                          className="signup-form-navigator-link"
                          rel="noopener noreferrer"
                        >
                          Terms of Service
                        </a>{" "}
                        and{" "}
                        <a
                          target="_blank"
                          href="https://www.fylamynt.com/privacy"
                          className="signup-form-navigator-link"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </label>
                    </div>
                    {formik.errors.agreeTerm && formik.touched.agreeTerm ? (
                      <div className="input-feedback">
                        {formik.errors.agreeTerm}
                      </div>
                    ) : null}
                    <div className="form-footer">
                      <Button
                        text="Next Step"
                        style={submitBtnStyle}
                        buttonStyle="primary"
                        size="large"
                        onClick={formik.handleSubmit}
                        className="signup-form-submit-btn"
                      />
                    </div>
                    <div className="signup-form-navigator-container">
                      <span className="mr-5-px">Have an account?</span>
                      <Link to={"/"} className="signup-form-navigator-link">
                        Sign In
                      </Link>
                    </div>
                  </>
                )}
                {currTab === "tab2" && (
                  <>
                    <div className="three-dots">
                      <div className="dot-outer">
                        <div className="dot-active"></div>
                      </div>
                      <div className="dot-outer">
                        <div className="dot-active"></div>
                      </div>
                      <div className="dot-outer"></div>
                    </div>
                    <h1 className="signup-card-header">Create Password</h1>
                    <div className="signup-form-input">
                      <label className="signup-form-input-label">
                        New Password
                      </label>
                      <div className="d-flex">
                        <input
                          id="password"
                          className={`signup-form-input-text ${
                            formik.errors.password &&
                            formik.touched.password &&
                            "error"
                          }`}
                          aria-describedby="basic-addon1"
                          type={passwordVisible ? "text" : "password"}
                          {...formik.getFieldProps("password")}
                          onKeyUp={e =>
                            keyUpHandler(
                              e,
                              formik.handleSubmit,
                              formik.isSubmitting,
                            )
                          }
                          autoComplete="new-password"
                          autoFocus
                        />
                        <img
                          onClick={changePasswordVisibility}
                          src={passwordVisible ? visibleIcon : invisibleIcon}
                          alt="invisibleIcon"
                          className="password-eye-icon"
                          width="18"
                          height="18"
                        />
                      </div>
                      {!!formik.values.password && (
                        <PasswordStrength
                          password={formik.values.password}
                          setPasswordScore={setStrongPasswordFlag}
                        />
                      )}
                      {formik.errors.password && formik.touched.password ? (
                        <div className="input-feedback">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </div>
                    <div className="signup-form-input">
                      <label className="signup-form-input-label">
                        Confirm New Password
                      </label>
                      <div className="d-flex">
                        <input
                          id="confirmPassword"
                          className={`signup-form-input-text ${
                            formik.errors.confirmPassword &&
                            formik.touched.confirmPassword &&
                            "error"
                          }`}
                          aria-describedby="basic-addon1"
                          type={confirmPasswordVisible ? "text" : "password"}
                          {...formik.getFieldProps("confirmPassword")}
                          onKeyUp={e =>
                            keyUpHandler(
                              e,
                              formik.handleSubmit,
                              formik.isSubmitting,
                            )
                          }
                          autoComplete="new-password"
                        />
                        <img
                          onClick={changeConfirmPasswordVisibility}
                          src={
                            confirmPasswordVisible ? visibleIcon : invisibleIcon
                          }
                          alt="invisibleIcon"
                          className="password-eye-icon"
                          width="18"
                          height="18"
                        />
                      </div>
                      {formik.errors.confirmPassword &&
                      formik.touched.confirmPassword ? (
                        <div className="input-feedback">
                          {formik.errors.confirmPassword}
                        </div>
                      ) : null}
                    </div>
                    <div className="input-feedback">
                      {!!formik.status && formik.status.formSubmitMessage}
                    </div>
                    <div className="form-footer">
                      <Button
                        text="Next Step"
                        style={submitBtnStyle}
                        buttonStyle="primary"
                        size="large"
                        onClick={formik.handleSubmit}
                        className="signup-form-submit-btn"
                        disabled={!isPasswordStrong}
                      />
                    </div>
                    <span
                      className="pointer link-underline mt-10-px"
                      onClick={() => {
                        formik.setStatus({ formSubmitMessage: "" });
                        setCurrTab("tab1");
                      }}
                    >
                      Go Back
                    </span>
                  </>
                )}

                {currTab === "tab3" && (
                  <>
                    <div className="three-dots">
                      <div className="dot-outer">
                        <div className="dot-active"></div>
                      </div>
                      <div className="dot-outer">
                        <div className="dot-active"></div>
                      </div>
                      <div className="dot-outer">
                        <div className="dot-active"></div>
                      </div>
                    </div>
                    <h1 className="signup-card-header">Check Email For Code</h1>
                    <div className="activation-code-helper">
                      We’ve sent a 6-digit code to{" "}
                      <b className="activation-code-helper-highlight">
                        {formik.values.email}
                      </b>
                      .
                      <br />
                      Can’t find your code? Check your spam or{" "}
                      <span
                        className="login-form-navigator-link"
                        onClick={() =>
                          sendConfirmationCode(formik.values, formik, true)
                        }
                      >
                        Resend Code
                      </span>
                    </div>
                    <div className="signup-form-input">
                      <label className="signup-form-input-label">
                        Activation Code
                      </label>
                      <input
                        id="activationCode"
                        className={`signup-form-input-text ${
                          formik.errors.activationCode &&
                          formik.touched.activationCode &&
                          "error"
                        }`}
                        aria-describedby="basic-addon1"
                        type="text"
                        {...formik.getFieldProps("activationCode")}
                        onKeyUp={e =>
                          keyUpHandler(
                            e,
                            formik.handleSubmit,
                            formik.isSubmitting,
                          )
                        }
                        autoComplete="off"
                        autoFocus
                      />
                      {formik.errors.activationCode &&
                      formik.touched.activationCode ? (
                        <div className="input-feedback">
                          {formik.errors.activationCode}
                        </div>
                      ) : null}
                    </div>
                    <img
                      height="1"
                      width="1"
                      alt="linkdinImage"
                      style={{ display: "none" }}
                      src="https://px.ads.linkedin.com/collect/?pid=2803348&conversionId=3270700&fmt=gif"
                    />
                    <div className="input-feedback">
                      {!!formik.status && formik.status.formSubmitMessage}
                    </div>
                    <div className="form-footer">
                      <Button
                        text={`Activate & Sign In`}
                        style={submitBtnStyle}
                        buttonStyle="primary"
                        size="large"
                        onClick={formik.handleSubmit}
                        className="signup-form-submit-btn"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="signup-right">
              <div className="marketing-content">
                <img
                  src={fylamyntLogo}
                  alt="fylamyntLogo"
                  width={300}
                  height={50}
                  className="signup-logo"
                />
                <h3 className="signup-message">
                  Build, Run and Analyze cloud workflows securely for any cloud
                  with little to no code.
                </h3>
                <div className="signup-point">
                  <img
                    src={messagePoint}
                    alt="messagePoint"
                    className="message-point"
                    width={14}
                    height={14}
                  />
                  Orchestrate and automate your incident response workflows
                  across all cloud operation areas (SREs, DevOps).
                </div>
                <div className="signup-point">
                  <img
                    src={messagePoint}
                    alt="messagePoint"
                    className="message-point"
                    width={14}
                    height={14}
                  />
                  Accelerate your investigations by getting to the right details
                  and enriching specific insights into access-control,
                  misconfigurations and threat vectors.
                </div>
                <div className="signup-point">
                  <img
                    src={messagePoint}
                    alt="messagePoint"
                    className="message-point"
                    width={14}
                    height={14}
                  />
                  Get rid of the team silos by using our integrated case
                  management feature and speed up resolution by assigning
                  incidents to relevant teams.
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Signup;
