import React from "react";
import PanelsHeader from "./panels-header";
import TagMultiSelectFilter from "./TagMultiSelectFilter";
import { Consumer } from "../../runbooks-lib/runbooks.context";
import Panel from "./panel";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";
import { Button } from "@components/ui";
import "./Panels.scss";

export default ({ isMultiSelectFilterEnable }) => (
  <>
    <PanelsHeader />
    <Consumer>
      {({
        runbooksList,
        runbooks,
        runbookSearchTerm,
        searchAndSort,
        toggleRunbookModal,
        handleFilterSelection,
      }) => (
        <React.Fragment>
          {isMultiSelectFilterEnable && (
            <TagMultiSelectFilter
              runbooksList={runbooks}
              handleFilterSelection={handleFilterSelection}
            />
          )}
          {!runbookSearchTerm &&
          Array.isArray(runbooksList) &&
          !runbooksList?.length ? (
            <NoResultFound
              message="Start by adding a new Workflow"
              detail="To create a workflow, click New Workflow below"
              className="mt-100-px"
              errImgSrc="workflows-es"
              fallbackComponent={
                <Button
                  className="pt-10-px pb-10-px"
                  buttonStyle="primary"
                  onClick={toggleRunbookModal}
                  size="small"
                  text="New Workflow"
                  icon="icon-plus"
                ></Button>
              }
            />
          ) : (
            runbookSearchTerm &&
            Array.isArray(runbooksList) &&
            !runbooksList?.length && (
              <NoResultFound
                message={`No results found for ${runbookSearchTerm}`}
                detail="Try adjusting your search to find what you're looking for."
                className="mt-100-px"
                fallbackComponent={
                  <span
                    className="reset-search"
                    onClick={() => searchAndSort({ search: "" })}
                  >
                    Clear Search
                  </span>
                }
              />
            )
          )}
          <div className="runbooks-grid">
            {Array.isArray(runbooksList) &&
              !!runbooksList.length &&
              runbooksList.map(runbook => (
                <Panel runbook={runbook} key={runbook.Name} />
              ))}
          </div>
        </React.Fragment>
      )}
    </Consumer>
  </>
);
