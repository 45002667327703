import React from "react";

export default () => (
  <div className="fade-select-none">
    <div className="editor-detail-panel editor-detail-panel-row">
      <div className="editor-detail-panel-icon editor-detail-panel-icon-select" />
      <div>None Selected</div>
    </div>
    <div className="editor-detail-panel editor-detail-placeholder">
      <div className="editor-detail-placeholder-inner-wrap">
        <div className="editor-detail-placeholder-icon"></div>
        <div className="editor-detail-placeholder-text">
          Select a node on the canvas to activate this panel
        </div>
      </div>
    </div>
  </div>
);
