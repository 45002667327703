import React from "react";
import { connect } from "react-redux";
import { Accordion, AccordionLabel } from "@components/ui";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import SnippetHeader from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/SnippetHeader/SnippetHeader";
import {
  getJiraProjectsIssueFields,
  getProjectsIssueDetail,
  getProjectsIssueList,
  clearSelectedIssue,
} from "@redux/actions/snippets.actions";
import Output from "../../../output";
import invert from "lodash/invert";
import JiraIssueDetails from "./JiraIssueDetails";
import JiraRequiredFields from "./JiraRequiredFields";
import JiraOptionalFields from "./JiraOptionalFields";
import {
  hideGlobalLoader,
  showGlobalLoader,
} from "@redux/actions/globalLoader.actions";

export type jiraDetailsType = {
  projects: Array<string>;
  issueTypes: Array<string>;
  reporters: Array<string>;
  priorities: Array<string>;
  labels: [{ name: string; id: string }];
  description: string;
  summary: string;
  createdOn: Date | string;
  id: string;
  issues: Array<string>;
  selectedIssue: any;
  transitions: Array<string>;
};

type JiraExistingIssuePanelProps = {
  jiraDetails: jiraDetailsType;
  activeNode: any;
  fetchJiraDetails: () => {};
  getJiraProjectsIssueFields: (project_key: any) => {};
  getProjectsIssueDetail: (issueId: string) => {};
  getProjectsIssueList: (project_key: string) => {};
  notifyRunbookUpdate: (param: boolean) => any;
  clearSelectedIssue: () => {};
  loaderFlag: boolean;
  hideGlobalLoader: () => void;
  showGlobalLoader: () => void;
};

type selectedInputsType = {
  project: string;
  issueType: string;
  id: string;
  createdOn: string;
  reporter: string;
  priority: string;
  description: string;
  summary: string;
  issueKey: string;
  transition: string;
};

type JiraExistingIssuePanelState = {
  selectedInputs: selectedInputsType;
  showIssueDetails: boolean;
};

const fieldInputMap = {
  issueType: "issuetype",
  description: "description",
  summary: "summary",
  project: "project_key",
  priority: "priority",
  reporter: "reporter",
  issueKey: "issue_key",
  transition: "transition",
};

class JiraExistingIssuePanel extends React.Component<
  JiraExistingIssuePanelProps,
  JiraExistingIssuePanelState
> {
  constructor(props) {
    super(props);
    let selectedInputs: selectedInputsType = {
      project: "",
      issueType: "",
      id: "",
      createdOn: "",
      reporter: "",
      priority: "",
      description: "",
      summary: "",
      issueKey: "",
      transition: "",
    };
    // eslint-disable-next-line
    const invertedFieldInputMap = invert(fieldInputMap);

    for (let item of props.activeNode.extras.runbookNode.parameterInputs) {
      if (typeof item.source.sourceValue === "string") {
        selectedInputs[invertedFieldInputMap[item.name]] =
          typeof item.source.sourceValue === "string" &&
          item.source.sourceValue;
      }
    }

    this.state = {
      selectedInputs: selectedInputs,
      showIssueDetails: !!(selectedInputs.project && selectedInputs.issueKey),
    };
  }

  componentDidMount() {
    if (
      this.props.jiraDetails &&
      (!this.props.jiraDetails.projects ||
        !this.props.jiraDetails.projects.length)
    ) {
      this.props.fetchJiraDetails();
    }

    if (
      !!(
        this.state.selectedInputs.project && this.state.selectedInputs.issueKey
      )
    ) {
      this.selectIssue(this.state.selectedInputs.issueKey, false, false);
      this.props.getJiraProjectsIssueFields(this.state.selectedInputs.project);
      this.props.getProjectsIssueList(this.state.selectedInputs.project);
    }
    if (this.props.loaderFlag) {
      this.props.showGlobalLoader();
    }
  }

  componentWillUnmount() {
    this.props.clearSelectedIssue();
    if (!this.props.loaderFlag) {
      this.props.hideGlobalLoader();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.jiraDetails.selectedIssue &&
      state.selectedInputs.id !== props.jiraDetails.selectedIssue.id
    ) {
      let issue = props.jiraDetails.selectedIssue;
      let parameterInputs = props.activeNode.extras.runbookNode.parameterInputs;
      const priorityInput = parameterInputs.find(
        ip => ip.name === fieldInputMap.priority,
      );
      const descriptionInput = parameterInputs.find(
        ip => ip.name === fieldInputMap.description,
      );
      const summaryInput = parameterInputs.find(
        ip => ip.name === fieldInputMap.summary,
      );
      const statusInput = parameterInputs.find(
        ip => ip.name === fieldInputMap.transition,
      );

      return {
        selectedInputs: {
          ...state.selectedInputs,
          id: issue.id,
          createdOn: issue.created,
          reporter: issue.reporter,
          priority:
            !state.selectedInputs.id &&
            priorityInput.source.sourceValue !== null &&
            priorityInput.source.sourceValue !== issue.priority
              ? priorityInput.source.sourceValue
              : issue.priority,
          description:
            !state.selectedInputs.id &&
            descriptionInput.source.sourceValue !== null &&
            descriptionInput.source.sourceValue !== issue.description
              ? descriptionInput.source.sourceValue
              : issue.description,
          summary:
            !state.selectedInputs.id &&
            summaryInput.source.sourceValue !== null &&
            summaryInput.source.sourceValue !== issue.summary
              ? summaryInput.source.sourceValue
              : issue.summary,
          transition:
            !state.selectedInputs.id &&
            statusInput.source.sourceValue !== null &&
            statusInput.source.sourceValue !== issue.status
              ? statusInput.source.sourceValue
              : issue.status,
        },
        showIssueDetails: true,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.jiraDetails.selectedIssue &&
        this.props.jiraDetails.selectedIssue &&
        this.props.jiraDetails.selectedIssue.id) ||
      (prevProps.jiraDetails.selectedIssue &&
        prevProps.jiraDetails.selectedIssue.id !==
          this.props.jiraDetails.selectedIssue.id)
    ) {
      // eslint - disable - next - line;
      const invertedFieldInputMap = invert(fieldInputMap);
      for (let input of this.props.activeNode.extras.runbookNode
        .parameterInputs) {
        const value = this.state.selectedInputs[
          invertedFieldInputMap[input.name]
        ];

        if (value && input) {
          input.source = new RunbookStepInputSource("constant", value);
        }
      }
    }
  }

  updateInputFieldValue = (newValue, notifyRunbookUpdate, field) => {
    // notify reducer that current runbook got updates.

    const input = this.props.activeNode.extras.runbookNode.parameterInputs.find(
      ip => ip.name === fieldInputMap[field],
    );
    if (input) {
      input.source = new RunbookStepInputSource("constant", newValue);
      notifyRunbookUpdate(true);
    }

    this.setState({
      selectedInputs: {
        ...this.state.selectedInputs,
        [field]: newValue,
      },
    });
  };

  updateSelectedProject = (newValue, notifyRunbookUpdate) => {
    const input = this.props.activeNode.extras.runbookNode.parameterInputs.find(
      ip => ip.name === fieldInputMap["project"],
    );

    if (input) {
      input.source = new RunbookStepInputSource("constant", newValue);
    }

    newValue &&
      this.props.getJiraProjectsIssueFields(newValue) &&
      this.props.getProjectsIssueList(newValue) &&
      this.selectIssue("", true, true);

    this.setState({
      selectedInputs: {
        ...this.state.selectedInputs,
        project: newValue,
        issueType: "",
        description: "",
        summary: "",
        reporter: "",
        priority: "",
        id: "",
        createdOn: "",
        issueKey: "",
        transition: "",
      },
    });

    notifyRunbookUpdate(true);
  };

  selectIssue = (selectedIssue, resetTransition, updateStore) => {
    const input = this.props.activeNode.extras.runbookNode.parameterInputs.find(
      ip => ip.name === fieldInputMap["issueKey"],
    );
    if (input) {
      input.source = new RunbookStepInputSource("constant", selectedIssue);
      updateStore && this.props.notifyRunbookUpdate(true);
    }

    if (resetTransition) {
      const transitionInput = this.props.activeNode.extras.runbookNode.parameterInputs.find(
        ip => ip.name === fieldInputMap["transition"],
      );

      if (transitionInput) {
        transitionInput.source = new RunbookStepInputSource("constant", "");
        updateStore && this.props.notifyRunbookUpdate(true);
      }
    }

    this.setState(
      {
        selectedInputs: {
          ...this.state.selectedInputs,
          issueKey: selectedIssue,
          transition: resetTransition
            ? ""
            : this.state.selectedInputs.transition,
        },
        showIssueDetails: !!selectedIssue ? true : false,
      },
      () => {
        !!selectedIssue && this.props.getProjectsIssueDetail(selectedIssue);
      },
    );
  };

  render() {
    let project = this.state.selectedInputs.project;
    let issueKey = this.state.selectedInputs.issueKey;
    return (
      <TabCollection
        active="input"
        activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
        inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
        contentTop={50}
        tabs={["input", "output", "details"]}
      >
        <Tab title="Input" name="input">
          <TabContent>
            <SnippetHeader
              snippetDef={this.props.activeNode.extras.runbookNode.snippetDef}
            />
            {!!issueKey && (
              <JiraIssueDetails
                selectedIssue={this.props.jiraDetails.selectedIssue}
              />
            )}
            <Accordion isExpanded={true} useArrow={true}>
              <AccordionLabel className="editor-accordion-label mt-10-px">
                Required Inputs
              </AccordionLabel>
              <JiraRequiredFields
                project={project}
                issueKey={issueKey}
                issues={this.props.jiraDetails.issues}
                projectsList={this.props.jiraDetails.projects}
                updateSelectedProject={this.updateSelectedProject}
                selectIssue={this.selectIssue}
              />
            </Accordion>
            {!!issueKey && (
              <Accordion isExpanded={true} useArrow={true}>
                <JiraOptionalFields
                  showIssueDetails={this.state.showIssueDetails}
                  updateInputFieldValue={this.updateInputFieldValue}
                  description={this.state.selectedInputs.description}
                  summary={this.state.selectedInputs.summary}
                  transition={this.state.selectedInputs.transition}
                  transitionsList={this.props.jiraDetails.transitions}
                  priority={this.state.selectedInputs.priority}
                  prioritiesList={this.props.jiraDetails.priorities}
                />
              </Accordion>
            )}
          </TabContent>
        </Tab>
        <Tab title="Output" name="output">
          <TabContent>
            <Output />
          </TabContent>
        </Tab>
        <Tab title="Details" name="details">
          <TabContent></TabContent>
        </Tab>
      </TabCollection>
    );
  }
}

const mapState = state => ({
  jiraDetails: state.snippetsReducer.jiraDetails,
  loaderFlag: state.globalLoaderReducer.loaderFlag,
});

const mapDispatch = dispatch => ({
  getJiraProjectsIssueFields: input =>
    dispatch(getJiraProjectsIssueFields(input)),
  getProjectsIssueDetail: input => dispatch(getProjectsIssueDetail(input)),
  getProjectsIssueList: input => dispatch(getProjectsIssueList(input)),
  clearSelectedIssue: () => dispatch(clearSelectedIssue),
  hideGlobalLoader: () => dispatch(hideGlobalLoader()),
  showGlobalLoader: () => dispatch(showGlobalLoader()),
});

export default connect(mapState, mapDispatch)(JiraExistingIssuePanel);
