import React from "react";
import { Consumer } from "../../../runbook-editor-lib/runbook-editor.context";
import showVersionsIcon from "@assets/images/icons/icon-versions.svg";
import ReactTooltip from "react-tooltip";

export default class Versions extends React.Component {
  clickAction = (activeRunbookVersion, setActivePanel) => {
    if (activeRunbookVersion !== "Draft") {
      setActivePanel("versions", "Versions");
    }
  };

  render() {
    return (
      <Consumer>
        {({ setActivePanel, activePanel, activeRunbookVersion }) => (
          <div
            data-tut="reactour__versions"
            className={`editor-menu-item ${
              activePanel === "versions"
                ? "editor-menu-item-active"
                : "editor-menu-item-inactive"
            }`}
            onClick={() =>
              this.clickAction(activeRunbookVersion, setActivePanel)
            }
          >
            <ReactTooltip
              id="manage-versions"
              place="right"
              effect="solid"
              type="light"
            />
            <img
              src={showVersionsIcon}
              alt="showVersionsIcon"
              className="editor-menu-item-icon editor-menu-item-icon-image"
              width="18"
              height="18"
              data-for="manage-versions"
              data-tip="Manage Versions"
            />
          </div>
        )}
      </Consumer>
    );
  }
}
