import moment from "moment";
import {
  getFriendlyDateTime,
  getFriendlyDate,
  getFriendlyDateWithHour,
  getFriendlyDateRoundMinutes,
  getFriendlyDateWithQuarterHour,
} from "@lib/utils";

export const getExecutionsStats = data => {
  let statistics = {
    succeeded: 0,
    pending: 0,
    failed: 0,
    cancelled: 0,
    waiting: 0,
    inProgress: 0,
    timedOut: 0,
    total: data.length,
  };
  data.forEach(item => {
    const status = item.AutomationExecutionStatus.toLowerCase();
    switch (status) {
      case "pending":
        statistics.pending++;
        break;

      case "success":
        statistics.succeeded++;
        break;

      case "failed":
        statistics.failed++;
        break;

      case "cancelled":
        statistics.cancelled++;
        break;

      case "waiting":
        statistics.waiting++;
        break;

      case "inprogress":
      case "in progress":
        statistics.inProgress++;
        break;

      case "timedout":
        statistics.timedOut++;
        break;

      default:
        break;
    }
  });
  return statistics;
};

export const executionsFilterData = (data, filter) => {
  let afterDate, hours;
  switch (filter) {
    case "1 hour":
      hours = 60 * 60 * 1000;
      afterDate = Date.now() - hours;
      return _filter(getFriendlyDateTime(afterDate), data);

    case "3 hours":
      hours = 60 * 60 * 1000 * 3;
      afterDate = Date.now() - hours;
      return _filter(getFriendlyDateTime(afterDate), data);

    case "1 day":
      hours = 60 * 60 * 1000 * 24;
      afterDate = Date.now() - hours;
      return _filter(getFriendlyDateTime(afterDate), data);

    case "7 days":
      afterDate = getFriendlyDateTime(
        new Date(new Date().setDate(new Date().getDate() - 7)),
      );
      return _filter(afterDate, data);

    case "30 days":
      afterDate = getFriendlyDateTime(
        new Date(new Date().setDate(new Date().getDate() - 30)),
      );
      return _filter(afterDate, data);

    default:
      return data;
  }
};

const _filter = (afterDate, data) => {
  if (!data) {
    return;
  }
  let filtered = {
    cancelled: [],
    succeeded: [],
    failed: [],
    all: [],
  };
  const now = getFriendlyDateTime(Date.now());
  data.forEach(item => {
    const thisDate = getFriendlyDateTime(item.ExecutionEndTime);
    if (thisDate >= afterDate && !thisDate.includes("NaN") && thisDate < now) {
      filtered = _addFilteredItem(item, filtered);
    }
  });
  return filtered;
};

const _addFilteredItem = (item, filtered) => {
  const status = item.AutomationExecutionStatus;
  const timeSeriesItem = {
    date: getFriendlyDateTime(item.ExecutionEndTime),
    value: 1,
    group: item.AutomationExecutionStatus,
  };
  filtered.all.push(timeSeriesItem);
  switch (status) {
    case "Failed":
      filtered.failed.push(item);
      break;

    case "Success":
      filtered.succeeded.push(item);
      break;

    case "Cancelled":
      filtered.cancelled.push(item);
      break;

    default:
      break;
  }
  return filtered;
};

export const timeSeriesValues = (timeSeriesArray, increment) => {
  switch (increment) {
    case "1 hour":
      return timeSeriesByMinutes(timeSeriesArray);

    case "3 hours":
      return timeSeriesByMinutes(timeSeriesArray);

    case "1 day":
      return timeSeriesByQuarterHour(timeSeriesArray);

    case "7 days":
      return timeSeriesByHours(timeSeriesArray);

    case "30 days":
      return timeSeriersByDays(timeSeriesArray);

    default:
      return timeSeriesArray;
  }
};

const timeSeriesByMinutes = timeSeriesArray => {
  let arrayOfKeys,
    keyValuePair = {};
  let timeSeriesArrayValues = [];
  arrayOfKeys = timeSeriesArray
    .map(item => getFriendlyDateRoundMinutes(item.date))
    .sort((a, b) => a < b);
  [...new Set(arrayOfKeys)].forEach(item => {
    keyValuePair[item] = 0;
  });
  Object.keys(keyValuePair).forEach(key => {
    timeSeriesArrayValues.push({
      date: getFriendlyDateRoundMinutes(key),
      value: arrayOfKeys.filter(item => getMinutes(key) === getMinutes(item))
        .length,
    });
  });
  return timeSeriesArrayValues;
};

const timeSeriesByQuarterHour = timeSeriesArray => {
  let arrayOfKeys,
    keyValuePair = {};
  let timeSeriesArrayValues = [];
  arrayOfKeys = timeSeriesArray
    .map(item => getFriendlyDateWithQuarterHour(item.date))
    .sort((a, b) => a < b);
  [...new Set(arrayOfKeys)].forEach(item => {
    keyValuePair[item] = 0;
  });
  Object.keys(keyValuePair).forEach(key => {
    timeSeriesArrayValues.push({
      date: getFriendlyDateWithQuarterHour(key + ":00") + ":00",
      value: arrayOfKeys.filter(item => getMinutes(key) === getMinutes(item))
        .length,
    });
  });
  return timeSeriesArrayValues;
};

const timeSeriesByHours = timeSeriesArray => {
  let arrayOfKeys,
    keyValuePair = {};
  let timeSeriesArrayValues = [];
  arrayOfKeys = timeSeriesArray
    .map(item => getFriendlyDateWithHour(item.date))
    .sort((a, b) => a < b);
  [...new Set(arrayOfKeys)].forEach(item => {
    keyValuePair[item] = 0;
  });
  Object.keys(keyValuePair).forEach(key => {
    timeSeriesArrayValues.push({
      date: getFriendlyDateWithHour(key + ":00") + ":00",
      value: arrayOfKeys.filter(
        item => getHour(key + ":00") === getHour(item + ":00"),
      ).length,
    });
  });
  return timeSeriesArrayValues;
};

const timeSeriersByDays = timeSeriesArray => {
  let arrayOfKeys,
    keyValuePair = {};
  let timeSeriesArrayValues = [];
  arrayOfKeys = timeSeriesArray
    .map(item => getFriendlyDate(item.date))
    .sort((a, b) => a < b);
  [...new Set(arrayOfKeys)].forEach(item => {
    keyValuePair[item] = 0;
  });
  Object.keys(keyValuePair).forEach(key => {
    timeSeriesArrayValues.push({
      date: getFriendlyDate(key) + " 12:00",
      value: arrayOfKeys.filter(item => getDate(key) === getDate(item)).length,
    });
  });
  return timeSeriesArrayValues;
};

const getMinutes = date => {
  const _date = new Date(date);
  return parseInt(_date.getMinutes());
};

const getHour = date => {
  const _date = new Date(date);
  return parseInt(_date.getHours());
};

const getDate = date => {
  const _date = new Date(date);
  return _date.getDate();
};

/**
 * Get cut off date for data filtering.
 * @param {string} timeOption : Options selected by user in filter.
 * @returns {moment} : cutoff date.
 */
export const getCutoffDateBasedOnFilter = timeOption => {
  let currentDate = moment();
  switch (timeOption) {
    case "1 hour":
      currentDate.add(-1, "h");
      break;
    case "3 hours":
      currentDate.add(-3, "h");
      break;
    case "7 days":
      currentDate.add(-7, "d");
      break;
    case "30 days":
      currentDate.add(-30, "d");
      break;
    default:
      currentDate.add(-1, "d");
  }
  return currentDate;
};
