import axios from "axios";
import Bugsnag from "@bugsnag/js";
import { store } from "@redux/store";
import {
  filterSystemTags,
  filterTriggerTags,
  getRunbookTags,
  isNull,
} from "@lib/utils";
import { getApiServerUrl, replaceAllSpacesWithHyphen } from "@lib/utils";
import { FTNotification } from "@components/ui";
import { authLogout } from "@components/authentication/authHelper";

// Global loader actions
import {
  requestSent,
  responseReceived,
  requestError,
} from "@redux/actions/globalLoader.actions";

const config = require("./../../config/config.json");
const { dispatch } = store;

// Configure Axios Intercepter
axios.interceptors.request.use(
  config => {
    // Dispatch request sent
    dispatch(requestSent());
    return config;
  },
  error => {
    // Dispatch error
    dispatch(requestError());
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  config => {
    // Dispatch response received
    dispatch(responseReceived());
    return config;
  },
  error => {
    // Dispatch error
    dispatch(requestError());
    return Promise.reject(error);
  },
);

export default class Api {
  static async getSnippets(type = "All") {
    const snippets = await Api._getData(`snippets?type=${type}`);
    return snippets;
  }

  static async getSlackChannelList() {
    return await Api._getData("connectors/slack/channels");
  }

  static async getSplunkOnCallTeams() {
    return await Api._getData("connectors/splunk-on-call/team-policies");
  }

  static async getHumioRepositories() {
    return await Api._getData("connectors/humio/repositories");
  }

  static async getHumioAlerts(repository) {
    return await Api._getData(
      `connectors/humio/alerts?repository=${repository}`,
    );
  }

  static async fetchSplunkOnCallUsers() {
    return await Api._getData("connectors/splunk-on-call/users");
  }

  static async fetchSplunkOnCallPolicies() {
    return await Api._getData("connectors/splunk-on-call/policies");
  }

  static getJiraDetails() {
    return Api._getData("connectors/jira/projects");
  }

  static getJiraTransitions(selectedIssue) {
    return Api._getData(
      `/connectors/jira/transitions?issue_key=${selectedIssue}`,
    );
  }

  static async getJiraProjectsIssueFields(projectKey) {
    return await Api._getData(
      `connectors/jira/issue-fields?project_key=${projectKey}`,
    );
  }

  static async getJiraProjectsIssueList(projectKey) {
    return await Api._getData(
      `connectors/jira/issues?project_key=${projectKey}`,
    );
  }

  static async getProjectsIssueDetail(issueId) {
    return await Api._getData(`connectors/jira/issue?issue_key=${issueId}`);
  }

  static async validateTwilioToken(payload) {
    return await Api._postData(`connectors/twilio/verify`, payload);
  }

  static async validateJenkinsSetup(payload) {
    return await Api._postData(`connectors/jenkins/verify`, payload);
  }

  static async fetchJenkinsJobList() {
    return await Api._getData(`connectors/jenkins/jobs`);
  }

  static async getSendersNumber() {
    return await Api._getData(`connectors/twilio/sender_numbers`);
  }

  static async getSnippetDetails(snippetId, version = "2.0.0") {
    return await Api._getData(`snippets/${snippetId}?version=${version}`, {
      blockNotification: true,
    });
  }

  /* this is the runbooks with details, but no "Content" */
  static async getRunbookDetails(runbookId, version = null) {
    return new Promise(async (success, reject) => {
      try {
        const data = await Api._getData(
          `workflows/${runbookId}/info` +
            (!isNull(version) ? `?version=${version}` : ``),
        );
        success(data);
      } catch (e) {
        reject(e);
      }
    });
  }

  /* individual runbook with content */
  static async getRunbookContent(runbookId, version) {
    let endpoint;

    endpoint = !isNull(version)
      ? `workflows/${runbookId}/content?version=${version}`
      : `workflows/${runbookId}/content`;

    return new Promise(async (r, reject) => {
      try {
        const contentData = await Api._getData(endpoint);
        const detailData = await Api.getRunbookDetails(runbookId, version);
        const data = Object.assign(contentData, detailData, {
          Description: contentData.Description,
        });
        r(data);
      } catch (e) {
        reject(e);
      }
    });
  }

  static async getRunbooks(owner = "Self") {
    return await Api._getData(`workflows?owner=${owner}`);
  }

  static async getRunbookVersion(runbookId, version) {
    let endpoint = `workflows/${runbookId}/content`;
    if (!isNull(version)) {
      endpoint += `?version=${version}`;
    }
    return await Api._getData(endpoint);
  }

  static async getExecutions(workflow) {
    return await Api._getData(
      `executions?limit=${config.maxExecutions}` +
        (workflow ? `&workflow=${workflow}` : ``),
    );
  }

  static async getRunbookExecution(executionId) {
    return await Api._getData(`executions/${executionId}`);
  }

  static async cancelRunbookExecution(executionId) {
    return await Api._putData(`executions/${executionId}?action=cancel`);
  }

  static async executeRunbook(runbookId, parameters, version) {
    let endpoint = `executions/${runbookId}`;
    if (!isNull(version)) endpoint += `?version=${version}`;
    return await Api._postData(endpoint, parameters);
  }

  static async executeResumeStep(
    executionId,
    stepName,
    action = "resume",
    connectionId,
  ) {
    const payload = {
      StepName: stepName,
      connection_id: connectionId,
    };
    let endpoint = `executions/${executionId}?action=resume`;
    return await Api._putData(endpoint, payload);
  }

  /**
   * Saves a new runbook
   * @param {string} name the name of the runbook
   * @param {string[]} tags an array of user supplied tags
   * @param {json} ssm the SSM document defining the runbook
   */
  static async saveNewRunbook(
    activeRunbook,
    ssm,
    connectorList,
    trigger,
    snippetList,
  ) {
    ssm.description = activeRunbook.Description;

    const [connectorTags, snippetTags] = getRunbookTags(
      connectorList,
      snippetList,
    );

    const runbook = {
      Name: activeRunbook.Name,
      Tags: filterSystemTags(activeRunbook.Tags)
        .concat(connectorTags)
        .concat(snippetTags)
        .concat(trigger ? filterTriggerTags(trigger) : []),
      Content: ssm,
    };
    let endpoint = "workflows";

    return await Api._postData(endpoint, runbook);
  }

  static async updateRunbook(
    activeRunbook,
    ssm,
    connectorList,
    trigger,
    snippetList,
  ) {
    let endpoint = `workflows/${activeRunbook.Name}`;

    const [connectorTags, snippetTags] = getRunbookTags(
      connectorList,
      snippetList,
    );

    const runbook = {
      Tags: filterSystemTags(activeRunbook.Tags)
        .concat(connectorTags)
        .concat(snippetTags)
        .concat(trigger ? filterTriggerTags(trigger) : []),
      Content: ssm,
    };

    return await Api._putData(endpoint, runbook);
  }

  static async updateRunbookTags(activeRunbook, tags, version) {
    let endpoint = `workflows/${activeRunbook.Name}/tags?version=${version}`;
    return await Api._putData(endpoint, tags);
  }

  static async fetchRunbookTags(runbookName) {
    let endpoint = `workflows/${runbookName}/tags`;
    return await Api._getData(endpoint);
  }

  static async createDuplicateRunbook(runbookName, baseRunbookName) {
    const formattedRunbookName = replaceAllSpacesWithHyphen(runbookName);
    let endpoint = `workflows/${baseRunbookName}/duplicate`;
    return await Api._postData(endpoint, {
      NewName: formattedRunbookName,
    });
  }

  static async deleteRunbook(name, version = null) {
    let endpoint = "workflows/" + name;
    if (version) {
      endpoint += `?version=${version}`;
    }
    return new Promise(r => {
      const data = Api._deleteData(endpoint);
      return r(data);
    });
  }

  static async updateDefaultVersion(name, version) {
    if (!version || !name) return Promise.reject({});
    let endpoint = `workflows/${name}/info`;
    return await Api._putData(endpoint, { version: version });
  }

  static async scheduleRunbook(runbook, json) {
    let endpoint = `schedules/${runbook}`;
    return await Api._postData(endpoint, json);
  }

  static async enableDisableSchedule(runbook, version, json) {
    let endpoint = `schedules/${runbook}?version=${version}`;
    return await Api._putData(endpoint, json);
  }

  static async getSchedules(runbook = null, version = null) {
    let endpoint = `schedules`;
    if (runbook) {
      endpoint += `/${runbook}`;
    }
    if (version) {
      endpoint += `?version=${version}`;
    }
    return await Api._getData(endpoint);
  }

  static async deleteSchedule(runbook, version, rule_name) {
    let endpoint = `schedules/${runbook}?version=${version}`;
    return await Api._deleteData(endpoint, {}, { rule: rule_name });
  }

  static async getAWSServices() {
    return await Api._getData(`aws/`);
  }

  static async getAWSServiceOperations(service) {
    return await Api._getData(`aws/${service}`);
  }

  static async getAWSServiceOperationDetails(service, operation) {
    return await Api._getData(`aws/${service}/${operation}`);
  }

  static async fetchAlertPayload(connector) {
    return await Api._getData(`connectors/alert-payload/${connector}`);
  }

  static async getDatadogMonitorList() {
    return await Api._getData(`connectors/datadog`);
  }

  static async getDatadogMonitor(id) {
    return await Api._getData(`connectors/datadog/${id}`);
  }

  static async getDatadogWebhookPayload() {
    return await Api._getData(`connectors/datadog/webhook-payload`);
  }

  /* CONTAINER */
  static async fetchRepositories(alias = "") {
    return await Api._getData(`resources/ecr/repositories?alias=${alias}`);
  }

  static async fetchImages(alias = "", repository = "") {
    return await Api._getData(
      `resources/ecr/images?repository=${repository}&alias=${alias}`,
    );
  }

  /* TERRAFORM */
  static async getTerraformWorkspaces() {
    // @TODO get the org name from settings
    return await Api._getData(`connectors/terraform/workspaces`);
  }

  static async fetchTerraformVariables(workspaceId) {
    return await Api._getData(
      `connectors/terraform/workspace-vars?workspace=${workspaceId}`,
    );
  }

  /* INSTANA */
  static async fetchInstanaEntityTypes() {
    return await Api._getData(`connectors/instana/entity-types`);
  }

  static async fetchInstanaEntityNames(entityType) {
    return await Api._getData(
      `connectors/instana/entity-names?entity_type=${entityType}`,
    );
  }

  /* Resources List */
  static async getResourcesList() {
    let list = {};
    let scanner = await Api._getData(`resources/scanner`).catch(error => {
      Bugsnag.notify(error);
    });
    if (scanner) {
      Object.assign(list, scanner);
    }
    let targets = store.getState().getStartedReducer.userDefaultAccounts;
    if (!targets?.length) {
      targets = await Api.getAWSTargetAccounts().catch(e => {
        Bugsnag.notify(e);
      });
    }
    if (targets) {
      Object.assign(list, { targets: targets });
    }
    return list;
  }
  static async fetchEc2Regions() {
    return await Api._getData(`resources/ec2/regions`);
  }
  /* Settings Page (Connector Configuration) APIs BEGIN */

  static async getSettingsConnectors() {
    return await Api._getData(`settings/connectors`);
  }

  static async getConnector(connector) {
    return await Api._getData(`settings/connectors/${connector}`);
  }

  static async getSettingsTriggers() {
    return await Api._getData(`settings/triggers`);
  }

  static async getConnectorSchema(nodeName) {
    return await Api._getData(`settings/schema/${nodeName}`);
  }

  static async getConnectorActions(nodeName) {
    return await Api._getData(`connectors/actions/${nodeName}`);
  }

  static async getConnectorConfigDetails(nodeName) {
    return await Api._getData(`settings/${nodeName}`);
  }

  static async saveConnectorSchema(jsonData, connector) {
    let endpoint = `settings/${connector}`;
    return await Api._postData(endpoint, jsonData);
  }

  static async updateConnectorSchema(jsonData, connector) {
    let endpoint = `settings/${connector}`;
    return await Api._patchData(endpoint, jsonData);
  }

  static async getJiraOauth(connector, url) {
    let endpoint = `settings/${connector}/oauth`;
    const accountUrl = `${url}${url[url.length - 1] === "/" ? "" : "/"}`;
    return await Api._getData(`${endpoint}?jira_url=${accountUrl}`);
  }

  static async requestJiraPermission(connector, url, token, secret) {
    let endpoint = `settings/${connector}/approval`;
    const accountUrl = `${url}${url[url.length - 1] === "/" ? "" : "/"}`;
    return await Api._putData(
      `${endpoint}?jira_url=${accountUrl}&req_token=${token}&req_token_secret=${secret}`,
    );
  }

  static async removeIntegration(connector, alias = null) {
    let endpoint = `settings/${connector}/remove_integration`;
    if (alias) endpoint = `${endpoint}?alias=${alias}`;
    return Api._deleteData(endpoint);
  }

  static async removeTerraformCLIIntegration(connector) {
    let endpoint = `settings/${connector}`;
    return Api._deleteData(endpoint);
  }

  static async setStatus(connector, flag, status) {
    let endpoint = `settings/${connector}/${status}?action=${flag}`;
    return await Api._putData(`${endpoint}`);
  }

  static async fetchSlackChannels(connector) {
    let endpoint = `connectors/${connector}/integrated_channels`;
    return await Api._getData(`${endpoint}`);
  }

  static async getConnectorIntegrationUrl(connector) {
    let endpoint = `settings/${connector}/integration_url`;
    return await Api._getData(`${endpoint}`);
  }

  static async fetchTerraformCLIModules() {
    return await Api._getData(`settings/terraform_cli`);
  }

  static async getTerraformCLIModules(alias) {
    return await Api._getData(`settings/terraform_cli?alias=${alias}`);
  }

  static async editTerraformCLIModule(module, alias, data) {
    let endpoint = `settings/terraform_cli/${module}?alias=${alias}`;
    return await Api._putData(endpoint, data);
  }

  static async deleteTerraformCLIModule(module, alias) {
    let endpoint = `settings/terraform_cli/${module}?alias=${alias}`;
    return Api._deleteData(endpoint);
  }

  static async fetchCloudFormationTemplates() {
    return await Api._getData(`settings/cloudformation/templates`);
  }

  static async createCloudFormationTemplate(data) {
    let endpoint = `settings/cloudformation/templates`;
    return await Api._postData(endpoint, data);
  }

  static async editCloudFormationTemplate(template, data) {
    let endpoint = `settings/cloudformation/templates/${template}`;
    return await Api._putData(endpoint, data);
  }

  static async deleteCloudFormationTemplate(template) {
    let endpoint = `settings/cloudformation/templates/${template}`;
    return Api._deleteData(endpoint);
  }

  static async completeOAuth(connector, payload) {
    let endpoint = `settings/${connector}/finish_oauth${payload}`;
    return Api._postData(endpoint);
  }

  /* Settings Page (Connector Configuration) APIs END */

  /* AWS Settings Page APIs BEGIN */
  static async getAWSTargetAccounts() {
    return await Api._getData(`targets`);
  }

  static async saveNewAWSTargetAccount(jsonData) {
    let endpoint = "targets";
    return await Api._postData(endpoint, jsonData);
  }

  static async deleteAWSTargetAccount(item) {
    let endpoint = "targets/" + item.alias;
    return Api._deleteData(endpoint);
  }

  static async updateAWSTargetAccount(item, alias) {
    let endpoint = "targets/" + alias;
    return Api._patchData(endpoint, item);
  }

  static async getAWSTargetAccountInfo(alias) {
    let endpoint = "targets/" + alias;
    return await Api._getData(endpoint);
  }
  /* AWS Settings Page APIs END */

  /* API Keys APIs BEGIN */
  static async getAPIKeys() {
    return await Api._getData(`settings/apikeys`);
  }

  static async createAPIKey(name) {
    let endpoint = `settings/apikeys/${name}`;
    return await Api._postData(endpoint, {});
  }

  static async deleteAPIKey(name) {
    let endpoint = `settings/apikeys/${name}`;
    return Api._deleteData(endpoint);
  }
  /* API Keys APIs END */

  /* GIT Repos APIs BEGIN */

  static async getGitRepoList() {
    let endpoint = `settings/git`;
    return Api._getData(endpoint);
  }

  static async getGitRepoIds() {
    let endpoint = `settings/git/repo_ids`;
    return Api._getData(endpoint);
  }

  static async saveGitRepo(payload, repoId) {
    let endpoint = `settings/git/${repoId}`;
    return Api._putData(endpoint, payload);
  }

  static async deleteGitRepo(repoId) {
    let endpoint = `settings/git/${repoId}`;
    return Api._deleteData(endpoint);
  }
  /* GIT Repos APIs END */

  /* SSH Targets APIs BEGIN */

  static fetchSSHTargets() {
    let endpoint = `settings/ssh_targets`;
    return Api._getData(endpoint);
  }

  static addSSHTarget(payload) {
    let endpoint = `settings/ssh_targets`;
    return Api._postData(endpoint, payload);
  }

  static updateSSHTarget(payload, targetName) {
    let endpoint = `settings/ssh_targets/${targetName}`;
    return Api._putData(endpoint, payload);
  }

  static deleteSSHTarget(targetName) {
    let endpoint = `settings/ssh_targets/${targetName}`;
    return Api._deleteData(endpoint);
  }

  /*S3 Bucket APIs BEGIN */

  static async fetchAllS3BucketList(alias) {
    let endpoint = `resources/s3/buckets?alias=${alias}`;
    return Api._getData(endpoint);
  }

  static async fetchSelectedS3BucketList(alias) {
    let endpoint = `settings/s3_buckets?alias=${alias}`;
    return Api._getData(endpoint);
  }

  static async addS3Bucket(alias, s3BucketName, payload) {
    let endpoint = `settings/s3_buckets/${s3BucketName}?alias=${alias}`;
    return Api._putData(endpoint, payload);
  }

  static async removeS3Bucket(alias, s3BucketName) {
    let endpoint = `settings/s3_buckets/${s3BucketName}?alias=${alias}`;
    return Api._deleteData(endpoint);
  }

  /*S3 Bucket APIs END */

  /* Account Plan Info APIs BEGIN */

  static async fetchAccountPlanInfo() {
    let endpoint = `settings/quotas`;
    return Api._getData(endpoint);
  }

  /* Account Plan Info APIs END */

  /** AWS Cloudwatch */

  static async fetchTargetAccountAlarmsList(alias) {
    let endpoint = `connectors/cloudwatch/list-alarms?alias=${alias}`;
    return await Api._getData(endpoint, { blockNotification: true });
  }

  static async saveCloudwatchIntegration(alias) {
    let endpoint = `connectors/cloudwatch/save-integration?alias=${alias}`;
    return await Api._postData(endpoint, {});
  }

  static async fetchCloudwatchTargetAccounts() {
    let endpoint = `connectors/cloudwatch/list-configured-account`;
    return await Api._getData(endpoint);
  }

  /** AWS Cloudwatch END */

  /** PagerDuty BEGINS */

  static async fetchPagerDutyServiceList() {
    let endpoint = `connectors/pagerduty/services`;
    return await Api._getData(endpoint);
  }

  /** PagerDuty END */

  /** Opsgenie BEGINS */

  static async fetchOpsgenieServiceList() {
    let endpoint = `connectors/opsgenie/services`;
    return await Api._getData(endpoint);
  }

  /** Opsgenie END */

  /* EKS Actions BEGIN */

  static async fetchEksActionResource(alias) {
    let endpoint = `resources/eks/clusters?alias=${alias}`;
    return await Api._getData(endpoint);
  }

  static async fetchEksActionList() {
    let endpoint = `settings/eks`;
    return await Api._getData(endpoint);
  }

  static async saveEksActionRole(alias, payload) {
    let endpoint = `settings/eks?alias=${alias}`;
    return await Api._putData(endpoint, payload);
  }

  static async deleteEksActionRole(alias = "", name) {
    let endpoint = `settings/eks/${name}?alias=${alias}`;
    return Api._deleteData(endpoint);
  }
  /* EKS Actions END */

  /* String Trasnformers BEGIN */

  static async getStringTransformersOperations() {
    let endpoint = "connectors/string_transformers";
    return Api._getData(endpoint);
  }

  /* String Trasnformers END */

  /* Task Management BEGIN */

  static async createTask(data) {
    let endpoint = `tasks`;
    return Api._postData(endpoint, data);
  }

  static async fetchTaskList() {
    let endpoint = `tasks`;
    return Api._getData(endpoint);
  }

  static async fetchTask(id) {
    let endpoint = `tasks/${id}`;
    return Api._getData(endpoint);
  }

  static async patchTask(id, data) {
    let endpoint = `tasks/${id}`;
    return Api._patchData(endpoint, data);
  }

  static async deleteTask(id) {
    let endpoint = `tasks/${id}`;
    return Api._deleteData(endpoint);
  }

  static async fetchTaskExecutions(taskId) {
    let endpoint = `executions/?task_id=${taskId}`;
    return Api._getData(endpoint);
  }

  static async fetchTaskSchedules(taskId) {
    let endpoint = `schedules/?task_id=${taskId}`;
    return Api._getData(endpoint);
  }

  static async fetchTaskTypesList() {
    let endpoint = `tasks/types`;
    return Api._getData(endpoint);
  }

  static async createTaskType(taskTypeData) {
    let endpoint = `tasks/types`;
    return Api._postData(endpoint, taskTypeData);
  }

  static async updateTaskType(taskId, taskTypeData) {
    let endpoint = `tasks/types/${taskId}`;
    return Api._putData(endpoint, taskTypeData);
  }

  static async deleteTaskType(taskId) {
    let endpoint = `tasks/types/${taskId}`;
    return Api._deleteData(endpoint);
  }

  static async fetchTaskTypeAssignment(connector) {
    let endpoint = `tasks/types?service=${connector}`;
    return Api._getData(endpoint);
  }

  static async deleteTaskTypeAssignment(taskId, payload) {
    let endpoint = `tasks/types/${taskId}/assignment`;
    return Api._deleteData(endpoint, {}, payload);
  }

  static async createTaskTypeAssignment(taskId, payload) {
    let endpoint = `tasks/types/${taskId}`;
    return Api._patchData(endpoint, payload);
  }

  /* Task Management END */

  /* Collaboration BEGIN*/

  static async fetchWorkspaceUsersList() {
    let endpoint = `connectors/slack/users`;
    return Api._getData(endpoint);
  }

  static async fetchCollaborationDetails() {
    let endpoint = `incident/collaboration`;
    return Api._getData(endpoint);
  }

  static async editCollaborationDetails(data) {
    let endpoint = `incident/collaboration`;
    return await Api._putData(endpoint, data);
  }

  static async fetchIncidentNotificationDetails() {
    let endpoint = `incident/notification`;
    return Api._getData(endpoint);
  }

  static async updateIncidentNotificationDetails(data) {
    let endpoint = `incident/notification`;
    return Api._putData(endpoint, data);
  }

  static async createJiraIssue(data) {
    let endpoint = `incident/collaboration/jira-create-issue`;
    return Api._postData(endpoint, data);
  }

  static async createSlackChannel(data) {
    let endpoint = `incident/collaboration/slack-create-channel`;
    return Api._postData(endpoint, data);
  }

  static async createZoomLink(data) {
    let endpoint = `incident/collaboration/zoom-create-room`;
    return Api._postData(endpoint, data);
  }

  /* Collaboration END */

  /* DATA MOCKING */
  static async getMockExecutions() {
    return new Promise(r => {
      setTimeout(() => {
        const data = require("@assets/data/executions.json");
        return r(data);
      }, 1000);
    });
  }

  static async getMockSnippets() {
    return new Promise(r => {
      const data = require("@assets/data/snippets.json");
      return r(data);
    });
  }

  static async getMockSnippet(snippetId) {
    return new Promise(r => {
      const data = require("@assets/data/snippets/" + snippetId + ".json");
      return r(data);
    });
  }

  static async showErrorNotifications({
    title = "Error",
    message = "Something went wrong, please try again later",
    timeout = 2,
    blockNotification = false,
  }) {
    !blockNotification &&
      FTNotification.error({
        title,
        message,
        timeout,
      });
  }

  static async _getData(
    endpoint = "",
    errorNotificationConfig = {},
    externalApi = "",
  ) {
    const api = new Api();
    const apiEndpoint = externalApi || api.getEndpoint(endpoint);
    axios.defaults.headers.common["Authorization"] = `${api.Token}`;

    return await axios
      .get(apiEndpoint)
      .then(response => {
        if (response.status === 204) return;

        return typeof response.data === "string"
          ? JSON.parse(response.data)
          : response.data;
      })
      .catch(err => {
        errorNotificationConfig = api.handleError(err, errorNotificationConfig);
        Api.showErrorNotifications(errorNotificationConfig);
        // error code 403 is an authentication error
        return Promise.reject({ ERROR: err });
      });
  }

  static async _postData(
    endpoint,
    postData = {},
    errorNotificationConfig = {},
  ) {
    const api = new Api();
    const apiEndpoint = api.getEndpoint(endpoint);
    axios.defaults.headers.common["Authorization"] = `${api.Token}`;
    return await axios
      .post(apiEndpoint, postData)
      .then(response => {
        if (response.status === 204) return;

        return typeof response.data === "string"
          ? JSON.parse(response.data)
          : response.data;
      })
      .catch(err => {
        errorNotificationConfig = api.handleError(err, errorNotificationConfig);
        Api.showErrorNotifications(errorNotificationConfig);
        return { ERROR: err };
      });
  }

  static async _putData(endpoint, putData = {}, errorNotificationConfig = {}) {
    const api = new Api();
    const apiEndpoint = api.getEndpoint(endpoint);
    axios.defaults.headers.common["Authorization"] = `${api.Token}`;
    return await axios
      .put(apiEndpoint, putData)
      .then(response => {
        if (response.status === 204) return;

        return typeof response.data === "string"
          ? JSON.parse(response.data)
          : response.data;
      })
      .catch(err => {
        errorNotificationConfig = api.handleError(err, errorNotificationConfig);
        Api.showErrorNotifications(errorNotificationConfig);
        return Promise.reject({
          ERROR: err,
        });
      });
  }

  static async _deleteData(
    endpoint,
    errorNotificationConfig = {},
    payload = {},
  ) {
    const api = new Api();
    const apiEndpoint = api.getEndpoint(endpoint);
    axios.defaults.headers.common["Authorization"] = `${api.Token}`;
    return await axios
      .delete(apiEndpoint, { data: payload })
      .then(response => {
        if (response.status === 204) return;

        if (response.data.constructor.name === "Object") {
          return Promise.resolve(response.data);
        } else {
          return Promise.resolve(JSON.parse(response.data));
        }
      })
      .catch(err => {
        errorNotificationConfig = api.handleError(err, errorNotificationConfig);
        Api.showErrorNotifications(errorNotificationConfig);
        Bugsnag.notify(err);
        return Promise.reject({ ERROR: err });
      });
  }

  static async _patchData(
    endpoint,
    patchData = {},
    errorNotificationConfig = {},
  ) {
    const api = new Api();
    const apiEndpoint = api.getEndpoint(endpoint);
    axios.defaults.headers.common["Authorization"] = `${api.Token}`;
    return await axios
      .patch(apiEndpoint, patchData)
      .then(response => response)
      .catch(err => {
        errorNotificationConfig = api.handleError(err, errorNotificationConfig);
        Api.showErrorNotifications(errorNotificationConfig);
        return Promise.reject({
          ERROR: err,
        });
      });
  }

  handleError = (err, errorNotificationConfig) => {
    const api = new Api();
    if (err.response) {
      return api.handleResponseError(err, errorNotificationConfig);
    } else if (err.request) {
      // client never received a response, or request never left
      // idToken has expired
      return api.handleRequestError(errorNotificationConfig);
    }
    return errorNotificationConfig;
  };

  handleResponseError = (err, errorNotificationConfig) => {
    let error = err.response;
    /**
     * Check if the token has expired
     */
    if (error?.status === 401) {
      authLogout(store.dispatch);
      errorNotificationConfig["message"] =
        "Your session token has expired. Please log in again.";
    } else {
      error =
        typeof error?.data === "string" ? JSON.parse(error?.data) : error?.data;
      errorNotificationConfig["message"] =
        error?.message || "Oops! Something went wrong!";
    }
    return errorNotificationConfig;
  };

  handleRequestError = errorNotificationConfig => {
    errorNotificationConfig["message"] = "Oops! Something went wrong!";
    return errorNotificationConfig;
  };

  getErrorCode(error) {
    const regEx = new RegExp(/\d+/, "g");
    const matches = error.toString().match(regEx);
    if (matches) {
      return matches[0];
    }
  }

  getEndpoint(endpoint) {
    const apiServer = getApiServerUrl();
    return `${apiServer}/${endpoint}`;
  }

  get Token() {
    return store.getState().userReducer.idToken;
  }

  static async getAppConfig() {
    let host = window.location.hostname;
    let appConfigUrl =
      process.env.NODE_ENV === "development" ||
      process.env.REACT_APP_isCIBuild === "true"
        ? `${config.configApiServer}/${config.configApiVersion}/`
        : `https://config.${host}/${config.configApiVersion}/`;
    return await axios
      .get(appConfigUrl)
      .then(response =>
        typeof response.data === "string"
          ? JSON.parse(response.data)
          : response.data,
      )
      .catch(e => {
        return Promise.reject({
          ERROR: e,
        });
      });
  }
}
