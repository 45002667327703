import { ConditionalStep } from "./conditionalstep";
import { SnippetStep } from "./snippetstep";
import { SnippetType, ControlNames } from "./strings";
import { Step } from "../ssm/steps";
import { WaitStep, NeurOpsStatusStep } from "./waitstep";
import { ActionNodeStep } from "./actionnodestep";
import { DatadogConnectorStep } from "./DatadogConnectorStep";
import { PauseStep } from "../ssm/pausestep";
import { SleepStep } from "../ssm/sleepstep";
import SlackConnectorStep from "./slackConnectorStep";
import JiraConnectorStep from "./jiraConnectorStep";
import { LoopStep } from "./loopstep";
import { WaitForResourceStep } from "./waitforresourcestep";
import InstanaStep from "./InstanaStep";
import JSONPathStep from "./JSONPathStep";
import ApprovalNodeStep from "./ApprovalNodeStep";
import { SnippetStepNew } from "../SnippetDefNew/snippetstep";
import TriggerStep from "./TriggerStep";

export function stepFromControlNodeData(data, trigger = null) {
  let step;
  // Set the runbookNode to be an Action object from our model.
  if (["Action_Node", "AWS"].includes(data.extras.name)) {
    return ActionNodeStep.createNewActionNode(data.extras, "ec2");
  }
  if (data.extras.name === "Wait_For_Resource") {
    return WaitForResourceStep.create(data.extras, "ec2", "describe_instances");
  }
  if (data.extras.version === "2.0.0") {
    return SnippetStepNew.fromSnippetDefinition(data.extras, trigger);
  }
  switch (data.extras.type) {
    case SnippetType.CONTROL:
      step = stepFromControlDef(data.extras, trigger);
      break;

    case SnippetType.SNIPPET:
      const customSnippets = [
        ControlNames.InstanaAlert,
        ControlNames.DatadogAlert,
        ControlNames.SlackConnector,
        ControlNames.JiraUpdateConnector,
        //ControlNames.CloudwatchAlert,
      ];
      if (customSnippets.includes(data.extras?.name)) {
        step = stepFromControlDef(data.extras);
      } else if (data.extras?.name === "Webhook") {
        step = TriggerStep.fromSnippetDefinition(data.extras);
      } else {
        step = SnippetStep.fromSnippetDefinition(data.extras);
      }

      break;
    default:
      break;
  }
  return step;
}

function stepFromControlDef(snippetDef, trigger = null) {
  switch (snippetDef.name) {
    case ControlNames.Conditional:
    case ControlNames.CheckELBResult:
    case ControlNames.CheckExeStatus:
      return ConditionalStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.Wait:
      return WaitStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.Success:
    case ControlNames.Fail:
    case ControlNames.Stop:
      return NeurOpsStatusStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.Pause:
      return PauseStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.DatadogAlert:
      return DatadogConnectorStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.SlackConnector:
      return SlackConnectorStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.JiraConnector:
    case ControlNames.JiraUpdateConnector:
      return JiraConnectorStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.LoopForEach:
      return LoopStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.InstanaAlert:
      return InstanaStep.fromControlNodeDef(snippetDef);
    case ControlNames.JSONPathStep:
      return JSONPathStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.Approval:
      return ApprovalNodeStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.SplunkOnCallResolveIncidents:
    case ControlNames.SplunkOnCallRerouteIncidents:
      return SnippetStep.fromSnippetDefinition(snippetDef);
    case ControlNames.Manual:
      const snipName = `${trigger ? trigger?.snippet_name : snippetDef.name}_1`;
      return new SleepStep(
        { ...snippetDef.content, name: snipName },
        snippetDef,
        trigger,
      );
    default:
      const name = `${snippetDef.name}_${
        snippetDef.insertionOrder
          ? snippetDef.insertionOrder
          : Math.floor(1000 * Math.random())
      }`;
      const ssm = { ...snippetDef.content, name: name };
      return new Step(ssm);
  }
}
