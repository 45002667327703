import React from "react";
import { setTitle, toProperCase } from "@lib/utils";
import { TabCollection, Tab, TabContent } from "@components/ui";
import {
  SettingsPanelContent,
  SettingsPanelHeader,
} from "./settings-panel-components";
import "./SettingsPanel.scss";
import TaskTypes from "./TaskType/TaskTypes";
import { withRouter } from "react-router";
import Notifications from "./Notifications/Notifications";
import Collaboration from "./Collaboration/Collaboration";

class SettingsPanel extends React.Component {
  constructor(props) {
    super(props);
    setTitle("Fylamynt | Settings");
    this.state = {
      subTab: toProperCase(this.props.match.params.subTab),
      selectedTab: toProperCase(this.props.match.params.subTab),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.subTab !== this.props.match.params.subTab) {
      this.setState({ subTab: toProperCase(this.props.match.params.subTab) });
    }
  }

  setSelectedTab = selectedTab => {
    this.setState({
      selectedTab,
    });
  };

  render() {
    const { selectedTab, subTab } = this.state;
    return (
      <div className="d-flex flex-column settings">
        <SettingsPanelHeader
          heading="Settings"
          breadCrumbText={`Settings/${subTab}`}
          isBreadCrumb={true}
        />
        <TabCollection
          active="integrations"
          className="mt-40-px w-100"
          contentTop={100}
          tabs={[
            "integrations",
            "incident-types",
            "notifications",
            "collaboration",
          ]}
          tabChange={tab => {
            this.setSelectedTab(tab);
          }}
        >
          <Tab title="Integrations" name="integrations">
            <TabContent>
              {selectedTab === "integrations" && <SettingsPanelContent />}
            </TabContent>
          </Tab>
          <Tab title="Incident Types" name="incident-types">
            <TabContent>
              {selectedTab === "incident-types" && <TaskTypes />}
            </TabContent>
          </Tab>
          <Tab title="Notifications" name="notifications">
            <TabContent>
              {selectedTab === "notifications" && <Notifications />}
            </TabContent>
          </Tab>
          <Tab title="Collaboration" name="collaboration">
            <TabContent>
              {selectedTab === "collaboration" && <Collaboration />}
            </TabContent>
          </Tab>
        </TabCollection>
      </div>
    );
  }
}

export default withRouter(SettingsPanel);
