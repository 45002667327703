import React from "react";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  TextArea,
} from "@components/ui";
import { isEmpty } from "@lib/utils";

export default class SettingsDescription extends React.PureComponent {
  state = {
    className: isEmpty(this.props.runbook.Description)
      ? `compact-text-area rule-input-error`
      : `compact-text-area`,
    value: this.props.runbook.Description,
    setFocus: isEmpty(this.props.runbook.Description),
  };

  onKeyUp = value => {
    const className =
      value.length === 0
        ? `compact-text-area rule-input-error`
        : `compact-text-area`;
    this.setState(
      {
        value,
        className,
      },
      () => this.updateDescription(),
    );
  };
  updateDescription = () => {
    this.props.updateRunbook("Description", this.state.value);
    this.props.updateRunbookDescription(this.state.value);
    this.props.notifyRunbookUpdate(true);
  };
  render() {
    return (
      <Accordion isExpanded={true} useArrow={true}>
        <AccordionLabel className="editor-accordion-label">
          Descripton
        </AccordionLabel>
        <AccordionSection>
          <div className="paragraph-editor">
            <TextArea
              value={this.state.value}
              onKeyUp={this.onKeyUp}
              className={this.state.className}
              focus={this.state.setFocus}
              style={{
                minWidth: "405px",
                width: "auto",
              }}
            ></TextArea>
          </div>
        </AccordionSection>
      </Accordion>
    );
  }
}
