import * as Yup from "yup";
import { isEmpty, checkQuotes } from "@lib/utils";

export const getValidationSchema = (
  type: string,
  list: Array<any>,
  isEdit: boolean,
) => {
  switch (type) {
    case "Filters":
      return Yup.object({
        inputValue: Yup.string()
          .required("Please enter a valid Value")
          .test("checkQuotes", "Please enter a valid Value", checkQuotes),
      });
    case "StringList":
      return Yup.object({
        inputValue: Yup.string()
          .required("Please enter a valid Input")
          .test("value", "Duplicate Input", inputValue => {
            return list.length
              ? isEmpty(list.find(value => value === inputValue))
              : true;
          })
          .test("checkQuotes", "Please enter a valid Input", checkQuotes),
      });
    case "Tags":
    case "StringMap":
      let validationSchemaObj = {
        inputValue: Yup.string()
          .required("Please enter a valid Value")
          .test("checkQuotes", "Please enter a valid Value", checkQuotes),
      };
      if (!isEdit) {
        validationSchemaObj["inputKey"] = Yup.string()
          .required("Please enter a valid Key")
          .test("key", "Duplicate Key", inputKey => {
            return list.length
              ? isEmpty(list.find(key => key === inputKey))
              : true;
          })
          .test("checkQuotes", "Please enter a valid Key", checkQuotes);
      }
      return Yup.object(validationSchemaObj);
  }
};
