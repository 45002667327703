import * as Yup from "yup";

export const getParsedRuntimeParameters = runtimeParams => {
  let parsedRuntimeParams = {};
  for (let key in runtimeParams) {
    try {
      parsedRuntimeParams[key] = JSON.parse(runtimeParams[key]);
    } catch (e) {
      parsedRuntimeParams[key] = runtimeParams[key];
    }
  }
  return parsedRuntimeParams;
};

export const getRuntimeParameterInitialValues = parameters => {
  let reqParams = {};
  parameters.forEach(param => {
    if (!["AutomationAssumeRole", "WorkflowSession"].includes(param.Name))
      reqParams[param.Name] = param.DefaultValue ? param.DefaultValue : "";
  });
  return reqParams;
};

export const getRuntimeParametersValidationSchema = requiredParameters => {
  let reqParams = {};
  requiredParameters
    .filter(p => !["AutomationAssumeRole", "WorkflowSession"].includes(p.Name))
    .forEach(param => {
      reqParams[param.Name] = Yup.string()
        .required(`${param.Name} is required`)
        .trim(`This field can't be empty`);
    });
  return Yup.object().shape(reqParams);
};
