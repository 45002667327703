import React from "react";
import { Modal, TextInput, Button, Form } from "@components/ui";
import { Consumer } from "../runbooks-lib/runbooks.context";
import { connect } from "react-redux";
import { createDuplicateRunbook } from "@redux/actions/runbook.action";
import { checkIfRunbookNameIsValid } from "@lib/utils";
import { replaceAllSpecialCharWithSpace } from "@lib/utils";

const getFooter = closeFunction => {
  return (
    <div className="modal-buttons-footer">
      <Button
        text="Cancel"
        buttonStyle="secondary"
        size="none"
        onClick={closeFunction}
        style={{ width: "50%" }}
      />
      <Button
        text="Create Workflow"
        buttonStyle="primary"
        style={{ width: "50%" }}
        type={"submit"}
      />
    </div>
  );
};

class RunbookDuplicate extends React.Component {
  state = {
    formIsSubmitting: false,
    submissionError: "",
    success: false,
    runbookName: "",
    errorMessage: "",
  };

  checkForm = parameters => {
    if (!parameters) {
      return;
    }
    const userProvidedRunbookName = parameters["new-runbook"].trim
      ? parameters["new-runbook"].trim()
      : parameters["new-runbook"];

    const runbookNameValidityObj = checkIfRunbookNameIsValid(
      this.props.runbooks,
      userProvidedRunbookName,
    );

    if (runbookNameValidityObj.isValidName) {
      this.setState(
        {
          formIsSubmitting: true,
          runbookName: userProvidedRunbookName,
          errorMessage: "",
        },
        () =>
          this.props.createDuplicateRunbook(
            this.state.runbookName,
            this.props.baseRunbookName,
          ),
      );
    } else {
      this.setState({
        formIsSubmitting: false,
        runbookName: userProvidedRunbookName,
        errorMessage: runbookNameValidityObj.errorMessage,
      });
    }
  };

  render() {
    return (
      <Consumer>
        {() => (
          <Form
            method="post"
            action="./runbook-editor"
            onSubmit={this.checkForm}
            formIsSubmitting={this.state.formIsSubmitting}
            submissionError={this.state.errorMessage}
            id="create-form"
          >
            <Modal
              onClose={this.props.toggleDuplicateRunbookModal}
              title="Duplicate Workflow"
              showClose={true}
              footer={getFooter(this.props.toggleDuplicateRunbookModal)}
            >
              <div className="mt-10-px">
                <div>
                  This action will copy the{" "}
                  <b>
                    "
                    {replaceAllSpecialCharWithSpace(this.props.baseRunbookName)}
                    "
                  </b>{" "}
                  Workflow's Default Version as a new Workflow.
                </div>
                <div>Please name the new Workflow, below:</div>
              </div>
              <div className="mt-10-px">
                <TextInput
                  name="new-runbook"
                  label="Workflow Name"
                  labelClassName="label"
                  labelPosition="top"
                  minLength={3}
                  value={this.state.runbookName}
                />
              </div>
              <div className="form-error">{this.state.errorMessage}</div>
            </Modal>
          </Form>
        )}
      </Consumer>
    );
  }
}
const mapState = ({ runbooksReducer }) => ({
  runbooks: runbooksReducer.runbookList,
});
const mapDispatch = dispatch => ({
  createDuplicateRunbook: (runbookName, baseRunbookName) =>
    dispatch(createDuplicateRunbook(runbookName, baseRunbookName)),
});
export default connect(mapState, mapDispatch)(RunbookDuplicate);
