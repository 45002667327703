import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { Input, Output, Details } from ".";
import { SERVICES_NEEDED } from "@redux/types";
import {
  fetchServices,
  setSelectedService,
  setSelectedOperation,
} from "@redux/actions/action-node.action";
import {
  hideGlobalLoader,
  showGlobalLoader,
} from "@redux/actions/globalLoader.actions";
import {
  getResourcesList,
  fetchRegionList,
} from "@redux/actions/runbook.action";

const ActionNodePanel = props => {
  const [state, setState] = useState(null);

  const runbookEditorContext = useContext(RunbookEditorContext);
  const {
    activeNode,
    setActiveActionNode,
    activeActionNode,
    notifyRunbookUpdate,
  } = runbookEditorContext;

  const setOutputs = outputs => {
    setState(outputs);
  };

  const resetOperation = () => {
    activeActionNode.resetActionNode();
  };

  useEffect(() => {
    return () => {
      if (!props.loaderFlag) {
        props.hideGlobalLoader();
      }
    };
  }, [props]);

  return (
    <TabCollection
      active="input"
      activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
      inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
      contentTop={50}
      tabs={["input", "output", "details"]}
    >
      <Tab title="Input" name="input">
        <TabContent>
          <Input
            activeNode={activeNode}
            runbookNode={activeNode.extras.runbookNode}
            operations={props.operations}
            services={props.services}
            servicesNeeded={props.servicesNeeded}
            setServicesNeeded={props.setServicesNeeded}
            setActiveActionNode={setActiveActionNode}
            activeActionNode={activeActionNode}
            fetchServices={props.fetchServices}
            fetchOperations={props.fetchOperations}
            isFetchingServices={props.isFetchingServices}
            isFetchingOperations={props.isFetchingOperations}
            setSelectedService={props.setSelectedService}
            setSelectedOperation={props.setSelectedOperation}
            selectedOperation={props.selectedOperation}
            selectedService={props.selectedService}
            isFetchingOperationDetails={props.isFetchingOperationDetails}
            operationDetails={props.operationDetails}
            notifyRunbookUpdate={notifyRunbookUpdate}
            setOutputs={setOutputs}
            resetOperation={resetOperation}
            rerenderEditor={props.rerenderEditor}
            serviceDocLink={props.serviceDocLink}
            awsDocLink={props.awsDocLink}
            aliasList={props.aliasList}
            getResourcesList={props.getResourcesList}
            fetchRegionList={props.fetchRegionList}
            regionList={props.regionList}
          />
        </TabContent>
      </Tab>
      <Tab title="Output" name="output">
        <TabContent>
          <Output outputs={state} />
        </TabContent>
      </Tab>
      <Tab title="Details" name="details">
        <TabContent>
          <Details />
        </TabContent>
      </Tab>
    </TabCollection>
  );
};

const mapState = ({
  actionNodeReducer,
  globalLoaderReducer,
  runbookReducer,
}) => {
  return {
    operations: actionNodeReducer.operations,
    services: actionNodeReducer.services,
    serviceDocLink: actionNodeReducer.serviceDocLink,
    awsDocLink: actionNodeReducer.awsDocLink,
    servicesNeeded: actionNodeReducer.servicesNeeded,
    isFetchingOperations: actionNodeReducer.isFetchingOperations,
    isFetchingServices: actionNodeReducer.isFetchingServices,
    selectedService: actionNodeReducer.selectedService,
    selectedOperation: actionNodeReducer.selectedOperation,
    operationDetails: actionNodeReducer.operationDetails,
    isFetchingOperationDetails: actionNodeReducer.isFetchingOperationDetails,
    loaderFlag: globalLoaderReducer.loaderFlag,
    aliasList: runbookReducer.resourcesList.targets,
    regionList: runbookReducer.regionList,
  };
};

const mapDispatch = dispatch => ({
  setServicesNeeded: servicesNeeded =>
    dispatch({
      type: SERVICES_NEEDED,
      payload: servicesNeeded,
    }),
  fetchServices: () => dispatch(fetchServices()),
  setSelectedService: selectedService =>
    dispatch(setSelectedService(selectedService)),
  setSelectedOperation: selectedOperation =>
    dispatch(setSelectedOperation(selectedOperation)),
  hideGlobalLoader: () => dispatch(hideGlobalLoader()),
  showGlobalLoader: () => dispatch(showGlobalLoader()),
  getResourcesList: () => dispatch(getResourcesList()),
  fetchRegionList: () => dispatch(fetchRegionList()),
});

export default connect(mapState, mapDispatch)(ActionNodePanel);
