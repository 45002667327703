import React from "react";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import Inputs from "./helpers/Inputs";
import Output from "../output";
import Details from "../details";

type TriggerPanelProps = { activeNode: any };

const TriggerPanel: React.FC<TriggerPanelProps> = ({ activeNode }) => {
  return (
    <TabCollection
      active="input"
      activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
      inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
      contentTop={50}
      tabs={["input", "output", "details"]}
    >
      <Tab title="Input" name="input">
        <TabContent>
          <Inputs activeNode={activeNode} />
        </TabContent>
      </Tab>
      <Tab title="Output" name="output">
        <TabContent>
          <Output />
        </TabContent>
      </Tab>
      <Tab title="Details" name="details">
        <TabContent>
          <Details />
        </TabContent>
      </Tab>
    </TabCollection>
  );
};

export default TriggerPanel;
