import React from "react";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import RuleDataSource from "./rule-data-source";
import RuleComparisonOperator from "./rule-comparison-operator";
import RuleComparisonValue from "./rule-comparison-value";
import RuleNextStep from "./rule-next-step";

export default props => (
  <Consumer>
    {({ activeNode, notifyRunbookUpdate }) => (
      <div className="rule-list-div">
        <div className="rule-list-header">
          <div className="rule-list-icon" />
          <div>RULE DETAILS</div>
          <div className="rule-list-close" onClick={props.toggle}>
            &times;
          </div>
        </div>
        <RuleDataSource
          choice={props.choice}
          optionFilterId={props.optionFilterId}
          toggleInputOption={props.toggleInputOption}
          updateSelectedVariableType={props.updateSelectedVariableType}
        />

        <RuleComparisonOperator
          choice={props.choice}
          notifyRunbookUpdate={notifyRunbookUpdate}
          selectedVariableType={props.selectedVariableType}
          activeNode={activeNode}
        />
        <RuleComparisonValue
          choice={props.choice}
          notifyRunbookUpdate={notifyRunbookUpdate}
          activeNode={activeNode}
        />
        <RuleNextStep
          choice={props.choice}
          activeNode={activeNode}
          rerenderEditor={props.rerenderEditor}
          addLinkToStep={props.addLinkToStep}
          removeLinkToStep={props.removeLinkToStep}
        />
      </div>
    )}
  </Consumer>
);
