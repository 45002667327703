import React from "react";
import "./Inputs.scss";
import SnippetHeader from "../../../../../runbook-editor-lib/SnippetDefNew/utils/SnippetHeader/SnippetHeader";
// import ExternalLinkIcon from "@assets/images/icons/icon-external__blue.svg";

type InputsProps = { activeNode: any };

const Inputs: React.FC<InputsProps> = ({ activeNode }) => {
  const { snippetDef } = activeNode.extras.runbookNode;
  const trigger = activeNode.extras.runbookNode?.trigger || null;
  return (
    <>
      <SnippetHeader
        snippetDef={snippetDef}
        displayName={trigger?.display_name}
        iconName={trigger?.name}
      />
      <div className="ti">
        <div className="ti-wrap">
          {trigger && trigger.name !== "manual" ? (
            <>
              <span className="ti-wrap-header">
                About Integration Triggers:
              </span>
              <span className="ti-wrap-container">
                The workflow will run automatically if certain conditions are
                met. These conditions are configured within the Incident
                Management feature set.
              </span>
              <span className="ti-wrap-container">
                The alert output structure for the selected integration can be
                consumed and passed to downstream steps in the workflow. Preview
                the alert output in the 'Output' tab above.
              </span>
            </>
          ) : (
            <>
              <span className="ti-wrap-header">About Manual Triggers:</span>
              <span className="ti-wrap-container">
                The workflow will run on manual input. You could either go to:
              </span>
              <ul>
                <li>Workflow details page and click on 'Run Now', or;</li>
                <li>
                  Add the workflow to a incident through incident management
                  feature set and click on 'Run Now', or;
                </li>
                <li>Schedule the workflow to trigger on a custom schedule.</li>
              </ul>
            </>
          )}
          {/* <a
            href={`https://docs.fylamynt.com/integrations`}
            target="_blank"
            rel="noopener noreferrer"
            className="learn"
          >
            <span>Learn more</span>
            <img src={ExternalLinkIcon} alt="external-link"></img>
          </a> */}
        </div>
      </div>
    </>
  );
};
export default Inputs;
