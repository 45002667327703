import React from "react";
import { OverflowMenu } from "@components/ui";
import MaskedField from "./utils/MaskedField";

export default (onRemove, onEdit, showSSHKey, showAccessToken) => {
  return [
    {
      name: "Repo Id",
      selector: "name",
      grow: 1,
    },
    {
      name: "SSH Key",
      cell: row => {
        return (
          <MaskedField row={row} showKey={showSSHKey} value={row["ssh-key"]} />
        );
      },
      grow: 2,
    },
    {
      name: "Access Token",
      cell: row => {
        return (
          <MaskedField
            row={row}
            showKey={showAccessToken}
            value={row["access_token"]}
          />
        );
      },
      grow: 2,
    },
    {
      name: "URL",
      selector: "url",
      grow: 2,
    },
    {
      name: "",
      selector: "options",
      cell: row => (
        <OverflowMenu>
          <div className="button-grey" onMouseDown={() => onEdit(row)}>
            Edit
          </div>
          <div className="button-delete" onMouseDown={() => onRemove(row)}>
            Delete
          </div>
        </OverflowMenu>
      ),
      grow: 1,
    },
  ];
};
