import * as Yup from "yup";

export const getValidationSchema = (isAuthenticationRequired: boolean) => {
  if (isAuthenticationRequired) {
    return Yup.object({
      activationCode: Yup.string()
        .required("Please enter confirmation code")
        .matches(/^[0-9]+$/, "Please enter confirmation code")
        .min(6, "Please enter confirmation code")
        .max(6, "Please enter confirmation code"),
    });
  }
  return Yup.object({
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Please enter a valid email address"),
    password: Yup.string().required("Please enter a password"),
  });
};
