import React from "react";
import { Consumer } from "../../../../runbook-editor-lib/runbook-editor.context";

export default props => {
  return (
    <Consumer>
      {({ removeTagFromRunbook }) => (
        <div className="oval-item">
          <div className="oval-item-inner-wrap">
            <div>{props.text}</div>
            <div
              className="oval-x"
              onClick={() => {
                removeTagFromRunbook(props.text);
              }}
            />
          </div>
        </div>
      )}
    </Consumer>
  );
};
