import React from "react";
import invisibleIcon from "@assets/images/icons/icon-eye__not-allowed.svg";

type MaskedFieldProps = {
  row: Object;
  value: string;
  showKey: (row: Object) => void;
};

const MaskedField: React.FC<MaskedFieldProps> = ({ value, row, showKey }) => {
  return (
    <div className="api-key-value">
      {value ? (
        <>
          <input type="password" value={value} readOnly></input>
          <img
            src={invisibleIcon}
            alt="expandIcon"
            width="15"
            height="15"
            className="gr-copy-icon"
            onClick={() => {
              showKey(row);
            }}
          />
        </>
      ) : (
        <span>--</span>
      )}
    </div>
  );
};

export default MaskedField;
