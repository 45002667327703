import React from "react";
import { Consumer } from "./accordion.context";

export default props => (
  <Consumer>
    {({ isExpanded, useArrow, setExpanded, arrowSide }) => (
      <div
        className={`accordion-label-container ${props.className}`}
        onClick={setExpanded}
      >
        <div className="accordion-label">
          {useArrow && (
            <div
              className={`accordion-arrow accordion-arrow-${arrowSide} ${
                isExpanded ? "accordion-arrow-up" : "accordion-arrow-down"
              } `}
            />
          )}
          <div className={`accordion-label-arrow-${arrowSide}`}>
            {props.children}
          </div>
        </div>
      </div>
    )}
  </Consumer>
);
