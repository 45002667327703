import React, { useState } from "react";
import copyIcon from "@assets/images/icons/icon-copy-new.svg";
import IconEye from "@components/ui/icons/icon-eye";
import IconEyeCancelled from "@components/ui/icons/icon-eye-cancelled";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./Password.scss";
import Header from "../Layouts/Header/Header";
import ReactTooltip from "react-tooltip";

type PasswordProps = {
  title?: string;
  value: string;
  readOnly: boolean;
  description?: string;
  helpText?: string;
  isRequired?: boolean;
  showPriorityError?: any;
  onChange: (value) => void;
};

const Password: React.FC<PasswordProps> = ({
  title = "",
  value = "",
  readOnly = false,
  description = "",
  helpText,
  isRequired = false,
  showPriorityError,
  onChange,
}) => {
  const [inputType, setInputType] = useState("password");
  const [password, setPassword] = useState(value);
  const showError = isRequired && !password;
  return (
    <div className="input-wrap">
      <Header title={title} description={description} helpText={helpText} />
      <div className="d-flex">
        <input
          defaultValue={password}
          id={"input-password"}
          className={`input-wrap-input-container ${inputType === "password"} ${
            showPriorityError !== null
              ? showPriorityError && "dti-input-error"
              : showError && "dti-input-error"
          }`}
          maxLength={256}
          required={true}
          type={inputType}
          readOnly={readOnly}
          onChange={e => {
            const input = e.target.value;
            setPassword(input);
            onChange(input);
          }}
          autoComplete="new-password"
        />

        {inputType === "text" ? (
          <button
            type="button"
            className="input-wrap-icon input-wrap-icon__eye"
          >
            <IconEye
              className="eye-icon"
              handleClick={() => setInputType("password")}
              height="14"
              width="14"
            />
          </button>
        ) : (
          <button
            type="button"
            className="input-wrap-icon input-wrap-icon__eye"
          >
            <IconEyeCancelled
              className="eye-icon"
              handleClick={() => setInputType("text")}
              height="14"
              width="14"
            />
          </button>
        )}
        <div data-for="passTip" data-tip>
          <CopyToClipboard text={password}>
            <button
              type="button"
              className="input-wrap-icon input-wrap-icon__copy"
            >
              <img
                src={copyIcon}
                alt="invisibleIcon"
                className="mr-5-px pointer"
                width="14"
                height="14"
              />
            </button>
          </CopyToClipboard>
        </div>
        <ReactTooltip
          id="passTip"
          place="bottom"
          effect="solid"
          type="light"
          event="click"
          isCapture={true}
          afterShow={() => {
            setTimeout(ReactTooltip.hide, 1000);
          }}
          className="ttDimension"
        >
          Copied!
        </ReactTooltip>
      </div>
      {showPriorityError !== null
        ? showPriorityError && (
            <div className="input-feedback">Password is Required</div>
          )
        : showError && (
            <div className="input-feedback">Password is Required</div>
          )}
    </div>
  );
};

export default Password;
