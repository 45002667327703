import { InvokeLambdaStep } from "../ssm/invokelambdastep";
import { RunbookStepOutput } from "../ssm/nodeinputoutput";

import { StepTypes } from "./strings";
import { readLambdaPayload, ActionNodeStep } from "./actionnodestep";
import { SSMActionNames } from "../ssm/strings";

export default class InstanaStep extends InvokeLambdaStep {
  static fromControlNodeDef(controlDef) {
    const payload = {
      alert_type: "",
      entity_type: "",
      entity_name: "",
      event_id: "",
      alert_body: "{{ InstanaAlertBody }}",
      workflow_session: "{{ WorkflowSession}} ",
    };

    const { lambda_arn } = controlDef.content;
    const stepJSON = {
      name: "Instana_Alert",
      action: SSMActionNames.INVOKE_LAMBDA,
      inputs: {
        FunctionName: lambda_arn,
        Payload: JSON.stringify(payload),
      },
      maxAttempts: 1,
      onFailure: "Abort",
      editorNodeId: controlDef.editorNodeId,
    };
    return new InstanaStep(stepJSON, controlDef);
  }

  constructor(stepJSON, controlDef) {
    super(stepJSON);
    console.log("stepJSON->", controlDef);
    this.stepType = StepTypes.InstanaAlertConnectorStep;
    this.editable = true;
    this.parameterInputs = [];

    this.lambdaPayload = readLambdaPayload(stepJSON);
    this.alertType = this.lambdaPayload.alert_type;
    this.entityType = this.lambdaPayload.entity_type;
    this.entityName = this.lambdaPayload.entity_name;
    this.eventId = this.lambdaPayload.event_id;

    this.stepType = StepTypes.InstanaAlertConnectorStep;
    this.actionNodeDef = controlDef;
    this.lambda_arn = controlDef ? controlDef.content.lambda_arn : "";
    this.snippetDef = controlDef;
  }

  setEventId = type => {
    if (type !== this.eventId) {
      this.eventId = type;
      // TODO: remove old outputs
      this.updateOutputs(type);
    }
  };

  setAlertType = type => {
    if (type !== this.alertType) {
      this.alertType = type;
      // TODO: remove old outputs
      this.updateOutputs(type);
    }
  };

  setEntityType = type => {
    if (type !== this.entityType) {
      this.entityType = type;
      // TODO: remove old outputs
      this.updateOutputs(type);
    }
  };

  setEntityName = name => {
    if (name !== this.entityName) {
      this.entityName = name;
      // TODO: remove old outputs
      this.updateOutputs(name);
    }
  };

  updateOutputs = type => {
    let outputSpec = this.actionNodeDef?.static_outputs?.[type];
    let name;
    const outputs = [];

    if (outputSpec) {
      for (name of Object.keys(outputSpec)) {
        const selector = `$.output.${name}`;
        const output = new RunbookStepOutput(
          this,
          name,
          outputSpec[name],
          selector,
        );
        outputs.push(output);
      }
      this.outputs = outputs;
    }
  };

  writeLambdaPayload() {
    const payload = {
      alert_type: this.alertType,
      entity_type: this.entityType,
      entity_name: this.entityName,
      event_id: this.eventId,
      alert_body: "{{ InstanaAlertBody }}",
      workflow_session: "{{ WorkflowSession}} ",
    };
    return JSON.stringify(payload);
  }

  readInputSources(runbook) {
    super.readInputSources(runbook);
    console.log({ parameterInputs: this.parameterInputs });
    const alertBodyInput = this.parameterInputs.find(
      input => input.name === "alert_body",
    );
    alertBodyInput.source.default = "{}";
    alertBodyInput.source.sourceValue.spec.ssm.default = "{}";
    alertBodyInput.source.sourceValue.isRequired = true;
    alertBodyInput.source.sourceValue.spec.description =
      "(Required - String) Stringified JSON of Instana alert received at the Webhook";

    alertBodyInput.source.sourceValue.spec.ssm.description =
      alertBodyInput.source.sourceValue.spec.description;
    runbook.ssmDoc.parameters.InstanaAlertBody =
      alertBodyInput.source.sourceValue;
  }

  toSSM = () => {
    const outputs = this.outputs
      ? this.outputs.filter(out => out.isConsumed()).map(out => out.toSSM())
      : [];

    return {
      action: "aws:invokeLambdaFunction",
      inputs: {
        FunctionName: this.lambda_arn,
        Payload: this.writeLambdaPayload(),
        ClientContext: ActionNodeStep._writeSnippetDetailsForContext(
          this.actionNodeDef,
        ),
      },
      isEnd: !this.nextStep,
      maxAttempts: 1,
      name: this.name,
      nextStep: this.nextStep,
      onFailure: "Abort",
      outputs,
    };
  };
}
