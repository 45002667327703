import React from "react";
import { getBezierPath } from "react-flow-renderer";

const arrowSize = { x: 2, y: 3 };

const ConnectionLine = ({ sourceX, sourceY, targetX, targetY }) => {
  const dAttr = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <g>
      <path fill="none" stroke="#6f6f6f" strokeWidth={2} d={dAttr} />
      <circle
        cx={targetX}
        cy={targetY}
        fill="#a07ff0"
        r={7.5}
        stroke="#f3f3f3"
        strokeWidth={2}
      />
      <polygon
        pointerEvents="none"
        fill="#f3f3f3"
        points={`
          ${targetX},${targetY + arrowSize.y / 2}
          ${targetX + arrowSize.x},${targetY - arrowSize.y / 2}
          ${targetX - arrowSize.x},${targetY - arrowSize.y / 2}
        `}
      ></polygon>
    </g>
  );
};
export default ConnectionLine;
