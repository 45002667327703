import React from "react";
import { OverflowMenu } from "@components/ui";
import { Consumer } from "@containers/Runbooks/runbooks-lib/runbooks.context";
import { isFylamyntWorkflow, getRedirectUrl } from "@lib/utils/index";
import { useHistory } from "react-router-dom";
import { RouteConstants } from "../../../../routes/Constants";

const duplicate = (props, callBack) => {
  callBack(props.runbook);
};

const deleteItem = (props, callBack) => {
  callBack(props.runbook);
};

export default props => {
  const history = useHistory();

  return (
    <Consumer>
      {({ setShowConfirm, openDuplicateRunbookModal }) => (
        <div className="runbook-item__overflow-menu">
          <OverflowMenu>
            <div
              onMouseDown={() =>
                history.push(
                  `${getRedirectUrl(RouteConstants.runbookDetails.defaultUrl, [
                    { key: "name", value: props.runbook.Name },
                  ])}`,
                )
              }
              className="button-grey"
            >
              View Details
            </div>
            <div
              onMouseDown={e => duplicate(props, openDuplicateRunbookModal)}
              className="button-grey"
            >
              Duplicate
            </div>
            {!isFylamyntWorkflow(props.runbook) && (
              <div
                onMouseDown={e => deleteItem(props, setShowConfirm)}
                className="button-delete"
              >
                Delete
              </div>
            )}
          </OverflowMenu>
        </div>
      )}
    </Consumer>
  );
};
