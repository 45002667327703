import React from "react";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { hasKeys } from "@lib/utils";
import SnippetHeader from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/SnippetHeader/SnippetHeader";
import ActionSnippetHeader from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/ActionSnippetHeader/ActionSnippetHeader";

export default () => (
  <Consumer>
    {({ activeNode }) => {
      const trigger = activeNode.extras.runbookNode?.trigger || null;
      return (
        <div>
          {activeNode.extras.runbookNode.hasOwnProperty("snippetDef") ? (
            <SnippetHeader
              snippetDef={activeNode.extras.runbookNode.snippetDef}
              displayName={trigger?.display_name}
              iconName={trigger?.name}
            />
          ) : (
            <ActionSnippetHeader runbookNode={activeNode.extras.runbookNode} />
          )}
          <Accordion isExpanded={true} useArrow={true}>
            <AccordionLabel className="editor-accordion-label">
              Description
            </AccordionLabel>
            <AccordionSection>
              <div className="editor-right-panel-details">
                {hasKeys(
                  activeNode,
                  "extras.runbookNode.snippetDef.description",
                ) && activeNode.extras.runbookNode.snippetDef.description}
              </div>
            </AccordionSection>
          </Accordion>
          <Accordion isExpanded={true} useArrow={true}>
            <AccordionLabel className="editor-accordion-label">
              Tags
            </AccordionLabel>
            <AccordionSection>
              <div className="editor-right-panel-details">
                {hasKeys(activeNode, "extras.runbookNode.snippetDef.tags") &&
                  activeNode.extras.runbookNode.snippetDef.tags.map(tag => (
                    <div key={tag}>{tag}</div>
                  ))}
              </div>
            </AccordionSection>
          </Accordion>
        </div>
      );
    }}
  </Consumer>
);
