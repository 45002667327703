import produce from "immer";
import {
  EKS_ACTIONS_FETCH_SUCCESS,
  EKS_ACTION_ROLE_DELETE_SUCCESS,
} from "@redux/types";

const INITIAL_STATE = {
  eksActionList: [],
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case EKS_ACTIONS_FETCH_SUCCESS:
        draft.eksActionList = action.payload;
        break;

      case EKS_ACTION_ROLE_DELETE_SUCCESS:
        draft.eksActionList = state.eksActionList.filter(
          val =>
            !(
              val.name === action.payload?.name &&
              val.alias === action.payload.alias
            ),
        );
        break;

      default:
        break;
    }
  });
