import React from "react";
import { TextArea } from "@components/ui";
import Filters from "@components/ui/InputTypes/Filters/Filters";
import Tags from "@components/ui/InputTypes/Tags/Tags";
import JSONEditor from "@components/ui/InputTypes/JSONEditor/JSONEditor";
import MapList from "@components/ui/InputTypes/MapList/MapList";
import StringMap from "@components/ui/InputTypes/StringMap/StringMap";
import StringList from "@components/ui/InputTypes/StringList/StringList";
import Password from "@components/ui/InputTypes/Password/Password";
import URL from "@components/ui/InputTypes/URL/URL";
import Boolean from "@components/ui/InputTypes/Boolean/Boolean";
import Number from "@components/ui/InputTypes/Number/Number";
import DateTime from "@components/ui/InputTypes/DateTime/DateTime";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { getParamInput } from "./Helper";
import DefaultTextInput from "./DefaultTextInput/DefaultTextInput";
import Header from "@components/ui/InputTypes/Layouts/Header/Header";
import { MultiSelect } from "carbon-components-react";
import { isEmpty } from "@lib/utils";
import { Parameter } from "../../ssm/parameters";

export default class SetValueNow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingData: false,
    };
  }

  updateOptionsData = async input => {
    if (isEmpty(input.api)) {
      return this.props.dropdownOptions[input.name];
    }
    this.setState({ fetchingData: true });
    const response = await this.props.fetchUpdatedOptions(input);
    this.setState({ fetchingData: false });
    return response;
  };

  getInputContent = () => {
    const {
      // key,
      input,
      updateValue,
      updateDropdownSelection,
      dropdownOptions,
      isRequired,
      showPriorityError,
      isDynamic,
    } = this.props;

    const {
      type,
      name,
      display_name,
      description,
      masked,
      copyable,
      input_option,
      allowed_pattern,
      help_text,
    } = input;

    const value =
      getParamInput(input.name, this.props.parameterInputs)?.source
        ?.sourceValue || "";
    let label = display_name || name;
    let isInvalidInput = isRequired && !value;

    switch (label) {
      case "Filters":
        return (
          <Filters
            filters={value || []}
            onChange={filters => updateValue(filters)}
            description={description}
            helpText={help_text}
          />
        );
      case "tags":
      case "Tags":
        return (
          <Tags
            title={label}
            tags={value || []}
            onChange={value => updateValue(value)}
            description={description}
            helpText={help_text}
          />
        );
      case "password":
      case "Password":
        return (
          <Password
            title={label}
            readOnly={false}
            value={value || ""}
            isRequired={isRequired}
            description={description}
            onChange={value => updateValue(value)}
            helpText={help_text}
            showPriorityError={showPriorityError}
          />
        );
      default:
        switch (type) {
          case "MapList":
            return (
              <MapList
                title={label}
                mapListArray={value || []}
                onChange={value => updateValue(value)}
                description={description}
                helpText={help_text}
              />
            );
          case "Text":
            let inputValue = value || "";
            return (
              <>
                <Header
                  title={label}
                  description={description}
                  helpText={help_text}
                />
                <div className="d-flex">
                  <TextArea
                    value={inputValue}
                    onKeyUp={value => updateValue(value)}
                    className={`compact-text-area p-10-px ${
                      showPriorityError !== null
                        ? showPriorityError
                          ? "text-area-error"
                          : ""
                        : !inputValue && isRequired
                        ? "text-area-error"
                        : ""
                    }`}
                  />
                </div>
              </>
            );
          case "StringMap":
            let stringMap = value || {};
            return (
              <StringMap
                title={label}
                description={description}
                helpText={help_text}
                stringMap={
                  stringMap && typeof stringMap === "string"
                    ? JSON.parse(stringMap)
                    : stringMap
                }
                onChange={value => {
                  updateValue(value);
                }}
              />
            );
          case "Map":
          case "Object":
            return (
              <JSONEditor
                value={
                  value && !(value instanceof Parameter)
                    ? JSON.stringify(value, undefined, 4)
                    : ""
                }
                onChange={value => {
                  updateValue(value);
                }}
                title={label}
                description={description}
                helpText={help_text}
              />
            );
          case "Boolean":
            return (
              <Boolean
                title={label}
                description={description}
                helpText={help_text}
                value={value !== null ? value : ""}
                isRequired={isRequired}
                onChange={value => {
                  updateValue(value);
                }}
                showPriorityError={showPriorityError}
              />
            );
          case "Integer":
            return (
              <Number
                title={label}
                helpText={help_text}
                description={description}
                value={value || null}
                onChange={value => {
                  updateValue(value);
                }}
                isRequired={isRequired}
                showPriorityError={showPriorityError}
              />
            );
          case "Decimal":
            return (
              <Number
                title={label}
                value={value || null}
                onChange={value => {
                  updateValue(value);
                }}
                isDecimal={true}
                isRequired={isRequired}
                description={description}
                helpText={help_text}
                showPriorityError={showPriorityError}
              />
            );
          case "URL":
            return (
              <URL
                title={label}
                value={value || ""}
                onChange={value => {
                  updateValue(value);
                }}
                description={description}
                helpText={help_text}
                isRequired={isRequired}
                allowedPattern={allowed_pattern}
                showPriorityError={showPriorityError}
              />
            );
          case "DateTime":
            return (
              <DateTime
                title={label}
                value={value || ""}
                onChange={value => updateValue(value)}
                description={description}
                helpText={help_text}
                isRequired={isRequired}
                showPriorityError={showPriorityError}
              />
            );
          case "StringList":
            if (input_option === "multiselect") {
              let selectedItems =
                dropdownOptions[input.name] &&
                Array.isArray(dropdownOptions[input.name]) &&
                dropdownOptions[input.name].reduce((acc, item) => {
                  if (value.includes(item.value)) {
                    acc.push(item);
                  }
                  return acc;
                }, []);

              return (
                <>
                  <Header title={label} description={description} />
                  <MultiSelect.Filterable
                    ariaLabel="MultiSelect"
                    default
                    id={label}
                    placeholder={`Search ${label}`}
                    onChange={({ selectedItems }) => {
                      updateDropdownSelection(selectedItems?.map(i => i.value));
                    }}
                    className={`multiselect-filterable ${
                      showPriorityError !== null
                        ? showPriorityError && "error"
                        : isRequired &&
                          ((Array.isArray(value) && value.length === 0) ||
                            !value)
                        ? "error"
                        : ""
                    }`}
                    initialSelectedItems={selectedItems}
                    items={dropdownOptions[input.name] || []}
                    label={label}
                    itemToString={item => (item ? item.label : "")}
                  />
                </>
              );
            }

            return (
              <StringList
                title={label}
                StringListArr={value || []}
                onChange={value => updateValue(value)}
                description={description}
                helpText={help_text}
                isRequired={isRequired}
                allowedPattern={allowed_pattern}
                showPriorityError={showPriorityError}
              />
            );

          default:
            switch (input_option) {
              case "textarea":
                let inputValue = value || "";
                return (
                  <>
                    <Header
                      title={label}
                      description={description}
                      helpText={help_text}
                    />
                    <div className="d-flex">
                      <TextArea
                        value={inputValue}
                        onKeyUp={value => updateValue(value)}
                        className={`compact-text-area p-10-px ${
                          showPriorityError !== null
                            ? showPriorityError && "text-area-error"
                            : !inputValue && isRequired
                            ? "text-area-error"
                            : ""
                        }`}
                      />
                    </div>
                  </>
                );

              case "singleselect":
              case "dropdown":
                const dropdownDisplayLabel =
                  (dropdownOptions[input.name] &&
                    dropdownOptions[input.name].find(o => o.value === value)
                      ?.label) ||
                  "Select from below";
                return (
                  <>
                    <Header
                      title={label}
                      description={description}
                      helpText={help_text}
                    />
                    <ReactSelect
                      id={input.name}
                      api={input.api}
                      name={input.name}
                      isDynamic={isDynamic}
                      value={{
                        value: (value && !(value instanceof Parameter)) || "",
                        label: dropdownDisplayLabel,
                      }}
                      handleChange={data => {
                        if (data === null) {
                          updateDropdownSelection("");
                        } else if (value !== data.value)
                          updateDropdownSelection(data.value);
                      }}
                      selectOptions={dropdownOptions[input.name]}
                      required={isRequired}
                      customControlClass="mt-2-px"
                      showPriorityError={showPriorityError}
                      fetchingData={this.state.fetchingData}
                      updateOptionsData={this.updateOptionsData}
                    />
                    {showPriorityError !== null
                      ? showPriorityError && (
                          <div className="input-feedback">
                            {input.display_name || input.name} is Required
                          </div>
                        )
                      : isRequired &&
                        !value && (
                          <div className="input-feedback">
                            {input.display_name || input.name} is Required
                          </div>
                        )}
                  </>
                );

              case "text":
              default:
                return (
                  <DefaultTextInput
                    fieldName={input.name}
                    label={label}
                    helpText={help_text}
                    copyable={copyable}
                    subText={description}
                    masked={masked}
                    placeholder={`Enter ${label}`}
                    fieldValue={value}
                    onChangeHandler={newValue => {
                      updateValue(newValue);
                    }}
                    showError={
                      showPriorityError !== null
                        ? showPriorityError
                        : isInvalidInput
                    }
                  />
                );
            }
        }
    }
  };
  render() {
    return this.getInputContent();
  }
}
