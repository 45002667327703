import React, { useState } from "react";
import TextInputView from "../Layouts/TextInputView/TextInputView";
import AddButton from "../Layouts/AddButton/AddButton";
import { ManageKeyValue } from "../Layouts/ManageKeyValue/ManageKeyValue";
import Header from "../Layouts/Header/Header";

type tagObj = { Key: ""; Value: "" };

type TagsProps = {
  title?: string;
  tags: Array<tagObj>;
  onChange: (value) => void;
  description?: string;
  helpText?: string;
};

const Tags: React.FC<TagsProps> = ({
  title = "Tags",
  tags,
  onChange,
  description = "",
  helpText,
}) => {
  const [tagList, setTagList] = useState(tags ? tags : []);
  const [isManageKeyValueOpen, setIsManageKeyValueOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  const toggleIsManageKeyValueOpen = () => {
    setIsManageKeyValueOpen(!isManageKeyValueOpen);
  };

  /**
   * Delete a tag from tagList
   * @param {object} tag - tag object to be deleted
   */
  const onDelete = tag => {
    let updatedTagList = tagList.filter(t => t.Key !== tag.Key);
    setTagList(updatedTagList);
    onChange(updatedTagList);
  };

  /**
   * Called when "+ Add Tags" is clicked
   */
  const onAddTag = () => {
    setIsEditMode(false);
    setSelectedTag(null);
    toggleIsManageKeyValueOpen();
  };

  /**
   * Called when "Edit tag" is clicked
   * @param {object} tag - tag object to be edited
   */
  const onEditTag = tag => {
    setIsEditMode(true);
    setSelectedTag({ inputKey: tag.Key, inputValue: tag.Value });
    toggleIsManageKeyValueOpen();
  };

  /**
   * Close the key/value popup
   */
  const onCancel = () => {
    toggleIsManageKeyValueOpen();
  };

  /**
   * Edit/Add a tag ({"Key": "abc", "Value": "xyz"})
   * in tagList
   * @param {object} tag - edited or newly created tag object
   * @return {void}
   */
  const onSave = ({ inputKey, inputValue }) => {
    let tag = { Key: inputKey, Value: inputValue };
    let updatedTagList = [];
    if (isEditMode) {
      updatedTagList = tagList.map(t => {
        if (t.Key === selectedTag.inputKey) {
          return tag;
        }
        return t;
      });
    } else {
      updatedTagList = [...tagList, tag];
    }
    setTagList(updatedTagList);
    toggleIsManageKeyValueOpen();
    onChange(updatedTagList);
  };

  return (
    <div className="filters">
      <div
        className={`d-flex flex-column filters-header ${
          tagList.length > 0 && "mb-5-px"
        }`}
      >
        <Header title={title} description={description} helpText={helpText} />
      </div>
      <div className="filters-container">
        {!!tagList &&
          Array.isArray(tagList) &&
          tagList.map((tag, idx) => {
            return (
              <TextInputView
                key={idx}
                value={JSON.stringify(tag)}
                onEdit={() => onEditTag(tag)}
                onDelete={() => onDelete(tag)}
              />
            );
          })}
      </div>
      {isManageKeyValueOpen && (
        <ManageKeyValue
          isEdit={isEditMode}
          selectedInput={selectedTag}
          onCancel={onCancel}
          className="manage-tags"
          onSave={value => onSave(value)}
          type="Tags"
          allowDuplicateKey={false}
          list={tagList.map(tag => tag.Key)}
        />
      )}
      <AddButton title={"Add Tags"} handleClick={onAddTag} />
    </div>
  );
};

export default Tags;
