import React, { Component } from "react";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { isEmpty } from "@lib/utils";
import moment from "moment";

type TaskListFiltersProps = {
  allTaskList: Array<Object>;
  setTaskList: (taskList) => void;
};
const dateRangeOptions = [
  { value: "last7Days", label: "Last 7 days" },
  { value: "last30Days", label: "Last 30 days" },
  { value: "currentMonth", label: "Current month" },
  { value: "yearToDate", label: "Year to date" },
  { value: "allTimes", label: "All times" },
];
class TaskListFilters extends Component<TaskListFiltersProps> {
  state = {
    selectedDateRange: "last7Days",
    selectedSearchValue: "",
  };

  componentDidUpdate(prevProps) {
    if (prevProps.allTaskList !== this.props.allTaskList) {
      this.filterTaskList();
    }
  }

  searchTaskList = (searchValue, taskList) => {
    let filteredTaskList = [...taskList];
    if (searchValue === "") return filteredTaskList;
    else {
      searchValue = searchValue.toLowerCase();
      filteredTaskList = filteredTaskList.filter(
        task =>
          task.TaskId.toString().includes(searchValue) ||
          task.name.toLowerCase().includes(searchValue) ||
          task.status.toLowerCase().includes(searchValue) ||
          task.task_type.TaskType.toLowerCase().includes(searchValue) ||
          (searchValue.includes("auto") && task.trigger === true) ||
          (searchValue.includes("manual") && task.trigger === false),
      );
      return filteredTaskList;
    }
  };

  filterTaskListByDateRange = (dateRange, taskList) => {
    let filteredTaskList = [...taskList];
    switch (dateRange) {
      case "allTimes":
        return filteredTaskList;
      case "last7Days":
        return filteredTaskList.filter(task =>
          moment
            .utc(task.created)
            .isBetween(moment().subtract(6, "d"), moment()),
        );
      case "last30Days":
        return filteredTaskList.filter(task =>
          moment
            .utc(task.created)
            .isBetween(moment().subtract(29, "d"), moment()),
        );
      case "currentMonth":
        let currentYear = moment().year();
        let currentMonth = moment().format("MM");
        return filteredTaskList.filter(task =>
          moment
            .utc(task.created)
            .isSameOrAfter(
              moment(`01-${currentMonth}-${currentYear}`, "DD-MM-YYYY"),
            ),
        );
      case "yearToDate":
        let year = moment().year();
        return filteredTaskList.filter(task =>
          moment
            .utc(task.created)
            .isSameOrAfter(moment(`01-01-${year}`, "DD-MM-YYYY")),
        );
    }
  };

  filterTaskList = (
    searchValue = this.state.selectedSearchValue,
    dateRange = this.state.selectedDateRange,
  ) => {
    let filteredTaskList = [...this.props.allTaskList];
    filteredTaskList = this.searchTaskList(searchValue, filteredTaskList);
    filteredTaskList = this.filterTaskListByDateRange(
      dateRange,
      filteredTaskList,
    );

    this.props.setTaskList(filteredTaskList);
  };

  handleSearchChange = value => {
    if (value === "") {
      this.filterTaskList("");
    }
    this.setState({
      selectedSearchValue: value,
    });
  };
  render() {
    return (
      <div className="actions">
        <ReactSelect
          id="date-range"
          name="date-range"
          value={{
            value: this.state.selectedDateRange,
            label: this.state.selectedDateRange
              ? dateRangeOptions.find(
                  range => range.value === this.state.selectedDateRange,
                )?.label
              : "Select from below",
          }}
          isClearable={false}
          handleChange={data => {
            if (data === null) {
              this.setState({
                selectedDateRange: "",
              });
              this.filterTaskList(this.state.selectedSearchValue, "");
            } else if (!isEmpty(data) && !!data.value && !!data.label) {
              this.setState({
                selectedDateRange: data.value,
              });
              this.filterTaskList(this.state.selectedSearchValue, data.value);
            }
          }}
          className="date-range"
          customControlClass="date-range-cc"
          customMenuClass="date-range-menu"
          customOptionClass="date-range-menu-option"
          selectOptions={dateRangeOptions}
          isSearchable={false}
        />
        <div className="search">
          <input
            className="rule-input"
            placeholder="Search"
            value={this.state.selectedSearchValue}
            onChange={e => {
              this.handleSearchChange(e.target.value);
            }}
            onKeyPress={e => {
              if (e.key === "Enter") {
                this.filterTaskList();
              }
            }}
          />
          <div
            className="clear-search"
            onClick={() => {
              this.setState({
                selectedSearchValue: "",
              });
              this.filterTaskList("");
            }}
          >
            ✕
          </div>
        </div>
      </div>
    );
  }
}

export default TaskListFilters;
