import React, { useState } from "react";
import difference from "lodash/difference";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  DropDown,
  DropDownOption,
  LabelTop,
  FTNotification,
} from "@components/ui";
import { useSelector } from "react-redux";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import SnippetHeader from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/SnippetHeader/SnippetHeader";
import RuleDetails from "./rule-list/rule-details";
import ReactCSSTransition from "@components/shared/ReactCSSTransition";
import { updateLinks } from "./../../../../runbook-editor-lib/runbook-editor.helpers";
import { RunbookStepInputSource } from "./../../../../runbook-editor-lib/ssm/nodeinputoutput";
import join from "lodash/join";
import { useStore } from "react-flow-renderer";
import { getSnippetDisplayName } from "@containers/RunbookEditor/runbook-editor-components/editor-right-panel/input-selector/lib/input-selector.lib.js";

const ConditionalInput = props => {
  const [openRules, setOpenRules] = useState(false);
  const [optionFilterId, setOptionFilterId] = useState(null);
  const rfStore = useStore();

  const snippetDisplayNameMap = useSelector(
    state => state.snippetsReducer.snippetDisplayNameMap,
  );

  const validateRuleDetails = activeNode => {
    let invalidFields = [];
    if (!activeNode.extras) return false;

    if (!activeNode.extras.runbookNode.choices[0].nextStep) {
      invalidFields.push("Destination");
    }

    if (
      ["", null, undefined].includes(
        activeNode.extras.runbookNode.choices[0].condition.value,
      )
    ) {
      invalidFields.push("Comparison Value");
    }
    if (invalidFields.length) {
      FTNotification.error({
        title: "Required Fields",
        message: `Please fill: ${join(invalidFields, ", ")}`,
        timeout: 2,
      });
      return false;
    }
    return true;
  };

  const toggleRules = () => {
    setOpenRules(!openRules);
  };

  const ruleDefaultControlForNode = activeNode => {
    const { runbookNode } = activeNode.extras;
    return (
      <div className="editor-detail-panel editor-detail-panel-column">
        <div className="editor-terraform-container">
          <LabelTop
            label={`Next step if no rule is satisfied`}
            labelClassName="label"
          >
            <DropDown
              title={
                getSnippetDisplayName(
                  runbookNode.defaultNextStep || "",
                  runbookNode.runbook.mainStepIndex[runbookNode.defaultNextStep]
                    ?.stepType,
                  snippetDisplayNameMap,
                ) || ""
              }
              showArrow={true}
              iconLeft="icon-filter"
              style={{ width: "calc(100% - 8px)", marginTop: "0px" }}
              onChange={(newText, newValue) => {
                runbookNode.setDefault(newValue);
                runbookNode.runbook.updateDAGAtStep(runbookNode);
                _updateLinks(activeNode, runbookNode);
              }}
            >
              {runbookNode.legalSuccessors().map(succ => (
                <DropDownOption
                  text={getSnippetDisplayName(
                    succ.name,
                    succ.stepType,
                    snippetDisplayNameMap,
                  )}
                  value={succ.name}
                  key={succ.name}
                />
              ))}
            </DropDown>
          </LabelTop>
        </div>
      </div>
    );
  };

  const _updateLinks = (activeNode, runbookStep) => {
    const { nodes } = rfStore?.getState();

    if (!nodes.length) return;

    const linkIds = [
      ...new Set(
        nodes.find(item => item.id === activeNode.extras.editorNodeId)?.outPort,
      ),
    ];

    const linkTargetIds = linkIds.map(
      item => nodes.find(node => node.id === item)?.data?.uniqueId,
    );
    const newTargets = runbookStep.nextSteps();

    const addLinks = difference(newTargets, linkTargetIds);
    const removeLinks = difference(linkTargetIds, newTargets);

    for (let target of removeLinks) {
      props.removeLinkToStep(activeNode?.extras?.runbookNode?.name, target);
    }
    for (let targetNodeName of addLinks) {
      props.addLinkToStep(
        activeNode?.extras?.runbookNode?.name,
        targetNodeName,
      );
    }

    activeNode.extras.runbookNode.showHideWarning(
      !activeNode.extras.runbookNode.isHealthyStep(),
    );
  };

  const toggleInputOption = optionFilterId => {
    if (typeof optionFilterId === "object") {
      const obj = optionFilterId.target;
      if (obj.classList.contains("checkmark")) {
        setTimeout(() => {
          setOptionFilterId("none");
        }, 100);
      } else {
        if (obj.type === "text") {
          return;
        }

        setOptionFilterId("none");
      }
    } else {
      setOptionFilterId(optionFilterId);
    }
  };

  const deleteConditionalRule = (activeNode, notifyRunbookUpdate) => {
    let choice = activeNode.extras.runbookNode.choices[0];
    choice.condition.operator = "";
    choice.condition.value = "";
    choice.nextStep = "";

    choice.ssmStep.runbook.updateDAGAtStep(choice.ssmStep);

    updateLinks(
      activeNode,
      choice.ssmStep,
      props.addLinkToStep,
      props.removeLinkToStep,
      rfStore,
    );
    let input = choice.inputs()[0];

    input.source = new RunbookStepInputSource("constant", "");
    activeNode.extras.runbookNode.didUpdateStep({});
    notifyRunbookUpdate(true);
  };

  return (
    <Consumer>
      {({ activeNode, notifyRunbookUpdate }) => {
        return (
          <ReactCSSTransition panel={"input-panel"}>
            {!openRules ? (
              <div key={"rule"}>
                <SnippetHeader
                  snippetDef={activeNode.extras.runbookNode.snippetDef}
                />
                <Accordion isExpanded={true} useArrow={true}>
                  <AccordionLabel className="editor-accordion-label">
                    RULES
                  </AccordionLabel>
                  <AccordionSection>
                    <div className="editor-detail-panel editor-detail-panel-column">
                      <div className="label">
                        Define conditional by adding a rule
                      </div>
                      <div className="rule-actions"></div>

                      {!!activeNode.extras.runbookNode.choices[0].condition
                        .value ||
                      activeNode.extras.runbookNode.choices[0].nextStep ? (
                        <div
                          className="rule-list rule-list-wrap"
                          style={{
                            width: "calc(100% - 8px)",
                            marginTop: "0px",
                          }}
                        >
                          <div className="rule-list-item">
                            <div>Rule 1</div>
                            <div className="rule-actions">
                              <div
                                className="rule-icon rule-icon-edit"
                                onClick={toggleRules}
                              />
                              <div
                                className="rule-icon rule-icon-delete"
                                onClick={e => {
                                  e.preventDefault();
                                  deleteConditionalRule(
                                    activeNode,
                                    notifyRunbookUpdate,
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="rule-list rule-list-wrap rule-list-empty mt-10-px"
                          onClick={toggleRules}
                          style={{
                            width: "calc(100% - 12px)",
                            marginTop: "0px",
                          }}
                        >
                          + Add Rule
                        </div>
                      )}
                    </div>
                  </AccordionSection>
                </Accordion>
                <Accordion isExpanded={true} useArrow={true}>
                  <AccordionLabel className="editor-accordion-label">
                    Fallback Destination
                  </AccordionLabel>
                  <AccordionSection>
                    <div>
                      <div>{ruleDefaultControlForNode(activeNode)}</div>
                    </div>
                  </AccordionSection>
                </Accordion>
              </div>
            ) : (
              <>
                <RuleDetails
                  key={"ruleDetails"}
                  rerenderEditor={props.rerenderEditor}
                  addLinkToStep={props.addLinkToStep}
                  removeLinkToStep={props.removeLinkToStep}
                  toggle={() =>
                    validateRuleDetails(activeNode) && toggleRules()
                  }
                  toggleInputOption={toggleInputOption}
                  optionFilterId={optionFilterId}
                  choice={activeNode.extras.runbookNode.choices[0]}
                />
              </>
            )}
          </ReactCSSTransition>
        );
      }}
    </Consumer>
  );
};

export default ConditionalInput;
