import React from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import SnippetControl from "./SnippetControl";
import { getFilteredSnippetList, addBucketName } from "@lib/utils";

export default class Snippet extends React.Component {
  render() {
    const { snippetName, snippets, index } = this.props;
    const snips = snippets[snippetName] || [];

    const finalSnippetList = getFilteredSnippetList(
      addBucketName(snips, `${snippetName} Actions`),
      this.props.searchVal,
    );
    return (
      finalSnippetList.length > 0 && (
        <Accordion
          isExpanded={!index}
          useArrow={true}
          key={snippetName}
          searchTerm={this.props.searchVal}
        >
          <AccordionLabel className="actions-accordion editor-accordion-label">
            {snippetName}
          </AccordionLabel>
          <AccordionSection>
            <div className="snippet-block">
              {finalSnippetList.map(snippet => (
                <SnippetControl snippet={snippet} key={snippet.id} />
              ))}
            </div>
          </AccordionSection>
        </Accordion>
      )
    );
  }
}

Snippet.propTypes = {
  snippetName: PropTypes.any.isRequired,
  snippets: PropTypes.any.isRequired,
  searchVal: PropTypes.string,
};
