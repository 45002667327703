import React from "react";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import join from "lodash/join";

const displayExistingIssueDetails = selectedIssue => {
  if (!selectedIssue || !selectedIssue.id) return;
  let existingIssueList = [
    { key: "Id", value: selectedIssue.id },
    { key: "Created On", value: selectedIssue.created },
    {
      key: "Label",
      value: join(selectedIssue.labels, ", "),
    },
    {
      key: "Reporter",
      value: selectedIssue.reporter,
    },
  ];
  return (
    <div className="mt-20-px mr-10-px ml-10-px pb-2">
      {existingIssueList.map(issue => (
        <div
          className="d-flex w-100"
          style={{ width: "calc(100% - 16px)", marginLeft: "3px" }}
          key={issue.key}
        >
          <div
            className="w-25"
            style={{
              borderRight: "0px",
              backgroundColor: "transparent",
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              padding: "0px",
              height: "30px",
              borderBottom: "1px solid #555",
              fontWeight: "bold",
            }}
          >
            {issue.key}
          </div>
          <div
            className="w-75 scroll-x-auto"
            style={{
              borderRight: "0px",
              backgroundColor: "transparent",
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              padding: "0px",
              height: "30px",
              borderBottom: "1px solid #555",
            }}
          >
            <div>{issue.value}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const JiraIssueDetails = ({ selectedIssue }) => {
  return (
    <Accordion isExpanded={true} useArrow={true}>
      <AccordionLabel className="editor-accordion-label mt-10-px">
        Issue Details
      </AccordionLabel>
      <AccordionSection>
        {displayExistingIssueDetails(selectedIssue)}
      </AccordionSection>
    </Accordion>
  );
};

export default JiraIssueDetails;
