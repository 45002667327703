import React from "react";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  TextInput,
  LabelTop,
} from "@components/ui";

export default props => (
  <Accordion isExpanded={true} useArrow={true}>
    <AccordionLabel className="editor-accordion-label">
      3. Set Comparison Value
    </AccordionLabel>
    <AccordionSection>
      <div className="editor-detail-panel editor-detail-panel-column">
      <LabelTop
        label={`What to compare the selected variable to`}
        labelClassName="label"
      ></LabelTop>
        <TextInput
          name="comparison-value"
          style={{ width: "calc(100% - 8px)", marginTop: "0px" }}
          className="rule-input"
          value={`${
            props.choice.condition.hasOwnProperty("value")
              ? props.choice.condition.value
              : ""
          }`}
          onChange={newValue => {
            props.choice.condition.value = newValue || "";
            props.notifyRunbookUpdate(true);
            props.activeNode.extras.runbookNode.didUpdateStep({});
          }}
        />
      </div>
    </AccordionSection>
  </Accordion>
);
