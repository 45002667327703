import { SnippetStep } from "./snippetstep";
import { StepTypes } from "./strings";
import { SSMActionNames } from "../ssm/strings";
import { Parameter } from "../ssm/parameters";

export default class ApprovalNodeStep extends SnippetStep {
  static fromControlNodeDefinition(controlDef) {
    const stepJSON = {
      ...controlDef.content,
      name: `${controlDef.name}_${
        controlDef.insertionOrder
          ? controlDef.insertionOrder
          : Math.floor(10000 * Math.random())
      }`,
      action: SSMActionNames.INVOKE_LAMBDA,
      maxAttempts: 1,
      inputs: {
        ClientContext: super._writeSnippetDetailsForContext(controlDef),
        FunctionName: controlDef.content.lambda_arn,
        Payload: "",
      },
      outputs: Object.keys(controlDef.outputs).map(output => {
        return {
          Name: output,
          Selector: `$.${output}`,
          Type: controlDef.outputs[output],
        };
      }),
      editorNodeId: controlDef?.editorNodeId,
    };
    return new ApprovalNodeStep(stepJSON, controlDef);
  }

  constructor(stepJSON, controlDef) {
    super(stepJSON, controlDef);
    this.stepType = StepTypes.ApprovalNodeStep;
    this.snippetDef = controlDef;
    this.timeout = "";
    this.timeoutUnit = "Seconds";

    this.parameterInputs = this.parameterInputs.filter(
      param =>
        !(param.name === "execution_id" || param.name === "pause_step_name"),
    );
  }

  setTimeoutForPauseNode = timeout => {
    this.timeout = parseFloat(timeout);
  };

  setTimeoutUnitForPauseNode = timeoutUnit => {
    this.timeoutUnit = timeoutUnit;
  };

  invokeStepSSM = controlDef => {
    return {
      name: this.name,
      action: SSMActionNames.INVOKE_LAMBDA,
      maxAttempts: 1,
      inputs: {
        ClientContext: SnippetStep._writeSnippetDetailsForContext(controlDef),
        FunctionName: controlDef.content.lambda_arn,
        Payload: this.writeInputParams(),
      },
      outputs: Object.keys(controlDef.outputs).map(output => {
        return {
          Name: output,
          Selector: `$.${output}`,
          Type: controlDef.outputs[output],
        };
      }),
      isEnd: false,
      nextStep: `Pause_${this.name}`,
      onFailure: "Abort",
    };
  };

  approvalPauseStep = () => ({
    name: `Pause_${this.name}`,
    action: "aws:pause",
    inputs: { Message: `{ "timeout_unit" : "${this.timeoutUnit}"}` },
    timeoutSeconds: this.timeout,
    onFailure: `step:Timeout_${this.name}`,
    nextStep: this.nextStep,
    isEnd: !this.nextStep,
  });

  approvalTimeoutStep = controlDef => ({
    name: `Timeout_${this.name}`,
    action: SSMActionNames.INVOKE_LAMBDA,
    onFailure: "Abort",
    maxAttempts: 1,
    inputs: {
      FunctionName: controlDef.content.postprocess_arn,
      Payload: `{ "execution_start_time":"{{ ${this.name}.execution_start_time }}", "message_channel_id":"{{ ${this.name}.message_channel_id }}", "message_ts":"{{ ${this.name}.message_ts }}", "workflow_name":"{{ ${this.name}.workflow_name }}", "workflow_session": "{{ WorkflowSession }}" }`,
      ClientContext: SnippetStep._writeSnippetDetailsForContext(controlDef),
    },
    outputs: [
      {
        Name: "message_channel_id",
        Selector: "$.message_channel_id",
        Type: "String",
      },
      {
        Name: "message_ts",
        Selector: "$.message_ts",
        Type: "String",
      },
      {
        Name: "execution_status",
        Selector: "$.execution_status",
        Type: "String",
      },
    ],
    isEnd: true,
  });

  writeInputParams() {
    const inputlist = this.parameterInputs
      .map(input => input.writeInputParam())
      .filter(param => !!param)
      .concat([
        `"execution_id":"{{ automation:EXECUTION_ID }}"`,
        `"pause_step_name":"Pause_${this.name}"`,
        '"workflow_session": "{{ WorkflowSession }}"',
      ])
      .join(", ");
    return `{ ${inputlist} }`;
  }

  toSSM = () => [
    this.invokeStepSSM(this.snippetDef),
    this.approvalTimeoutStep(this.snippetDef),
    this.approvalPauseStep(),
  ];

  isHealthyStep() {
    let boolList = [];
    this.parameterInputs.forEach(param => {
      let sourceValue = param.source.sourceValue;
      if (
        param.required &&
        (sourceValue === null ||
          sourceValue instanceof Parameter ||
          !sourceValue)
      ) {
        boolList.push(false);
      }
    });
    if (!this.timeout) {
      boolList.push(false);
    }
    return boolList.every(Boolean);
  }
}
