import React from "react";
import { Link } from "react-router-dom";
import { TextInput } from "@components/ui/";
import { Consumer } from "../schedules-lib/schedules.context";

const SchedulesFilter = props => {
  const { searchTerm, setSearchTerm } = props;
  return (
    <Consumer>
      {({ schedules, searchSchedules }) => {
        return (
          <>
            <div className="d-flex mt-40-px">
              <TextInput
                placeholder="Search"
                name="schedules-search"
                className="text-input text-input__darker"
                onChange={e => {
                  setSearchTerm(e);
                  searchSchedules(e);
                }}
                value={searchTerm}
              />
            </div>
            <div className="schedules-clear-filters">
              {schedules.length
                ? `${searchTerm ? "Found " : "You have "} "${
                    schedules.length
                  }" scheduled workflows`
                : null}
              <Link
                to={`#`}
                onClick={e => {
                  searchSchedules("");
                  setSearchTerm("");
                }}
              >
                Clear all filters
              </Link>
            </div>
          </>
        );
      }}
    </Consumer>
  );
};

export default SchedulesFilter;
