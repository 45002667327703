import React from "react";
import SettingsDescription from "./SettingsDescription";
import SettingsTags from "./SettingsTags";
import RunbookParameters from "./RunbookParameters";
import { Consumer } from "../../../../runbook-editor-lib/runbook-editor.context";

export default class EditorSettings extends React.Component {
  render() {
    return (
      <Consumer>
        {({ runbook, updateRunbook, notifyRunbookUpdate, runbookObj }) => (
          <div className="editor-slideout-content-container-area">
            <SettingsDescription
              runbook={runbook}
              updateRunbook={updateRunbook}
              updateRunbookDescription={this.props.updateRunbookDescription}
              notifyRunbookUpdate={notifyRunbookUpdate}
            />
            <SettingsTags runbook={runbook} updateRunbook={updateRunbook} />
            {/* <RunbookActions runbook={runbook} updateRunbook={updateRunbook} /> */}
            <RunbookParameters
              runbookObj={runbookObj}
              notifyRunbookUpdate={notifyRunbookUpdate}
            />
          </div>
        )}
      </Consumer>
    );
  }
}
