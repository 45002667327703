import React, { Component } from "react";
import ReactFlow, {
  Background,
  isEdge,
  isNode,
  removeElements,
  Controls,
  ControlButton,
} from "react-flow-renderer";
import { v4 as uuidv4 } from "uuid";
import { Easing, Tween, autoPlay } from "es6-tween";
import cloneDeep from "lodash/cloneDeep";

// Components
import ConnectionLine from "./utils/ConnectionLine/ConnectionLine";

// Helper functions
import { stepFromControlNodeData } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/builder";
import { getPortType, isTriggerNode } from "./utils/helpers";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import { FTNotification, Modal, Wait } from "@components/ui";
import {
  isEmpty,
  snakeToPascal,
  capitalizeFirstLetter,
  getBackgroundImgSource,
} from "@lib/utils";
import RefreshIcon from "@assets/images/icons/icon-refresh.svg";
import TriggerSelection from "@components/shared/TriggerSelection/TriggerSelection";

import "./EditorDesignArea.scss";

// Zoom transition
autoPlay(true);
const TRANSITION_TIME = 300;
const EASING = Easing.Quadratic.Out;

class EditorDiagram extends Component {
  onLoad = _reactFlowInstance => {
    if (_reactFlowInstance) {
      _reactFlowInstance.fitView();
    }
    this.props.handleSetState({ reactFlowInstance: _reactFlowInstance });
  };

  onPaneClick = () => this.props.deSelectNode();

  onConnect = params => {
    const { elements } = this.props.state;

    // Check if edge/link already present & return
    const isEdgePresent = elements.find(
      item =>
        isEdge(item) &&
        item.source === params.source &&
        item.target === params.target,
    );

    if (isEdgePresent) {
      return;
    }

    // Check if edge/link generates loop
    if (this.props.isLoopInNodes(params.source, params.target)) {
      return;
    }

    try {
      // Check for valid link
      // TO DO Optimize the use of elements

      const sourceId = elements.find(element => element?.id === params?.source)
        ?.data?.uniqueId;
      const targetId = elements.find(element => element?.id === params?.target)
        ?.data?.uniqueId;

      const source = this.props.runbookObj.mainStepIndex[sourceId];
      const target = this.props.runbookObj.mainStepIndex[targetId];

      if (!isEmpty(source) && !isEmpty(target)) {
        if (
          source.stepType !== "ConditionalStep" &&
          source.stepType !== "ApprovalNodeStep" &&
          !!source.nextStep
        ) {
          alert(
            `Cannot add another link to ${source.name}. Delete the previous link first before adding the new link.`,
          );
          return;
        }

        this.props.runbookObj.linkActions(source, target);

        const newEdge = {
          id: uuidv4(),
          source: params?.source,
          target: params?.target,
          type: "defaultEdge",
          data: {
            onEdgeRemoveMutation: this.props.onEdgeRemoveMutation,
          },
        };

        // Find parent element & child element
        const _elements = elements.concat(newEdge);
        const parentElement = elements.find(item => item.id === params?.source);
        const childElement = elements.find(item => item.id === params?.target);

        if (parentElement?.outPort && Array.isArray(parentElement.outPort)) {
          parentElement.outPort.push(params.target);
        } else {
          parentElement.outPort = [params.target];
        }
        if (childElement?.inPort && Array.isArray(childElement.inPort)) {
          childElement.inPort.push(params.source);
        } else {
          childElement.inPort = [params.source];
        }

        // Update State
        this.props.handleSetState({ elements: _elements });
      }
    } catch (e) {}
  };

  onConnectStop = event => {
    const { handleSetState, reactFlowWrapper, state, rfNodes } = this.props;
    try {
      handleSetState({ isInPortEnabled: false });
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = state.reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const elementToConnect = rfNodes?.getState().nodes.find(singleNode => {
        if (
          singleNode?.__rf?.position?.x <= position.x &&
          position.x <= singleNode?.__rf?.position?.x + 60 &&
          singleNode?.__rf?.position?.y <= position.y &&
          position.y <= singleNode?.__rf?.position?.y + 60
        ) {
          return true;
        }
        return false;
      });
      if (elementToConnect?.id) {
        this.onConnect({
          source: state.startNodeId,
          target: elementToConnect?.id,
        });
      }
    } catch (e) {
      handleSetState({ isInPortEnabled: true });
    }
  };

  onElementClick = (_, diagramData) => {
    const {
      state,
      deSelectNode,
      runbookObj,
      handleSetState,
      setActiveNode,
    } = this.props;
    if (_.target?.name === "custom_action") return;
    /**
     * @case IF - return if node is already selected
     */
    if (diagramData.id === state.selectedNode?.extras?.editorNodeId) {
      return;
    }
    deSelectNode(); // Deselect previous node before selecting next node.

    // Fetch latest data from props
    let node = diagramData?.data?.nodeData;
    let runbookNode = runbookObj.mainStepIndex[diagramData?.data?.uniqueId];

    if (isEmpty(runbookNode)) {
      return;
    }

    if (!isEmpty(node)) {
      node.extras.runbookNode = runbookNode;
      setActiveNode(node);
      handleSetState({ selectedNode: node });
    }
  };

  onElementsRemove = elementsToRemove => {
    const {
      state,
      isDependableNode,
      runbookObj,
      deSelectNode,
      notifyRunbookUpdate,
      handleSetState,
    } = this.props;
    /**
     * Condition to prohibit user from deleting Trigger node
     */
    if (
      elementsToRemove.some(node =>
        isTriggerNode(node.data?.nodeData?.extras?.runbookNode),
      )
    ) {
      return;
    }
    // There are two variation of element to be removed
    // 1. It is a node
    // 2. It is an edge

    const { elements } = state;
    const _elements = removeElements(elementsToRemove, elements);

    if (Array.isArray(elementsToRemove)) {
      for (const item of elementsToRemove) {
        if (isNode(item)) {
          let name = item?.data?.uniqueId || "";

          if (isDependableNode(name)) {
            FTNotification.error({
              message: `This node is being used in the Slack node; reconfigure the Slack node first to remove the dependency`,
            });
            return;
          }

          const runbookNode = runbookObj.mainStepIndex[item?.data?.uniqueId];

          if (!isEmpty(runbookNode)) {
            runbookObj.removeStep(runbookNode);
            deSelectNode();

            // Update inPort & outPorts
            _elements.forEach(element => {
              if (element?.inPort) {
                element.inPort = element.inPort.filter(el => el !== item.id);
              }
              if (element?.outPort) {
                element.outPort = element.outPort.filter(el => el !== item.id);
              }
            });
          }
        }

        if (isEdge(item)) {
          const parentElement = _elements.find(
            element => element?.id === item?.source,
          );
          const childElement = _elements.find(
            element => element?.id === item?.target,
          );

          const source =
            runbookObj.mainStepIndex[parentElement?.data?.uniqueId];
          const target = runbookObj.mainStepIndex[childElement?.data?.uniqueId];

          if (!isEmpty(source) && !isEmpty(target)) {
            runbookObj.removeLink(source, target);
            // Update inPort & outPorts
            if (parentElement?.outPort) {
              const _outPort = parentElement?.outPort?.filter(
                idx => idx !== item?.target,
              );
              parentElement.outPort = _outPort;
            }
            if (childElement?.inPort) {
              const _inPort = childElement?.inPort?.filter(
                idx => idx !== item?.source,
              );
              childElement.inPort = _inPort;
            }
            // Update FromPreviousStep references
            if (target?.parameterInputs) {
              target.parameterInputs.forEach(param => {
                if (param.source.type === "actionNode") {
                  param.source = new RunbookStepInputSource("actionNode", null);
                  deSelectNode();
                }
              });
            }
          }
        }
      }
    }
    notifyRunbookUpdate(true);
    // Update State
    handleSetState({
      elements: _elements,
    });
  };

  onDragOver = event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  onDrop = event => {
    event.preventDefault();
    try {
      const { reactFlowInstance, elements } = this.props.state;
      const { notifyRunbookUpdate, updateRunbookObj, runbookObj } = this.props;

      const reactFlowBounds = this.props.reactFlowWrapper.current.getBoundingClientRect();

      const nodeData =
        typeof event.dataTransfer.getData("application/reactflow") === "string"
          ? JSON.parse(event.dataTransfer.getData("application/reactflow"))
          : event.dataTransfer.getData("application/reactflow");

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left - 30,
        y: event.clientY - reactFlowBounds.top - 30,
      });

      const id = uuidv4();

      const insertionOrder =
        Math.max(
          ...elements.map(element => {
            if (element.type !== "defaultEdge") {
              const tempStepId = element.data.uniqueId.match(/\d+$/);
              return tempStepId
                ? parseInt(element.data.uniqueId.match(/\d+$/)[0], 10)
                : 0;
            } else {
              return -1;
            }
          }),
          0,
        ) + 1;
      nodeData.extras.insertionOrder = insertionOrder;

      const runbookNode = stepFromControlNodeData(nodeData);
      const type = getPortType(runbookNode);

      const nodeDisplayName =
        runbookNode?.stepType === "ActionNodeStep"
          ? `${capitalizeFirstLetter(runbookNode.service) || ""} ${
              snakeToPascal(runbookNode.operation) || ""
            }`
          : nodeData.extras?.display_name || "";
      const nodeIconName =
        runbookNode?.stepType === "ActionNodeStep"
          ? "aws"
          : nodeData.extras.icon_name || "";

      // Add runbookNode
      nodeData.extras = {};
      nodeData.extras.runbookNode = runbookNode;
      // Add editorNodeId
      nodeData.extras.runbookNode.editorNodeId = id;
      nodeData.extras.editorNodeId = id;

      const nodeIconClass = this.props.getIconClass(nodeData);

      const newNode = {
        id,
        position,
        type,
        data: {
          label: (
            <>
              <div>{nodeDisplayName || ""}</div>
              <div className="step-id">
                #
                {insertionOrder || insertionOrder === 0 ? insertionOrder : "NA"}
              </div>
            </>
          ),
          nodeData,
          uniqueId: runbookNode.ssm.name,
          nodeIconClass,
          insertionOrder,
          cloneElementMutation: this.props.cloneElementMutation,
          deleteElementMutation: this.props.deleteElementMutation,
          showTriggerSelectionModal: this.props.toggleTriggerSelectionModal,
          nodeIconStyle: {
            backgroundImage: `url(${getBackgroundImgSource(
              nodeDisplayName,
              nodeIconName,
            )})`,
          },
        },
      };

      // Update runbookObj & notify runbook update
      runbookObj.addStep(runbookNode);

      notifyRunbookUpdate(true);

      /**
       * Update runbookObj state in RunbookEditor
       */
      updateRunbookObj(this.props.runbookObj);

      // Update State
      this.props.handleSetState(state => ({
        elements: state.elements.concat(newNode),
      }));
    } catch (e) {
      console.log(
        `Failure dropping the node onto the canvas with the following error: ${e}`,
      );
    }
  };

  handleZoom = ratio => {
    const { reactFlowInstance } = this.props.state;
    const { zoom } = reactFlowInstance.toObject();

    new Tween({ zoom })
      .to({ zoom: zoom * ratio }, TRANSITION_TIME)
      .easing(EASING)
      .on("update", ({ zoom }) => reactFlowInstance.zoomTo(zoom))
      .start();
  };

  setLocalTrigger = localTrigger => {
    this.props.handleSetState({
      localTrigger,
    });
  };

  updateTriggerSelection = () => {
    const {
      state,
      handleSetState,
      isDependableNode,
      setSelectedTriggerForWF,
      toggleTriggerSelectionModal,
      deSelectNode,
      snippets,
      runbookObj,
      notifyRunbookUpdate,
      updateRunbookObj,
    } = this.props;
    /**
     * @case to handle if the former trigger node is being used as a dependency in the slack node
     */
    if (isDependableNode(state.parentData?.uniqueId)) {
      FTNotification.error({
        message: `This node is being used in the Slack node; reconfigure the Slack node first to remove the dependency`,
      });
      return;
    }
    setSelectedTriggerForWF(state.localTrigger);

    // remove the existing trigger node programmatically
    this.props.deleteElementMutation(state.parentData, state.editorData);

    if (!snippets || !snippets.length) return;
    let updatedTrigger = snippets.find(
      snippet => snippet.name === state.localTrigger?.snippet_name,
    );
    const updatedTriggerDisplayName = state.localTrigger.display_name;
    const { editorData } = state;
    const updatedTriggerPos = { x: editorData.xPos, y: editorData.yPos };
    updatedTrigger.insertionOrder = 1;
    const newRunbookNode = stepFromControlNodeData(
      {
        name: updatedTrigger.name,
        extras: updatedTrigger,
      },
      state.localTrigger,
    );
    const id = uuidv4();

    runbookObj.addStep(newRunbookNode);
    let label = (
      <>
        <div>{updatedTriggerDisplayName || ""}</div>
        <div className="step-id">#{1}</div>
      </>
    );
    let nodeIconStyle = {
      backgroundImage: `url(${getBackgroundImgSource(
        label,
        state.localTrigger?.name,
      )})`,
    };

    newRunbookNode["editorNodeId"] = id;
    let nodeData = {
      name: updatedTriggerDisplayName?.toLowerCase(),
      extras: { runbookNode: newRunbookNode, editorNodeId: id },
    };

    let nodeIconClass = this.props.getIconClass(nodeData);
    const newNode = {
      id,
      position: updatedTriggerPos,
      type: editorData["type"],
      data: {
        label,
        uniqueId: newRunbookNode.ssm.name,
        nodeData,
        nodeIconClass,
        cloneElementMutation: this.props.cloneElementMutation,
        deleteElementMutation: this.props.deleteElementMutation,
        showTriggerSelectionModal: this.props.toggleTriggerSelectionModal,
        nodeIconStyle,
      },
    };
    // Update runbook object & notify update
    updateRunbookObj(runbookObj);

    // Update state
    handleSetState(state => ({
      elements: state.elements.concat(newNode),
    }));

    // Update runbook object & update the runbook
    notifyRunbookUpdate(true);
    toggleTriggerSelectionModal();
    deSelectNode();
  };

  render() {
    const {
      elements,
      isRenderingDiagram,
      showTriggerSelectionModal,
      localTrigger,
      isInPortEnabled,
    } = this.props.state;

    return (
      <>
        {showTriggerSelectionModal && (
          <Modal
            onClose={this.props.toggleTriggerSelectionModal}
            title="Replace The Trigger"
            showClose={true}
            contentClass={`modal-content__32px`}
            titleClass={`modal-header__32px`}
            submitButtonText="Update Trigger"
            cancelButtonText="Cancel"
            onSubmit={this.updateTriggerSelection}
            onCancel={this.props.toggleTriggerSelectionModal}
          >
            <div className="d-flex flex-column">
              <span className="mb-2">
                Your workflow needs a trigger to get started. Select from the
                list of triggers below.
              </span>
              <TriggerSelection
                triggerList={this.props.triggerList}
                localTrigger={localTrigger}
                setLocalTrigger={this.setLocalTrigger}
              />
            </div>
          </Modal>
        )}
        {isRenderingDiagram && <Wait text="Loading..." />}
        <div
          className={`rf-editor-wrapper ${
            isInPortEnabled ? "inport-enabled" : ""
          }`}
          ref={this.props.reactFlowWrapper}
        >
          <ReactFlow
            elements={isRenderingDiagram ? [] : cloneDeep(elements)}
            onConnect={this.onConnect}
            onConnectStop={this.onConnectStop}
            onConnectStart={(_, { nodeId }) =>
              this.props.handleSetState({
                startNodeId: nodeId,
                isInPortEnabled: true,
              })
            }
            onElementClick={this.onElementClick}
            onElementsRemove={this.onElementsRemove}
            onLoad={this.onLoad}
            onDrop={this.onDrop}
            zoomOnScroll={false}
            panOnScroll={true}
            onDragOver={this.onDragOver}
            onPaneClick={this.onPaneClick}
            nodeTypes={this.props.nodeTypes}
            edgeTypes={this.props.edgeTypes}
            connectionLineComponent={ConnectionLine}
            defaultZoom={1}
            snapToGrid={true}
            minZoom={0.5}
            deleteKeyCode={this.props.deleteKeyCode}
            translateExtent={[
              [-1500, -250],
              [3000, 8000],
            ]}
          >
            <Controls
              showInteractive={false}
              onZoomIn={() => this.handleZoom(1.1)}
              onZoomOut={() => this.handleZoom(1 / 1.1)}
            >
              <ControlButton onClick={this.props.draw}>
                <img src={RefreshIcon} alt="Rerender Workflow" />
              </ControlButton>
            </Controls>
            <Background color="#4E4E4E" gap={25} />
          </ReactFlow>
        </div>
      </>
    );
  }
}

export default EditorDiagram;
