import React from "react";
import { isTriggerNode } from "../../helpers";

type NodeBodyProps = {
  nodeData: any;
  editorData: any;
};

const NodeBody: React.FC<NodeBodyProps> = ({ nodeData, editorData }) => {
  const isTrigger = isTriggerNode(nodeData?.nodeData?.extras?.runbookNode);

  return (
    <>
      <div className="rf-editor-default-node__name" key={nodeData.name}>
        {nodeData?.label || ""}
      </div>
      <div
        className={nodeData?.nodeIconClass}
        style={nodeData?.nodeIconStyle}
      ></div>
      <div
        className={`rf-editor-btn-wrapper ${
          isTrigger ? "rf-editor-btn-wrapper-trigger" : ""
        }`}
      >
        {isTrigger ? (
          <button
            name="custom_action"
            type="button"
            className="rf-editor-btn rf-editor-btn__edit"
            onClick={() =>
              nodeData.showTriggerSelectionModal(nodeData, editorData)
            }
          ></button>
        ) : (
          <>
            <button
              name="custom_action"
              type="button"
              className="rf-editor-btn rf-editor-btn__clone"
              onClick={() =>
                nodeData.cloneElementMutation(nodeData, editorData)
              }
            ></button>
            <button
              name="custom_action"
              type="button"
              className="rf-editor-btn rf-editor-btn__delete"
              onClick={() =>
                nodeData.deleteElementMutation(nodeData, editorData)
              }
            ></button>
          </>
        )}
      </div>
    </>
  );
};

export default NodeBody;
