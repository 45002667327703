import produce from "immer";
import {
  S3_BUCKET_IS_ALL_BUCKET_LIST_LOADING,
  S3_BUCKET_ALL_BUCKETS_FETCH_SUCCESS,
} from "@redux/types";

const INITIAL_STATE = {
  isAllS3BucketListLoading: false,
  allS3BucketList: [],
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case S3_BUCKET_ALL_BUCKETS_FETCH_SUCCESS:
        draft.allS3BucketList = action.payload;
        break;

      case S3_BUCKET_IS_ALL_BUCKET_LIST_LOADING:
        draft.isAllS3BucketListLoading = action.payload;
        break;

      default:
        break;
    }
  });
