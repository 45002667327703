import React, { useState, useContext, useEffect } from "react";
import { RunbooksContext } from "../../runbooks-lib/runbooks.context";
import cancelIcon from "@assets/images/icons/icon-close.svg";

export default props => {
  const context = useContext(RunbooksContext);
  const { searchAndSort, runbookSearchTerm } = context;
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (runbookSearchTerm) {
      setSearchTerm(runbookSearchTerm);
    }
    return () => {
      setSearchTerm("");
    };
  }, [runbookSearchTerm, searchAndSort]);

  return (
    <div className="tab-content__actions-wrap">
      <span>
        <input
          name="search"
          placeholder="Search by Name or Tags"
          className="text-input text-input__darker w-input"
          autoComplete="off"
          value={searchTerm}
          onChange={event => setSearchTerm(event.target.value)}
          onKeyDown={event => {
            searchAndSort(searchTerm);
          }}
        />
        <button
          type="button"
          className="reset-button__darker"
          onClick={() => {
            setSearchTerm("");
            searchAndSort({ search: "" });
          }}
        >
          <img src={cancelIcon} alt="" />
        </button>
      </span>
    </div>
  );
};
