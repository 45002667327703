import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { Wait } from "@components/ui";

// Actions
import {
  resetLoaderState,
  showGlobalLoader,
} from "@redux/actions/globalLoader.actions";

type GlobalLoaderProviderProps = {
  children: React.ReactNode;
};

const GlobalLoaderProvider: React.FC<GlobalLoaderProviderProps> = ({
  children,
}) => {
  const loaderState = useSelector(
    (state: RootStateOrAny) => state.globalLoaderReducer,
  );
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    // Reset loaderFlag on path change
    dispatch(showGlobalLoader());
  }, [pathname, dispatch]);

  // Fall back if some edge case occurs
  useEffect(() => {
    if (loaderState.loaderCount < 0) {
      dispatch(resetLoaderState());
    }
  }, [dispatch, loaderState.loaderCount]);

  return (
    <>
      {!loaderState.loaderFlag && loaderState.loaderCount > 0 && (
        <div className="wait-wrapper">
          <Wait text={loaderState.loaderMessage} />
        </div>
      )}
      {children}
    </>
  );
};

export default GlobalLoaderProvider;
