import React, { useState } from "react";
import "./TagMultiSelectFilter.scss";

export default function TagMultiSelectFilter({
  runbooksList,
  handleFilterSelection,
}) {
  const categories = [];
  let isOthersExist = false;
  runbooksList.forEach(runbook => {
    runbook.Tags.forEach(tag => {
      if (tag.startsWith("category:")) {
        const name = tag.replace("category:", "");
        const displayName = name.replaceAll("_", " ");
        if (name === "Others") {
          isOthersExist = true;
        } else if (!categories.some(category => category.name === name)) {
          categories.push({ name: name, displayName: displayName });
        }
      }
    });
  });
  if (isOthersExist) {
    categories.push({ name: "Others", displayName: "Others" });
  }

  const [selectedCategories, setSelectedCategories] = useState([]);
  const handleCategorySelection = input => {
    let temp = [...selectedCategories];
    if (temp.includes(input)) {
      temp = temp.filter(item => item !== input);
    } else {
      temp.push(input);
    }
    setSelectedCategories(temp);
    handleFilterSelection(temp);
  };

  const getBackgroundImgSource = bool => {
    const bgImageSource = bool
      ? require(`@assets/images/snippets/icon-black-check.svg`)
      : "";
    return bgImageSource;
  };

  return (
    <div className="tags-wrapper">
      {categories.map(category => (
        <div
          key={category.name}
          className={
            selectedCategories.includes(category.name)
              ? "tag-custom tag-selected"
              : "tag-custom"
          }
          onClick={() => handleCategorySelection(category.name)}
        >
          <div
            style={{
              backgroundImage: `url(${getBackgroundImgSource(
                selectedCategories.includes(category.name),
              )})`,
            }}
            className={
              selectedCategories.includes(category.name) ? "check-mark" : ""
            }
          ></div>
          <div>{category.displayName}</div>
        </div>
      ))}
    </div>
  );
}
