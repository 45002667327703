import produce from "immer";
import {
  TERRAFORM_CLI_FETCH_SUCCESS,
  TERRAFORM_CLI_SAVE_SUCCESS,
  TERRAFORM_CLI_UPDATE_SUCCESS,
  TERRAFORM_CLI_DELETE_SUCCESS,
} from "../types";

const INITIAL_STATE = {
  terraformModules: [],
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TERRAFORM_CLI_FETCH_SUCCESS:
        draft.terraformModules = action.payload;
        break;

      case TERRAFORM_CLI_SAVE_SUCCESS:
        let duplicateEntry = state.terraformModules.find(
          item =>
            item?.name === action.payload?.name &&
            item?.alias === action.payload?.alias,
        );
        if (duplicateEntry) {
          draft.terraformModules = state.terraformModules;
        } else {
          draft.terraformModules = [...state.terraformModules, action.payload];
        }
        break;

      case TERRAFORM_CLI_UPDATE_SUCCESS:
        const modulesList = state.terraformModules.filter(
          val =>
            !(
              val?.name === action.payload?.name &&
              val?.alias === action.payload?.alias
            ),
        );
        modulesList.push(action.payload);
        draft.terraformModules = modulesList;
        break;

      case TERRAFORM_CLI_DELETE_SUCCESS:
        draft.terraformModules = state.terraformModules.filter(
          val =>
            !(
              val?.name === action.payload?.name &&
              val?.alias === action.payload?.alias
            ),
        );
        break;

      default:
        break;
    }
  });
