import React from "react";
import { Link } from "react-router-dom";
import { DataTable } from "@components/ui/data-table";
import { Consumer } from "../schedules-lib/schedules.context";
import moment from "moment";
import { Button, Modal, FTNotification } from "@components/ui";
import Api from "@lib/api/api";
import later from "later"; // http://bunkat.github.io/later/
import iconHelp from "@assets/images/icons/icon-help.svg";
import ReactTooltip from "react-tooltip";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";
import { RouteConstants } from "../../../routes/Constants";
import { replaceAllSpecialCharWithSpace } from "@lib/utils";

later.date.localTime();

export class SchedulesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionButtons: {},
      confirmDeleteSchedule: null,
    };
    this.getColumns();
    this.tooltipRef = React.createRef();
    this.statusBlockStyle = {
      paddingLeft: "17px",
      backgroundPosition: "0% 50%",
    };
  }

  componentDidUpdate() {
    this.getColumns();
  }

  parseWorkflowName = workflow => {
    workflow = workflow.split("/");
    return {
      name: workflow[0],
      version: workflow[1],
    };
  };

  getSchedules = cronExpression => {
    try {
      const cron = later.parse.cron(cronExpression);
      const occurrences = later.schedule(cron).next(10);
      let text = `<div class="next-schedule-list"><div class="title">Next ${occurrences.length} executions at:<br/></div>`;
      for (var i = 0; i < occurrences.length; i++) {
        text += `<div>${occurrences[i]}<br/></div>`;
      }
      text += `</div>`;
      return text;
    } catch (e) {
      return "";
    }
  };

  getColumns = () => {
    this.columns = [
      {
        name: "Name & Version",
        selector: "runbook",
        format: row => {
          let workflow = this.parseWorkflowName(row.workflow);
          return (
            <Link
              to={`workflow/${workflow.name}/parameters?v=${workflow.version}`}
            >
              {replaceAllSpecialCharWithSpace(workflow.name)}:v
              {workflow.version}
            </Link>
          );
        },
      },
      {
        name: "Creation Time",
        selector: "created_at",
        format: row =>
          moment(parseInt(row.created_at)).format("MM.DD.YYYY, h:mm:ss a"),
        grow: 1,
      },

      {
        name: "Rate/Expression",
        selector: "schedule_expression",
        format: row => {
          let workflow = this.parseWorkflowName(row.workflow);
          if (row.schedule_expression.indexOf("rate(") > -1) {
            const match = row.schedule_expression
              ?.match(/rate\((.*)\)/i)[1]
              ?.split(" ");
            return `Every ${match[0]} ${match[1]}`;
          } else {
            const match = row.schedule_expression?.match(/cron\((.*)\)/i)[1];
            return (
              <span>
                {match}{" "}
                <img
                  alt="view"
                  src={iconHelp}
                  data-for={`tooltip-${workflow.name}-${workflow.version}`}
                  data-tip={this.getSchedules(match)}
                  data-html
                />
                <ReactTooltip
                  id={`tooltip-${workflow.name}-${workflow.version}`}
                  place="bottom"
                  effect="solid"
                  type="light"
                />
              </span>
            );
          }
        },
      },
      {
        name: "Status",
        selector: "enabled",
        grow: 0.8,
        format: row =>
          row.enabled ? (
            <span className="Success-block" style={this.statusBlockStyle}>
              Enabled
            </span>
          ) : (
            <span className="Failed-block" style={this.statusBlockStyle}>
              Disabled
            </span>
          ),
      },
      {
        name: "Incident ID",
        selector: "TaskId",
        wrap: true,
        grow: 0.4,
        cell: row => {
          const taskId = row.task_id;
          return taskId ? (
            <Link
              to={`${RouteConstants.taskDetails.baseUrl}/${taskId}/workflows`}
            >{`#${taskId}`}</Link>
          ) : (
            ""
          );
        },
      },
      {
        name: "Actions",
        selector: "",
        cell: row => {
          return (
            <div className="d-flex">
              <Button
                text={row.enabled ? `Disable` : `Enable`}
                buttonStyle={row.enabled ? "warning" : "primary"}
                size="small"
                style={{
                  width: "90px",
                  height: "30px",
                  padding: "8px",
                  marginRight: "6px",
                }}
                onClick={e => this.enableDisable(e, row)}
                icon={row.enabled ? `icon-close.svg` : `icon-play.svg`}
                isLoading={this.state.actionButtons[row.workflow] || false}
              />
              &nbsp;
              <Button
                text={``}
                buttonStyle="secondary"
                style={{ width: "40px", height: "30px" }}
                icon={"icon-trash-v2.svg"}
                onClick={e => this.showHideDeleteConfirmModal(row)}
              />
            </div>
          );
        },
      },
    ];
  };

  enableDisable = (e, data) => {
    e.preventDefault();
    this.setState({
      actionButtons: { ...this.state.actionButtons, [data.workflow]: true },
    });
    let json = {
      rule: data.rule,
      state: !data.enabled,
    };
    let workflow = this.parseWorkflowName(data.workflow);
    Api.enableDisableSchedule(workflow.name, workflow.version, json).then(d => {
      this.props.fetchSchedules(workflow.name, workflow.version);
      this.setState({
        actionButtons: { ...this.state.actionButtons, [data.workflow]: false },
      });
    });
  };

  showHideDeleteConfirmModal = row => {
    this.setState(prevState => {
      return {
        confirmDeleteSchedule: prevState.confirmDeleteSchedule ? null : row,
      };
    });
  };

  deleteRule = (e, row) => {
    let workflow = this.parseWorkflowName(row.workflow);
    Api.deleteSchedule(workflow.name, workflow.version, row.rule).then(e => {
      this.props.removeScheduledItem(row);
      this.props.fetchSchedules(null, null, false);
      this.showHideDeleteConfirmModal(null);
      FTNotification.success({ message: `Deleted successfully` });
    });
  };

  getDeleteModelFooter = () => {
    return (
      <div className="modal-buttons-footer">
        <Button
          text="Cancel"
          onClick={this.showHideDeleteConfirmModal}
          buttonStyle="secondary"
          style={{ width: "50%" }}
        />
        <Button
          text="Delete"
          onClick={e => this.deleteRule(e, this.state.confirmDeleteSchedule)}
          buttonStyle="warning"
          style={{ width: "50%" }}
        />
      </div>
    );
  };

  render() {
    const { resetSearch } = this.props;
    let workflow;
    if (this.state.confirmDeleteSchedule)
      workflow = this.parseWorkflowName(
        this.state.confirmDeleteSchedule.workflow,
      );
    return (
      <Consumer>
        {({ schedules, isFetchingSchedules }) => {
          return (
            <>
              {this.state.confirmDeleteSchedule && (
                <Modal
                  title={`Confirm Deleting Schedule`}
                  showClose={true}
                  footer={this.getDeleteModelFooter()}
                  onClose={this.showHideDeleteConfirmModal}
                >
                  <div>
                    <p>
                      Are you sure you want to delete&nbsp;
                      <span className="failed-stats">
                        {replaceAllSpecialCharWithSpace(workflow.name)}(v:
                        {workflow.version})
                      </span>{" "}
                      schedule?
                    </p>
                  </div>
                </Modal>
              )}
              {!isFetchingSchedules &&
                (Array.isArray(schedules) && schedules.length > 0 ? (
                  <DataTable
                    className="snippet-list-table"
                    columns={this.columns}
                    data={schedules}
                    responsive={true}
                  />
                ) : this.props?.searchTerm?.length > 0 ? (
                  <NoResultFound
                    message="No results found"
                    detail="Try adjusting your search to find what you're looking for."
                    className="mt-100-px"
                    errImgSrc="search-es"
                    fallbackComponent={
                      <span
                        className="reset-search"
                        onClick={() => resetSearch()}
                      >
                        Clear Search
                      </span>
                    }
                  />
                ) : (
                  <NoResultFound
                    message="There are no Schedules yet"
                    detail="You can schedule a workflow inside the workflow editor or from the workflow detail page."
                    className="mt-100-px"
                    errImgSrc="schedules-es"
                  />
                ))}
            </>
          );
        }}
      </Consumer>
    );
  }
}
