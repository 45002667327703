import produce from "immer";
import { LOG_IN, LOG_OUT } from "@redux/actions/user.actions";
import { mergeObjects } from "@lib/utils";

const INITIAL_STATE = {
  isLoggedIn: false,
  email: "no-reply@neurops.io",
  cognitoUsername: null,
  idToken: null,
  name: null,
};
export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOG_IN:
        draft.email = action.payload.attributes
          ? action.payload.attributes.email
          : action.payload.challengeParam.userAttributes.email;
        draft.cognitoUsername = action.payload.username;
        draft.idToken = action.payload.signInUserSession.idToken.jwtToken;
        draft.name = action.payload.attributes
          ? action.payload.attributes.name
          : action.payload.challengeParam.userAttributes.name;
        draft.isLoggedIn = true;
        break;
      case LOG_OUT:
        mergeObjects(draft, INITIAL_STATE);
        break;

      default:
        break;
    }
  });
