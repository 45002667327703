import React from "react";
import PanelMetaInfo from "./panel-meta-info";
import PanelTags from "./panel-tags";
import PanelLogos from "./panel-logos";
import PanelOverflowMenu from "./panel-overflow-menu";
import { Link } from "react-router-dom";
import { RUNBOOK_SET_ACTIVE } from "@redux/types";
import { connect } from "react-redux";
import { RouteConstants } from "../../../../routes/Constants";

import "./Panel.scss";

const Panel = props => (
  <div className="runbook-item fade-in">
    <Link to={`${RouteConstants.runbookEditor.url}/${props.runbook.Name}`}>
      <div className="runbook-item__container">
        <PanelMetaInfo runbook={props.runbook} />
        <div className="runbook-meta__wrap">
          <PanelLogos tags={props.runbook.Tags} />
          <PanelTags tags={props.runbook.Tags} />
        </div>
      </div>
    </Link>
    <PanelOverflowMenu runbook={props.runbook} />
  </div>
);

const mapState = () => ({});

const mapDispatch = dispatch => ({
  setActiveRunbook: runbook =>
    dispatch({
      type: RUNBOOK_SET_ACTIVE,
      payload: runbook,
    }),
});

export default connect(mapState, mapDispatch)(Panel);
