import React from "react";
import { Consumer } from "../../../runbook-editor-lib/runbook-editor.context";
import showPlusIcon from "@assets/images/icons/icon-actions.svg";
import ReactTooltip from "react-tooltip";

export default class Add extends React.Component {
  render() {
    return (
      <Consumer>
        {({ setActivePanel, activePanel }) => (
          <div
            className={`editor-menu-item ${
              activePanel === "add"
                ? "editor-menu-item-active"
                : "editor-menu-item-inactive"
            }`}
            onClick={() => setActivePanel("add", "Add")}
            data-tut="reactour__add"
          >
            <ReactTooltip
              id="add-actions"
              place="right"
              effect="solid"
              type="light"
            />
            <img
              src={showPlusIcon}
              alt="showPlusIcon"
              className="editor-menu-item-icon editor-menu-item-icon-image"
              width="18"
              height="18"
              data-for="add-actions"
              data-tip="Add Actions"
            />
          </div>
        )}
      </Consumer>
    );
  }
}
