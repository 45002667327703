import { connect } from "react-redux";
import {
  fetchSnippets,
  searchingSnippets,
  filterSnippetsByService,
  resetFilters,
} from "@redux/actions/snippets.actions";
import { setSelectedSnippet } from "@redux/actions/snippetDetails.actions";
import Snippets from "../Snippets";
import {
  updateRecordsCurrentPage,
  updateRecordsPerPage,
  updateSortColumn,
} from "@redux/actions/common.actions";

const mapState = ({ snippetsReducer, commonReducer }) => ({
  showLoading: true,
  isFetchingSnippets: snippetsReducer.isFetchingSnippets,
  fetchingMessage: snippetsReducer.fetchingMessage,
  snippetsList: snippetsReducer.snippetsList,
  searchTerm: snippetsReducer.searchTerm,
  snippetDetailsList: snippetsReducer.snippetDetailsList || [],
  filteredServicesSet: snippetsReducer.filteredServicesSet || new Set(),
  currentPages: commonReducer?.currentPages || {},
  recordsPerPage: commonReducer?.recordsPerPage || {},
  sortingDetails: commonReducer?.sortingDetails || {},
});

const mapDispatch = dispatch => ({
  fetchSnippets: () => dispatch(fetchSnippets("SNIPPET")),
  searchSnippets: val => dispatch(searchingSnippets(val)),
  resetFilters: () => dispatch(resetFilters()),
  filterSnippetsByService: service =>
    dispatch(filterSnippetsByService(service)),
  setSelectedSnippet: snippet => {
    dispatch(setSelectedSnippet(snippet));
  },
  updateRecordsCurrentPage: data => {
    dispatch(updateRecordsCurrentPage(data));
  },
  updateRecordsPerPage: data => dispatch(updateRecordsPerPage(data)),
  updateSortColumn: data => dispatch(updateSortColumn(data)),
});

export default connect(mapState, mapDispatch)(Snippets);
