import React from "react";
import "./ManageKeyValue.scss";
import { Footer } from "../Footer/Footer";
import { Formik } from "formik";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";
import { getValidationSchema } from "./ValidationSchema";

type ManageKeyValueProps = {
  isEdit: boolean;
  showKey?: boolean;
  className?: string;
  onCancel: () => void;
  onSave: (value) => void;
  selectedInput: object;
  allowDuplicateKey?: boolean;
  list?: Array<string>;
  type?: string;
};
const newValues = {
  inputKey: "",
  inputValue: "",
};
export const ManageKeyValue: React.FC<ManageKeyValueProps> = ({
  isEdit,
  showKey = true,
  onCancel,
  onSave,
  selectedInput,
  className,
  allowDuplicateKey = true,
  list = [],
  type = "",
}) => {
  const initialValues = isEdit ? selectedInput : newValues;
  const validationSchema = getValidationSchema(type, list, isEdit);

  /**
   * Called when formik's save button is clicked
   * to edit/add key value
   * @param {object} values - formik values field
   * @param {object} actions - formik action object
   */
  const handleSubmit = (values, actions) => {
    onSave(values);
  };

  return (
    <div>
      <div className="manage-overlay"></div>
      <div className={`manage ${className}`}>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {formik => (
            <>
              {showKey && (
                <FormInput
                  fieldName="Key"
                  name="inputKey"
                  id="inputKey"
                  handleChange={formik.handleChange}
                  touched={formik.touched}
                  errors={formik.errors}
                  autoComplete="off"
                  className={"filters-add-input"}
                  labelClassName={"key-value-label"}
                  readOnly={!allowDuplicateKey && isEdit}
                />
              )}
              <FormInput
                fieldName={`${type === "StringList" ? "Input" : "Value"}`}
                name="inputValue"
                id="inputValue"
                handleChange={formik.handleChange}
                touched={formik.touched}
                errors={formik.errors}
                autoComplete="off"
                className={"filters-add-input"}
                labelClassName={"key-value-label"}
              />
              <Footer onCancel={onCancel} onSave={formik.handleSubmit} />
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};
