import * as Yup from "yup";

export const getValidationSchema = function (currentPage, requiredParameters) {
  if (!currentPage) {
    // Fall back for edge case
    return Yup.object().shape({});
  }
  if (currentPage === 1) {
    return Yup.object().shape({
      name: Yup.string().required("Please enter incident type name"),
      description: Yup.string().required("Please enter description"),
      priority: Yup.string().required("Please select priority"),
    });
  }
  if (currentPage === 2) {
    let reqParams = {};
    requiredParameters
      .filter(
        p => !["AutomationAssumeRole", "WorkflowSession"].includes(p.Name),
      )
      .forEach(param => {
        reqParams[param.Name] = Yup.string()
          .required(`${param.Name} is required`)
          .trim(`This field can't be empty`);
      });
    return Yup.object().shape(reqParams);
  }
};
