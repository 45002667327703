import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { RouteConstants } from "../../../routes/Constants";
import TaskStatus from "@components/shared/TaskStatus/TaskStatus";
import { getPriorityIcon } from "@lib/utils";

export const columns = [
  {
    name: "ID",
    selector: "TaskId",
    sortable: true,
    width: "10%",
    cell: row => {
      const id = row.TaskId;
      return (
        <Link
          to={`${RouteConstants.taskDetails.baseUrl}/${id}/workflows`}
        >{`#${id}`}</Link>
      );
    },
  },
  {
    name: "Name",
    selector: "name",
    sortable: true,
    width: "25%",
    cell: row => {
      const name = row.name;
      const id = row.TaskId;
      return (
        <Link to={`${RouteConstants.taskDetails.baseUrl}/${id}/workflows`}>
          {name}
        </Link>
      );
    },
  },
  {
    name: "Type",
    selector: "TaskType",
    sortable: true,
    width: "15%",
    cell: row => {
      const type = row?.task_type;
      return <div className="table">{type?.TaskType}</div>;
    },
  },
  {
    name: "Priority",
    selector: "task_priority",
    sortable: true,
    width: "10%",
    cell: row => {
      return (
        <>
          <img
            src={getPriorityIcon(row?.task_priority)}
            alt="priority-icon"
            className="mr-5-px"
          ></img>
          <div className="table">{row?.task_priority}</div>
        </>
      );
    },
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    width: "10%",
    cell: row => {
      const status = row.status;
      return <TaskStatus status={status} />;
    },
  },
  {
    name: "Trigger",
    selector: "trigger",
    sortable: true,
    width: "10%",
    cell: row => {
      const trigger = row.trigger;
      return <div className="table">{trigger ? "Auto" : "Manual"}</div>;
    },
  },
  {
    name: "Created",
    selector: "created",
    sortable: true,
    width: "20%",
    cell: row => {
      const created = row.created;
      return (
        <div className="table">
          {moment.utc(created).local().format("ddd DD MMM YYYY hh:mm:ss.SSS")}
        </div>
      );
    },
  },
];
