import React from "react";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import OutputActionNode from "./OutputActionNode";
import OutputNonActionNode from "./OutputNonAction";
import { ControlNames } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/strings";

export default () => {
  const getOutput = activeNode => {
    if (
      activeNode.extras.runbookNode.stepType === "ActionNodeStep" ||
      activeNode.extras.runbookNode?.name?.startsWith(ControlNames.Trigger)
    ) {
      return <OutputActionNode activeNode={activeNode} />;
    }
    return <OutputNonActionNode activeNode={activeNode} />;
  };
  return (
    <Consumer>
      {({ activeNode }) => {
        return getOutput(activeNode);
      }}
    </Consumer>
  );
};
