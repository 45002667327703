import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button } from "@components/ui";
import Auth from "@aws-amplify/auth";
import { FTNotification } from "@components/ui";
import { useDispatch } from "react-redux";
import { isFetching } from "@redux/actions/settingsPanel.action";
import ReactTooltip from "react-tooltip";

const ProfileInfo: React.FC = () => {
  const [user, setUser] = useState(null);
  const initialValues = {
    username: user?.attributes?.name || "",
    email: user?.attributes?.email || "",
    company: (user?.attributes && user?.attributes["custom:company"]) || "",
  };

  const dispatch = useDispatch();
  const getUserInfo = async () => {
    const userInfo = await Auth.currentAuthenticatedUser({ bypassCache: true });
    setUser(userInfo);
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .required("Please enter full name")
      .trim("Please enter full name")
      .min(1, "Please enter valid full name")
      .matches(/(\p{Alpha}|\p{N})/u, {
        message: "Please enter valid full name",
      }),
    email: Yup.string()
      .email("Please enter valid email address")
      .required("Please enter valid email address"),
    company: Yup.string()
      .required("Please enter company name")
      .trim("Please enter company name")
      .min(1, "Please enter your full company name")
      .matches(/(\p{Alpha}|\p{N})/u, {
        message: "Please enter valid company name",
      }),
  });

  useEffect(() => {
    getUserInfo();
  }, []);

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    dispatch(isFetching(true, "Saving user information"));
    try {
      let params = {};
      params["name"] = values.username.trim();
      params["custom:company"] = values.company.trim();
      await Auth.updateUserAttributes(user, params);
      FTNotification.success({
        title: "Profile Information Updated Successfully!",
      });
    } catch (error) {
      FTNotification.error({
        title: "Could not update information",
        message: error.message,
      });
    }
    actions.setSubmitting(false);
    dispatch(isFetching(false, ""));
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {formik => (
        <div className="d-flex up-info" id="tab-info-content">
          <div className="up-info-card">
            <h2 className="pb-30-px">User Information</h2>
            <div className="up-info-form-input">
              <label className="up-info-form-input-label">Full Name</label>
              <input
                type="text"
                id="username"
                name="username"
                placeholder="John Doe"
                className={`text-input ${
                  formik.errors.username && formik.touched.username && "error"
                }`}
                aria-describedby="basic-addon1"
                {...formik.getFieldProps("username")}
                autoComplete="off"
              />
              {formik.errors.username && formik.touched.username ? (
                <div className="input-feedback">{formik.errors.username}</div>
              ) : null}
            </div>
            <div className="up-info-form-input">
              <label className="up-info-form-input-label">Email Address</label>
              <input
                type="email"
                id="email"
                placeholder="johndoe@acme.com"
                className={`text-input ${
                  formik.errors.email && formik.touched.email && "error"
                }`}
                aria-describedby="basic-addon1"
                {...formik.getFieldProps("email")}
                autoComplete="off"
                disabled={true}
              />
              {formik.errors.email && formik.touched.email ? (
                <div className="input-feedback">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="up-info-form-input">
              <label className="up-info-form-input-label">Company Name</label>
              <input
                type="text"
                id="company"
                name="company"
                placeholder="Acme Corp"
                className={`text-input ${
                  formik.errors.company && formik.touched.company && "error"
                }`}
                aria-describedby="basic-addon1"
                {...formik.getFieldProps("company")}
                autoComplete="off"
              />
              {formik.errors.company && formik.touched.company ? (
                <div className="input-feedback">{formik.errors.company}</div>
              ) : null}
            </div>

            <div
              className="form-footer form-footer-submit"
              data-for="submitTip"
              data-tip
            >
              <Button
                className="up-info-form-submit-btn"
                text="Update Information"
                buttonStyle="primary"
                size="large"
                disabled={!formik.dirty}
                onClick={formik.handleSubmit}
              />
            </div>
            {!formik.dirty && (
              <ReactTooltip
                id="submitTip"
                place="top"
                effect="solid"
                type="light"
              >
                Nothing to update.
              </ReactTooltip>
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};

export default ProfileInfo;
