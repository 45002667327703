import { connect } from "react-redux";
import Executions from "../Executions";
import { fetchExecutions } from "@redux/actions/execution.action";
import {
  updateExecutionSearchTerm,
  updateExecutionStatusFilter,
  updateExecutionTimeFilter,
  updateRecordsPerPage,
  updateRecordsCurrentPage,
  updateSortColumn,
} from "@redux/actions/common.actions";

const mapState = ({
  userReducer,
  executionsReducer,
  runbookReducer,
  commonReducer,
}) => ({
  user: userReducer.user,
  isFetchingExecution: executionsReducer.isFetchingExecution,
  versions: runbookReducer.activeRunbook.Versions,
  executionsData: executionsReducer.executionsData,
  executionSearchTerm: commonReducer.executionSearchTerm,
  executionStatusFilter: commonReducer.executionStatusFilter,
  executionTimeFilter: commonReducer.executionTimeFilter,
  recordsPerPage: commonReducer?.recordsPerPage || {},
  currentPages: commonReducer?.currentPages || {},
  sortingDetails: commonReducer?.sortingDetails || {},
  message: executionsReducer.message,
});

const mapDispatch = dispatch => ({
  fetchExecutions: workflow => dispatch(fetchExecutions(workflow)),
  updateExecutionSearchTerm: searchTerm =>
    dispatch(updateExecutionSearchTerm(searchTerm)),
  updateExecutionStatusFilter: status =>
    dispatch(updateExecutionStatusFilter(status)),
  updateExecutionTimeFilter: time => dispatch(updateExecutionTimeFilter(time)),
  updateRecordsPerPage: data => dispatch(updateRecordsPerPage(data)),
  updateRecordsCurrentPage: data => dispatch(updateRecordsCurrentPage(data)),
  updateSortColumn: data => dispatch(updateSortColumn(data)),
});

export default connect(mapState, mapDispatch)(Executions);
