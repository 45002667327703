import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { Button, ModalWithSidebar, FTNotification } from "@components/ui";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";
import CheckIcon from "@assets/images/icons/icon-page-tick__complete.svg";
import RadioButtonIcon from "@assets/images/icons/icon-page-tick__active.svg";
import RadioButtonUnchecked from "@assets/images/icons/icon-page-tick__inactive.svg";
import { fetchAllTaskTypes } from "@redux/actions/taskManagement.actions";
import { getValidationSchema } from "./utils/ValidationSchema";
import Api from "@lib/api";
import { fetchRunbooks } from "@redux/actions/runbooks.actions";
import RuntimeParameters from "@containers/TaskManagement/utils/CreateTask/utils/RuntimeParameters";
import { isEmpty } from "@lib/utils";
import { getRuntimeParameterInitialValues } from "@containers/TaskManagement/utils/CreateTask/utils/Helpers";
import { taskPrioritiesList } from "@lib/utils/constants";

type ManageTaskTypesProps = {
  selectedType: any;
  editMode: string;
  closeModal: (flag: boolean) => void;
};

const ManageTaskTypes: React.FC<ManageTaskTypesProps> = ({
  selectedType,
  editMode,
  closeModal,
}) => {
  // Rutime Parameters
  const [selectedWorkflow, setSelectedWorkflow] = useState(
    selectedType?.meta?.workflow?.name || "",
  );
  const [requiredParameters, setRequiredParameters] = useState([]);
  const [selectedRequiredParameters, setSelectedRequiredParameters] = useState(
    {},
  );

  const [typeDetails, setTypeDetails] = useState({
    name: "",
    description: "",
    workflow: "",
    priority: "",
  });
  const isTypeDetailsFilled = useRef(false);

  const [currentCount, setCurrentCount] = useState(1);
  const [formPages, setFormPages] = useState(() => {
    if (editMode === "edit" && selectedType?.meta?.workflow?.name) {
      return ["Incident Type Details", "Runtime Parameters"];
    }
    return ["Incident Type Details"];
  });

  const workflowList = useSelector(
    (state: RootStateOrAny) => state.runbooksReducer.runbookList,
  );

  const dispatch = useDispatch();

  // Functions
  const getInitialValues = () => {
    switch (currentCount) {
      case 1:
        if (isTypeDetailsFilled.current) return typeDetails;
        let data = {
          name: selectedType?.meta?.name || "",
          description: selectedType?.meta?.description || "",
          workflow: selectedType?.meta?.workflow?.name || "",
          priority: selectedType?.meta?.priority || "",
        };
        return data;
      case 2:
        return selectedRequiredParameters;

      default:
        return {};
    }
  };

  const onSubmitHandler = async values => {
    // Increment page count & setup form to use local state rather than using values from props
    if (currentCount !== formPages.length) {
      setTypeDetails(values);
      isTypeDetailsFilled.current = true;
      setCurrentCount(currentCount + 1);
    } else {
      try {
        let payload = {};
        // Case 1: Workflow is not selected
        if (currentCount === 1) {
          let { name, description, priority } = values;
          payload = { name, description, priority };
        }
        // Case 2: Workflow is selected
        if (currentCount === 2 && selectedWorkflow !== "") {
          payload = { ...typeDetails };
          payload["workflow"] = {
            name: typeDetails["workflow"],
            params: {
              ...values,
            },
          };
        }
        if (editMode === "create") {
          await Api.createTaskType(payload);
        } else {
          let { TaskTypeId: id } = selectedType;
          await Api.updateTaskType(id, payload);
        }
        dispatch(fetchAllTaskTypes());
        if (editMode === "create") {
          FTNotification.success({
            title: "Incident Type Added Successfully!",
          });
        }
        if (editMode === "edit") {
          FTNotification.success({
            title: "Incident Type Updated Successfully!",
          });
        }
      } catch (error) {
        if (editMode === "create") {
          FTNotification.error({
            title: "Could not add Incident Type",
            message: error.message,
          });
        }
        if (editMode === "edit") {
          FTNotification.error({
            title: "Could not update Incident Type",
            message: error.message,
          });
        }
      }
      closeModal(false);
    }
  };

  const getFooter = formik => {
    const { handleSubmit } = formik;

    return (
      <div className="modal-buttons-footer__wizard">
        <Button
          text="Cancel"
          buttonStyle="secondary"
          className="button-tertiary modal-buttons-footer__button"
          size="large"
          type="button"
          onClick={() => {
            closeModal(false);
            setCurrentCount(1);
          }}
        />
        {currentCount > 1 && (
          <Button
            text="Back"
            buttonStyle="secondary"
            className="modal-buttons-footer__button"
            size="large"
            type="button"
            onClick={() => {
              if (currentCount === 2) {
                setSelectedRequiredParameters(formik.values);
              }
              setCurrentCount(currentCount - 1);
            }}
          />
        )}
        <Button
          text={
            currentCount !== formPages.length
              ? "Next"
              : editMode === "create"
              ? "Create Incident Type"
              : "Update Incident Type"
          }
          className="modal-buttons-footer__button"
          buttonStyle="primary"
          size="large"
          onClick={handleSubmit}
        />
      </div>
    );
  };

  const getFormTitle = currentCount => {
    return formPages[currentCount - 1] ? formPages[currentCount - 1] : "";
  };

  const getContent = (currentCount, formik) => {
    if (!currentCount || !formik) {
      return;
    }

    const { errors, touched, handleChange } = formik;

    if (currentCount === 1) {
      return (
        <>
          <div className="task-input">
            <FormInput
              fieldName="Name"
              name={"name"}
              id="name"
              handleChange={handleChange}
              touched={touched}
              errors={errors}
              autoComplete="off"
            />
          </div>
          <div className="task-input">
            <FormInput
              fieldName="Description"
              name={"description"}
              id="description"
              handleChange={handleChange}
              touched={touched}
              errors={errors}
              autoComplete="off"
              as="textarea"
            />
          </div>
          <div className="task-input mb-4">
            <label className="label">Priority</label>
            <ReactSelect
              id="priority"
              name="priority"
              required
              value={{
                value: formik.values.priority,
                label: formik.values.priority
                  ? formik.values.priority
                  : "Select from below",
              }}
              handleChange={data => {
                if (data === null) {
                  formik.setFieldValue("priority", "");
                }
                if (!isEmpty(data) && !!data.value && !!data.label) {
                  formik.setFieldValue("priority", data.value);
                }
              }}
              selectOptions={taskPrioritiesList.map(option => {
                return {
                  value: option,
                  label: option || "",
                };
              })}
              customMenuClass="default-select-options-container"
              customMenuListClass="default-select-options-list"
              customValueContainerClass="default-select-value-container"
              customControlClass="default-select-control"
              customOptionClass="default-select-list-item"
            />
            {errors.priority && touched.priority ? (
              <div className="input-feedback">{errors.priority}</div>
            ) : null}
          </div>
          <div className="task-input">
            <label className="label">Default Workflow (optional)</label>
            <ReactSelect
              id="workflow"
              name="workflow"
              value={{
                value: formik.values.workflow,
                label: formik.values.workflow
                  ? formik.values.workflow
                  : "Select from below",
              }}
              handleChange={data => {
                if (data === null) {
                  formik.setFieldValue("workflow", "");
                  setSelectedWorkflow("");
                  // Remove runtime params page from form
                  if (formPages.includes("Runtime Parameters")) {
                    let filteredPages = formPages.filter(
                      page => page !== "Runtime Parameters",
                    );
                    setFormPages(filteredPages);
                  }
                }
                if (!isEmpty(data) && !!data.value && !!data.label) {
                  if (!formPages.includes("Runtime Parameters")) {
                    setFormPages([...formPages, "Runtime Parameters"]);
                  }
                  formik.setFieldValue("workflow", data.value);
                  setSelectedWorkflow(data.value);
                }
              }}
              selectOptions={[
                ...workflowList.Self,
                ...workflowList.Fylamynt,
              ].map(option => {
                return {
                  value: option.Name,
                  label: option.Name,
                };
              })}
              customMenuClass="default-select-options-container"
              customMenuListClass="default-select-options-list"
              customValueContainerClass="default-select-value-container"
              customControlClass="default-select-control"
              customOptionClass="default-select-list-item"
            />
          </div>
        </>
      );
    }
    if (currentCount === 2) {
      return (
        <RuntimeParameters
          formik={formik}
          requiredParameters={requiredParameters}
        />
      );
    }
  };

  const getSidebar = () => {
    return (
      <div className="sidebar-list">
        {formPages.map((page, index) => (
          <li key={page} className="pb-20-px">
            {currentCount === index + 1 ? (
              <img
                src={RadioButtonIcon}
                alt="radio-icon"
                className="page-tick-icon"
              />
            ) : currentCount > index + 1 ? (
              <img
                src={CheckIcon}
                alt="radio-icon"
                className="page-tick-icon"
              />
            ) : (
              <img
                src={RadioButtonUnchecked}
                alt="radio-icon"
                className="page-tick-icon"
              />
            )}
            {page}
          </li>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (!(workflowList.Self.concat(workflowList.Fylamynt).length > 0)) {
      dispatch(fetchRunbooks("Self"));
    }
  }, [dispatch, workflowList.Self, workflowList.Fylamynt]);

  useEffect(() => {
    if (selectedWorkflow) {
      (async () => {
        try {
          let response = await Api.getRunbookVersion(selectedWorkflow, null);
          let reqParams = getRuntimeParameterInitialValues(response.Parameters);
          setSelectedRequiredParameters(reqParams);
          setRequiredParameters(response.Parameters);
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [selectedWorkflow, selectedType, editMode]);

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={onSubmitHandler}
      validationSchema={getValidationSchema(currentCount, requiredParameters)}
      enableReinitialize={true}
    >
      {formik => {
        return (
          <ModalWithSidebar
            onClose={() => closeModal(false)}
            title={
              editMode === "create"
                ? "Create New Incident Type"
                : "Edit Incident Type"
            }
            footer={getFooter(formik)}
            showClose={true}
            containerClass="w-90"
            sidebarComponent={getSidebar()}
            formTitle={getFormTitle(currentCount)}
          >
            <div className="task-panel">{getContent(currentCount, formik)}</div>
          </ModalWithSidebar>
        );
      }}
    </Formik>
  );
};

export default ManageTaskTypes;
