import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import "./Collaboration.scss";
import TabWrapper from "../Shared/TabWrapper/TabWrapper";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button } from "@components/ui";
import { MultiSelect } from "carbon-components-react";
import slackIcon from "@assets/images/snippets/icon-slack.svg";
import jiraIcon from "@assets/images/snippets/icon-jira.svg";
import zoomIcon from "@assets/images/snippets/icon-zoom.svg";
import { RouteConstants } from "../../../routes/Constants";
import {
  getWorkspaceUsersList,
  getCollaborationDetails,
  updateCollaborationDetails,
} from "@redux/actions/collaboration.actions";
import { fetchSettingsConnectors } from "@redux/actions/settingsPanel.action";
import {
  fetchJiraDetails,
  getJiraProjectsIssueFields,
} from "@redux/actions/snippets.actions";
import { taskPrioritiesList } from "@lib/utils/constants";
import { isEmpty } from "@lib/utils";

type CollaborationProps = {};

const Collaboration: React.FC<CollaborationProps> = () => {
  const formikRef = useRef(null);
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);

  const workspaceUsersList = useSelector(
    (state: RootStateOrAny) => state.collaborationReducer.workspaceUsersList,
  );

  const workspaceUsersMap = useSelector(
    (state: RootStateOrAny) => state.collaborationReducer.workspaceUsersMap,
  );

  const jiraProjectsList = useSelector(
    (state: RootStateOrAny) => state.snippetsReducer.jiraDetails.projects,
  );

  const issueTypesList = useSelector(
    (state: RootStateOrAny) => state.snippetsReducer.jiraDetails.issueTypes,
  );

  const collaborationDetails = useSelector(
    (state: RootStateOrAny) => state.collaborationReducer.collaborationDetails,
  );

  const connectors = useSelector(
    (state: RootStateOrAny) => state.settingsPanelReducer.connectors,
  );

  const {
    IncidentPriorities,
    SlackEnableCreate,
    SlackAddUsers,
    ZoomEnableCreate,
    JiraEnableCreate,
    JiraProject,
    JiraIssueType,
  } = collaborationDetails;

  const { slack, zoom, jira } = connectors;

  useEffect(() => {
    if (jira?.is_configured) {
      dispatch(fetchJiraDetails());
    }
  }, [dispatch, jira]);

  useEffect(() => {
    (async () => {
      try {
        let usersAction = {};
        let collabDetailsAction = {};
        if (slack?.is_configured) {
          usersAction = await dispatch(getWorkspaceUsersList());
          collabDetailsAction = await dispatch(getCollaborationDetails());
        } else if (slack) {
          collabDetailsAction = await dispatch(getCollaborationDetails());
        }
        if (
          (collabDetailsAction["type"] ===
            "FETCH_COLLABORATION_DETAILS_SUCCESS" &&
            usersAction["type"] === "FETCH_WORKSPACE_USERS_LIST_SUCCESS") ||
          (isEmpty(usersAction) &&
            collabDetailsAction["type"] ===
              "FETCH_COLLABORATION_DETAILS_SUCCESS")
        ) {
          setShowForm(true);
        }
      } catch (e) {
        setShowForm(true);
      }
    })();
  }, [dispatch, slack]);

  useEffect(() => {
    if (JiraProject) {
      dispatch(getJiraProjectsIssueFields(JiraProject));
    }
  }, [JiraProject, dispatch]);

  useEffect(() => {
    if (isEmpty(connectors)) {
      dispatch(fetchSettingsConnectors());
    }
  }, [dispatch, connectors]);

  const getValidationSchema = () => {
    return Yup.object({
      incidents: Yup.array().required("Please select incidents"),
      users: Yup.array().test("users", "Please select users", function (users) {
        const IsSlackEnabled = formikRef.current.values.isSlackEnabled;
        return !IsSlackEnabled || (IsSlackEnabled && users.length);
      }),
      project: Yup.string().test("project", "Please select project", function (
        project,
      ) {
        const IsJiraEnabled = formikRef.current.values.isJiraEnabled;
        return !IsJiraEnabled || (IsJiraEnabled && project);
      }),
      issueType: Yup.string().test(
        "issueType",
        "Please select issue type",
        function (issueType) {
          const IsJiraEnabled = formikRef.current.values.isJiraEnabled;
          return !IsJiraEnabled || (IsJiraEnabled && issueType);
        },
      ),
    });
  };

  const onSubmitHandler = async (values, actions) => {
    actions.setSubmitting(true);
    const payload = {
      IncidentPriorities: values.incidents.map(option => option.value),
      SlackEnableCreate: values.isSlackEnabled,
      SlackAddUsers: values.users.map(option => option.value),
      ZoomEnableCreate: values.isZoomEnabled,
      JiraEnableCreate: values.isJiraEnabled,
      JiraProject: values.project,
      JiraIssueType: values.issueType,
    };
    const action = await dispatch(updateCollaborationDetails(payload));
    if (action["type"] === "UPDATE_COLLABORATION_DETAILS_SUCCESS") {
      actions.resetForm({ values });
      actions.setSubmitting(false);
    }
  };
  return showForm ? (
    <TabWrapper
      showToggle={false}
      title="Collaboration Options"
      info="Combine the power of Slack, Zoom & Jira to collaborate with ease."
      description="Enable the following options to automatically create a private Slack channel, Zoom meeting and Jira issue. You can also disable the creation of these options and manually create from the incident details page."
    >
      <Formik
        innerRef={formikRef}
        initialValues={{
          users:
            SlackAddUsers?.map(option => {
              return {
                value: option,
                label: workspaceUsersMap[option] || "",
              };
            }) || [],
          incidents:
            IncidentPriorities?.map(option => {
              return {
                value: option,
                label: option || "",
              };
            }) || [],
          project: JiraProject || "",
          issueType: JiraIssueType || "",
          isSlackEnabled: SlackEnableCreate || false,
          isZoomEnabled: ZoomEnableCreate || false,
          isJiraEnabled: JiraEnableCreate || false,
        }}
        onSubmit={onSubmitHandler}
        validationSchema={getValidationSchema()}
      >
        {formik => {
          const { touched, errors, handleSubmit } = formik;
          return (
            <>
              <div className="collaborations-incidents">
                <div className="wrapper">
                  <div>
                    <label className="label mb-10-px">
                      Enable full collaboration mode for the following incident
                      priorities:
                    </label>
                    <MultiSelect
                      ariaLabel="MultiSelect"
                      default
                      id="incidents"
                      onChange={({ selectedItems }) => {
                        formik.setFieldValue("incidents", selectedItems);
                      }}
                      className="settings-multiselect"
                      initialSelectedItems={formik.values.incidents}
                      items={taskPrioritiesList.map(option => {
                        return {
                          value: option,
                          label: option || "",
                        };
                      })}
                      label="Select incident priorities"
                      itemToString={item => (item ? item.label : "")}
                    />
                    {errors["incidents"] && touched["incidents"] ? (
                      <div className="input-feedback">
                        {errors["incidents"]}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="toggle-container">
                {slack?.is_configured && (
                  <div className="toggle">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="slack"
                        id="slack-toggle"
                        checked={formik.values.isSlackEnabled}
                        onChange={e => {
                          formik.setFieldValue(
                            "isSlackEnabled",
                            e.target.checked,
                          );
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                    <span className="status ml-2">
                      {formik.values.isSlackEnabled ? "ON" : "OFF"}
                    </span>
                  </div>
                )}
                {!slack?.is_configured && (
                  <a
                    className="configure-link"
                    href={RouteConstants.slack.defaultUrl}
                  >
                    <div>
                      <div>Configure Slack</div>
                    </div>
                  </a>
                )}
              </div>
              <div
                className={`collaborations-options ${
                  !formik.values.isSlackEnabled || !slack?.is_configured
                    ? "disable"
                    : ""
                }`}
              >
                <div className="title-wrapper">
                  <div className="header">
                    <div className="left">
                      <img src={slackIcon} alt="slackIcon"></img>
                      <span className="title">
                        Create a private Slack channel by default on each new
                        incident
                      </span>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="mb-4">
                    <label className="label mb-10-px">
                      Select workspace users:
                    </label>
                    <MultiSelect
                      ariaLabel="MultiSelect"
                      default
                      id="users"
                      onChange={({ selectedItems }) => {
                        formik.setFieldValue("users", selectedItems);
                      }}
                      className={`settings-multiselect ${
                        !formik.values.isSlackEnabled || !slack?.is_configured
                          ? "disabled"
                          : ""
                      } ${false && "error"}`}
                      initialSelectedItems={formik.values.users}
                      items={workspaceUsersList}
                      label="Select workspace users"
                      itemToString={item => (item ? item.label : "")}
                      disabled={
                        !formik.values.isSlackEnabled || !slack?.is_configured
                      }
                    />
                    {errors["users"] && touched["users"] ? (
                      <div className="input-feedback">{errors["users"]}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="toggle-container">
                {zoom?.is_configured && (
                  <div className="toggle">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="zoom"
                        id="zoom-toggle"
                        checked={formik.values.isZoomEnabled}
                        onChange={e => {
                          formik.setFieldValue(
                            "isZoomEnabled",
                            e.target.checked,
                          );
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                    <span className="status ml-2">
                      {formik.values.isZoomEnabled ? "ON" : "OFF"}
                    </span>
                  </div>
                )}
                {!zoom?.is_configured && (
                  <a
                    className="configure-link"
                    href="/settings/integration/zoom/description"
                  >
                    <div>
                      <div>Configure Zoom</div>
                    </div>
                  </a>
                )}
              </div>
              <div
                className={`collaborations-options ${
                  !formik.values.isZoomEnabled || !zoom?.is_configured
                    ? "disable"
                    : ""
                }`}
              >
                <div className="title-wrapper">
                  <div className="header">
                    <div className="left">
                      <img src={zoomIcon} alt="zoomIcon"></img>
                      <span className="title">
                        Create a Zoom meeting by default on each new incident
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="toggle-container">
                {jira?.is_configured && (
                  <div className="toggle">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="jira"
                        id="jira-toggle"
                        checked={formik.values.isJiraEnabled}
                        onChange={e => {
                          formik.setFieldValue(
                            "isJiraEnabled",
                            e.target.checked,
                          );
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                    <span className="status ml-2">
                      {formik.values.isJiraEnabled ? "ON" : "OFF"}
                    </span>
                  </div>
                )}
                {!jira?.is_configured && (
                  <a
                    className="configure-link"
                    href={RouteConstants.jira.defaultUrl}
                  >
                    <div>
                      <div>Configure Jira</div>
                    </div>
                  </a>
                )}
              </div>
              <div
                className={`collaborations-options ${
                  !formik.values.isJiraEnabled || !jira?.is_configured
                    ? "disable"
                    : ""
                }`}
              >
                <div className="title-wrapper">
                  <div className="header">
                    <div className="left">
                      <img src={jiraIcon} alt="jiraIcon"></img>
                      <span className="title">
                        Create a Jira issue by default on each new incident
                      </span>
                    </div>
                  </div>
                </div>

                <div className="wrapper">
                  <div className="mb-4">
                    <label className="label mb-10-px">
                      Select Jira Project to create new tickets
                    </label>
                    <ReactSelect
                      id="projects"
                      name="projects"
                      className={`settings-multiselect ${
                        !formik.values.isJiraEnabled || !jira?.is_configured
                          ? "disabled"
                          : ""
                      } ${false && "error"}`}
                      value={{
                        value: formik.values.project,
                        label: formik.values.project
                          ? formik.values.project
                          : "Select from below",
                      }}
                      handleChange={data => {
                        if (data === null) {
                          formik.setFieldValue("project", "");
                          formik.setFieldValue("issueType", "");
                        }
                        if (!isEmpty(data) && !!data.value && !!data.label) {
                          formik.setFieldValue("project", data.value);
                          dispatch(getJiraProjectsIssueFields(data.value));
                        }
                      }}
                      selectOptions={jiraProjectsList?.map(option => {
                        return {
                          value: option,
                          label: option || "",
                        };
                      })}
                      isDisabled={
                        !formik.values.isJiraEnabled || !jira?.is_configured
                      }
                      customMenuClass="default-select-options-container"
                      customMenuListClass="default-select-options-list"
                      customValueContainerClass="default-select-value-container"
                      customControlClass="default-select-control"
                      customOptionClass="default-select-list-item"
                    />
                    {errors["project"] && touched["project"] ? (
                      <div className="input-feedback">{errors["project"]}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="label mb-10-px">
                      Select Issue Type for new tickets
                    </label>
                    <ReactSelect
                      id="issueType"
                      name="issueType"
                      value={{
                        value: formik.values.issueType,
                        label: formik.values.issueType
                          ? formik.values.issueType
                          : "Select from below",
                      }}
                      className={`settings-multiselect ${
                        !formik.values.isJiraEnabled || !jira?.is_configured
                          ? "disabled"
                          : ""
                      } ${false && "error"}`}
                      handleChange={data => {
                        if (data === null) {
                          formik.setFieldValue("issueType", "");
                        }
                        if (!isEmpty(data) && !!data.value && !!data.label) {
                          formik.setFieldValue("issueType", data.value);
                        }
                      }}
                      selectOptions={
                        formik.values.project
                          ? issueTypesList?.map(option => {
                              return {
                                value: option,
                                label: option || "",
                              };
                            })
                          : []
                      }
                      isDisabled={
                        !formik.values.isJiraEnabled || !jira?.is_configured
                      }
                      customMenuClass="default-select-options-container"
                      customMenuListClass="default-select-options-list"
                      customValueContainerClass="default-select-value-container"
                      customControlClass="default-select-control"
                      customOptionClass="default-select-list-item"
                    />
                    {errors["issueType"] && touched["issueType"] ? (
                      <div className="input-feedback">
                        {errors["issueType"]}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <Button
                text="Save Changes"
                buttonStyle="primary"
                size="small"
                className="save-btn"
                disabled={!formik.dirty}
                onClick={handleSubmit}
              />
            </>
          );
        }}
      </Formik>
    </TabWrapper>
  ) : (
    <></>
  );
};

export default Collaboration;
