import React, { useState } from "react";
import "../Filters.scss";
import TextInputView from "../../Layouts/TextInputView/TextInputView";
import AddButton from "../../Layouts/AddButton/AddButton";
import closeIcon from "@assets/images/icons/icon-close.svg";
import { Formik } from "formik";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";
import * as Yup from "yup";
import { Footer } from "../../Layouts/Footer/Footer";
import { ManageKeyValue } from "../../Layouts/ManageKeyValue/ManageKeyValue";
import { checkQuotes } from "@lib/utils";

const validationSchema = Yup.object({
  filterName: Yup.string()
    .required("Please enter a valid Name")
    .test("checkQuotes", "Please enter a valid Name", checkQuotes),
});

type ManageFilterProps = {
  title?: string;
  isEdit: boolean;
  selectedFilter: any;
  onCancel: () => void;
  onSave: (filter) => void;
};

const ManageFilter: React.FC<ManageFilterProps> = ({
  title = "Filter Details",
  isEdit = "",
  selectedFilter = {},
  onCancel,
  onSave,
}) => {
  let initialValues = {
    filterName: isEdit ? selectedFilter.Name : "",
  };
  const newValue = { inputValue: "" };
  const [filterValues, setFilterValues] = useState(
    isEdit ? selectedFilter?.Values : [],
  );
  const [showAddValuePopup, setShowAddValuePopup] = useState(false);
  const [isEditValue, setIsEditValue] = useState(false);
  const [selectedFilterValue, setSelectedFilterValue] = useState(newValue);

  /**
   * Called when formik's save button is clicked
   * to edit/add a filter
   * @param {object} values - formik values field
   * @param {object} actions - formik action object
   */
  const handleSubmit = (values, actions) => {
    if (filterValues.length) {
      onSave({ Name: values.filterName, Values: filterValues });
    } else {
      actions.setStatus({ message: "Please enter at least one value" });
    }
  };

  /**
   * Show/Hide Add/Edit Value popup
   */
  const toggleAddValue = () => {
    setShowAddValuePopup(!showAddValuePopup);
  };

  /**
   * Delete a value from filter
   * @param {String} value - value to be deleted
   */
  const onDelete = value => {
    setFilterValues(filterValues.filter(f => f !== value));
  };

  /**
   * Called when "+ Add Value" is clicked
   */
  const onAddValue = () => {
    setIsEditValue(false);
    setSelectedFilterValue(newValue);
    toggleAddValue();
  };

  /**
   * Called when "Edit value" is clicked
   * @param {String} value - value to be edited
   */
  const onEditValue = (value: string) => {
    setIsEditValue(true);
    setSelectedFilterValue({ inputValue: value });
    toggleAddValue();
  };

  /**
   * Edit/Add a value in filter
   * @param {String} value - edited or newly created value
   */
  const manageValue = value => {
    if (isEditValue) {
      let updateFilterValues = filterValues.map(fv => {
        if (fv === selectedFilterValue.inputValue) {
          return value.inputValue;
        }
        return fv;
      });
      setFilterValues(updateFilterValues);
    } else {
      setFilterValues([...filterValues, value.inputValue]);
    }
    toggleAddValue();
  };

  return (
    <div className="d-flex flex-column filters-add slide-out-from-right">
      <div className="d-flex filters-add-header">
        <label>{title}</label>
        <button onClick={onCancel} type="button" className="close-icon">
          <img
            src={closeIcon}
            alt="closeIcon"
            width="12"
            height="12"
            className="close-img"
          />
        </button>
      </div>
      <div className="clearfix"></div>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {formik => (
          <>
            <FormInput
              fieldName="Name"
              name="filterName"
              id="filterName"
              handleChange={formik.handleChange}
              touched={formik.touched}
              errors={formik.errors}
              autoComplete="off"
              className={"filters-add-input"}
            />
            <label className="label label__padding-left">Values</label>
            <div className="filters-manage-container">
              {!!filterValues &&
                filterValues.map(value => {
                  return (
                    <TextInputView
                      key={value}
                      value={value}
                      onEdit={() => onEditValue(value)}
                      onDelete={() => onDelete(value)}
                    />
                  );
                })}
            </div>
            {showAddValuePopup && (
              <ManageKeyValue
                isEdit={isEditValue}
                showKey={false}
                className="filter-width"
                selectedInput={selectedFilterValue}
                onCancel={toggleAddValue}
                onSave={value => manageValue(value)}
                type="Filters"
              />
            )}
            <AddButton title={"Add Value"} handleClick={onAddValue} />
            {!!formik.status && filterValues.length === 0 && (
              <div className="mb-10-px filters-value-error">
                {formik.status.message}
              </div>
            )}

            <div className="mb-2"></div>
            <Footer onCancel={onCancel} onSave={formik.handleSubmit} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default ManageFilter;
