import React from "react";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import NoneSelected from "./none-selected";

export default class SnippetDefault extends React.PureComponent {
  render() {
    return (
      <Consumer>
        {({ activeNode }) => (
          <TabCollection
            active="input"
            activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
            inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
            contentTop={50}
            tabs={["input", "output", "details"]}
          >
            <Tab title="Input" name="input">
              <TabContent>
                <NoneSelected />
              </TabContent>
            </Tab>
            <Tab title="Output" name="output">
              <TabContent>
                <NoneSelected />
              </TabContent>
            </Tab>
            <Tab title="Details" name="details">
              <TabContent>
                <NoneSelected />
              </TabContent>
            </Tab>
          </TabCollection>
        )}
      </Consumer>
    );
  }
}
