import React from "react";
import { hasKeys, generateId } from "@lib/utils";
import SnippetHeader from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/SnippetHeader/SnippetHeader";
import ActionSnippetHeader from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/ActionSnippetHeader/ActionSnippetHeader";

export default props => {
  const activeNode = props.activeNode;
  const outputs = props.outputs || activeNode.extras.runbookNode?.outputs;
  const trigger = activeNode.extras.runbookNode?.trigger || null;
  return (
    <div className="d-flex flex-column">
      {activeNode.extras.runbookNode.hasOwnProperty("snippetDef") ? (
        <SnippetHeader
          snippetDef={activeNode.extras.runbookNode.snippetDef}
          displayName={trigger?.display_name}
          iconName={trigger?.name}
        />
      ) : (
        <ActionSnippetHeader runbookNode={activeNode.extras.runbookNode} />
      )}

      <div className="output-container">
        <div className="output-table">
          <div className="output-row output-row-header">
            <div className="output-cell width-80-pct">Name</div>
            <div className="output-cell width-20-pct">Type</div>
          </div>
          {hasKeys(activeNode, "extras.runbookNode.outputs") &&
            outputs.map(item => (
              <div className="output-row" key={`${item.name}-${generateId()}`}>
                <div className="output-cell">{item.name}</div>
                <div className="output-cell">{item.type}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
