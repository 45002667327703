import flatMap from "lodash/flatMap";
import difference from "lodash/difference";
import { DirectedAcyclicGraph } from "../dag";
import { SSMActionNames } from "../ssm/strings";
import { StepTypeChecker } from "../neuropssteps/steptypechecker";
import { ParameterSet } from "../ssm/parameters";
import { SSMDocument } from "../ssm/ssmdocument";
import { hasKeys } from "@lib/utils";
import { StepTypes } from "../neuropssteps/strings";
import Bugsnag from "@bugsnag/js";

export class NeurOpsRunbook {
  // Construction
  /** Takes an SSMDocument object built by SSMReader */
  constructor(ssmDoc, name, tags, version) {
    if (!ssmDoc) {
      const parameters = new ParameterSet({
        AutomationAssumeRole: {
          default: "",
          description:
            "(Optional) The ARN role that allows Automation to execute this workflow.",
          type: "String",
        },
        WorkflowSession: {
          default: "{}",
          description:
            "(Optional) Tenant credentials to authorize workflow executions",
          type: "String",
        },
      });
      const mainSteps = [];
      const description = "";
      ssmDoc = new SSMDocument(description, parameters, mainSteps);
    }
    this.name = name || "New Workflow";
    this.tags = tags || [];
    this.version = version || "Draft";
    this.description = ssmDoc.description;
    // save any default values
    this.defaultValues = {
      alias: "",
      regionName: "",
    };
    this.unHealthySteps = [];

    this.ssmDoc = ssmDoc; // This is an SSMDocument object
    this.mainSteps = ssmDoc.mainSteps;
    this.mainStepIndex = {};
    this._dag = new DirectedAcyclicGraph({});
    this._indexAndGraphSteps();
    this._decorateSteps();
  }

  _indexAndGraphSteps() {
    let step;
    this.mainStepIndex = {};
    const newDAG = new DirectedAcyclicGraph();
    this._dag = newDAG;
    for (step of this.ssmDoc.mainSteps) {
      this._indexAndGraph(step);
    }
  }

  _indexAndGraph(step) {
    if (!this.mainStepIndex[step.name]) {
      this.mainStepIndex[step.name] = step;
      this._dag.addNode(step.name, step.nextSteps());
    }
  }

  // Step Mutators - adding, linking, and removing steps
  addStep(step) {
    if (step && !this.mainStepIndex[step.name]) {
      this.ssmDoc.mainSteps.push(step);
      this.mainStepIndex[step.name] = step;
      this._dag.addNode(step.name, step.nextSteps());
      this._decorateStep(step);
    }
  }

  removeStep(nodeStep) {
    const runbookNode = nodeStep;
    runbookNode && runbookNode.destructor();
    let mainSteps = this.mainSteps.filter(
      step => step.name !== runbookNode?.name,
    );

    for (let out of runbookNode?.outputs || []) {
      for (let input of out.consumers() || []) {
        input.resetSource();
      }
    }

    let step;
    for (step of this.mainSteps) {
      if (step.nextStep === runbookNode?.name) {
        delete step.nextStep;
      }
      const hasSetDefault = step.hasOwnProperty("setDefault");
      if (hasSetDefault && step.action === SSMActionNames.BRANCH) {
        if (step.getDefault() === runbookNode?.name) {
          step.setDefault(undefined);
        }
        let choice;
        for (choice of step.choices) {
          if (choice.nextStep === runbookNode?.name) {
            delete choice.nextStep;
          }
        }
      }
    }

    this.mainSteps = mainSteps;
    this.ssmDoc.mainSteps = mainSteps;
    this._indexAndGraphSteps();
    this.isNodeDeleted = true;
    this._decorateSteps();

    return;
  }

  renameStep(oldName, newName) {
    delete this.mainStepIndex[oldName];
    let step;
    for (step of this.mainSteps) {
      if (step.nextStep === oldName) {
        step.nextStep = newName;
      }
      const hasSetDefault = step.hasOwnProperty("setDefault");
      if (hasSetDefault && step.action === SSMActionNames.BRANCH) {
        if (step.getDefault() === oldName) {
          step.setDefault(newName);
        }
        let choice;
        for (choice of step.choices) {
          if (choice.nextStep === oldName) {
            choice.nextStep = newName;
          }
        }
      }
    }
    this._dag.renameNode(oldName, newName);
  }

  replaceNonConditionalStep(oldStep, newStep) {
    const oldName = oldStep.name;
    const newName = newStep.name;
    const index = this.mainSteps.findIndex(
      step => step && step.name === oldStep.name,
    );
    for (let out of oldStep.outputs || []) {
      for (let input of out.consumers()) {
        input.resetSource();
      }
    }
    newStep.nextStep = oldStep.nextStep;
    delete oldStep.nextStep;
    this.mainSteps[index] = newStep;
    this.mainStepIndex[newStep.name] = newStep;
    delete this.mainStepIndex[oldName];

    this.renameStep(oldName, newName);
    this._decorateStep(newStep);
  }

  linkActions(source, target) {
    // Throws if there's a loop
    this._dag.addEdge(source.name, target.name);

    if (source.ssm.action === SSMActionNames.BRANCH) {
      source.addChoiceForTarget(target.name, this);
    } else {
      if (
        target.ssm.action === SSMActionNames.BRANCH &&
        hasKeys(target, "updateForPreviousStep")
      ) {
        target.updateForPreviousStep(source);
      }
      source.nextStep = target.name;
    }

    // this method was defined by reference in  editor-design-area.js
    this.notifyRunbookUpdate(true);
  }

  removeLink(source, target) {
    if (!source || !target) {
      return;
    }
    if (source.ssm.action === SSMActionNames.BRANCH) {
      // WARNING: Assumes this link is remove this link is
      // the ONLY one with this target.  This removes ALL choices/defaults to
      // targetName
      source.removeTarget(target.name);
    } else {
      source.nextStep = undefined;
    }
    this._dag.removeEdge(source.name, target.name);
    if (
      target.hasOwnProperty("stepType") &&
      target.stepType === "ConditionalStep"
    ) {
      this.conditionalTargetLinkRemoval(source, target);
    }

    this.notifyRunbookUpdate(true);
  }

  conditionalTargetLinkRemoval = (source, target) => {
    Object.keys(target.choices).forEach(key => {
      let input = target.choices[key].condition.input;
      if (hasKeys(input, "source.sourceValue")) {
        input.source.sourceValue = null;
      }
    });
    this.notifyRunbookUpdate(true);
  };

  updateDAGAtStep(step) {
    const nextSteps = step.nextSteps();
    const oldNextSteps = this._dag.children(step.name);
    const add = difference(nextSteps, oldNextSteps);
    const remove = difference(oldNextSteps, nextSteps);
    let child;
    for (child of add) {
      this._dag.addEdge(step.name, child);
    }
    for (child of remove) {
      this._dag.removeEdge(step.name, child);
    }
  }

  // Input helpers
  inputsWithoutSource() {
    return flatMap(
      this.mainSteps
        .filter(step => !!step.parameterInputs)
        .map(step => step.parameterInputs.filter(input => !input.source)),
    );
  }

  runbookParameterInputs() {
    return flatMap(
      this.mainSteps
        .filter(step => step && !!step.parameterInputs)
        .map(
          step =>
            (step &&
              (step.allInputs ? step.allInputs() : step.parameterInputs).filter(
                input =>
                  input.source &&
                  input.source.type === "userProvided" &&
                  !!input.source.sourceValue,
              )) ||
            [],
        ),
    );
  }

  setParameter(param) {
    this.ssmDoc.parameters.parameters[param.name] = param;

    let input;
    for (input of this.runbookParameterInputs()) {
      const inputParameter = input.source.sourceValue;
      if (inputParameter.name === param.name) {
        input.source.sourceValue = param;
      }
    }
  }

  renameParameter(oldName, newName) {
    const param = this.ssmDoc.parameters.parameters[oldName];
    param.name = newName;
    delete this.ssmDoc.parameters.parameters[oldName];
    this.ssmDoc.parameters.parameters[param.name] = param;

    let input;
    for (input of this.runbookParameterInputs()) {
      const inputParameter = input.source.sourceValue;
      if (inputParameter.name === oldName) {
        input.source.sourceValue = param;
      }
    }
  }

  updateParameters() {
    const parameterSSM = {
      AutomationAssumeRole: {
        default: "",
        description:
          "(Optional) The ARN role that allows Automation to execute this workflow.",
        type: "String",
      },
      WorkflowSession: {
        default: "{}",
        description:
          "(Optional) Tenant credentials to authorize workflow executions",
        type: "String",
      },
    };

    const aliasParam = this.ssmDoc.parameters.parameters["alias"];
    const regionNameParam = this.ssmDoc.parameters.parameters["regionName"];

    try {
      if (
        this.mainSteps.find(
          step =>
            step &&
            (step?.stepType === StepTypes.LoopStep ||
              step?.stepType === StepTypes.WaitForResourceStep),
        )
      ) {
        parameterSSM["alias"] = aliasParam
          ? {
              type: "String",
              description: aliasParam.spec.description,
              default: aliasParam.spec.default, // TODO: replace default value with targets API
            }
          : {
              type: "String",
              description: "(Required) Target Account Alias for AssumeRole",
              default: this.defaultValues.alias, // TODO: replace default value with targets API
            };
        parameterSSM["regionName"] = regionNameParam
          ? {
              type: "String",
              description:
                regionNameParam.spec.description ||
                "(Optional) AWS region for workflow execution",
              default: regionNameParam.spec.default,
            }
          : {
              type: "String",
              description: "(Optional) AWS region for workflow execution",
              default: this.defaultValues.regionName,
            };
      }

      let input;
      for (input of this.runbookParameterInputs()) {
        const inputParameter = input.source.sourceValue;

        parameterSSM[inputParameter.name] = inputParameter.spec.toSSM();
        // TODO: remove this after implementing parameter editor
        parameterSSM[inputParameter.name].default =
          input.source.default || inputParameter.spec?.default;
      }
      console.log("saving parameter set", { parameterSSM });
      this.ssmDoc.parameters = new ParameterSet(parameterSSM);
    } catch (e) {
      console.error(`error creating parameters`, e);
    }
  }

  sortSteps() {
    const sortedMainSteps = this._dag.toplogicalSort();
    this.mainSteps = sortedMainSteps.map(name => this.mainStepIndex[name]);
    this.ssmDoc.mainSteps = this.mainSteps;
  }

  setDescription(description) {
    this.description = description;
    this.ssmDoc.description = description;
  }

  setTags(tags) {
    this.tags = tags;
  }

  toSSM() {
    // first we need to update our parameters
    this.sortSteps();
    this.updateParameters();
    return this.ssmDoc.toSSM();
  }

  // Workflow Step decorators
  _decorateSteps() {
    let step;
    for (step of this.ssmDoc.mainSteps) {
      this._decorateStep(step);
    }
  }
  _decorateStep(step) {
    step.runbook = this;

    step.predecessors = node => {
      const predecessorIDs = this._dag.predecessors(node?.name || step.name);
      return predecessorIDs.map(name => this.mainStepIndex[name]);
    };

    step.successors = () => {
      const predecessorIDs = this._dag.successors(step.name);
      return predecessorIDs.map(name => this.mainStepIndex[name]);
    };

    step.legalSuccessors = () => {
      const predecessorIDs = this._dag.predecessors(step.name);
      predecessorIDs.push(step.name);
      return this.mainSteps.filter(
        mainStep => !predecessorIDs.find(id => id === mainStep?.name),
      );
    };

    if (
      StepTypeChecker.isInvokeLambdaStep(step) ||
      StepTypeChecker.isBranchStep(step) ||
      StepTypeChecker.isWaitForResourceStep(step) ||
      StepTypeChecker.isJSONPathStep(step)
    ) {
      step.readInputSources(this);
    }
    //add the parameters
    const paramInputs = (step.parameterInputs || []).filter(
      input => input.source?.type === "userProvided",
    );

    let input;
    for (input of paramInputs) {
      const sourceVal = input.source.sourceValue;
      if (!!sourceVal && !this.ssmDoc.parameters.parameters[sourceVal.name]) {
        // This happens when we have a parameter requested in an input but not yet in the
        // runbook. For example, when we drop a new node in the editor or configure an
        // action node with an operation that requires new inputs, we will create
        // parameters for them.  This is where they get added to the runbook.
        this.ssmDoc.parameters.parameters[sourceVal.name] = sourceVal;
      }
    }
  }

  stepOutputType = step => {
    // return given step output type
    // step: string
    step = step.split(".");
    let type = "String";
    try {
      type = this.mainSteps
        .filter(fStep => fStep.name === step[0])?.[0]
        .outputs.filter(output => output.name === step[1])?.[0].type;
    } catch (error) {
      Bugsnag.notify(error);
    } finally {
      return type;
    }
  };

  getStepByName = name => {
    let step = this.mainSteps.filter(
      step => step.name === name.split(".")?.[0],
    );
    if (step?.length) {
      return step[0];
    } else {
      return null;
    }
  };
}
