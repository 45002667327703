import { Base64 } from "js-base64";
import {
  InvokeLambdaStep,
  readInputSourcesFromSSM,
} from "../ssm/invokelambdastep";
import { SSMActionNames } from "../ssm/strings";
import { RunbookStepInput, RunbookStepOutput } from "./nodeinputoutput";
import { StepTypes, ControlNames } from "../neuropssteps/strings";
import { checkInvalidRequiredInput } from "../ssm/util";
import {
  _isHealthyStringTransformers,
  _isHealthyTriggerService,
  _isHealthyWithPriorityErrors,
} from "../utils/utils";
import { kebabToCamel, capitalizeFirstLetter, isEmpty } from "@lib/utils";

export class SnippetStepNew extends InvokeLambdaStep {
  /**
   * Creates simple SSM Step for the snippet, with no
   * input sources.
   * @param {*} snippetDef
   */
  static fromSnippetDefinition(snippetDef, trigger) {
    const name = `${trigger ? `${trigger?.snippet_name}` : snippetDef.name}_${
      snippetDef.insertionOrder
        ? snippetDef.insertionOrder
        : Math.floor(1000 * Math.random())
    }`;
    const action = SSMActionNames.INVOKE_LAMBDA;
    const outputs = [];
    let field;
    for (field of Object.keys(snippetDef.outputs)) {
      const output = new RunbookStepOutput(
        null,
        field,
        snippetDef.outputs[field],
      );
      outputs.push(output.toSSM());
    }
    this._addNeurOpsDefaultOutputs(outputs);

    const ssm = {
      name,
      action,
      onFailure: "Abort",
      maxAttempts: 1,
      inputs: {
        FunctionName: snippetDef.content.lambda_arn,
        Payload: "",
        ClientContext: SnippetStepNew._writeSnippetDetailsForContext(
          snippetDef,
        ),
      },
      outputs,
      isEnd: true,
      editorNodeId: snippetDef.editorNodeId,
    };
    return new SnippetStepNew(ssm, snippetDef, trigger);
  }

  /**
   * Construct a new RunbookStepOutput for "execution_status"
   * if it's not yet in the outputs
   * @param {*} outputs
   */
  static _addNeurOpsDefaultOutputs(outputs) {
    if (isEmpty(outputs)) return;

    if (
      !outputs.find(out => {
        const name = out.name || out.Name;
        return name === "execution_status";
      })
    ) {
      const exeStatus = new RunbookStepOutput(
        null,
        "execution_status",
        "String",
      ).toSSM();
      outputs.push(exeStatus);
    }
  }

  /**
   * Write the Snippet detail information in a format that can be packaged into
   * the lambda context.
   */
  static _writeSnippetDetailsForContext(snippetDefinition) {
    const {
      description,
      version,
      tags,
      outputs,
      optional_inputs,
      required_inputs,
    } = snippetDefinition || {};
    const obj = {
      custom: {
        description,
        version,
        tags,
        outputs,
        required_inputs,
        optional_inputs,
      },
    };
    return Base64.encode(JSON.stringify(obj));
  }

  constructor(stepJSON, snippetDef, trigger) {
    super(stepJSON);
    this.stepType = StepTypes.SnippetStep;
    this.editable = true;

    this.snippetDef = snippetDef;
    this.parameterInputs = [];
    this.trigger = trigger;

    if (snippetDef?.required_inputs?.length) {
      snippetDef.required_inputs.forEach(paramInput => {
        if (paramInput.hidden && paramInput.name === "integration") {
          return;
        } else {
          this.parameterInputs.push(
            new RunbookStepInput(this, paramInput.name, paramInput, true),
          );
        }
      });
    }
    if (snippetDef?.optional_inputs?.length) {
      snippetDef.optional_inputs.forEach(paramInput => {
        this.parameterInputs.push(
          new RunbookStepInput(this, paramInput.name, paramInput, false),
        );
      });
    }
    let field;
    if (snippetDef?.outputs) {
      for (field of Object.keys(snippetDef.outputs)) {
        // Just check that it is there. The outputs should be entirely
        // defined by the SSM document.
        //eslint-disable-next-line
        const found = this.outputs.find(out => {
          return out.name === field;
        });
        if (!found) {
          console.warn(`${field} not found`);
          console.warn(
            `An output '${field}' is given in a action definition but missing in the SSM step.
            action definition: ${JSON.stringify(snippetDef, undefined, 2)}
              SSM: ${JSON.stringify(this.ssm, undefined, 2)}`,
          );
          this.outputs.push(
            new RunbookStepOutput(this, field, snippetDef.outputs[field]),
          );
        } else {
          // get the type from the snippetDef
          found.type = snippetDef.outputs[field];
        }
      }
    }
  }

  writeInputParams() {
    let defaultParameters = ['"workflow_session": "{{ WorkflowSession }}"'];
    if (this.trigger) {
      defaultParameters = defaultParameters.concat([
        `"integration":"${this.trigger.name}"`,
        `"display_name":${JSON.stringify(this.trigger.display_name)}`,
      ]);
    }
    const inputlist = this.parameterInputs
      .map(input => input.writeInputParam())
      .filter(param => !!param)
      .concat(defaultParameters)
      .join(", ");
    return `{ ${inputlist} }`;
  }

  readInputSources(runbook) {
    const inputsWithSources = readInputSourcesFromSSM(this, runbook);
    if (this.parameterInputs) {
      // This is a SnippetStepNew and already expects certain inputs
      for (let input of this.parameterInputs) {
        if (
          input.hidden &&
          input.source &&
          input.source.sourceValue &&
          typeof input.source.sourceValue === "object" &&
          input.name === "alert_body"
        ) {
          input.source.sourceValue.name = `${capitalizeFirstLetter(
            kebabToCamel(input.name),
          )}`;
          input.source.default = "{}";
          input.source.sourceValue.spec.ssm.default = "{}";
          input.source.sourceValue.isRequired = true;
          input.source.sourceValue.spec.description = input.description;
          input.source.sourceValue.spec.ssm.description =
            input.source.sourceValue.spec.description;
          runbook.ssmDoc.parameters[`${kebabToCamel(input.name)}`] =
            input.source.sourceValue;
        }

        const newSource = inputsWithSources[input.name];
        if (
          newSource &&
          newSource?.type !== "deleted" &&
          !runbook.isNodeDeleted
        )
          input.source = newSource;
      }
    }
  }

  toSSM() {
    const outputs = this.outputs ? this.outputs.map(out => out.toSSM()) : [];

    return {
      name: this.name,
      action: "aws:invokeLambdaFunction",
      onFailure: "Abort",
      maxAttempts: 1,
      inputs: {
        FunctionName: this.snippetDef?.content.lambda_arn,
        Payload: this.writeInputParams(),
        ClientContext: SnippetStepNew._writeSnippetDetailsForContext(
          this.snippetDef,
        ),
      },
      outputs,
      nextStep: this.nextStep,
      isEnd: !this.nextStep,
    };
  }

  isHealthyStep() {
    /**
     * @case Trigger Step Healthy check
     */
    if (this.snippetDef.service.includes("Trigger")) {
      return _isHealthyTriggerService(this?.parameterInputs);
    } else if (
      this.snippetDef?.at_least_one ||
      this.snippetDef?.mutual_exclusion
    ) {
      return _isHealthyWithPriorityErrors(
        this?.parameterInputs,
        this.snippetDef?.at_least_one,
        this.snippetDef?.mutual_exclusion,
      );
    } else {
      switch (this.snippetDef.name) {
        case ControlNames.StringTransformers:
          return _isHealthyStringTransformers(this?.parameterInputs);
        default:
          return checkInvalidRequiredInput(this?.parameterInputs);
      }
    }
  }
}
