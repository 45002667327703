import React, { useEffect } from "react";
import { TextInput } from "@components/ui";
import { executionStatusMap } from "@lib/utils/constants";
import { MultiSelect } from "carbon-components-react";

const mapStatus = (statusArr = []) =>
  statusArr.map((status, key) => ({
    id: key,
    text: status,
    label: status,
  }));

const unmapStatus = (statusArrObj = []) =>
  statusArrObj.map(statusObj => {
    return statusObj.text;
  });

let executionStatuses = mapStatus(Object.values(executionStatusMap));

export default props => {
  useEffect(() => {
    // Condition to clear selections on `Clear All Filters` click (work-around)
    if (!props.selectedStatuses?.length) {
      const ele = document.querySelectorAll(
        ".executions-status-filter .bx--list-box__selection ",
      );
      if (ele?.length > 0) {
        ele[0].click();
      }
    }
  }, [props.selectedStatuses]);
  return (
    <div className="d-flex justify-content-between">
      <TextInput
        placeholder="Search by Workflow Name"
        name="executions-search"
        className="text-input text-input__darker"
        onChange={props.updateSearchTerm}
        value={props.searchTerm}
      />
      <MultiSelect
        ariaLabel="MultiSelect"
        default
        id="execution-status-multiselect"
        onChange={({ selectedItems }) => {
          props.updateFilterByStatuses(unmapStatus(selectedItems));
        }}
        className="executions-status-filter"
        items={executionStatuses}
        label="Select Status"
        itemToString={item => (item ? item.text : "")}
      />
    </div>
  );
};
