import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { Button, DataTable, Confirm, FTNotification } from "@components/ui";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";
import ManageTaskTypes from "./ManageTaskTypes";
import tableColumn from "./utils/tableColumn";
import Api from "@lib/api/api";
import { toggleDeleteConfirmBox } from "@redux/actions/AWSSettings.actions";
import {
  updateRecordsCurrentPage,
  updateRecordsPerPage,
  updateSortColumn,
} from "@redux/actions/common.actions";
import { isFetching } from "@redux/actions/settingsPanel.action";
import cancelIcon from "@assets/images/icons/icon-close.svg";
import { fetchAllTaskTypes } from "@redux/actions/taskManagement.actions";

import "./TaskTypes.scss";

const initialTypeValue = {
  TaskTypeId: "",
  meta: {
    name: "",
    description: "",
    workflow: {
      name: "",
      param: {},
    },
  },
};

type taskType = {
  TaskTypeId: number;
  meta: {
    name: string;
    description: string;
    workflow?: {
      name: string;
      params?: object;
    };
  };
};

const filterList = (data, searchTerm) => {
  if (!searchTerm || !data) {
    return;
  }
  let searchVal = searchTerm.toLowerCase();
  return data.filter(
    item =>
      item.TaskTypeId?.toString()?.includes(searchVal) ||
      item.meta?.name?.toLowerCase()?.includes(searchVal) ||
      item.meta?.description?.toLowerCase()?.includes(searchVal) ||
      item.meta?.workflow?.name?.toLowerCase()?.includes(searchVal) ||
      item.meta?.source?.toLowerCase()?.includes(searchVal),
  );
};

type TaskTypesProps = {};

const TaskTypes: React.FC<TaskTypesProps> = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [toggleModal, setToggleModal] = useState(false);
  const [editMode, setEditMode] = useState("create");
  const [selectedType, setSelectedType] = useState(initialTypeValue);
  const [filteredData, setFilteredData] = useState(null);

  const taskTypesList: taskType[] = useSelector(
    (state: RootStateOrAny) => state.taskManagementReducer.allTaskTypesList,
  );

  // Redux
  const showConfirm = useSelector(
    (state: RootStateOrAny) => state.uiReducer.showConfirm,
  );

  const taskTypesCPage = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.currentPages?.taskTypesCPage,
  );

  const taskTypesRecords = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.recordsPerPage?.taskTypesRecords,
  );

  const { sortField, sortFieldDirection } = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.sortingDetails?.taskTypes || {},
  );

  const dispatch = useDispatch();

  // Functions
  const changeTaskTypesCurrentPage = cPage => {
    dispatch(
      updateRecordsCurrentPage({
        tableName: "taskTypesCPage",
        cPage: cPage,
      }),
    );
  };

  const changeTaskTypesRowsPerPage = rows => {
    dispatch(
      updateRecordsPerPage({
        tableName: "taskTypesRecords",
        cPageRecords: rows,
      }),
    );
  };

  const handleTaskTypesSort = (column, sortDirection) => {
    dispatch(
      updateSortColumn({
        tableName: "taskTypes",
        sortField: column?.selector,
        sortFieldDirection: sortDirection,
      }),
    );
  };

  const onEdit = row => {
    setSelectedType(row);
    setEditMode("edit");
    setToggleModal(true);
  };

  const onDelete = row => {
    setSelectedType(row);
    dispatch(toggleDeleteConfirmBox(true));
  };

  const columns = tableColumn(onEdit, onDelete);

  const filterData = searchTerm => {
    if (!searchTerm) {
      return;
    } else {
      let data = filterList(taskTypesList, searchTerm);
      setFilteredData(data);
    }
  };

  useEffect(() => {
    if (!searchTerm) {
      setFilteredData(taskTypesList);
    }
  }, [searchTerm, setFilteredData, taskTypesList]);

  useEffect(() => {
    dispatch(fetchAllTaskTypes());
  }, [dispatch]);

  return (
    <div className="p-20-px">
      {showConfirm && (
        <Confirm
          heading="Delete Confirmation"
          cancelText="Cancel"
          confirmText="Delete"
          message="The entry for this Incident Type will be deleted."
          confirmValue={selectedType.meta?.name || ""}
          confirmRequiredText={`Confirm by typing '${
            selectedType.meta?.name || ""
          }' below`}
          verifiedCallback={async () => {
            dispatch(isFetching(true, ""));
            try {
              dispatch(toggleDeleteConfirmBox(false));
              let { TaskTypeId: id } = selectedType;
              await Api.deleteTaskType(id);
              dispatch(fetchAllTaskTypes());
              FTNotification.success({
                title: "Incident Type Deleted Successfully!",
              });
            } catch (error) {
              FTNotification.error({
                title: "Could not delete Incident Type",
                message: error?.message || "",
              });
            }
            dispatch(isFetching(false, ""));
          }}
        />
      )}
      <div className="d-flex mt-20-px mb-50-px">
        <div className="w-100">
          <input
            name="Search"
            placeholder="Search"
            className="text-input text-input__darker"
            autoComplete="off"
            value={searchTerm}
            onChange={event => setSearchTerm(event.target.value)}
            onKeyPress={event => {
              if (event.key === "Enter") {
                filterData(searchTerm);
              }
            }}
          />
          <button
            type="button"
            className="reset-button__darker"
            onClick={() => {
              setSearchTerm("");
            }}
          >
            <img src={cancelIcon} alt="" />
          </button>
        </div>
        <Button
          className="btn-new-type"
          buttonStyle="primary"
          size="medium"
          text="New Type"
          icon="icon-plus.svg"
          onClick={() => {
            setEditMode("create");
            setSelectedType(initialTypeValue);
            setToggleModal(true);
          }}
        ></Button>
      </div>
      {toggleModal && (
        <ManageTaskTypes
          selectedType={selectedType}
          editMode={editMode}
          closeModal={setToggleModal}
        />
      )}
      {Array.isArray(filteredData) && filteredData.length > 0 ? (
        <div className="aws-settings-list task-wrapper">
          <DataTable
            columns={columns}
            data={filteredData}
            fixedHeader={true}
            fixedScrollHeight={"60px"}
            pagination={true}
            currentPage={taskTypesCPage}
            recordsPerPage={taskTypesRecords}
            onChangeCurrentPage={changeTaskTypesCurrentPage}
            onChangeRowsPerPage={changeTaskTypesRowsPerPage}
            defaultSortField={sortField}
            defaultSortAsc={sortFieldDirection === "asc" ? true : false}
            onSort={handleTaskTypesSort}
          />
        </div>
      ) : searchTerm.length > 0 ? (
        <NoResultFound
          message="No results found"
          detail="Try adjusting your search to find what you're looking for."
          className="mt-100-px"
          errImgSrc="search-es"
          fallbackComponent={
            <span className="reset-search" onClick={() => setSearchTerm("")}>
              Clear Search
            </span>
          }
        />
      ) : (
        <NoResultFound
          message="Add your first Incident Type"
          detail="To add Incident Type, click the 'Add New' button in the top-right of this screen."
          className="mt-100-px"
          errImgSrc="settings-es"
        />
      )}
    </div>
  );
};

export default TaskTypes;
