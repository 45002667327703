import React from "react";
import { OverflowMenu } from "@components/ui";

export default (onRemove, onEdit) => {
  return [
    {
      name: "Template Id",
      selector: "templateId",
      grow: 2,
    },
    {
      name: "Repo Id",
      selector: "repoId",
      grow: 2,
    },
    {
      name: "File Path",
      selector: "filePath",
      grow: 2,
    },
    {
      name: "Ref",
      selector: "ref",
      grow: 2,
    },
    {
      name: "",
      selector: "options",
      cell: row => (
        <OverflowMenu>
          <div className="button-grey" onMouseDown={() => onEdit(row)}>
            Edit
          </div>
          <div className="button-delete" onMouseDown={() => onRemove(row)}>
            Delete
          </div>
        </OverflowMenu>
      ),
      grow: 1,
    },
  ];
};
