import React from "react";
import { Consumer } from "../execution-run-details-lib/execution-run-details.context";
import {
  convertToObject,
  getStepOutputs,
  getStepFailure,
} from "../execution-run-details-lib/execution-run-details.helpers";
import { hasKeys } from "@lib/utils";
import { JsonView } from "@components/ui";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";

export default () => {
  const fetchOutputData = outputs =>
    Object.keys(outputs).map(key => {
      let output = outputs[key];
      if (typeof output !== "string") {
        output = JSON.stringify(output);
      }
      try {
        output = JSON.parse(output);
        if (typeof output === "boolean") {
          output = JSON.stringify(output);
        }
      } catch (e) {}
      return (
        <div className="detail-table-row" key={key}>
          <div className="detail-table-row-child detail-table-row-child-left">
            {key}
          </div>
          <div className="detail-table-row-child detail-table-row-child-right position-relative">
            {(["errorMessage", "stackTrace"].includes(key) &&
              ["string", "object", "array"].includes(typeof output)) ||
            ["object", "array"].includes(typeof output) ? (
              <JsonView
                id={`execution-run-detail-output`}
                data={output}
                showCopy={true}
                className="detail-table-json-container"
                jsonViewClass="detail-table-json-view"
              ></JsonView>
            ) : (
              output
            )}
          </div>
        </div>
      );
    });
  return (
    <Consumer>
      {({ executionRunDetails }) => {
        if (
          executionRunDetails.Outputs &&
          ((hasKeys(executionRunDetails, "Outputs.Payload") &&
            executionRunDetails.Outputs.Payload.length) ||
            (hasKeys(executionRunDetails, "Outputs.OutputPayload") &&
              executionRunDetails.Outputs.OutputPayload.length) ||
            (hasKeys(executionRunDetails, "Outputs.output") &&
              executionRunDetails.Outputs.output.length))
        ) {
          const outputs = convertToObject(getStepOutputs(executionRunDetails));
          return (
            <div>
              <div className="mb-20-px">
                This execution step generates the following output parameters:
              </div>
              <div className="d-flex flex-column mb-20-px">
                {Array.isArray(outputs)
                  ? outputs && outputs.map(output => fetchOutputData(output))
                  : fetchOutputData(outputs)}
              </div>
            </div>
          );
        } else if (executionRunDetails.FailureDetails?.Details) {
          const failureDetails = convertToObject(
            getStepFailure(executionRunDetails),
          );
          return (
            <>
              <div className="mb-10-px">
                This execution step results in the following failure:
              </div>
              <div className="d-flex flex-column">
                {fetchOutputData(failureDetails)}
              </div>
            </>
          );
        } else {
          return (
            <NoResultFound
              message="No results found"
              detail="This step has no output parameters."
              className="mt-100-px"
            />
          );
        }
      }}
    </Consumer>
  );
};
