import React from "react";
import "./UserProfile.scss";
import { ProfileHeader } from "./utils/Header";
import ProfileInfo from "./utils/ProfileInfo";
import ChangePassword from "./utils/ChangePassword";
import AccountPlan from "./utils/AccountPlan";
import { TabCollection, Tab, TabContent } from "@components/ui/tabs";

const UserProfile: React.FC = () => {
  return (
    <>
      <div className="d-flex flex-column">
        <ProfileHeader />
        <br />
        <br />
        <TabCollection
          className={`tabs-menu-border`}
          tabs={["information", "password", "account-plan"]}
        >
          <Tab title={`Information`} name={`information`}>
            <TabContent>
              <ProfileInfo />
            </TabContent>
          </Tab>
          <Tab title={`Password`} name={`password`}>
            <TabContent>
              <ChangePassword />
            </TabContent>
          </Tab>
          <Tab title={`Account Plan`} name={`account-plan`}>
            <TabContent>
              <AccountPlan />
            </TabContent>
          </Tab>
        </TabCollection>
      </div>
    </>
  );
};

export default UserProfile;
