import React from "react";
import { Form, Modal } from "@components/ui";
import { Consumer } from "../../../../runbook-editor-lib/runbook-editor.context";

const getFooter = (toggleRunbookModal, isLoading) => (
  <div className="modal-buttons-footer__editor">
    <button
      type="button"
      className="footer-btn footer-btn-cancel"
      onClick={toggleRunbookModal}
    >
      Cancel
    </button>
    <button
      type={"submit"}
      isLoading={isLoading}
      className="footer-btn footer-btn-save"
    >
      Create
    </button>
  </div>
);

export default class EditorSaveNewVersionModal extends React.Component {
  state = {
    startLoading: false,
  };
  selectVersionRef = React.createRef();

  submitForm = (callBack, notifyRunbookUpdate) => {
    this.setState({
      startLoading: true,
    });
    callBack(this.selectVersionRef.current.value);
    notifyRunbookUpdate(true);
    this.props.onClose();
  };

  render() {
    const { onClose } = this.props;
    return (
      <Consumer>
        {({ versions, loadVersion, notifyRunbookUpdate }) => {
          return (
            <React.Fragment>
              <Form
                name="save-runbook-version"
                onSubmit={() => {
                  this.submitForm(loadVersion, notifyRunbookUpdate);
                }}
              >
                <Modal
                  onClose={onClose}
                  title={`Create New Version`}
                  showClose={true}
                  coverClass={`modal-cover__editor`}
                  containerClass={`modal-container__editor-transactional`}
                  contentClass={`modal-content__editor-transactional`}
                  titleClass={`modal-title__json`}
                  closeClass={`modal-close__editor`}
                  footerClass={`modal-footer__editor`}
                  footer={getFooter(onClose, this.state.startLoading)}
                >
                  <div>
                    <div className="mb-10-px">Based on version:</div>
                    <select
                      className="editor-dropdown__versions"
                      ref={this.selectVersionRef}
                    >
                      {versions.map(version => {
                        return (
                          <option
                            key={version.DocumentVersion}
                            value={version.DocumentVersion}
                          >
                            {version.DocumentVersion}.0{" "}
                            {version.IsDefaultVersion ? `Default` : ``}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Modal>
              </Form>
            </React.Fragment>
          );
        }}
      </Consumer>
    );
  }
}
