import { connect } from "react-redux";
import { fetchRunbooks, searchAndSort } from "@redux/actions/runbooks.actions";
import {
  deleteRunbook,
  fetchRunbookSchedules,
} from "@redux/actions/runbook.action";
import Runbooks from "../Runbooks";
import { RUNBOOK_SET_ACTIVE, SHOW_CONFIRM } from "@redux/types";
import {
  toggleFirstLogin,
  disableSplashScreen,
} from "@redux/actions/apikeys.actions";
import { fetchAccountPlanInfo } from "@redux/actions/accountPlan.actions";
import {
  setSelectedTriggerForWF,
  fetchTriggers,
} from "@redux/actions/common.actions";

const mapState = ({
  runbooksReducer,
  runbookReducer,
  uiReducer,
  getStartedReducer,
  APIKeysReducer,
  accountPlanReducer,
}) => ({
  showLoading: runbooksReducer.showLoading,
  message: runbooksReducer.message,
  runbookList: runbooksReducer.runbookList,
  runbookNew: runbookReducer.runbookNew,
  runbookDuplicate: runbookReducer.runbookDuplicate,
  fetchingRunbookName: runbookReducer.fetchingRunbookName,
  runbookListFiltered: runbooksReducer.runbookListFiltered,
  runbookSearchTerm: runbooksReducer.runbookSearchTerm,
  showConfirm: uiReducer.showConfirm,
  runbookIsDeleting: runbookReducer.isDeleting,
  deletingMessage: runbookReducer.message,
  isNewRunbook: runbookReducer.isNewRunbook,
  isAccountDetailFetched: getStartedReducer.isAccountDetailFetched,
  userDefaultAccounts: getStartedReducer.userDefaultAccounts,
  firstLogin: APIKeysReducer.firstLogin,
  quotas: accountPlanReducer.quotas,
});

const mapDispatch = dispatch => ({
  fetchRunbooks: owner => dispatch(fetchRunbooks(owner)),
  setCurrentRunbook: runbook =>
    dispatch({
      type: RUNBOOK_SET_ACTIVE,
      payload: runbook,
    }),
  setShowConfirm: show =>
    dispatch({
      type: SHOW_CONFIRM,
      payload: show,
    }),
  deleteRunbook: (runbook, owner) => dispatch(deleteRunbook(runbook, owner)),
  fetchRunbookSchedules: runbook => dispatch(fetchRunbookSchedules(runbook)),
  toggleFirstLogin: flag => dispatch(toggleFirstLogin(flag)),
  disableSplashScreen: flag => dispatch(disableSplashScreen(flag)),
  searchAndSort: (
    searchTerm,
    activeOwner,
    sortType,
    actualRunbookList,
    resortRunbooksList,
    selectedCategories,
  ) =>
    dispatch(
      searchAndSort(
        searchTerm,
        activeOwner,
        sortType,
        actualRunbookList,
        resortRunbooksList,
        selectedCategories,
      ),
    ),
  fetchAccountPlanInfo: () => dispatch(fetchAccountPlanInfo()),
  fetchTriggers: () => dispatch(fetchTriggers()),
  setSelectedTriggerForWF: trigger =>
    dispatch(setSelectedTriggerForWF(trigger)),
});

export default connect(mapState, mapDispatch)(Runbooks);
