import React, { useState, useEffect, useCallback, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  saveConnecterSchema,
  isFetching,
} from "@redux/actions/settingsPanel.action";
import { Formik } from "formik";
import * as Yup from "yup";
import FormInput from "../../../settings-panel-components/SettingsRightConfigPanel/FormInput";
import { Redirect } from "react-router";
import Api from "@lib/api/api";
import { FTNotification } from "@components/ui";
import { RouteConstants } from "../../../../../routes/Constants";
import { FormFooter } from "@components/shared/GetStarted/Utils/Helpers";
import { useLocation } from "react-router-dom";
import { getRedirectUrl } from "@lib/utils";

const ERROR_MSG = {
  accountSid: "AccountSid is required",
  authToken: "AuthToken is required",
};

const validationSchema = Yup.object({
  accountSid: Yup.string()
    .required(ERROR_MSG.accountSid)
    .trim(ERROR_MSG.accountSid),
  authToken: Yup.string()
    .required(ERROR_MSG.authToken)
    .trim(ERROR_MSG.authToken),
});

type PROP_MODEL = {
  message: string;
  formSubmittedSuccess: boolean;
  saveConnecterSchema: any;
  isFetching: any;
  setShowWarningModal: (input) => void;
  resetForm: boolean;
};

const ConnectorForm = (props: PROP_MODEL) => {
  const search = useLocation().search;
  const isEditMode = new URLSearchParams(search).get("edit") ? true : false;
  const [showForm, setShowForm] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const formikRef: React.RefObject<any> = useRef({});

  useEffect(() => {
    if (props.resetForm) {
      formikRef.current?.resetForm({
        values: {
          accountSid: "",
          authToken: "",
        },
      });
    }
  }, [props.resetForm]);

  /**
   * Application local state
   */
  const [redirect, setRedirect] = useState({
    doRedirect: false,
    redirectUrl: {},
  });

  /**
   * Sets state to navigate to settings page
   */
  const goBack = () => {
    setRedirect({
      doRedirect: true,
      redirectUrl: {
        pathname: getRedirectUrl(RouteConstants["integration"].defaultUrl, [
          { key: "connector", value: "twilio" },
        ]),
        search: "?edit=true",
        state: { from: "Integrations" },
      },
    });
  };

  /**
   * Prepares payload
   * @param formData
   */
  const getPayload = (formData: any) => {
    return { credentials: formData };
  };

  /**
   * This function is used to validate creadentials
   * @param accountSid
   * @param authToken
   * @param actions
   */
  const validateToken = async (
    accountSid: string,
    authToken: string,
    actions: any,
  ) => {
    const response = await Api.validateTwilioToken({ authToken, accountSid });
    if (response?.ERROR?.response.status === 401) {
      const error = "Account SID or Auth Token is invalid";
      FTNotification.error({
        title: "Validation Failed",
        message: error,
        timeout: 10,
      });

      actions.setSubmitting(false);
      props.isFetching(false, "");
      return false;
    }

    return true;
  };

  /**
   * This is separate function to avoid code duplication
   * @param actions Formik actions
   */
  const setErrorStatus = () => {
    FTNotification.error({
      title: "Oops! Something went wrong !!!",
    });
  };

  /**
   * This function handles formik submission
   * @param formInput Formik input data
   * @param actions formik actions object
   */
  const onSubmitHandle = async (formInput: any, actions: any) => {
    actions.setSubmitting(true);
    const payload = getPayload(formInput);

    // Validate credentials before saving it
    const validationResponse = await validateToken(
      formInput.accountSid,
      formInput.authToken,
      actions,
    );

    if (!validationResponse) {
      // Abort, because validation failed
      return;
    }

    try {
      let response;
      if (isEditMode) {
        response = await Api.updateConnectorSchema(payload, "twilio");
      } else {
        response = await Api.saveConnectorSchema(payload, "twilio");
      }

      if (response?.statusCode === 200 || response?.statusCode === 201) {
        FTNotification.success({
          title: "Connector details saved successfully!!!",
        });

        // Navigate to settings page
        setTimeout(() => {
          goBack();
        }, 1500);
      } else {
        setErrorStatus();
      }
    } catch (error) {
      setErrorStatus();
    }
    actions.setSubmitting(false);
    actions.resetForm({ values: formInput });
  };

  const getInitialValues = useCallback(async () => {
    if (isEditMode) {
      try {
        let response = await Api.getConnectorConfigDetails("twilio");
        if (response?.body?.constructor.name === "String") {
          let data = JSON.parse(response.body);
          if (data.constructor.name === "String") {
            data = JSON.parse(data);
          }

          let initialValues = Object.keys(data).reduce((acc, key) => {
            acc[key] = data[key];
            return acc;
          }, {});
          setInitialValues(initialValues);
          setShowForm(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setInitialValues({
        accountSid: "",
        authToken: "",
      });
      setShowForm(true);
    }
  }, [isEditMode]);

  useEffect(() => {
    getInitialValues();
  }, [getInitialValues]);

  return (
    <>
      {redirect.doRedirect && <Redirect to={redirect.redirectUrl} push />}
      {showForm && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandle}
          innerRef={formikRef}
        >
          {formik => (
            <div className="d-flex flex-column">
              {props.setShowWarningModal(formik.dirty)}
              <h2 className="pt-10-px pb-10-px mb-2">Authorize Fylamynt</h2>
              <div className="gs-panel-right-field-wrapper">
                <FormInput
                  fieldName="Account Sid"
                  name="accountSid"
                  id="accountSid"
                  handleChange={formik.handleChange}
                  touched={formik.touched}
                  errors={formik.errors}
                  autoComplete="new-password"
                />
              </div>
              <div className="gs-panel-right-field-wrapper">
                <FormInput
                  fieldName="Auth Token"
                  name="authToken"
                  id="authToken"
                  handleChange={formik.handleChange}
                  touched={formik.touched}
                  errors={formik.errors}
                  autoComplete="new-password"
                  isMasked={true}
                />
              </div>
              <FormFooter
                onSubmit={formik.handleSubmit}
                isEditMode={isEditMode}
                disableSubmit={!formik.dirty}
              />
            </div>
          )}
        </Formik>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  message: state.runbooksReducer.message,
  formSubmittedSuccess: state.settingsPanelReducer.formSubmittedSuccess,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveConnecterSchema,
      isFetching,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectorForm);
