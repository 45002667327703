import React from "react";
import { replaceAllSpecialCharWithSpace } from "@lib/utils";

export default props => {
  const getupdateDuration = updatedDate => {
    const currentTime = +new Date();
    const timeDifference = currentTime - +new Date(updatedDate);
    const timeDifferenceInMinutes = Math.ceil(timeDifference / (1000 * 60));
    const timeDifferenceInHours = Math.ceil(timeDifference / (1000 * 60 * 60));
    const timeDifferenceInDays = Math.ceil(
      timeDifference / (1000 * 60 * 60 * 24),
    );
    const timeDifferenceInMonths = Math.ceil(
      timeDifferenceInDays / (365.25 / 12),
    );
    if (timeDifferenceInMinutes < 60) {
      return `${timeDifferenceInMinutes} minutes`;
    } else if (timeDifferenceInHours < 24) {
      return `${timeDifferenceInHours} hours`;
    } else if (timeDifferenceInDays < 31) {
      return `${timeDifferenceInDays} days`;
    } else {
      return `${timeDifferenceInMonths} months`;
    }
  };
  return (
    <div className="runbook-item__meta">
      <h2 className="runbook-item__title">
        <span>{replaceAllSpecialCharWithSpace(props.runbook.Name)}</span>
      </h2>
      <div className="runbook-item__info">
        <div>{`Updated ${getupdateDuration(
          props.runbook.UpdatedDate,
        )} ago`}</div>
      </div>
      <div className="runbook-item__description">
        {props.runbook.Description}
      </div>
    </div>
  );
};
