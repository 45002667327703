import React from "react";
import PropTypes from "prop-types";
import "./button.scss";
import { Consumer } from "../lib/ui.context";

const Button = props => {
  let backgroundImage;
  if (props.icon) {
    const icon = props.icon.match(/\./g) ? props.icon : `${props.icon}.svg`;
    backgroundImage = require(`@assets/images/icons/${icon}`);
  }
  if (props.isLoading) {
    backgroundImage = require("@assets/images/icons/wait.svg");
  }

  return (
    <Consumer>
      {context => (
        <button
          type={props.type}
          onClick={props.type === "submit" ? null : props.onClick}
          className={`btn-${props.buttonStyle} ${props.className} ${
            props.disabled ? "btn-disabled" : ""
          }`}
          style={props.style}
          disabled={props.disabled || props.isLoading} // button should be disabled if it's currently loading or directly disabled
        >
          <div className="btn-inner-wrap">
            <div>
              {props.text}
              {props.children}
            </div>
            {backgroundImage && (
              <div
                className={`btn-with-icon btn-with-icon-${props.size}`}
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                }}
              />
            )}
          </div>
        </button>
      )}
    </Consumer>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large", "none"]),
  type: PropTypes.oneOf(["button", "submit", "link"]),
  buttonStyle: PropTypes.oneOf([
    "none",
    "primary",
    "secondary",
    "warning",
    "link",
  ]),
  style: PropTypes.object,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  icon: PropTypes.string,
};

Button.defaultProps = {
  onClick: () => {
    return;
  },
  className: "button-default",
  size: "small",
  style: { maxWidth: "12rem" },
  type: "button",
  buttonStyle: "none",
  disabled: false,
  isLoading: false,
};

export default Button;
