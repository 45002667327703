import React from "react";
import { withRouter, Redirect } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";

// Actions
import { isFetching } from "@redux/actions/settingsPanel.action";

// Utils
import { isEmpty, snakeToPascal, getRedirectUrl } from "@lib/utils";
import Api from "@lib/api/api";
import { RouteConstants } from "../../../../routes/Constants";

// Components
import { FTNotification } from "@components/ui";

// Styles
import "./ConnectorFormOAuth.scss";

class ConnectorFormOAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doRedirect: false,
      redirectUrl: "",
      formMessage: "",
      formSubmitStatus: "",
      isEditMode: null,
      connectorName: null,
    };
  }

  componentDidMount() {
    const url = new URL(window.location.href);
    const isEditMode = url.searchParams.get("edit");
    const status = url.searchParams.get("status");
    const message = url.searchParams.get("message");
    const oAuthCode = url.searchParams.get("code");
    const path = this.props.location?.pathname?.split("/");
    const connectorName = path?.[3];

    this.setState({
      isEditMode,
      connectorName,
    });

    this.completeOAuth(oAuthCode, connectorName);

    if (!!status && status !== null) {
      this.setState({ formMessage: message, formSubmitStatus: status });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      let search = this.props.location.search;
      const isEditMode = new URLSearchParams(search).get("edit");
      this.setState({
        isEditMode,
      });
    }
  }

  onSubmitHandle = async () => {
    try {
      const { connectorName } = this.state;
      let response = await Api.getConnectorIntegrationUrl(connectorName);
      const integrationUrl =
        !isEmpty(response) && !isEmpty(response.body)
          ? JSON.parse(response.body).integration_url
          : "";

      if (!!integrationUrl) {
        window.location.href = integrationUrl;
      } else {
        FTNotification.error({
          title: "Oops! Something went wrong !!!",
          message: this.state.formMessage,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getIntegrationMessage = connectorName => {
    const message = {
      zoom: "Click the button below to integrate Zoom app with Fylamynt",
      slack:
        "Click the button below to add the Fylamynt Slack app to your Slack Workspace",
    };
    return message[connectorName];
  };

  getImageURI = connectorName => {
    if (!connectorName) return;
    try {
      const imageURI = require(`@assets/images/screenshots/settings-${connectorName}/add-to-${connectorName}.svg`);
      return imageURI;
    } catch (e) {
      console.log(e);
    }
  };

  completeOAuth = async (oAuthCode, connectorName) => {
    if (!!oAuthCode && oAuthCode !== null) {
      try {
        const payload = this.props.location?.search;
        const result = await Api.completeOAuth(connectorName, payload);
        if (result?.statusCode === 200) {
          const message =
            typeof result?.body === "string"
              ? JSON.parse(result?.body)?.message
              : `${snakeToPascal(
                  connectorName,
                )} integration with Fylamynt is successful!`;

          this.props.history.push({
            pathname: getRedirectUrl(RouteConstants["integration"].defaultUrl, [
              { key: "connector", value: connectorName },
            ]),
            search: "?edit=true",
            state: { from: "Integrations" },
          });

          FTNotification.success({
            title: "Success",
            message,
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  render() {
    const { connectorName, doRedirect, redirectUrl } = this.state;
    return (
      <>
        {doRedirect && <Redirect to={redirectUrl} push />}

        <Formik initialValues={{}} onSubmit={this.onSubmitHandle}>
          {formik => (
            <div className="d-flex flex-column">
              <h2 className="pt-10-px pb-10-px mb-2">Authorize Fylamynt</h2>
              <h4>
                <b>{this.getIntegrationMessage(connectorName)}</b>
              </h4>
              <div className="gs-form-footer">
                <img
                  src={this.getImageURI(connectorName)}
                  alt={`Add to ${snakeToPascal(connectorName)}`}
                  onClick={!this.state.isEditMode ? formik.handleSubmit : null}
                  className={`connector-integration-add ${
                    this.state.isEditMode ? "disable" : ""
                  }`}
                />
              </div>
            </div>
          )}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = state => ({
  message: state.runbooksReducer.message,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      isFetching,
    },
    dispatch,
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConnectorFormOAuth),
);
