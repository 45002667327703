import React, { useState } from "react";
import { useSelector } from "react-redux";
import GetStarted from "@components/shared/GetStarted/GetStartedForm";
import ConnectorForm from "./utils/ConnectorForm";
import { Link } from "react-router-dom";
import { RouteConstants } from "../../../routes/Constants";

export default function CloudwatchSetup() {
  const aliasList = useSelector(
    state => state.getStartedReducer.userDefaultAccounts,
  );

  const [alias, setAlias] = useState(aliasList.find(alias => alias.is_default));

  const header = (
    <div className="ml-n1 mb-20-px pl-2">
      <div className="d-flex align-items-baseline">
        <Link to="/settings/integrations" className="breadcrumb-link pointer">
          Settings
        </Link>{" "}
        <span className="breadcrumb-pike">/</span>
        <Link
          to={RouteConstants.cloudwatch.url}
          className="breadcrumb-link pointer"
        >
          CloudWatch
        </Link>
      </div>
    </div>
  );

  return (
    <GetStarted
      connectorForm={ConnectorForm}
      connectorProps={{aliasChanged: setAlias, alias}}
      showCustomForm={true}
      customHeader={header}
      customContainerClass={`cw-form-container`}
      customTitleClass={`cw-form-title`}
      customPanelClass={`cw-form-panel`}
      customPanelLeftClass={`cw-form-left`}
    />
  );
}
