import React from "react";

/**
 *
 * @param inlineStyleObject
 */

export const getSelectStyle = ({
  input = {},
  indicatorSeparator = {},
  indicatorsContainer = {},
}) => ({
  input: styles => ({ ...styles, color: "#f3f3f3", ...input }),
  indicatorSeparator: styles => ({
    ...styles,
    marginRight: "3px",
    marginLeft: "3px",
    visibility: "hidden",
    ...indicatorSeparator,
  }),
  indicatorsContainer: styles => ({
    ...styles,
    marginRight: "0.5rem",
    ...indicatorsContainer,
  }),
});

/**
 * Function to support fuzzy search for react-select
 */

export const fuzzySearch = (option: any, searchVal: string = "") => {
  const optionValue = option?.label?.toLowerCase() || "";
  const optionLength = option?.label?.length || 0;
  const searchValLength = searchVal.length;

  if (searchValLength > optionLength) {
    /*
     * Case where searched value is longer than option label
     */
    return false;
  } else if (searchValLength === optionLength) {
    /*
     * case where searched value length is eq to option label's length
     * in that case, returns true/false if value is eq to option or not
     */
    return searchVal.toLowerCase() === optionValue;
  } else {
    /**
     * case where searched value is a substring of option label
     */
    const labelRegex = ".*" + searchVal.split("").join(".*") + ".*";
    const queryRegex = new RegExp(labelRegex, "ig");
    return queryRegex.test(optionValue);
  }
};

export const formatOptionLabel = ({ label }, { inputValue }) => {
  let diplayValue = label;
  let searchText = inputValue.toLowerCase();

  let labelArr = label.split("");

  for (let i = 0; i < labelArr.length; i++) {
    if (!searchText) {
      break;
    }
    if (searchText.includes(labelArr[i].toLowerCase())) {
      searchText = searchText.replace(labelArr[i].toLowerCase(), "");
      labelArr[i] = `<span class="header-light-blue">${labelArr[i]}</span>`;
    }
  }
  diplayValue = labelArr.join("");

  return (
    <span
      data-test={label}
      dangerouslySetInnerHTML={{ __html: diplayValue }}
      className="rs-option-inner"
    />
  );
};
