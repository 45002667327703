import React, { useState } from "react";
import { IconBurger } from "@components/ui/icons";
import { Consumer } from "../../../runbook-editor-lib/runbook-editor.context";
import MenuList from "./MenuList/MenuList";
import "./MenuList/MenuList.scss";

const Logo = () => {
  const [component, setComponent] = useState("NeuropsLogo");
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <Consumer>
      {({ runbook, activeRunbookVersion, goBack }) => (
        <div
          className="editor-menu-item"
          onMouseOver={() => setComponent("IconBurger")}
          onMouseOut={() => setComponent("NeuropsLogo")}
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <div>
            {component === "NeuropsLogo" ? (
              <div className="logo-wrap-editor editor-menu-fylamynt-logo"></div>
            ) : (
              <IconBurger
                name="iconburger"
                className="editor-menu-item-icon editor-menu-item-icon-logo"
              />
            )}
          </div>
          {toggleMenu ? (
            <>
              <div
                onClick={() => {
                  setToggleMenu(false);
                  setComponent("NeuropsLogo");
                }}
                className="list-overlay"
              ></div>
              <MenuList
                runbookName={runbook.Name}
                isDraftState={activeRunbookVersion}
                goBack={goBack}
              />
            </>
          ) : null}
        </div>
      )}
    </Consumer>
  );
};

export default Logo;
