import React from "react";
import { filterSystemTags } from "@lib/utils";

import "./PanelTag.scss";

export default props => {
  return (
    <div className="runbook-item__tag-wrap">
      {props.tags &&
        filterSystemTags(props.tags)
          .slice(0, 2)
          .map((tag, idx) => {
            return (
              <React.Fragment key={idx}>
                <div className="tag tag_primary-light">
                  <div className="tag__inner-wrap">
                    <div>{tag}</div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
      {filterSystemTags(props.tags).length > 2 && (
        <div className="show-more__text">
          {`+${filterSystemTags(props.tags).length - 2} more`}
        </div>
      )}
    </div>
  );
};
