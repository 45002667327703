import { store } from "@redux/store";
import { isEmpty } from "@lib/utils";
import Api from "@lib/api/api";
import {
  CONNECTOR_CONFIGURATION_PANEL_SHOW,
  CONNECTOR_CONFIGURATION_SCHEMA_FETCH_SUCCESS,
  CONNECTOR_CONFIGURATION_FORM_SAVING,
  CONNECTOR_CONFIGURATION_FORM_SAVE_SUCCESS,
  CONNECTOR_CONFIGURATION_FORM_SAVE_ERROR,
  CONNECTOR_CONFIGURATION_INFO_FETCH_SUCCESS,
  CONNECTOR_CONFIGURATION_INFO_FETCH_ERROR,
  CONNECTORS_FETCH_SUCCESS,
  CONNECTORS_FETCH_ERROR,
  FETCHING_RUNBOOKS,
  CONNECTOR_STATUS_UPDATE_SUCCESS,
  CONNECTOR_STATUS_UPDATE_ERROR,
  FETCH_TERRAFORM_CLI_MODULES_SUCCESS,
  FETCH_TERRAFORM_CLI_MODULES_ERROR,
  CREATE_TERRAFORM_CLI_MODULE_SUCCESS,
  CREATE_TERRAFORM_CLI_MODULE_ERROR,
  DELETE_TERRAFORM_CLI_MODULE_SUCCESS,
  DELETE_TERRAFORM_CLI_MODULE_ERROR,
  UPDATE_TERRAFORM_CLI_MODULE_SUCCESS,
  UPDATE_TERRAFORM_CLI_MODULE_ERROR,
  FETCH_CLOUDFORMATION_TEMPLATE_SUCCESS,
  FETCH_CLOUDFORMATION_TEMPLATE_ERROR,
  CREATE_CLOUDFORMATION_TEMPLATE_SUCCESS,
  CREATE_CLOUDFORMATION_TEMPLATE_ERROR,
  DELETE_CLOUDFORMATION_TEMPLATE_SUCCESS,
  DELETE_CLOUDFORMATION_TEMPLATE_ERROR,
  UPDATE_CLOUDFORMATION_TEMPLATE_SUCCESS,
  UPDATE_CLOUDFORMATION_TEMPLATE_ERROR,
  CONNECTOR_ACTIONS_FETCH_SUCCESS,
  CACHE_ACTIONS,
  REMOVE_CACHED_ACTIONS,
} from "@redux/types";

export function fetchSettingsConnectors() {
  return async dispatch => {
    dispatch({
      type: FETCHING_RUNBOOKS,
      payload: true,
      message: "Fetching Connectors",
    });
    try {
      const response = await Api.getSettingsConnectors();
      if (!isEmpty(response) && !isEmpty(response.body)) {
        dispatch({
          type: CONNECTORS_FETCH_SUCCESS,
          payload:
            typeof response.body === "string"
              ? JSON.parse(response.body)
              : response.body,
        });
      }
      if (!isEmpty(response) && !isEmpty(response.ERROR)) {
        dispatch({
          type: CONNECTORS_FETCH_ERROR,
          payload: "Unexpected Error while Fetching Connectors Information",
        });
      }
    } catch (e) {
      if (!isEmpty(e)) {
        dispatch({
          type: CONNECTORS_FETCH_ERROR,
          payload: "Unexpected Error while Fetching Connectors Information",
        });
      }
    }
    dispatch({
      type: FETCHING_RUNBOOKS,
      payload: false,
      message: "",
    });
  };
}

export function toggleConfigPanel(showFlag) {
  return async dispatch => {
    dispatch({ type: CONNECTOR_CONFIGURATION_PANEL_SHOW, payload: showFlag });
  };
}

export function fetchConnectorSchema(connector) {
  return async dispatch => {
    const connectorSchemaInfo = await Api.getConnectorSchema(
      connector,
    ).catch(e => console.log(e));
    if (!isEmpty(connectorSchemaInfo) && !isEmpty(connectorSchemaInfo.body)) {
      dispatch({
        type: CONNECTOR_CONFIGURATION_SCHEMA_FETCH_SUCCESS,
        payload:
          typeof connectorSchemaInfo.body === "string"
            ? JSON.parse(connectorSchemaInfo.body)
            : connectorSchemaInfo.body,
      });
    }
  };
}

export function fetchConnectorActions(connector) {
  return async dispatch => {
    try {
      const actionsMap = store.getState().commonReducer.getStartedActionsMap;
      let connectorActions = actionsMap?.[connector];

      // If actions are not cached, fectch actions and cache them.
      if (isEmpty(connectorActions)) {
        connectorActions = await Api.getConnectorActions(connector);
        dispatch({
          type: CACHE_ACTIONS,
          payload: {
            connector,
            data:
              typeof connectorActions === "string"
                ? JSON.parse(connectorActions)
                : connectorActions,
          },
        });
      }

      if (!isEmpty(connectorActions)) {
        dispatch({
          type: CONNECTOR_ACTIONS_FETCH_SUCCESS,
          payload:
            typeof connectorActions === "string"
              ? JSON.parse(connectorActions)
              : connectorActions,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export const resetCachedActions = () => ({
  type: REMOVE_CACHED_ACTIONS,
});

export function saveConnecterSchema(data, connector) {
  return async dispatch => {
    dispatch({
      type: FETCHING_RUNBOOKS,
      payload: true,
      message: "Saving Connector Details",
    });
    let response = await Api.saveConnectorSchema(data, connector).catch(e =>
      dispatch({
        type: CONNECTOR_CONFIGURATION_FORM_SAVE_ERROR,
        payload: "Something went wrong while saving the form",
      }),
    );
    if (response && response.ERROR) {
      dispatch({
        type: CONNECTOR_CONFIGURATION_FORM_SAVE_ERROR,
        payload: response.ERROR.response.data.Error.Message,
      });
    } else {
      dispatch({
        type: CONNECTOR_CONFIGURATION_FORM_SAVE_SUCCESS,
        payload: true,
      });
    }
    dispatch({
      type: FETCHING_RUNBOOKS,
      payload: false,
      message: "",
    });
  };
}
export function updateConnecterSchema(data, connector) {
  return async dispatch => {
    dispatch({
      type: FETCHING_RUNBOOKS,
      payload: true,
      message: "Updating Connector Details",
    });
    let response = await Api.updateConnectorSchema(data, connector).catch(e =>
      dispatch({
        type: CONNECTOR_CONFIGURATION_FORM_SAVE_ERROR,
        payload: "Something went wrong while saving the form",
      }),
    );
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: CONNECTOR_CONFIGURATION_FORM_SAVE_SUCCESS,
        payload: true,
      });
    } else {
      dispatch({
        type: CONNECTOR_CONFIGURATION_FORM_SAVE_ERROR,
        payload: response.ERROR.response.data.Error.Message,
      });
    }

    dispatch({
      type: FETCHING_RUNBOOKS,
      payload: false,
      message: "",
    });
  };
}
export function savingConnecterSchema(bool) {
  return async dispatch =>
    dispatch({
      type: CONNECTOR_CONFIGURATION_FORM_SAVING,
      payload: bool,
    });
}

export function isFetching(_isFetching = false, msg = "") {
  return async dispatch =>
    dispatch({
      type: FETCHING_RUNBOOKS,
      payload: _isFetching,
      message: _isFetching ? msg : "",
    });
}

export function fetchConnectorConfigDetails(name) {
  return async dispatch => {
    const connectorConfigInfo = await Api.getConnectorConfigDetails(
      name.toLowerCase(),
    ).catch(e => {
      return dispatch({
        type: CONNECTOR_CONFIGURATION_INFO_FETCH_ERROR,
        payload: "Unexpected Error while Fetching Connector Information",
      });
    });
    if (!isEmpty(connectorConfigInfo) && !isEmpty(connectorConfigInfo.body)) {
      return dispatch({
        type: CONNECTOR_CONFIGURATION_INFO_FETCH_SUCCESS,
        payload:
          typeof connectorConfigInfo.body === "string"
            ? JSON.parse(connectorConfigInfo.body)
            : connectorConfigInfo.body,
      });
    } else {
      return dispatch({
        type: CONNECTOR_CONFIGURATION_INFO_FETCH_ERROR,
        payload: "Unexpected Error while Fetching Connector Information",
      });
    }
  };
}

export function updateStatus(connector, flag, status) {
  return async dispatch => {
    const notificationStatus = await Api.setStatus(
      connector,
      flag,
      status,
    ).catch(e => {
      return dispatch({
        type: CONNECTOR_STATUS_UPDATE_ERROR,
        payload: "Unexpected Error while updating status",
      });
    });
    if (!isEmpty(notificationStatus) && !isEmpty(notificationStatus.body)) {
      return dispatch({
        type: CONNECTOR_STATUS_UPDATE_SUCCESS,
        payload:
          typeof notificationStatus.body === "string"
            ? JSON.parse(notificationStatus.body)
            : notificationStatus.body,
      });
    } else {
      return dispatch({
        type: CONNECTOR_STATUS_UPDATE_ERROR,
        payload: "Unexpected Error while updating status",
      });
    }
  };
}

export function fetchTerraformCLIModules() {
  return async dispatch => {
    dispatch(isFetching(true, "Fetching Terraform CLI Modules"));
    const response = await Api.fetchTerraformCLIModules().catch(e => {
      dispatch(isFetching(false, ""));
      return dispatch({
        type: FETCH_TERRAFORM_CLI_MODULES_ERROR,
        payload: "Error in fetching Terraform CLI Modules",
      });
    });
    if (!isEmpty(response)) {
      dispatch(isFetching(false, ""));
      const body =
        typeof response?.body === "string"
          ? JSON.parse(response?.body)
          : response?.body;
      const modules =
        typeof body === "string" ? JSON.parse(body).modules : body?.modules;

      if (!isEmpty(modules)) {
        return dispatch({
          type: FETCH_TERRAFORM_CLI_MODULES_SUCCESS,
          payload: modules,
        });
      }
    }
  };
}

export const createTerraformCLIModule = (module, data) => {
  return async dispatch => {
    try {
      await Api.editTerraformCLIModule(module, data);
      return dispatch({
        type: CREATE_TERRAFORM_CLI_MODULE_SUCCESS,
        module,
        data,
      });
    } catch (e) {
      return dispatch({
        type: CREATE_TERRAFORM_CLI_MODULE_ERROR,
        payload: e,
      });
    }
  };
};

export const editTerraformCLIModule = (module, data) => {
  return async dispatch => {
    try {
      await Api.editTerraformCLIModule(module, data);
      return dispatch({
        type: UPDATE_TERRAFORM_CLI_MODULE_SUCCESS,
        module,
        data,
      });
    } catch (e) {
      return dispatch({
        type: UPDATE_TERRAFORM_CLI_MODULE_ERROR,
        payload: e,
      });
    }
  };
};

export const deleteTerraformCLIModule = module => {
  return async dispatch => {
    try {
      await Api.deleteTerraformCLIModule(module);
      return dispatch({
        type: DELETE_TERRAFORM_CLI_MODULE_SUCCESS,
        payload: module,
      });
    } catch (e) {
      return dispatch({
        type: DELETE_TERRAFORM_CLI_MODULE_ERROR,
        payload: e,
      });
    }
  };
};

/****  CloudFormation begins ****/

export function fetchCloudFormationTemplates() {
  return async dispatch => {
    dispatch(isFetching(true, "Fetching CloudFormation Templates"));
    try {
      const response = await Api.fetchCloudFormationTemplates();
      if (!isEmpty(response)) {
        dispatch(isFetching(false, ""));
        const body =
          typeof response?.body === "string"
            ? JSON.parse(response?.body)
            : response?.body;
        const templates =
          typeof body === "string"
            ? JSON.parse(body).templates
            : body?.templates;

        if (!isEmpty(templates)) {
          return dispatch({
            type: FETCH_CLOUDFORMATION_TEMPLATE_SUCCESS,
            payload: templates,
          });
        }
      }
    } catch (e) {
      dispatch(isFetching(false, ""));
      return dispatch({
        type: FETCH_CLOUDFORMATION_TEMPLATE_ERROR,
        payload: "Error in fetching CloudFormation Templates",
      });
    }
  };
}

export const createCloudFormationTemplate = template => {
  return async dispatch => {
    try {
      await Api.createCloudFormationTemplate(template);
      return dispatch({
        type: CREATE_CLOUDFORMATION_TEMPLATE_SUCCESS,
        template,
      });
    } catch (e) {
      return dispatch({
        type: CREATE_CLOUDFORMATION_TEMPLATE_ERROR,
        payload: e,
      });
    }
  };
};

export const editCloudFormationTemplate = (templateId, payload) => {
  return async dispatch => {
    try {
      await Api.editCloudFormationTemplate(templateId, payload);
      return dispatch({
        type: UPDATE_CLOUDFORMATION_TEMPLATE_SUCCESS,
        templateId,
        payload,
      });
    } catch (e) {
      return dispatch({
        type: UPDATE_CLOUDFORMATION_TEMPLATE_ERROR,
        payload: e,
      });
    }
  };
};

export const deleteCloudFormationTemplate = template => {
  return async dispatch => {
    try {
      await Api.deleteCloudFormationTemplate(template);
      return dispatch({
        type: DELETE_CLOUDFORMATION_TEMPLATE_SUCCESS,
        payload: template,
      });
    } catch (e) {
      return dispatch({
        type: DELETE_CLOUDFORMATION_TEMPLATE_ERROR,
        payload: e,
      });
    }
  };
};

/****  CloudFormation ends ****/
