import React, { useState } from "react";
import { Modal } from "@components/ui";
import OutputModal from "./snippet-panel/OutputModal";
import SnippetHeader from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/SnippetHeader/SnippetHeader";
import ActionSnippetHeader from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/ActionSnippetHeader/ActionSnippetHeader";

export default props => {
  const [displayModal, toggleModal] = useState(false);
  const activeNode = props.activeNode;
  const trigger = activeNode.extras.runbookNode?.trigger || null;
  return (
    <React.Fragment>
      <div>
        {props.activeNode.extras.runbookNode.hasOwnProperty("snippetDef") ? (
          <SnippetHeader
            snippetDef={props.activeNode.extras.runbookNode.snippetDef}
            displayName={trigger?.display_name}
            iconName={trigger?.name}
          />
        ) : (
          <ActionSnippetHeader
            runbookNode={props.activeNode.extras.runbookNode}
          />
        )}
        <div style={{ width: "100%", margin: 20 }}>
          <div
            className="link-light-blue"
            onClick={() => {
              toggleModal(!displayModal);
            }}
          >
            View Outputs
          </div>
        </div>
      </div>
      {displayModal && (
        <Modal
          onClose={() => {
            toggleModal(!displayModal);
          }}
          title={`Outputs: ${props.activeNode.extras.runbookNode.name}`}
          showClose={true}
          appendToPortal={true}
          showFooter={false}
          coverClass={`modal-cover__editor`}
          containerClass={`modal-container__editor`}
          contentClass={`modal-content__editor-json`}
          contentContainerClass={`modal-content-container__editor-json`}
          titleClass={`modal-title__json`}
          closeClass={`modal-close__editor`}
        >
          <OutputModal activeNode={props.activeNode} />
        </Modal>
      )}
    </React.Fragment>
  );
};
