import React from "react";

export default () => (
  <div className="instructions">
    <span>
      {" "}
      1. Copy url from <b>URL</b> section above and paste it in desired webhook
      application.
      <br />
      <br />
    </span>
    <span>
      {" "}
      2. Make sure at least one Fylamynt API Key is created in Settings / API
      Keys first. Select an API key from the drop down menu above.
      <br />
      <br />
    </span>
    <span>
      {" "}
      3. In your webhook application, configure request header to include
      parameter <b>‘x-api-key’: {`<api_key_value>`}</b>. “api_key value” is the
      value of the API key selected above.
    </span>
  </div>
);
