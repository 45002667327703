import { StepTypes, ControlNames } from "./strings";
import { InvokeLambdaStep } from "../ssm/invokelambdastep";
import { SSMActionNames } from "../ssm/strings";

export default class JSONPathStep extends InvokeLambdaStep {
  static fromControlNodeDefinition(snippetDef) {
    const { lambda_arn } = snippetDef.content;
    const payload = {
      json_path: "$",
      json_object: {},
      workflow_session: "{{ WorkflowSession }}",
    };
    const stepJSON = {
      ...snippetDef.content,
      name: ControlNames.JSONPathStep,
      action: SSMActionNames.INVOKE_LAMBDA,
      editorNodeId: snippetDef.editorNodeId,
      inputs: {
        FunctionName: lambda_arn,
        Payload: JSON.stringify(payload),
      },
      outputs: Object.keys(snippetDef.outputs).map(output => {
        return {
          Name: output,
          Selector: `$.${output}`,
          Type: snippetDef.outputs[output],
        };
      }),
    };

    return new JSONPathStep(stepJSON, snippetDef);
  }
  constructor(stepJSON, snippetDef) {
    super(stepJSON);
    this.stepType = StepTypes.JSONPathStep;
    this.lambda_arn = snippetDef ? snippetDef.content.lambda_arn : "";
  }

  writeLambdaPayload() {
    const inputlist = this.parameterInputs
      .map(input => {
        return input.writeInputParam()
       })
      .filter(param => !!param)
      .join(", ");
    return `{ ${inputlist} }`;
  }

  toSSM() {
    const outputs = this.outputs
      ? this.outputs.map(out => out.toSSM())
      : [];
    return {
      action: "aws:invokeLambdaFunction",
      inputs: {
        FunctionName: this.lambda_arn,
        Payload: this.writeLambdaPayload(),
      },
      isEnd: !this.nextStep,
      maxAttempts: 1,
      name: this.name,
      nextStep: this.nextStep,
      onFailure: "Abort",
      outputs,
    };
  }

  isHealthyStep() {
    let boolList = [];
    this.parameterInputs.forEach(i => {
      if (i.required) {
        switch (i.source.type) {
          case 'constant':
            if (!i.source.sourceValue?.length) {
              boolList.push(false)
            }
            break;
          default:
            break;
        }
      }
    })
    return boolList.every(Boolean);
  }
}
