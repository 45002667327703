import React, { useEffect } from "react";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import Output from "../output";
import Details from "../details";
import RequiredInputs from "./Helpers/RequiredInputs";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { hideGlobalLoader } from "@redux/actions/globalLoader.actions";
import SnippetHeader from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/SnippetHeader/SnippetHeader";

type ApprovalNodeProps = {
  activeNode: any;
};

const ApprovalNode: React.FC<ApprovalNodeProps> = ({ activeNode }) => {
  const state = useSelector(
    (state: RootStateOrAny) => state.globalLoaderReducer,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!state.loaderFlag) {
      dispatch(hideGlobalLoader());
    }
  }, [state, dispatch]);

  return (
    <TabCollection
      active="input"
      activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
      inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
      contentTop={50}
      tabs={["input", "output", "details"]}
    >
      <Tab title="Input" name="input">
        <TabContent>
          <SnippetHeader
            snippetDef={activeNode.extras.runbookNode.snippetDef}
          />
          <RequiredInputs />
        </TabContent>
      </Tab>
      <Tab title="Output" name="output">
        <TabContent>
          <Output />
        </TabContent>
      </Tab>
      <Tab title="Details" name="details">
        <TabContent>
          <Details />
        </TabContent>
      </Tab>
    </TabCollection>
  );
};

export default ApprovalNode;
