import React from "react";
import "./wait.scss";
import { ReactComponent as WaitImg } from "@assets/images/icons/wait.svg";

export default props => (
  <div className="wait__cover">
    <div>
      <div className="wait-text">
        {typeof props.text === "string" && props.text}
      </div>
      <div className="wait-spinner">
        <WaitImg />
      </div>
    </div>
  </div>
);
