import { BranchStep, Choice, ComparisonCondition } from "../ssm/branchstep";
import remove from "lodash/remove";
import {
  SSMActionNames,
  ComparisonOperators,
  ParameterType,
} from "../ssm/strings";
import { ControlNames, StepTypes } from "./strings";
import {
  RunbookStepInput,
  RunbookStepInputSource,
} from "../ssm/nodeinputoutput";
import { Parameter } from "../ssm/parameters";

export class ConditionalStep extends BranchStep {
  /**
   * Creates simple SSM Step for the snippet, with no
   * input sources.
   * @param {*} snippetDef
   */
  static fromControlNodeDefinition(snippetDef) {
    const name = `${snippetDef.name}_${
      snippetDef.insertionOrder
        ? snippetDef.insertionOrder
        : Math.floor(1000 * Math.random())
    }`;
    const action = SSMActionNames.BRANCH;

    const ssm = {
      name,
      action,
      inputs: {
        Choices: [],
        Default: "",
      },
      editorNodeId: snippetDef.editorNodeId,
    };
    if (
      snippetDef.name === ControlNames.CheckELBResult ||
      snippetDef.name === ControlNames.CheckExeStatus
    ) {
      return new NeurOpsCustomConditionalStep(ssm, snippetDef);
    }
    ssm.inputs.Choices.push(emptyChoice().toSSM());
    return new ConditionalStep(ssm, snippetDef);
  }

  constructor(stepJSON, snippetDef) {
    super(stepJSON);
    this.stepType = StepTypes.ConditionalStep;
    this.editable = true;
    this.snippetDef = snippetDef;

    window.setTimeout(() => {
      this.showHideWarning(!this.isHealthyStep());
    }, 500);
  }

  didUpdateStep = options => {
    this.showHideWarning(!this.isHealthyStep());
  };

  isHealthyStep() {
    let boolList = [true];
    this.choices.forEach(choice => {
      boolList.push(!!choice?.nextStep);
      boolList.push(!!choice?.condition?.value.toString());
      boolList.push(!!choice?.condition?.operator);
      boolList.push(!!choice?.condition?.input?.source?.sourceValue);
      boolList.push(!!choice?.condition?.input?.snippetAction?.defaultNextStep);
      boolList.push(
        !(choice?.condition?.input?.source?.sourceValue instanceof Parameter),
      );
    });

    return boolList.every(Boolean);
  }

  addChoice = choice => {
    choice.ssmStep = this;
    this.choices.push(choice);
  };

  removeChoice = choice => {
    // Assumes that choice objects are never cloned, so reference equality is enough
    remove(this.choices, candidate => candidate === choice);
    this.showHideWarning(!this.isHealthyStep());
  };

  addChoiceForTarget = (targetName, runbook) => {
    // If we don't have a default, it comes first
    if (!this.defaultNextStep) {
      this.defaultNextStep = targetName;
    } else {
      if (this.choices.length === 0) {
        const choice = emptyChoice();
        choice.nextStep = targetName;
        choice.readInputSources(runbook, this);
        this.choices.push(choice);
      }
      if (!this.choices[0].nextStep) this.choices[0].nextStep = targetName;
    }
    this.showHideWarning(!this.isHealthyStep());
  };

  /*
  WARNING: removes ALL links to the target, so if more than one choice and/or a default
  point to it, they are all gone.
  */
  removeTarget = targetName => {
    if (this.defaultNextStep === targetName) {
      this.defaultNextStep = undefined;
    }
    let choice;
    for (choice of this.choices) {
      if (choice.nextStep === targetName) {
        choice.nextStep = undefined;
      }
    }
    this.showHideWarning(!this.isHealthyStep());
  };

  getDefault = () => {
    return this.defaultNextStep;
  };

  setDefault = stepName => {
    this.ssm.inputs.Default = stepName;
    this.inputs.Default = stepName;
    this.defaultNextStep = stepName;
    this.showHideWarning(!this.isHealthyStep());
  };

  // Must implement toSSM() since this is editable
  toSSM = () => {
    // Get the top level metadata - name, action, etc. from
    // the constructing template.  Only the inputs are editable for now.
    const ssmData = {
      ...this.ssm,
      inputs: {
        Default: this.defaultNextStep,
        Choices: this.choices.map(choice => choice.toSSM()),
      },
    };
    delete ssmData.editorNodeId;
    return ssmData;
  };

  updateForPreviousStep(prevStep) {
    // TO DO: see if choices are still valid?
  }
}

//NeurOps specific conditionals

export class NeurOpsCustomConditionalStep extends ConditionalStep {
  // eslint-disable-next-line
  constructor(stepJSON, snippetDef) {
    super(stepJSON, snippetDef);
  }

  updateForPreviousStep(prevStep) {
    const nextStep = this.choices[0] && this.choices[0].nextStep;
    if (this.name.startsWith(ControlNames.CheckELBResult)) {
      this.choices[0] = this.checkELBResultChoice(prevStep);
    } else if (this.name.startsWith(ControlNames.CheckExeStatus)) {
      this.choices[0] = this.checkExeStatusChoice(prevStep);
    }
    this.choices[0].nextStep = nextStep;
  }

  addChoiceForTarget(targetName, runbook) {
    if (
      targetName === this.defaultNextStep ||
      this.choices.find(choice => choice.nextStep === targetName)
    ) {
      throw new Error(
        "Adding Choice to ConditionalStep: Cannot have more than one link to the same target",
      );
    }

    // If we don't have a default, it comes first
    if (!this.defaultNextStep) {
      this.defaultNextStep = targetName;
    } else {
      const choice =
        this.choices[0] ||
        (this.name.startsWith(ControlNames.CheckELBResult) &&
          this.checkELBResultChoice()) ||
        (this.name.startsWith(ControlNames.CheckExeStatus) &&
          this.checkExeStatusChoice());
      choice.nextStep = targetName;
      choice.readInputSources(runbook, this);
      this.choices[0] = choice;
    }
  }

  checkExeStatusChoice(prevStep) {
    const choice = this.checkPreviousStepChoice(
      ParameterType.String,
      "execution_status",
      ComparisonOperators.StringEquals,
      "S_OK",
      prevStep,
    );
    // console.log("created choice");
    // console.log(choice);

    return choice;
  }

  checkELBResultChoice(prevStep) {
    const choice = this.checkPreviousStepChoice(
      ParameterType.Integer,
      "no_bad_vms",
      ComparisonOperators.NumericGreater,
      0,
      prevStep,
    );

    // console.log("created choice");
    // console.log(choice);

    return choice;
  }

  checkPreviousStepChoice(type, outputName, operator, value, prevStep) {
    const choice = emptyChoice();
    const sourceOutput = (prevStep.outputs || []).find(
      output => output.name === outputName,
    );

    const newSource = new RunbookStepInputSource("snippetOutput", sourceOutput);
    choice.condition.input = new RunbookStepInput(
      this,
      "Variable",
      type,
      true,
      newSource,
    );

    choice.condition.variable =
      prevStep && `{{ ${prevStep.name}.${outputName} }}`;
    choice.condition.operator = operator;
    choice.condition.value = value;
    return choice;
  }
}

function emptyChoice() {
  const condition = new ComparisonCondition(
    "{{ choiceParameter }}",
    ComparisonOperators.StringEquals,
    "",
  );
  return new Choice(undefined, condition);
}
