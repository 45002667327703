import React from "react";
import { RadioSet, Radio } from "@components/ui";

export default props => (
  <div>
    <RadioSet
      name="timeseries-options"
      className="label"
      onChange={props.onChange}
      defaultSelected={props.defaultOption}
    >
      <Radio value="1 hour">1 hour ago</Radio>
      <Radio value="3 hours">3 hours ago</Radio>
      <Radio value="1 day">Last 24 Hours</Radio>
      <Radio value="7 days">Past week</Radio>
      <Radio value="30 days">Past Month</Radio>
    </RadioSet>
  </div>
);
