import React from "react";
import "./TabWrapper.scss";
import { RouteConstants } from "../../../../routes/Constants";
import { Link } from "react-router-dom";

type TabWrapperProps = {
  showToggle: boolean;
  isToggleOn?: boolean;
  isNotConfigured?: boolean;
  onChangeToggle?: (bool) => void;
  title: string;
  description: string;
  info?: string;
};

const TabWrapper: React.FC<TabWrapperProps> = props => {
  return (
    <div className="tw">
      <div className="header">
        <div className="left">
          <span className="title">{props.title}</span>
          <span className="info">{props.info}</span>
          <span className="description">{props.description}</span>
        </div>
        <div className="right">
          {props.showToggle && (
            <div className="toggle">
              <label className="switch">
                <input
                  type="checkbox"
                  name="notification-toggle"
                  id="notification-toggle"
                  checked={props.isToggleOn}
                  onChange={() => {
                    props.onChangeToggle(!props.isToggleOn);
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span className="status ml-2">
                {props.isToggleOn ? "ON" : "OFF"}
              </span>
            </div>
          )}
          {props.isNotConfigured && (
            <Link
              className="configure-link"
              to={RouteConstants.slack.defaultUrl}
            >
              <div>Configure Slack</div>
            </Link>
          )}
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default TabWrapper;
