import React from "react";
import StepTable from "./step-table";

const getContainerCss = index => {
  let retVal = "stepper-wrap";
  if (index > 1) {
    retVal += " stepper-child";
  }
  return retVal;
};
export default props => (
  <div className={getContainerCss(props.stepNumber)}>
    <div className="stepper-content">
      <div className="stepper-circle">{props.stepNumber}</div>
      <div className="stepper-table-container">
        <StepTable step={props.step} stepNumber={props.stepNumber} />
      </div>
    </div>
  </div>
);
