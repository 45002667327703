import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  isFetching,
  fetchConnectorConfigDetails,
} from "@redux/actions/settingsPanel.action";
import { Formik } from "formik";
import * as Yup from "yup";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";
import { getRedirectUrl, isEmpty } from "@lib/utils";
import Api from "@lib/api/api";
import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";
import { RouteConstants } from "../../../../../routes/Constants";
import { FormFooter } from "@components/shared/GetStarted/Utils/Helpers";
import { FTNotification } from "@components/ui";

const validationSchema = Yup.object({
  consumerKey: Yup.string()
    .required("Consumer Key is required")
    .trim("Consumer Key is required"),
  consumerName: Yup.string()
    .required("Consumer Name is required")
    .trim("Consumer Name is required"),
  publicKey: Yup.string()
    .required("Public Key is required")
    .trim("Public Key is required"),
  accountUrl: Yup.string()
    .required("JIRA URL is required")
    .trim("JIRA URL is required"),
});

class ConnectorForm extends React.Component {
  constructor(props) {
    super(props);
    this.formikRef = React.createRef();
    this.state = {
      showForm: false,
      nodeConfig: {},
      doRedirect: false,
      redirectUrl: "",
    };
    this.getJiraConfig();
  }

  componentDidUpdate(prevProps) {
    /**
     * Reset the formik form when remove integration is successfull
     * from GetStartedForm.tsx
     */
    if (
      prevProps.resetForm !== this.props.resetForm &&
      this.props.resetForm === true
    ) {
      // eslint-disable-next-line no-unused-expressions
      this.formikRef.current?.resetForm({
        values: {
          consumerKey: this.state.nodeConfig["JIRA_CONSUMER_KEY"],
          consumerName: this.state.nodeConfig["JIRA_CONSUMER_NAME"],
          publicKey: this.formatPublicKey(
            this.state.nodeConfig["JIRA_PUBLIC_KEY"],
          ),
          accountUrl: "",
        },
      });
    }
  }

  getJiraConfig = async () => {
    this.props.isFetching(true, "Loading connector details");
    let action = await this.props.fetchConnectorConfigDetails("jira");
    if (action.type === "CONNECTOR_CONFIGURATION_INFO_FETCH_SUCCESS") {
      this.setState(
        {
          nodeConfig: this.props.connectorConfig,
          showForm: true,
        },
        () => {
          this.props.isFetching(false, "");
        },
      );
    } else {
      this.props.isFetching(false, "");
    }
  };

  goBack = () => {
    this.setState({
      doRedirect: true,
      redirectUrl: {
        pathname: getRedirectUrl(RouteConstants["integration"].defaultUrl, [
          { key: "connector", value: "jira" },
        ]),
        search: "?edit=true",
        state: { from: "Integrations" },
      },
    });
  };

  onSubmitHandle = async (accountDetails, actions) => {
    this.props.isFetching(true, "Integration in progress");
    const response = await Api.getJiraOauth(
      "jira",
      accountDetails.accountUrl,
    ).catch(e => console.log(e));
    const oAuthDetails = !isEmpty(response) ? JSON.parse(response.body) : {};
    if (!isEmpty(oAuthDetails)) {
      window.open(oAuthDetails["JIRA_URL"]);
      const url =
        accountDetails.accountUrl[accountDetails.accountUrl.length - 1] === "/"
          ? accountDetails.accountUrl
          : accountDetails.accountUrl + "/";
      const permissionResponse = await Api.requestJiraPermission(
        "jira",
        url,
        oAuthDetails["REQUEST_TOKEN"],
        oAuthDetails["REQUEST_TOKEN_SECRET"],
      ).catch(e => console.log(e));

      if (!isEmpty(permissionResponse)) {
        const resBody = JSON.parse(permissionResponse.body);
        FTNotification.success({
          title: resBody,
        });
        actions.resetForm({ values: accountDetails });
        setTimeout(() => {
          this.goBack();
        }, 1500);
      } else {
        FTNotification.error({
          title: "Oops! Something went wrong !!!",
        });
      }
    } else {
      FTNotification.error({
        title: "Oops! Something went wrong !!!",
      });
    }
    this.props.isFetching(false, "");
  };

  formatPublicKey = value => {
    if (Array.isArray(value)) {
      return value.join();
    }
    if (typeof value === "string") {
      const val = !!value && JSON.parse(value);
      if (!!val && Array.isArray(JSON.parse(value))) {
        return val.join();
      }
    }
  };

  render() {
    const isEditMode = new URLSearchParams(this.props?.location?.search).get(
      "edit",
    );

    return (
      <>
        {this.state.doRedirect && <Redirect to={this.state.redirectUrl} push />}
        {this.state.showForm ? (
          <Formik
            initialValues={{
              consumerKey: this.state.nodeConfig["JIRA_CONSUMER_KEY"],
              consumerName: this.state.nodeConfig["JIRA_CONSUMER_NAME"],
              publicKey: this.formatPublicKey(
                this.state.nodeConfig["JIRA_PUBLIC_KEY"],
              ),
              accountUrl: this.state.nodeConfig["JIRA_URL"],
            }}
            validationSchema={validationSchema}
            onSubmit={this.onSubmitHandle}
            innerRef={this.formikRef}
          >
            {formik => (
              <>
                {this.props.setShowWarningModal(formik.dirty)}
                <div className="d-flex flex-column">
                  <h2 className="pt-10-px pb-10-px mb-2">Authorize Fylamynt</h2>
                  <h4 className="pb-10-px">
                    <b>Step 1: Link Fylamynt Application in Jira</b>
                  </h4>
                  <FormInput
                    fieldName="Consumer Key:"
                    name="consumerKey"
                    id="consumerKey"
                    fieldValue={formik.values.consumerKey}
                    handleChange={formik.handleChange}
                    touched={formik.touched}
                    errors={formik.errors}
                    className="copy-text"
                    copyable
                    readOnly
                  />
                  <FormInput
                    fieldName="Consumer Name:"
                    name="consumerName"
                    id="consumerName"
                    fieldValue={formik.values.consumerName}
                    handleChange={formik.handleChange}
                    touched={formik.touched}
                    errors={formik.errors}
                    className="copy-text"
                    copyable
                    readOnly
                  />

                  <FormInput
                    fieldValue={formik.values.publicKey}
                    name="publicKey"
                    showJson={true}
                    id="publicKey"
                    fieldName="Public Key"
                    touched={formik.touched}
                    errors={formik.errors}
                    autoComplete="off"
                    isMasked
                    as={`textarea`}
                    readOnly
                    copyable
                  />

                  <h4 className="pt-20-px pb-10-px">
                    <b>Step 2: Configure Jira OAuth1</b>
                  </h4>
                  <FormInput
                    fieldName="URL of JIRA Account:"
                    name="accountUrl"
                    id="accountUrl"
                    handleChange={formik.handleChange}
                    touched={formik.touched}
                    errors={formik.errors}
                    autoComplete="off"
                  />
                  <FormFooter
                    onSubmit={formik.handleSubmit}
                    isEditMode={isEditMode}
                    disableSubmit={!formik.dirty}
                  />
                </div>
              </>
            )}
          </Formik>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => ({
  message: state.runbooksReducer.message,
  connectorConfig: state.settingsPanelReducer.currentNodeConfigInfo,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      isFetching,
      fetchConnectorConfigDetails,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ConnectorForm));
