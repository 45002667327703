import React from "react";
import { Consumer } from "../runbook-details-lib/runbook-details.context";
import VersionsDropdown from "./runbook-details-versions-dropdown";
import Buttons from "./runbook-details-buttons";
import { sortObject } from "@lib/utils";

export default props => (
  <Consumer>
    {({
      runbook,
      activeRunbookVersion,
      updateDefaultVersion,
      isFetchingVersion,
      isVersionUpdateSuccess,
      isVersionUpdateFailed,
      isDeleting,
      deleteRunbookVersion,
      runbookVersionDeleteStatus,
      setActiveVersion,
    }) => (
      <div>
        <h4 id="runbook-detail-version-title">Versions</h4>
        <div className="version-actions__wrap">
          <VersionsDropdown
            versions={sortObject(
              runbook?.Versions,
              "DocumentVersion",
              "desc",
              true,
            )}
            activeVersion={activeRunbookVersion}
            setActiveVersion={setActiveVersion}
            runbook={runbook}
            activeRunbookVersion={activeRunbookVersion}
            updateDefaultVersion={updateDefaultVersion}
            isFetchingVersion={isFetchingVersion}
            isVersionUpdateSuccess={isVersionUpdateSuccess}
            isVersionUpdateFailed={isVersionUpdateFailed}
            isDeleting={isDeleting}
            deleteRunbookVersion={deleteRunbookVersion}
            runbookVersionDeleteStatus={runbookVersionDeleteStatus}
          />
          <Buttons {...props} />
        </div>
      </div>
    )}
  </Consumer>
);
