import React from "react";
import { Modal, Button } from "@components/ui";
import PropTypes from "prop-types";
import { workflowLimit, runsLimit } from "@lib/utils/constants";

const getFooter = props => {
  return (
    <div className="modal-buttons-footer">
      <Button
        text="Cancel"
        buttonStyle="secondary"
        style={{ width: "50%" }}
        onClick={props.toggleDialog}
        type="button"
        isLoading={false}
      />
      <Button
        text="View Details"
        buttonStyle="primary"
        style={{ width: "50%" }}
        size="large"
        isLoading={false}
        onClick={props.goTo}
      />
    </div>
  );
};
const LimitDialog = props => {
  return (
    <Modal
      onClose={props.toggleDialog}
      title={
        props.limitType === "runs" ? "Runs Limit Reached" : "Plan Limit Reached"
      }
      showClose={true}
      footer={getFooter(props)}
    >
      <div>
        <span>{props.limitType === "runs" ? runsLimit : workflowLimit}</span>
      </div>
    </Modal>
  );
};

LimitDialog.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  limitType: PropTypes.string.isRequired,
};

export default LimitDialog;
