import React from "react";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  TextInput,
} from "@components/ui";
import { Consumer } from "../../../../runbook-editor-lib/runbook-editor.context";
import Tag from "./tag";
import { filterSystemTags } from "@lib/utils";

export default class SettingsTags extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { tagValue: "", isDuplicate: false };
  }
  render() {
    return (
      <Consumer>
        {({
          runbook,
          addTagToRunbook,
          clearTagInput,
          resetClearTagInputFlag,
        }) => (
          <Accordion isExpanded={true} useArrow={true}>
            <AccordionLabel className="editor-accordion-label">
              Tags
            </AccordionLabel>
            <AccordionSection>
              <div className="editor-detail-panel flex-column mb-0">
                <TextInput
                  placeholder="Press Enter to add tag"
                  name="new-tag"
                  className="rule-input"
                  value={clearTagInput ? "" : this.state.tagValue}
                  onChange={e => {
                    this.setState({ isDuplicate: false });
                  }}
                  onKeyPress={e => {
                    this.setState({ isDuplicate: false });
                    resetClearTagInputFlag(false);
                    let newTag = e.target?.value?.trim();
                    this.setState({ tagValue: newTag });
                    if (newTag && e.key === "Enter") {
                      if (!runbook.Tags.includes(newTag)) {
                        addTagToRunbook(newTag);
                      } else {
                        this.setState({ isDuplicate: true });
                      }
                    }
                  }}
                  id="new-tag"
                />
                {this.state.isDuplicate && this.state.tagValue && (
                  <span className="duplicate-tag-error">Duplicate Tag</span>
                )}
              </div>
              <div className="paragraph oval-wrapper">
                {runbook &&
                  runbook.hasOwnProperty("Tags") &&
                  filterSystemTags(runbook.Tags).map(tag => (
                    <Tag text={tag} key={tag} />
                  ))}
              </div>
            </AccordionSection>
          </Accordion>
        )}
      </Consumer>
    );
  }
}
