import React, { memo } from "react";
import { Handle, Position } from "react-flow-renderer";
import NodeBody from "./helpers/NodeBody";

type DefaultNodeProps = {
  data: any;
};

const DefaultNode: React.FC<DefaultNodeProps> = memo(
  ({ data, ...editorData }) => {
    return (
      <div className="rf-editor-hover-zone">
        <div className="rf-editor-default-node">
          <div className="rf-editor-hover-zone-top"></div>
          <div className="rf-editor-hover-zone-center"></div>
          <div className="rf-editor-hover-zone-bottom"></div>
          <NodeBody nodeData={data} editorData={editorData} />
          <Handle type="target" position={Position.Top} />
          <Handle
            className="target-node"
            type="source"
            position={Position.Bottom}
          />
        </div>
      </div>
    );
  },
);

export default DefaultNode;

//rf-editor-hover-zone-center div can be used if we revert back to hover for triggering delete and duplicate button visibility
