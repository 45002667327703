import React from "react";
import "./MenuList.scss";
import { RouteConstants } from "../../../../../../routes/Constants";

const MenuList = props => {
  return (
    <div className="menu-list">
      <div
        className="list-item"
        onClick={() => props.goBack(`${RouteConstants.runbooks.defaultUrl}`)}
      >
        <div className="list-icon list-icon-home"></div>
        <div className="list-text">Home</div>
      </div>
      {props.isDraftState !== "Draft" ? (
        <div
          className="list-item"
          onClick={() =>
            props.goBack(`/workflow/${props.runbookName}/parameters`)
          }
        >
          <div className="list-icon list-icon-workflow"></div>
          <div className="list-text">Workflow Details</div>
        </div>
      ) : null}
    </div>
  );
};

export default MenuList;
