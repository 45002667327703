import { DropDown, DropDownOption, FTNotification } from "@components/ui";
import { Consumer } from "../../runbook-editor-lib/runbook-editor.context";
import get from "lodash/get";
import { getTrigger, isEmpty } from "@lib/utils/index.js";
import JsonContent from "./json-content/JsonContent";
import { RUNBOOK_SET_ACTIVE } from "@redux/types";
import React from "react";
import SaveButton from "./EditorToolbarSave";
import WarningCard from "@components/ui/WarningSign/WarningCard";
import { connect } from "react-redux";
import { draftStatus } from "@lib/utils/constants";
import { fetchAccountPlanInfo } from "@redux/actions/accountPlan.actions";
import { limitExceeded } from "../../../../utils/common";
import { sanitizeDecimal } from "@lib/utils";
import "./EditorToolbar.scss";
import {
  ControlNames,
  StepTypes,
} from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/strings";
import { replaceAllSpecialCharWithSpace } from "@lib/utils";

class EditorToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAWSAccountAdded:
        this.props.isAccountDetailFetched &&
        !!this.props.userDefaultAccounts.length,
      dropdownStyle:
        this.props.isAccountDetailFetched &&
        !!this.props.userDefaultAccounts.length
          ? { cursor: "pointer" }
          : { cursor: "not-allowed" },
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isAccountDetailFetched !== this.props.isAccountDetailFetched
    ) {
      this.setState({
        isAWSAccountAdded:
          this.props.isAccountDetailFetched &&
          !!this.props.userDefaultAccounts.length,
        dropdownStyle:
          this.props.isAccountDetailFetched &&
          !!this.props.userDefaultAccounts.length
            ? { cursor: "pointer" }
            : { cursor: "not-allowed" },
      });
    }
  }

  isInvalidWorkflow = runbookObj => {
    let edges = [];
    let reverseEdges = [];
    let zeroInwardEdgeNodes = 0;
    if (Object.keys(runbookObj._dag._edges).length > 1) {
      Object.keys(runbookObj._dag._edges).forEach(key => {
        edges = edges.concat(runbookObj._dag._edges[key]);
      });

      Object.keys(runbookObj._dag._reverse).forEach(key => {
        if (
          Array.isArray(runbookObj._dag._reverse[key]) &&
          runbookObj._dag._reverse[key].length === 0
        )
          zeroInwardEdgeNodes++;
        reverseEdges = reverseEdges.concat(runbookObj._dag._reverse[key]);
      });
      /**
       * Return true if WF has a
       * node which doesn't have an inward edge
       */
      if (zeroInwardEdgeNodes > 0) return true;

      /**
       * Return true if WF contains a node
       * which has no inward and outward edge
       */
      for (let node in runbookObj._dag._edges) {
        if (!edges.includes(node) && !reverseEdges.includes(node)) {
          return true;
        }
      }
    }

    return false;
  };

  hasInvalidWorkflowParams = runbookObj => {
    /**
     * Function to check if any node in the runbookObj has any required field empty.
     * This function prevents user to save such invalid runbook, so that while execution
     * it must not fail with an error related to required parameter not found.
     * @param {[Object]} runbookObj : Runbook object
     * @param {[Boolean]}           : Boolean value specifies if given object is valid or not.
     */
    let isInvalid = false;
    for (let stepIndex in runbookObj.mainSteps) {
      let step = runbookObj.mainSteps[stepIndex];
      for (let inputIndex in step.parameterInputs) {
        let input = step.parameterInputs[inputIndex];
        if (
          input?.source?.type === "constant" &&
          input.required === true &&
          input?.source?.sourceValue === null
        ) {
          isInvalid = true;
          break;
        }
      }
    }
    return isInvalid;
  };

  checkSlackMessageBody = runbookObj => {
    if (
      Array.isArray(runbookObj?.mainSteps) &&
      runbookObj?.mainSteps.length > 0
    ) {
      const slackSteps = runbookObj.mainSteps.filter(
        step => step.stepType === StepTypes.SlackConnectorStep,
      );

      const stepNames = runbookObj.mainSteps.map(item => item?.name);
      for (const slackStep of slackSteps) {
        const { parameterInputs } = slackStep;
        const messageBody = parameterInputs.find(
          item => item.name === "message",
        )?.source?.sourceValue;

        if (!!messageBody) {
          const regexpPattern = /{{(.*?)\}}/g;
          const variables = [...messageBody.matchAll(regexpPattern)];
          const filteredVariables = variables.map(item => item?.[1]?.trim());
          for (const item of filteredVariables) {
            const sanitizedName = item?.split(".")?.[0];
            if (!stepNames.includes(sanitizedName)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  savingRunbook = async (runbookObj, saveRunbook, activeRunbookVersion) => {
    if (this.checkSlackMessageBody(runbookObj)) {
      FTNotification.error({
        title: "Could not save",
        message: "Slack message body contains invalid variable(s).",
      });
      return;
    }

    // If user tries to save Integer values in Decimal type, sanitize Decimal input type to convert Integer only values to flaoting/decimal type
    if (runbookObj.mainSteps) {
      runbookObj.mainSteps.forEach(step => {
        if (!isEmpty(get(step, "parameterInputs"))) {
          step.parameterInputs.forEach(item => {
            if (item.type === "Decimal" && item.source.sourceValue) {
              let newSourceValue = sanitizeDecimal(item.source.sourceValue);
              item.source.sourceValue = newSourceValue;
            }
          });
        }
      });
    }
    if (runbookObj.mainSteps.length > 0) {
      // Find if step has 'errors' property
      let step = runbookObj.mainSteps.find(step => step.errors);
      if (step && step.errors.errorMessage) {
        FTNotification.error({
          title: "Could not save",
          message: step?.errors?.errorMessage,
        });
        return;
      }
    }
    if (runbookObj.unHealthySteps.length > 0) {
      FTNotification.error({
        title: "Could not save",
        message: `You have (${runbookObj.unHealthySteps.length}) incomplete step(s)`,
      });
      return;
    } else if (this.isInvalidWorkflow(runbookObj)) {
      FTNotification.error({
        title: "Could not save",
        message: `Invalid workflow! You have disconnected node(s)`,
      });
      return;
    } else if (this.hasInvalidWorkflowParams(runbookObj)) {
      FTNotification.error({
        title: "Cannot save workflow",
        message: `Invalid input! Required input value cannot be empty.`,
      });
      return;
    }
    if (activeRunbookVersion === "Draft") {
      try {
        await this.props.fetchAccountPlanInfo();
        if (
          limitExceeded(
            this.props.quotas?.workflows?.current_usage,
            this.props.quotas?.workflows?.limit,
          )
        ) {
          this.props.toggleWorkflowLimitDialog();
          return;
        }
      } catch (e) {
        FTNotification.error({
          title: "Could not fetch Quotas",
        });
        return;
      }
    }
    saveRunbook();
  };

  handleRunNow = async toggleRunbookModal => {
    try {
      await this.props.fetchAccountPlanInfo();
      if (
        limitExceeded(
          this.props.quotas?.executions?.current_usage,
          this.props.quotas?.executions?.limit,
        )
      ) {
        this.props.toggleRunsLimitDialog();
        return;
      }
    } catch (e) {
      FTNotification.error({
        title: "Could not fetch Quotas",
      });
      return;
    }
    toggleRunbookModal();
  };

  handleScheduleRun = async toggleScheduleModal => {
    try {
      await this.props.fetchAccountPlanInfo();
      if (
        limitExceeded(
          this.props.quotas?.executions?.current_usage,
          this.props.quotas?.executions?.limit,
        )
      ) {
        this.props.toggleRunsLimitDialog();
        return;
      }
    } catch (e) {
      FTNotification.error({
        title: "Could not fetch Quotas",
      });
      return;
    }
    toggleScheduleModal();
  };

  isSaveButtonDisabled = runbookObj => {
    /**
     * Disable Save button when only Trigger node is present
     * on the canvas
     */
    if (runbookObj && runbookObj.mainSteps.length === 1) {
      if (
        [
          ControlNames.Trigger,
          ControlNames.Manual,
          ControlNames.Webhook,
          ControlNames.CloudwatchAlert,
        ].some(stepName => runbookObj.mainSteps[0].name.startsWith(stepName))
      ) {
        return true;
      }
    }
    return false;
  };

  render() {
    return (
      <Consumer>
        {({
          runbookId,
          setActivePanel,
          activeRunbookVersion,
          runbook,
          toggleRunbookModal,
          saveRunbook,
          runbookObj,
          runbookDidUpdate,
          versions,
          toggleScheduleModal,
        }) => (
          <div
            className="editor-middle-toolbar"
            onMouseDown={this.props.onClick}
          >
            <button
              className="editor-middle-toolbar-left"
              onClick={() => setActivePanel("versions", "Versions")}
              type="button"
            >
              {activeRunbookVersion !== draftStatus ? (
                <div className="wf-title">
                  {replaceAllSpecialCharWithSpace(runbookId)}
                </div>
              ) : (
                replaceAllSpecialCharWithSpace(runbookId)
              )}
              {activeRunbookVersion && (
                <>
                  {`:`}
                  <div className="version-number">{activeRunbookVersion}</div>
                </>
              )}
              {versions &&
              versions.length > 0 &&
              versions.find(obj => obj.DocumentVersion === activeRunbookVersion)
                ?.IsDefaultVersion ? (
                <span className="default-version-tags">default</span>
              ) : null}
            </button>
            <div className="editor-middle-toolbar-right">
              <div className="editor-middle-toolbar-right-content">
                {runbookObj && runbookObj.mainSteps.length > 0 && (
                  <JsonContent runbook={runbookObj} />
                )}
                <div className="ht-100p" data-tut="reactour__save">
                  {activeRunbookVersion === draftStatus || runbookDidUpdate ? (
                    <React.Fragment>
                      {runbookObj && runbookObj.mainSteps.length > 0 && (
                        <SaveButton
                          activeRunbookVersion={activeRunbookVersion}
                          saveClickHandler={() =>
                            this.savingRunbook(
                              runbookObj,
                              saveRunbook,
                              activeRunbookVersion,
                            )
                          }
                          version={
                            versions &&
                            versions.length > 0 &&
                            versions[0].DocumentVersion !== "Draft"
                              ? `New Version`
                              : ``
                          }
                          disabled={this.isSaveButtonDisabled(runbookObj)}
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    <>
                      <DropDown
                        changeAble={false}
                        className={`workflow-run-dropdown ${
                          (!this.state.isAWSAccountAdded ||
                            this.props.unconfiguredNodes.length > 0) &&
                          "no-pointer"
                        }`}
                        title="Run"
                        style={this.state.dropdownStyle}
                      >
                        <DropDownOption
                          text="Run Now"
                          handleClick={() =>
                            this.handleRunNow(toggleRunbookModal)
                          }
                        >
                          <div className="btn-svg btn-svg__run"></div>
                        </DropDownOption>
                        <DropDownOption
                          text="Schedule Run"
                          disabled={
                            getTrigger(runbookObj) &&
                            getTrigger(runbookObj) !== "manual"
                              ? true
                              : false
                          }
                          tooltipText={"Trigger workflows cannot be scheduled!"}
                          value={
                            runbook ? runbook.Name || runbook.Description : ""
                          }
                          handleClick={() =>
                            this.handleScheduleRun(toggleScheduleModal)
                          }
                        >
                          <div className="btn-svg btn-svg__schedule" />
                        </DropDownOption>
                      </DropDown>
                    </>
                  )}
                </div>
              </div>
              {(!this.state.isAWSAccountAdded ||
                this.props.unconfiguredNodes.length > 0) && (
                <div className="m-2">
                  <WarningCard
                    unconfiguredNodes={this.props.unconfiguredNodes}
                    isAWSAccountConfigured={this.state.isAWSAccountAdded}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}

const mapStateToProps = state => ({
  isAccountDetailFetched: state.getStartedReducer.isAccountDetailFetched,
  userDefaultAccounts: state.getStartedReducer.userDefaultAccounts,
  quotas: state.accountPlanReducer.quotas,
});

const mapDispatch = dispatch => ({
  setActiveRunbook: runbook =>
    dispatch({
      type: RUNBOOK_SET_ACTIVE,
      payload: runbook,
    }),
  fetchAccountPlanInfo: () => dispatch(fetchAccountPlanInfo()),
});

export default connect(mapStateToProps, mapDispatch)(EditorToolbar);
