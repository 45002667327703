import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { Output, Details, WaitForResourceInput } from ".";
import { useDispatch, useSelector } from "react-redux";
import { hideGlobalLoader } from "@redux/actions/globalLoader.actions";

function WaitForResourcePanel(props) {
  const { activeNode } = props;
  const runbookEditorContext = useContext(RunbookEditorContext);
  const { notifyRunbookUpdate } = runbookEditorContext;

  const loaderState = useSelector(state => state.globalLoaderReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaderState.loaderFlag) {
      dispatch(hideGlobalLoader());
    }
  }, [loaderState, dispatch]);

  return (
    <TabCollection
      active="input"
      activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
      inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
      contentTop={50}
      tabs={["input", "output", "details"]}
    >
      <Tab title="Input" name="input">
        <TabContent>
          <WaitForResourceInput
            activeNode={activeNode}
            runbookNode={activeNode.extras.runbookNode}
            notifyRunbookUpdate={notifyRunbookUpdate}
          />
        </TabContent>
      </Tab>
      <Tab title="Output" name="output">
        <TabContent>
          <Output />
        </TabContent>
      </Tab>
      <Tab title="Details" name="details">
        <TabContent>
          <Details />
        </TabContent>
      </Tab>
    </TabCollection>
  );
}

const mapState = ({ actionNodeReducer }) => {
  return {
    operations: actionNodeReducer.operations,
  };
};

export default connect(mapState)(WaitForResourcePanel);
