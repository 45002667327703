import React from "react";
import { OverflowMenu } from "@components/ui";
import { getPriorityIcon } from "@lib/utils";

const tableColumn = (onEdit, onDelete) => {
  return [
    {
      name: "ID",
      selector: "TaskTypeId",
      width: "10%",
      sortable: true,
    },
    {
      name: "Name",
      width: "20%",
      selector: "name",
      sortable: true,
      cell: row => {
        let name = row.meta?.name || "";
        return <div className="table">{name}</div>;
      },
    },
    {
      name: "Description",
      width: "25%",
      selector: "description",
      sortable: true,
      cell: row => {
        let description = row.meta?.description || "";
        return <div className="table-description">{description}</div>;
      },
    },
    {
      name: "Default Workflow",
      width: "20%",
      selector: "workflow",
      sortable: true,
      cell: row => {
        let workflow = row.meta?.workflow?.name || "";
        return <div className="table">{workflow}</div>;
      },
    },
    {
      name: "Priority",
      width: "10%",
      selector: "priority",
      sortable: true,
      cell: row => {
        let priority = row.meta?.priority || "P1";

        return (
          <>
            <img
              src={getPriorityIcon(priority)}
              alt="priority-icon"
              className="mr-5-px"
            ></img>
            <div className="table">{priority}</div>
          </>
        );
      },
    },
    {
      name: "Source",
      width: "10%",
      selector: "source",
      sortable: true,
      cell: row => {
        let source = row.meta?.source || "";
        return <div className="table">{source}</div>;
      },
    },
    {
      name: "",
      width: "5%",
      cell: row => {
        let source = row.meta?.source || "";
        return source.toLowerCase() === "user" ? (
          <OverflowMenu>
            <div className="button-grey" onMouseDown={() => onEdit(row)}>
              Edit
            </div>
            <div className="button-delete" onMouseDown={() => onDelete(row)}>
              Delete
            </div>
          </OverflowMenu>
        ) : null;
      },
    },
  ];
};

export default tableColumn;
