import React, { useState, useEffect } from "react";
import { ModalWithSidebar, Button } from "@components/ui";
import { useDispatch } from "react-redux";

import { Formik } from "formik";
import CreateTaskDetails from "./utils/CreateTaskDetails";
import "./CreateTask.scss";
import { getValidationSchema } from "./utils/ValidationSchema";
import CheckIcon from "@assets/images/icons/icon-page-tick__complete.svg";
import RadioButtonIcon from "@assets/images/icons/icon-page-tick__active.svg";
import RadioButtonUnchecked from "@assets/images/icons/icon-page-tick__inactive.svg";
import RuntimeParameters from "./utils/RuntimeParameters";
import Api from "@lib/api";
import { fetchAllTaskList } from "@redux/actions/taskManagement.actions";
import Schedule from "@components/shared/Schedule/Schedule";
import {
  getParsedRuntimeParameters,
  getRuntimeParameterInitialValues,
} from "./utils/Helpers";

enum WorkflowRunType {
  AUTOMATIC = "automatic",
  SCHEDULE = "schedule",
}

type CreateTaskProps = {
  setIsCreateTaskModalOpen: (boolean) => void;
  isCreateTaskModalOpen: boolean;
};

const CreateTask: React.FC<CreateTaskProps> = ({
  setIsCreateTaskModalOpen,
  isCreateTaskModalOpen,
}) => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [workflowRunType, setWorkflowRunType] = React.useState<WorkflowRunType>(
    WorkflowRunType.AUTOMATIC,
  );

  const [selectedTaskDetails, setSelectedTaskDetails] = useState({
    name: "",
    description: "",
    task_type: "",
    task_priority: "",
    workflow: { Name: "" },
  });

  const [selectedRequiredParameters, setSelectedRequiredParameters] = useState(
    {},
  );

  /**
   * To be used in CreateTaskDetails
   */
  const [selectedWorkflow, setSelectedWorkflow] = useState({
    Name: "",
    DocumentVersion: "",
  });

  /**
   * To be used in RuntimeParameters
   */
  const [requiredParameters, setRequiredParameters] = useState([]);

  /**
   * To be used in Schedule run
   */
  const [fixedRate, setFixedRate] = useState(null);
  const [scheduleExpression, setScheduleExpression] = useState(null);

  const setValues = (key, value) => {
    switch (key) {
      case "fixedRate":
        setFixedRate(value);
        break;
      case "scheduleExpression":
        setScheduleExpression(value);
        break;
    }
  };

  const createTaskWithoutWF = payload => {
    payload.task_type = payload.task_type.TaskTypeId;
    let { workflow, ...data } = payload;
    payload = data;
    createTask(payload);
  };

  const createTaskWithWFPayload = runtimeParams => {
    let { workflow, task_type, ...data } = selectedTaskDetails;
    let payload = data;
    payload["task_type"] = task_type["TaskTypeId"];
    payload["run"] = true;
    payload["workflow"] = {};
    payload["workflow"]["name"] = workflow.Name;
    let parsedRuntimeParams = getParsedRuntimeParameters(runtimeParams);
    payload["workflow"]["params"] = parsedRuntimeParams;

    return payload;
  };

  const createTaskWithWF = runtimeParams => {
    createTask(createTaskWithWFPayload(runtimeParams));
  };

  const createTaskWithSchedule = async schedule => {
    schedule["version"] = selectedWorkflow.DocumentVersion;
    schedule["expression"] = schedule.schedule_expression;
    delete schedule["schedule_expression"];
    let createTaskPayload = createTaskWithWFPayload(selectedRequiredParameters);
    delete createTaskPayload["run"];
    try {
      let response = await Api.createTask(createTaskPayload);
      response = typeof response === "string" ? JSON.parse(response) : response;
      let taskId = response?.TaskId;
      schedule["task_id"] = taskId;
      schedule["params"] = createTaskPayload["workflow"]["params"];

      /**
       * Create Schedule using the Task Id from the task created above
       */
      await Api.scheduleRunbook(selectedTaskDetails.workflow.Name, schedule);
      dispatch(fetchAllTaskList());
      setIsCreateTaskModalOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const createTask = async payload => {
    try {
      await Api.createTask(payload);
      dispatch(fetchAllTaskList());
    } catch (e) {
      console.log(e);
    }
    setIsCreateTaskModalOpen(false);
  };

  /**
   * Fetch RuntimeParameters on WF change
   */
  useEffect(() => {
    if (selectedWorkflow.Name) {
      (async () => {
        try {
          let response = await Api.getRunbookVersion(
            selectedWorkflow?.Name,
            null,
          );
          let reqParams = getRuntimeParameterInitialValues(response.Parameters);
          setSelectedRequiredParameters(reqParams);
          setRequiredParameters(response.Parameters);
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [selectedWorkflow]);

  const getInitialValues = () => {
    switch (currentStep) {
      case 1:
        return selectedTaskDetails;
      case 2:
        return selectedRequiredParameters;
      case 3:
        return getCurrentSchedule();
    }
  };

  const onSubmitHandler = formik => {
    if (currentStep === 1 && !formik.workflow.Name) {
      /**
       * Create Task without Runtime parameters
       */
      createTaskWithoutWF(formik);
    } else if (currentStep === 1 && formik.workflow.Name) {
      /**
       * Go to next step Runtime parameters
       */
      setSelectedTaskDetails(formik);
      setCurrentStep(currentStep + 1);
    } else if (
      currentStep === 2 &&
      workflowRunType === WorkflowRunType.AUTOMATIC
    ) {
      /**
       * Create Task with Runtime parameters
       */
      createTaskWithWF(formik);
    } else if (
      currentStep === 2 &&
      workflowRunType === WorkflowRunType.SCHEDULE
    ) {
      /**
       * Go to next step Schedule Run
       */
      setSelectedRequiredParameters(formik);
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 3) {
      /**
       * Create Task with Runtime parameters
       * Call Schedule API with created task_id
       */
      createTaskWithSchedule(formik);
    }
  };

  const getSidebar = () => {
    return (
      <div className="sidebar-list">
        <li className="pb-30-px">
          <img
            src={
              currentStep === 1
                ? RadioButtonIcon
                : currentStep > 1
                ? CheckIcon
                : RadioButtonUnchecked
            }
            alt="radio-icon"
            className="page-tick-icon"
          />
          <span>Incident Details</span>
        </li>
        {selectedWorkflow.Name !== "" && (
          <li className="pb-30-px">
            <img
              src={
                currentStep === 2
                  ? RadioButtonIcon
                  : currentStep > 2
                  ? CheckIcon
                  : RadioButtonUnchecked
              }
              alt="radio-icon"
              className="page-tick-icon"
            />
            <span>Runtime Parameters</span>
          </li>
        )}
        {workflowRunType === WorkflowRunType.SCHEDULE &&
          selectedWorkflow.Name !== "" && (
            <li className="pb-30-px">
              <img
                src={
                  currentStep === 3
                    ? RadioButtonIcon
                    : currentStep > 3
                    ? CheckIcon
                    : RadioButtonUnchecked
                }
                alt="radio-icon"
                className="page-tick-icon"
              />
              <span>Schedule Workflow Run</span>
            </li>
          )}
      </div>
    );
  };

  const getSubmitButtonText = formik => {
    if (
      (!formik.values.workflow?.Name && currentStep === 1) ||
      (workflowRunType === WorkflowRunType.AUTOMATIC && currentStep === 2) ||
      currentStep === 3
    ) {
      return "Create Incident";
    } else if (
      (formik.values.workflow?.Name && currentStep === 1) ||
      (workflowRunType === WorkflowRunType.SCHEDULE && currentStep === 2)
    ) {
      return "Next";
    }
  };

  const getFooter = formik => {
    const { handleSubmit } = formik;
    return (
      <div className="modal-buttons-footer__wizard">
        <Button
          text="Cancel"
          buttonStyle="secondary"
          className="button-tertiary modal-buttons-footer__button"
          size="large"
          type="button"
          onClick={() => {
            setIsCreateTaskModalOpen(false);
            setCurrentStep(1);
          }}
        />
        {currentStep > 1 && (
          <Button
            text="Back"
            buttonStyle="secondary"
            className="modal-buttons-footer__button"
            size="large"
            type="button"
            onClick={() => {
              if (currentStep === 2) {
                setSelectedRequiredParameters(formik.values);
              }
              setCurrentStep(currentStep - 1);
            }}
          />
        )}
        <Button
          text={getSubmitButtonText(formik)}
          className="modal-buttons-footer__button"
          buttonStyle="primary"
          size="large"
          onClick={handleSubmit}
        />
      </div>
    );
  };

  const getFormTitle = () => {
    switch (currentStep) {
      case 1:
        return "Incident Details";
      case 2:
        return "Runtime Parameters For Workflow";
      case 3:
        return "Schedule Workflow Run";
    }
  };

  const getCurrentSchedule = () => {
    let expression = fixedRate
      ? fixedRate
      : scheduleExpression
      ? `cron(${scheduleExpression.join(" ")})`
      : null;
    return {
      schedule_expression: expression,
    };
  };

  return (
    <>
      {isCreateTaskModalOpen ? (
        <Formik
          initialValues={getInitialValues()}
          onSubmit={onSubmitHandler}
          validationSchema={getValidationSchema(
            currentStep,
            requiredParameters,
          )}
          enableReinitialize={true}
        >
          {formik => {
            return (
              <ModalWithSidebar
                onClose={() => setIsCreateTaskModalOpen(false)}
                title={"Create New Incident"}
                footer={getFooter(formik)}
                showClose={true}
                containerClass="w-90"
                sidebarComponent={getSidebar()}
                formTitle={getFormTitle()}
                formDescription={
                  currentStep === 2
                    ? "If applicable, please fill in the Runtime Parameters needed for the Incidents default Workflow."
                    : ""
                }
              >
                {currentStep === 1 && (
                  <CreateTaskDetails
                    formik={formik}
                    workflowRunType={workflowRunType}
                    setWorkflowRunType={setWorkflowRunType}
                    enumWorkflowRunType={WorkflowRunType}
                    setSelectedWorkflow={setSelectedWorkflow}
                  />
                )}
                {currentStep === 2 && (
                  <RuntimeParameters
                    formik={formik}
                    requiredParameters={requiredParameters}
                  />
                )}
                {currentStep === 3 && (
                  <Schedule
                    currentSchedule={getCurrentSchedule()}
                    fixedRate={fixedRate}
                    scheduleExpression={scheduleExpression}
                    setValues={setValues}
                  />
                )}
              </ModalWithSidebar>
            );
          }}
        </Formik>
      ) : (
        ""
      )}
    </>
  );
};

export default CreateTask;
