/**
 * The key name for connectors should be the same as it
 * is in the `/v1.0/settings/connectors` API
 */

/**
 * baseUrl: We are using it as a subRoutes for nested tabs
 * defaultUrl: Using it for navigation from one screen to another screen
 */

export const RouteConstants = {
  forgotPassword: {
    displayName: "Forgot Password",
    url: "/forgot-password",
  },

  login: {
    displayName: "Login",
    url: "/login",
  },

  signUp: {
    displayName: "Sign Up",
    url: "/sign-up",
  },

  runbookEditor: {
    displayName: "Runbook Editor",
    url: "/workflow/editor",
  },

  runbookDetails: {
    displayName: "Runbook Details",
    baseUrl: "/workflow/:name",
    url: "/workflow/:name/:subTab",
    defaultUrl: "/workflow/:name/parameters",
  },

  runbooks: {
    displayName: "Runbooks",
    url: "/workflows/:subTab",
    defaultUrl: "/workflows/my-workflows",
  },

  schedules: {
    displayName: "Schedules",
    url: "/schedules",
  },

  /** Settings Routes begin */
  settings: {
    displayName: "Settings",
    baseUrl: "/settings",
    url: "/settings/:subTab",
  },

  awsSettings: {
    displayName: "AWS Settings",
    url: "/settings/aws-settings",
  },

  git: {
    displayName: "GitHub Repos",
    url: "/settings/git-settings",
  },

  ssh: {
    displayName: "SSH Targets",
    url: "/settings/ssh-targets",
  },

  jira: {
    displayName: "Jira",
    url: "/settings/integration/jira/:subTab",
    defaultUrl: "/settings/integration/jira/description",
  },

  slack: {
    displayName: "Slack",
    url: "/settings/integration/slack/:subTab",
    defaultUrl: "/settings/integration/slack/description",
  },

  zoom: {
    displayName: "Zoom",
    url: "/settings/integration/zoom/:subTab",
    defaultUrl: "/settings/integration/zoom/description",
  },

  twilio: {
    displayName: "Twilio",
    url: "/settings/integration/twilio/:subTab",
    defaultUrl: "/settings/integration/twilio/description",
  },

  terraform_cli: {
    displayName: "Terraform Modules",
    url: "/settings/terraform-cli",
  },

  apikeys: {
    displayName: "API Keys",
    url: "/settings/apikeys",
  },

  aws: { displayName: "AWS Target Account", url: "/settings/get-started" },

  s3: { displayName: "S3 Buckets", url: "/settings/s3-buckets" },

  cloudwatch: {
    displayName: "CloudWatch Targets",
    url: "/settings/cloudwatch",
  },

  cloudwatchTarget: {
    url: "/settings/resource/cloudwatch/add-integration/:subTab",
    defaultUrl: "/settings/resource/cloudwatch/add-integration/description",
  },

  cloudformation: {
    displayName: "CloudFormation Templates",
    url: "/settings/cloudformation",
  },

  eks: {
    displayName: "EKS Permissions",
    url: "/settings/eks",
  },

  setupEks: {
    displayName: "EKS Permissions",
    url: "/settings/resource/eks/add-integration/:subTab",
    defaultUrl: "/settings/resource/eks/add-integration/description",
  },

  integration: {
    url: "/settings/integration/:connector/:subTab",
    defaultUrl: "/settings/integration/:connector/description",
  },

  /** Settings Routes end */

  actions: {
    displayName: "Actions",
    url: "/actions",
  },

  actionDetails: {
    displayName: "Action Details",
    baseUrl: "/actions/:name",
    url: "/actions/:name/:subTab",
  },

  /** Execution Routes begin */
  executionsRun: {
    displayName: "Executions Run",
    url: "/executions/run/*",
  },

  executionDetails: {
    displayName: "Execution Details",
    url: "/executions/execution-details",
  },

  executionRunDetails: {
    displayName: "Execution Run Details",
    baseUrl: "/executions/details/:executionId/step/:stepExecutionId",
    url: "/executions/details/:executionId/step/:stepExecutionId/:subTab",
  },

  executions: {
    displayName: "Executions",
    url: "/executions",
  },
  /** Execution Routes end */

  userProfile: {
    displayName: "User Profile",
    baseUrl: "/user-profile",
    url: "/user-profile/:subTab",
  },

  appInfo: {
    displayName: "App Info",
    url: "/app-info",
  },

  taskManagement: {
    displayName: "Incident Management",
    url: "/incidents",
  },

  taskDetails: {
    displayName: "Incident Details",
    baseUrl: "/incidents/incident-details",
    url: "/incidents/incident-details/:taskId/:subTab",
  },
};
