import React from "react";
import { connect } from "react-redux";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { Input } from ".";
import Output from "../output";
import Details from "../details";
import {
  hideGlobalLoader,
  showGlobalLoader,
} from "@redux/actions/globalLoader.actions";

class DatadogConnectorPanel extends React.Component {
  componentDidMount() {
    if (this.props.loaderFlag) {
      this.props.showGlobalLoader();
    }
  }

  componentWillUnmount() {
    if (!this.props.loaderFlag) {
      this.props.hideGlobalLoader();
    }
  }

  render() {
    return (
      <Consumer>
        {({
          datadogMonitorList,
          datadogError,
          isFetchingDatadogMonitorList,
          getDatadogMonitorList,
          activeNode,
          runbook, // activeRunbook - the data from the API
        }) => (
          <TabCollection
            active="input"
            activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
            inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
            contentTop={50}
            tabs={["input", "output", "details"]}
          >
            <Tab title="Input" name="input">
              <TabContent>
                <Input
                  getDatadogMonitorList={getDatadogMonitorList}
                  datadogMonitorList={datadogMonitorList}
                  isFetchingDatadogMonitorList={isFetchingDatadogMonitorList}
                  datadogError={datadogError}
                  activeNode={activeNode}
                  activeRunbook={runbook}
                />
              </TabContent>
            </Tab>
            <Tab title="Output" name="output">
              <TabContent>
                <Output />
              </TabContent>
            </Tab>
            <Tab title="Details" name="details">
              <TabContent>
                <Details />
              </TabContent>
            </Tab>
          </TabCollection>
        )}
      </Consumer>
    );
  }
}

const mapState = ({ globalLoaderReducer }) => ({
  loaderFlag: globalLoaderReducer.loaderFlag,
});

const mapDispatch = dispatch => ({
  showGlobalLoader: () => dispatch(showGlobalLoader()),
  hideGlobalLoader: () => dispatch(hideGlobalLoader()),
});

export default connect(mapState, mapDispatch)(DatadogConnectorPanel);
