import React, { useState } from "react";
import "./MapList.scss";
import JSONEditor from "@components/ui/InputTypes/JSONEditor/JSONEditor";
import AddButton from "../Layouts/AddButton/AddButton";
import { isEmpty } from "@lib/utils";
import Header from "../Layouts/Header/Header";

type MapListProps = {
  title?: string;
  description?: string;
  helpText?: string;
  mapListArray: Array<object>;
  onChange: (value) => void;
};

const MapList: React.FC<MapListProps> = ({
  title = "MapList",
  description = "",
  helpText,
  mapListArray,
  onChange,
}) => {
  const [mapList, setMapList] = useState(mapListArray);

  /**
   *
   * @param list - list to filter on the basis of isDeleted flag
   */
  const filterMapList = (list: Array<any>) => list.filter(m => !m["isDeleted"]);
  /**
   * Called when "+ Add to MapList" is clicked
   */
  const onAddMap = () => {
    let updatedMapList = [...mapList];
    updatedMapList.push({});
    setMapList(updatedMapList);
  };

  /**
   * Edit/Add a map object ({"abc":"xyz"})
   * in mapList
   * @param {value} content - edited or newly created map object content
   * @return {void}
   */

  const updateContent = (value, idx) => {
    let updatedMapList = [...mapList];
    updatedMapList[idx] = value;
    setMapList(updatedMapList);
    onChange(filterMapList(updatedMapList));
  };

  /**
   * Delete a map object ({"abc":"xyz"})
   * from mapList
   * @param {idx} index - index of map object to be deleted
   * @return {void}
   */

  const onDeleteHandler = idx => {
    let updatedMapList = mapList.map((val, id) => {
      if (id === idx) {
        val["isDeleted"] = true;
      }
      return val;
    });
    setMapList(updatedMapList);
    onChange(filterMapList(updatedMapList));
  };

  return (
    <>
      <div className={`ml-header ${mapList.length > 0 && "mb-5-px"}`}>
        <Header title={title} description={description} helpText={helpText} />
      </div>
      {!!mapList &&
        Array.isArray(mapList) &&
        mapList.map((mapObj, idx) => {
          return !mapObj["isDeleted"] ? (
            <JSONEditor
              key={idx}
              title={`Map Object`}
              editorTitle={`Map Object`}
              value={
                !isEmpty(mapObj) ? JSON.stringify(mapObj, undefined, 4) : ""
              }
              displayContainerClassName="mb-10-px"
              allowDelete
              onChange={value => {
                updateContent(value, idx);
              }}
              onDelete={() => onDeleteHandler(idx)}
            />
          ) : null;
        })}
      <AddButton title={`Add To MapList`} handleClick={onAddMap} />
    </>
  );
};

export default MapList;
