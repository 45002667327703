import React from "react";
import { getMarkerEnd } from "react-flow-renderer";
import { getCustomCenter, getCustomSmoothStepPath } from "./helpers";

const foreignObjectSize = 45;

const DefaultEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  arrowHeadType,
  markerEndId,
  data,
  source,
  target,
}) => {
  const edgePath = getCustomSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const [edgeCenterX, edgeCenterY] = getCustomCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const onEdgeClick = (evt, id) => {
    evt.stopPropagation();
    data.onEdgeRemoveMutation(id, source, target);
  };

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path-outer"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <button
          className="rf-editor-edgebutton"
          onClick={event => onEdgeClick(event, id)}
        ></button>
      </foreignObject>
    </>
  );
};

export default DefaultEdge;
