import React from "react";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  LabelTop,
  DropDown,
  DropDownOption,
} from "@components/ui";
import { ComparisonOperators } from "@containers/RunbookEditor/runbook-editor-lib/ssm/strings";

export default props => (
  <Accordion isExpanded={true} useArrow={true}>
    <AccordionLabel className="editor-accordion-label">
      2. Select Comparison Operation
    </AccordionLabel>
    <AccordionSection>
    <div className="editor-detail-panel editor-detail-panel-column">
      <div className="editor-terraform-container">
        <LabelTop
          label={`How to evaluate the selected variable`}
          labelClassName="label"
        >
        <DropDown
          title={props.choice.condition.operator || "Comparison Condition"}
          showArrow={true}
          iconLeft="icon-filter"
          style={{ width: "calc(100% - 8px)", marginTop: "0px" }}
          onChange={newValue => {
            props.choice.condition.operator = newValue;
            props.notifyRunbookUpdate(true);
            props.activeNode.extras.runbookNode.didUpdateStep({});
          }}
        >
          {ComparisonOperators.LeafOperators.filter(op => {
            let selectedVariableType = props.selectedVariableType;
            return selectedVariableType
              ? (op.type === "String" && selectedVariableType === "String") ||
                  (op.type === "Numeric" &&
                    selectedVariableType === "Integer") ||
                  (op.type === "Boolean" && selectedVariableType === "Boolean")
              : true;
          }).map(op => {
            return <DropDownOption text={op.name} key={op.name} />;
          })}
        </DropDown>
      </LabelTop>
      </div>
      </div>
    </AccordionSection>
  </Accordion>
);
