import React from "react";
import { Button } from "@components/ui";
import { HeaderMain } from "@components/modules";
import { Consumer } from "../runbooks-lib/runbooks.context";
import { useParams } from "react-router-dom";
import { toProperCase } from "@lib/utils";

export default () => {
  const { subTab } = useParams();
  return (
    <Consumer>
      {({ toggleRunbookModal }) => (
        <HeaderMain
          title="All Workflows"
          breadCrumbText={`Workflows/${toProperCase(subTab)}`}
          showBreadcrumbs={true}
        >
          <Button
            buttonStyle="primary"
            onClick={toggleRunbookModal}
            size="small"
            text="New Workflow"
            icon="icon-plus"
          ></Button>
        </HeaderMain>
      )}
    </Consumer>
  );
};
