import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import Details from "../../details";
import { InstanaAlertInput } from "./instana-input";
import OutputNonAction from "../../OutputNonAction";
import { hideGlobalLoader } from "@redux/actions/globalLoader.actions";

function InstanaConnectorPanel(props) {
  const { activeNode } = props;
  const [outputs, setOutputs] = useState(activeNode?.runbookNode?.outputs);

  const runbookEditorContext = useContext(RunbookEditorContext);
  const { notifyRunbookUpdate } = runbookEditorContext;

  useEffect(() => {
    console.log(`outputs changed`, { outputs });
  }, [outputs]);

  useEffect(() => {
    if (!props.loaderFlag) {
      props.hideGlobalLoader();
    }
  }, [props]);

  return (
    <TabCollection
      active="input"
      activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
      inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
      contentTop={50}
      tabs={["input", "output", "details"]}
    >
      <Tab title="Input" name="input">
        <TabContent>
          <InstanaAlertInput
            activeNode={activeNode}
            runbookNode={activeNode.extras.runbookNode}
            notifyRunbookUpdate={notifyRunbookUpdate}
            setOutputs={setOutputs}
          />
        </TabContent>
      </Tab>
      <Tab title="Output" name="output">
        <TabContent>
          <OutputNonAction outputs={outputs} activeNode={activeNode} />
        </TabContent>
      </Tab>
      <Tab title="Details" name="details">
        <TabContent>
          <Details />
        </TabContent>
      </Tab>
    </TabCollection>
  );
}

const mapState = ({ actionNodeReducer, globalLoaderReducer }) => {
  return {
    operations: actionNodeReducer.operations,
    loaderFlag: globalLoaderReducer.loaderFlag,
  };
};

const mapDispatch = dispatch => ({
  hideGlobalLoader: () => dispatch(hideGlobalLoader()),
});

export default connect(mapState, mapDispatch)(InstanaConnectorPanel);
