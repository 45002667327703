import React from "react";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { InputSelector } from "@containers/RunbookEditor/runbook-editor-components/editor-right-panel/input-selector";
import { getSelectedOption } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.helpers";

export default class InputGroup extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.inputs &&
          this.props.inputs.length > 0 &&
          this.props.selectedAwsOperation && (
            <Accordion isExpanded={true} useArrow={true}>
              <AccordionLabel className="editor-accordion-label mt-10-px">
                {this.props.groupName}
              </AccordionLabel>
              <AccordionSection>
                <div className="editor-terraform-container">
                  {this.props.inputs.map((input, i) => {
                    return (
                      <InputSelector
                        optionFilterId={this.props.optionFilterId}
                        toggleInputOption={this.props.toggleInputOption}
                        input={input}
                        selectedOption={getSelectedOption(input)}
                        key={`${input.name}-${i}`}
                        label={this.props.label}
                      />
                    );
                  })}
                </div>
              </AccordionSection>
            </Accordion>
          )}
      </React.Fragment>
    );
  }
}
