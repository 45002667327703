import React from "react";
import { Consumer } from "../../../runbook-editor-lib/runbook-editor.context";
import EditorSettings from "./settings/EditorSettings";
import EditorVersions from "./versions/EditorVersions";
import EditorAddSnippets from "./AddSnippets/AddSnippets";
import backIcon from "@assets/images/icons/icon-back.svg";
import "./EditorSlideouts.scss";
export default class EditorMenuSlideouts extends React.Component {
  renderTabContent = activePanel => {
    switch (activePanel) {
      case "add":
        return <EditorAddSnippets />;

      case "versions":
        return <EditorVersions />;

      case "settings":
        return (
          <EditorSettings
            updateRunbookDescription={this.props.updateRunbookDescription}
          />
        );

      default:
        return;
    }
  };

  render() {
    return (
      <Consumer>
        {({ activePanel, activePanelTitle }) => (
          <React.Fragment>
            {activePanel && (
              <>
                {this.props.isEditorOverlayOpen && activePanel !== "add" && (
                  <div
                    className="help-overlay"
                    onClick={this.props.closePanel}
                  ></div>
                )}
                <div className="editor-slideout" onClick={this.canedlBubble}>
                  <div className="editor-slideout-content ">
                    <div className="editor-slideout-content-heading">
                      <div className={"editor-slideout-content-heading__title"}>
                        {activePanelTitle}
                        {this.props.actionButton &&
                        activePanel === this.props.actionButton.activePanel ? (
                          <button
                            className="action-button"
                            type="button"
                            onClick={this.props.actionButton.action}
                          >
                            <img src={this.props.actionButton.icon} alt="" />
                          </button>
                        ) : null}
                      </div>
                      <button
                        className="panel-close"
                        type="button"
                        onClick={this.props.closePanel}
                      >
                        <img
                          src={backIcon}
                          alt="backIcon"
                          height="12"
                          width="12"
                        />
                      </button>
                    </div>
                    {this.renderTabContent(activePanel)}
                  </div>
                </div>
              </>
            )}
          </React.Fragment>
        )}
      </Consumer>
    );
  }
}

EditorMenuSlideouts.propTypes = {};
