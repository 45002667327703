import React, { useEffect } from "react";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { ConditionalInput, Output, Details } from ".";
import { useDispatch, useSelector } from "react-redux";
import { hideGlobalLoader } from "@redux/actions/globalLoader.actions";

const Conditional = props => {
  const loaderState = useSelector(state => state.globalLoaderReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaderState.loaderFlag) {
      dispatch(hideGlobalLoader());
    }
  }, [loaderState, dispatch]);

  return (
    <TabCollection
      active="input"
      activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
      inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
      contentTop={50}
      tabs={["input", "output", "details"]}
    >
      <Tab title="Input" name="input">
        <TabContent>
          <ConditionalInput
            rerenderEditor={props.rerenderEditor}
            addLinkToStep={props.addLinkToStep}
            removeLinkToStep={props.removeLinkToStep}
          />
        </TabContent>
      </Tab>
      <Tab title="Output" name="output">
        <TabContent>
          <Output />
        </TabContent>
      </Tab>
      <Tab title="Details" name="details">
        <TabContent>
          <Details />
        </TabContent>
      </Tab>
    </TabCollection>
  );
};

export default Conditional;
