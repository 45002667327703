import React, { useState, useEffect, useContext } from "react";
import { DataTable } from "@components/ui/data-table";
import { TableColumns } from "../snippets-lib/snippets-helper";
import { SnippetContext } from "../snippets-lib/snippets.context";
import { getFilteredSnippets } from "@lib/utils";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";

const SnippetsTableContainer = () => {
  const [actionsList, setActionsList] = useState([]);
  const snippetContext = useContext(SnippetContext);
  const {
    snippetsList,
    searchTerm,
    filteredServicesSet,
    resetFilters,
    isFetchingSnippets,
    setSelectedSnippet,
    snippetsCPage,
    snippetsRecords,
    snippetsSortingDetails,
    updateRecordsCurrentPage,
    updateRecordsPerPage,
    updateSortColumn,
  } = snippetContext;

  useEffect(() => {
    let list = getFilteredSnippets(
      snippetsList,
      searchTerm,
      filteredServicesSet,
    );
    setActionsList(list);
  }, [snippetsList, searchTerm, filteredServicesSet]);

  const changeSnippetsCurrentPage = cPage => {
    updateRecordsCurrentPage({
      tableName: "snippetsCPage",
      cPage: cPage,
    });
  };

  const changeSnippetsRowsPerPage = rows => {
    updateRecordsPerPage({
      tableName: "snippetsRecords",
      cPageRecords: rows,
    });
  };

  const handleSnippetsSort = (column, sortDirection) => {
    updateSortColumn({
      tableName: "snippets",
      sortField: column?.selector,
      sortFieldDirection: sortDirection,
    });
  };

  return (
    <>
      {!isFetchingSnippets &&
        (Array.isArray(actionsList) && actionsList.length > 0 ? (
          <DataTable
            className="snippet-list-table"
            columns={TableColumns(setSelectedSnippet)}
            data={actionsList}
            responsive={true}
            pagination={true}
            recordsPerPage={snippetsRecords}
            currentPage={snippetsCPage}
            onChangeCurrentPage={changeSnippetsCurrentPage}
            onChangeRowsPerPage={changeSnippetsRowsPerPage}
            defaultSortField={snippetsSortingDetails?.sortField}
            defaultSortAsc={
              snippetsSortingDetails?.sortFieldDirection === "asc"
                ? true
                : false
            }
            onSort={handleSnippetsSort}
          />
        ) : (
          <NoResultFound
            message="No results found"
            detail="Try adjusting your search to find what you're looking for."
            className="mt-100-px"
            errImgSrc="search-es"
            fallbackComponent={
              <span className="reset-search" onClick={resetFilters}>
                Clear Search & Filters
              </span>
            }
          />
        ))}
    </>
  );
};

export default SnippetsTableContainer;
