import { getTourContainer } from "../Tour";

export const getTourSteps = (toggleIsTourOpen, toggleWelcomeDialog) => {
  const steps = [
    {
      selector: '[data-tut="reactour__add"]',
      content: ({ goTo, step }) =>
        getTourContainer(
          goTo,
          step,
          steps,
          toggleIsTourOpen,
          toggleWelcomeDialog,
          "Add Actions",
          "Drag and drop actions from the Add panel to start building your workflow.",
        ),
      style: {
        backgroundColor: "#282828",
      },
    },
    {
      selector: '[data-tut="reactour__versions"]',
      content: ({ goTo, step }) =>
        getTourContainer(
          goTo,
          step,
          steps,
          toggleIsTourOpen,
          toggleWelcomeDialog,
          "Manage Versions",
          "Create new and manage existing versions of your workflow. ",
        ),
      style: {
        backgroundColor: "#282828",
      },
    },
    {
      selector: '[data-tut="reactour__settings"]',
      content: ({ goTo, step }) =>
        getTourContainer(
          goTo,
          step,
          steps,
          toggleIsTourOpen,
          toggleWelcomeDialog,
          "Configure Settings For The Workflow",
          "Add a Description (required) and Tags, and review Runtime Parameters for the workflow.",
        ),
      style: {
        backgroundColor: "#282828",
      },
    },
    {
      selector: '[data-tut="reactour__rightPanel"]',
      content: ({ goTo, step }) =>
        getTourContainer(
          goTo,
          step,
          steps,
          toggleIsTourOpen,
          toggleWelcomeDialog,
          "Configure Actions",
          "Configure Actions to connect your cloud stack in interesting ways to solve various business tasks.",
        ),
      style: {
        backgroundColor: "#282828",
      },
    },
    {
      selector: '[data-tut="reactour__save"]',
      content: ({ goTo, step }) =>
        getTourContainer(
          goTo,
          step,
          steps,
          toggleIsTourOpen,
          toggleWelcomeDialog,
          "Save, Run & Schedule Your Workflow",
          "The Workflow Editor allows you to Save Versions, Schedule Runs, or simply Run Now.",
        ),
      position: "bottom",
      style: {
        backgroundColor: "#282828",
      },
    },
    {
      selector: '[data-tut="reactour__help"]',
      content: ({ goTo, step }) =>
        getTourContainer(
          goTo,
          step,
          steps,
          toggleIsTourOpen,
          toggleWelcomeDialog,
          "Reset Walkthrough",
          "If you ever need a refresher, you can return to this walkthrough anytime through here.",
        ),
      style: {
        backgroundColor: "#282828",
      },
    },
  ];
  return steps;
};
