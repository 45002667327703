import camelCase from "lodash/camelCase";
import findIndex from "lodash/findIndex";
import flatten from "lodash/flatten";
import { prohibitedPrefixesForRunbookName } from "@lib/utils/constants";
import { store } from "@redux/store";
import { StepTypeChecker } from "../../containers/RunbookEditor/runbook-editor-lib/neuropssteps/steptypechecker";
import { SSMActionNames } from "../../containers/RunbookEditor/runbook-editor-lib/ssm/strings";
import Bugsnag from "@bugsnag/js";
const config = require("./../../config/config.json");

export const setTitle = title => {
  document.title = title;
};

export const mergeObjects = (originalObject, newObject) => {
  Object.keys(newObject).forEach(key => {
    originalObject[key] = newObject[key];
  });
};

export const getUrlParameter = (pathPartNumber = 3) => {
  const urlArray = urlParts();
  let index;
  if (pathPartNumber === "last") {
    index = urlArray.length - 2;
  } else if (pathPartNumber === "second-last") {
    index = urlArray.length - 3;
  } else {
    index = pathPartNumber;
  }
  return urlArray[index];
};

export const getBreadCrumbs = () => {
  const urlArray = urlParts();
  const lastIndex = urlArray.length - 1;
  if (urlArray[lastIndex] === "") {
    urlArray.pop();
  }
  return urlArray;
};

export const cleanRunbooks = runbookObj => {
  const mainSteps = [];
  runbookObj.mainSteps.forEach(step => {
    if (step === undefined) {
      console.log("Undefined");
    } else {
      mainSteps.push(step);
    }
  });
  runbookObj.mainSteps = mainSteps;
  return runbookObj;
};

export const urlParts = () => {
  let url = window.location.href;
  if (url.charAt(url.length - 1) !== "/") {
    url += "/";
  }
  const urlArray = url.split("/");
  /* strip off query string */
  let urlSliced = urlArray.slice(3);
  let last = urlSliced[urlSliced.length - 2].split("?");
  urlSliced[urlSliced.length - 2] = last[0];
  return urlSliced;
};

export const isEmpty = obj => {
  if (!obj) {
    return true;
  }
  return Object.entries(obj).length === 0 && obj.constructor === Object;
};

export const isRunbookListEmpty = runbooks => {
  if (isEmpty(runbooks)) {
    return true;
  }
  const filtered = Object.keys(runbooks).filter(
    owner => runbooks[owner].length > 0,
  );
  if (!filtered || !filtered.length) {
    return true;
  } else {
    return false;
  }
};

export const isNull = str => {
  if (!str) {
    return true;
  }
  if (typeof str === "undefined") {
    return true;
  }
  return false;
};

export const toProperCase = str => {
  try {
    str = str.toLowerCase();
    str = str.replaceAll("-", " ");
    str = str.split(" ");
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
  } catch (e) {
    return str;
  }
};

export const formatLabelName = str => {
  str = str.toLowerCase();
  str = str.split("_");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
};

export const getIntersection = (a, b) =>
  [...new Set(a)].filter(v => b.includes(v));

export const capitalizeFirstLetter = str => {
  if (!str) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/*
  sortObject - Sorts the array of object by a given key

  Params:
    items - Object array
    sortByField - sort key
    direction - sorting direction (default: asc)
    numeric - flag if sortByField needs to be considered as integer value while sorting
*/
export const sortObject = (
  items,
  sortByField = "",
  direction = "asc",
  numeric = false,
) => {
  if (!items || !sortByField) {
    return;
  }

  if (Array.isArray(items)) {
    let __arr = items.slice(0);
    let sorted = __arr.sort((a, b) => {
      if (direction === "asc") {
        if (numeric) {
          return parseInt(a[sortByField]) > parseInt(b[sortByField]) ? 1 : -1;
        }
        return a[sortByField] > b[sortByField] ? 1 : -1;
      } else {
        if (numeric) {
          return parseInt(a[sortByField]) < parseInt(b[sortByField]) ? 1 : -1;
        }
        return a[sortByField] < b[sortByField] ? 1 : -1;
      }
    });

    return sorted;
  } else if (items.constructor.name === "Object") {
    const itemKeys = Object.keys(items);
    if (itemKeys.length === 1) return items;
    let sorted = {};
    const sortedKeys = itemKeys.sort(
      (a, b) => items[a][sortByField] - items[b][sortByField],
    );
    sortedKeys.forEach(key => {
      sorted[key] = items[key];
    });
    return sorted;
  }
  return items;
};

export const getFriendlyDate = date => {
  const _date = new Date(date);
  const Year = _date.getFullYear();
  let day = formatLeadingZero(_date.getDate());
  let month = formatLeadingZero(_date.getMonth() + 1);
  return `${Year}-${month}-${day}`;
};

export const getFriendlyDateWithHour = date => {
  const _date = new Date(date);
  const hour = formatLeadingZero(_date.getHours());
  return getFriendlyDate(date) + ` ${hour}`;
};

export const getFriendlyDateRoundMinutes = date => {
  const coeff = 1000 * 60 * 5;
  const _date = new Date(date);
  const rounded = new Date(Math.round(_date.getTime() / coeff) * coeff);
  const hour = formatLeadingZero(rounded.getHours());
  const minutes = formatLeadingZero(rounded.getMinutes());
  return getFriendlyDate(date) + ` ${hour}:${minutes}`;
};
export const getFriendlyDateWithQuarterHour = date => {
  const coeff = 1000 * 60 * 15;
  const _date = new Date(date);
  const rounded = new Date(Math.round(_date.getTime() / coeff) * coeff);
  const hour = formatLeadingZero(rounded.getHours());
  const minutes = formatLeadingZero(rounded.getMinutes());
  return getFriendlyDate(date) + ` ${hour}:${minutes}`;
};

const formatLeadingZero = item => {
  if (item < 10) {
    return `0${item}`;
  }
  return item;
};

export const getFriendlyDateTime = date => {
  const dateWithYear = getFriendlyDate(date);
  const _date = new Date(date);
  let hour = formatLeadingZero(_date.getHours());
  let minute = formatLeadingZero(_date.getMinutes());
  return `${dateWithYear} ${hour}:${minute}`;
};

export const addMinutes = (date, minutes) => {
  const dateObject = new Date(date);
  return new Date(dateObject.getTime() + minutes * 60000);
};

export const removeHashFromAddressBar = () => {
  window.onhashchange = () => _removeHash();
};

function _removeHash() {
  window.history.pushState("", document.title, window.location.pathname);
}

export function generateId(prefix = "") {
  const letters = getRandomLetters(4);
  return prefix + letters + Math.floor(Date.now() / 1000);
}

export function getRandomLetters(n) {
  const alpha = "abcdefghijklmnopqrstuvwxyz";
  let retval = "";
  for (let i = 0; i < n; i++) {
    retval += alpha.charAt(getRandomInt(0, 25));
  }
  return retval;
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function listObjectsToArray(object) {
  return Object.keys(object).map(key => object[key]);
}

export function keyNamesToUpperCase(arrayOfObjects) {
  return arrayOfObjects.map(list => {
    let newList = {};
    /* eslint-disable-next-line */
    Object.keys(list).map(key => {
      newList[capitalizeFirstLetter(key)] = list[key];
    });
    return newList;
  });
}

export function getFormElements(form) {
  let formDictionary = [];
  for (let i = 0; i < form.elements.length; i = i + 1) {
    let formElement = form.elements[i];
    formDictionary.push({
      name: formElement.name,
      value: formElement.value,
    });
  }
  return formDictionary;
}

export function categorizeList(category, list) {
  if (!list) {
    return;
  }
  const returnArray = [];
  list.forEach(item => {
    const key = item[category];
    if (!returnArray.hasOwnProperty(key)) {
      returnArray[key] = [];
    }
    returnArray[key].push(item);
  });
  return returnArray;
}

export function getSnippetDisplayNameMap(list) {
  let map = new Map();
  list.forEach(item => {
    map.set(item.name, item.display_name);
  });
  return map;
}

export function categorizeSnippets(list) {
  let organizedByServiceList = {};
  list.forEach(item => {
    if (!organizedByServiceList.hasOwnProperty(item.type)) {
      organizedByServiceList[item.type] = [];
    }
    const type = organizedByServiceList[item.type];
    if (type.hasOwnProperty(item.service)) {
      type[item.service].push(item);
    } else {
      type[item.service] = [item];
    }
  });
  /* hack: put custom snippets in last */
  try {
    if (organizedByServiceList?.SNIPPET?.Execution) {
      const execution = organizedByServiceList.SNIPPET.Execution;
      delete organizedByServiceList.SNIPPET.Execution;
      organizedByServiceList.SNIPPET["Execution"] = execution;
    }
  } catch (e) {
    console.log(e);
  }
  return organizedByServiceList;
}

export function categorizeSnippetsByService(list) {
  let serviceSnippetMap = list.reduce((acc, snippet) => {
    if (Array.isArray(snippet.service)) {
      snippet.service.forEach(service => {
        if (acc.hasOwnProperty(service)) {
          acc[service].push(snippet);
        } else {
          acc[service] = [];
          acc[service].push(snippet);
        }
      });
    }
    return acc;
  }, {});
  return serviceSnippetMap;
}

function searchSnippets(list = [], val = "") {
  let filterdList = [];
  for (let item = 0; item <= list.length - 1; item++) {
    let tags = "";
    if (list[item].tags) {
      tags = list[item].tags.join(" ");
    }
    // @TODO: if the search by name, desc, tags is gonna be used, we need to do more work on it,
    // excluding slashes / commas , dots .... etc
    // for example: check_elb name should be found by searching "check el" string, now it does NOT
    let searchIn = list[item].display_name
      .concat(" ", list[item].description, " ", tags)
      .toLowerCase();
    if (searchIn.search(val.trim().toLowerCase()) > -1) {
      filterdList.push(list[item]);
    }
  }
  return filterdList;
}

const filteredsnippetsByService = (selectedSnippets, selectedSerives) => {
  let filteredSnippets = [];
  for (let key in selectedSnippets) {
    if (selectedSerives.has(key)) {
      filteredSnippets = [...filteredSnippets, ...selectedSnippets[key]];
    }
  }
  return filteredSnippets;
};

export const getFilteredSnippets = (
  snippetsList,
  searchByText,
  selectedSerives,
) => {
  const searchedSnippets = searchSnippets(snippetsList, searchByText);
  const selectedSnippets = categorizeSnippetsByService(searchedSnippets);
  return selectedSerives.size
    ? filteredsnippetsByService(selectedSnippets, selectedSerives)
    : searchedSnippets;
};

export function filterServiceTags(list, payload) {
  // list type is Set()
  list.has(payload) ? list.delete(payload) : list.add(payload);
  return new Set(list);
}

export function deleteDuplicate(property, propertyValue, arrayOfObjects) {
  for (let i = 0; i < arrayOfObjects.length; i++) {
    const item = arrayOfObjects[i];
    if (item[property] === propertyValue) {
      arrayOfObjects.splice(i, 1);
      return arrayOfObjects;
    }
  }
  return arrayOfObjects;
}

export function searchRunbooks(list = [], searchTerm = "") {
  /* eslint-disable array-callback-return */
  let filterdList = [];
  list.find(runbook => {
    runbook.DisplayName = replaceAllSpecialCharWithSpace(runbook.Name);
    let searchIn = hasKeys(runbook, "Name")
      ? runbook.DisplayName.concat(" ", runbook.Tags.join(" ")).concat(
          " ",
          runbook.Name,
        )
      : null;
    if (searchIn) {
      try {
        // Sanitize/Escape the string to avoid issue related to invalid RegExp
        let escapedSearchTerm = escapeRegExp(searchTerm);
        let reg = new RegExp(escapedSearchTerm, "gi");
        let found = searchIn.search(reg);
        if (found > -1) filterdList.push(runbook);
      } catch (error) {
        Bugsnag.notify(error);
      }
    }
  });

  return filterdList;
}

export function filterRunbooks(list = [], selectedCategories = []) {
  /* eslint-disable array-callback-return */
  let filterdList = [];
  filterdList = list.filter(item => {
    return selectedCategories.some(category => {
      return item.Tags.includes(`category:${category}`);
    });
  });
  return filterdList;
}

//returns index of search
export const searchRunbooksByName = (list, searchTerm) =>
  findIndex(list, o => o?.Name === searchTerm);

//deletes from list of runbooks
export function deleteRunbook(list, name) {
  const index = searchRunbooksByName(list, name);
  const retval = [...list.slice(0, list), ...list.slice(index + 1)];
  return retval;
}

export function hasKeys(obj, find) {
  let args = find.split(".");

  for (var i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
}

export function isLocal() {
  return window.location.hostname === "localhost";
}

export function camelToSnake(string) {
  return (
    !!string &&
    string
      .replace(/[\w]([A-Z])/g, function (m) {
        return m[0] + "_" + m[1];
      })
      .toLowerCase()
  );
}

export function snakeToCamel(string) {
  return (
    string &&
    string.replace(/(_\w)/g, function (m) {
      return m[1].toUpperCase();
    })
  );
}

export function snakeToPascal(string) {
  return capitalizeFirstLetter(snakeToCamel(string));
}

export function kebabToCamel(string) {
  if (!string) {
    return "";
  }
  var _string = string.replaceAll("-", "_");
  return snakeToCamel(_string);
}

export function objectLength(object) {
  return Object.keys(object).length;
}

let _foundParent;

export function scrollParentToChild(parent, child, className = "found-text") {
  _foundParent = parent;
  //first, remove previously "found" classname
  removeFoundElement(className);
  setFoundElement(child, className);
  // Where is the parent on page
  var parentRect = parent.getBoundingClientRect();
  // What can you see?
  var parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth,
  };

  // Where is the child
  var childRect = child.getBoundingClientRect();
  // Is the child viewable?
  var isViewable =
    childRect.top >= parentRect.top &&
    childRect.top <= parentRect.top + parentViewableArea.height;

  // if you can't see the child try to scroll parent
  if (!isViewable) {
    // scroll by offset relative to parent
    parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top - 100;
  }
}

export function truncate(string, max, addendum = "...") {
  const shortenedText = string.slice(0, max);
  return shortenedText + addendum;
}

export function removeFoundElement(className = "found-text") {
  if (!_foundParent) {
    return;
  }
  const el = _foundParent.querySelector(`.${className}`);
  if (el) {
    el.classList.remove(className);
  }
}

export function setFoundElement(element, className = "found-text") {
  element.classList.add(className);
}

export const checkIfRunbookNameExists = (runbookTitle, runbooksObj) =>
  !!flatten(Object.values(runbooksObj)).find(
    runbook =>
      replaceAllSpecialCharWithSpace(runbook?.Name.toLowerCase()) ===
      runbookTitle.toLowerCase(),
  );

export const checkIfRunbookNameIsValid = (runbooksObj, runbookName) => {
  let errorMessage = "";

  const doesRunbookWithSameNameExistAlready = checkIfRunbookNameExists(
    runbookName,
    runbooksObj,
  );
  const doesRunbookNameHaveProhibitedPrefix = prohibitedPrefixesForRunbookName.some(
    prohibitedPrefix => runbookName?.toLowerCase().startsWith(prohibitedPrefix),
  );
  const doesRunbookNameContainProhibitedCharacters = !new RegExp(
    "^[A-Za-z0-9 ]+$",
  ).test(runbookName);
  const doesRunbookNameExceedLength =
    runbookName.length < 3 || runbookName.length > 128;

  const isInvalidRunbookName =
    doesRunbookWithSameNameExistAlready ||
    doesRunbookNameHaveProhibitedPrefix ||
    doesRunbookNameContainProhibitedCharacters ||
    doesRunbookNameExceedLength;

  if (isInvalidRunbookName) {
    if (doesRunbookWithSameNameExistAlready) {
      errorMessage = `Workflow with name ${runbookName} already exist`;
    } else if (doesRunbookNameHaveProhibitedPrefix) {
      const prohibitedPrefixList = prohibitedPrefixesForRunbookName.reduce(
        (prohibitedPrefixesString, prohibitedPrefix, index) =>
          `${prohibitedPrefixesString} ${prohibitedPrefix}${
            index !== prohibitedPrefixesForRunbookName.length - 1 ? "," : ""
          }`,
        "",
      );
      errorMessage = `Workflow name cannot start with ${prohibitedPrefixList}`;
    } else if (doesRunbookNameContainProhibitedCharacters) {
      errorMessage =
        "Workflow name can only have alphabets, numbers and spaces";
    } else if (doesRunbookNameExceedLength) {
      errorMessage = "Workflow name must be 3 to 128 character long";
    } else {
    }

    return { isValidName: false, errorMessage };
  }

  return { isValidName: true, errorMessage };
};

export const getAwsConfig = appConfig => {
  return {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: appConfig.region,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: appConfig.userPoolId,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: appConfig.userPoolWebClientId,
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
      // REQUIRED - Amazon Cognito Identity pool ID
      identityPoolId: appConfig.identityPoolId,
      // oauth
    },
  };
};

export const getApiServerUrl = () => {
  const host = window.location.hostname;
  const runbookApiVersion = store.getState().appReducer.appConfig
    .runbookApiVersion;
  return process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_isCIBuild === "true"
    ? `${config.apiServer}${runbookApiVersion}`
    : `https://api.${host}/api${runbookApiVersion}`;
};

export const appendDecimalZeroToInteger = number => {
  let integer = typeof number === "string" ? +number : number;
  return integer % 1 === 0 ? `${integer}.0` : `${integer}`;
};

export const urlHasHash = () => {
  let url = window.location.href;
  let reg = new RegExp("#", "gi");
  return reg.test(url);
};

export const urlHasChanged = compareUrlWith => {
  if (urlHasHash()) {
    return false;
  } else if (window.location.href === compareUrlWith) {
    return false;
  } else {
    return true;
  }
};

export const generateRandomPassword = () =>
  new Array(20)
    .fill(
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$",
    )
    .map(
      x =>
        x[
          Math.floor(
            (crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) *
              x.length,
          )
        ],
    )
    .join("");

export function toCamelCaseKeys(o) {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = toCamelCaseKeys(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = camelCase(origKey);
        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamelCaseKeys(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
}

function _getRelevantMainStepNames(runbookObj) {
  const whitelistedSnippets = [
    "Delete_S3_buckets",
    "Create_S3_Bucket",
    "EKS",
    "EKS_Get_Pods_Status",
    "EKS_Delete_Pods",
    "EKS_Get_Deployments_Name_Only",
    "EKS_Check_Pods_CPU_Memory_Usage",
    "EKS_Get_Namespaces",
    "EKS_Get_Nodes",
    "EKS_Fetch_Dead_Pods",
    "EKS_Get_Not_Running_Pods",
    "EKS_Get_Running_Pods",
    "EKS_Check_Node_Usage",
    "EKS_Check_Nodes_CPU_Memory_Usage",
    "EKS_Get_Pods",
    "EKS_Copy_Pod_Logs",
    "CloudFormation_Filter_Resources",
  ];
  let mainSteps = [];
  if (runbookObj.hasOwnProperty("ssmDoc")) {
    /**
     * Check if the step is an ActionNodeStep
     */
    mainSteps = runbookObj.mainSteps
      ? runbookObj.mainSteps
          .filter(step => step.stepType !== "ActionNodeStep")
          .filter(step => !whitelistedSnippets.includes(step?.snippetDef?.name))
      : [];
  } else {
    /**
     * Check if the step is an ActionNodeStep when the stepType info
     * is unavailable
     */
    mainSteps = runbookObj.mainSteps
      ? runbookObj.mainSteps
          .filter(
            step =>
              SSMActionNames.INVOKE_LAMBDA === step.action &&
              !StepTypeChecker.isActionNodeStep(step),
          )
          .filter(step => {
            let stepName = step?.name
              .split("_")
              .filter((step, i, arr) => i !== arr.length - 1)
              .join("_");

            return !whitelistedSnippets.includes(stepName);
          })
      : [];
  }

  // Get the step name from runbookObj.
  const stepNames = [];
  for (const step of mainSteps) {
    stepNames.push(step.name.toLowerCase());
  }

  return stepNames;
}

/**
 * find all uncofigured nodes in current workflow.
 * @param {object} connectors - Object of connectors from backend.
 * @param {object} runbookObj - current runbook object.
 * @returns {Array<string>} - all unconfigured nodes.
 */
export function findUnconfiguredNodes(connectors, runbookObj) {
  if (isEmpty(connectors) || isEmpty(runbookObj)) {
    return [];
  }
  let stepNames = _getRelevantMainStepNames(runbookObj);

  const nodes = [];
  for (const connector in connectors) {
    for (const step of stepNames) {
      let normalisedStep = kebabToCamel(step);
      let normalisedConnector = kebabToCamel(connector);
      /**
       * TODO: Change this logic with the help of snippetDef 2.0
       * such that these checks specific to a connector
       * are not required
       */
      if (
        (normalisedConnector === "splunk" &&
          normalisedStep.includes("splunkOnCall")) ||
        (normalisedConnector === "terraform" &&
          normalisedStep.includes("terraformCli"))
      ) {
        continue;
      }
      /******/
      if (
        (normalisedStep.includes(normalisedConnector) &&
          !connectors[connector].is_configured) ||
        (normalisedConnector === "slack" &&
          !connectors[connector].is_configured &&
          normalisedStep.includes("approval")) ||
        (normalisedConnector === "newRelic" &&
          !connectors[connector].is_configured &&
          normalisedStep.includes("nrqlQuery"))
      ) {
        nodes.push({ step: step, connector: connector });
      }
    }
  }
  return [...nodes];
}

/**
 * Will return true if current node is configured.
 * @param {string} currentNode - Name of the current node.
 * @param {object} unconfiguredNodes - unconfiguredNode list
 * @return {boolean} if current node is configured.
 */
export function isCurrentNodeConfigured(currentNode, unconfiguredNodes) {
  // Basic sanity checking.
  if (unconfiguredNodes.length === 0 || !currentNode) return true;

  let isConfigured = true;
  for (const node of unconfiguredNodes) {
    if (currentNode === node.step) {
      isConfigured = false;
      break;
    }
  }
  return isConfigured;
}

/**
 * Return a unique list of connectors which are unconfigured
 * for a WF
 *

 * @param {object} unconfiguredNodes - unconfiguredNode list for a WF
 * @return {array} unique list of unconfigured connectors
 */
export function getUnconfiguredNodeList(unconfiguredNodes) {
  let connectorSet = new Set();
  unconfiguredNodes.forEach(node => connectorSet.add(node.connector));
  return Array.from(connectorSet);
}

/**
 * Function to add a property on which search will work
 * @param {list} list
 * @param {value} value to add
 */

export const addBucketName = (list = [], value = "") =>
  list.map(s => {
    let rValue = { ...s };
    rValue["bucketName"] = value;
    return rValue;
  });
/**
 * getFilteredSnippetList - Function to filter snippets on search value passed
 */
export const getFilteredSnippetList = (list = [], searchVal = "") => {
  return !!searchVal
    ? list.filter(
        snippet =>
          formatLabelName(snippet.name)
            .toLowerCase()
            .includes(searchVal.toLowerCase()) ||
          snippet?.bucketName
            ?.toLowerCase?.()
            .includes?.(searchVal?.toLowerCase?.()),
      )
    : list;
};

/**
 * Will return array of connectors where is_configured = false
 * @param {array} connectors - Connector List
 * @return {array} list of unconfigured connectors
 */
export const getBlacklistConnectorList = connectors => {
  return Object.keys(connectors)
    .filter(connector => connectors[connector]["is_configured"] === false)
    .map(connector => connector);
};

/**
 * Returns an array of snippets with isDisconnected=true attribute
 * @param {array} snippets - Snippet List
 * @param {array} blacklistConnectors - list of unconfigured connectors
 * @return {array} snippet List with isDisonnected=true attribute for unconfigured nodes
 */
export const addIsDisconnectedAttribute = (snippets, blacklistConnectors) => {
  Object.keys(snippets).forEach(snippet => {
    if (blacklistConnectors.includes(snippet.toLowerCase())) {
      snippets[snippet] = snippets[snippet].map(s => {
        let rSnippet = { ...s };
        rSnippet["isDisconnected"] = true;
        return rSnippet;
      });
    }
  });
  return snippets;
};

/**
 * Returns true if string includes single or double quote
 * @param {String} input - String value
 * @return {Boolean}
 */
export const checkQuotes = input => {
  return input && !(input.includes("'") || input.includes('"'));
};

export const sortResourceList = resourceList => {
  return resourceList.sort((a, b) => b.is_default - a.is_default);
};

export const sortObjectKeys = objectInput =>
  Object.keys(objectInput)
    .sort()
    // eslint-disable-next-line no-sequences
    .reduce((acc, key) => ((acc[key] = objectInput[key]), acc), {});

export const sanitizeDecimal = number => {
  let integer = parseInt(number);
  let decimal = number - integer;

  if (decimal === 0) {
    return parseFloat(number).toFixed(1);
  } else {
    return number;
  }
};

export const escapeNewLineChar = str => {
  return str.replace(/\n/g, "\\n");
};

/**
  This function will escape not already escaped double quotes
 */

export const escapeDoubleQuotes = str => {
  return str.replace(/\\([\s\S])|(")/g, "\\$1$2");
};

export const escapeRegExp = (string = "") =>
  string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export const isFylamyntWorkflow = runbook =>
  runbook?.Tags?.toString().includes("release:");

export const isValidNumber = (number, type) => {
  if (number) {
    if (type === "Integer") {
      return !!number.toString().match(/^-?\d+$/);
    }
    if (type === "Decimal") {
      return !!number.toString().match(/^\d+\.\d+$/);
    }
  }
  return false;
};

/**
 * Check if the step is a Trigger Step and return
 * true if any of the step satisfies condition
 */

export const getTrigger = runbookObj => {
  if (!runbookObj || isEmpty(runbookObj)) {
    return null;
  }

  let { mainStepIndex } = runbookObj;

  for (let step in mainStepIndex) {
    let snippetDef = mainStepIndex[step]?.snippetDef || {};

    if (!isEmpty(snippetDef) && snippetDef?.service?.includes("Trigger")) {
      if (snippetDef.name === "Webhook") return "webhook";
      return mainStepIndex[step]?.trigger?.name || null;
    }
  }
  return null;
};

export const filterSystemTags = tags => {
  if (!tags || !Array.isArray(tags)) return [];

  /**
   * SYSTEM_TAGS includes the tags which are hidden on the UI
   * Add tag to this array in order to hide that tag on UI.
   */
  const SYSTEM_TAGS = [
    "connector:",
    "trigger:",
    "snippet:",
    "category:",
    "release:",
  ];

  let filtered = [...tags];
  SYSTEM_TAGS.forEach(
    item => (filtered = filtered.filter(tag => !tag.startsWith(item))),
  );
  return filtered;
};

export const filterConnectorTriggerTags = tags => {
  if (!tags || !Array.isArray(tags)) return [];
  let output = {
    connectors: [],
    trigger: "",
  };
  tags.forEach(tag => {
    if (
      tag.startsWith("trigger:") &&
      tag !== "trigger:false" &&
      tag !== "trigger:true"
    ) {
      output.trigger = tag.replace("trigger:", "");
    } else if (tag.startsWith("connector:")) {
      output.connectors.push(tag.replace("connector:", ""));
    }
  });
  output.connectors = output.connectors.filter(
    (connector, index) =>
      connector !== output.trigger &&
      output.connectors.indexOf(connector) === index,
  );
  return output;
};

export const toObject = (arr = [], key) =>
  [...arr].reduce((a, b) => ({ ...a, [b[key]]: { ...b } }), {});

export const getSnippetList = (mainSteps = []) => {
  return mainSteps
    .filter(step => step?.snippetDef?.type?.toLowerCase() !== "control")
    .map(step => step?.snippetDef?.name || step?.actionNodeDef?.name);
};

export const getConnectorList = (mainSteps = [], connectors) => {
  if (isEmpty(connectors)) return [];

  let nodes = [];
  for (const connector in connectors) {
    mainSteps.forEach(step => {
      if (step?.snippetDef?.connectors?.includes(connector.toLowerCase())) {
        nodes.push(connector);
      }
    });
  }

  return nodes;
};

export const getRunbookTags = (connectorList, snippetList) => {
  return [
    [...new Set(connectorList.map(item => `connector:${item}`))],
    [...new Set(snippetList.map(item => `snippet:${item}`))],
  ];
};

export const getIconName = (displayName, iconName) => {
  return iconName || displayName.split(" ")[0].toLowerCase();
};

export const getHumanReadableStepNameWithStepId = stepName => {
  let replacement = " #";
  let tempResult = stepName.replace(/_([^_]*)$/, replacement + "$1");
  return tempResult.replaceAll("_", " ");
};

export const getHumanReadableStepName = stepName => {
  return stepName.substring(0, stepName.lastIndexOf("_")).replaceAll("_", " ");
};

export const getBackgroundImgSource = (displayName, iconName) => {
  let bgIconName = "";
  let bgImageSource = "";
  try {
    bgIconName = getIconName(displayName, iconName);
    bgImageSource = require(`@assets/images/snippets/icon-${bgIconName}.svg`);
  } catch (e) {
    bgImageSource = require(`@assets/images/snippets/icon-loop.svg`);
  }
  return bgImageSource;
};

export const filterTriggerTags = trigger => {
  if (!trigger) return [];

  // Ignore following items as connector in request
  const ignoreConnectors = ["manual", "webhook"];

  if (ignoreConnectors.includes(trigger)) {
    return [`trigger:${trigger}`];
  }

  return [`trigger:${trigger}`, `connector:${trigger}`];
};

export const replaceAllSpecialCharWithSpace = str => {
  return str ? str.replace(/[^a-zA-Z0-9 ]/g, " ") : "";
};
/**
 * url: Url with placeholders.
 * items: It is an array of object, where each object will contain value of placeholder.
 */
export const getRedirectUrl = (url, items) => {
  let updatedUrl = url;
  items.forEach(item => {
    updatedUrl = updatedUrl.replace(`:${item.key}`, item.value);
  });
  return updatedUrl;
};

export const replaceAllSpacesWithHyphen = str => {
  return str.replaceAll(" ", "-");
};

export const getPriorityDisplayText = str => {
  let output = "";
  switch (str) {
    case "P0":
      output = "P0 - Highest";
      break;
    case "P1":
      output = "P1 - High";
      break;
    case "P2":
      output = "P2 - Medium";
      break;
    case "P3":
      output = "P3 - Low";
      break;
    case "P4":
      output = "P4 - Very Low";
      break;
    default:
      output = "P1 - High";
  }
  return output;
};

export const getPriorityIcon = priority => {
  let imgSource = "";
  try {
    imgSource = require(`@assets/images/snippets/icon-${priority?.toLowerCase()}.svg`);
  } catch (e) {
    imgSource = require(`@assets/images/snippets/icon-p1.svg`);
  }
  return imgSource;
};
