import React, { useState, useEffect } from "react";
import { TextInput, TextArea, LabelTop } from "@components/ui";

export default function RunbookParameter({
  runbookObj,
  parameter,
  active,
  notifyRunbookUpdate,
}) {
  const [name, setName] = useState(parameter.name);
  const [description, setDescription] = useState(parameter.spec.description);
  const [defaultValue, setDefaultValue] = useState(parameter.spec.default);

  const onKeyUp = value => {
    setDescription(value);
  };

  useEffect(() => {
    if (name && name !== parameter.name) {
      //update it in the runbook
      runbookObj.renameParameter(parameter.name, name);
      notifyRunbookUpdate(true);
      console.log(`set name`, { parameter, runbookObj });
    }
  }, [name, notifyRunbookUpdate, parameter, runbookObj]);

  useEffect(() => {
    if (description !== parameter.spec.description) {
      //update it in the runbook
      parameter.spec.setDescription(description);
      runbookObj.setParameter(parameter);
      notifyRunbookUpdate(true);
      console.log(`set description`, { parameter, runbookObj });
    }
  }, [description, notifyRunbookUpdate, parameter, runbookObj]);

  useEffect(() => {
    if (defaultValue !== parameter.spec.default) {
      //update it in the runbook
      parameter.spec.setDefault(defaultValue);
      runbookObj.setParameter(parameter);
      notifyRunbookUpdate(true);
      console.log(`set default`, { parameter, runbookObj });
    }
  }, [defaultValue, notifyRunbookUpdate, parameter, runbookObj]);

  const onNameChange = name => {
    // console.log(`onNameChange`, { name });
  };

  return (
    <div
      style={{
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderTopColor: "#2c2c2c",
        marginBottom: "18px",
        padding: "16px",
        paddingTop: "18px",
      }}
    >
      <div>
        <TextInput
          name={parameter.name}
          id={parameter.name}
          label="Name"
          labelClassName="label"
          labelPosition="top"
          className="rule-input"
          value={name}
          onSave={setName}
          onChange={onNameChange}
          style={{ marginBottom: "16px" }}
        />
      </div>
      <div>
        <LabelTop noMargin labelClassName="label" label="Description">
          <TextArea
            value={description}
            onKeyUp={onKeyUp}
            className="compact-text-area"
            style={{ marginBottom: "16px" }}
          ></TextArea>
        </LabelTop>
      </div>

      <div>
        <TextInput
          name={`${parameter.name}.default`}
          id={parameter.name}
          label="Default value"
          labelClassName="label"
          labelPosition="top"
          className="rule-input"
          value={defaultValue}
          onChange={setDefaultValue}
        />
      </div>
    </div>
  );
}
