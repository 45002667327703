import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { Route } from "react-router";
import RequireAuthentication from "@components/authentication/requireAuthentication";
import * as utils from "@lib/utils";
import "@scss/app.scss";
import UserProfile from "@components/authentication/UserProfile/UserProfile";
import ForgotPassword from "@components/authentication/ForgotPassword/ForgotPassword";
import Login from "@components/authentication/Login/Login";
import Signup from "@components/authentication/Signup/Signup";
import AmplifyConfig from "@components/AmplifyConfig/index";
import Runbooks from "@containers/Runbooks/runbooks-lib/runbooks.connect";
import RunbookDetails from "@containers/RunbookDetails/runbook-details-lib/runbook-details.connect";
import RunbookEditor from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.connect";
import Executions from "@containers/Executions/executions-lib/executions.connect";
import ExecutionsRun from "@containers/ExecutionsRun/executions-run-lib/executions-run.connect";
import ExecutionDetails from "@containers/ExecutionDetails/execution-details-lib/execution-details.connect";
import ExecutionRunDetails from "@containers/ExecutionRunDetails/execution-run-details-lib/exeuction-run-details.connect";
import SettingsPanel from "@containers/SettingsPanel/SettingsPanel";
import Schedules from "@containers/Schedules/schedules-lib/schedules.connect";
import AWSSettings from "@containers/SettingsPanel/AWSSettings";
import Snippets from "@containers/Snippets/snippets-lib/snippets.connect";
import SnippetDetails from "@containers/SnippetDetails/snippet-details-lib/snippet-details.connect";
import SettingsAccountInfo from "@containers/SettingsPanel/SettingsAccountInfo";
import AWSTargetSettings from "@containers/GetStarted/Profile";
import AppInfo from "@containers/AppInfo/AppInfo";
import JiraSetup from "@containers/SettingsPanel/ConnectorSetup/JIRA/JiraSetup";
import SlackSetup from "@containers/SettingsPanel/ConnectorSetup/Slack/SlackSetup";
import APIKeys from "@containers/SettingsPanel/APIKeys";
import GitRepos from "@containers/SettingsPanel/GIT/GITRepos";
import TwilioSetup from "@containers/SettingsPanel/ConnectorSetup/Twilio/TwilioSetup";
import S3Buckets from "@containers/SettingsPanel/S3Buckets/S3Buckets";
import SSHTargets from "@containers/SettingsPanel/SSH";
import SmallScreenError from "@components/SmallScreenError/SmallScreenError";
import { MIN_SUPPORTED_WIDTH } from "@lib/utils/constants";
import { RouteConstants } from "./Constants";
import CloudFormation from "@containers/SettingsPanel/ConnectorSetup/CloudFormation/CloudFormation";
import CloudwatchTargets from "@containers/SettingsPanel/Cloudwatch";
import CloudwatchSetup from "@containers/SettingsPanel/Cloudwatch/setup";
import withSpashScreen from "../withSplashScreen";
import TerraformCLI from "@containers/SettingsPanel/ConnectorSetup/TerraformCLI/TerraformCLI";
import EKSActionRoles from "@containers/SettingsPanel/EKSActionRoles/EKSActionRoles";
import EKSActionRoleSetup from "@containers/SettingsPanel/EKSActionRoles/EKSActionRoleSetup";
import TaskManagement from "@containers/TaskManagement/TaskManagement";
import TaskDetails from "@containers/TaskDetails/TaskDetails";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import GetStarted from "@components/shared/GetStarted/GetStartedForm";
import ZoomSetup from "@containers/SettingsPanel/ConnectorSetup/Zoom/ZoomSetup";

utils.removeHashFromAddressBar();

class RoutedApp extends React.Component {
  render() {
    return (
      <Router>
        <GlobalLoader>
          <AmplifyConfig>
            <Switch>
              <Route
                exact
                path={RouteConstants.forgotPassword.url}
                component={ForgotPassword}
              />
              <Route exact path="/" component={Login} />
              <Route exact path={RouteConstants.login.url} component={Login} />
              <Route
                exact
                path={RouteConstants.signUp.url}
                component={Signup}
              />
              {window.innerWidth > MIN_SUPPORTED_WIDTH ? (
                <RequireAuthentication>
                  <Switch>
                    <Route
                      exact
                      path={`${RouteConstants.runbookEditor.url}/:runbookId`}
                      component={RunbookEditor}
                    />
                    <Route
                      path={`${RouteConstants.runbookDetails.url}`}
                      component={RunbookDetails}
                    />
                    <Redirect
                      from={RouteConstants.runbookDetails.baseUrl}
                      to={`${RouteConstants.runbookDetails.baseUrl}/parameters`}
                    />
                    <Route
                      exact
                      path={RouteConstants.runbooks.url}
                      component={Runbooks}
                    />
                    <Route
                      exact
                      path={RouteConstants.schedules.url}
                      component={Schedules}
                    />
                    <Route
                      exact
                      path={RouteConstants.aws.url}
                      component={AWSTargetSettings}
                    />
                    <Route
                      exact
                      path={RouteConstants.awsSettings.url}
                      component={AWSSettings}
                    />
                    <Route
                      exact
                      path={RouteConstants.git.url}
                      component={GitRepos}
                    />
                    <Route
                      exact
                      path={RouteConstants.ssh.url}
                      component={SSHTargets}
                    />
                    <Route
                      exact
                      path={RouteConstants.jira.url}
                      component={JiraSetup}
                    />
                    <Route
                      exact
                      path={RouteConstants.slack.url}
                      component={SlackSetup}
                    />
                    <Route
                      exact
                      path={RouteConstants.zoom.url}
                      component={ZoomSetup}
                    />
                    <Route
                      exact
                      path={RouteConstants.terraform_cli.url}
                      component={TerraformCLI}
                    />
                    <Route
                      exact
                      path={RouteConstants.twilio.url}
                      component={TwilioSetup}
                    />
                    <Route
                      exact
                      path={RouteConstants.s3.url}
                      component={S3Buckets}
                    />
                    <Route
                      exact
                      path={RouteConstants.cloudformation.url}
                      component={CloudFormation}
                    />
                    <Route
                      exact
                      path={RouteConstants.apikeys.url}
                      component={APIKeys}
                    />
                    <Route
                      exact
                      path={`${RouteConstants.awsSettings.url}/:alias`}
                      component={SettingsAccountInfo}
                    />
                    <Route
                      exact
                      path={RouteConstants.actions.url}
                      component={Snippets}
                    />
                    <Route
                      exact
                      path={RouteConstants.actionDetails.url}
                      component={SnippetDetails}
                    />
                    <Redirect
                      from={RouteConstants.actionDetails.baseUrl}
                      to={`${RouteConstants.actionDetails.baseUrl}/inputs`}
                    />
                    <Route
                      path={RouteConstants.executionsRun.url}
                      component={ExecutionsRun}
                    />
                    <Route
                      exact
                      path={RouteConstants.executionDetails.url}
                      component={ExecutionDetails}
                    />
                    <Route
                      exact
                      path={`${RouteConstants.executionDetails.url}/:executionId/:subTab`}
                      component={ExecutionDetails}
                    />
                    <Redirect
                      from={`${RouteConstants.executionDetails.url}/:executionId`}
                      to={`${RouteConstants.executionDetails.url}/:executionId/steps`}
                    />
                    <Route
                      exact
                      path={RouteConstants.executionRunDetails.url}
                      component={ExecutionRunDetails}
                    />
                    <Redirect
                      from={RouteConstants.executionRunDetails.baseUrl}
                      to={`${RouteConstants.executionRunDetails.baseUrl}/inputs`}
                    />
                    <Route
                      exact
                      path={RouteConstants.executions.url}
                      component={Executions}
                    />
                    <Route
                      exact
                      path={`${RouteConstants.userProfile.url}`}
                      component={UserProfile}
                    />
                    <Redirect
                      from={RouteConstants.userProfile.baseUrl}
                      to={`${RouteConstants.userProfile.baseUrl}/information`}
                    />
                    <Route
                      exact
                      path={RouteConstants.appInfo.url}
                      component={AppInfo}
                    />
                    <Route
                      exact
                      path={RouteConstants.cloudwatch.url}
                      component={CloudwatchTargets}
                    />
                    <Route
                      exact
                      path={RouteConstants.cloudwatchTarget.url}
                      component={CloudwatchSetup}
                    />
                    <Route
                      exact
                      path={RouteConstants.eks.url}
                      component={EKSActionRoles}
                    />
                    <Route
                      exact
                      path={RouteConstants.setupEks.url}
                      component={EKSActionRoleSetup}
                    />
                    <Route
                      exact
                      path={RouteConstants.taskManagement.url}
                      component={TaskManagement}
                    />
                    <Route
                      exact
                      path={RouteConstants.taskDetails.url}
                      component={TaskDetails}
                    />
                    <Redirect
                      from={`${RouteConstants.taskDetails.baseUrl}/:taskId`}
                      to={`${RouteConstants.taskDetails.baseUrl}/:taskId/workflows`}
                    />
                    <Route
                      path={RouteConstants.integration.url}
                      component={GetStarted}
                      exact
                    />
                    <Route
                      exact
                      path={RouteConstants.settings.url}
                      component={SettingsPanel}
                    />
                    <Redirect
                      from={RouteConstants.settings.baseUrl}
                      to={`${RouteConstants.settings.baseUrl}/integrations`}
                    />
                    <Route path="*">
                      <Redirect to={RouteConstants.runbooks.defaultUrl} push />
                    </Route>
                  </Switch>
                </RequireAuthentication>
              ) : (
                <SmallScreenError />
              )}
            </Switch>
          </AmplifyConfig>
        </GlobalLoader>
      </Router>
    );
  }
}

export default withSpashScreen(RoutedApp);
