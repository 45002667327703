import React from "react";
import "./AddButton.scss";

type AddButtonProps = {
  title: string;
  handleClick: (value) => void;
};
const AddButton: React.FC<AddButtonProps> = ({ title, handleClick }) => {
  return (
    <div className="add-button" onClick={handleClick}>
      {`+ ${title}`}
    </div>
  );
};

export default AddButton;
