import React from "react";
import { isEdge, removeElements, useStore } from "react-flow-renderer";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import { store } from "@redux/store";
import cloneDeep from "lodash/cloneDeep";

// Components
import DefaultNode from "./utils/NodeTypes/DefaultNode";
import InPortNode from "./utils/NodeTypes/InPortNode";
import OutPortNode from "./utils/NodeTypes/OutPortNode";
import DefaultEdge from "./utils/EdgeTypes/DefaultEdge";

// Helper functions
import { stepFromControlNodeData } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/builder";
import { getPortType, setSnippetName } from "./utils/helpers";
import {
  StepTypes,
  ControlNames,
} from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/strings";
import { SnippetLibrary } from "@containers/RunbookEditor/runbook-editor-lib/snippetlibrary";
import { RunbookReader } from "@containers/RunbookEditor/runbook-editor-lib/runbook/runbookreader";
import { StepTypeChecker } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/steptypechecker";
import { ParameterType } from "@containers/RunbookEditor/runbook-editor-lib/ssm/strings";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import { FTNotification } from "@components/ui";
import {
  findUnconfiguredNodes,
  isCurrentNodeConfigured,
  isEmpty,
  snakeToPascal,
  capitalizeFirstLetter,
  getBackgroundImgSource,
} from "@lib/utils";
import {
  fetchTriggers,
  setSelectedTriggerForWF,
} from "@redux/actions/common.actions";
import { createNewRunbook } from "@redux/actions/runbook.action";
import { REMOVE_CACHED_SNIPPETS } from "@redux/types";

import EditorDiagram from "./EditorDiagram";
import "./EditorDesignArea.scss";

/**
 * Documentation:
 *
 * The component's state consists of elements array which contains two kind of objects:
 *  1. Node Object
 *     Structure {
 *                id: uniqueId
 *                inPort: [uniqueId of connected parentNode] (Mostly 1 element in this Array)
 *                outPort: [uniqueId of connected childNode] (Can contain more than 1 element Ex. Conditional node)
 *                data: {
 *                        runbook related data, snippet name, callback methods, etc
 *                      }
 *                type: "defaultNode" | "inPortNode" | "outPoTRANSITION_TIMErtNode" <- custom components
 *                position: {
 *                            x: number, y:number
 *                          }
 *              }
 *
 *  2. Edge Object
 *     Structure {
 *                  id: uniqueId
 *                  source: uniqueId of parentNode
 *                  target: uniqueId of childNode
 *                  data: {
 *                           callback method (onEdgeRemoveMutation)
 *                        }
 *               }
 *
 *  Overview => elements [ { node 1 }, { node 2}, { edge 1 }, etc  ]
 *
 *  Whenever any CRUD operation is done, the elements array is mutated.
 *
 *  The method name which end with "Mutation" are callback methods which are passed in data property of node/edges.
 *  This methods are invoked from the custom node or custom edges.
 *
 */

type EditorDesignAreaState = {
  elements: any;
  selectedNode: any;
  reactFlowInstance: any;
  startNodeId: any;
  isInPortEnabled: boolean;
  isRenderingDiagram: boolean;
  showTriggerSelectionModal: boolean;
  editorData: any;
  parentData: any;
  localTrigger: any;
};

type EditorDesignAreaProps = {
  preparing: any;
  reloadEditor: any;
  snippets: any;
  connectors: any;
  runbook: any;
  runbookObj: any;
  setActiveNode: (input?: any) => void;
  updateRunbookObj: (input) => void;
  notifyRunbookUpdate: (input) => void;
  fetchAwsOperations: (input) => void;
  fetchAwsOperationDetails: (service, operation) => void;
  awsOperationDetails: () => void;
  rfNodes: any;
  selectedTrigger: any;
  triggerList: any;
  setSelectedTriggerForWF: (input: any) => void;
  fetchTriggers: () => void;
  editorRef: any;
  isNodeDeleted: any;
  handleNodeDelete: (value: any) => void;
};

const ReactFlowStore = Component => {
  const ComponentWrapped = props => {
    const store = useStore();

    return <Component rfNodes={store} {...props} />;
  };
  return ComponentWrapped;
};

class EditorDesignArea extends React.Component<
  EditorDesignAreaProps,
  EditorDesignAreaState
> {
  // ------------ Component configuration start ------------
  private reactFlowWrapper: React.RefObject<HTMLDivElement>;

  private nodeTypes: any;
  private edgeTypes: any;
  private deleteKeyCode: number;

  constructor(props) {
    super(props);
    this.state = {
      elements: [],
      selectedNode: null,
      reactFlowInstance: null,
      startNodeId: null,
      isInPortEnabled: false,
      isRenderingDiagram: false,
      showTriggerSelectionModal: false,
      editorData: null,
      parentData: null,
      localTrigger: this.props.selectedTrigger,
    };
    this.reactFlowWrapper = React.createRef();

    // Custom Node
    this.nodeTypes = {
      defaultNode: DefaultNode,
      inPortNode: InPortNode,
      outPortNode: OutPortNode,
    };
    // Custom Edge
    this.edgeTypes = {
      defaultEdge: DefaultEdge,
    };

    // Delete key codes for mac OS
    this.deleteKeyCode = navigator.userAgent?.includes("Macintosh") ? 8 : 46;
  }

  componentDidMount() {
    if (!this.props.triggerList?.length) {
      this.props.fetchTriggers();
    }

    // Setup the Parent's reference variable (i.e editorReference) for callbacks
    // Note: This callbacks are invoked from Conditional node panel & Action node panel
    this.props.editorRef.current = {
      rerenderEditor: this.rerenderEditor,
      addLinkToStep: this.addLinkToStep,
      removeLinkToStep: this.removeLinkToStep,
      handlePrevStepOptionHover: this.handlePrevStepOptionHover,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.preparing) {
      return;
    } else {
      if (
        (this.props.preparing !== prevProps.preparing &&
          !this.props.preparing) ||
        (this.props.reloadEditor !== prevProps.reloadEditor &&
          this.props.reloadEditor) ||
        this.props.connectors !== prevProps.connectors
      ) {
        this.setState({ elements: [] });
        this.readAndRenderRunbook();
      }
      if (this.awsDataNowReady(prevProps)) {
        // TODO: fix the action node input types
        this.fixActionNodeInputTypes(this.props.runbookObj?.mainSteps);
      }
      this.checkIfRunbookVersionChanged(prevProps);
    }
  }

  componentWillUnmount() {
    // Clean up
    const { dispatch } = store;
    dispatch({ type: REMOVE_CACHED_SNIPPETS });
  }

  handleSetState = setData => {
    this.setState(setData);
  };
  // ------------ Component configuration ends ------------

  // ------------ Runbook helper functions start ------------

  getIconClass = nodeObject => {
    setSnippetName(nodeObject);
    const iconClass = [];
    iconClass.push("rf-editor-node-icon");

    // Add the extra opacity for unconfigured nodes.
    if (
      nodeObject.extras.runbookNode.hasOwnProperty("is_configured") &&
      !nodeObject.extras.runbookNode.is_configured
    ) {
      iconClass.push("opaque");
    }
    return iconClass.join(" ");
  };

  getParentNodes = (nodeList, node) => {
    const childId = node.id;
    let parentArray = [];
    Object.keys(nodeList).forEach(item => {
      const currentNode = nodeList[item].node;
      if (
        Array.isArray(currentNode?.outPort) &&
        currentNode.outPort.includes(childId)
      ) {
        parentArray.push(item);
      }
    });

    return parentArray;
  };

  deSelectNode = () => {
    this.props.setActiveNode();
    this.setState({ selectedNode: null });
  };

  onEdgeRemoveMutation = (id, sourceId, targetId) => {
    if (!id || !sourceId || !targetId) return;

    const { elements } = this.state;

    const sourceIdx = elements.find(element => element?.id === sourceId)?.data
      ?.uniqueId;
    const targetIdx = elements.find(element => element?.id === targetId)?.data
      ?.uniqueId;

    const source = this.props.runbookObj.mainStepIndex[sourceIdx];
    const target = this.props.runbookObj.mainStepIndex[targetIdx];

    // Update runbook object
    if (!isEmpty(source) && !isEmpty(target)) {
      this.props.runbookObj.removeLink(source, target);

      const elementsToRemove = [{ id, source: sourceId, target: targetId }];

      // Remove edge
      let _elements = removeElements(elementsToRemove, elements);

      // Find parent & child node and update the outPort/inPort
      const parentElement: any = _elements.find(item => item.id === sourceId);
      const childElement: any = _elements.find(item => item.id === targetId);

      if (parentElement?.outPort) {
        const _outPort = parentElement?.outPort?.filter(
          item => item !== targetId,
        );
        parentElement.outPort = _outPort;
      }
      if (childElement?.inPort) {
        const _inPort = childElement?.inPort?.filter(item => item !== sourceId);
        childElement.inPort = _inPort;
      }
      // Update FromPreviousStep references
      target?.parameterInputs?.forEach(param => {
        if (param.source.type === "actionNode") {
          param.source = new RunbookStepInputSource("actionNode", null);
          this.deSelectNode();
        }
      });
      this.props.notifyRunbookUpdate(true);
      this.setState({ elements: _elements });
    }
  };

  toggleTriggerSelectionModal = (data = null, editorData = null) => {
    if (!this.state.showTriggerSelectionModal) {
      this.setState({
        localTrigger: this.props.selectedTrigger,
      });
    }
    this.setState({
      showTriggerSelectionModal: !this.state.showTriggerSelectionModal,
      editorData: editorData,
      parentData: data,
    });
  };

  cloneElementMutation = (data, editorData) => {
    if (isEmpty(data) || isEmpty(editorData)) return;

    const sourceRunbookNode = this.props.runbookObj.mainStepIndex[
      data?.uniqueId
    ];

    if (isEmpty(sourceRunbookNode)) return;

    let stepData: any = {};
    let isAWSNode = ["AWS", "Action_Node"].includes(
      sourceRunbookNode?.actionNodeDef?.name,
    );

    let insertionOrder =
      Math.max(
        ...this.state.elements.map(element => {
          if (element.type !== "defaultEdge") {
            const tempStepId = element.data.uniqueId.match(/\d+$/);
            return tempStepId
              ? parseInt(element.data.uniqueId.match(/\d+$/)[0], 10)
              : 0;
          } else {
            return -1;
          }
        }),
        0,
      ) + 1;

    if (isAWSNode) {
      stepData = {
        name: sourceRunbookNode?.actionNodeDef?.name,
        extras: cloneDeep(sourceRunbookNode?.actionNodeDef),
      };
    } else {
      const step = this.props.snippets.find(
        step => step.name === sourceRunbookNode?.snippetDef?.name,
      );

      if (isEmpty(step)) {
        return;
      }

      stepData = {
        name: step?.name,
        extras: step,
      };
    }

    const id = uuidv4();
    const type = editorData.type;
    const position = { x: editorData.xPos + 250, y: editorData.yPos };
    let clonedData = cloneDeep(data);
    stepData.extras.insertionOrder = insertionOrder;

    const clonedRunbookNode: any = stepFromControlNodeData(stepData);

    // Modify runbookNode data & append it to data property

    clonedRunbookNode.editorNodeId = id;
    clonedData.nodeData.extras.editorNodeId = id;
    clonedData.nodeData.extras.runbookNode = clonedRunbookNode;
    clonedData.nodeData.extras.insertionOrder = insertionOrder;
    clonedData.insertionOrder = insertionOrder;
    clonedData.uniqueId = clonedRunbookNode.ssm.name;

    if (isAWSNode) {
      clonedRunbookNode.service = sourceRunbookNode.service;
      clonedRunbookNode.operation = sourceRunbookNode.operation;
    }

    let label = isAWSNode
      ? `${capitalizeFirstLetter(clonedRunbookNode.service) || ""} ${
          snakeToPascal(clonedRunbookNode.operation) || ""
        }`
      : clonedRunbookNode?.snippetDef?.display_name || "";

    clonedData.label = (
      <>
        <div>{label || ""}</div>
        <div className="step-id">#{insertionOrder || ""}</div>
      </>
    );

    // Copy common data
    if (sourceRunbookNode?.inputs) {
      clonedRunbookNode.inputs = cloneDeep(sourceRunbookNode.inputs);
    }
    if (sourceRunbookNode?.parameterInputs) {
      if (!(sourceRunbookNode.stepType === "WaitForResourceStep")) {
        clonedRunbookNode.parameterInputs = cloneDeep(
          sourceRunbookNode.parameterInputs,
        );
        clonedRunbookNode.parameterInputs.forEach(parameterInput => {
          parameterInput.snippetAction.name = clonedRunbookNode.name;
        });
      }
    }
    if (sourceRunbookNode?.is_configured) {
      clonedRunbookNode.is_configured = sourceRunbookNode.is_configured;
    }
    // Conditional Node
    if (sourceRunbookNode?.defaultNextStep) {
      clonedRunbookNode.defaultNextStep = sourceRunbookNode.defaultNextStep;
    }
    // Wait Node
    if (sourceRunbookNode?.iso8601Duration) {
      clonedRunbookNode.iso8601Duration = cloneDeep(
        sourceRunbookNode.iso8601Duration,
      );
    }
    // Approval Node
    if (sourceRunbookNode?.timeout || sourceRunbookNode?.timeoutUnit) {
      clonedRunbookNode.timeout = sourceRunbookNode.timeout;
      clonedRunbookNode.timeoutUnit = sourceRunbookNode.timeoutUnit;
    }
    // Loop for Each Node

    if (sourceRunbookNode?.itemsInput) {
      clonedRunbookNode.itemsInput = cloneDeep(sourceRunbookNode.itemsInput);
      clonedRunbookNode.items =
        clonedRunbookNode.itemsInput?.source?.sourceValue || [];
    }
    if (sourceRunbookNode?.operationDetails) {
      clonedRunbookNode.operationDetails = cloneDeep(
        sourceRunbookNode.operationDetails,
      );
    }
    if (sourceRunbookNode?.parameter) {
      clonedRunbookNode.parameter = cloneDeep(sourceRunbookNode.parameter);
    }
    // Wait For Resource
    if (sourceRunbookNode.stepType === StepTypes.WaitForResourceStep) {
      clonedRunbookNode.aws_call = cloneDeep(sourceRunbookNode.aws_call);
      clonedRunbookNode._awsCall = cloneDeep(sourceRunbookNode._awsCall);
      clonedRunbookNode._operationDetails = cloneDeep(
        sourceRunbookNode._operationDetails,
      );
      clonedRunbookNode.count = sourceRunbookNode.count;
      clonedRunbookNode.desired_state = sourceRunbookNode.desired_state;
      clonedRunbookNode.index = sourceRunbookNode.index;
      clonedRunbookNode.result_jsonpath = sourceRunbookNode.result_jsonpath;
      clonedRunbookNode.step = sourceRunbookNode.step;
      clonedRunbookNode.wait_seconds = sourceRunbookNode.wait_seconds;
    }

    const newNode = {
      id,
      position,
      type,
      data: {
        ...clonedData,
      },
    };

    // Update runbook object & notify update
    this.props.runbookObj.addStep(clonedRunbookNode);
    this.props.notifyRunbookUpdate(true);

    // Update state
    this.setState(state => ({ elements: state.elements.concat(newNode) }));

    // Update runbook object & update the runbook
    this.props.notifyRunbookUpdate(true);
  };

  deleteElementMutation = (data, editorData) => {
    const runbookNode = this.props.runbookObj.mainStepIndex[data?.uniqueId];

    if (!isEmpty(runbookNode)) {
      if (this.isDependableNode(runbookNode?.name)) {
        FTNotification.error({
          message: `This node is being used in the Slack node; reconfigure the Slack node first to remove the dependency`,
        });
        return;
      }

      this.props.runbookObj.removeStep(runbookNode);
      this.deSelectNode();
    }

    const elementToRemove = [{ ...data, ...editorData }];

    const _elements = removeElements(elementToRemove, this.state.elements);
    _elements.forEach((element: any) => {
      if (element?.inPort) {
        element.inPort = element.inPort.filter(item => item !== editorData.id);
      }
      if (element?.outPort) {
        element.outPort = element.outPort.filter(
          item => item !== editorData.id,
        );
      }
    });

    this.props.handleNodeDelete(!this.props.isNodeDeleted);
    // Update inPort & outPort of all nodes
    this.props.notifyRunbookUpdate(true);

    this.setState({ elements: _elements });
  };

  addEdgeLink = (nodeList, currentNodeName, nextNode) => {
    const parentNode = nodeList[currentNodeName].node;
    const childNode = nodeList[nextNode].node;

    const newEdge = {
      id: uuidv4(),
      source: parentNode.id,
      target: childNode.id,
      type: "defaultEdge",
      data: { onEdgeRemoveMutation: this.onEdgeRemoveMutation },
    };

    // Add port data
    if (
      nodeList[currentNodeName].node?.outPort &&
      Array.isArray(nodeList[currentNodeName].node.outPort)
    ) {
      nodeList[currentNodeName].node.outPort.push(newEdge.target);
    } else {
      nodeList[currentNodeName].node.outPort = [newEdge.target];
    }

    if (
      nodeList[nextNode].node?.inPort &&
      Array.isArray(nodeList[nextNode].node.inPort)
    ) {
      nodeList[nextNode].node.inPort.push(newEdge.source);
    } else {
      nodeList[nextNode].node.inPort = [newEdge.source];
    }

    // Update state
    this.setState(state => ({ elements: state.elements.concat(newEdge) }));
  };

  memoizedUpdateNodePosition = () => {
    let memoizedObj = {};

    const updateNodePosition = (
      nodeList,
      mostLeftPosition,
      currentNodeName,
      x,
      y,
    ) => {
      const currentNode = nodeList[currentNodeName].node;
      currentNode.position = { x: 0, y: y * 200 };
      y = y + 0.6;
      let nextNodes = nodeList[currentNodeName].action.nextSteps();
      /**
       * Consider a node iff it has not been traversed before
       */
      if (!memoizedObj.hasOwnProperty(currentNode.data.uniqueId)) {
        memoizedObj[currentNode.data.uniqueId] = 1;
        let offset = (nextNodes.length - 1) * 80;
        for (let nextNode of nextNodes) {
          if (!nodeList[nextNode]) {
            console.warn(`No node in nodeList for ${nextNode}`);
            continue;
          }
          nodeList[nextNode].node.position = { x, y: y * 100 };
          // Add links
          this.addEdgeLink(nodeList, currentNodeName, nextNode);
          mostLeftPosition =
            Math.min(x, x + offset) < mostLeftPosition
              ? Math.min(x + offset)
              : mostLeftPosition;

          updateNodePosition(
            nodeList,
            mostLeftPosition,
            nextNode,
            x + offset,
            y,
          );
          offset -= 160;
        }
      } else {
        memoizedObj[currentNode.data.uniqueId] += 1;
      }
    };
    return updateNodePosition;
  };

  setNodePosition = (nodeList, positionXY = {}) => {
    const adjustLeftPosition =
      this.reactFlowWrapper.current.getBoundingClientRect().right / 2.6;
    let previousNode = null;
    let previousNodeIsConditional = false;
    let updateX = adjustLeftPosition;
    let childOffset = 230;
    const testConditional = new RegExp(/Conditional/gi);

    Object.keys(nodeList).forEach(nodeItem => {
      const node = nodeList[nodeItem].node;
      const parentNodes = this.getParentNodes(nodeList, node);
      const firstParent = parentNodes[0];
      const secondParent = parentNodes.pop();

      if (firstParent) {
        let nodeObj = nodeList[firstParent].node;
        updateX = nodeObj.position.x;
      }
      if (adjustLeftPosition > 0) {
        let currentY = node.position.y;
        if (nodeList[firstParent]?.node?.position?.y) {
          currentY = nodeList[firstParent]?.node?.position?.y + 115;
        }
        if (
          nodeList[secondParent]?.node?.position?.y >=
          nodeList[firstParent]?.node?.position?.y
        ) {
          currentY = nodeList[secondParent]?.node?.position?.y + 115;
        }

        let positionKey = `${updateX}_${currentY}`;
        if (positionXY[positionKey]) {
          if (previousNode) {
            let x = updateX + childOffset;
            if (secondParent) {
              currentY = nodeList[secondParent].node.position.y + 115;
            }
            if (firstParent !== secondParent) {
              x = nodeList[firstParent].node.position.x;
            }
            node.position = { x, y: currentY };
            currentY += 115;
          }
        } else {
          if (previousNodeIsConditional) {
            let y = node.position.y;
            if (secondParent) {
              y = nodeList[secondParent].node.position.y + 115;
            }
            node.position = { x: updateX - childOffset - 10, y };
          } else {
            node.position = { x: updateX, y: currentY };
          }
        }
        positionXY[`${updateX}_${currentY}`] = "1";
      }

      previousNode = node;
      previousNodeIsConditional = testConditional.test(node.data.uniqueId);
    });
  };

  readAndRenderRunbook = () => {
    const {
      snippets,
      runbook,
      connectors,
      notifyRunbookUpdate,
      updateRunbookObj,
    } = this.props;
    if (!snippets || !snippets.length) return;

    const snippetLibrary = new SnippetLibrary(snippets);
    const mostLeftPosition = 200;

    // Create Runbook object instance
    // Case 1: Saved WF
    // Case 2: New WF

    let _runbookObj: any;
    const reader = new RunbookReader(snippetLibrary);
    _runbookObj = reader.readAPIResponse(runbook);
    this.loadRunbookAwsData(_runbookObj);

    /**
     * Case: When a New WF has to be created
     */
    if (runbook?.DefaultVersion === "Draft") {
      let trigger = snippets.find(
        snippet => snippet.name === this.props.selectedTrigger?.snippet_name,
      );
      if (!trigger) {
        let selectedTrigger = {
          name: "manual",
          display_name: "Manual Trigger",
          snippet_name: ControlNames.Manual,
        };
        this.props.setSelectedTriggerForWF(selectedTrigger);
        trigger = snippets.find(
          snippet => snippet.name === selectedTrigger?.snippet_name,
        );
      }

      trigger.insertionOrder = 1;
      const runbookNode = stepFromControlNodeData(
        {
          name: trigger.name,
          extras: trigger,
        },
        this.props.selectedTrigger,
      );
      _runbookObj.addStep(runbookNode);
    }

    //  Render nodes
    // 1. Run Diagram
    let unconfiguredNodes = findUnconfiguredNodes(connectors, _runbookObj);

    for (const item of _runbookObj?.mainSteps || []) {
      item.is_configured = isCurrentNodeConfigured(
        item.name.toLowerCase(),
        unconfiguredNodes,
      );
    }
    // define notifyRunbookUpdate() in NeurOpsRunbook object to be used for links actions
    _runbookObj.notifyRunbookUpdate = notifyRunbookUpdate;
    _runbookObj.updateRunbookObj = updateRunbookObj;

    // 2. Draw Diagram

    const nodeList = [];
    const reactFlowBounds = this.reactFlowWrapper.current.getBoundingClientRect();
    let xCordinate = reactFlowBounds.top + 100;
    let yCordinate = reactFlowBounds.left + 50;

    for (const step of _runbookObj?.mainSteps) {
      let id = uuidv4();
      const tempStepId = step.name.match(/\d+$/);
      const stepId = tempStepId ? parseInt(tempStepId[0], 10) : 0;
      let snippetDef = step.snippetDef || step.actionNodeDef || {};
      let label = step?.name?.startsWith(ControlNames.Trigger)
        ? step.trigger.display_name
        : step?.stepType === "ActionNodeStep"
        ? `${capitalizeFirstLetter(step.service) || "AWS"} ${
            snakeToPascal(step.operation) || ""
          }`
        : snippetDef?.display_name || "";
      let iconName = step?.name?.startsWith(ControlNames.Trigger)
        ? step?.trigger?.name
        : step?.stepType === "ActionNodeStep"
        ? "aws"
        : snippetDef?.icon_name || "";

      // Add editorNodeId
      step.editorNodeId = id;
      let nodeData = {
        name: label?.toLowerCase(),
        extras: { runbookNode: step, editorNodeId: id },
      };

      let type = getPortType(step);
      let nodeIconClass = this.getIconClass(nodeData);

      yCordinate += 100;

      let newNode = {
        id,
        position: { x: xCordinate, y: yCordinate },
        type,
        data: {
          label: (
            <>
              <div>{label || ""}</div>
              <div className="step-id">
                #{stepId || stepId === 0 ? stepId : "NA"}
              </div>
            </>
          ),
          uniqueId: step.ssm.name,
          nodeData,
          nodeIconClass,
          cloneElementMutation: this.cloneElementMutation,
          deleteElementMutation: this.deleteElementMutation,
          showTriggerSelectionModal: this.toggleTriggerSelectionModal,
          nodeIconStyle: {
            backgroundImage: `url(${getBackgroundImgSource(label, iconName)})`,
          },
        },
      };

      // Update State
      this.setState(state => ({ elements: state.elements.concat(newNode) }));

      nodeList[step.name] = {
        type: step.type,
        name: step.name,
        description: "",
        action: step,
        content: step.toSSM(),
        parameterInputs: step.parameterInputs || [],
        nextNode: step.nextStep,
        node: newNode,
      };
    }

    // 3. Update node position co-ordinates
    if (Object.keys(nodeList).length > 0) {
      const startNode = _runbookObj.mainSteps[0].name;
      let updateNodePosition = this.memoizedUpdateNodePosition();
      updateNodePosition(nodeList, mostLeftPosition, startNode, 100, 0.5);

      this.setNodePosition(nodeList);
    }

    updateRunbookObj(_runbookObj);
  };

  fixActionNodeInputTypes = (steps = []) => {
    const { awsOperationDetails } = this.props;

    for (let step of steps) {
      if (
        StepTypeChecker.isActionNodeStep(step.ssm) ||
        StepTypeChecker.isLoopStep(step.ssm) ||
        StepTypeChecker.isWaitForResourceStep(step.ssm)
      ) {
        const opKey = `${step.service}.${step.operation}`;
        const operationDetails = awsOperationDetails[opKey];

        let input;
        for (input of step.parameterInputs) {
          const inputName = input.name;
          if (!operationDetails) {
            continue;
          }
          const awsType = operationDetails.input[inputName];

          if (typeof awsType === "string") {
            input.setType(awsType);
          } else if (Array.isArray(awsType)) {
            input.setType(ParameterType.StringList);
          } else if (typeof awsType === "object") {
            input.setType(ParameterType.StringMap);
          }
        }
      }
    }
  };

  checkIfRunbookVersionChanged = prevProps => {
    if (this.props.runbookObj && prevProps.runbookObj) {
      if (this.props.runbookObj?.version !== prevProps.runbookObj?.version) {
        this.deSelectNode();
      }
    }
  };

  isDependableNode = selectedNodeName => {
    let returnVal = false;

    const slackNode = this.props.runbookObj?.mainSteps?.find(
      v => v.stepType === StepTypes.SlackConnectorStep,
    );

    /**
     * @condition If Slack node exists in the workflow and Selected node is not Slack Node
     */
    if (
      !!slackNode &&
      !!selectedNodeName &&
      selectedNodeName !== slackNode.name
    ) {
      const input = slackNode.parameterInputs.find(ip => ip.name === "message");
      returnVal = input.source.sourceValue?.includes(selectedNodeName);
    }
    return returnVal;
  };

  isLoopInNodes = (sourceId, targetId) => {
    let flag = false;
    const { elements } = this.state;

    const sourceElement = elements.find(element => element.id === sourceId);

    // Back track the nodes to find all parents
    let currentElement = sourceElement;
    while (!!currentElement?.inPort?.length) {
      if (currentElement.id === targetId) {
        // Found loop in between nodes
        flag = true;
        break;
      }
      let parentId = currentElement.inPort[0];
      currentElement = elements.find(ele => ele.id === parentId);
    }
    // Found loop at root which does not have inPort
    if (currentElement.id === targetId) {
      flag = true;
    }

    return flag;
  };

  updateAWSNodeName = () => {
    // If the elements array contain AWS node then the uniqueId property is set to <prefix>_<undefined>_<random_n0>
    // The following code updates the uniqueId once the AWS node is configured via right config panel
    const { elements } = this.state;
    for (let step in this.props.runbookObj?.mainStepIndex || []) {
      if (
        this.props.runbookObj?.mainStepIndex[step]?.stepType ===
        "ActionNodeStep"
      ) {
        const element = elements.find(
          item =>
            item?.data?.nodeData?.extras?.editorNodeId ===
            this.props.runbookObj?.mainStepIndex[step]?.editorNodeId,
        );

        if (
          !this.props.runbookObj?.mainStepIndex.hasOwnProperty(
            element?.data?.uniqueId,
          )
        ) {
          element.data.uniqueId = this.props.runbookObj?.mainStepIndex[
            step
          ]?.ssm.name;

          const service = this.props.runbookObj?.mainStepIndex[step]?.service;
          const operation = this.props.runbookObj?.mainStepIndex[step]
            ?.operation;
          const stepId = step.substring(step.lastIndexOf("_") + 1);
          element.data.label = (
            <>
              <div>
                {`${capitalizeFirstLetter(service) || "AWS"} ${
                  snakeToPascal(operation) || ""
                }`}
              </div>
              <div className="step-id">#{stepId || ""}</div>
            </>
          );

          this.props.updateRunbookObj(this.props.runbookObj);
          this.setState({ elements: cloneDeep(elements) });
        }
      }
    }
  };

  // ------------ Runbook helper functions end ------------

  // ------------ AWS helper functions start ------------

  loadAwsDataForSSMStep = ssmStep => {
    if (
      StepTypeChecker.isActionNodeStep(ssmStep.ssm) ||
      StepTypeChecker.isLoopStep(ssmStep.ssm) ||
      StepTypeChecker.isWaitForResourceStep(ssmStep.ssm)
    ) {
      const { service, operation } = ssmStep;
      if (service) {
        this.props.fetchAwsOperations(service);
      }

      if (service && operation) {
        this.props.fetchAwsOperationDetails(service, operation);
      }
    }
  };

  loadRunbookAwsData = runbook => {
    let step;
    for (step of runbook?.mainSteps || []) {
      this.loadAwsDataForSSMStep(step);
    }
  };

  awsDataNowReady = prevProps => {
    if (this.props.awsOperationDetails !== prevProps.awsOperationDetails) {
      let step;

      for (step of this.props.runbookObj?.mainSteps || []) {
        if (
          StepTypeChecker.isActionNodeStep(step.ssm) ||
          StepTypeChecker.isLoopStep(step.ssm) ||
          StepTypeChecker.isWaitForResourceStep(step.ssm)
        ) {
          const opKey = `${step.service}.${step.operation}`;
          if (!this.props.awsOperationDetails[opKey]) {
            return false;
          }
        }
      }
      return true;
    }
    return false;
  };

  // ------------ AWS helper functions end ------------

  // ------------ Parent callbacks functions start ------------
  rerenderEditor = () => {
    this.updateAWSNodeName();
  };

  handlePrevStepOptionHover = () => {
    const { elements } = this.state;

    if (this.props.runbookObj?.hoverMutation) {
      this.props.runbookObj?.mainSteps.forEach(step => {
        if (step.isHovered) {
          elements.forEach(element => {
            if (
              element.data.uniqueId &&
              element.data.uniqueId.substring(
                element.data.uniqueId.lastIndexOf("_") + 1,
              ) === step.name.substring(step.name.lastIndexOf("_") + 1)
            ) {
              element.className = "option-hover";
            } else {
              element.className = "";
            }
          });
          this.props.runbookObj.hoverMutation = false;
          this.setState({ elements: cloneDeep(elements) });
        }
      });
    }
    if (this.props.runbookObj?.mouseLeave) {
      elements.forEach(element => {
        element.className = "";
      });
      this.props.runbookObj.mouseLeave = false;
      this.setState({ elements: cloneDeep(elements) });
    }
  };

  draw = () => {
    this.setState({ isRenderingDiagram: true });

    let _runbookObj = this.props.runbookObj;

    if (isEmpty(_runbookObj)) return;

    // Re-arrange mainSteps
    _runbookObj.toSSM();

    const { elements } = this.state;
    const newElementsArray = [];
    const mostLeftPosition = 200;
    const nodeList = [];
    const reactFlowBounds = this.reactFlowWrapper.current.getBoundingClientRect();
    let xCordinate = reactFlowBounds.top + 100;
    let yCordinate = reactFlowBounds.left + 50;

    for (const step of _runbookObj?.mainSteps) {
      const node = elements.find(item => item.id === step?.editorNodeId);

      if (!node) {
        return;
      }
      yCordinate += 100;
      node.position = { x: xCordinate, y: yCordinate };

      newElementsArray.push(node);

      nodeList[step.name] = {
        type: step.type,
        name: step.name,
        description: "",
        action: step,
        content: step.toSSM(),
        parameterInputs: step.parameterInputs || [],
        nextNode: step.nextStep,
        node: node,
      };
    }
    this.setState({ elements: newElementsArray });

    setTimeout(() => {
      if (Object.keys(nodeList).length > 0) {
        const startNode = _runbookObj.mainSteps[0].name;
        let updateNodePosition = this.memoizedUpdateNodePosition();
        updateNodePosition(nodeList, mostLeftPosition, startNode, 100, 0.5);
        this.setNodePosition(nodeList);
      }
      this.setState({ isRenderingDiagram: false });
    }, 300);
  };

  // This method is invoked from Conditional Component
  addLinkToStep = (sourceNodeName, targetNodeName) => {
    const { elements } = this.state;

    const parentNode = elements.find(
      element => element?.data?.uniqueId === sourceNodeName,
    );
    const childNode = elements.find(
      element => element?.data?.uniqueId === targetNodeName,
    );

    if (!isEmpty(parentNode) && !isEmpty(childNode)) {
      const newEdge = {
        id: uuidv4(),
        source: parentNode.id,
        target: childNode.id,
        type: "defaultEdge",
        data: { onEdgeRemoveMutation: this.onEdgeRemoveMutation },
      };

      //Add port data
      if (parentNode?.outPort && Array.isArray(parentNode.outPort)) {
        parentNode.outPort.push(newEdge.target);
      } else {
        parentNode.outPort = [newEdge.target];
      }

      if (childNode?.inPort && Array.isArray(childNode.inPort)) {
        childNode.inPort.push(newEdge.source);
      } else {
        childNode.inPort = [newEdge.source];
      }

      // Check if edge/link already present & return
      const isEdgePresent = elements.find(
        item =>
          isEdge(item) &&
          item.source === newEdge.source &&
          item.target === newEdge.target,
      );

      if (!isEmpty(isEdgePresent)) {
        console.log("Edge Already present!");
        return;
      }

      this.setState(state => ({ elements: state.elements.concat(newEdge) }));
    }
  };

  removeLinkToStep = (sourceNodeName, targetNodeName) => {
    const { elements } = this.state;
    let newElements = [];

    const sourceNode = elements.find(
      item => item?.data?.uniqueId === sourceNodeName,
    );
    const targetNode = elements.find(
      item => item?.data?.uniqueId === targetNodeName,
    );
    const edge = elements.find(
      item => item.source === sourceNode?.id && item.target === targetNode?.id,
    );

    if (edge) {
      if (sourceNode?.outPort) {
        const _outPort = sourceNode.outPort.filter(id => id !== edge.target);
        sourceNode.outPort = _outPort;
      }
      newElements = elements.filter(item => item.id !== edge.id);
      this.setState({ elements: newElements });
    }
  };

  // ------------ Parent callbacks functions end ------------

  render() {
    return (
      <EditorDiagram
        {...this.props}
        state={this.state}
        handleSetState={this.handleSetState}
        deSelectNode={this.deSelectNode}
        runbookObj={this.props.runbookObj}
        rfNodes={this.props.rfNodes}
        notifyRunbookUpdate={this.props.notifyRunbookUpdate}
        setSelectedTriggerForWF={this.props.setSelectedTriggerForWF}
        triggerList={this.props.triggerList}
        isLoopInNodes={this.isLoopInNodes}
        onEdgeRemoveMutation={this.onEdgeRemoveMutation}
        isDependableNode={this.isDependableNode}
        getIconClass={this.getIconClass}
        draw={this.draw}
        cloneElementMutation={this.cloneElementMutation}
        deleteElementMutation={this.deleteElementMutation}
        toggleTriggerSelectionModal={this.toggleTriggerSelectionModal}
        reactFlowWrapper={this.reactFlowWrapper}
        nodeTypes={this.nodeTypes}
        edgeTypes={this.edgeTypes}
        deleteKeyCode={this.deleteKeyCode}
      />
    );
  }
}

const mapState = ({ runbooksReducer, commonReducer }) => ({
  runbooks: runbooksReducer.runbookList,
  triggerList: commonReducer.triggerList,
  selectedTrigger: commonReducer.selectedTrigger,
});
const mapDispatch = dispatch => ({
  createNewRunbook: runbookName => dispatch(createNewRunbook(runbookName)),
  setSelectedTriggerForWF: trigger =>
    dispatch(setSelectedTriggerForWF(trigger)),
  fetchTriggers: () => dispatch(fetchTriggers()),
});

export default connect(mapState, mapDispatch)(ReactFlowStore(EditorDesignArea));
