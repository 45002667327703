import { RunbookStepInputSource } from "../nodeinputoutput";

export const getParamInput = (input: string, parameterInputs = []) =>
  parameterInputs.find(p => p.name === input);

export const prepareUrl = (endpoint, params, runbookNode) => {
  let url = endpoint;
  /**
   * @case Params do not exist
   * Ex - Alias
   */
  if (!params) {
    return url;
  }
  return Object.keys(params).forEach(p => {
    const value = getParamInput(p, runbookNode?.parameterInputs)?.source
      ?.sourceValue;
    url = `${url}?${p}=${value}`;
    return url;
  });
};

export const shouldShowField = ({ name, snippetAction }, runbookNode) => {
  const inputs = snippetAction.snippetDef.required_inputs;
  const dependencies = inputs[name].dependencies || [];
  if (!dependencies.length) return true;
  const requiredInputs = runbookNode.parameterInputs;
  for (let i = 0; i < requiredInputs.length; i++) {
    const p = requiredInputs[i];
    if (p.name === name) continue;
    if (
      dependencies.includes(p.name) &&
      // typeof p.source?.sourceValue === "string" &&
      !p.source?.sourceValue
    ) {
      return false;
    }
  }
  return true;
};

export const isDynamic = input => {
  return (
    input.hasOwnProperty("api") ||
    input.hasOwnProperty("external_api") ||
    input.hasOwnProperty("allowed_values")
  );
};

export const isFieldRequired = (runbookNode, name) =>
  runbookNode.parameterInputs.find(p => p.name === name)?.required;

export const updateConstantValueControl = (
  input,
  newValue,
  notifyRunbookUpdate,
) => {
  // notify reducer that current runbook got updates.
  input.source = new RunbookStepInputSource("constant", newValue);
  notifyRunbookUpdate(true);
};

export const updateInputFromPreviousControl = (input, notifyRunbookUpdate) => {
  // notify reducer that current runbook got updates.
  input.source = new RunbookStepInputSource("actionNode", null);
  notifyRunbookUpdate(true);
};
