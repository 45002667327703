import React from "react";
import { Link } from "react-router-dom";
import { StatusBlock } from "@components/modules";
import {
  dateTimeDisplayFormat,
  dateTimeOnHoverDisplayFormat,
} from "@lib/utils/constants";
import OnHover from "@components/ui/OnHover";
import {
  appendDecimalZeroToInteger,
  replaceAllSpecialCharWithSpace,
} from "@lib/utils";
import moment from "moment";
import { RouteConstants } from "../../../../routes/Constants";

export const columns = [
  {
    name: "Workflow Name & Version",
    selector: "DocumentName",
    sortable: true,
    minWidth: "320px",
    cell: row => {
      const runbookName = replaceAllSpecialCharWithSpace(row.DocumentName);
      const id = row.AutomationExecutionId;
      return (
        <Link to={`${RouteConstants.executionDetails.url}/${id}/steps`}>
          {runbookName} : v{appendDecimalZeroToInteger(row.DocumentVersion)}
        </Link>
      );
    },
  },
  {
    name: "Overall Status",
    selector: "AutomationExecutionStatus",
    sortable: true,
    maxWidth: "180px",
    cell: row => {
      const status = row.AutomationExecutionStatus;
      return <StatusBlock status={status} />;
    },
  },
  {
    name: "Start Time",
    selector: "ExecutionStartTime",
    wrap: true,
    format: row => (
      <OnHover
        title={moment(row.ExecutionStartTime).format(
          dateTimeOnHoverDisplayFormat,
        )}
      >
        {moment(row.ExecutionStartTime).format(dateTimeDisplayFormat)}
      </OnHover>
    ),
    sortable: true,
  },
  {
    name: "End Time",
    selector: "ExecutionEndTime",
    wrap: true,
    format: row =>
      (row.AutomationExecutionStatus === "Success" ||
        row.AutomationExecutionStatus === "Cancelled" ||
        row.AutomationExecutionStatus === "Failed" ||
        row.AutomationExecutionStatus === "TimedOut") && (
        <OnHover
          title={moment(row.ExecutionEndTime).format(
            dateTimeOnHoverDisplayFormat,
          )}
        >
          {moment(row.ExecutionEndTime).format(dateTimeDisplayFormat)}
        </OnHover>
      ),
    sortable: true,
  },
  {
    name: "Duration",
    selector: "ExecutionDuration",
    wrap: true,
    format: row => {
      if (
        !["InProgress", "Waiting", "Pending"].includes(
          row.AutomationExecutionStatus,
        )
      ) {
        return row.duration;
      }
    },
    sortable: true,
  },
  {
    name: "Incident ID",
    selector: "TaskId",
    wrap: true,
    cell: row => {
      const taskId = row.TaskId;
      return taskId && taskId !== 0 ? (
        <Link
          to={`${RouteConstants.taskDetails.baseUrl}/${taskId}/workflows`}
        >{`#${taskId}`}</Link>
      ) : (
        ""
      );
    },
  },
];
