import React from "react";
import { Modal, TextInput, Button, Form } from "@components/ui";
import { Consumer } from "../runbooks-lib/runbooks.context";
import { connect } from "react-redux";
import { createNewRunbook } from "@redux/actions/runbook.action";
import {
  checkIfRunbookNameIsValid,
  replaceAllSpacesWithHyphen,
  replaceAllSpecialCharWithSpace,
} from "@lib/utils";
import TriggerSelection from "@components/shared/TriggerSelection/TriggerSelection";
import { setSelectedTriggerForWF } from "@redux/actions/common.actions";

const getFooter = (closeFunction, isDisabled) => {
  return (
    <div className="modal-buttons-footer">
      <Button
        text="Cancel"
        buttonStyle="secondary"
        size="none"
        onClick={closeFunction}
        style={{ width: "50%" }}
      />
      <Button
        text="Create Workflow"
        buttonStyle="primary"
        style={{ width: "50%" }}
        type={"submit"}
        disabled={isDisabled}
      />
    </div>
  );
};

class RunbookCreate extends React.Component {
  state = {
    formIsSubmitting: false,
    submissionError: "",
    success: false,
    runbookName: "",
    errorMessage: "",
    localTrigger: this.props.selectedTrigger,
    isDisabled: true,
    isNameEmp: true,
  };

  enableWfButton = () => {
    if (this.state.localTrigger !== null && !this.state.isNameEmp) {
      this.setState({ isDisabled: false });
    }
  };

  isWfNameEmpty = wfName => {
    if (wfName?.trim().length > 0) {
      this.setState({ isNameEmp: false }, () => this.enableWfButton());
    } else {
      this.setState({ isNameEmp: true, isDisabled: true });
    }
  };

  setLocalTrigger = localTrigger => {
    this.setState(
      {
        localTrigger,
      },
      () => this.enableWfButton(),
    );
  };

  checkForm = parameters => {
    if (!parameters) {
      return;
    }
    let userProvidedRunbookName = parameters["new-runbook"].trim
      ? parameters["new-runbook"].trim()
      : parameters["new-runbook"];

    const runbookNameValidityObj = checkIfRunbookNameIsValid(
      this.props.runbooks,
      userProvidedRunbookName,
    );

    userProvidedRunbookName = replaceAllSpacesWithHyphen(
      userProvidedRunbookName,
    );

    if (runbookNameValidityObj.isValidName && this.state.localTrigger) {
      this.props.setSelectedTriggerForWF(this.state.localTrigger);
      this.setState(
        {
          formIsSubmitting: true,
          runbookName: userProvidedRunbookName,
          errorMessage: "",
          localTrigger: null,
        },
        () => this.props.createNewRunbook(this.state.runbookName),
      );
    } else if (!this.state.localTrigger) {
      this.setState({
        formIsSubmitting: false,
        runbookName: userProvidedRunbookName,
      });
    } else {
      this.setState({
        formIsSubmitting: false,
        runbookName: userProvidedRunbookName,
        errorMessage: runbookNameValidityObj.errorMessage,
      });
    }
  };

  render() {
    return (
      <Consumer>
        {({ toggleRunbookModal }) => (
          <Form
            method="post"
            action="./runbook-editor"
            onSubmit={this.checkForm}
            formIsSubmitting={this.state.formIsSubmitting}
            submissionError={this.state.errorMessage}
            id="create-form"
          >
            <Modal
              onClose={toggleRunbookModal}
              title="Create Workflow"
              showClose={true}
              contentClass={`modal-content__32px`}
              titleClass={`modal-header__32px`}
              footer={getFooter(toggleRunbookModal, this.state.isDisabled)}
            >
              <TextInput
                name="new-runbook"
                label="Workflow Name"
                labelClassName="label"
                labelPosition="top"
                minLength={3}
                value={replaceAllSpecialCharWithSpace(this.state.runbookName)}
                autoFocus={true}
                containerClass="create-wf"
                className="text-input create-wf"
                onKeyUp={wfName => this.isWfNameEmpty(wfName)}
              />
              <div className="form-error mt-10-px">
                {this.state.errorMessage}
              </div>

              <div className="create-wf create-wf-trigger">
                <span className="editor-right-panel-field-label">
                  Trigger Type
                </span>
                <span className="description">
                  Your workflow needs a trigger to get started. Select from the
                  list of triggers below.
                </span>
                <TriggerSelection
                  triggerList={this.props.triggerList}
                  localTrigger={this.state.localTrigger}
                  setLocalTrigger={this.setLocalTrigger}
                />
              </div>
            </Modal>
          </Form>
        )}
      </Consumer>
    );
  }
}
const mapState = ({ runbooksReducer, commonReducer }) => ({
  runbooks: runbooksReducer.runbookList,
  triggerList: commonReducer.triggerList,
  selectedTrigger: commonReducer.selectedTrigger,
});
const mapDispatch = dispatch => ({
  createNewRunbook: runbookName => dispatch(createNewRunbook(runbookName)),
  setSelectedTriggerForWF: trigger =>
    dispatch(setSelectedTriggerForWF(trigger)),
});
export default connect(mapState, mapDispatch)(RunbookCreate);
