import React, { memo } from "react";
import { Handle, Position } from "react-flow-renderer";
import NodeBody from "./helpers/NodeBody";

type OutPortNodeProps = {
  data: any;
};

const OutPortNode: React.FC<OutPortNodeProps> = memo(
  ({ data, ...editorData }) => {
    return (
      <div className="rf-editor-hover-zone">
        <div className="rf-editor-default-node">
          <div className="rf-editor-hover-zone-top"></div>
          <div className="rf-editor-hover-zone-center"></div>
          <div className="rf-editor-hover-zone-bottom"></div>
          <NodeBody nodeData={data} editorData={editorData} />
          <Handle
            className="target-node"
            type="source"
            position={Position.Bottom}
          />
        </div>
      </div>
    );
  },
);

export default OutPortNode;
