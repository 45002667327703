import React from "react";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { SET_SELECTED_OUTPUT } from "@redux/types";
import {store} from '@redux/store'

export default class SnippetActionOption extends React.Component {
  defaultTitle = "Choose an output from the step";
  state = {
    title: this.defaultTitle,
  };

  updateInputControl = ({ label: text }) => {
    const value = `${this.props.name}.${text}`;
    const input = this.props.consumingInput;
    const runbook = input.snippetAction?.runbook || input.runbook;
    const parts = value.split(".");
    if (parts.length === 2) {
      const sourceStep = runbook.mainStepIndex[parts[0]];
      const sourceOutput = sourceStep.outputs.find(
        out => out.name === parts[1],
      );
      // The `input` object is part of the global `runbookObj` so changing
      // it updates the global state
      input.source = new RunbookStepInputSource("actionNode", sourceOutput);
      /**
       * Following code is to persist input source value in rule set for conditional node
       * in case of snippets other than AWS in case of new workflow design
       */
      if (
        input?.snippetAction?.inputs?.Choices &&
        input?.snippetAction?.inputs?.Choices[0]?.Variable ===
          "{{ choiceParameter }}"
      ) {
        let variable = `${sourceStep.name}.${sourceOutput.name}`;
        input.snippetAction.inputs.Choices[0].Variable = `{{ ${variable} }}`;
        input.choice.condition.variable = `{{ ${variable} }}`;
      }
      
      // updates {selectedOutput} when we change option in output selector
      store.dispatch({
        type: SET_SELECTED_OUTPUT,
        payload: sourceOutput?.selector,
      });
    }
    this.props.setPreviousStepSourceValue(text);
    // notify reducer that current runbook got updates.
    this.props.onChangeCallBack && this.props.onChangeCallBack(value);
    this.props.notifyRunbookUpdate(true);
    
  };

  render() {
    let sourceOutputs = this.props.step?.extras.runbookNode.outputs;
    if (this.props.step?.extras?.runbookNode?.actionNodeDef?.name === "AWS") {
      sourceOutputs = sourceOutputs.filter(
        out => out.name === "execution_status" || out.name === "output",
      );
    }
    return (
      <>
        <div className="label editor-right-panel-field-label mt-20-px">
          Previous Step Output
        </div>
        <ReactSelect
          id="previous-step-output"
          name="previous-step-output"
          required
          isSearchable={false}
          value={{
            label: this.props.previousStepSourceValue || this.defaultTitle,
            value: this.props.previousStepSourceValue || this.defaultTitle,
          }}
          isClearable={false}
          handleChange={this.updateInputControl}
          selectOptions={(sourceOutputs || []).map(item => {
            return {
              value: item.selector,
              label: item.name,
            };
          })}
        />
      </>
    );
  }
}
