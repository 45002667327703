import React from "react";
import eyeIconCancelled, {
  ReactComponent as IconEyeCancelled,
} from "@assets/images/icons/icon-eye__not-allowed.svg";

export default props => (
  <IconEyeCancelled
    src={eyeIconCancelled}
    className={props.className}
    onClick={props.handleClick}
  />
);
