import CloseIcon from "@assets/images/icons/icon-close.svg";
import "./style.scss";

/*
 * FTNotification: Fylamynt Notification
 * How to use:
  import { FTNotification } from "@components/ui";
  FTNotification.error({
      title: string:,
      message: string:,
      timeout: int:seconds
  })
  FTNotification.success({..})  //same as above
  title and/or message can be added,
  timeout is 5 seconds by default so it's optional
 *
*/
export default class FTNotification {
  constructor(props) {
    this.options = {
      ...props,
      timeout: props.timeout || 5, // in seconds
    };

    const notificationStyle = this.options.notificationStyle || "success";
    const uid = this.uuidv4();

    const title = document.createElement("div");
    if (this.options.title) {
      title.setAttribute("class", "title");
      title.appendChild(document.createTextNode(this.options.title));
    }

    const message = document.createElement("div");
    if (this.options.message) {
      message.setAttribute("class", "message");
      message.appendChild(document.createTextNode(this.options.message));
    }

    const remove = document.createElement("button");
    remove.setAttribute("class", "remove");
    const close = document.createElement("img");
    close.setAttribute("src", CloseIcon);
    remove.appendChild(close);
    remove.onclick = this.remove;

    const wrapper = document.createElement("div");
    wrapper.setAttribute("class", `notification-item ${notificationStyle}`);
    wrapper.setAttribute("id", uid);
    wrapper.appendChild(title);
    wrapper.appendChild(message);
    wrapper.appendChild(remove);
    this.push(wrapper);
  }

  push(wrapper) {
    if (!document.getElementById("notification-holder")) {
      const holder = document.createElement("div");
      holder.setAttribute("id", "notification-holder");
      const list = document.createElement("div");
      list.setAttribute("class", "list");
      holder.appendChild(list);
      document.body.append(holder);
    }
    const holderSelector = document
      .getElementById("notification-holder")
      .querySelector(".list");

    // Remove duplicate error notification
    this.removeDuplicateNotification(holderSelector, "error", wrapper);

    holderSelector.appendChild(wrapper);
    window.setTimeout(() => {
      if (document.getElementById(wrapper.id)) {
        wrapper.classList.add("display");
      }
    }, 200);
    window.setTimeout(() => {
      if (document.getElementById(wrapper.id)) {
        wrapper.classList.add("removed");
      }
      window.setTimeout(() => {
        if (document.getElementById(wrapper.id)) {
          holderSelector.removeChild(wrapper);
        }
      }, 200);
    }, this.options.timeout * 1000 - 200);
  }

  remove(e) {
    let target = e.target.parentElement;
    const holderSelector = document
      .getElementById("notification-holder")
      .querySelector(".list");
    if (e.target.tagName === "IMG") target = target.parentElement;
    if (target) {
      holderSelector.removeChild(target);
    }
  }

  //https://stackoverflow.com/a/2117523
  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c,
    ) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  /**
   * Remove duplicate notification triggered due multiple failed API calls
   * @param {DOMElement} holder - Holder Element for all the notifications
   * @param {String} target - Filter target (error, success or info)
   * @param {DOMElement} wrapper  - Input notification
   * @param {String} filter - Apply filter based on? (tittle content or message content)
   */
  removeDuplicateNotification(holder, target, wrapper, filter = "message") {
    let targetList = holder.querySelectorAll("." + target);
    if (targetList.length) {
      targetList.forEach(target => {
        let errorMessage = Array.from(target.childNodes).find(item =>
          Array.from(item.classList).includes(filter),
        )?.textContent;
        let wrapperMessage = Array.from(wrapper.childNodes).find(item =>
          Array.from(item.classList).includes(filter),
        )?.textContent;

        if (wrapperMessage?.startsWith(errorMessage)) {
          target.classList.add("removed");
          holder.removeChild(target);
        }
      });
    }
  }

  static error(props) {
    props.notificationStyle = "error";
    return new FTNotification(props);
  }

  static success(props) {
    props.notificationStyle = "success";
    return new FTNotification(props);
  }

  static info(props) {
    props.notificationStyle = "info";
    return new FTNotification(props);
  }
}
