import { awsFriendlyName } from "../ssm/util";
import { ParameterType } from "../ssm/strings";
import { Parameter } from "../ssm/parameters";
import { StepTypeChecker } from "../neuropssteps/steptypechecker";
import { isEmpty, escapeDoubleQuotes, escapeNewLineChar } from "@lib/utils";
import { isDynamic } from "./utils/Helper";

/**
 * Represents a named output from a Snippet.  Includes
 * fields needed for correct representation as a step in an
 * SSM document.
 */
export class RunbookStepOutput {
  constructor(snippetAction, name, type, selector) {
    this.snippetAction = snippetAction;
    this.name = name;
    this.type = fixTypeName(type);
    this.ssmStepOutputType = actionNodeSSMOutputType(this.type);
    this.selector = selector || `$.${name}`;
  }

  getQualifiedName() {
    return `${this.snippetAction.name}.${this.name}`;
  }

  getSSMPayloadValue() {
    return `{{ ${this.getQualifiedName()} }}`;
  }

  getSSMChoiceValue() {
    return `{{ ${this.getQualifiedName()} }}`;
  }

  isConsumed() {
    const successorInputs = this.consumers();
    return successorInputs && successorInputs.length > 0;
  }

  consumers() {
    return (
      this.snippetAction &&
      this.snippetAction.successors &&
      this.snippetAction
        .successors()
        .flatMap(
          step =>
            !isEmpty(step) &&
            (step.parameterInputs ||
              (step.choiceInputs && step.choiceInputs()) ||
              []),
        )
        .filter(input => (input?.source?.sourceValue === this ? true : false))
    );
  }

  toSSM() {
    return {
      Name: this.name,
      Selector: this.selector,
      Type: this.ssmStepOutputType,
    };
  }
}

export class ActionNodeOutput {
  constructor(sourceStep, selector, type, existingSSMOutput) {
    this.sourceStep = sourceStep;
    this.selector = selector;
    this.type = fixTypeName(type);
    this.ssmStepOutputType = actionNodeSSMOutputType(type);
    if (StepTypeChecker.isDatadogConnectorStep(sourceStep)) {
      const outputId = Math.floor(1000 * Math.random());
      this.selectorName = selector.replace("$.output.", "");
      this.name = `output_${outputId}`;
      this.ssmStepOutputType = type;
    } else if (existingSSMOutput) {
      this.name = existingSSMOutput.name;
      this.selectorName = existingSSMOutput.selector.split(".")[2];
    } else {
      const words = selector.split(".");
      const selectorSlices = words.slice(-2, words.length).join("_");
      const outputId = Math.floor(1000 * Math.random());
      this.selectorName = `${selectorSlices}_${outputId}`;
      this.name = `output_${outputId}`;
      this.selectorName = this.selectorName.replace(/\[\*\]/g, "_star");
    }
    const matches = this.selectorName.match(/\[\d\]/g);
    if (matches) {
      matches.forEach(match => {
        const num = match.replace(/\[|\]/g, "");
        const regEx = new RegExp(`\\[${num}\\]`, "g");
        this.selectorName = this.selectorName.replace(regEx, "_" + num + "");
      });
    }
  }

  getQualifiedName() {
    return `${this.sourceStep.name}.${this.name}`;
  }

  getSSMPayloadValue() {
    return `{{ ${this.getQualifiedName()} }}`;
  }

  getSSMChoiceValue() {
    return `{{ ${this.getQualifiedName()} }}`;
  }

  isConsumed() {
    const successorInputs = this.consumers();
    return successorInputs && successorInputs.length > 0;
  }

  consumers() {
    return (
      this.sourceStep &&
      this.sourceStep.successors &&
      this.sourceStep
        .successors()
        .flatMap(
          step =>
            (!isEmpty(step) &&
              ((step.dynamicInputs && step.dynamicInputs()) ||
                (step.allInputs && step.allInputs()) ||
                step.parameterInputs ||
                (step.choiceInputs && step.choiceInputs()))) ||
            [],
        )
        .filter(input => input.source.sourceValue === this)
    );
  }

  forLambdaPayload() {
    const { originalType } = this;
    const description = {
      Name: this.selectorName,
      Selector: this.selector,
      Type: this.type,
      originalType,
    };
    return description;
  }
  toSSM() {
    let selectorName = this.selectorName.startsWith("$")
      ? ""
      : `.${this.selectorName}`;
    return {
      Name: this.name,
      Selector: `$.output${selectorName}`,
      Type: this.ssmStepOutputType,
    };
  }
}

export function fixTypeName(typeName) {
  // String, StringList, Boolean, Integer, MapList, and StringMap
  switch (typeName) {
    case "string":
      return "String";
    case "number":
    case "integer":
      return "Integer";
    case "boolean":
      return "Boolean";
    case "array":
      return "StringList";
    case "Text":
    case "URL":
    case "DateTime":
    case "Boolean":
    case "Integer":
    case "Decimal":
    case "String":
    case "StringList":
    case "MapList":
    case "Object":
    case "Map":
    case "StringMap":
      return typeName;
    default:
      // check to see if it is a map type
      if (/{.*}/.test(typeName)) {
        return ParameterType.Map;
      }
      return "String";
  }
}

export function actionNodeSSMOutputType(typeName) {
  if ([ParameterType.Integer, ParameterType.Boolean].includes(typeName)) {
    return typeName;
  }

  if (typeName === ParameterType.Map) {
    // StringMap is an output type used in SSM
    return ParameterType.StringMap;
  }

  return ParameterType.String;
}

/**
 * Workflow nodes - snippets, conditionals, wait nodes, and others often
 * accept or require input parameters.  These can be obtained in three ways:
 * * `snippetOutput` - gets the value from the output of a snippet that is a predecessor
 *   of this node in the workflow DAG.
 * * `constant` is a constant value provided by the workflow author
 * * `userProvided` - gets the value from the user every time they run the workflow.
 */
export class RunbookStepInputSource {
  /** type can be 'snippetOutput' | 'constant' | 'userProvided' | 'actionNode'*/
  constructor(type, sourceValue) {
    this.type = type;
    this.sourceValue = sourceValue;
    if (this.type === "constant" && sourceValue === "") {
      this.sourceValue = null;
    }
  }
}

export class ActionNodeInputSource extends RunbookStepInputSource {
  constructor(sourceStep, selector, type) {
    super("actionNode", new ActionNodeOutput(sourceStep, selector, type));
  }
}

export const getEmptyConstantSource = (defaultValue = null) =>
  new RunbookStepInputSource("constant", defaultValue);

export class RunbookStepInput {
  static parameterName(name, runbookStepInput) {
    switch (name) {
      case "alias":
      case "regionName":
      case "region_name":
        return name;
      default:
        return awsFriendlyName(`${name}${runbookStepInput.snippetAction.name}`);
    }
  }

  constructor(snippetAction, name, input, required, source) {
    // check if type has something like "String|ec2|id"
    this.resourcesAutocomplete = [];
    this.snippetAction = snippetAction;
    this.name = name;
    this.hidden = input.hidden;
    this.type = fixTypeName(input.type);
    this.required = required;
    this.source = isDynamic(input)
      ? getEmptyConstantSource(input.default || null)
      : source || getEmptyConstantSource(input.default || null);
    this.allowedPattern = input.allowed_pattern || null;
    this.description = input?.description || "";
    this.display_name = input?.display_name || "";
    if (required && !source && !isDynamic(input)) {
      // we need to create a new source for it and add it to the runbook
      // The alias parameter is special - all nodes get the same alias.
      const parameterName = RunbookStepInput.parameterName(name, this);
      const description = `(Required - ${input.type}) ${snippetAction.name} - ${name}`;
      const param =
        snippetAction.runbook?.ssmDoc.parameters.parameters[parameterName] ||
        Parameter.createFylamyntParameter(parameterName, description);
      if (snippetAction.runbook) {
        snippetAction.runbook.ssmDoc.parameters.parameters[
          parameterName
        ] = param;
      }
      const newSource = new RunbookStepInputSource("userProvided", param);
      this.source = newSource;
    }
    this.defaultValue = () => {
      const param = this.snippetAction.runbook.parameters[this.name];
      return (param && param.default) || "";
    };
  }

  resetSource() {
    if (this.required) {
      const parameterName = RunbookStepInput.parameterName(this.name, this);
      const description = `(Required - ${this.type}) ${this.snippetAction.name} - ${this.name}`;
      const param =
        this.snippetAction.runbook?.ssmDoc.parameters.parameters[
          parameterName
        ] || Parameter.createFylamyntParameter(parameterName, description);
      if (this.snippetAction.runbook) {
        this.snippetAction.runbook.ssmDoc.parameters.parameters[
          parameterName
        ] = param;
      }
      const newSource = new RunbookStepInputSource("userProvided", param);
      this.source = newSource;
    } else {
      this.source = getEmptyConstantSource();
    }
  }

  setType(typeString) {
    if (typeString.includes("|")) {
      let typeParts = typeString.split("|");
      this.resourcesAutocomplete = typeParts.slice(1, 3);
      typeString = typeParts[0];
    } else {
      this.resourcesAutocomplete = [];
    }
    this.type = fixTypeName(typeString);
  }

  setValue(constantValue) {
    this.source = new RunbookStepInputSource("constant", constantValue);
  }

  /**
   * Write the escaped JSON field to insert into the lambda invocation
   * payload in an SSM automation document.
   */
  writeInputParam() {
    let param = "";
    let paramValue;
    if (
      this.source.sourceValue ||
      typeof this.source.sourceValue === "boolean"
      // checking to see if the sourceValue is present - so if sourceValue === false
      // we still want to proceed
    ) {
      param = `"${this.name}":`;
      paramValue = this.writeInputParamValue();
      if (
        this.type === ParameterType.StringList ||
        this.type === ParameterType.StringMap ||
        this.type === ParameterType.Object ||
        this.type === ParameterType.Map
      ) {
        param +=
          Array.isArray(paramValue) || typeof paramValue === "object"
            ? JSON.stringify(paramValue)
            : paramValue;
      } else {
        param += `${paramValue}`;
      }
    }
    return param;
  }

  writeInputParamValue() {
    let targetType = this.type;
    let sourceType = this.source.sourceValue.type;
    let val = "";
    switch (this.source.type) {
      case "userProvided":
        // a user typed in a string
        sourceType = this.type; //ParameterType.String;
        val = `{{ ${awsFriendlyName(this.source.sourceValue.name)} }}`;
        break;
      case "constant":
        // a user typed in a string
        sourceType = this.type; //ParameterType.String;
        val = Array.isArray(this.source.sourceValue)
          ? JSON.stringify(this.source.sourceValue)
          : this.source.sourceValue;

        /**
         * Check explicitly if the user entered a JSON Object in
         * input box and SSM doc expects a String
         */
        try {
          JSON.parse(this.source.sourceValue);
          if (this.type === ParameterType.String) {
            targetType = "StringObject";
          }
        } catch (e) {
          console.log("not an object can't be parsed");
        }
        /***/
        break;
      case "snippetOutput":
      case "actionNode":
        val = this.source.sourceValue.getSSMPayloadValue();
        return this._decorateSSMSubstitution(sourceType, targetType, val);
      default:
        break;
    }
    return this._decorateUserTypedInput(sourceType, targetType, val);
  }

  _decorateUserTypedInput(sourceType, targetType, val) {
    // put quotes if the target type is a string
    // otherwise assume a correct JSON string is coming in
    switch (targetType) {
      case ParameterType.Text:
        return `"${escapeNewLineChar(val)}"`;
      case ParameterType.URL:
      case ParameterType.DateTime:
      case ParameterType.String:
        return `"${escapeDoubleQuotes(val)}"`;
      case "StringObject":
        return JSON.stringify(val);
      default:
        return val;
    }
  }

  _decorateSSMSubstitution(sourceType, targetType, val) {
    // convert the following to the appropriate JSON representation
    if (
      sourceType === ParameterType.String ||
      sourceType === ParameterType.Text ||
      sourceType === ParameterType.Boolean ||
      sourceType === ParameterType.Integer ||
      sourceType === ParameterType.Decimal
    ) {
      switch (targetType) {
        case ParameterType.String:
        case ParameterType.Text:
          return `"${val}"`;
        case ParameterType.StringList:
          return sourceType === ParameterType.StringList
            ? `"${val}"`
            : `["${val}"]`;
        default:
          return val;
      }
    }
    return val;
  }

  getSSMChoiceValue() {
    if (this.source) {
      switch (this.source.type) {
        case "userProvided":
          return `{{ ${awsFriendlyName(this.name)} }}`;
        case "constant":
          return this.source.sourceValue;
        case "snippetOutput":
        case "actionNode":
          if (this.source && this.source.sourceValue) {
            return `${this.source.sourceValue.getSSMChoiceValue()}`;
          }
          break;
        default:
          break;
      }
    }
  }
}

export function readLambdaParam(runbook, value) {
  const lookForVar = /\{\{\s*(\w+(?:\.\w+)*)\s*\}\}/.exec(value);

  if (lookForVar) {
    // we have a match, the variable name is item 1 in the array
    const varName = lookForVar[1];
    const parts = varName.split(".");
    if (parts.length === 1) {
      // we have an SSM Document parameter - userProvided
      const param =
        runbook.ssmDoc.parameters.parameters[varName] ||
        Parameter.createFylamyntParameter(varName);
      //make sure it is stored in the runbook
      runbook.ssmDoc.parameters.parameters[varName] = param;

      return [
        new RunbookStepInputSource("userProvided", param),
        Array.isArray(value),
      ];
    } else {
      // it is an output from a previous snippet
      const stepName = parts[0];
      const outputName = parts[parts.length - 1];
      const sourceAction = runbook.mainStepIndex[stepName];
      if (!sourceAction) {
        // TODO: this is really an invalid state.  Make sure that
        // we never save a runbook with references to previous steps
        // that have been removed.  We can still do this here to recover if we have
        // a bad handwritten or old runbook.
        const param = Parameter.createFylamyntParameter(
          `${parts[0]}_${stepName}`,
        );
        //make sure it is stored in the runbook
        runbook.ssmDoc.parameters.parameters[param.name] = param;
        return [
          new RunbookStepInputSource("deleted", param),
          Array.isArray(value),
        ];
      }
      const sourceOutput = (sourceAction.outputs || []).find(
        output => output.name === outputName,
      );
      if (!sourceOutput) {
        const param = Parameter.createFylamyntParameter(value);
        //make sure it is stored in the runbook
        runbook.ssmDoc.parameters.parameters[param.name] = param;
        return [
          new RunbookStepInputSource("userProvided", param),
          Array.isArray(value),
        ];
      }
      return [
        new RunbookStepInputSource("actionNode", sourceOutput),
        Array.isArray(value),
      ];
    }
  } else {
    // it's just a constant value
    return [
      new RunbookStepInputSource("constant", value),
      Array.isArray(value),
    ];
  }
}
