import React from "react";
import { InputSelector } from "@containers/RunbookEditor/runbook-editor-components/editor-right-panel/input-selector";
import { getSelectedOption } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.helpers";

export default class InputsRequired extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.requiredInputs &&
          this.props.requiredInputs.length > 0 &&
          this.props.selectedAwsOperation &&
          this.props.requiredInputs.map((input, i) => {
            return (
              <InputSelector
                optionFilterId={this.props.optionFilterId}
                toggleInputOption={this.props.toggleInputOption}
                input={input}
                selectedOption={getSelectedOption(input)}
                key={`${input.name}-${i}`}
                label={this.props.label}
              />
            );
          })}
      </React.Fragment>
    );
  }
}
