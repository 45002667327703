import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AWSOperationSelector from "../../aws-operation-selector";
import { InputSelector } from "@containers/RunbookEditor/runbook-editor-components/editor-right-panel/input-selector";
import { getSelectedOption } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.helpers";
import SnippetHeader from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/SnippetHeader/SnippetHeader";
import Header from "@components/ui/InputTypes/Layouts/Header/Header";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { isEmpty } from "@lib/utils";
import { InputGroup } from "./";

function LoopForEachInput(props) {
  const { runbookNode, notifyRunbookUpdate } = props;

  const [loopParameter, setLoopParameter] = useState(runbookNode.parameter);
  const [operationDetailsReady, setOperationDetailsReady] = useState(false);

  // can we remove this? it is getting set but not read. Smells like a bug.
  // eslint-disable-next-line no-unused-vars
  const [optionFilterId, setOptionFilterId] = useState("none");

  const inputs = runbookNode.parameterInputs || [];
  const requiredInputs = inputs.filter(
    input => input.required && input.name !== loopParameter,
  );
  const optionalInputs = inputs.filter(
    input => !input.required && input.name !== loopParameter,
  );

  useEffect(() => {
    if (loopParameter && loopParameter !== runbookNode.parameter) {
      runbookNode.setParameter(loopParameter);
      notifyRunbookUpdate(true);
    }
  }, [loopParameter, notifyRunbookUpdate, runbookNode]);

  useEffect(() => {
    if (
      props.selectedService &&
      props.selectedService !== runbookNode.service
    ) {
      runbookNode.setService(props.selectedService);
      setOperationDetailsReady(false);
    }
  }, [props.selectedService, notifyRunbookUpdate, runbookNode]);

  useEffect(() => {
    if (
      props.selectedOperation &&
      props.selectedOperation !== runbookNode.operation
    ) {
      runbookNode.setOperation(props.selectedOperation);
      setOperationDetailsReady(false);
    }
  }, [props.selectedOperation, notifyRunbookUpdate, runbookNode]);

  const handleOperationDetailsReady = () => {
    if (props.operationDetails) {
      runbookNode.setOperationDetails(props.operationDetails);

      setLoopParameter(runbookNode.parameter);
      setOperationDetailsReady(true);
    }
  };

  const loopParameterSelect = () => {
    return (
      <div className="editor-detail-panel editor-detail-panel-column p-0-px">
        <Header title="Loop Parameter" description="Loop Parameters" />
        {operationDetailsReady ? (
          <div className="editor-aws-container">
            <ReactSelect
              id="loop-parameters"
              name="loop-parameters"
              customControlClass="mt-2-px"
              value={{
                value: loopParameter || "",
                label: loopParameter || "Select from below",
              }}
              isLoading={!inputs?.length}
              handleChange={data => {
                if (data === null) {
                  setLoopParameter("");
                } else if (!isEmpty(data) && !!data.value) {
                  setLoopParameter(data.value);
                }
              }}
              selectOptions={
                inputs && Array.isArray(inputs)
                  ? inputs.map(input => {
                      return {
                        value: input.name,
                        label: input.name,
                      };
                    })
                  : []
              }
              required
            />
          </div>
        ) : (
          <div className="editor-is-fetching mb-5-px">...fetching inputs</div>
        )}{" "}
      </div>
    );
  };

  const toggleInputOption = ofId => {
    if (typeof ofId === "object") {
      const obj = ofId.target;
      if (obj.classList.contains("checkmark")) {
        this.timer = setTimeout(() => {
          setOptionFilterId(ofId);
        }, 100);
      } else {
        if (obj.type === "text") {
          return;
        }

        setOptionFilterId("none");
      }
    } else {
      setOptionFilterId(ofId);
    }
  };

  const loopInputSelector = () => {
    const input = runbookNode.itemsInput;
    return input ? (
      <InputSelector
        optionFilterId={"none"}
        toggleInputOption={toggleInputOption}
        input={input}
        selectedOption={getSelectedOption(input)}
        label="Loop over list"
      />
    ) : (
      <div className="editor-is-fetching">
        ...cannot find input ${loopParameter}
      </div>
    );
  };

  return (
    <React.Fragment>
      <SnippetHeader snippetDef={runbookNode.actionNodeDef} />
      {loopInputSelector()}
      <div className="editor-detail-panel editor-detail-panel-column">
        <label className="editor-right-panel-field-label">
          Each item is an input to the following AWS operation:
        </label>
        <div className="editor-terraform-container">
          <AWSOperationSelector
            runbookNode={runbookNode}
            handleOperationDetailsReady={handleOperationDetailsReady}
            notifyRunbookUpdate={notifyRunbookUpdate}
          />

          {loopParameterSelect()}
        </div>
      </div>
      <InputGroup
        groupName="Required Inputs"
        inputs={requiredInputs}
        optionFilterId={props.optionFilterId}
        toggleInputOption={toggleInputOption}
        selectedAwsOperation={props.selectedOperation}
      />

      <InputGroup
        groupName="Optional Inputs"
        inputs={optionalInputs}
        optionFilterId={props.optionFilterId}
        toggleInputOption={toggleInputOption}
        selectedAwsOperation={props.selectedOperation}
      />
    </React.Fragment>
  );
}

const mapState = ({ actionNodeReducer }) => {
  return {
    operations: actionNodeReducer.operations,
    services: actionNodeReducer.services,
    selectedService: actionNodeReducer.selectedService,
    selectedOperation: actionNodeReducer.selectedOperation,
    operationDetails: actionNodeReducer.operationDetails,
  };
};

export default connect(mapState)(LoopForEachInput);
