import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  TextInput,
} from "@components/ui";
import { isEmpty } from "@lib/utils";
import Api from "@lib/api";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import { FromPreviousStep } from "@containers/RunbookEditor/runbook-editor-components/editor-right-panel/input-selector/option-actions";
import TickIcon from "@assets/images/icons/icon-tick.svg";
import Operations from "./Operations";
import Header from "@components/ui/InputTypes/Layouts/Header/Header";

const InputOptionSelector = ({
  defaultInput,
  setSelectedInputChoice,
  setInputStringUpdate,
  setInputPrevStep,
}) => {
  const [toggleSelector, setToggleSelector] = useState(false);
  const options = [
    { label: "Set value now", value: "setValueNow", choice: "constant" },
    {
      label: "Set value from a previous step",
      value: "setValueFromPrevious",
      choice: "actionNode",
    },
  ];

  return (
    <>
      <div className="editor-gear-icon-wrap">
        <div
          className="editor-gear-icon"
          onClick={() => setToggleSelector(!toggleSelector)}
        />
      </div>
      {toggleSelector && (
        <nav
          className="editor-dropdown editor-dropdown-list editor-dropdown-container"
          id="w-dropdown-toggle-9"
        >
          {options.map(option => (
            <li
              key={option.value}
              className="editor-dropdown-link"
              onClick={() => {
                setSelectedInputChoice(option.choice);
                setInputStringUpdate(null);
                setInputPrevStep(null);
                setToggleSelector(false);
              }}
            >
              <img
                className={`dropdown-tick-icon ${
                  defaultInput !== option.choice ? "visibility-hidden" : ""
                }`}
                src={TickIcon}
                alt="tick-icon"
              />
              {option.label}
            </li>
          ))}
        </nav>
      )}
    </>
  );
};

type RequiredInputsProps = {};

const RequiredInputs: React.FC<RequiredInputsProps> = () => {
  const isFirstRun = useRef(true);

  const context = useContext(RunbookEditorContext) as any;
  const runbookNode = context.activeNode.extras.runbookNode;
  const runbookObj = context.runbookObj;
  const notifyRunbookUpdate = context.notifyRunbookUpdate;

  const [defaultInputChoice, setDefaultInputChoice] = useState("");
  const [selectedInput, setSelectedInput] = useState("");
  const [inputString, setInputString] = useState("");
  const [inputPrevStep, setInputPrevStep] = useState(null);

  // Operations
  const [isLoadingOperations, setIsLoadingOperations] = useState(false);
  const [isErrorOperations, setIsErrorOperations] = useState(false);
  const [operations, setOperations] = useState([]);
  const [operationsMap, setOperationsMap] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState("");
  const [transformValue, setTransformValue] = useState(null);

  const getParamInput = useCallback(
    input => runbookNode.parameterInputs.find(p => p.name === input),
    [runbookNode.parameterInputs],
  );
  const getParamValue = useCallback(
    input => {
      const value = getParamInput(input).source?.sourceValue;
      if (value && value.constructor.name === "String") {
        return input === "regex_pattern" ? JSON.parse(value) : value;
      }
      return "";
    },
    [getParamInput],
  );

  const setSelectedInputChoice = (choice: string = "constant") => {
    setSelectedInput(choice);
    // input_string specific param choice set in payload
    const inputSource = getParamInput("input_string");
    inputSource.source = new RunbookStepInputSource(choice, "input_string");
    notifyRunbookUpdate(true);
  };

  const setPayload = useCallback(
    (input, param) => {
      const paramValue = getParamInput(param);
      if (paramValue) {
        paramValue.source = new RunbookStepInputSource("constant", input);
        notifyRunbookUpdate(true);
      }
    },
    [getParamInput, notifyRunbookUpdate],
  );

  const setInputStringUpdate = input => {
    setInputString(input);
    setPayload(input, "input_string");
  };

  const setOperationUpdate = input => {
    setSelectedOperation(input);
    setPayload(input, "operation_type");
  };

  const removeOperationParameterUpdate = () => {
    if (!selectedOperation || !operationsMap) {
      return;
    }
    let filterredOperations = [];
    operationsMap.forEach(value => {
      filterredOperations.push(
        Object.keys(value?.required_inputs || {}).filter(
          item => item !== "input_string",
        ),
      );
    });
    filterredOperations.flat().map(item => setPayload("", item));
  };

  const getTransfromValue = useCallback(() => {
    if (!inputString || !selectedOperation) {
      setTransformValue("");
      return;
    }
    switch (selectedOperation) {
      case "From_String":
        let prefix = getParamValue("prefix");
        if (prefix) {
          inputString.indexOf(prefix) !== -1
            ? setTransformValue(inputString.substr(inputString.indexOf(prefix)))
            : setTransformValue("");
        } else {
          setTransformValue("");
        }
        break;

      case "Regex_Extract_All":
        let regex = getParamValue("regex_pattern");
        if (regex) {
          try {
            let matches = Array.from(inputString.matchAll(regex), m => m[0]);
            matches ? setTransformValue(matches) : setTransformValue("");
          } catch {
            setTransformValue("");
          }
        } else {
          setTransformValue("");
        }
        break;

      case "Replace_All":
        let toReplace = getParamValue("to_replace");
        let replaceWith = getParamValue("replace_with");
        if (toReplace && replaceWith) {
          setTransformValue(
            inputString.replaceAll(
              new RegExp(`\\b${toReplace}\\b`, "g"),
              replaceWith,
            ),
          );
        } else {
          setTransformValue("");
        }
        break;

      case "Split":
        let delimiter = getParamValue("delimiter");
        if (delimiter) {
          setTransformValue(inputString.split(delimiter));
        } else {
          setTransformValue("");
        }
        break;

      case "Strip_Chars":
        let stripChar = getParamValue("strip_chars");
        if (stripChar) {
          let chars = stripChar.split("");
          let outString = inputString;
          chars.forEach(char => {
            outString = outString.replaceAll(char, "");
          });
          setTransformValue(outString);
        } else {
          setTransformValue("");
        }
        break;

      case "To_Lowercase":
        setTransformValue(inputString.toLowerCase());
        break;

      case "To_Uppercase":
        setTransformValue(inputString.toUpperCase());
        break;

      default:
        setTransformValue(inputString);
    }
  }, [getParamValue, inputString, selectedOperation]);

  const setInitialParamValues = useRef(() => {});
  setInitialParamValues.current = () => {
    const inputStringValSource = getParamInput("input_string").source;
    setSelectedInput(
      inputStringValSource.type === "userProvided"
        ? "constant"
        : inputStringValSource.type,
    );
    const inputStringVal = inputStringValSource?.sourceValue;

    if (inputStringVal && inputStringVal.constructor.name === "String") {
      setInputString(inputStringVal);
    }
    // Set dummy initial state for FromPreviousStep
    if (inputStringValSource.type !== "userProvided" && inputStringVal) {
      setInputPrevStep("place_holder");
    }

    const operationType = getParamInput("operation_type").source?.sourceValue;
    if (operationType && operationType.constructor.name === "String") {
      setSelectedOperation(operationType);
    }
  };

  const getAllOperations = useRef(() => {});
  getAllOperations.current = async () => {
    setIsLoadingOperations(true);
    setIsErrorOperations(false);
    try {
      const data = await Api.getStringTransformersOperations();
      const parsedData = typeof data === "string" ? JSON.parse(data) : data;
      if (!isEmpty(parsedData)) {
        let keys = Object.keys(parsedData);
        let operationMap = new Map();
        keys.map(key => operationMap.set(key, parsedData[key]));
        setOperations(keys);
        setOperationsMap(operationMap);
        setIsLoadingOperations(false);
        setIsErrorOperations(false);
      }
    } catch (error) {
      setIsLoadingOperations(false);
      setIsErrorOperations(true);
    }
  };

  // Callback fired on input changes
  const showWarning = () => {
    if (runbookNode) {
      let flag = true;
      if (operationsMap && selectedOperation) {
        let operationsObj = operationsMap.get(selectedOperation);
        let operationLabels = Object.keys(operationsObj?.required_inputs || {});
        operationLabels = operationLabels.filter(
          operation => operation !== "input_string",
        );
        operationLabels.forEach(item => {
          let value = getParamValue(item);
          if (!value) {
            flag = false;
          }
        });
      }
      if (
        !flag ||
        (!inputString && selectedInput === "constant") ||
        (!inputPrevStep && selectedInput !== "constant") ||
        !selectedOperation
      ) {
        runbookNode.showHideWarning(true);
      }
    }
  };

  useEffect(() => {
    setDefaultInputChoice(selectedInput);
  }, [selectedInput]);

  useEffect(() => {
    getAllOperations.current();
  }, []);

  useEffect(() => {
    getTransfromValue();
  }, [inputString, selectedOperation, getTransfromValue]);

  useEffect(() => {
    if (isFirstRun.current) {
      setInitialParamValues.current();
      isFirstRun.current = false;
    }
  }, []);

  useEffect(() => {
    if (runbookNode) {
      // Handle initial case when node renders
      let flag = true;
      if (operationsMap && selectedOperation) {
        let operationsObj = operationsMap.get(selectedOperation);
        let operationLabels = Object.keys(operationsObj?.required_inputs || {});
        operationLabels = operationLabels.filter(
          operation => operation !== "input_string",
        );
        operationLabels.forEach(item => {
          let value = getParamValue(item);
          if (!value) {
            flag = false;
          }
        });
      }
      runbookNode.showHideWarning(
        (!inputString && selectedInput === "constant") ||
          (!inputPrevStep && selectedInput !== "constant") ||
          !selectedOperation ||
          !flag,
      );
    }
  }, [
    selectedInput,
    inputPrevStep,
    runbookNode,
    runbookNode.parameterInputs,
    inputString,
    selectedOperation,
    operationsMap,
    getParamValue,
    setPayload,
  ]);

  return (
    <Accordion isExpanded={true} useArrow={true}>
      <AccordionLabel className="editor-accordion-label mt-10-px">
        Required Inputs
      </AccordionLabel>
      <AccordionSection>
        <div className="editor-detail-panel editor-detail-panel-column string-transform-input">
          {selectedInput !== "constant" ? (
            <label className="label">String Input</label>
          ) : (
            <Header title={"String Input"} description={""} helpText={""} />
          )}

          <InputOptionSelector
            defaultInput={defaultInputChoice}
            setSelectedInputChoice={setSelectedInputChoice}
            setInputStringUpdate={setInputStringUpdate}
            setInputPrevStep={setInputPrevStep}
          />
          {selectedInput === "constant" ? (
            <TextInput
              name="input_string"
              value={inputString}
              type="textarea"
              maxLength={null}
              onKeyUp={value => {
                setInputStringUpdate(value);
              }}
              className={`rule-input p-10-px w-auto ${
                !inputString ? "rule-input-error" : ""
              }`}
              required
            />
          ) : (
            <div className={`mt-n3`}>
              <FromPreviousStep
                input={getParamInput("input_string")}
                runbookObj={runbookObj}
                notifyRunbookUpdate={notifyRunbookUpdate}
                onChangeCallBack={data => {
                  setInputPrevStep(data);
                }}
                updateInputControl={() => {
                  const input = getParamInput("input_string");
                  input.source = new RunbookStepInputSource("actionNode", null);
                  notifyRunbookUpdate(true);
                }}
              />
            </div>
          )}
        </div>

        <Operations
          isLoadingOperations={isLoadingOperations}
          isErrorOperations={isErrorOperations}
          operations={operations}
          operationsMap={operationsMap}
          getParamValue={getParamValue}
          setPayload={setPayload}
          selectedOperation={selectedOperation}
          setOperationsUpdate={setOperationUpdate}
          removeOperationParameterUpdate={removeOperationParameterUpdate}
          showWarning={showWarning}
          showOutput={selectedInput === "constant"}
          transformValue={transformValue}
          getTransfromValue={getTransfromValue}
        />
      </AccordionSection>
    </Accordion>
  );
};

export default RequiredInputs;
