import React from "react";
import { connect } from "react-redux";
import { Accordion, AccordionSection } from "@components/ui";
import { snakeToPascal, camelToSnake, isEmpty } from "@lib/utils";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import Header from "@components/ui/InputTypes/Layouts/Header/Header";
import { updateActionNode } from "./snippet-tabs/action-node-panel/input.helpers";
import { SERVICES_NEEDED } from "@redux/types";
import {
  fetchServices,
  setSelectedService,
  setSelectedOperation,
} from "@redux/actions/action-node.action";

class AWSOperationSelector extends React.Component {
  state = {
    operationDetailsNeeded: true,
  };

  componentDidMount() {
    this.initServiceAndOperation();
  }

  componentDidUpdate(prevProps) {
    this.setOperationDetails(prevProps);
  }

  initServiceAndOperation = () => {
    let { service, operation } = this.props.runbookNode;
    this.setService(service);
    if (operation === "undefined") {
      operation = null;
    }
    if (operation) {
      operation = camelToSnake(operation);
    }
    this.setSelectedOperation(operation);
    if (this.props.servicesNeeded) {
      this.props.fetchServices();
    }
  };

  setService = newValue => {
    this.props.setSelectedService(camelToSnake(newValue));
  };

  setSelectedOperation = operation => {
    this.props.setSelectedOperation(operation);
  };

  setOperationDetails = prevProps => {
    if (!this.state.operationDetailsNeeded) {
      return;
    }
    if (
      prevProps.isFetchingOperationDetails &&
      !this.props.isFetchingOperationDetails
    ) {
      this.props.handleOperationDetailsReady();
    }
  };
  updateService = newValue => {
    this.setService(newValue);
    this.props.setSelectedOperation();
    this.props.notifyRunbookUpdate(true);
  };

  updateOperation = newValue => {
    this.setSelectedOperation(camelToSnake(newValue));
    this.setState({
      operationDetailsNeeded: true,
    });
    this.props.notifyRunbookUpdate(true);
  };

  operationsOnClear = () => {
    this.setSelectedOperation(null);
    updateActionNode(this);
    this.props.notifyRunbookUpdate(true);
  };

  getServicesSelect = () => {
    return (
      <div className="editor-detail-panel editor-detail-panel-column p-0-px">
        <Header title="Service" description="Service" />
        {!this.props.isFetchingServices ? (
          <div className="editor-aws-container">
            <ReactSelect
              id="aws-services"
              name="aws-services"
              customControlClass="mt-2-px"
              value={{
                value: this.props.selectedService
                  ? snakeToPascal(this.props.selectedService)
                  : "",
                label: this.props.selectedService
                  ? snakeToPascal(this.props.selectedService)
                  : "Select from below",
              }}
              isLoading={!this.props.services?.length}
              handleChange={newValue => {
                if (newValue === null) {
                  this.updateService("");
                } else if (!isEmpty(newValue) && !!newValue.value) {
                  this.updateService(newValue.value);
                }
              }}
              selectOptions={
                this.props.services && Array.isArray(this.props.services)
                  ? this.props.services.map(service => {
                      return {
                        value: snakeToPascal(service),
                        label: snakeToPascal(service),
                      };
                    })
                  : []
              }
              required
            />
          </div>
        ) : (
          <div className="editor-is-fetching mb-5-px">...fetching services</div>
        )}
      </div>
    );
  };

  getOperationsSelect = () => {
    return (
      <div className="editor-detail-panel editor-detail-panel-column p-0-px">
        <Header title="Operation" description="Operation" />
        {!this.props.isFetchingOperations ? (
          <div className="editor-aws-container">
            <ReactSelect
              id="aws-operations"
              name="aws-operations"
              customControlClass="mt-2-px"
              value={{
                value: this.props.selectedOperation
                  ? snakeToPascal(this.props.selectedOperation)
                  : "",
                label: this.props.selectedOperation
                  ? snakeToPascal(this.props.selectedOperation)
                  : "Select from below",
              }}
              isLoading={!this.props.operations?.length}
              handleChange={newValue => {
                if (newValue === null) {
                  this.updateOperation("");
                } else if (!isEmpty(newValue) && !!newValue.value) {
                  this.updateOperation(newValue.value);
                }
              }}
              selectOptions={
                this.props.operations && Array.isArray(this.props.operations)
                  ? this.props.operations.map(operation => {
                      return {
                        value: snakeToPascal(operation),
                        label: snakeToPascal(operation),
                      };
                    })
                  : []
              }
              required
            />
          </div>
        ) : (
          <div className="editor-is-fetching mb-5-px">
            ...fetching operations
          </div>
        )}{" "}
      </div>
    );
  };

  render() {
    return (
      <div className="d-flex flex-column ">
        <Accordion isExpanded={true} useArrow={true}>
          <AccordionSection className="mt-2">
            {this.getServicesSelect()}
            {this.getOperationsSelect()}
          </AccordionSection>
        </Accordion>
      </div>
    );
  }
}

const mapState = ({ actionNodeReducer }) => {
  return {
    operations: actionNodeReducer.operations,
    services: actionNodeReducer.services,
    servicesNeeded: actionNodeReducer.servicesNeeded,
    isFetchingOperations: actionNodeReducer.isFetchingOperations,
    isFetchingServices: actionNodeReducer.isFetchingServices,
    selectedService: actionNodeReducer.selectedService,
    selectedOperation: actionNodeReducer.selectedOperation,
    operationDetails: actionNodeReducer.operationDetails,
    isFetchingOperationDetails: actionNodeReducer.isFetchingOperationDetails,
  };
};

const mapDispatch = dispatch => ({
  setServicesNeeded: servicesNeeded =>
    dispatch({
      type: SERVICES_NEEDED,
      payload: servicesNeeded,
    }),
  fetchServices: () => dispatch(fetchServices()),
  setSelectedService: selectedService =>
    dispatch(setSelectedService(selectedService)),
  setSelectedOperation: selectedOperation =>
    dispatch(setSelectedOperation(selectedOperation)),
});

export default connect(mapState, mapDispatch)(AWSOperationSelector);
