import React from "react";
import "./InputResult.scss";

type InputResultProps = {
  displayValue?: string;
};

export const InputResult: React.FC<InputResultProps> = ({
  displayValue = "",
}) => {
  return (
    <div className="mb-10-px mt-10-px">
      <label className="label">Input Result</label>
      <div className="result">{displayValue}</div>
    </div>
  );
};
