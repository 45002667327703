import React from "react";
import { useSelector } from "react-redux";
import { useStore } from "react-flow-renderer";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  DropDown,
  LabelTop,
  DropDownOption,
} from "@components/ui";
import { updateLinks } from "./../../../../../runbook-editor-lib/runbook-editor.helpers";
import { getSnippetDisplayName } from "@containers/RunbookEditor/runbook-editor-components/editor-right-panel/input-selector/lib/input-selector.lib.js";

export default props => {
  const snippetDisplayNameMap = useSelector(
    state => state.snippetsReducer.snippetDisplayNameMap,
  );

  const rfStore = useStore();

  return (
    <Accordion isExpanded={true} useArrow={true}>
      <AccordionLabel className="editor-accordion-label">
        4. Destination
      </AccordionLabel>
      <AccordionSection>
        <div className="editor-detail-panel editor-detail-panel-column">
          <div className="editor-terraform-container">
            <LabelTop
              label={`Next Step If This Rule Is Met`}
              labelClassName="label"
            >
              <DropDown
                title={
                  (props.choice &&
                    getSnippetDisplayName(
                      props.choice.nextStep || "",
                      props.choice.ssmStep.runbook.mainStepIndex[
                        props.choice.nextStep
                      ]?.stepType,
                      snippetDisplayNameMap,
                    )) ||
                  ""
                }
                showArrow={true}
                iconLeft="icon-filter"
                style={{ width: "calc(100% - 8px)", marginTop: "0px" }}
                onChange={(newText, newValue) => {
                  props.choice.nextStep = newValue;
                  props.choice.ssmStep.runbook.updateDAGAtStep(
                    props.choice.ssmStep,
                  );

                  updateLinks(
                    props.activeNode,
                    props.choice.ssmStep,
                    props.addLinkToStep,
                    props.removeLinkToStep,
                    rfStore,
                  );
                  props.activeNode.extras.runbookNode.didUpdateStep({});
                }}
              >
                {props.choice.ssmStep.legalSuccessors().map(succ => (
                  <DropDownOption
                    text={getSnippetDisplayName(
                      succ.name,
                      succ.stepType,
                      snippetDisplayNameMap,
                    )}
                    value={succ.name}
                    key={succ.name}
                  />
                ))}
              </DropDown>
            </LabelTop>
          </div>
        </div>
      </AccordionSection>
    </Accordion>
  );
};
