import React, { useContext } from "react";
import { TextArea, AccordionLabel, AccordionSection } from "@components/ui";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import ReactSelect from "@components/ui/React-Select/ReactSelect";

const JiraOptionalFields = ({
  showIssueDetails,
  updateInputFieldValue,
  description,
  summary,
  transition,
  transitionsList,
  priority,
  prioritiesList,
}) => {
  const { notifyRunbookUpdate } = useContext(RunbookEditorContext);

  return (
    <div className="mt-20-px">
      <AccordionLabel className="editor-accordion-label mt-10-px">
        Optional Inputs
      </AccordionLabel>
      <AccordionSection>
        {showIssueDetails && (
          <div className="editor-detail-panel editor-detail-panel-column">
            <div className="editor-select-container">
              <label className="label">Description</label>
              <div id="update-jira-description" className="d-flex mb-2">
                <TextArea
                  onKeyUp={newValue =>
                    updateInputFieldValue(
                      newValue,
                      notifyRunbookUpdate,
                      "description",
                    )
                  }
                  value={
                    description && typeof description === "string"
                      ? description
                      : ""
                  }
                  className={"compact-text-area"}
                ></TextArea>
              </div>
              <label className="label">Summary</label>
              <div id="update-jira-summary" className="d-flex mb-2">
                <TextArea
                  onKeyUp={newValue =>
                    updateInputFieldValue(
                      newValue,
                      notifyRunbookUpdate,
                      "summary",
                    )
                  }
                  value={summary && typeof summary === "string" ? summary : ""}
                  className={"compact-text-area"}
                  maxLength={255}
                ></TextArea>
              </div>
              <label className="label">Transition to</label>
              <ReactSelect
                id="jira-transition"
                name="jira-transition"
                value={{
                  value:
                    transition && typeof transition === "string"
                      ? transition
                      : "",
                  label:
                    transition && typeof transition === "string"
                      ? transition
                      : "",
                }}
                handleChange={data => {
                  if(data === null){
                    updateInputFieldValue(
                      "",
                      notifyRunbookUpdate,
                      "transition",
                    );
                  } else if (data.value) {
                    updateInputFieldValue(
                      data.value,
                      notifyRunbookUpdate,
                      "transition",
                    );
                  }
                }}
                selectOptions={(transitionsList || []).map(item => ({
                  label: item,
                  value: item,
                }))}
              />
              <label className="label mt-2">Priority</label>
              <ReactSelect
                id="jira-transition"
                name="jira-transition"
                value={{
                  value:
                    priority && typeof priority === "string" ? priority : "",
                  label:
                    priority && typeof priority === "string" ? priority : "",
                }}
                handleChange={data => {
                  if(data === null){
                    updateInputFieldValue(
                      "",
                      notifyRunbookUpdate,
                      "priority",
                    );
                  } else if (data.value) {
                    updateInputFieldValue(
                      data.value,
                      notifyRunbookUpdate,
                      "priority",
                    );
                  }
                }}
                selectOptions={(prioritiesList || []).map(item => ({
                  label: item,
                  value: item,
                }))}
              />
            </div>
          </div>
        )}
      </AccordionSection>
    </div>
  );
};

export default JiraOptionalFields;
