import { isEmpty, hasKeys } from "@lib/utils";

export const getPortType = runbookNode => {
  if (!runbookNode || isEmpty(runbookNode)) return;

  let snippetDef = runbookNode?.snippetDef;

  if (snippetDef?.content?.isStart === true || snippetDef?.is_start === true) {
    return "outPortNode";
  } else if (
    snippetDef?.content?.isEnd === true ||
    snippetDef?.is_end === true
  ) {
    return "inPortNode";
  } else {
    return "defaultNode";
  }
};

export const setSnippetName = nodeObj => {
  let snippetName = "";
  /* start with the corner cases */
  if (nodeObj?.name) {
    if (nodeObj.name.match(/LoopForEach|Loop_For_Each/)) {
      snippetName = "loop";
    } else if (hasKeys(nodeObj.extras.runbookNode, "actionNodeDef")) {
      snippetName =
        nodeObj.extras.runbookNode.actionNodeDef?.name.toLowerCase() ||
        nodeObj.extras.runbookNode.name ||
        "action";
    } else if (hasKeys(nodeObj.extras.runbookNode, "snippetDef")) {
      snippetName =
        nodeObj.extras.runbookNode.snippetDef?.name.toLowerCase() ||
        nodeObj.extras.runbookNode.name;
    } else {
      snippetName = nodeObj.extras.runbookNode?.name || "action";
    }
  }
  nodeObj.snippetName = snippetName;
};

export const isTriggerNode = (runbookNode = null) =>
  runbookNode?.snippetDef?.service?.includes("Trigger");
