import React, { useState } from "react";
import clearIcon from "@assets/images/icons/icon-close.svg";
import SnippetList from "./utils/SnippetList";

const EditorAddSnippets = () => {
  const [searchVal, setSearchVal] = useState("");
  return (
    <div className="editor-slideout-content-container">
      <div className="editor-slideout-content-container-search">
        <input
          type="text"
          className="editor-slideout-content-container-search__input"
          value={searchVal}
          placeholder="Search Snippet"
          onChange={e => setSearchVal(e.target.value)}
          autoFocus
        />
        {!!searchVal && (
          <button
            className="search-clear"
            type="button"
            onClick={() => setSearchVal("")}
          >
            <img
              src={clearIcon}
              alt="clearIcon"
              className="editor-slideout-content-container-search__clear"
              width="10"
              height="10"
            />
          </button>
        )}
      </div>

      <div className="editor-slideout-content-container-area-actions">
        <SnippetList searchVal={searchVal} />
      </div>
    </div>
  );
};
export default EditorAddSnippets;
