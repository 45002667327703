import React, { useState, useEffect } from "react";
import "./Notifications.scss";
import TabWrapper from "../Shared/TabWrapper/TabWrapper";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { Formik } from "formik";
import * as Yup from "yup";
import { isEmpty, getBlacklistConnectorList } from "@lib/utils";
import { Button } from "@components/ui";
import { MultiSelect } from "carbon-components-react";
import { taskPrioritiesList } from "@lib/utils/constants.ts";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchSlackChannelList } from "@redux/actions/snippets.actions";
import Api from "@lib/api";
import { fetchSettingsConnectors } from "@redux/actions/settingsPanel.action";

const getValidationSchema = () => {
  return Yup.object({
    SlackDefaultChannel: Yup.string().required("Please select a slack channel"),
    IncidentPriorities: Yup.array().required(
      "Please select incident priorities",
    ),
    EnableSlackNotifications: Yup.boolean().required(
      "Please enable/disable notifications",
    ),
  });
};

type NotificationsProps = {};

const Notifications: React.FC<NotificationsProps> = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [initialValues, setInitialValues] = useState({
    SlackDefaultChannel: "",
    IncidentPriorities: "",
    EnableSlackNotifications: true,
  });
  const slackChannelList = useSelector(
    (state: RootStateOrAny) => state.snippetsReducer.slackChannelList,
  );
  const connectors = useSelector(
    (state: RootStateOrAny) => state.settingsPanelReducer.connectors,
  );

  const toggleNotifications = formik => {
    formik.setFieldValue(
      "EnableSlackNotifications",
      !formik.values.EnableSlackNotifications,
    );
  };

  const [isSlackEnabled, setIsSlackEnabled] = useState(false);

  useEffect(() => {
    if (!isEmpty(connectors)) {
      const isSlackConfigured = !getBlacklistConnectorList(connectors).includes(
        "slack",
      );
      setIsSlackEnabled(isSlackConfigured);
      if (isSlackConfigured) {
        (async () => {
          try {
            const response = await Api.fetchIncidentNotificationDetails();
            setInitialValues(response);
            setShowForm(true);
          } catch (e) {
            setShowForm(true);
          }
        })();
      } else {
        setShowForm(true);
      }
    }
  }, [connectors]);

  useEffect(() => {
    if (isSlackEnabled) {
      dispatch(fetchSlackChannelList());
    }
  }, [dispatch, isSlackEnabled]);

  useEffect(() => {
    if (isEmpty(connectors)) {
      dispatch(fetchSettingsConnectors());
    }
  }, [dispatch, connectors]);

  const onSubmitHandler = async (formik, actions) => {
    try {
      const values = await Api.updateIncidentNotificationDetails(formik);
      /**
       * Reset form to reset formik.dirty to false
       */
      actions.resetForm({ values });
    } catch (e) {
      console.log(e);
    }
  };
  return showForm ? (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      validationSchema={getValidationSchema()}
    >
      {formik => {
        const { touched, errors, handleSubmit } = formik;
        return (
          <TabWrapper
            showToggle={isSlackEnabled}
            isToggleOn={formik.values.EnableSlackNotifications}
            onChangeToggle={() => toggleNotifications(formik)}
            isNotConfigured={!isSlackEnabled}
            title="Incident Notifications (Via Slack)"
            description="Notify me about new incidents generated in Fylamynt"
          >
            <div
              className={`notifications ${
                !isSlackEnabled || !formik.values.EnableSlackNotifications
                  ? "disable"
                  : ""
              }`}
            >
              <div className="wrapper">
                <div className="mb-4">
                  <label className="label mb-2-px">
                    Slack Channel to receive notifications
                  </label>
                  <ReactSelect
                    id="runtime-param-alias"
                    name="runtime-param-alias"
                    value={{
                      value: formik.values.SlackDefaultChannel,
                      label: formik.values.SlackDefaultChannel
                        ? formik.values.SlackDefaultChannel
                        : "Select from below",
                    }}
                    handleChange={data => {
                      if (data === null) {
                        formik.setFieldValue("SlackDefaultChannel", "");
                      } else if (!isEmpty(data) && !!data.value) {
                        formik.setFieldValue("SlackDefaultChannel", data.value);
                      }
                    }}
                    selectOptions={slackChannelList?.channels?.map(channel => {
                      return {
                        value: channel,
                        label: channel,
                      };
                    })}
                    isSearchable={false}
                    required
                    isDisabled={
                      !isSlackEnabled || !formik.values.EnableSlackNotifications
                    }
                    customMenuClass="default-select-options-container"
                    customMenuListClass="default-select-options-list"
                    customValueContainerClass="default-select-value-container"
                    customControlClass="default-select-control"
                    customOptionClass="default-select-list-item"
                  />
                  {errors["SlackDefaultChannel"] &&
                  touched["SlackDefaultChannel"] ? (
                    <div className="input-feedback">
                      {errors["SlackDefaultChannel"]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-4">
                  <label className="label mb-2-px">
                    Send notifications for the following incident priorities
                  </label>
                  <MultiSelect
                    ariaLabel="MultiSelect"
                    default
                    id="IncidentPriorities"
                    onChange={({ selectedItems }) => {
                      formik.setFieldValue("IncidentPriorities", selectedItems);
                    }}
                    className={`settings-multiselect ${
                      !isSlackEnabled || !formik.values.EnableSlackNotifications
                        ? "disabled"
                        : ""
                    } ${false && "error"}`}
                    initialSelectedItems={
                      formik.values.IncidentPriorities || []
                    }
                    items={taskPrioritiesList}
                    label="Select incident priorities"
                    itemToString={item => (item ? item : "")}
                    disabled={
                      !isSlackEnabled || !formik.values.EnableSlackNotifications
                    }
                  />
                  {errors["IncidentPriorities"] &&
                  touched["IncidentPriorities"] ? (
                    <div className="input-feedback">
                      {errors["IncidentPriorities"]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <Button
              text="Save Changes"
              buttonStyle="primary"
              size="small"
              className="save-btn"
              disabled={!formik.dirty || !isSlackEnabled}
              onClick={handleSubmit}
            />
          </TabWrapper>
        );
      }}
    </Formik>
  ) : (
    <></>
  );
};

export default Notifications;
