import "./SnippetHeader.scss";
import React from "react";
import { getBackgroundImgSource } from "@lib/utils";

type SnippetHeaderProps = {
  snippetDef: any;
  displayName?: string;
  iconName?: any;
};

const SnippetHeader: React.FC<SnippetHeaderProps> = ({
  snippetDef,
  displayName,
  iconName,
}) => {
  const getHeader = () => {
    return (
      <div className="sh-container">
        <div className="d-flex align-items-center">
          <div className="sh-icon">
            <img
              src={getBackgroundImgSource(
                snippetDef?.display_name,
                iconName || snippetDef?.icon_name,
              )}
              alt="inputIcon"
            />
          </div>
          <div className="d-flex flex-column">
            <span className="sh-label">
              {displayName || snippetDef?.display_name || ""}
            </span>
            <span className="sh-id">{snippetDef?.name || ""}</span>
          </div>
        </div>
      </div>
    );
  };

  return <>{getHeader()}</>;
};

export default SnippetHeader;
