import React from "react";
import { Button } from "@components/ui";

export default props => (
  <Button
    buttonStyle="primary"
    type="button"
    onClick={props.saveClickHandler}
    className="button-save"
    disabled={props.disabled}
  >
    <div>Save {props.version}</div>
    <div className="btn-svg btn-svg__save"></div>
  </Button>
);
