import React from "react";
import { Modal } from "@components/ui";
import { JsonView } from "@components/ui";

export default class JsonContentModal extends React.Component {
  closeModal = () => {
    console.log("close");
  };

  getFooter = () => {
    return <div>Footer...</div>;
  };

  render() {
    return (
      <Modal
        onClose={this.props.closeModal}
        title={"Workflow Content"}
        showClose={true}
        appendToPortal={true}
        coverClass={`modal-cover__editor`}
        containerClass={`modal-container__editor`}
        contentClass={`modal-content__editor-json`}
        contentContainerClass={`modal-content-container__editor-json`}
        titleClass={`modal-title__json`}
        closeClass={`modal-close__editor`}
        showFooter={false}
      >
        <JsonView data={this.props.json} title={""}></JsonView>
      </Modal>
    );
  }
}
