import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInstanaEntityTypes,
  fetchInstanaEntityNames,
} from "@redux/actions/snippets.actions";

import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import ReactSelect from "@components/ui/React-Select/ReactSelect";

export function InstanaAlertInput(props) {
  const dispatch = useDispatch();
  const [entityType, setEntityType] = useState(props.runbookNode.entityType);
  const [entityName, setEntityName] = useState(props.runbookNode.entityName);
  const [alertType, setAlertType] = useState(props.runbookNode.alertType);
  const [eventId, setEventId] = useState(props.runbookNode.eventId);
  const alertTypes = ["onOpen", "onClose", "onChange"];
  const { runbookNode, notifyRunbookUpdate, setOutputs } = props;

  const isFetchingInstanaEntityTypes = useSelector(
    state => state.snippetsReducer.isFetchingInstanaEntityTypes,
  );
  const instanaEntityTypes = useSelector(
    state => state.snippetsReducer.instanaEntityTypes,
  );
  const isFetchingInstanaEntityNames = useSelector(
    state => state.snippetsReducer.isFetchingInstanaEntityNames,
  );
  const instanaEntityNames = useSelector(
    state => state.snippetsReducer.instanaEntityNames,
  );

  const selectEntityType = entityType => {
    dispatch(fetchInstanaEntityNames(entityType));
    setEntityType(entityType);
  };

  const selectEntityName = entityName => {
    setEntityName(entityName);
    setEventId(instanaEntityNames[entityName]);
  };

  const selectAlertType = alertType => {
    setAlertType(alertType);
  };

  const isFirstRunA = useRef(true);
  useEffect(() => {
    if (isFirstRunA.current) {
      isFirstRunA.current = false;
      return;
    } else {
      runbookNode.setEntityType(entityType);
      runbookNode.setEntityName(entityName);
      runbookNode.setEventId(eventId);

      if (runbookNode.outputs) {
        setOutputs(runbookNode.outputs);
      }
      notifyRunbookUpdate(true);
    }
  }, [
    eventId,
    entityType,
    entityName,
    notifyRunbookUpdate,
    runbookNode,
    setOutputs,
  ]);

  const isFirstRunB = useRef(true);
  useEffect(() => {
    if (isFirstRunB.current) {
      isFirstRunB.current = false;
      return;
    } else {
      runbookNode && runbookNode.setAlertType(alertType);
      if (runbookNode?.outputs) {
        setOutputs(runbookNode.outputs);
      }
      notifyRunbookUpdate(true);
    }
  }, [alertType, notifyRunbookUpdate, runbookNode, setOutputs]);

  useEffect(() => {
    dispatch(fetchInstanaEntityTypes());
  }, [dispatch]);

  const isFirstRunC = useRef(true);
  useEffect(() => {
    if (isFirstRunC.current) {
      isFirstRunC.current = false;
      dispatch(fetchInstanaEntityNames(entityType));
      return;
    } else {
      setEntityName("");
      setEventId("");
    }
  }, [entityType, dispatch]);

  return (
    <React.Fragment>
      <h4 className="editor-node-name">
        {(runbookNode && runbookNode.name) || "Instana_Alert"}
      </h4>
      <React.Fragment>
        <Accordion isExpanded={true} useArrow={true}>
          <AccordionLabel className="editor-accordion-label mt-10-px">
            Required Inputs
          </AccordionLabel>
          <AccordionSection>
            <div className="editor-detail-panel editor-detail-panel-column">
              <div className="editor-select-container">
                <label className="label">Alert Type</label>
                <ReactSelect
                  id="alert-type"
                  name="alert-type"
                  required
                  value={{
                    value: alertType || "",
                    label: alertType || "",
                  }}
                  handleChange={data => {
                    if(data === null){
                      selectAlertType("");
                    } else if (data.value) {
                      selectAlertType(data.value);
                    }
                  }}
                  selectOptions={(alertTypes || []).map(item => ({
                    label: item,
                    value: item,
                  }))}
                />
                {!isFetchingInstanaEntityTypes ? (
                  <>
                    <label className="label mt-2">Entity Types</label>
                    <ReactSelect
                      id="entity-type"
                      name="entity-type"
                      required
                      value={{
                        value: entityType || "",
                        label: entityType || "",
                      }}
                      handleChange={data => {
                        if(data === null){
                          selectEntityType("");
                        } else if (data.value) {
                          selectEntityType(data.value);
                        }
                      }}
                      selectOptions={(instanaEntityTypes || []).map(item => ({
                        label: item,
                        value: item,
                      }))}
                    />
                  </>
                ) : (
                  <div className="mb-10-px">Fetching entity types...</div>
                )}
                {entityType &&
                  (!isFetchingInstanaEntityNames && instanaEntityNames ? (
                    <>
                      <label className="label mt-2">Entity Names</label>
                      <ReactSelect
                        id="entity-name"
                        name="entity-name"
                        required
                        value={{
                          value: entityName || "",
                          label: entityName || "",
                        }}
                        handleChange={data => {
                          if(data === null){
                            selectEntityName("");
                          } else if (data.value) {
                            selectEntityName(data.value);
                          }
                        }}
                        selectOptions={(
                          instanaEntityNames && Object.keys(instanaEntityNames)
                        ).map(item => ({
                          label: item,
                          value: item,
                        }))}
                      />
                    </>
                  ) : (
                    <div className="mb-10-px">Fetching entity names...</div>
                  ))}
                <div className="label">
                  <span>Event Id: </span>
                  <span>{eventId}</span>
                </div>
              </div>
            </div>
          </AccordionSection>
        </Accordion>
      </React.Fragment>
    </React.Fragment>
  );
}
