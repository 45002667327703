import React from "react";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { InputSelector } from "@containers/RunbookEditor/runbook-editor-components/editor-right-panel/input-selector";
import { getSelectedOption } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.helpers";

export default props => (
  <Consumer>
    {activeNode => {
      const input = props.choice && props.choice.inputs()[0];
      console.log(input);
      return (
        <Accordion isExpanded={true} useArrow={true}>
          <AccordionLabel className="editor-accordion-label">
            1. Pick variable
          </AccordionLabel>
          <AccordionSection>
            {
              //variable to evaluate
            }
            <InputSelector
              optionFilterId={props.optionFilterId}
              label="Variable to evaluate"
              toggleInputOption={props.toggleInputOption}
              input={input}
              selectedOption={getSelectedOption(input)}
              key={`${input.name}-0`}
              isConditionalVariableInput={true}
              updateSelectedVariableType={props.updateSelectedVariableType}
            />
          </AccordionSection>
        </Accordion>
      );
    }}
  </Consumer>
);
