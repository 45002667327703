import React from "react";
import { TextInput } from "@components/ui";
import { getInputLimit } from "@containers/RunbookEditor/runbook-editor-lib/ssm/util";

export default props => {
  return (
    <TextInput
      disabled
      description={props.input?.description}
      helpText={props.input?.help_text}
      name={props.input.name}
      style={{ width: "100%" }}
      label={props.label || props.input.name}
      labelPosition="top"
      className="rule-input rule-input__cursor-na"
      labelClassName="mb-1"
      value={"Value will be set at runtime"}
      maxLength={getInputLimit(props.input.name)}
    />
  );
};
