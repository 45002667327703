import React from "react";
import * as utils from "@lib/utils";
import {
  RunbooksHeader,
  RunbooksTabs,
  RunbooksCreate,
  RunbooksDuplicate,
} from "./runbooks-components";
import { Confirm, FTNotification } from "@components/ui";
import { Provider } from "./runbooks-lib/runbooks.context";
import "./Runbooks.scss";
import * as helpers from "./runbooks-lib/runbooks.helpers";
import { Redirect } from "react-router-dom";
import { Modal } from "@components/ui";
import WelcomeDialog from "@assets/images/screenshots/welcome-dialog/WelcomeDialog.jpg";
import NoAccountWarning from "@components/ui/WarningSign/NoAccountWarning";
import LimitDialog from "@containers/WarningDialogs/LimitDialog";
import { limitExceeded } from "../../utils/common";
import { RouteConstants } from "../../routes/Constants";

const { runbookOwners } = require("@config/constants.json");

export default class Runbooks extends React.PureComponent {
  timer = null;

  state = {
    showRunbooksCreate: false,
    runbookNew: false,
    doRedirect: false,
    redirectUrl: null,
    selectedTabOwner: runbookOwners.self,
    confirmValue: "",
    originalValue: "",
    cancelText: "Cancel",
    confirmMessage: "",
    confirmRequiredText: "",
    waitMessage: "",
    isPreparing: this.props.showLoading,
    runbooksErrorMessage: "",
    retries: 0,
    maxRetries: 5,
    showRunbooksDuplicate: false,
    baseRunbookName: "",
    isWelcomeDialogVisible: false,
    sortType: null,
    isWorkflowLimitDialogVisible: false,
    selectedCategories: {},
  };

  componentDidMount() {
    utils.setTitle("Fylamynt | Workflows");
    this.props && this.props.fetchRunbooks(this.state.selectedTabOwner);
    this.props.disableSplashScreen(true);
    /**
     * Used while creating a WF
     */
    this.props.fetchTriggers();
  }

  componentDidUpdate(prevProps, prevState) {
    this.showWait(prevProps);
    this.doSearch(prevProps);
    if (
      prevProps?.isAccountDetailFetched !== this.props.isAccountDetailFetched
    ) {
      if (
        this.props.userDefaultAccounts?.length <= 0 &&
        this.props.firstLogin
      ) {
        this.setState({ isWelcomeDialogVisible: true }, () => {
          this.props && this.props.toggleFirstLogin(false);
        });
      }
    }
  }

  showWait(prevProps) {
    if (prevProps.runbookIsDeleting !== this.props.runbookIsDeleting) {
      if (this.props.runbookIsDeleting) {
        this.setState({
          isPreparing: true,
          waitMessage: this.props.deletingMessage,
        });
      } else {
        this.setState({
          isPreparing: false,
          waitMessage: "",
        });
      }
    } else if (prevProps?.showLoading !== this.props.showLoading) {
      this.setState({
        isPreparing: this.props.showLoading,
        waitMessage: this.props.message,
      });
    }
  }

  setActiveRunbook = e => {
    let owner = e === "my-workflows" ? "Self" : "Fylamynt";
    // this.runbooksLengthCheck(owner);
    // reset search on changing tabs
    this.setState(
      {
        selectedTabOwner: owner,
      },
      () => this.searchAndSort(),
    );
  };

  runbooksLengthCheck = owner => {
    if (this.props.runbookList[owner].length === 0) {
      this.props && this.props.fetchRunbooks(owner);
    }
  };

  openCloseRunbookModal = async () => {
    if (this.state.showRunbooksCreate === false) {
      /**
       * Reset previously selected trigger while
       * creating a New WF
       */
      this.props.setSelectedTriggerForWF(null);
      try {
        await this.props.fetchAccountPlanInfo();
        if (
          limitExceeded(
            this.props.quotas?.workflows?.current_usage,
            this.props.quotas?.workflows?.limit,
          )
        ) {
          this.toggleWorkflowLimitDialog();
        } else {
          this.setState({ showRunbooksCreate: !this.state.showRunbooksCreate });
        }
      } catch (e) {
        FTNotification.error({
          title: "Could not fetch Quotas",
        });
      }
    } else {
      this.setState({ showRunbooksCreate: !this.state.showRunbooksCreate });
    }
  };

  toggleDuplicateRunbookModal = () => {
    this.setState({
      showRunbooksDuplicate: !this.state.showRunbooksDuplicate,
    });
  };

  openDuplicateRunbookModal = async runbook => {
    try {
      await this.props.fetchAccountPlanInfo();
      if (
        limitExceeded(
          this.props.quotas?.workflows?.current_usage,
          this.props.quotas?.workflows?.limit,
        )
      ) {
        this.toggleWorkflowLimitDialog();
      } else {
        this.setState({
          showRunbooksDuplicate: true,
          baseRunbookName: runbook.Name,
        });
      }
    } catch (e) {
      FTNotification.error({
        title: "Could not fetch Quotas",
      });
    }
  };

  renderConfirmDelete = async runbook => {
    this.setState({
      confirmHeading: "Delete Confirmation",
      confirmMessage: `The workflow “${utils.replaceAllSpecialCharWithSpace(
        runbook.Name,
      )}“ will be deleted permanently. If the workflow has already been assigned as default to a incident type, then that assignment will be removed after this workflow deletion.`,
      confirmCancelText: "Cancel",
      confirmConfirmText: "Delete",
      confirmValue: utils.replaceAllSpecialCharWithSpace(runbook.Name),
      originalValue: runbook.Name,
      confirmRequiredText: `Confirm by typing '${utils.replaceAllSpecialCharWithSpace(
        runbook.Name,
      )}' below`,
      confirmIsLoading: true,
    });
    const d =
      this.props && (await this.props.fetchRunbookSchedules(runbook.Name));
    this.setState({
      confirmIsLoading: false,
      confirmConfirmText: d?.length ? `Delete (with all schedules)` : `Delete`,
    });
    this.props && this.props.setShowConfirm(true);
  };

  deleteRunbook = runbook => {
    this.props && this.props.setShowConfirm(false);
    this.props &&
      this.props.deleteRunbook(runbook, this.state.selectedTabOwner);
  };

  doSearch(prevProps) {
    if (
      prevProps?.showLoading !== this.props.showLoading &&
      !this.props.showLoading
    ) {
      if (this.props.runbookSearchTerm) {
        this.searchAndSort();
      }
    }
  }

  searchAndSort = (searchTerm = this.props.runbookSearchTerm) => {
    if (typeof searchTerm === "object") searchTerm = searchTerm?.search;

    this.props &&
      this.props.searchAndSort(
        searchTerm,
        this.state.selectedTabOwner,
        this.state.sortType,
        this.props.runbookList,
        helpers.resortRunbooksList,
        this.state.selectedCategories,
      );
  };

  handleFilterSelection = input => {
    let tempSelectedCategories = this.state.selectedCategories;
    tempSelectedCategories[this.state.selectedTabOwner] = input;
    this.setState({
      selectedCategories: tempSelectedCategories,
    });
    this.searchAndSort();
  };

  getRunbooksList = isLoading => {
    if (isLoading) {
      return [];
    }
    let runbooksList;
    if (
      this.props.runbookSearchTerm ||
      this.state.sortType ||
      this.state.selectedCategories[this.state.selectedTabOwner]?.length > 0
    ) {
      runbooksList = this.props.runbookListFiltered[
        this.state.selectedTabOwner
      ];
    } else {
      runbooksList = this.props.runbookList[this.state.selectedTabOwner];
    }
    return runbooksList;
  };

  connectAWSAccount = () => {
    this.setState({
      doRedirect: true,
      redirectUrl: RouteConstants.aws.url,
    });
  };

  toggleWelcomeDialog = () => {
    this.setState({
      isWelcomeDialogVisible: !this.state.isWelcomeDialogVisible,
    });
  };

  resort = sortType => {
    this.setState(
      {
        sortType,
      },
      () => this.searchAndSort(),
    );
  };

  goToProfilePage = () => {
    this.setState({
      doRedirect: true,
      redirectUrl: {
        pathname: `${RouteConstants.userProfile.baseUrl}/account-plan`,
      },
    });
  };

  toggleWorkflowLimitDialog = () => {
    this.setState({
      isWorkflowLimitDialogVisible: !this.state.isWorkflowLimitDialogVisible,
    });
  };

  render() {
    const runbooksList = this.getRunbooksList(this.state.isPreparing);
    const contextValue = {
      handleChange: this.setActiveRunbook,
      toggleRunbookModal: this.openCloseRunbookModal,
      reSort: sortBy => this.resort(sortBy),
      runbooksList: runbooksList,
      runbooks: this.props.runbookList[this.state.selectedTabOwner],
      searchAndSort: this.searchAndSort,
      handleFilterSelection: this.handleFilterSelection,
      sortType: this.state.sortType,
      runbookSearchTerm: this.props.runbookSearchTerm,
      setShowConfirm: this.renderConfirmDelete,
      openDuplicateRunbookModal: this.openDuplicateRunbookModal,
      activeTabOwner: this.state.selectedTabOwner,
      categorySelected: this.state.selectedCategories,
    };
    return (
      <>
        <Provider value={contextValue}>
          {this.state.doRedirect && (
            <Redirect to={this.state.redirectUrl} push />
          )}
          {this.state.showRunbooksCreate && <RunbooksCreate />}
          {this.state.showRunbooksDuplicate && (
            <RunbooksDuplicate
              toggleDuplicateRunbookModal={this.toggleDuplicateRunbookModal}
              baseRunbookName={this.state.baseRunbookName}
            />
          )}
          {this.props.showConfirm && (
            <Confirm
              heading={this.state.confirmHeading}
              cancelText={this.state.cancelText}
              confirmText={this.state.confirmConfirmText}
              message={this.state.confirmMessage}
              confirmValue={this.state.confirmValue}
              originalValue={this.state.originalValue}
              confirmRequiredText={this.state.confirmRequiredText}
              verifiedCallback={val => this.deleteRunbook(val)}
              isLoading={this.state.confirmIsLoading}
            />
          )}
          {this.state.isWorkflowLimitDialogVisible && (
            <LimitDialog
              limitType={"workflow"}
              goTo={this.goToProfilePage}
              toggleDialog={this.toggleWorkflowLimitDialog}
            />
          )}
          {this.state.isWelcomeDialogVisible && (
            <Modal
              onClose={this.toggleWelcomeDialog}
              title="Welcome to Fylamynt"
              showClose={true}
              onCancel={this.toggleWelcomeDialog}
              onSubmit={this.connectAWSAccount}
              cancelButtonText={`Setup Later`}
              submitButtonText={`Connect AWS Account`}
              contentClass={`welcome-content`}
              containerClass={`welcome-container`}
            >
              <div className="welcome-dialog">
                <div className="welcome-dialog__img-wrap">
                  <img alt="welcome" src={WelcomeDialog}></img>
                </div>
                <span>
                  Seamlessly integrate easy-to-use pre-defined connectors to
                  construct time-saving workflows including everyday tasks to
                  enterprise infrastructure. To start using Fylamynt, you'll
                  need to connect your AWS Account.
                </span>
              </div>
            </Modal>
          )}
          <div className="d-flex flex-column">
            {this.state.runbooksErrorMessage ? (
              <div>{this.state.runbooksErrorMessage}</div>
            ) : (
              <>
                <RunbooksHeader />
                {this.props.isAccountDetailFetched &&
                  !this.props.userDefaultAccounts.length && (
                    <NoAccountWarning fromComponent="Runbooks" />
                  )}
                <RunbooksTabs isWorkflow={true} />
              </>
            )}
          </div>
          {this.props.runbookNew && this.props.isNewRunbook && (
            <Redirect to={`/workflow/editor/${this.props.runbookNew}`} push />
          )}
          {this.props.runbookDuplicate &&
            (window.location.href = `/workflow/${this.props.runbookDuplicate}/parameters`)}
        </Provider>
      </>
    );
  }
}
