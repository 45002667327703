import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import "./ReactCSSTransition.scss";

const ReactCSSTransition = (props: any) => {
  return (
    <ReactCSSTransitionGroup
      transitionName={props.panel}
      transitionEnterTimeout={500}
      transitionLeaveTimeout={1}
    >
      {props.children}
    </ReactCSSTransitionGroup>
  );
};

export default ReactCSSTransition;
