import { Parameter } from "../ssm/parameters";
import { getParamInput } from "../SnippetDefNew/utils/Helper";

export const getShowPriorityError = (
  paramInputs,
  atLeastOneList,
  mutuallyExclusiveList,
) => {
  let showAtLeastOneError = false;
  let showMutuallyExclusiveError = false;
  let errorInputs = [];
  let priorityError = "";
  if (atLeastOneList) {
    let parameterInputs = paramInputs.filter(i => {
      let condition = atLeastOneList?.includes(i.name);
      if (condition) errorInputs.push(i?.display_name);
      return condition;
    });
    let boolList = atLeastOneList.reduce((acc, i) => {
      let x = getParamInput(i, parameterInputs)?.source?.sourceValue;
      if (x === null || x === undefined || x === "") {
        acc.push(false);
      } else if (Array.isArray(x) && x.length === 0) {
        acc.push(false);
      } else {
        acc.push(true);
      }
      return acc;
    }, []);
    showAtLeastOneError = !boolList.some(b => b);
    priorityError = `At least one of ${errorInputs.join(
      ", ",
    )} has to be provided.`;
  }
  if (mutuallyExclusiveList) {
    let parameterInputs = paramInputs.filter(i => {
      let condition = mutuallyExclusiveList?.includes(i.name);
      if (condition) errorInputs.push(i?.display_name);
      return condition;
    });
    let boolList = mutuallyExclusiveList.reduce((acc, i) => {
      let x = getParamInput(i, parameterInputs)?.source?.sourceValue;
      if (x === null || x === undefined || x === "") {
        acc.push(false);
      } else if (Array.isArray(x) && x.length === 0) {
        acc.push(false);
      } else {
        acc.push(true);
      }
      return acc;
    }, []);
    let boolCount = 0;
    boolList.forEach(b => {
      if (b) boolCount = boolCount + 1;
    });
    if (boolCount === 1) {
      showMutuallyExclusiveError = false;
    } else {
      showMutuallyExclusiveError = true;
    }
    priorityError = `Inputs ${errorInputs.join(
      ", ",
    )} are mutually exclusive. Please choose only one of them.`;
  }

  return {
    showPriorityError: showAtLeastOneError || showMutuallyExclusiveError,
    priorityError,
  };
};

export const _isHealthyWithPriorityErrors = (
  parameterInputs,
  atLeastOneList,
  mutuallyExclusiveList,
) => {
  let { showPriorityError } = getShowPriorityError(
    parameterInputs,
    atLeastOneList,
    mutuallyExclusiveList,
  );
  let boolList = [];
  parameterInputs
    .filter(
      i =>
        !atLeastOneList?.includes(i.name) ||
        !mutuallyExclusiveList?.includes(i.name),
    )
    .forEach(param => {
      let sourceValue = param.source.sourceValue;
      if (param.required && (sourceValue === null || !sourceValue)) {
        boolList.push(false);
      }
    });
  boolList.push(!showPriorityError);
  return boolList.every(Boolean);
};

export function _isHealthyStringTransformers(parameterInputs) {
  let boolList = [];
  parameterInputs = parameterInputs.filter(item => item.name !== "alias");
  parameterInputs.forEach(param => {
    let sourceValue = param.source.sourceValue;
    if (
      param.required &&
      (sourceValue === null || sourceValue instanceof Parameter || !sourceValue)
    ) {
      boolList.push(false);
    }
  });
  return boolList.every(Boolean);
}

export const _isHealthyTriggerService = (parameterInputs = []) => {
  let boolList = [];

  parameterInputs
    .filter(param => !param.hidden)
    .forEach(param => {
      let sourceValue = param.source.sourceValue;
      if (param.required && (sourceValue === null || !sourceValue)) {
        boolList.push(false);
      }
    });
  return boolList.every(Boolean);
};
