import * as Yup from "yup";
import { getRuntimeParametersValidationSchema } from "./Helpers";

export const getValidationSchema = function (currentStep, requiredParameters) {
  if (currentStep === 1) {
    return Yup.object().shape({
      name: Yup.string().required("Please enter name"),
      description: Yup.string().required("Please enter description"),
      task_type: Yup.object().required("Please select incident type"),
      task_priority: Yup.string().required("Please select incident priority"),

      workflow: Yup.object(),
    });
  } else if (currentStep === 2) {
    return getRuntimeParametersValidationSchema(requiredParameters);
  } else if (currentStep === 3) {
    return Yup.object().shape({
      schedule_expression: Yup.string().required(
        "Please enter schedule expression",
      ),
    });
  }
};
