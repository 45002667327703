export const InputChoices = [
  { label: "Set value now", value: "setValueNow", choice: "constant" },
  {
    label: "Set value from a previous step",
    value: "setValueFromPrevious",
    choice: "actionNode",
  },
  {
    label: "Set value at runtime",
    value: "setValueNow",
    choice: "userProvided",
  },
];
