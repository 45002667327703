import React, { useContext, useEffect } from "react";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { Input, Output, Details } from ".";
import { useDispatch, useSelector } from "react-redux";
import { hideGlobalLoader } from "@redux/actions/globalLoader.actions";
import InputFields from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/InputFields/InputFields";

const SnippetPanel = props => {
  const runbookEditorContext = useContext(RunbookEditorContext);
  const { activeNode } = runbookEditorContext;
  const { snippetDef } = activeNode.extras.runbookNode;
  const version = snippetDef?.version || "1.0.0";
  const loaderState = useSelector(state => state.globalLoaderReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaderState.loaderFlag && version !== "2.0.0") {
      dispatch(hideGlobalLoader());
    }
  }, [loaderState, dispatch, version]);

  return (
    <TabCollection
      active="input"
      activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
      inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
      contentTop={44}
      tabs={["input", "output", "details"]}
    >
      <Tab title="Input" name="input">
        <TabContent className="input-tab">
          {version === "2.0.0" ? (
            <InputFields activeNode={activeNode} />
          ) : (
            <Input inputOptions={props.inputOptions} activeNode={activeNode} />
          )}
        </TabContent>
      </Tab>
      <Tab title="Output" name="output">
        <TabContent>
          <Output />
        </TabContent>
      </Tab>
      <Tab title="Details" name="details">
        <TabContent>
          <Details />
        </TabContent>
      </Tab>
    </TabCollection>
  );
};

export default SnippetPanel;
