import React, { useState } from "react";
import { TextInput } from "@components/ui";
import "./Boolean.scss";

type BooleanListProps = {
  title?: string;
  value: boolean;
  isRequired: boolean;
  showPriorityError?: any;
  onChange: (value) => void;
  description: string;
};

const Boolean: React.FC<BooleanListProps> = ({
  title = "Boolean",
  value = "",
  isRequired = false,
  showPriorityError,
  onChange,
  description,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [boolValue, setBoolValue] = useState(`${value}`);
  const handleDropdownClick = e => {
    e.target.blur();
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleOptionClick = bool => {
    setBoolValue(bool);
    setIsDropdownVisible(!isDropdownVisible);
    onChange(bool);
  };

  const handleCloseIconClick = () => {
    setBoolValue("");
    setIsDropdownVisible(false);
  };

  return (
    <div className="bool">
      <div className="bool-container">
        <TextInput
          name={title}
          id={title}
          placeholder={`Select value`}
          label={title}
          labelClassName="mb-1"
          labelPosition="top"
          className={`rule-input 
            ${
              showPriorityError !== null
                ? showPriorityError && "rule-input-error"
                : !boolValue && isRequired
                ? "rule-input-error"
                : ""
            }
          `}
          value={boolValue}
          readOnly={true}
          onClick={handleDropdownClick}
          description={description}
        />
        {!!boolValue && (
          <div
            className="bool-container-close"
            onClick={handleCloseIconClick}
          ></div>
        )}
        <div
          className="bool-container-arrow"
          onClick={handleDropdownClick}
        ></div>
      </div>

      {isDropdownVisible && (
        <div className="bool-overlay" onClick={handleDropdownClick}></div>
      )}
      {isDropdownVisible && (
        <div className="bool-dropdown">
          <div
            className={`bool-dropdown-option ${
              boolValue === "true" ? "bool-dropdown-option-selected" : ""
            }`}
            onClick={() => handleOptionClick("true")}
          >
            <span>true</span>
            {boolValue === "true" && (
              <div className="bool-dropdown-option-selected-check"></div>
            )}
          </div>
          <div
            className={`bool-dropdown-option ${
              boolValue === "false" ? "bool-dropdown-option-selected" : ""
            }`}
            onClick={() => handleOptionClick("false")}
          >
            <span>false</span>
            {boolValue === "false" && (
              <div className="bool-dropdown-option-selected-check"></div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Boolean;
