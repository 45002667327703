import React from "react";
import * as TimeSeriesD3 from "./timeseries.d3";
import "./timeseries.scss";

class TimeSeries extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.renderVisualization();
    }
  }

  renderVisualization() {
    if (!this.props.data) {
      return;
    }
    const element = this.ref.current;
    TimeSeriesD3.render(element, this.props.data);
  }

  render() {
    return <div ref={this.ref} className="time-series" />;
  }
}

export default TimeSeries;
