import React from "react";
import { filterConnectorTriggerTags } from "@lib/utils";
import "./PanelLogos.scss";

export default props => {
  const filteredTags = filterConnectorTriggerTags(props.tags);
  const getBackgroundImg = (name, index) => {
    if (!name) {
      return {};
    }
    let imgURL = "";
    try {
      const formattedName = name.toLowerCase();
      imgURL = require(`@assets/images/snippets/icon-${formattedName}.svg`);
    } catch (e) {
      imgURL = require(`@assets/images/snippets/icon-loop.svg`);
    }
    return {
      backgroundImage: `url(${imgURL})`,
      marginLeft: index ? -8 : 0,
      zIndex: 100 - index,
    };
  };

  const getArrowImg = () => {
    try {
      const imgURL = require(`@assets/images/snippets/arrow-right.svg`);
      return {
        backgroundImage: `url(${imgURL})`,
        zIndex: 999,
      };
    } catch (e) {}
  };

  return (
    <div className="services-logos">
      {filteredTags.trigger && (
        <>
          <div
            className="wf-card__logo"
            style={getBackgroundImg(filteredTags.trigger, 0)}
          />
          {filteredTags.connectors.length > 0 && (
            <div className="arrow" style={getArrowImg()}></div>
          )}
        </>
      )}
      {filteredTags.connectors.slice(0, 3).map((connector, i) => (
        <div className="wf-card__logo" style={getBackgroundImg(connector, i)} />
      ))}
      {filteredTags.connectors.length > 3 && (
        <div className="show-more__text" style={{ lineHeight: "2rem" }}>
          {`+${filteredTags.connectors.length - 3}`}
        </div>
      )}
    </div>
  );
};
