import union from "lodash/union";
import uniq from "lodash/uniq";
import flatMap from "lodash/flatMap";
import reverse from "lodash/reverse";

export class DirectedAcyclicGraph {
  constructor(edges) {
    this._edges = edges || {};
    this._reverse = reverseEdges(this._edges);
  }

  renameNode(oldName, newName) {
    this._edges[newName] = this._edges[oldName];
    delete this._edges[oldName];
    this._reverse[newName] = this._reverse[oldName];
    delete this._reverse[oldName];

    let node = "";
    for (node of Object.keys(this._edges)) {
      if (this._edges[node]) {
        this._edges[node] = this._edges[node].map(n =>
          n === oldName ? newName : n,
        );
      }
    }
    for (node of Object.keys(this._reverse)) {
      if (this._reverse[node]) {
        this._reverse[node] = this._reverse[node].map(n =>
          n === oldName ? newName : n,
        );
      }
    }
  }

  addNode(node, children) {
    this._edges[node] = union(this._edges[node] || [], children || []);
    if (children) {
      let child;
      for (child of children) {
        // All children must be nodes, so add them
        this._edges[child] = this._edges[child] || [];

        // then add the reverse lookup
        this._reverse[child] = this._reverse[child] || [];
        this._reverse[child].push(node);
      }
    }
  }

  addEdge(source, target) {
    let oldEdges = [];
    this._edges = makeIterable(this._edges);
    this._reverse = makeIterable(this._reverse);
    try {
      oldEdges = [...this._edges];
    } catch (e) {
      console.log(e);
    }
    const oldReverse = [...this._reverse];

    this._edges[source] = this._edges[source] || [];
    this._reverse[target] = this._reverse[target] || [];
    this._edges[source].push(target);
    this._reverse[target].push(source);
    this._edges[source] = uniq(this._edges[source]);
    this._reverse[target] = uniq(this._reverse[target]);

    const backs = this.backEdges();
    if (backs.length) {
      // We added a loop! Rollback.
      this._edges = oldEdges;
      this._reverse = oldReverse;
      throw new FoundLoop(backs);
    }
  }

  removeEdge(source, target) {
    if (this.hasEdge(source, target)) {
      this._edges[source] = this._edges[source].filter(t => t !== target);
      this._reverse[target] = this._reverse[target].filter(s => s !== source);
    }
  }

  hasEdge(source, target) {
    return !!(
      this._edges[source] && this._edges[source].find(n => n === target)
    );
  }

  successors(node) {
    const children = this._edges[node] || [];
    // Base case for recursion
    if (!children.length) {
      return [];
    }
    return uniq(
      children.concat(flatMap(children.map(child => this.successors(child)))),
    );
  }

  predecessors(node) {
    const children = this._reverse[node] || [];
    // Base case for recursion
    if (!children.length) {
      return [];
    }
    return uniq(
      children.concat(flatMap(children.map(child => this.predecessors(child)))),
    );
  }

  isSuccessor(node, successor) {
    const children = this._edges[node];
    // Breadth first...
    return (
      !!children.find(child => child === successor) ||
      children.find(child => this.isSuccessor(child, successor))
    );
  }

  isPredecessor(node, successor) {
    const parents = this._reverse[node];
    // Breadth first...
    return (
      !!parents.find(parent => parent === successor) ||
      parents.find(parent => this.isSuccessor(parent, successor))
    );
  }

  children(node) {
    return this._edges[node];
  }

  parents(node) {
    return this._reverse[node];
  }

  _dfs(startVisitAction, finishVisitAction, backEdgeAction) {
    const colorings = { whiteNodes: {}, grayNodes: {}, blackNodes: {} };
    let node;
    for (node of Object.keys(this._edges)) {
      colorings.whiteNodes[node] = true;
    }
    for (node of Object.keys(this._edges)) {
      if (colorings.whiteNodes[node]) {
        this._dfsVisit(
          node,
          colorings,
          startVisitAction,
          finishVisitAction,
          backEdgeAction,
        );
      }
    }
  }
  _dfsVisit(
    node,
    colorings,
    startVisitAction,
    finishVisitAction,
    backEdgeAction,
  ) {
    backEdgeAction = backEdgeAction || (() => {});
    startVisitAction(node);
    colorings.whiteNodes[node] = false;
    colorings.grayNodes[node] = true;

    let edgeOut;
    if (this._edges[node]) {
      for (edgeOut of this._edges[node]) {
        if (colorings.whiteNodes[edgeOut]) {
          this._dfsVisit(
            edgeOut,
            colorings,
            startVisitAction,
            finishVisitAction,
            backEdgeAction,
          );
        }
        if (colorings.grayNodes[edgeOut]) {
          //this is a back edge so there is a loop
          backEdgeAction(node, edgeOut);
        }
      }
    }
    colorings.grayNodes[node] = false;
    colorings.blackNodes[node] = true;
    finishVisitAction(node);
  }

  toplogicalSort() {
    const reversedResult = [];
    const startVisitAction = () => {};
    const finishVisitAction = node => {
      reversedResult.push(node);
    };
    this._dfs(startVisitAction, finishVisitAction);

    return reverse(reversedResult);
  }

  backEdges() {
    const startVisitAction = () => {};
    const finishVisitAction = node => {};
    const backs = [];

    const backEdgeAction = (source, target) => {
      backs.push([source, target]);
    };

    this._dfs(startVisitAction, finishVisitAction, backEdgeAction);

    return backs;
  }
}

function reverseEdges(edges) {
  const result = {};
  let node;
  for (node of Object.keys(edges)) {
    let child;
    for (child of edges[node]) {
      if (result[child]) {
        result[child].push(node);
      } else {
        result[child] = [node];
      }
    }
  }
  return result;
}

class FoundLoop extends Error {
  constructor(backEdges) {
    super("Found loop in directed acyclic graph");
    this.backEdges = backEdges;
  }
}

function makeIterable(obj) {
  if (!obj) {
    return [];
  }
  const newObjArray = [];
  let key;
  for (key of Object.keys(obj)) {
    newObjArray[key] = obj[key];
  }
  return newObjArray;
}
