import React from "react";
import { Button, OverflowMenu, Confirm } from "@components/ui";
import { DataTable } from "@components/ui/data-table";
import { HeaderMain } from "@components/modules";
import Api from "@lib/api";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleDeleteConfirmBox } from "@redux/actions/AWSSettings.actions";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";
import { RouteConstants } from "../../../routes/Constants";

class CloudwatchTargets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumns: this.tableColumns(),
      confirmDelete: null,
    };
  }

  componentDidMount() {
    this.fetchConfiguredTargetAccounts();
  }

  tableColumns = () => {
    return [
      {
        name: "Account Alias",
        selector: "alias",
        grow: 8,
      },
      {
        name: "",
        selector: "options",
        cell: row => (
          <OverflowMenu>
            <div
              className="button-delete"
              onMouseDown={() => {
                this.setState({
                  confirmDelete: row,
                });
                this.props.toggleDeleteConfirmBox(true);
              }}
            >
              Remove Integration
            </div>
          </OverflowMenu>
        ),
      },
    ];
  };

  fetchConfiguredTargetAccounts = async () => {
    this.setState({ message: "Fetching CloudWatch Targets" });
    let configuredAccounts = await Api.fetchCloudwatchTargetAccounts();
    this.setState({ configuredAccounts, message: null });
  };

  render() {
    return (
      <>
        {this.props.showConfirm && (
          <Confirm
            heading="Delete Confirmation"
            cancelText="Cancel"
            confirmText="Delete"
            message="CloudWatch target integration will be removed."
            confirmValue={this.state.confirmDelete.alias}
            confirmRequiredText={`Confirm by typing "${this.state.confirmDelete.alias}" below`}
            verifiedCallback={async () => {
              this.props.toggleDeleteConfirmBox(false);
              this.setState({
                message: `Removing CloudWatch Target account integration`,
                confirmDelete: null,
              });
              await Api.removeIntegration(
                "cloudwatch",
                this.state.confirmDelete.alias,
              );
              await this.fetchConfiguredTargetAccounts();
            }}
          />
        )}
        <div className="d-flex flex-column">
          <div className="main-wrap">
            <HeaderMain
              title={`CloudWatch Target Accounts`}
              showBreadcrumbs={true}
              breadCrumbText={`Settings/CloudWatch`}
            >
              <Button
                buttonStyle="primary"
                onClick={e =>
                  this.props.history.push(
                    RouteConstants.cloudwatchTarget.defaultUrl,
                  )
                }
                size="small"
                text="Add New"
                icon="icon-plus"
              ></Button>
            </HeaderMain>
            <div className="mt-40-px ml-2">
              {!this.state.message &&
                (Array.isArray(this.state.configuredAccounts) &&
                this.state.configuredAccounts.length > 0 ? (
                  <div className="aws-settings-list">
                    <DataTable
                      className="snippet-list-table"
                      columns={this.state.tableColumns}
                      data={this.state.configuredAccounts}
                      responsive={true}
                      fixedHeader={true}
                    />
                  </div>
                ) : (
                  <NoResultFound
                    message="Add your first CloudWatch Target"
                    detail="To add CloudWatch Target, click the 'Add New' button in the top-right of this screen."
                    className="mt-100-px"
                    errImgSrc="settings-es"
                  />
                ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  showConfirm: state.uiReducer.showConfirm,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleDeleteConfirmBox,
    },
    dispatch,
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(CloudwatchTargets);
