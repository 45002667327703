import produce from "immer";
import {
  AWS_OPERATIONS_FETCHING,
  AWS_OPERATION_DETAILS_SET,
  AWS_OPERATIONS_SET,
  AWS_SERVICES_FETCHING,
  AWS_SERVICES_SET,
  AWS_INFO_ERROR,
  RUNBOOK_SET_BUTTON_ACTIVE,
  RUNBOOK_SET_ACTIVE,
  RUNBOOK_SET_ACTIVE_VERSION,
  RUNBOOK_FETCHING_VERSION,
  FETCH_RUNBOOK_VERSION_SUCCESS,
  RUNBOOK_UPDATE_TAGS,
  RUNBOOK_BEFORE_EDITING,
  RUNBOOK_IS_FETCHING,
  RUNBOOK_NAME_FETCHING,
  RUNBOOK_NEW,
  RUNBOOK_IS_SAVING,
  RUNBOOK_IS_DELETING,
  RUNBOOK_SAVE_ERROR,
  RUNBOOK_SAVE_SUCCESS,
  UPDATE_RUNBOOK_DATA,
  RUNBOOK_DID_UPDATE,
  RUNBOOK_DELETE_VERSION_SUCCESS,
  RESOURCES_SCANNED,
  RUNBOOK_VERSION_UPDATE_SUCCESS,
  RUNBOOK_VERSION_UPDATE_FAILURE,
  RUNBOOK_DUPLICATE,
  RUNBOOK_SET_EXISTING,
  SCHEDULED_RUNBOOKS,
  RUNBOOK_SCHEDULE,
  UPDATE_SNIPPET_FIELD_OPTIONS,
  RUNBOOK_FETCH_REGION_LIST,
} from "@redux/types";

const INITIAL_STATE = {
  runbookHeaderButtonActive: "SAVE",
  activeRunbook: {},
  activeRunbookBeforeEditing: {},
  activeRunbookVersion: null,
  isFetching: false,
  isFetchingVersion: false,
  fetchingRunbookName: false,
  runbookFetchNameError: "",
  runbookNew: false,
  isSaving: false,
  isDeleting: false,
  runbookSaveError: "",
  message: "",
  awsServices: [],
  awsOperations: {},
  awsOperationDetails: {},
  runbookDidUpdate: false,
  runbookVersionDeleteStatus: {},
  resourcesList: [],
  isVersionUpdateSuccess: false,
  isVersionUpdateFailed: false,
  isNewRunbook: false,
  scheduledRunbooks: [],
  runbookSchedule: [],
  splunkOnCallTeams: {},
  humioAlerts: [],
  splunkOnCallUsers: [],
  isFetchingSplunkOnCallUsers: false,
  snippetFieldOptions: {},
  regionList: [],
  //isDuplicateRunbook: false,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case RUNBOOK_SET_BUTTON_ACTIVE:
        draft.runbookHeaderButtonActive = action.payload;
        break;

      case RUNBOOK_SET_ACTIVE:
        draft.activeRunbook = action.payload;
        break;

      case RUNBOOK_SET_ACTIVE_VERSION:
        draft.isVersionUpdateSuccess = false;
        draft.isVersionUpdateFailed = false;
        draft.runbookVersionDeleteStatus = {};
        draft.activeRunbookVersion = action.payload;
        break;

      case RUNBOOK_FETCHING_VERSION:
        draft.isFetchingVersion = action.payload;
        break;

      case RUNBOOK_IS_FETCHING:
        draft.isFetching = action.payload;
        break;

      case RUNBOOK_BEFORE_EDITING:
        draft.activeRunbookBeforeEditing = action.payload;
        break;

      case FETCH_RUNBOOK_VERSION_SUCCESS:
        draft.activeRunbook = { ...state.activeRunbook, ...action.payload };
        draft.isFetchingVersion = false;
        break;

      case RUNBOOK_NAME_FETCHING:
        draft.fetchingRunbookName = action.payload;
        draft.runbookFetchNameError = action.error;
        break;

      case RUNBOOK_NEW:
        draft.runbookNew = action.payload;
        break;

      case RUNBOOK_SET_EXISTING:
        draft.isNewRunbook = action.payload;
        //draft.isDuplicateRunbook = action.payload;
        break;

      case RUNBOOK_DUPLICATE:
        draft.runbookDuplicate = action.payload;
        break;

      case RUNBOOK_IS_SAVING:
        draft.isSaving = action.payload;
        draft.message = action.message;
        break;

      case RUNBOOK_SAVE_SUCCESS:
        draft.isSaving = false;
        draft.activeRunbook = action.payload;
        draft.message = "";
        break;

      case RUNBOOK_SAVE_ERROR:
        draft.isSaving = false;
        draft.runbookSaveError = action.payload;
        break;

      case RUNBOOK_IS_DELETING:
        draft.isDeleting = action.payload;
        draft.message = action.message;
        break;

      case AWS_SERVICES_SET:
        draft.awsServices = action.payload.services;
        break;

      case AWS_OPERATIONS_SET:
        draft.awsOperations = { ...state.awsOperations };
        draft.awsOperations[action.payload.service] = action.payload.operations;
        break;

      case AWS_OPERATION_DETAILS_SET:
        const opKey = `${action.payload.service}.${action.payload.operation}`;
        draft.awsOperationDetails = { ...state.awsOperationDetails };
        draft.awsOperationDetails[opKey] = action.payload.data;
        draft.lastAwsOperationFetched = opKey;
        break;

      case AWS_SERVICES_FETCHING:
        draft.isFetchingAwsServices = action.payload;
        break;

      case AWS_OPERATIONS_FETCHING:
        draft.isFetchingAwsOperations = action.payload;
        break;

      case AWS_INFO_ERROR:
        draft.awsInfoError = action.payload;
        break;

      case UPDATE_RUNBOOK_DATA:
        draft.activeRunbook[action.payload.key] = action.payload.value;
        action.payload.DefaultVersion &&
          (draft.activeRunbook["DefaultVersion"] =
            action.payload.DefaultVersion);
        action.payload.DocumentVersion &&
          (draft.activeRunbook["DocumentVersion"] =
            action.payload.DocumentVersion);
        break;

      case RUNBOOK_UPDATE_TAGS:
        draft.activeRunbook.Tags = action.payload;
        break;

      case RUNBOOK_DID_UPDATE:
        draft.runbookDidUpdate = action.payload;
        break;

      case RUNBOOK_DELETE_VERSION_SUCCESS:
        let key = `${action.name}-${action.version}`;
        draft.runbookVersionDeleteStatus[key] = action.payload;
        break;

      case RESOURCES_SCANNED:
        draft.resourcesList = action.payload;
        break;

      case RUNBOOK_VERSION_UPDATE_SUCCESS:
        draft.isVersionUpdateSuccess = action.payload;
        break;

      case RUNBOOK_VERSION_UPDATE_FAILURE:
        draft.isVersionUpdateFailed = action.payload;
        break;

      case SCHEDULED_RUNBOOKS:
        draft.scheduledRunbooks = action.payload;
        break;
      case RUNBOOK_SCHEDULE:
        draft.runbookSchedule = action.payload;
        break;

      case UPDATE_SNIPPET_FIELD_OPTIONS:
        draft.snippetFieldOptions = {
          ...draft.snippetFieldOptions,
          ...action.payload,
        };
        break;

      case RUNBOOK_FETCH_REGION_LIST:
        draft.regionList = action.payload;
        break;

      default:
        break;
    }
  });
