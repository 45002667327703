import React, { useState } from "react";
import "./Filters.scss";
import TextInputView from "../Layouts/TextInputView/TextInputView";
import AddButton from "../Layouts/AddButton/AddButton";
import ManageFilter from "./Helpers/ManageFilter";
import Header from "../Layouts/Header/Header";

type filterObj = { Name: ""; Values: [] };

type FiltersProps = {
  title?: string;
  description?: string;
  helpText?: string;
  filters: Array<filterObj>;
  onChange: (value) => void;
};

const Filters: React.FC<FiltersProps> = ({
  title = "Filters",
  description = "",
  helpText,
  filters,
  onChange,
}) => {
  const [filterList, setFilterList] = useState(filters ? filters : []);
  const [isManageFilterOpen, setIsManageFilterOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);

  /**
   * Delete a filter from filterList
   * @param {object} filter - filter object to be deleted
   */
  const onDelete = filter => {
    let updatedFilterList = filterList.filter(f => f.Name !== filter.Name);
    setFilterList(updatedFilterList);
    onChange(updatedFilterList);
  };

  /**
   * Called when "+ Add Filters" is clicked
   */
  const onAddFilter = () => {
    setIsEditMode(false);
    setSelectedFilter(null);
    setIsManageFilterOpen(!isManageFilterOpen);
  };

  /**
   * Called when "Edit filter" is clicked
   * @param {object} filter - filter object to be edited
   */
  const onEditFilter = filter => {
    setIsEditMode(true);
    setSelectedFilter(filter);
    setIsManageFilterOpen(!isManageFilterOpen);
  };

  /**
   * Close the filter overlay
   */
  const onCancel = () => {
    setIsManageFilterOpen(!isManageFilterOpen);
  };

  /**
   * Edit/Add a filter ({"Name": "abc", "Values": ["test1", "test2"]})
   * in filterList
   * @param {object} filter - edited or newly created filter object
   * @return {void}
   */
  const onSave = filter => {
    let updatedFilterList = [];
    if (isEditMode) {
      updatedFilterList = filterList.map(f => {
        if (f.Name === selectedFilter.Name) {
          return filter;
        }
        return f;
      });
    } else {
      updatedFilterList = [...filterList, filter];
    }
    setFilterList(updatedFilterList);
    setIsManageFilterOpen(!isManageFilterOpen);
    onChange(updatedFilterList);
  };

  return (
    <div className="filters">
      <div
        className={`d-flex flex-column filters-header ${
          filterList.length > 0 && "mb-5-px"
        }`}
      >
        <Header title={title} description={description} helpText={helpText} />
      </div>
      <div className="filters-container">
        {!!filterList &&
          Array.isArray(filterList) &&
          filterList.map(filter => {
            return (
              <TextInputView
                key={filter.Name}
                value={JSON.stringify(filter)}
                onEdit={() => onEditFilter(filter)}
                onDelete={() => onDelete(filter)}
              />
            );
          })}
      </div>
      {isManageFilterOpen && (
        <ManageFilter
          isEdit={isEditMode}
          selectedFilter={selectedFilter}
          onCancel={onCancel}
          onSave={onSave}
        />
      )}
      <AddButton title={"Add Filters"} handleClick={onAddFilter} />
    </div>
  );
};

export default Filters;
