import React from "react";
import { connect } from "react-redux";
import "@scss/quill.css";
import "./SlackConnectorPanel.scss";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import {
  RunbookStepInputSource,
  ActionNodeOutput,
} from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import ReactQuill from "react-quill";
import { Button, TextInput } from "@components/ui";
import FromPreviousStep from "../../../input-selector/option-actions/from-previous-step/FromPreviousStep";
import { FTNotification } from "@components/ui";
import { ParameterType } from "@containers/RunbookEditor/runbook-editor-lib/ssm/strings";
import SnippetHeader from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/SnippetHeader/SnippetHeader";
import { fetchSlackChannelList } from "@redux/actions/snippets.actions";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { ControlNames } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/strings";

type SlackConnectorPanelProps = {
  slackChannelList: any;
  input: any;
  activeNode: any;
  fetchSlackChannelList: Function;
};

type SlackConnectorPanelState = {
  displayMessage: string;
  selectedInputs?: any;
  addVariablePanelVisible: boolean;
  slackVariableName: string;
  slackVariableValue: string;
  isOutputVariableListVisible: boolean;
  slackMessageEditorLastCursorPosition: null | number;
  sourceStep?: any;
  selector?: string;
};

class SlackConnectorPanel extends React.Component<
  SlackConnectorPanelProps,
  SlackConnectorPanelState
> {
  quillRef: any;
  _isMounted: any;

  constructor(props) {
    super(props);

    let selectedInputs = {};
    // eslint-disable-next-line
    for (let item of props.activeNode.extras.runbookNode.parameterInputs) {
      if (typeof item.source.sourceValue === "string") {
        selectedInputs[item.name] = item.source.sourceValue;
      }
    }
    this.state = {
      displayMessage: props.activeNode.extras.runbookNode.displayMessage,
      selectedInputs: selectedInputs,
      addVariablePanelVisible: false,
      slackVariableName: "",
      slackVariableValue: "",
      isOutputVariableListVisible: false,
      slackMessageEditorLastCursorPosition: null,
    };
    this.quillRef = null;
    this._isMounted = React.createRef();
  }

  componentDidMount() {
    if (!this.props.slackChannelList?.channels?.length) {
      this.props.fetchSlackChannelList();
    }
  }

  checkIsMounted = () => {
    return this._isMounted.current !== null;
  };

  updateSlackChannel(newValue, notifyRunbookUpdate) {
    // notify reducer that current runbook got updates.

    const input = this.props.activeNode.extras.runbookNode.parameterInputs.find(
      ip => ip.name === "channel_name",
    );
    input.source = new RunbookStepInputSource("constant", newValue);
    notifyRunbookUpdate(true);
    this.setState({
      selectedInputs: { ...this.state.selectedInputs, channel_name: newValue },
    });
  }

  formatSlackMessage = (message = "") =>
    message
      .replace(/\n/g, "")
      .replace(/"/g, "'")
      .replace(/<br >/g, "")
      .replace(/"/g, "'")
      .replace(/<br\/>/g, "")
      .replace(/rel="noopener noreferrer"/g, "")
      .replace(/target="_blank"/g, "")
      .replace(/style="background-color: rgb\(61, 61, 61\);"/g, "")
      .replace(/spellcheck="false"/g, "")
      .replace(/\\/g, "\\\\");

  updateSlackMessage(message, notifyRunbookUpdate) {
    let escapedValue = this.formatSlackMessage(message);

    let htmlElement = document.createElement("DIV");
    htmlElement.innerHTML = escapedValue;
    this.props.activeNode.extras.runbookNode.displayMessage = (htmlElement as any)
      .textContent
      ? escapedValue
      : "";
    // notify reducer that current runbook got updates.
    notifyRunbookUpdate(true);
  }

  stopEventPropogation = e => {
    let key = e.key;
    if (key === "Backspace" || key === "Delete") {
      e.stopPropagation();
      e.cancelBubble = false;
    }
  };

  insertTextAtCurrentPosition = text => {
    if (this.state.slackMessageEditorLastCursorPosition !== null) {
      this.quillRef
        .getEditor()
        .clipboard.quill.insertText(
          this.state.slackMessageEditorLastCursorPosition,
          text,
        );
    } else {
      FTNotification.error({
        message: "Please click on position where you want to insert variable",
      });
    }
    this.setState({ isOutputVariableListVisible: false });
  };

  setSlackVariableName = slackVariableName => {
    this.setState({ slackVariableName });
  };

  setSlackVariableValue = slackVariableValue => {
    this.setState({ slackVariableValue });
  };

  saveSlackVariable = notifyRunbookUpdate => {
    const { slackVariableName } = this.state;
    let slackVariableValue = this.state.slackVariableValue;
    const input = this.props.activeNode.extras.runbookNode.parameterInputs.find(
      ip => ip.name === "dynamic_variables",
    );

    if (this.state.sourceStep) {
      let output = this.state.sourceStep?.extras?.runbookNode?.outputs?.find(
        out => out.selector === this.state.selector,
      );
      if (output) {
        slackVariableValue = `${this.state.sourceStep?.extras?.runbookNode?.name}.${output.name}`;
      }
    }

    let sourceValue =
      input.source.sourceValue &&
      (typeof input.source.sourceValue === "string"
        ? JSON.parse(input.source.sourceValue)
        : input.source.sourceValue);

    if (sourceValue) {
      sourceValue[slackVariableName] = slackVariableValue;
    } else {
      sourceValue = { [slackVariableName]: slackVariableValue };
    }
    input.source.sourceValue = JSON.stringify(sourceValue);

    notifyRunbookUpdate(true);
    FTNotification.success({
      title: "Success",
      message: `Output variable saved successfully`,
      timeout: 4,
    });
    this.setState({
      slackVariableName: "",
      slackVariableValue: "",
      addVariablePanelVisible: !this.state.addVariablePanelVisible,
    });
  };

  /**
   * @function hideOutputVariableListVisibility To hide dynamic variable list on outside click
   */
  hideOutputVariableListVisibility = () => {
    if (this.state.isOutputVariableListVisible) {
      this.setState({
        isOutputVariableListVisible: false,
      });
    }
  };

  toggleOutputVariableListVisibility = () => {
    this.setState({
      isOutputVariableListVisible: !this.state.isOutputVariableListVisible,
    });
  };

  setSlackMessageEditorLastCursorPosition = () => {
    var range = this.quillRef.getEditorSelection();
    if (range !== null) {
      this.setState({ slackMessageEditorLastCursorPosition: range.index });
    }
  };

  getSlackOutputVariableSection = () => {
    const input = this.props.activeNode.extras.runbookNode.parameterInputs.find(
      ip => ip.name === "dynamic_variables",
    );

    let sourceValue =
      input?.source?.sourceValue &&
      (typeof input.source.sourceValue === "string"
        ? JSON.parse(input.source.sourceValue)
        : input.source.sourceValue);
    const dynamicVariables = (sourceValue && Object.keys(sourceValue)) || [];

    return dynamicVariables?.length ? (
      <div ref={this._isMounted} className="insert-dynamic-variable-button">
        <button
          onClick={() => {
            this.toggleOutputVariableListVisibility();
            if (this.quillRef.getEditorSelection()) {
              this.setSlackMessageEditorLastCursorPosition();
            }
          }}
        >
          Insert Variables
        </button>
        {this.checkIsMounted() && this.state.isOutputVariableListVisible && (
          <div className="slack-dynamic-variable-list">
            <ul className="no-list-style">
              {dynamicVariables.map((variable, i) => (
                <li
                  key={i}
                  id="slack-output-variable"
                  onClick={() =>
                    this.insertTextAtCurrentPosition(" {{" + variable + "}} ")
                  }
                >
                  {variable}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    ) : null;
  };

  onSelectActionNodeOutput = (selector, sourceStep) => {
    if (sourceStep) {
      const { runbookNode: sourceRunbookNode } = sourceStep.extras;

      if (!sourceRunbookNode.outputs) {
        sourceRunbookNode.outputs = [];
      }
      if (sourceRunbookNode.name?.startsWith(ControlNames.Trigger)) {
        this.setState({
          sourceStep,
          selector,
        });
      } else {
        this.setState({
          sourceStep: null,
          selector: null,
        });
        const sourceOutput = new ActionNodeOutput(
          sourceRunbookNode,
          selector,
          ParameterType.String,
        );
        sourceRunbookNode.outputs.push(sourceOutput);
        this.setSlackVariableValue(
          `${sourceRunbookNode.name}.${sourceOutput.name}`,
        );
      }
    } else {
      this.setSlackVariableValue(selector);
    }
  };

  render() {
    return (
      <Consumer>
        {({ activeNode, runbookObj, notifyRunbookUpdate }) => {
          let channelName = this.state.selectedInputs.channel_name;

          return (
            <TabCollection
              active="input"
              activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
              inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
              contentTop={50}
              tabs={["input", "output", "details"]}
            >
              <Tab title="Input" name="input">
                <TabContent>
                  <SnippetHeader
                    snippetDef={
                      this.props.activeNode.extras.runbookNode.snippetDef
                    }
                  />
                  <React.Fragment>
                    <Accordion isExpanded={true} useArrow={true}>
                      <AccordionLabel className="editor-accordion-label mt-10-px">
                        Required Inputs
                      </AccordionLabel>
                      <AccordionSection>
                        <div
                          className="editor-detail-panel editor-detail-panel-column"
                          onClick={this.hideOutputVariableListVisibility}
                        >
                          <div className="editor-select-container">
                            <label className="label">Slack Channel</label>
                            <ReactSelect
                              id="slack-channel"
                              name="slack-channel"
                              required
                              isSearchable={false}
                              value={{
                                value:
                                  channelName && typeof channelName === "string"
                                    ? channelName
                                    : "",
                                label:
                                  channelName && typeof channelName === "string"
                                    ? channelName
                                    : "",
                              }}
                              handleChange={data => {
                                if (data === null) {
                                  this.updateSlackChannel(
                                    "",
                                    notifyRunbookUpdate,
                                  );
                                } else if (data.value) {
                                  this.updateSlackChannel(
                                    data.value,
                                    notifyRunbookUpdate,
                                  );
                                }
                              }}
                              selectOptions={(
                                this.props.slackChannelList?.channels || []
                              ).map(item => ({
                                label: item,
                                value: item,
                              }))}
                            />
                            <label className="label mt-2">Message Text</label>
                            <div className="slack-editor slack-editor-builder">
                              <ReactQuill
                                ref={el => {
                                  this.quillRef = el;
                                }}
                                defaultValue={this.state.displayMessage}
                                onKeyUp={this.stopEventPropogation}
                                onKeyPress={this.stopEventPropogation}
                                modules={{
                                  toolbar: {
                                    container: [
                                      "bold",
                                      "italic",
                                      "code-block",
                                      "strike",
                                      "link",
                                    ],
                                    handlers: {},
                                  },
                                }}
                                onChange={message => {
                                  message.includes("unselectable") &&
                                    this.updateSlackMessage(
                                      message,
                                      notifyRunbookUpdate,
                                    );
                                }}
                              />
                              {this.getSlackOutputVariableSection()}
                            </div>

                            <div className="mt-10-px">
                              <Button
                                text="+ Add Slack Variables"
                                onClick={() => {
                                  this.setState({
                                    addVariablePanelVisible: !this.state
                                      .addVariablePanelVisible,
                                  });
                                }}
                                buttonStyle="secondary"
                                className="add-slackvariable-button"
                              />
                            </div>
                            {this.state.addVariablePanelVisible && (
                              <div className="mt-10-px">
                                <div className="editor-previous-step-label">
                                  Set Slack Variable
                                </div>
                                <TextInput
                                  className="rule-input"
                                  placeholder="Variable name"
                                  name={this.state.slackVariableName}
                                  value={this.state.slackVariableName}
                                  onChange={this.setSlackVariableName}
                                  style={{
                                    width: "calc(100% - 8px)",
                                    marginBottom: "16px",
                                  }}
                                />
                                {activeNode.extras.runbookNode && (
                                  <FromPreviousStep
                                    resetTitle={true}
                                    input={activeNode.extras.runbookNode}
                                    label={"label"}
                                    onChangeCallBack={
                                      this.onSelectActionNodeOutput
                                    }
                                    runbookObj={runbookObj}
                                    notifyRunbookUpdate={notifyRunbookUpdate}
                                  />
                                )}
                                {!!this.state.slackVariableName &&
                                  !!this.state.slackVariableValue && (
                                    <Button
                                      text="Save Variable"
                                      onClick={() =>
                                        this.saveSlackVariable(
                                          notifyRunbookUpdate,
                                        )
                                      }
                                      buttonStyle="primary"
                                      className="save-slackvariable-button mt-20-px"
                                    />
                                  )}
                              </div>
                            )}
                          </div>
                        </div>
                      </AccordionSection>
                    </Accordion>
                  </React.Fragment>
                </TabContent>
              </Tab>
              <Tab title="Output" name="output">
                <TabContent></TabContent>
              </Tab>
              <Tab title="Details" name="details">
                <TabContent></TabContent>
              </Tab>
            </TabCollection>
          );
        }}
      </Consumer>
    );
  }
}

const mapState = state => ({
  slackChannelList: state.snippetsReducer.slackChannelList,
});

const mapDispatch = dispatch => {
  return {
    fetchSlackChannelList: () => dispatch(fetchSlackChannelList()),
  };
};

export default connect(mapState, mapDispatch)(SlackConnectorPanel);
