import React from "react";
import { Consumer } from "../executions-run-lib/executions-run.context";
import { Breadcrumbs } from "@components/ui";
import { HeaderMain } from "@components/modules";

export default () => (
  <Consumer>
    {({ runbookName }) => (
      <div>
        <Breadcrumbs text={"Executions/Executions Detail"} />
        <HeaderMain title={runbookName} showBreadcrumbs={false} />
      </div>
    )}
  </Consumer>
);
