import { Base64 } from "js-base64";
import {
  InvokeLambdaStep,
  readInputSourcesFromSSM,
} from "../ssm/invokelambdastep";
import { SSMActionNames } from "../ssm/strings";
import { RunbookStepInput, RunbookStepOutput } from "../ssm/nodeinputoutput";
import { StepTypes } from "./strings";
import { Parameter } from "../ssm/parameters";
import { kebabToCamel, capitalizeFirstLetter } from "@lib/utils/index";

export default class TriggerStep extends InvokeLambdaStep {
  /**
   * Creates simple SSM Step for the snippet, with no
   * input sources.
   * @param {*} snippetDef
   */
  static fromSnippetDefinition(snippetDef) {
    const name = `${snippetDef.name}_1`;
    const action = SSMActionNames.INVOKE_LAMBDA;

    const outputs = Object.keys(snippetDef.outputs).map(output => {
      return {
        Name: output,
        Selector: `$.${output}`,
        Type: snippetDef.outputs[output],
      };
    });

    const ssm = {
      name,
      action,
      onFailure: "Abort",
      maxAttempts: 1,
      inputs: {
        FunctionName: snippetDef.content.lambda_arn,
        Payload: "",
        ClientContext: TriggerStep._writeSnippetDetailsForContext(snippetDef),
      },
      outputs,
      isStart: snippetDef.content.isStart,
      isEnd: snippetDef.content.isEnd,
      editorNodeId: snippetDef.editorNodeId,
    };
    return new TriggerStep(ssm, snippetDef);
  }

  /**
   * Write the Snippet detail information in a format that can be packaged into
   * the lambda context.
   */
  static _writeSnippetDetailsForContext(snippetDefinition) {
    const {
      description,
      version,
      tags,
      outputs,
      optional_inputs,
      required_inputs,
    } = snippetDefinition || {};
    const obj = {
      custom: {
        description,
        version,
        tags,
        outputs,
        required_inputs,
        optional_inputs,
      },
    };
    return Base64.encode(JSON.stringify(obj));
  }

  constructor(stepJSON, snippetDef) {
    super(stepJSON);
    this.stepType = StepTypes.TriggerStep;
    this.editable = true;

    this.snippetDef = snippetDef;
    this.parameterInputs = [];
    let field;
    if (snippetDef?.required_inputs) {
      for (field of Object.keys(snippetDef.required_inputs)) {
        this.parameterInputs.push(
          new RunbookStepInput(
            this,
            field,
            snippetDef.required_inputs[field],
            true,
          ),
        );
      }
    }
    if (snippetDef?.optional_inputs) {
      for (field of Object.keys(snippetDef.optional_inputs)) {
        this.parameterInputs.push(
          new RunbookStepInput(
            this,
            field,
            snippetDef.optional_inputs[field],
            false,
          ),
        );
      }
    }
    if (snippetDef?.outputs) {
      for (field of Object.keys(snippetDef.outputs)) {
        // Just check that it is there. The outputs should be entirely
        // defined by the SSM document.
        //eslint-disable-next-line
        const found = this.outputs?.find(out => {
          return out.name === field;
        });
        if (!found) {
          this.outputs.push(
            new RunbookStepOutput(this, field, snippetDef.outputs[field]),
          );
        } else {
          // get the type from the snippetDef
          found.type = snippetDef.outputs[field];
        }
      }
    }
  }

  writeInputParams() {
    const inputlist = this.parameterInputs
      .map(input => input.writeInputParam())
      .filter(param => !!param)
      .concat(['"workflow_session": "{{ WorkflowSession }}"'])
      .join(", ");
    return `{ ${inputlist} }`;
  }

  readInputSources(runbook) {
    const inputsWithSources = readInputSourcesFromSSM(this, runbook);
    const serviceName = capitalizeFirstLetter(
      kebabToCamel(this.snippetDef.service[0]).replace(" ", ""),
    );
    if (this.parameterInputs) {
      // This is a TriggerStep and already expects certain inputs
      let input;
      for (input of this.parameterInputs) {
        if (input.name === "alert_body" && input.source !== null) {
          input.source.sourceValue.name = `${serviceName}AlertBody`;
          input.source.default = "{}";
          input.source.sourceValue.spec.ssm.default = "{}";
          input.source.sourceValue.isRequired = true;
          input.source.sourceValue.spec.description = `(Required - String) Stringified JSON of ${serviceName} alert received at the EventBridge`;

          input.source.sourceValue.spec.ssm.description =
            input.source.sourceValue.spec.description;
          runbook.ssmDoc.parameters[`${serviceName}AlertBody`] =
            input.source.sourceValue;
        }

        const newSource = inputsWithSources[input.name];
        if (
          newSource &&
          newSource?.type !== "deleted" &&
          !runbook.isNodeDeleted
        )
          input.source = newSource;
      }
    }
  }

  toSSM() {
    const outputs = this.outputs ? this.outputs.map(out => out.toSSM()) : [];
    return {
      name: this.name,
      action: "aws:invokeLambdaFunction",
      onFailure: "Abort",
      maxAttempts: 1,
      inputs: {
        FunctionName: this.snippetDef?.content?.lambda_arn,
        Payload: this.writeInputParams(),
        ClientContext: TriggerStep._writeSnippetDetailsForContext(
          this.snippetDef,
        ),
      },
      outputs,
      nextStep: this.nextStep,
    };
  }

  isHealthyStep() {
    let boolList = [];
    this.parameterInputs
      .filter(param => param.name !== "alert_body")
      .forEach(param => {
        let sourceValue = param.source.sourceValue;
        if (
          param.required &&
          (sourceValue === null ||
            sourceValue instanceof Parameter ||
            !sourceValue)
        ) {
          boolList.push(false);
        }
      });
    return boolList.every(Boolean);
  }
}
