import React, { useState, useEffect, useContext } from "react";
import { DataTable } from "@components/ui";
import { ExecutionContext } from "../../executions-lib/executions.context";
import moment from "moment";
import { columns } from "./table-header-columns";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";

/**
 *  add duration to data for sorting
 */
function addDurationDataForSorting(filteredExecutionsData) {
  for (const row of filteredExecutionsData) {
    let start = moment(row.ExecutionStartTime);
    let end = moment(row.ExecutionEndTime);
    row["ExecutionDuration"] = end.diff(start);
  }
  return filteredExecutionsData;
}
export default props => {
  const { resetFilters } = props;
  const [filteredData, setFilteredData] = useState([]);
  const context = useContext(ExecutionContext);
  const { filteredExecutionsData, isFetchingExecution } = context;

  useEffect(() => {
    if (Array.isArray(filteredExecutionsData)) {
      let filterData = addDurationDataForSorting(filteredExecutionsData);
      setFilteredData(filterData);
    }
  }, [filteredExecutionsData]);

  const changeExecutionRowsPerPage = rows => {
    props.updateExecutionRecordsPerPage({
      tableName: "allExecutionsRecords",
      cPageRecords: rows,
    });
  };

  const changeExecutionCurrentPage = cPage => {
    props.updateExecutionRecordsCurrentPage({
      tableName: "allExecutionsCPage",
      cPage: cPage,
    });
  };

  const handleAllExecutionsSort = (column, sortDirection) => {
    props.updateExecutionSortColumn({
      tableName: "allExecutions",
      sortField: column?.selector,
      sortFieldDirection: sortDirection,
    });
  };

  return (
    <React.Fragment>
      {!isFetchingExecution && filteredExecutionsData && (
        <div className="w-100">
          {Array.isArray(filteredData) && filteredData.length > 0 ? (
            <DataTable
              columns={columns}
              data={filteredData}
              className="w-100"
              pagination={true}
              recordsPerPage={props.allExecutionsRecords}
              currentPage={props.allExecutionsCPage}
              onChangeRowsPerPage={changeExecutionRowsPerPage}
              onChangeCurrentPage={changeExecutionCurrentPage}
              defaultSortField={props.executionSortField}
              defaultSortAsc={
                props.executionSortFieldDirection === "asc" ? true : false
              }
              onSort={handleAllExecutionsSort}
            />
          ) : props?.searchTerm?.length > 0 ? (
            <NoResultFound
              message="No results found"
              detail="Try adjusting your search to find what you're looking for."
              className="mt-10-px"
              errImgSrc="search-es"
              fallbackComponent={
                <span className="reset-search" onClick={() => resetFilters()}>
                  Clear Search & Filters
                </span>
              }
            />
          ) : (
            <NoResultFound
              message="No execution data to display"
              detail="Run your first workflow to see detailed execution data here."
              className="mt-10-px"
              errImgSrc="executions-es"
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};
