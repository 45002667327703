import React from "react";
import ConfigureMapItem from "./ConfigureMapItem";
import { Consumer } from "./output-configure.context";

export default class ConfigureOutput extends React.PureComponent {
  state = {
    outputObjectArray: [],
  };

  componentDidMount() {
    this.composeOutputOptions();
  }

  composeOutputOptions = () => {
    console.log(this.props.outputString);
    let item = this.props.outputString.split(".");
    item.splice(0, 2);
    this.setState({
      outputObjectArray: item,
    });
  };

  getValue = (item, isTrigger) => {
    const regEx = new RegExp("\\[(.?)\\]");
    if (regEx.test(item)) {
      return item.match(regEx)[1];
    } else {
      return isTrigger ? "0" : "*";
    }
  };

  formatItem = (item, index, isTrigger) => {
    const regEx = new RegExp(/\[(.?)\]/);
    const style = {
      marginLeft: index * 10 + "px",
      paddingTop: "8px",
      paddingBottom: "8px",
      display: "flex",
      alignItems: "center",
      height: "48px",
      borderBottom: "1px solid #555",
    };
    let leading = "";
    if (index) {
      leading = "-";
    }
    if (regEx.test(item)) {
      let label = item.replace(/\[(\d)\]/, "");
      return (
        <div style={style} key={label}>
          <ConfigureMapItem
            label={label}
            leading={leading}
            value={this.getValue(item, isTrigger)}
            itemKey={item}
          />
        </div>
      );
    } else {
      return (
        <div style={style} key={item}>
          {leading} {item}
        </div>
      );
    }
  };

  render() {
    return (
      <Consumer>
        {({ saveOutput, returnToTree, isTrigger }) => (
          <div className="configue-output__wrap">
            <div>
              <div className="mb-20-px pt-10-px">
                {isTrigger ? (
                  <>
                    The following JSON path with 0 results in selection of the
                    first element in an array. To select another element,
                    replace the 0 with a different index number.
                  </>
                ) : (
                  <>
                    The following JSON path with * (wild card) results in
                    selection of all elements in an array. To select an
                    individual element, replace the * with an index number. For
                    instance, index 0 maps to the first element of the array.
                  </>
                )}
              </div>
            </div>
            {this.state.outputObjectArray.map((outputItem, index) => {
              return this.formatItem(outputItem, index, isTrigger);
            })}
            <div className="modal-buttons-footer__editor pt-20-px">
              <button
                type="button"
                className="footer-btn footer-btn-cancel footer-btn-cancel__editor"
                onClick={returnToTree}
              >
                Go Back
              </button>
              <button
                type="button"
                className="footer-btn footer-btn-save footer-btn-save__editor"
                onClick={saveOutput}
              >
                Save Output
              </button>
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}
