import React, { useState, useContext } from "react";
import "./InputFields.scss";
import InputRenderer from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/InputRenderer";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import SnippetHeader from "../SnippetHeader/SnippetHeader";
import { getBlacklistConnectorList } from "@lib/utils";
import { RouteConstants } from "../../../../../../routes/Constants";
import { Link } from "react-router-dom";
import warningSign from "@assets/images/icons/warning-triangle.svg";

type InputFieldsProps = {
  inputList?: Array<any>;
  snippetDef?: any;
  activeNode: any;
};

const InputFields: React.FC<InputFieldsProps> = ({ activeNode }) => {
  const [showForm, setShowForm] = useState(false);
  const [showPriorityError, setShowPriorityError] = useState(null);
  const [priorityError, setPriorityError] = useState(null);
  const context = useContext(RunbookEditorContext) as any;
  const { notifyRunbookUpdate, connectors } = context;
  const { snippetDef, parameterInputs } = activeNode.extras.runbookNode;
  let dependenciesToIntegrate = [];
  if (
    snippetDef.hasOwnProperty("connectors") &&
    snippetDef.connectors.length > 0
  ) {
    const blacklistedConnectors = getBlacklistConnectorList(connectors);
    dependenciesToIntegrate = snippetDef.connectors.filter(blc =>
      blacklistedConnectors.includes(blc),
    );
  }

  const getWarningMsg = (message = "To use this connector, please set up") => {
    return (
      <div className="editor-right-panel-warning">
        <img
          src={warningSign}
          alt="warningSign"
          width="20"
          height="20"
          className="mr-2"
        />
        <div>
          <span className="editor-right-panel-field-label">{message}</span>
          {dependenciesToIntegrate.map((dep, index) =>
            RouteConstants.hasOwnProperty(dep) ? (
              <React.Fragment key={dep}>
                <Link
                  className="warning-link"
                  to={RouteConstants[dep].url}
                  target="_blank"
                >
                  {RouteConstants[dep].displayName}
                </Link>
                {index === dependenciesToIntegrate.length - 2 ? (
                  <span className="editor-right-panel-field-label">
                    &nbsp;&nbsp;and&nbsp;
                  </span>
                ) : index === dependenciesToIntegrate.length - 1 ? (
                  <span className="editor-right-panel-field-label">
                    , and then refresh this page.
                  </span>
                ) : (
                  <span className="editor-right-panel-field-label">
                    ,&nbsp;
                  </span>
                )}
              </React.Fragment>
            ) : (
              ""
            ),
          )}
        </div>
      </div>
    );
  };

  const requiredInputs = snippetDef.required_inputs
    ? snippetDef.required_inputs.map(r => {
        let obj = { ...r };
        obj["required"] = true;
        return obj;
      })
    : [];
  const optionalInputs = snippetDef.optional_inputs
    ? snippetDef.optional_inputs.map(r => {
        let obj = { ...r };
        obj["required"] = false;
        return obj;
      })
    : [];

  const snippetFields = [...requiredInputs, ...optionalInputs];
  return (
    <>
      <SnippetHeader snippetDef={snippetDef} />
      <div className="editor-right-panel-scroll pt-24-px">
        {dependenciesToIntegrate.length > 0 && getWarningMsg()}
        {showPriorityError && getWarningMsg(priorityError)}
        <InputRenderer
          setShowForm={setShowForm}
          showForm={showForm}
          snippetDef={{ ...snippetDef }}
          parameterInputs={[...parameterInputs]}
          notifyRunbookUpdate={notifyRunbookUpdate}
          snippetFields={snippetFields}
          showPriorityError={showPriorityError}
          setShowPriorityError={setShowPriorityError}
          setPriorityError={setPriorityError}
        />
      </div>
    </>
  );
};
export default InputFields;
