export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const SET_USER = "SET_USER";

export function setUser(user) {
  return {
    type: SET_USER,
    payload: user,
  };
}

export function loginSuccess(data) {
  return {
    type: LOG_IN,
    payload: data,
  };
}

export function logout() {
  return {
    type: LOG_OUT,
  };
}