import "./WarningCard.scss";
import { Link } from "react-router-dom";
import React, { useContext } from "react";
import { RouteConstants } from "../../../routes/Constants";
import { getUnconfiguredNodeList, getRedirectUrl } from "@lib/utils";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";

type nodeObj = { step: ""; connector: "" };
type WarningCardProps = {
  isAWSAccountConfigured: boolean;
  unconfiguredNodes: Array<nodeObj>;
};

const WarningCard: React.FC<WarningCardProps> = ({
  isAWSAccountConfigured,
  unconfiguredNodes,
}) => {
  let unconfiguredNodeList = getUnconfiguredNodeList(unconfiguredNodes);
  const context = useContext(RunbookEditorContext) as any;
  const { goBack, connectors } = context;

  return (
    <div className="warning-card">
      <div className="warning-card-header">
        <div className="warning-card-header-text">Save-Only Mode</div>
      </div>
      <span className="warning-card-text">
        Running workflows is disabled due to the following items that are not
        yet setup:
      </span>
      <ul className="warning-card-ul">
        {/* Check if AWS node is configured and show it on top of warning if not */}
        {!isAWSAccountConfigured && (
          <li>
            <Link
              className="warning-card-link"
              onClick={e => {
                goBack(RouteConstants["aws"].url);
                e.stopPropagation();
              }}
            >
              {RouteConstants["aws"].displayName}
            </Link>
          </li>
        )}
        {unconfiguredNodeList.map(nodeName => (
          <li key={nodeName}>
            <Link
              className="warning-card-link"
              onClick={e => {
                goBack(
                  connectors.hasOwnProperty(nodeName) &&
                    connectors[nodeName].connector_type === "Resource"
                    ? RouteConstants[nodeName].url
                    : getRedirectUrl(RouteConstants["integration"].defaultUrl, [
                        { key: "connector", value: nodeName },
                      ]),
                );
                e.stopPropagation();
              }}
            >
              {connectors.hasOwnProperty(nodeName) &&
              connectors[nodeName].connector_type === "Resource"
                ? RouteConstants[nodeName]?.displayName
                : connectors[nodeName]?.display_name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WarningCard;
