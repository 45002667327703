import React from "react";
import { generateId, getBackgroundImgSource } from "@lib/utils";
import iconAlertWhite from "@assets/images/icons/warning-icon-white.svg";
import ReactTooltip from "react-tooltip";

export default class SnippetControl extends React.Component {
  state = {
    active: false,
    dragging: false,
    div: null,
    id: generateId("snippet-control"),
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.disconnectedSnippetTooltip = `This connector is not yet configured. Go to "Settings" and click "Add Integration" to configure.`;
  }

  registerDragStart = (event, snippet) => {
    const model = { type: "both", name: snippet.name, extras: snippet };
    event.dataTransfer.setData("application/reactflow", JSON.stringify(model));
    event.dataTransfer.effectAllowed = "move";
  };

  componentDidMount() {
    this.setState({
      div: this.ref.current,
    });
  }

  render() {
    const snippet = this.props.snippet;
    const snippetName = snippet.display_name;
    const snippetIconName = snippet.icon_name;

    return (
      <>
        {snippet.isDisconnected ? (
          <div
            className={`snippet-wrap-new-disconnected`}
            ref={this.ref}
            id={this.state.id}
          >
            <div className="snippet-icon snippet-disconnected">
              <div
                className={`snippet-icon-image ${
                  snippet.type === "TRIGGER" ? "trigger-node" : ""
                }`}
                style={{
                  backgroundImage: `url(${getBackgroundImgSource(
                    snippetName,
                    snippetIconName,
                  )})`,
                }}
              />
            </div>
            <div className="snippet-name snippet-disconnected__text">
              {snippetName}
            </div>
            <div>
              <ReactTooltip
                id={`tooltip-${snippet.name}`}
                place="right"
                effect="float"
                type="light"
                className="snippet-tooltip-container"
              />
              <img
                src={iconAlertWhite}
                alt="iconAlertWhite"
                width="18"
                height="18"
                className="mr-10-px"
                data-for={`tooltip-${snippet.name}`}
                data-tip={this.disconnectedSnippetTooltip}
                data-html
              />
            </div>
          </div>
        ) : (
          <div
            className={`snippet-wrap-new`}
            ref={this.ref}
            draggable={true}
            onDragStart={e => this.registerDragStart(e, snippet)}
            id={this.state.id}
          >
            <div className="snippet-icon">
              <div
                className={`snippet-icon-image ${
                  snippet.type === "TRIGGER" ? "trigger-node" : ""
                }`}
                style={{
                  backgroundImage: `url(${getBackgroundImgSource(
                    snippetName,
                    snippetIconName,
                  )})`,
                }}
              />
            </div>
            <div className="snippet-name">{snippetName}</div>
          </div>
        )}
      </>
    );
  }
}
