import React from "react";
import { filterSystemTags } from "@lib/utils";

export default props => (
  <div className="tags-wrap">
    {props.tags &&
      filterSystemTags(props.tags).map(tag => (
        <div key={tag} className="tag tag_primary">
          <div className="tag__innerWrap">{tag}</div>
        </div>
      ))}
  </div>
);
