import React from "react";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { RunbookStepInputSource, RunbookStepInput } from "../nodeinputoutput";
import InputChoiceSelector from "./InputChoiceSelector";
import {
  FromPreviousStep,
  SetValueAtRuntime,
} from "@containers/RunbookEditor/runbook-editor-components/editor-right-panel/input-selector/option-actions";
import SetValueNow from "./SetValueNow";
import {
  getParamInput,
  updateConstantValueControl,
  updateInputFromPreviousControl,
} from "./Helper";
import { awsFriendlyName } from "@containers/RunbookEditor/runbook-editor-lib/ssm/util.js";
import { Parameter } from "@containers/RunbookEditor/runbook-editor-lib/ssm/parameters.js";

type InputWithChoiceProps = {
  inputField: any;
  required?: boolean;
  parameterInputs: Array<any>;
  showPriorityError: any;
  setShowPriorityError: () => void;
};

type InputWithChoiceState = {
  selectedChoice: string;
  input: any;
};
class InputWithChoice extends React.Component<
  InputWithChoiceProps,
  InputWithChoiceState
> {
  constructor(props) {
    super(props);
    this.state = {
      selectedChoice: "",
      input: this.props.inputField,
    };
  }

  componentDidMount() {
    const paramInput = getParamInput(
      this.props.inputField.name,
      this.props.parameterInputs,
    );
    const selectedChoice = paramInput.source.type;
    this.setState({ selectedChoice });
    if (selectedChoice === "constant" && !paramInput?.source?.sourceValue) {
      const input = { ...this.state.input };
      input["value"] = "";
      this.setState({ input });
    }
  }

  updateUserProvidedInputControl = (paramInput, notifyRunbookUpdate) => {
    const name = RunbookStepInput.parameterName(
      this.state.input.name,
      paramInput,
    ); // `${input.name}${input.snippetAction.name}`;
    const parameterName = awsFriendlyName(name);
    const description = `(${this.props.required ? "Required" : "Optional"} - ${
      paramInput.type
    }) ${paramInput.snippetAction.name} - ${this.state.input.name}`;
    const param = Parameter.createFylamyntParameter(parameterName, description);

    paramInput.snippetAction.runbook.ssmDoc.parameters.parameters[
      parameterName
    ] = param;
    paramInput.source = new RunbookStepInputSource("userProvided", param);

    // notify reducer that current runbook got updates.
    notifyRunbookUpdate(true);
  };

  updateFieldInput = newValue => {
    const input = { ...this.state.input };
    input["value"] = newValue;
    this.setState({ input });
  };

  getInputField = (
    paramInput,
    updateRunbook,
    notifyRunbookUpdate,
    runbookObj,
  ) => {
    const input = { ...this.state.input };
    switch (this.state.selectedChoice) {
      case "snippetOutput":
        return (
          <FromPreviousStep
            inputDef={input}
            input={paramInput}
            label={input?.display_name || input?.name}
            updateInputControl={() =>
              updateInputFromPreviousControl(paramInput, notifyRunbookUpdate)
            }
            onChangeCallBack={updateRunbook}
            runbookObj={runbookObj}
          />
        );
      case "actionNode":
        return (
          <FromPreviousStep
            inputDef={input}
            input={paramInput}
            label={input.display_name || input.name}
            updateInputControl={() =>
              updateInputFromPreviousControl(paramInput, notifyRunbookUpdate)
            }
            onChangeCallBack={updateRunbook}
            runbookObj={runbookObj}
            notifyRunbookUpdate={notifyRunbookUpdate}
          />
        );
      case "userProvided":
        return (
          <SetValueAtRuntime
            input={input}
            label={input.display_name || input.name}
          />
        );

      case "constant":
      default:
        return (
          <SetValueNow
            parameterInputs={this.props.parameterInputs}
            input={input}
            updateValue={newValue => {
              this.updateFieldInput(newValue);
              updateConstantValueControl(
                paramInput,
                newValue,
                notifyRunbookUpdate,
              );
              this.props.setShowPriorityError();
            }}
            isRequired={this.props.required}
            showPriorityError={this.props.showPriorityError}
          />
        );
    }
  };

  updateChoice = (
    selectedChoice: string = "",
    paramInput,
    notifyRunbookUpdate,
  ) => {
    if (selectedChoice !== this.state.selectedChoice) {
      switch (selectedChoice) {
        case "userProvided":
          this.updateUserProvidedInputControl(paramInput, notifyRunbookUpdate);
          break;
        case "snippetOutput":
        case "actionNode":
          updateInputFromPreviousControl(paramInput, notifyRunbookUpdate);
          break;
        case "constant":
          this.updateFieldInput("");
          updateConstantValueControl(paramInput, "", notifyRunbookUpdate);
          break;
        default:
          console.warn(`Unknown input type: ${selectedChoice}`);
      }
      this.setState({ selectedChoice });
    }
  };

  render() {
    const paramInput = getParamInput(
      this.state.input.name,
      this.props.parameterInputs,
    );
    return (
      <Consumer>
        {({ notifyRunbookUpdate, updateRunbook, runbookObj }) => (
          <div className="editor-detail-panel editor-detail-panel-new editor-detail-panel-column">
            <InputChoiceSelector
              selectedChoice={this.state.selectedChoice}
              setSelectedInputChoice={choice => {
                this.updateChoice(choice, paramInput, notifyRunbookUpdate);
              }}
              isStart={paramInput.snippetAction.snippetDef.is_start}
            />
            {this.state.selectedChoice &&
              this.getInputField(
                paramInput,
                updateRunbook,
                notifyRunbookUpdate,
                runbookObj,
              )}
          </div>
        )}
      </Consumer>
    );
  }
}
export default InputWithChoice;
