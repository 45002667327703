import React from "react";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import OperationItem from "./OperationItem";

const renderOperationParameters = (
  selectedOperation,
  operationsMap,
  getParamValue,
  setPayload,
  showWarning,
  getTransfromValue,
) => {
  if (!selectedOperation || !operationsMap) {
    return;
  }
  let operationsObj = operationsMap.get(selectedOperation);
  // Remove redundent property "input_string"
  let operationLabels = Object.keys(operationsObj?.required_inputs || {});
  operationLabels = operationLabels.filter(
    operation => operation !== "input_string",
  );

  if (!operationLabels.length) {
    return null;
  }

  return (
    <div className="operations-wrapper">
      {operationLabels.map(operation => (
        <OperationItem
          showWarning={showWarning}
          key={operation}
          operation={operation}
          getParamValue={getParamValue}
          setPayload={setPayload}
          getTransfromValue={getTransfromValue}
        />
      ))}
    </div>
  );
};

const renderOutput = (selectedOperation, operationsMap, transformValue) => {
  if (!selectedOperation || !operationsMap) {
    return;
  }
  let outputObj = operationsMap.get(selectedOperation)?.outputs || {};
  let output = Object.keys(outputObj).find(out => out === "string_output")
    ? "String"
    : "StringList";

  return (
    <div className="editor-detail-panel editor-detail-panel-column">
      {output === "String" ? (
        typeof transformValue === "string" && transformValue.length > 0 ? (
          <>
            <label className="label">Transform Output</label>
            <div className="transform-output p-10-px w-auto">
              {transformValue}
            </div>
          </>
        ) : (
          <label className="label">No Transform Output</label>
        )
      ) : Array.isArray(transformValue) && transformValue.length > 0 ? (
        <>
          <label className="label">Transform Output</label>
          <div className="transform-output p-10-px w-auto">
            {JSON.stringify(transformValue, null, 4)}
          </div>
        </>
      ) : (
        <label className="label">No Transform Output</label>
      )}
    </div>
  );
};

const Operations = ({
  isLoadingOperations,
  isErrorOperations,
  operations,
  selectedOperation,
  setOperationsUpdate,
  removeOperationParameterUpdate,
  operationsMap,
  getParamValue,
  setPayload,
  showWarning,
  showOutput,
  transformValue,
  getTransfromValue,
}) => {
  if (isLoadingOperations) {
    return (
      <div className="editor-detail-panel editor-detail-panel-column">
        <label className="label">
          <i>Loading...</i>
        </label>
      </div>
    );
  }

  if (isErrorOperations) {
    return (
      <div className="editor-detail-panel editor-detail-panel-column">
        <label className="label">
          <i>Oh uh! Something went wrong!</i>
        </label>
      </div>
    );
  }

  return (
    <>
      <div className="editor-detail-panel editor-detail-panel-column">
        <label className="label">Operation</label>
        <ReactSelect
          id="operations"
          name="operations"
          required
          isSearchable={false}
          value={{
            value: selectedOperation,
            label: selectedOperation.split("_").join(" "),
          }}
          handleChange={data => {
            if(data === null) {
              setOperationsUpdate("");
              removeOperationParameterUpdate();
            } else if (data.value) {
              setOperationsUpdate(data.value);
              removeOperationParameterUpdate();
            }
          }}
          selectOptions={operations.map(operation => ({
            value: operation,
            label: operation.split("_").join(" "),
          }))}
        />
      </div>

      {selectedOperation &&
        renderOperationParameters(
          selectedOperation,
          operationsMap,
          getParamValue,
          setPayload,
          showWarning,
          getTransfromValue,
        )}
      {selectedOperation &&
        showOutput &&
        renderOutput(selectedOperation, operationsMap, transformValue)}
    </>
  );
};

export default Operations;
