import React from "react";
import { Modal } from "@components/ui";

const getFooter = (toggleWarning, toggleWarningIgnore) => (
  <div className="modal-buttons-footer__editor">
    <button
      type="button"
      className="footer-btn footer-btn-cancel"
      onClick={toggleWarning}
    >
      Cancel
    </button>
    <button
      type="button"
      className="footer-btn footer-btn-save"
      onClick={() => {
        toggleWarningIgnore();
      }}
    >
      Exit Without Saving
    </button>
  </div>
);

export default props => (
  <Modal
    title={`Alert: Unsaved Changes`}
    showClose={true}
    coverClass={`modal-cover__editor`}
    containerClass={`modal-container__editor-transactional`}
    contentClass={`modal-content__editor-transactional`}
    titleClass={`modal-title__json`}
    closeClass={`modal-close__editor`}
    footerClass={`modal-footer__editor`}
    footer={getFooter(props.toggleWarning, props.toggleWarningIgnore)}
    onClose={props.toggleWarning}
  >
    <div>
      <div className="mb-20-px">
        Are you sure you want to exit without saving?
      </div>
      <div>
        This Workflow hasn't been saved and leaving this page will lose all its
        content. To save changes, go back and click Save.
      </div>
    </div>
  </Modal>
);
