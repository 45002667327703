import React from "react";
import { snakeToPascal, camelToSnake, isEmpty } from "@lib/utils";
import InputsRequired from "./InputsRequired";
import InputsOptional from "./InputsOptional";
import { updateActionNode } from "./input.helpers";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { Accordion, AccordionSection } from "@components/ui";
import Header from "@components/ui/InputTypes/Layouts/Header/Header";
import ActionSnippetHeader from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/ActionSnippetHeader/ActionSnippetHeader";
import { InputSelector } from "@containers/RunbookEditor/runbook-editor-components/editor-right-panel/input-selector";
import { getSelectedOption } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.helpers";
export default class ActionNodeInputOptions extends React.Component {
  state = {
    operationDetailsNeeded: true,
    dropdownOptions: {},
  };

  async componentDidMount() {
    this.initServiceAndOperation();
    if (!(this.props.regionList.length > 0)) {
      this.props.fetchRegionList();
    }

    if (!(this.props.aliasList.length > 0)) {
      this.props.getResourcesList();
    }

    this.prepareDropdownOptions();
  }

  componentDidUpdate(prevProps) {
    this.setOperationDetails(prevProps);
    if (
      prevProps.regionList !== this.props.regionList ||
      prevProps.aliasList !== this.props.aliasList
    ) {
      this.prepareDropdownOptions();
    }
  }

  prepareDropdownOptions = () => {
    let dropdownOptions = {};
    dropdownOptions["alias"] = this.props.aliasList.reduce((acc, a) => {
      let obj = {
        label: a.alias,
        value: a.alias,
      };
      acc.push(obj);
      return acc;
    }, []);
    dropdownOptions["region_name"] = this.props.regionList.reduce((acc, r) => {
      let obj = {
        label: r.RegionName,
        value: r.RegionName,
      };
      acc.push(obj);
      return acc;
    }, []);

    this.setState({
      dropdownOptions,
    });
  };

  initServiceAndOperation = () => {
    let { service, operation } = this.props.runbookNode;
    this.props.setSelectedService(service);
    if (operation === "undefined") {
      operation = null;
    }
    if (operation) {
      operation = camelToSnake(operation);
    }
    this.setSelectedOperation(operation);
    if (this.props.servicesNeeded) {
      this.props.fetchServices();
    }
  };

  setService = newValue => {
    this.props.runbookNode.service = newValue;
    this.props.runbookNode.operation = null;
    this.props.setSelectedService(camelToSnake(newValue));
    this.props.runbookNode.showHideWarning(
      !this.props.runbookNode.isHealthyStep(),
    );
  };

  setSelectedOperation = operation => {
    this.props.setSelectedOperation(operation);
  };

  setOperationDetails = prevProps => {
    if (!this.state.operationDetailsNeeded) {
      return;
    }
    if (
      (prevProps.isFetchingOperationDetails &&
        !this.props.isFetchingOperationDetails) ||
      (prevProps.selectedOperation !== this.props.selectedOperation &&
        this.props.selectedOperation === undefined) ||
      prevProps.selectedService !== this.props.selectedService
    ) {
      updateActionNode(this, this.props.rerenderEditor);
    }
  };

  updateService = newValue => {
    this.setService(newValue);
    this.props.setSelectedOperation();
    this.props.notifyRunbookUpdate(true);
  };

  updateOperation = newValue => {
    /**
     * if operation is cleared
     */
    if (newValue === "") {
      this.props.resetOperation();
      this.props.setSelectedOperation();
      this.props.runbookNode.showHideWarning(
        !this.props.runbookNode.isHealthyStep(),
      );

      this.props.notifyRunbookUpdate(true);
      return;
    }
    this.setSelectedOperation(camelToSnake(newValue));
    this.setState({
      operationDetailsNeeded: true,
    });
    this.props.notifyRunbookUpdate(true);
  };

  getServicesSelect = () => {
    const { runbookNode } = this.props;
    const doc_link = {
      enabled: runbookNode.service ? true : false,
      href: this.props.serviceDocLink,
    };
    return (
      <div className="editor-detail-panel editor-detail-panel-column">
        <Header
          title="Service"
          description="Service"
          documentation={doc_link}
        />
        {!this.props.isFetchingServices ? (
          <div className="editor-aws-container">
            <ReactSelect
              id="aws-services"
              name="aws-services"
              customControlClass="mt-2-px"
              value={{
                value: this.props.selectedService
                  ? snakeToPascal(this.props.selectedService)
                  : "",
                label: this.props.selectedService
                  ? snakeToPascal(this.props.selectedService)
                  : "Select from below",
              }}
              isLoading={!this.props.services?.length}
              handleChange={data => {
                if (data === null) {
                  this.updateService("");
                } else if (!isEmpty(data) && !!data.value) {
                  this.updateService(data.value.toLowerCase());
                }
              }}
              selectOptions={
                this.props.services && Array.isArray(this.props.services)
                  ? this.props.services.map(service => {
                      return {
                        value: snakeToPascal(service),
                        label: snakeToPascal(service),
                      };
                    })
                  : []
              }
              required
            />
          </div>
        ) : (
          <div className="editor-is-fetching mb-5-px">...fetching services</div>
        )}
      </div>
    );
  };

  getOperationsSelect = () => {
    const { runbookNode } = this.props;
    const doc_link = {
      enabled: runbookNode.operation ? true : false,
      href: runbookNode.operationDetails?.documentation,
    };
    return (
      <div className="editor-detail-panel editor-detail-panel-column">
        <Header
          title="Operation"
          description="Operation"
          documentation={doc_link}
        />
        {!this.props.isFetchingOperations ? (
          <div className="editor-aws-container">
            <ReactSelect
              id="aws-operations"
              name="aws-operations"
              customControlClass="mt-2-px"
              value={{
                value: this.props.selectedOperation
                  ? snakeToPascal(this.props.selectedOperation)
                  : "",
                label: this.props.selectedOperation
                  ? snakeToPascal(this.props.selectedOperation)
                  : "Select from below",
              }}
              isLoading={!this.props.operations?.length}
              handleChange={data => {
                if (data === null) {
                  this.updateOperation("");
                } else if (!isEmpty(data) && !!data.value) {
                  this.updateOperation(data.value);
                }
              }}
              selectOptions={
                this.props.operations && Array.isArray(this.props.operations)
                  ? this.props.operations.map(operation => {
                      return {
                        value: snakeToPascal(operation),
                        label: snakeToPascal(operation),
                      };
                    })
                  : []
              }
              required
            />
          </div>
        ) : (
          <div className="editor-is-fetching mb-5-px">
            ...fetching operations
          </div>
        )}{" "}
      </div>
    );
  };

  getHeader = runbookNode => (
    <ActionSnippetHeader
      runbookNode={runbookNode}
      awsDocLink={this.props.awsDocLink}
    />
  );

  render() {
    const { runbookNode } = this.props;
    const inputs = runbookNode.parameterInputs;
    const requiredInputs =
      inputs &&
      inputs.filter(
        input =>
          input.required &&
          !(input.name === "alias" || input.name === "region_name"),
      );
    const optionalInputs =
      inputs &&
      inputs.filter(
        input =>
          !input.required &&
          !(input.name === "alias" || input.name === "region_name"),
      );

    return (
      <div className="d-flex flex-column">
        {this.getHeader(runbookNode)}
        <Accordion isExpanded={true} useArrow={true}>
          <AccordionSection className="mb-2 mt-2">
            {inputs &&
              inputs
                .filter(
                  input =>
                    input.required &&
                    (input.name === "alias" || input.name === "region_name"),
                )
                .map((input, i) => {
                  return (
                    <InputSelector
                      optionFilterId={this.props.optionFilterId}
                      toggleInputOption={this.props.toggleInputOption}
                      input={input}
                      selectedOption={getSelectedOption(input)}
                      key={`${input.name}-${i}`}
                      label={this.props.label}
                      dropdownOptions={this.state.dropdownOptions}
                    />
                  );
                })}
            {this.getServicesSelect()}

            {this.getOperationsSelect()}
            <InputsRequired
              requiredInputs={requiredInputs}
              optionFilterId={this.props.optionFilterId}
              toggleInputOption={this.props.toggleInputOption}
              selectedAwsOperation={this.props.selectedOperation}
            />
          </AccordionSection>
        </Accordion>

        <InputsOptional
          optionalInputs={optionalInputs}
          optionFilterId={this.props.optionFilterId}
          toggleInputOption={this.props.toggleInputOption}
          selectedAwsOperation={this.props.selectedOperation}
        />
      </div>
    );
  }
}
