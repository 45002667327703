import React, { useContext } from "react";
import { AccordionSection } from "@components/ui";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import ReactSelect from "@components/ui/React-Select/ReactSelect";

const JiraRequiredFields = ({
  project,
  projectsList,
  updateSelectedProject,
  issueKey,
  issues,
  selectIssue,
}) => {
  const { notifyRunbookUpdate } = useContext(RunbookEditorContext);

  return (
    <AccordionSection>
      <div className="editor-detail-panel editor-detail-panel-column">
        <div className="editor-select-container">
          <label className="label">Jira Projects</label>
          <ReactSelect
            id="jira-projects"
            name="jira-projects"
            required
            value={{
              value: project && typeof project === "string" ? project : "",
              label: project && typeof project === "string" ? project : "",
            }}
            handleChange={data => {
              if(data === null){
                updateSelectedProject("",notifyRunbookUpdate)
              } else if (data.value) {
                updateSelectedProject(data.value, notifyRunbookUpdate);
              }
            }}
            selectOptions={(projectsList || []).map(item => ({
              label: item,
              value: item,
            }))}
          />
          <label className="label mt-2">Issue List</label>
          <ReactSelect
            id="jira-issues"
            name="jira-issues"
            required
            value={{
              value: issueKey && typeof issueKey === "string" ? issueKey : "",
              label: issueKey && typeof issueKey === "string" ? issueKey : "",
            }}
            handleChange={data => {
              if(data === null){
                selectIssue("", true, true)
              } else if (data.value) {
                selectIssue(data.value, true, true);
              }
            }}
            selectOptions={(issues || []).map(item => ({
              label: item,
              value: item,
            }))}
          />
        </div>
      </div>
    </AccordionSection>
  );
};

export default JiraRequiredFields;
