import React, { useState, useEffect } from "react";
import * as yup from "yup";
import DefaultTextInput from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/DefaultTextInput/DefaultTextInput";

const urlSchema = (isRequired, allowedPattern) => {
  return yup.object().shape({
    url: isRequired
      ? allowedPattern
        ? yup.string().required().matches(allowedPattern)
        : yup
            .string()
            .required()
            .matches(
              /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g,
              "Invalid URL",
            )
      : yup.string().url(),
  });
};

const URL = ({
  title,
  value,
  onChange,
  description,
  isRequired,
  allowedPattern,
  showPriorityError,
}) => {
  const initialState = value;

  const [url, setUrl] = useState(initialState);
  const [error, setError] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    urlSchema(isRequired, allowedPattern)
      .validate({ url })
      .then(() => {
        setError(false);
        setErrorMsg("");
      })
      .catch(() => {
        setError(true);
        setErrorMsg("Invalid URL");
      });
  }, [url, isRequired, allowedPattern]);

  return (
    <div className="url-wrap">
      <DefaultTextInput
        fieldName={title}
        label={title}
        subText={description}
        placeholder={`Enter ${title}`}
        fieldValue={url}
        onChangeHandler={value => {
          onChange(value);
          setUrl(value);
        }}
        showError={
          (showPriorityError !== null
            ? showPriorityError
            : isRequired && !url) || error
        }
        errorMsg={errorMsg}
      />
    </div>
  );
};

export default URL;
