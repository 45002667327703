import React from "react";
import "./TextInputView.scss";
import editIcon from "../../../../../assets/images/icons/icon-edit-new.svg";
import deleteIcon from "../../../../../assets/images/icons/icon-trash-new.svg";

type TextInputViewProps = {
  value: string;
  onEdit: (value) => void;
  onDelete: (value) => void;
};
const TextInputView: React.FC<TextInputViewProps> = ({
  value = "",
  onEdit,
  onDelete,
}) => {
  return (
    <div className="input-view">
      <div className="input-view-container">
        <div className="input-view-container-left">
          <div>{value}</div>
        </div>
        <div className="input-view-container-right">
          <div className="input-view-container-right-edit" onClick={onEdit}>
            <img alt="edit" src={editIcon} />
          </div>
          <div className="input-view-container-right-delete" onClick={onDelete}>
            <img alt="delete" src={deleteIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextInputView;
