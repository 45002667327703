import React, { useState } from "react";
import { TextInput } from "@components/ui";

const OperationItem = ({
  operation,
  getParamValue,
  setPayload,
  showWarning,
  getTransfromValue,
}) => {
  const [input, setInput] = useState(getParamValue(operation) || "");

  return (
    <div className="editor-detail-panel editor-detail-panel-column">
      <label className="label">{operation}</label>
      <TextInput
        key={operation}
        id={operation}
        name={operation}
        value={input}
        onKeyUp={value => {
          setInput(value);
          if (operation === "regex_pattern") {
            // Stringify regex pattern to make it JSON safe string
            setPayload(JSON.stringify(value), operation);
          } else {
            setPayload(value, operation);
          }
          showWarning();
          getTransfromValue();
        }}
        className={`rule-input p-10-px w-auto ${
          !input ? "rule-input-error" : ""
        }`}
        required
      />
    </div>
  );
};

export default OperationItem;
