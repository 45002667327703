import React from "react";
import {
  CustomNameCell,
  CustomTagsCell,
} from "../snippets-components/snippets-table-cells";

export const TableColumns = setSelectedSnippet => [
  {
    name: "Action Name",
    selector: "display_name",
    cell: row => (
      <CustomNameCell setSelectedSnippet={setSelectedSnippet} snippet={row} />
    ),
    grow: 1.5,
    sortable: true,
  },
  {
    name: "Description",
    selector: "description",
    grow: 4,
  },
  {
    name: "Service",
    selector: "service",
  },
  {
    name: "Tags",
    selector: "tags",
    cell: row => <CustomTagsCell tags={row.tags} />,
    grow: 2.0,
  },
];
