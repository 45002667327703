import React from "react";
import "./Footer.scss";
type FooterProps = {
  onCancel: () => void;
  onSave: () => void;
};
export const Footer: React.FC<FooterProps> = ({ onCancel, onSave }) => {
  return (
    <div className="footer">
      <button
        type="button"
        className="footer-btn footer-btn-cancel"
        onClick={onCancel}
      >
        Cancel
      </button>
      <button
        type="submit"
        className="footer-btn footer-btn-save"
        onClick={onSave}
      >
        Save
      </button>
    </div>
  );
};
