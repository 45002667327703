import React from "react";
import { Link } from "react-router-dom";

export class CustomTagsCell extends React.Component {
  render() {
    return (
      <div className="snippets-tags-container">
        {this.props.tags &&
          this.props.tags.map((tag, i) => {
            return (
              <span className="tag" key={i}>
                {tag}
              </span>
            );
          })}
      </div>
    );
  }
}

export class CustomNameCell extends React.Component {
  render() {
    return (
      <Link
        to={`./actions/${this.props.snippet.name}/inputs`}
        onClick={() => this.props.setSelectedSnippet(this.props.snippet)}
      >
        {this.props.snippet?.display_name || this.props.snippet.name}
      </Link>
    );
  }
}
