import { ActionNodeStep } from "../../../../runbook-editor-lib/neuropssteps/actionnodestep";

export function updateActionNode(
  ActionNodeInputClass,
  rerenderEditor = () => {},
) {
  const {
    selectedService,
    selectedOperation,
    operationDetails,
    runbookNode,
  } = ActionNodeInputClass.props;
  const { runbook } = runbookNode;
  if (nodeNeedsReplacement(runbookNode, selectedService, selectedOperation)) {
    let actionNodeDef = runbookNode.actionNodeDef || runbookNode.snippetDef;
    actionNodeDef.editorNodeId = runbookNode.editorNodeId;
    actionNodeDef.insertionOrder = runbookNode.name.substring(
      runbookNode.name.lastIndexOf("_") + 1,
    );
    const newActionNode = ActionNodeStep.createNewActionNode(
      actionNodeDef,
      selectedService,
      selectedOperation,
      operationDetails,
      /**
       * alias and regionName once selected should not reset on
       * Service/Operation change
       */
      runbookNode.parameterInputs.filter(
        input =>
          (input.name === "alias" || input.name === "region_name") &&
          input?.source?.type !== "userProvided",
      ),
    );
    runbook.replaceNonConditionalStep(runbookNode, newActionNode);
    ActionNodeInputClass.props.setActiveActionNode(newActionNode);
  }
  else {
    runbookNode.setOperationDetails(operationDetails);
    ActionNodeInputClass.props.setOutputs(runbookNode.outputs);
    ActionNodeInputClass.forceUpdate();
  }
  rerenderEditor();
}

function nodeNeedsReplacement(runbookNode, selectedService, selectedOperation) {
  return (
    runbookNode.service !== selectedService ||
    runbookNode.operation !== selectedOperation
  );
}
