import {
  ACTIVE_NODE,
  UPDATE_NODE,
  CLEAR_ACTIVE_ACTION_NODE,
  SET_ACTIVE_ACTION_NODE,
} from "../types";

export function setNode(node = {}) {
  return dispatch => {
    if (
      node &&
      node.extras &&
      node.extras.runbookNode &&
      node.extras.runbookNode.stepType === "ActionNodeStep"
    ) {
      dispatch({
        type: SET_ACTIVE_ACTION_NODE,
        payload: node.extras.runbookNode,
      });
    } else {
      dispatch({
        type: CLEAR_ACTIVE_ACTION_NODE,
      });
    }
    dispatch({
      type: ACTIVE_NODE,
      payload: node,
    });
  };
}

export function updateNode(node, obj) {
  return dispatch => {
    dispatch({
      type: UPDATE_NODE,
      payload: { node, obj },
    });
  };
}

export function setActiveActionNode(actionNode) {
  return dispatch => {
    dispatch({
      type: SET_ACTIVE_ACTION_NODE,
      payload: actionNode,
    });
  };
}

export function clearActiveActionNode() {
  return dispatch => {
    dispatch({
      type: CLEAR_ACTIVE_ACTION_NODE,
    });
  };
}
