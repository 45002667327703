import React from "react";
import SettingPanelTile from "./settings-panel-tile";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  toggleConfigPanel,
  fetchSettingsConnectors,
  isFetching,
} from "@redux/actions/settingsPanel.action";
import { fetchDefaultAccounts } from "@redux/actions/getStarted.actions";
import { SettingsPanelHeader } from "./../settings-panel-components";
import { isEmpty, capitalizeFirstLetter, kebabToCamel } from "@lib/utils";
import { Modal } from "@components/ui";
import { Redirect } from "react-router";
import Api from "@lib/api/api";
import CloudWarningCard from "@components/ui/WarningSign/CloudWarningCard";
import { RouteConstants } from "../../../routes/Constants";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";
import { ConnectorType } from "../Constants.ts";
import { getRemoveMessage } from "@components/shared/GetStarted/Utils/Helpers";

class SettingsPanelContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cloudConfigNodes: [],
      integrationConfigNodes: [],
      resourceConfigNodes: [],
      formattedData: [],
      doRedirect: false,
      redirectUrl: "",
      showConfirmModal: false,
      confirmModalResponse: "",
      selectedNode: {},
      isLoaded: false,
    };
  }

  async componentDidMount() {
    try {
      this.loadConnectors();
    } catch (error) {
      console.log(error);
    }
  }

  loadConnectors = async () => {
    if (!this.props.isAccountDetailFetched) {
      await this.props.fetchDefaultAccounts();
    }
    if (isEmpty(this.props.connectors))
      await this.props.fetchSettingsConnectors();
    let formattedData = this.formatData(this.props.connectors) || [];
    let cloudNodes = formattedData.filter(data => ["AWS"].includes(data.name));
    let integrationNodes = formattedData.filter(
      data => data.type === ConnectorType.integration,
    );

    let resourceNodes = formattedData.filter(
      data => data.type === ConnectorType.resource,
    );

    let cloudConfigNodes = this.getSettingsConfigNodes(cloudNodes);
    let integrationConfigNodes = this.getSettingsConfigNodes(integrationNodes);
    let resourceConfigNodes = this.getSettingsConfigNodes(resourceNodes);
    this.setState({
      cloudConfigNodes,
      integrationConfigNodes,
      resourceConfigNodes,
      formattedData,
      isLoaded: true,
    });
  };

  getCloudNodesStatus = connectorName => {
    if (connectorName === "AWS") {
      if (
        this.props.isAccountDetailFetched &&
        !this.props.isFetchingDefaultAccounts
      ) {
        return this.props?.userDefaultAccounts?.length
          ? "Connected"
          : "Not Connected";
      }
    }
  };

  getConnectorProps = (dataObj, val) => {
    let obj = {
      name: capitalizeFirstLetter(val),
      displayName: dataObj[val].display_name,
      status: dataObj[val]["is_configured"] ? "Connected" : "Not Connected",
      type: dataObj[val].connector_type,
    };
    return obj;
  };

  formatData = dataObj =>
    !isEmpty(dataObj) &&
    [
      {
        name: "AWS",
        status: this.getCloudNodesStatus("AWS"),
        displayName: "AWS",
        type: ConnectorType.cloud,
      },
    ].concat(
      Object.keys(dataObj).map(val => this.getConnectorProps(dataObj, val)),
    );

  handleClick = async event => {
    event.preventDefault();
    const node = this.state.formattedData.find(
      val => val.name === event.currentTarget.title,
    );
    if (!isEmpty(node) && !this.props.isLoading) {
      switch (node.name.toLowerCase()) {
        case "aws":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.awsSettings.url,
          });
          break;

        case "git":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.git.url,
          });
          break;

        case "apikeys":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.apikeys.url,
          });
          break;

        case "terraform_cli":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.terraform_cli.url,
          });
          break;

        case "ssh":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.ssh.url,
          });
          break;

        case "s3":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.s3.url,
          });
          break;

        case "cloudformation":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.cloudformation.url,
          });
          break;

        case "cloudwatch":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.cloudwatch.url,
          });
          break;

        case "eks":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.eks.url,
          });
          break;

        default:
          this.setState({
            doRedirect: true,
            redirectUrl: {
              pathname: this.getAddIntegrationUrl(
                node.name.toLowerCase(),
                node.type,
              ),
              search: "?edit=true",
              state: { from: "Settings" },
            },
          });
          break;
      }
    }
  };

  toggleConfirmModal = () => {
    this.setState({ showConfirmModal: !this.state.showConfirmModal });
  };

  onRemoveIntegration = (event, nodeName) => {
    event.preventDefault();
    const selectedNode = this.state.formattedData.find(
      val => val.name === nodeName,
    );
    this.setState(
      {
        selectedNode,
        confirmModalResponse: "",
      },
      () => {
        this.toggleConfirmModal();
      },
    );
  };

  getAddIntegrationUrl = (nodeName = "", type) => {
    if (nodeName) {
      /**
       * @case if connector type is integration
       */
      if (type === ConnectorType.integration) {
        return `/settings/integration/${nodeName.toLowerCase()}/description`;
      }
      if (RouteConstants[kebabToCamel(nodeName?.toLowerCase())]) {
        return (
          RouteConstants[kebabToCamel(nodeName?.toLowerCase())].defaultUrl ||
          RouteConstants[kebabToCamel(nodeName?.toLowerCase())].url
        );
      }
    }
  };

  getSettingsConfigNodes = data =>
    !!data.length
      ? data.map((val, index) => (
          <SettingPanelTile
            handleClick={this.handleClick}
            addIntegrationUrl={this.getAddIntegrationUrl(val.name, val.type)}
            key={index}
            name={val.name}
            displayName={val.displayName}
            status={val.status}
            type={val.type}
            handleRemove={this.onRemoveIntegration}
          />
        ))
      : "No Connectors Available";

  removeIntegration = async () => {
    this.props.isFetching(true, "Removing Integraion");
    const connectorName = this.state.selectedNode.name.toLowerCase();
    let response;
    if (connectorName === "terraform_cli") {
      response = await Api.removeTerraformCLIIntegration(
        connectorName,
      ).catch(e => console.log(e));
    } else {
      response = await Api.removeIntegration(connectorName).catch(e =>
        console.log(e),
      );
    }
    this.props.isFetching(false, "");
    if (
      !isEmpty(response) &&
      (response.statusCode === 200 || response.statusCode === 201)
    ) {
      this.toggleConfirmModal();
      this.loadConnectors();
    } else {
      this.setState({
        confirmModalResponse: "Oops! Something went wrong !!!",
      });
    }
  };

  render() {
    return (
      <>
        {this.state.doRedirect && <Redirect to={this.state.redirectUrl} push />}
        {this.state.showConfirmModal && (
          <Modal
            title="Remove Integration"
            showClose={true}
            onClose={this.toggleConfirmModal}
            isDeleteModal
            submitButtonText={`Confirm`}
            onCancel={this.toggleConfirmModal}
            onSubmit={this.removeIntegration}
          >
            Are you sure you want to remove the integration between{" "}
            <b>{this.state.selectedNode.displayName}</b> and <b>Fylamynt</b>?
            {getRemoveMessage()}
            <br />
            <br />
            <span className="error">{this.state.confirmModalResponse}</span>
          </Modal>
        )}

        <div id={`cloud-services`}>
          <SettingsPanelHeader isBreadCrumb={false} heading="Cloud Services" />
          {this.props.isAccountDetailFetched &&
          !this.props.userDefaultAccounts.length ? (
            <CloudWarningCard />
          ) : (
            <>
              <div className="account-id">
                AWS Account ID : {this.props.accountId}
              </div>
              <div className="mt-40-px ml-2 mb-50-px">
                {this.props.responseMessage ? (
                  this.props.responseMessage
                ) : (
                  <>
                    <div className="position-relative">
                      {this.state.cloudConfigNodes}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <div id={`integrations`}>
          <SettingsPanelHeader isBreadCrumb={false} heading="Integrations" />
          <div className="mt-40-px ml-2 mb-50-px">
            {this.state.isLoaded && (
              <div className="position-relative">
                {Array.isArray(this.state.integrationConfigNodes) &&
                this.state.integrationConfigNodes.length !== 0 ? (
                  this.state.integrationConfigNodes
                ) : (
                  <NoResultFound
                    message="No integrations found"
                    detail="There are no integrations to display."
                    className="mt-50-px"
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div id={`resources`}>
          <SettingsPanelHeader isBreadCrumb={false} heading="Resources" />
          <div className="mt-40-px ml-2">
            {this.state.isLoaded && (
              <div className="position-relative">
                {Array.isArray(this.state.resourceConfigNodes) &&
                this.state.resourceConfigNodes.length !== 0 ? (
                  this.state.resourceConfigNodes
                ) : (
                  <NoResultFound
                    message="No resources found"
                    detail="There are no resources to display."
                    className="mt-50-px"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  showConfigPanel: state.settingsPanelReducer.showConfigPanel,
  isLoading: state.settingsPanelReducer.formSaving,
  connectors: state.settingsPanelReducer.connectors,
  responseMessage: state.settingsPanelReducer.responseMessage,
  accountId: state.appReducer.appConfig.awsAccountId,
  message: state.runbooksReducer.message,
  isAccountDetailFetched: state.getStartedReducer.isAccountDetailFetched,
  userDefaultAccounts: state.getStartedReducer.userDefaultAccounts,
  isFetchingDefaultAccounts: state.getStartedReducer.isFetchingDefaultAccounts,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleConfigPanel,
      fetchSettingsConnectors,
      isFetching,
      fetchDefaultAccounts,
    },
    dispatch,
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsPanelContent);
