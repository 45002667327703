import React from "react";
import Tour from "reactour";
import Bugsnag from "@bugsnag/js";
import { Prompt } from "react-router";
import { Redirect } from "react-router-dom";
import { ReactFlowProvider } from "react-flow-renderer";
import { EditorRightPanel } from "./runbook-editor-components";
import EditorDesignArea from "./runbook-editor-components/EditorDesignArea/EditorDesignArea";
import EditorToolbar from "../RunbookEditor/runbook-editor-components/editor-toolbar/EditorToolbar";
import EditorMenu from "./runbook-editor-components/editor-menu-left/EditorMenu";
import EditorSlideouts from "./runbook-editor-components/editor-menu-left/menu-slideouts/EditorSlideouts";
import {
  SaveWarning,
  UnsavedWarning,
} from "./runbook-editor-components/editor-dialogs";
import EditorSaveNewVersionModal from "./runbook-editor-components/editor-menu-left/menu-slideouts/versions/EditorSaveNewVersionModal";
import LimitDialog from "@containers/WarningDialogs/LimitDialog";
import { Provider } from "./runbook-editor-lib/runbook-editor.context";
import { MetaTag, Modal } from "@components/ui";
import { RunbookRun, RunbookScheduleModal } from "@components/modules";
import { getTourSteps } from "./Tour/helpers/TourSteps";
import { RouteConstants } from "../../routes/Constants";

import { shouldForceDefaultValues } from "./runbook-editor-lib/runbook-editor.helpers";
import {
  isEmpty,
  findUnconfiguredNodes,
  sortObject,
  urlHasChanged,
} from "@lib/utils";

import Api from "@lib/api/api";

import WelcomeDialog from "@assets/images/screenshots/welcome-dialog/WelcomeDialog.jpg";
import iconPlus from "@assets/images/icons/icon-plus.svg";

import "./RunbookEditor.scss";
import "./snippets.scss";

export default class RunbookEditor extends React.Component {
  state = {
    activePanel: null,
    activePanelTitle: null,
    runbookId: this.props.match.params.runbookId,
    preparingEditor: true,
    showRunModal: false,
    showSaveWarning: false,
    showUnsavedWarning: false,
    ignoreUnsavedWarning: false,
    hasUpdatedSinceSave: true,
    runbookObj: null,
    savingRunbook: false,
    redirect: false,
    redirectTo: null,
    prepareMessage: null,
    showSaveNewVersionModal: false,
    runModalAsSave: false,
    nextVersion: "",
    reloadEditor: false,
    isScheduleModalOpen: false,
    isWorkflowLimitDialogVisible: false,
    isRunsLimitDialogVisible: false,
    unconfiguredNodes: [],
    isConfigured: true,
    isTourOpen: false,
    isWelcomeDialogVisible: false,
    isEditorOverlayOpen: false,
    clearTagInput: false,
    parsedRuntimeParameters: {},
    isNodeDeleted: false,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.editorReference = React.createRef();
    this.timer = null;
    this.location = window.location.href;
    window.addEventListener("beforeunload", this.beforeunload);
  }

  handleNodeDelete = value => {
    this.setState({ isNodeDeleted: value });
  };

  beforeunload = e => {
    if (this.props.runbookDidUpdate) {
      e.preventDefault();
      e.returnValue =
        "You have unsaved changes, are you sure you want to leave?";
    }
  };

  componentDidMount() {
    if (this.checkIfRunbookExists()) {
      this.setState(
        {
          isExistingRunbook: true,
        },
        () => {
          this.fetchRunbookDetails();
        },
      );
    } else {
      this.fetchRunbookDetails();
    }
    this.props.getResourcesList();
    this.gotoExecutionDetails = false;
    this.props.clearNewRunbook(false);
    this.props.fetchSettingsConnectors();
  }

  componentWillUnmount() {
    this.props.unsetNode();
    this.props.notifyRunbookUpdate(false);
    this.props.unsetActiveRunbook();
    window.removeEventListener("beforeunload", this.beforeunload);
    window.onbeforeunload = null;
  }

  componentDidUpdate(prevProps, prevState) {
    this.isPreparing(prevProps);
    this.isSaving(prevProps);
    this.saveError(prevProps);
    this.loadedDifferentVersion(prevProps);
    this.reloaded(prevState);
    this.saveError(prevProps);
    this.checkIfNeedToRedirect(prevProps);
    if (
      prevState.runbookObj !== this.state.runbookObj ||
      this.props.connectors !== prevProps.connectors ||
      this.state.isNodeDeleted
    ) {
      this.setState(
        {
          unconfiguredNodes: findUnconfiguredNodes(
            this.props.connectors,
            this.state.runbookObj,
          ),
        },
        () => this.handleNodeDelete(false),
      );
    }
  }

  checkIfRunbookExists = () => {
    //TODO : this function will be used to identify if a runbook exists in the list or not via API or persist-store
    return false;
  };

  checkIfNeedToRedirect = prevProps => {
    if (
      !!this.props.executionId &&
      prevProps.executionId !== this.props.executionId &&
      this.gotoExecutionDetails
    ) {
      window.open(
        `${RouteConstants.executionDetails.url}/${this.props.executionId}/steps`,
        "_blank",
      );
      this.setState({ showRunModal: false });
    }
  };

  saveError(prevProps) {
    if (
      this.props.runbookFetchError &&
      this.props.runbookFetchError !== prevProps.runbookFetchError
    ) {
      this.setState(
        {
          isSaving: false,
          savingRunbook: false,
        },
        () => {
          setTimeout(() => {
            this.loadVersion(this.props.activeRunbookVersion);
          }, 2000);
        },
      );
    }
  }

  fetchRunbookDetails = () => {
    if (this.props.runbookNew && isEmpty(this.props.activeRunbook)) {
      this.props.fetchActiveRunbook(this.state.runbookId).then(() => {
        this.setState({ reloadEditor: true });
        this.getSnippets();
      });
    } else if (this.props.runbookNew) {
      this.getSnippets();
    } else {
      /* if we already have an activerunbook, then all we need to do is determine if we need snippets */
      if (this.props.activeRunbook && !isEmpty(this.props.activeRunbook)) {
        this.setState({ reloadEditor: true });
        this.getSnippets();
      }
      this.updateMessage("Getting workflow details, please wait");
      this.props.fetchActiveRunbook(this.state.runbookId).then(message => {
        this.setState({ reloadEditor: true });
        this.getSnippets();
      });
    }
  };

  updateRunbook = (key, value) => {
    this.props.updateRunbookData(key, value);
  };

  getSnippets = () => {
    if (!this.props.snippetsByCategory) {
      this.updateMessage("Getting actions, please wait...");
      this.props.fetchSnippets();
    }
  };

  updateMessage = message => {
    const show = message ? true : false;
    this.props.showWaitMessage(show);
    this.props.setWaitMessage(message);
  };

  runExecution = (runbookName, parameters) => {
    this.props.doExecution(
      runbookName,
      parameters,
      this.props.activeRunbookVersion,
    );
    this.gotoExecutionDetails = true;
  };

  goBack = url => {
    if (url) {
      this.url = url;
    }
    if (!this.state.ignoreUnsavedWarning) {
      if (this.runbookHasChanged()) {
        this.setState({
          showUnsavedWarning: true,
        });
        return;
      }
    }
    this.props.setActiveRunbook(this.state.runbookObj);

    if (this.props.runbookNew) {
      this.props.setRunbookIsNew(false);
    }
    if (!this.url) {
      //this.props.history.push("/workflows");
      /*
       used redirect instead of props.history.push
       as it maintains the browser history stack
       even if we choose to handle context differently
      */
      this.setState({
        redirect: true,
        redirectTo: RouteConstants.runbooks.url,
      });
    } else {
      //this.props.history.push(this.url);
      this.setState({
        redirect: true,
        redirectTo: this.url,
      });
    }
  };

  runbookHasChanged = () => {
    if (this.props.runbookDidUpdate) {
      return true;
    }
    if (!this.props.runbookNew) {
      return false;
    } else if (this.state.ignoreUnsavedWarning) {
      return false;
    } else {
      return true;
    }
  };

  toggleRunbookModal = () => {
    this.setState({
      showRunModal: !this.state.showRunModal,
      runModalAsSave: false,
    });
  };

  toggleWarning = () => {
    this.setState({
      showSaveWarning: !this.state.showSaveWarning,
    });
  };

  toggleUnsavedWarning = () => {
    if (this.props.loaderFlag) {
      this.props.showGlobalLoader();
    }
    this.setState({
      showUnsavedWarning: !this.state.showUnsavedWarning,
    });
  };

  toggleWarningIgnore = () => {
    this.setState(
      {
        ignoreUnsavedWarning: true,
      },
      () => {
        !!this.state.nextVersion
          ? this.loadVersion(this.state.nextVersion)
          : this.goBack();
      },
    );
  };

  toggleScheduleModal = () => {
    if (this.state.showRunModal === true) {
      this.setState(prevState => ({
        isScheduleModalOpen: !prevState.isScheduleModalOpen,
        showRunModal: false,
        runModalAsSave: false,
      }));
    } else if (
      this.state.showRunModal === false &&
      this.state.isScheduleModalOpen === false
    ) {
      this.setState({
        showRunModal: true,
        runModalAsSave: true,
      });
    } else {
      this.setState(prevState => ({
        isScheduleModalOpen: !prevState.isScheduleModalOpen,
      }));
      return;
    }
  };

  /* this gets called when a new node is dropped into the edit area, or specific step information changes */
  updateRunbookObj = runbookObj => {
    this.setState({
      runbookObj,
    });
  };

  updateRunbookDescription = updatedDescription => {
    if (!isEmpty(this.state.runbookObj)) {
      const updatedRunbookObj = Object.assign(
        Object.create(Object.getPrototypeOf(this.state.runbookObj)), // Dev: Vivek - Need to change this logic (Including root cause)
        { ...this.state.runbookObj, description: updatedDescription },
      );

      updatedRunbookObj.setDescription.call(
        updatedRunbookObj,
        updatedDescription,
      );

      this.setState({
        runbookObj: updatedRunbookObj,
      });
    }
  };

  isPreparing(prevProps) {
    if (this.props.isFetchingActiveRunbook) {
      if (!this.state.preparingEditor) {
        this.setState({
          preparingEditor: true,
          message: this.props.fetchingMessage,
        });
      }
    } else if (this.props.isFetchingSnippets) {
      if (!this.state.preparingEditor) {
        this.setState({
          preparingEditor: true,
          message: this.props.fetchingMessage,
        });
      }
    } else if (
      !this.props.isFetchingActiveRunbook &&
      prevProps.isFetchingActiveRunbook
    ) {
      this.setState({ preparingEditor: false });
    } else {
      if (prevProps.preparingEditor !== this.props.preparingEditor) {
        this.setState({ preparingEditor: false }); //, () => this.updateMessage());
      } else if (this.state.preparingEditor) {
        this.setState({ preparingEditor: false }); //, () => this.updateMessage());
      }
    }
  }

  setActivePanel = (activePanel, activePanelTitle) => {
    const _activePanel =
      activePanel === this.state.activePanel ? "" : activePanel;
    this.setState({
      activePanel: _activePanel,
      activePanelTitle,
      isEditorOverlayOpen: true,
    });
  };

  closeTabs = event => {
    this.setState({
      activePanel: null,
      activePanelTitle: "",
      isEditorOverlayOpen: false,
    });
  };

  onDragOver = e => {
    e.preventDefault();
  };

  loadVersion = version => {
    this.state.showUnsavedWarning && this.toggleUnsavedWarning();
    if (!this.state.ignoreUnsavedWarning) {
      if (this.runbookHasChanged()) {
        this.setState({
          showUnsavedWarning: true,
          nextVersion: version,
        });
        return;
      }
    }
    if (!!this.state.nextVersion) {
      this.setState({ ignoreUnsavedWarning: false });
      this.props.notifyRunbookUpdate(false);
    }
    this.setState({ nextVersion: "" }, () => {
      this.updateMessage(`Loading version ${version}`);
      this.props.onChangeVersionAction(this.state.runbookId, version);
    });
  };

  loadedDifferentVersion = prevProps => {
    if (
      prevProps.activeRunbookVersion !== this.props.activeRunbookVersion &&
      !!prevProps.activeRunbookVersion
    ) {
      this.updateMessage();
    }
  };

  reloaded = prevState => {
    if (prevState.runbookObj !== this.state.runbookObj) {
      this.updateMessage();
    }
  };

  saveError = prevProps => {
    if (
      prevProps.runbookSaveError !== this.props.runbookSaveError &&
      this.props.runbookSaveError
    ) {
      this.setState({
        showRunModal: false,
        runModalAsSave: false,
      });
      setTimeout(() => {
        this.updateMessage();
      }, 2000);
    }
  };

  updateActiveNode = (node, Key, value) => {
    this.props.updateNode(node, {
      Key,
      value,
    });
  };

  saveRunbook = async () => {
    // Reset selected node
    this.props.unsetNode();
    // Reset Loader flag
    if (this.props.loaderFlag) {
      this.props.showGlobalLoader();
    }
    if (isEmpty(this.props.activeRunbook.Description)) {
      this.toggleWarning();
      return;
    } else if (shouldForceDefaultValues(this.state.runbookObj)) {
      this.setState({
        showRunModal: true,
        runModalAsSave: true,
      });
      return;
    } else if (this.props.activeRunbookVersion === "Draft") {
      this.props.saveNewRunbook(this.state.runbookObj, this.props.connectors);
    } else {
      this.props.updateRunbook(this.state.runbookObj, this.props.connectors);
    }
    this.setState({
      showRunModal: false,
      runModalAsSave: false,
    });
    this.props.notifyRunbookUpdate(false);

    if (this.state.tryingToSchedule) {
      /// TODO: remove API call from here, handle it better by moving the API call here.
      const showSchedule = await this.showScheduleModal(this.state.runbookObj);
      if (showSchedule) this.toggleScheduleModal();
    }
    this.setState({ reloadEditor: true });
  };

  resetClearTagInputFlag = (flag = false) =>
    this.setState({ clearTagInput: flag });

  addTagToRunbook = async newTag => {
    const Tags = [...this.props.activeRunbook.Tags, newTag];
    if (this.props.activeRunbook.DefaultVersion.toLowerCase() === "draft") {
      this.setState({ clearTagInput: true });
      this.props.updateRunbookTags(Tags);
      return;
    }

    this.updateMessage(`Adding Tag ${newTag}`);
    try {
      await Api.updateRunbookTags(
        this.props.activeRunbook,
        { Tags },
        this.props.activeRunbookVersion,
      );
      this.setState({ clearTagInput: true });
      this.props.updateRunbookTags(Tags);
    } catch (error) {
      Bugsnag.notify(
        new Error(error?.message || "Could not update tags for this version."),
      );
    }
    this.updateMessage("");
  };

  removeTagFromRunbook = async removedTag => {
    const Tags = [...this.props.activeRunbook.Tags].filter(
      tag => tag !== removedTag,
    );
    if (this.props.activeRunbook.DefaultVersion.toLowerCase() === "draft") {
      this.props.updateRunbookTags(Tags);
      return;
    }
    this.updateMessage(`Removing Tag ${removedTag}`);
    try {
      await Api.updateRunbookTags(
        this.props.activeRunbook,
        { Tags },
        this.props.activeRunbookVersion,
      );
      this.props.updateRunbookTags(Tags);
    } catch (error) {
      Bugsnag.notify(
        new Error(error?.message || "Could not remove tag for this version."),
      );
    }
    this.updateMessage("");
  };

  saveNewVersion = () => {
    this.setState({
      showSaveNewVersionModal: true,
    });
  };

  isSaving(prevProps) {
    if (this.props.runbookIsSaving) {
      if (prevProps.runbookIsSaving !== this.props.runbookIsSaving) {
        this.setState({
          preparingEditor: true,
          savingRunbook: true,
          showRunModal: false,
        });
      }
    } else {
      if (
        prevProps.runbookIsSaving !== this.props.runbookIsSaving &&
        prevProps.runbookIsSaving
      ) {
        this.setState(
          {
            preparingEditor: false,
            savingRunbook: false,
            runbookNew: false,
          },
          () => {
            this.props.setRunbookIsNew(false);
            this.props.addToRunbooks(this.props.activeRunbook);
          },
        );
      }
    }
  }

  rerenderEditor = () => {
    this.editorReference.current.rerenderEditor();
  };

  handlePrevStepOptionHover = () => {
    this.editorReference.current.handlePrevStepOptionHover();
  };

  addLinkToStep = (activeNode, runbookStep) => {
    this.editorReference.current.addLinkToStep(activeNode, runbookStep);
  };

  removeLinkToStep = (activeNode, runbookStep) => {
    this.editorReference.current.removeLinkToStep(activeNode, runbookStep);
  };

  goToProfilePage = () => {
    this.setState({
      redirect: true,
      redirectTo: {
        pathname: `${RouteConstants.userProfile.baseUrl}/account-plan`,
      },
    });
  };

  toggleWorkflowLimitDialog = () => {
    this.setState({
      isWorkflowLimitDialogVisible: !this.state.isWorkflowLimitDialogVisible,
    });
  };

  toggleRunsLimitDialog = () => {
    this.setState({
      isRunsLimitDialogVisible: !this.state.isRunsLimitDialogVisible,
    });
  };

  toggleIsTourOpen = () => {
    this.setState({ isTourOpen: !this.state.isTourOpen });
  };

  toggleIsWelcomeDialogOpen = () => {
    this.setState({
      isWelcomeDialogVisible: !this.state.isWelcomeDialogVisible,
    });
  };

  startTour = () => {
    this.toggleIsWelcomeDialogOpen();
    this.toggleIsTourOpen();
  };

  setParsedRuntimeParameters = parsedRuntimeParameters => {
    this.setState({
      parsedRuntimeParameters,
    });
  };

  render() {
    const tags = {
      meta: {
        content:
          "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0",
        name: "viewport",
      },
    };
    const contextValue = {
      activePanel: this.state.activePanel,
      activePanelTitle: this.state.activePanelTitle,
      activeRunbookVersion: this.props.activeRunbookVersion,
      addTagToRunbook: this.addTagToRunbook,
      removeTagFromRunbook: this.removeTagFromRunbook,
      setActivePanel: this.setActivePanel,
      snippets: this.props.snippetsByCategory,
      versions: sortObject(
        this.props.activeRunbook ? this.props.activeRunbook["Versions"] : [],
        "DocumentVersion",
        "desc",
        true,
      ),
      runbookId: this.state.runbookId,
      loadVersion: this.loadVersion,
      toggleRunbookModal: this.toggleRunbookModal,
      runbook: this.props.activeRunbook,
      activeNode: this.props.activeNode,
      updateActiveNode: this.updateActiveNode,
      activeActionNode: this.props.activeActionNode,
      setActiveActionNode: this.props.setActiveActionNode,
      clearActiveActionNode: this.props.clearActiveActionNode,
      updateRunbookObj: this.updateRunbookObj,
      runbookObj: this.state.runbookObj,
      saveRunbook: this.saveRunbook,
      goBack: this.goBack,
      updateRunbook: this.updateRunbook,
      showRunModal: this.state.showRunModal,
      fetchAwsServices: this.props.fetchAwsServices,
      fetchAwsOperations: this.props.fetchAwsOperations,
      fetchAwsOperationDetails: this.props.fetchAwsOperationDetails,
      awsServices: this.props.awsServices,
      awsOperations: this.props.awsOperations,
      awsOperationDetails: this.props.awsOperationDetails,
      runbookDidUpdate: this.props.runbookDidUpdate,
      notifyRunbookUpdate: this.props.notifyRunbookUpdate,
      deleteRunbookVersion: this.props.deleteRunbookVersion,
      runbookVersionDeleteStatus: this.props.runbookVersionDeleteStatus,
      datadogMonitorList: this.props.datadogMonitorList,
      currentDatadogMonitor: this.props.currentDatadogMonitor,
      datadogError: this.props.datadogError,
      isFetchingDatadogMonitorList: this.props.isFetchingDatadogMonitorList,
      isFetchingDatadogMonitor: this.props.isFetchingDatadogMonitor,
      isConnectingDatadogMonitor: this.props.isConnectingDatadogMonitor,
      getDatadogMonitorList: this.props.getDatadogMonitorList,
      getDatadogMonitor: this.props.getDatadogMonitor,
      updateDefaultVersion: this.props.updateDefaultVersion,
      lastAwsOperationFetched: this.props.lastAwsOperationFetched,
      terraformWorkspaces: this.props.terraformWorkspaces,
      resourcesList: this.props.resourcesList,
      isVersionUpdateSuccess: this.props.isVersionUpdateSuccess,
      isVersionUpdateFailed: this.props.isVersionUpdateFailed,
      isFetchingVersion: this.props.isFetchingVersion,
      toggleScheduleModal: this.toggleScheduleModal,
      runbookSchedule: this.props.runbookSchedule,
      connectors: this.props.connectors,
      resetWalkthrough: this.toggleIsWelcomeDialogOpen,
      clearTagInput: this.state.clearTagInput,
      resetClearTagInputFlag: this.resetClearTagInputFlag,
      unsetNode: this.props.unsetNode,
      allSnippets: this.props.snippets,
      handlePrevStepOptionHover: this.handlePrevStepOptionHover,
    };

    return (
      <ReactFlowProvider>
        <Provider value={contextValue}>
          {this.state.isWelcomeDialogVisible && (
            <Modal
              onClose={this.toggleIsWelcomeDialogOpen}
              title="Welcome to The Workflow Editor"
              showClose={true}
              backgroundCanClose={false}
              coverClass={`modal-cover__editor-welcome`}
              containerClass={`modal-container__editor-welcome`}
              contentClass={`modal-content__editor`}
              showFooter={false}
            >
              <div className="welcome-dialog">
                <div className="welcome-dialog-image-container">
                  <img alt="welcome" src={WelcomeDialog}></img>
                </div>
                <span>
                  The Fylamynt Workflow Editor allows you to build Workflows
                  with little or no code. Fylamynt's visual interface is fast,
                  easy-to-use and flexible.
                  <br />
                  <br />
                  Let's take a look around. It shouldn't take more than a
                  minute.
                </span>
                <div className="welcome-dialog-footer">
                  <button
                    className="welcome-dialog-footer-button"
                    onClick={this.startTour}
                    type="button"
                  >
                    Let's Go
                  </button>
                </div>
              </div>
            </Modal>
          )}
          <Tour
            onRequestClose={this.toggleIsTourOpen}
            steps={getTourSteps(
              this.toggleIsTourOpen,
              this.toggleIsWelcomeDialogOpen,
            )}
            isOpen={this.state.isTourOpen}
            maskClassName="mask"
            maskSpace={0}
            className={`helper`}
            rounded={0}
            showNavigation={false}
            showNavigationNumber={false}
            showNumber={false}
            showButtons={false}
            closeWithMask={false}
          />
          <MetaTag tags={tags} removeMetaTags={["viewport"]} />
          {this.state.showSaveNewVersionModal && (
            <EditorSaveNewVersionModal
              onClose={() => {
                this.setState({ showSaveNewVersionModal: false });
              }}
            />
          )}
          <div className="editor" ref={this.ref}>
            <EditorMenu openTour={this.toggleIsTourOpen} />
            <EditorSlideouts
              updateRunbookDescription={this.updateRunbookDescription}
              actionButton={{
                icon: iconPlus,
                action: this.saveNewVersion,
                activePanel: "versions",
              }}
              closePanel={this.closeTabs}
              activePanel={this.state.activePanel}
              isEditorOverlayOpen={this.state.isEditorOverlayOpen}
            />
            <div className="editor-middle">
              <EditorToolbar
                toggleWorkflowLimitDialog={this.toggleWorkflowLimitDialog}
                toggleRunsLimitDialog={this.toggleRunsLimitDialog}
                unconfiguredNodes={this.state.unconfiguredNodes}
              />
              <EditorDesignArea
                editorRef={this.editorReference}
                preparing={this.state.preparingEditor}
                reloadEditor={this.state.reloadEditor}
                snippets={this.props.snippets}
                connectors={this.props.connectors}
                runbook={this.props.activeRunbook}
                runbookObj={this.state.runbookObj}
                setActiveNode={this.props.setActiveNode}
                updateRunbookObj={this.updateRunbookObj}
                notifyRunbookUpdate={this.props.notifyRunbookUpdate}
                fetchAwsOperations={this.props.fetchAwsOperations}
                fetchAwsOperationDetails={this.props.fetchAwsOperationDetails}
                awsOperationDetails={this.props.awsOperationDetails}
                selectedTrigger={this.props.selectedTrigger}
                isNodeDeleted={this.state.isNodeDeleted}
                handleNodeDelete={this.handleNodeDelete}
              />
            </div>
            <EditorRightPanel
              rerenderEditor={this.rerenderEditor}
              addLinkToStep={this.addLinkToStep}
              removeLinkToStep={this.removeLinkToStep}
              fetchJiraDetails={this.props.fetchJiraDetails}
              fetchTargetAccountAlarmsList={
                this.props.fetchTargetAccountAlarmsList
              }
              unconfiguredNodes={this.state.unconfiguredNodes}
            />
            {this.state.showRunModal && (
              <RunbookRun
                runbookObj={this.state.runbookObj}
                toggleRunbookModal={this.toggleRunbookModal}
                runExecution={this.runExecution}
                runModalAsSave={this.state.runModalAsSave}
                setParsedRuntimeParameters={this.setParsedRuntimeParameters}
                toggleScheduleModal={this.toggleScheduleModal}
              />
            )}
            {this.state.isScheduleModalOpen && (
              <RunbookScheduleModal
                toggleScheduleModal={this.toggleScheduleModal}
                currentSchedule={this.props.runbookSchedule}
                selectedRequiredParameters={this.state.parsedRuntimeParameters}
              />
            )}
            {this.state.redirect && (
              <Redirect to={this.state.redirectTo} push />
            )}
            {this.state.showSaveWarning && (
              <SaveWarning toggleWarning={this.toggleWarning} />
            )}
            {this.state.showUnsavedWarning && (
              <UnsavedWarning
                toggleWarning={this.toggleUnsavedWarning}
                toggleWarningIgnore={this.toggleWarningIgnore}
              />
            )}
          </div>
          {!this.state.showUnsavedWarning && (
            /* we need urlHasChanged to detect if it's just a hashtag that changed (which we can ignore ) */
            <Prompt
              when={this.props.runbookDidUpdate && urlHasChanged(this.location)}
              message="You have unsaved changes, are you sure you want to leave?"
            />
          )}
          {this.state.isRunsLimitDialogVisible && (
            <LimitDialog
              limitType={"runs"}
              goTo={this.goToProfilePage}
              toggleDialog={this.toggleRunsLimitDialog}
            />
          )}
          {this.state.isWorkflowLimitDialogVisible && (
            <LimitDialog
              limitType={"workflow"}
              goTo={this.goToProfilePage}
              toggleDialog={this.toggleWorkflowLimitDialog}
            />
          )}
        </Provider>
      </ReactFlowProvider>
    );
  }
}
