import React from "react";
import { Link } from "react-router-dom";
import { TextInput } from "@components/ui/";
import { Consumer } from "../snippets-lib/snippets.context";
import { getFilteredSnippets } from "@lib/utils";
import { MultiSelect } from "carbon-components-react";

export default SnippetsFilters => (
  <Consumer>
    {({
      snippetsList,
      searchTerm,
      searchSnippets,
      resetFilters,
      filterSnippetsByService,
      filteredServicesSet,
    }) => {
      let list = getFilteredSnippets(
        snippetsList,
        searchTerm,
        filteredServicesSet,
      );

      // get list of services in the orginal snippets list for service dropdown filter
      const services = snippetsList.reduce((acc, item) => {
        //Below check is for filtering out sd2.0 snippets

        item.service.forEach(s => {
          acc.add(s);
        });
        // }
        return acc;
      }, new Set());

      // get count of services in the filterd snippets list
      let servicesFiltered = list.reduce((acc, item) => {
        acc.add(...item.service);
        return acc;
      }, new Set());

      const unmapStatus = (statusArrObj = []) => {
        const unMappedArray = statusArrObj.map(statusObj => {
          return statusObj.text;
        });
        return new Set(unMappedArray);
      };

      const mapStatus = item => {
        return item.map((status, key) => ({
          id: key,
          text: status,
          label: status,
        }));
      };

      const onClearFilters = () => {
        const ele = document.querySelectorAll(
          ".snippets-service-filter .bx--list-box__selection ",
        );
        if (ele?.length > 0) {
          ele[0].click();
        }
        resetFilters();
      };

      const mapServices = mapStatus(Object.values([...services]));

      return (
        <>
          <div className="d-flex justify-content-between mt-40-px">
            <TextInput
              placeholder="Search"
              name="snippets-search"
              className="text-input text-input__darker"
              containerClass="snippets-filter-input"
              onKeyUp={searchSnippets}
              value={searchTerm}
            />
            <MultiSelect
              ariaLabel="MultiSelect"
              default
              id="snippets-service-multiselect"
              onChange={({ selectedItems }) => {
                filterSnippetsByService(unmapStatus(selectedItems));
              }}
              className="snippets-service-filter"
              items={mapServices}
              label="Select Service"
              itemToString={item => (item ? item.text : "")}
            />
          </div>
          <div className="snippets-clear-filters">
            {!!list.length
              ? `${list.length} actions across
            ${servicesFiltered.size} service‍s`
              : null}
            <Link to={`#`} onClick={onClearFilters}>
              Clear all filters
            </Link>
          </div>
        </>
      );
    }}
  </Consumer>
);
