import React from "react";
import Logo from "./menu-items/logo";
import Add from "./menu-items/add";
import Versions from "./menu-items/versions";
import Settings from "./menu-items/settings";
import Search from "./menu-items/search";
import Help from "./menu-items/help";
import "./EditorMenu.scss";

export default class EditorMenu extends React.Component {
  render() {
    return (
      <div className="editor-menu">
        <div className="editor-menu-top">
          <Logo />
          <Add />
          <Versions />
          <Settings />
        </div>
        <div className="editor-menu-bottom">
          <Search />
          <Help />
        </div>
      </div>
    );
  }
}
