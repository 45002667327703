import React from "react";
import { HeaderMain } from "@components/modules";
import { Button } from "@components/ui";

const TaskManagementHeader = ({
  heading,
  isBreadCrumb,
  breadCrumbText,
  setIsCreateTaskModalOpen,
}) => {
  return (
    <HeaderMain
      title={heading}
      showBreadcrumbs={isBreadCrumb}
      breadCrumbText={breadCrumbText}
    >
      <Button
        buttonStyle="primary"
        onClick={() => setIsCreateTaskModalOpen(true)}
        size="small"
        text="New Incident"
        icon="icon-plus"
        className="btn__new-task"
      ></Button>
    </HeaderMain>
  );
};

export default TaskManagementHeader;
