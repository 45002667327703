import produce from "immer";
import { FORM_SUBMITTING } from "../types";

const INITIAL_STATE = {
  formIsSubmitting: false,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FORM_SUBMITTING:
        draft.formIsSubmitting = action.payload;
        break;

      default:
        break;
    }
  });
