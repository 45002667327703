import React from "react";
import { IconSearch } from "@components/ui/icons";

export default class Search extends React.Component {
  render() {
    return (
      <div className="editor-menu-item hidden">
        <IconSearch className="editor-menu-item-icon  editor-menu-item-icon-svg" />
      </div>
    );
  }
}
