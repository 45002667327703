import React from "react";
import { Consumer } from "../../../../../runbook-editor-lib/runbook-editor.context";
import Snippet from "./Snippet";
import {
  sortObject,
  getBlacklistConnectorList,
  categorizeSnippetsByService,
} from "@lib/utils";

export default class SnippetList extends React.Component {
  getSnippetExecution = (allSnippets = [], blacklistConnectorList) => {
    let snippetList = allSnippets ? [...allSnippets] : [];
    return sortObject(
      snippetList.map(snippet => {
        let rSnippet = { ...snippet };
        if (
          Array.isArray(snippet.connectors) &&
          snippet.connectors.some(connector =>
            blacklistConnectorList.includes(connector),
          )
        ) {
          rSnippet["isDisconnected"] = true;
        }
        return rSnippet;
      }),
      "name",
    );
  };
  render() {
    return (
      <Consumer>
        {({ connectors, allSnippets }) => {
          const blacklistConnectorList = getBlacklistConnectorList(connectors);

          let snippets = this.getSnippetExecution(
            allSnippets,
            blacklistConnectorList,
          );

          let snippetsByService = categorizeSnippetsByService(snippets);
          let sortedSnippetsByService = [
            ...new Set([
              "Core",
              "Control Structures",
              "Utilities",
              ...Object.keys(snippetsByService)
                .filter(key => key !== "Trigger")
                .sort(),
            ]),
          ];

          return sortedSnippetsByService.map((snippetName, index) => {
            return (
              <Snippet
                index={index}
                snippets={snippetsByService}
                snippetName={snippetName}
                key={snippetName}
                searchVal={this.props.searchVal}
                blacklistConnectorList={blacklistConnectorList}
              />
            );
          });
        }}
      </Consumer>
    );
  }
}
