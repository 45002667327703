import AddTagsImage from "@assets/images/screenshots/get-started/add_tags.jpeg";
import AttachPermissionPoliciesImage from "@assets/images/screenshots/get-started/attach_permission_policies.jpeg";
import CreateRoleImage from "@assets/images/screenshots/get-started/create_role_aws_img.jpeg";
import React from "react";
import RightPanelImage from "@assets/images/screenshots/get-started/aws_right_panel.png";

export default () => (
  <>
    <div className="list-row">
      <div className="step-number">
        <div>1</div>
      </div>
      <div>Go to the IAM console for your AWS target account.</div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>2</div>
      </div>
      <div>
        Select <q>Create role</q>
      </div>
    </div>
    <div className="list-image">
      <img src={CreateRoleImage} alt="Create Role" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>3</div>
      </div>
      <div>
        Select <q>Another AWS account</q> for the Role Type.
      </div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>4</div>
      </div>
      <div>
        For <q>Account ID</q>, copy-paste <q>Your Fylamynt Account ID</q> from
        the right panel.
        <em>
          &nbsp; This is the AWS account ID that hosts the Fylamynt application,
          and you are granting that account access to your AWS target account.
        </em>
      </div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>5</div>
      </div>
      <div>
        Check off <q>Require external ID</q> and copy-paste the “External ID”
        from the right panel.
        <em> Make sure you leave Require MFA disabled.</em>
        &nbsp;For more information about the External ID, check out
        <a
          href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_create_for-user_externalid.html"
          className="link-light-blue"
        >
          &nbsp; this document in the IAM User Guide
        </a>
        .
      </div>
    </div>
    <div className="list-image">
      <img
        src="https://assets.website-files.com/5d5b7e4beaf3b7f4fce62862/5df92855e51cd18fb486e2bd_aws-image.png"
        srcSet="https://assets.website-files.com/5d5b7e4beaf3b7f4fce62862/5df92855e51cd18fb486e2bd_aws-image-p-500.png 500w, https://assets.website-files.com/5d5b7e4beaf3b7f4fce62862/5df92855e51cd18fb486e2bd_aws-image-p-800.png 800w, https://assets.website-files.com/5d5b7e4beaf3b7f4fce62862/5df92855e51cd18fb486e2bd_aws-image-p-1080.png 1080w, https://assets.website-files.com/5d5b7e4beaf3b7f4fce62862/5df92855e51cd18fb486e2bd_aws-image-p-1600.png 1600w, https://assets.website-files.com/5d5b7e4beaf3b7f4fce62862/5df92855e51cd18fb486e2bd_aws-image.png 1948w"
        sizes="(max-width: 479px) 92px, (max-width: 767px) 35vw, 40vw"
        alt=""
      />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>6</div>
      </div>
      <div>Click Next: Permissions.</div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>7</div>
      </div>
      <div>
        If you already have a policy, select it. Otherwise, the Fylamynt team
        can help you create a policy that suits your needs. For non-critical
        <em> test</em> accounts, you can choose the PowerUserAccess managed
        policy. This will unlock the full functionality of the Fylamynt
        platform.
      </div>
    </div>
    <div className="list-image">
      <img
        src={AttachPermissionPoliciesImage}
        alt="Attach Permission Policies"
      />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>8</div>
      </div>
      <div>Select the appropriate tags and proceed.</div>
    </div>
    <div className="list-image">
      <img src={AddTagsImage} alt="Add Tags" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>9</div>
      </div>
      <div>
        Name the role and add a description that denotes that this role is for
        Fylamynt access. Click <q>Create Role</q>. Copy-paste the ARN for the
        created role from the AWS IAM console into the <q>AssumeRole ARN</q>{" "}
        field in the right panel on this Fylamynt page.
      </div>
    </div>
    <div className="list-image">
      <img src={RightPanelImage} alt="Right Panel" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>10</div>
      </div>
      <div>
        Enter a friendly name, or alias, for this AWS target account into the
        <q>Account Alias</q> field in the right panel on this page. Alias may
        contain any alphabets and dashes. You select a <q>target</q> environment
        that your Fylamynt workflow run against by picking one of your target
        accounts as runtime input.
      </div>
    </div>

    <div className="list-row">
      <div className="step-number">
        <div>11</div>
      </div>
      <div>
        Enter the AWS region name where your Fylamynt workflows will run
        against.
      </div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>12</div>
      </div>
      <div>
        Click <q>Add Target Account</q>. You are now able to use Fylamynt with
        this account!
      </div>
    </div>
  </>
);
