import * as d3 from "d3";
import { executionStatusColorMap } from "@lib/utils/constants";
import flatten  from "lodash/flatten";

export function render(parent, filterData) {
  function redraw() {
    let _data = flatten(Object.values(filterData));
    if (parent.childNodes.length > 0) {
      parent.removeChild(parent.childNodes[0]);
    }

    var xmlns = "http://www.w3.org/2000/svg";
    const element = document.createElementNS(xmlns, "svg");
    parent.appendChild(element);

    element.style.width = "100%";
    element.style.height = "100%";

    const formatData = _data => {
      _data = _data.sort((a, b) => {
        return a.date > b.date ? 1 : -1;
      });
      return _data.map(function (d, i) {
        return { time: timeValue(d), value: dataValue(d) };
      });
    };

    // Feel free to change or delete any of the code you see in this editor!

    var svg = d3.select(element),
      margin = { top: 5, right: 10, bottom: 5, left: 40 },
      width = parent.offsetWidth - margin.left - margin.right,
      height = 160 - margin.top - margin.bottom;
    svg
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    const timeValue = function (d) {
      let _date = new Date(d.date).getTime();
      return _date;
    };
    const dataValue = function (d) {
      return +d.value;
    };
    const data = formatData(_data);

    var x = d3
      .scaleTime()
      .rangeRound([0, width - margin.left - margin.right])
      .domain(
        d3.extent(data, function (d) {
          return d.time;
        }),
      );
    var y = d3
      .scaleLinear()
      .rangeRound([height - margin.top - margin.bottom, 0])
      .domain(
        d3.extent(data, function (d) {
          return d.value;
        }),
      );

    var g = d3.selectAll("g");

    svg.data([data]).enter().append("g");
    // g.append("path")
    //   .data([data])
    //   .attr("class", "data")
    //   .attr("fill", "none")
    //   .attr("stroke", "#6300ff")
    //   .attr("stroke-linejoin", "round")
    //   .attr("stroke-linecap", "round")
    //   .attr("stroke-width", 4);

    g.append("g")
      .attr("class", "axisx")
      .attr("fill", "#b3b3b3")
      .attr("transform", "translate(0," + (height - margin.bottom) + ")")
      .call(d3.axisBottom(x).ticks(5))
      .select(".domain")
      .remove();
    g.append("g")
      .attr("class", "axis y")
      .append("text")
      .attr("fill", "#b3b3b3")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dy", "0.71em")
      .attr("text-anchor", "end")
      .text("Data");
    g.select("g.axis.y").attr("class", "axis y").call(d3.axisLeft(y));
    g.append("path").attr("class", "data");
    function transition(path) {
      path.transition().duration(2000).attrTween("stroke-dasharray", tweenDash);
    }
    function tweenDash() {
      var l = this.getTotalLength(),
        i = d3.interpolateString("0," + l, l + "," + l);
      return function (t) {
        return i(t);
      };
    }
    let dataGroup = Object.keys(filterData).map(val => {
      const data = formatData(filterData[val]);
      return { key: val, values: data };
    });
    dataGroup.map(function (d, i) {
      var line = d3
        .line()
        .x(function (d) {
          return x(d.time);
        })
        .y(function (d) {
          return y(d.value);
        });
      return g
        .append("svg:path")
        .attr("d", line(d.values))
        .attr("stroke", executionStatusColorMap[d.key])
        .attr("stroke-width", 2)
        .attr("fill", "none")
        .call(transition);
    });
  }
  redraw();

  window.addEventListener("resize", redraw);
}
