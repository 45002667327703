import React from "react";
import Stat from "./stat";
import { getExecutionsStats } from "../../executions-lib/executions.helpers";

export default props => {
  if (props.filteredExecutionsBySearchTerm) {
    const {
      succeeded,
      pending,
      failed,
      cancelled,
      waiting,
      inProgress,
      timedOut,
      total,
    } = getExecutionsStats(props.filteredExecutionsBySearchTerm);
    return (
      <div className="w-100 statline">
        {[
          { label: "Total", value: total, classPrefix: "all" },
          { label: "Succeeded", value: succeeded, classPrefix: "success" },
          { label: "Failed", value: failed, classPrefix: "failed" },
          { label: "Pending", value: pending, classPrefix: "pending" },
          { label: "Waiting", value: waiting, classPrefix: "waiting" },
          { label: "Cancelled", value: cancelled, classPrefix: "cancelled" },
          { label: "InProgress", value: inProgress, classPrefix: "inprogress" },
          { label: "TimedOut", value: timedOut, classPrefix: "timedout" },
        ].map(({ label, value, classPrefix }) => (
          <Stat
            label={label}
            key={label.toLowerCase()}
            value={value}
            className={`${classPrefix}-stats`}
          />
        ))}
      </div>
    );
  } else {
    return <React.Fragment />;
  }
};
