import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import { isEmpty } from "@lib/utils";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import Api from "@lib/api/api";
import "./RequiredInputs.scss";
import { Link } from "react-router-dom";
import { RouteConstants } from "../../../../../../../routes/Constants";

type RequiredInputsProps = {};

const RequiredInputs: React.FC<RequiredInputsProps> = () => {
  const getTimeout = runbookNode => {
    if (runbookNode.timeout) {
      switch (runbookNode.timeoutUnit) {
        case "Seconds":
          return runbookNode.timeout;
        case "Minutes":
          return runbookNode.timeout / 60;
        case "Hours":
          return runbookNode.timeout / 3600;
      }
    } else {
      return "";
    }
  };
  const isFirstRun = useRef(true);
  const context = useContext(RunbookEditorContext) as any;
  const runbookNode = context.activeNode.extras.runbookNode;
  const notifyRunbookUpdate = context.notifyRunbookUpdate;
  const [slackChannelList, setSlackChannelList] = useState([]);
  const [isFetchingSlackChannels, setIsFetchingSlackChannels] = useState(false);
  const [selectedSlackChannel, setSelectedSlackChannel] = useState("");
  const [selectedApprovalNotes, setSelectedApprovalNotes] = useState("");
  const [selectedTimeout, setSelectedTimeout] = useState(
    getTimeout(runbookNode),
  );
  const [selectedTimeoutUnit, setSelectedTimeoutUnit] = useState(
    runbookNode.timeoutUnit ? runbookNode.timeoutUnit : "Seconds",
  );
  const [showTimeoutError, setShowTimeoutError] = useState(false);
  const { goBack } = context;
  const getParamInput = useCallback(
    (input: string) => runbookNode.parameterInputs.find(p => p.name === input),
    [runbookNode.parameterInputs],
  );

  const getSlackChannelList = useCallback(async () => {
    setIsFetchingSlackChannels(true);
    try {
      const res = await Api.fetchSlackChannels("slack");
      let slackChannelList = typeof res === "string" ? JSON.parse(res) : res;
      setSlackChannelList(slackChannelList.channels);
    } catch (error) {
      console.log(error);
    }
    setIsFetchingSlackChannels(false);
  }, []);

  const setInitialParamValues = useCallback(() => {
    const slackChannelName = getParamInput("slack_channel_name").source
      ?.sourceValue;
    if (slackChannelName && slackChannelName.constructor.name === "String") {
      setSelectedSlackChannel(slackChannelName);
    }

    const approvalNotes = getParamInput("approval_notes").source?.sourceValue;
    if (approvalNotes && approvalNotes.constructor.name === "String") {
      setSelectedApprovalNotes(approvalNotes);
    }
  }, [getParamInput]);

  useEffect(() => {
    if (isFirstRun.current) {
      setInitialParamValues();
      isFirstRun.current = false;
    }
  }, [setInitialParamValues]);

  useEffect(() => {
    getSlackChannelList();
  }, [getSlackChannelList]);

  const setPayload = (input, value) => {
    switch (input) {
      case "approval_notes":
        let approvalNotes = getParamInput("approval_notes");
        approvalNotes.source = new RunbookStepInputSource(`constant`, value);
        break;
      case "slack_channel_name":
        let slackChannelName = getParamInput("slack_channel_name");
        slackChannelName.source = new RunbookStepInputSource(`constant`, value);
        break;
      case "timeout":
        setShowTimeoutError(false);
        setTimeout(getTimeoutInSeconds(value, selectedTimeoutUnit));
        break;
      case "timeout_unit":
        setShowTimeoutError(false);
        runbookNode.setTimeoutUnitForPauseNode(value);
        setTimeout(getTimeoutInSeconds(selectedTimeout, value));
        break;
    }
    notifyRunbookUpdate(true);
  };

  const getTimeoutInSeconds = (selectedTimeout, selectedTimeoutUnit) => {
    let timeout = selectedTimeout;
    if (selectedTimeoutUnit === "Minutes") timeout = timeout * 60;
    else if (selectedTimeoutUnit === "Hours") timeout = timeout * 60 * 60;
    return timeout;
  };

  const setTimeout = timeout => {
    /**
     * Maximum allowed timeout is 7 days
     */
    if (timeout <= 604800) runbookNode.setTimeoutForPauseNode(timeout);
    else {
      runbookNode.setTimeoutForPauseNode("");
      setShowTimeoutError(true);
    }
  };

  return (
    <Accordion isExpanded={true} useArrow={true}>
      <AccordionLabel className="editor-accordion-label mt-10-px">
        Required Inputs
      </AccordionLabel>
      <AccordionSection>
        <div className="editor-detail-panel editor-detail-panel-column">
          {isFetchingSlackChannels ? (
            <label className="ci-input-label loading-text">
              <i>Fetching Slack Channels</i>
            </label>
          ) : !!slackChannelList && slackChannelList.length > 0 ? (
            <>
              <label className="label">Select Slack Channel to notify</label>
              <ReactSelect
                id="approval-slack-channel"
                name="approval-slack-channel"
                value={{
                  value: selectedSlackChannel,
                  label: selectedSlackChannel
                    ? selectedSlackChannel
                    : "Select from below",
                }}
                handleChange={data => {
                  if (data === null) {
                    setSelectedSlackChannel("");
                    setPayload("slack_channel_name", "");
                  } else if (!isEmpty(data) && !!data.value) {
                    setSelectedSlackChannel(data.value);
                    setPayload("slack_channel_name", data.value);
                  }
                }}
                selectOptions={slackChannelList.map(channel => {
                  return {
                    value: channel,
                    label: channel,
                  };
                })}
                isSearchable={false}
                required
              />
            </>
          ) : (
            <label className="label mb-0">
              Please set up Slack Channel &nbsp;
              <Link
                className="settings-link"
                onClick={e => {
                  goBack(RouteConstants.slack.url);
                  e.stopPropagation();
                }}
              >
                here.
              </Link>
            </label>
          )}
        </div>
        <div className="editor-detail-panel editor-detail-panel-column">
          <label className="label">Notes for reviewer</label>
          <div className="d-flex">
            <textarea
              id="approval-notes"
              onChange={e => {
                setSelectedApprovalNotes(e.target.value);
                setPayload("approval_notes", e.target.value);
              }}
              value={selectedApprovalNotes}
              className={
                !!selectedApprovalNotes
                  ? "compact-text-area "
                  : "compact-text-area  text-area-error"
              }
              required={true}
            />
          </div>
        </div>
        <div className="editor-detail-panel editor-detail-panel-column">
          <label className="label">Interval before triggering time out</label>
          <div className="d-flex">
            <input
              id="approval-timeout"
              name={`timeout`}
              value={`${selectedTimeout}`}
              onChange={e => {
                setPayload("timeout", e.target.value);
                setSelectedTimeout(e.target.value);
              }}
              autoComplete="off"
              className={`rule-input p-10-px w-auto apr-time-input ${
                !selectedTimeout || showTimeoutError ? "rule-input-error" : ""
              }`}
            />
            <ReactSelect
              id={`approval-time-unit-${Math.floor(1000 * Math.random())}`}
              name="approval-time-unit"
              value={{
                value: selectedTimeoutUnit,
                label: selectedTimeoutUnit
                  ? selectedTimeoutUnit
                  : "Select from below",
              }}
              handleChange={data => {
                if (data === null) {
                  setPayload("timeout_unit", "");
                  setSelectedTimeoutUnit("");
                } else if (!isEmpty(data) && !!data.value) {
                  setPayload("timeout_unit", data.value);
                  setSelectedTimeoutUnit(data.value);
                }
              }}
              className="apr-select-unit"
              selectOptions={[
                { value: "Seconds", label: "Seconds" },
                { value: "Minutes", label: "Minutes" },
                { value: "Hours", label: "Hours" },
              ]}
              isSearchable={false}
              isClearable={false}
            />
          </div>
          {showTimeoutError && (
            <div className="apr-timeout-error">
              Maximum allowed timeout is 7 days
            </div>
          )}
        </div>
      </AccordionSection>
    </Accordion>
  );
};

export default RequiredInputs;
