import { connect } from "react-redux";
import Schedules from "../Schedules";
import { fetchSchedules } from "@redux/actions/schedules.action";

const mapState = ({ schedulesReducer }) => ({
  showLoading: true,
  scheduledRunbooks: schedulesReducer.scheduledRunbooks,
  isFetchingSchedules: schedulesReducer.isFetchingSchedules,
  message: schedulesReducer.message,
});

const mapDispatch = dispatch => ({
  fetchSchedules: (runbook, version, showWait) => dispatch(fetchSchedules(runbook, version, showWait)),
});

export default connect(mapState, mapDispatch)(Schedules);
