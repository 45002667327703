import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { useLocation } from "react-router-dom";

import Headers from "./Utils/Headers";
import Description from "./Utils/Description";
import DefaultForm from "./Utils/DefaultForm";
import { TabCollection, Tab, TabContent, Modal } from "@components/ui";

import {
  fetchConnectorActions,
  fetchConnectorSchema,
} from "@redux/actions/settingsPanel.action";
import TaskTypeAssignment from "./Utils/TaskTypeAssignment";
import ExternalLinkIcon from "@assets/images/icons/icon-external__blue.svg";
import Api from "@lib/api";
import { getRedirectUrl, isEmpty } from "@lib/utils";
import { RouteConstants } from "../../../routes/Constants";
import { getRemoveMessage } from "./Utils/Helpers";

import "./GetStartedForm.scss";
import "./Utils/LeftPanel.scss";
import "./Utils/RightPanel.scss";

type GetStartedProps = {
  connectorForm?: any;
  showCustomForm?: boolean;
  customHeader?: React.ReactElement;
  customContainerClass?: string;
  customTitleClass?: string;
  customPanelClass?: string;
  customPanelLeftClass?: string;
  showWarningModal?: boolean;
  connectorProps?: any;
};

const GetStarted: React.FC<GetStartedProps> = ({
  connectorForm,
  showCustomForm,
  customHeader,
  customContainerClass = "",
  customTitleClass = "",
  customPanelClass = "",
  showWarningModal = false,
  connectorProps = {},
}) => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const isEditMode = new URLSearchParams(search).get("edit") ? true : false;
  const path = useLocation()?.pathname?.split("/");
  const connectorName = path?.[3];
  const history = useHistory();
  let ConnectorForm = connectorForm;

  const [
    isRemoveIntegrationConfirmOpen,
    setIsRemoveIntegrationConfirmOpen,
  ] = useState(false);

  const [confirmModalResponse, setConfirmModalResponse] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [
    isNewTaskTypeAssignmentOpen,
    setIsNewTaskTypeAssignmentOpen,
  ] = useState(false);
  const [
    isDeleteTaskTypeAssignmentOpen,
    setIsDeleteTaskTypeAssignmentOpen,
  ] = useState(false);

  const currentNodeSchema = useSelector(
    (state: RootStateOrAny) => state.settingsPanelReducer.currentNodeSchema,
  );

  const connectorActions = useSelector(
    (state: RootStateOrAny) => state.settingsPanelReducer.actions,
  );

  const formContent = showCustomForm ? (
    <ConnectorForm resetForm={resetForm} {...connectorProps} />
  ) : (
    <DefaultForm
      connector={connectorName}
      displayName={currentNodeSchema?.display_name}
      currentNodeSchema={currentNodeSchema}
      isEditMode={isEditMode}
      setShowWarning={setShowWarning}
      resetForm={resetForm}
      setResetForm={setResetForm}
      isNewTaskTypeAssignmentOpen={isNewTaskTypeAssignmentOpen}
      isDeleteTaskTypeAssignmentOpen={isDeleteTaskTypeAssignmentOpen}
    />
  );

  useEffect(() => {
    (async () => {
      await dispatch(fetchConnectorSchema(connectorName));
      await dispatch(fetchConnectorActions(connectorName));
      setShowForm(true);
    })();
  }, [connectorName, dispatch]);

  useEffect(() => {
    /**
     * This piece of code should execute only when user tries to
     * edit the url manually or reloads the integration page.
     * This code shouldn't execute if the app routes bring the
     * user to integration page (i.e. history.location.state?.from === "Settings")
     */
    if (
      history.location.state?.from === undefined &&
      history.location.state?.from !== "Integrations"
    )
      (async () => {
        const response = await Api.getConnector(connectorName);
        const connector =
          typeof response?.body === "string"
            ? JSON.parse(response?.body)
            : null;
        if (connector) {
          const isConfigured = connector[connectorName]?.is_configured;
          if (isConfigured)
            history.push({
              pathname: getRedirectUrl(
                RouteConstants["integration"].defaultUrl,
                [{ key: "connector", value: connectorName }],
              ),
              search: "?edit=true",
              state: { from: "Integrations" },
            });
          else
            history.push({
              pathname: getRedirectUrl(
                RouteConstants["integration"].defaultUrl,
                [{ key: "connector", value: connectorName }],
              ),
              state: { from: "Integrations" },
            });
        }
      })();
  }, [connectorName, dispatch, history]);

  useEffect(() => {
    setShowWarning(showWarningModal);
  }, [showWarningModal]);

  const getBackgroundImg = name => {
    if (!name) {
      return {};
    }
    try {
      const imgURL = require(`@assets/images/snippets/icon-${name}.svg`);
      return {
        backgroundImage: `url(${imgURL})`,
      };
    } catch (e) {}
  };

  const toggleConfirmModal = () => {
    setConfirmModalResponse("");
    setIsRemoveIntegrationConfirmOpen(!isRemoveIntegrationConfirmOpen);
  };

  const removeIntegration = async () => {
    let response;
    try {
      if (connectorName === "terraform_cli") {
        response = await Api.removeTerraformCLIIntegration(connectorName);
      } else {
        response = await Api.removeIntegration(connectorName);
      }
      if (
        !isEmpty(response) &&
        (response.statusCode === 200 || response.statusCode === 201)
      ) {
        toggleConfirmModal();
        history.push(
          getRedirectUrl(RouteConstants["integration"].defaultUrl, [
            { key: "connector", value: connectorName },
          ]),
        );
        setResetForm(true);
      } else {
        setConfirmModalResponse("Oops! Something went wrong !!!");
      }
    } catch (e) {
      console.log(e);
      setConfirmModalResponse("Oops! Something went wrong !!!");
    }
  };

  return (
    <div className={`gs-container ${customContainerClass}`}>
      {isRemoveIntegrationConfirmOpen && (
        <Modal
          title="Remove Integration"
          showClose={true}
          onClose={toggleConfirmModal}
          isDeleteModal
          submitButtonText={`Confirm`}
          onCancel={toggleConfirmModal}
          onSubmit={removeIntegration}
        >
          Are you sure you want to remove the integration between{" "}
          <b>{currentNodeSchema?.display_name}</b> and <b>Fylamynt</b>?
          {getRemoveMessage()}
          <br />
          <br />
          <span className="error">{confirmModalResponse}</span>
        </Modal>
      )}
      {showForm && (
        <>
          {customHeader ? customHeader : <Headers showWarning={showWarning} />}
          <div className="gs-body">
            <div className={`gs-title ${customTitleClass}`}>
              <div className="left">
                <div className="icon-wrapper">
                  <div
                    style={getBackgroundImg(connectorName)}
                    className="icon"
                  ></div>
                </div>
                <div className="title">
                  <span>{`${currentNodeSchema?.display_name} Integration`}</span>
                </div>
              </div>
              <div className="right">
                <div className="documentation">
                  <a
                    href={`https://docs.fylamynt.com/${
                      currentNodeSchema?.connector_type?.toLowerCase() ===
                      "resource"
                        ? "resources"
                        : "integrations"
                    }/${connectorName}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Documentation</span>
                    <img src={ExternalLinkIcon} alt="external-link"></img>
                  </a>
                </div>
                {isEditMode ? (
                  <>
                    <div className="status">
                      <div className="dot green"></div>
                      <span>Connected</span>
                    </div>
                    <button onClick={toggleConfirmModal}>
                      Remove Integration
                    </button>
                  </>
                ) : (
                  <div className="status">
                    <div className="dot"></div>
                    <span>Not Connected</span>
                  </div>
                )}
              </div>
            </div>
            <div className={`gs-panel ${customPanelClass}`}>
              <div className="gs-panel-left">
                <TabCollection
                  contentTop={100}
                  menuClassName={"gs-tab-wrap"}
                  tabs={["incident-type-assignment", "description"]}
                >
                  <Tab title="Description" name="description">
                    <TabContent>
                      <Description
                        overview={currentNodeSchema?.overview || []}
                        actions={connectorActions}
                      />
                    </TabContent>
                  </Tab>
                  {currentNodeSchema?.is_trigger &&
                    currentNodeSchema?.alert_type && (
                      <Tab
                        title="Incident Type Assignments"
                        name="incident-type-assignment"
                        disable={!isEditMode}
                      >
                        <TabContent>
                          <TaskTypeAssignment
                            connector={connectorName}
                            toggleModal={isNewTaskTypeAssignmentOpen}
                            setToggleModal={setIsNewTaskTypeAssignmentOpen}
                            setIsDeleteTaskTypeAssignmentOpen={
                              setIsDeleteTaskTypeAssignmentOpen
                            }
                          />
                        </TabContent>
                      </Tab>
                    )}
                </TabCollection>
              </div>

              <div className="gs-panel-inner gs-panel-right">{formContent}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default GetStarted;
