import React, { useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { isEmpty, replaceAllSpecialCharWithSpace } from "@lib/utils";
import { fetchRunbooks } from "@redux/actions/runbooks.actions";
import { fetchAllTaskTypes } from "@redux/actions/taskManagement.actions";
import { taskPrioritiesList } from "@lib/utils/constants";

type CreateTaskDetailsProps = {
  formik: { handleChange; touched; errors; values; setFieldValue };
  workflowRunType: string;
  setWorkflowRunType: (string) => void;
  enumWorkflowRunType: any;
  setSelectedWorkflow: (obj) => void;
};

const CreateTaskDetails: React.FC<CreateTaskDetailsProps> = ({
  formik,
  workflowRunType,
  setWorkflowRunType,
  enumWorkflowRunType,
  setSelectedWorkflow,
}) => {
  const { handleChange, touched, errors } = formik;
  const dispatch = useDispatch();

  const workflowList = useSelector(
    (state: RootStateOrAny) => state.runbooksReducer.runbookList,
  );

  const taskTypesList = useSelector(
    (state: RootStateOrAny) => state.taskManagementReducer.allTaskTypesList,
  );

  useEffect(() => {
    if (!(taskTypesList.length > 0)) dispatch(fetchAllTaskTypes());
  }, [dispatch, taskTypesList.length]);

  useEffect(() => {
    if (!(workflowList.Self.concat(workflowList.Fylamynt).length > 0)) {
      dispatch(fetchRunbooks("Self"));
    }
  }, [dispatch, workflowList.Self, workflowList.Fylamynt]);

  return (
    <div className="create-task">
      <FormInput
        fieldName="Name"
        name={"name"}
        id="name"
        handleChange={handleChange}
        touched={touched}
        errors={errors}
        autoComplete="off"
      />
      <FormInput
        fieldName="Description"
        name={"description"}
        id="description"
        handleChange={handleChange}
        touched={touched}
        errors={errors}
        autoComplete="off"
        as={"textarea"}
        textAreaClassName="create-task-description"
      />
      <div className="create-task-select">
        <label className="label">Incident Priority</label>
        <ReactSelect
          id="task_priority"
          name="task_priority"
          value={{
            value: formik.values.task_priority,
            label: formik.values.task_priority
              ? formik.values.task_priority
              : "Select from below",
          }}
          handleChange={data => {
            if (data === null) {
              formik.setFieldValue("task_priority", "");
            } else if (!isEmpty(data) && !!data.value && !!data.label) {
              formik.setFieldValue("task_priority", data.value);
            }
          }}
          selectOptions={taskPrioritiesList.map(option => {
            return {
              value: option,
              label: option || "",
            };
          })}
          customMenuClass="default-select-options-container"
          customMenuListClass="default-select-options-list"
          customValueContainerClass="default-select-value-container"
          customControlClass="default-select-control"
          customOptionClass="default-select-list-item"
        />
        {formik.errors.task_priority && formik.touched.task_priority ? (
          <div className="input-feedback">{formik.errors.task_priority}</div>
        ) : (
          ""
        )}
      </div>
      <div className="create-task-select">
        <label className="label">Incident Type</label>
        <ReactSelect
          id="task_type"
          name="task_type"
          value={{
            value: formik.values.task_type,
            label: formik.values.task_type?.meta?.name
              ? formik.values.task_type?.meta?.name
              : "Select from below",
          }}
          handleChange={data => {
            if (data === null) {
              formik.setFieldValue("task_type", "");
              setSelectedWorkflow({ Name: "", DocumentVersion: "" });
              formik.setFieldValue("workflow", { Name: "" });
            } else if (!isEmpty(data) && !!data.value && !!data.label) {
              formik.setFieldValue("task_type", data.value);
              let selectedWF = workflowList.Self.concat(
                workflowList.Fylamynt,
              ).find(wf => wf.Name === data.value.meta.workflow?.name);
              formik.setFieldValue("workflow", selectedWF || { Name: "" });
              setSelectedWorkflow(
                selectedWF || { Name: "", DocumentVersion: "" },
              );
            }
          }}
          selectOptions={taskTypesList.map(option => {
            return {
              value: option,
              label: option?.meta?.name || "",
            };
          })}
          customMenuClass="default-select-options-container"
          customMenuListClass="default-select-options-list"
          customValueContainerClass="default-select-value-container"
          customControlClass="default-select-control"
          customOptionClass="default-select-list-item"
        />
        {formik.errors.task_type && formik.touched.task_type ? (
          <div className="input-feedback">{formik.errors.task_type}</div>
        ) : (
          ""
        )}
      </div>

      <div className="create-task-select">
        <label className="label">Select Workflow (Optional)</label>
        <ReactSelect
          id="workflow"
          name="workflow"
          value={{
            value: formik.values.workflow,
            label: formik.values.workflow?.Name
              ? replaceAllSpecialCharWithSpace(formik.values.workflow?.Name)
              : "Select from below",
          }}
          handleChange={data => {
            if (data === null) {
              formik.setFieldValue("workflow", { Name: "" });
              setSelectedWorkflow({ Name: "", DocumentVersion: "" });
            } else if (!isEmpty(data) && !!data.value && !!data.label) {
              formik.setFieldValue("workflow", data.value);
              setSelectedWorkflow(data?.value);
            }
          }}
          selectOptions={workflowList.Self.concat(workflowList.Fylamynt).map(
            option => {
              return {
                value: option,
                label: replaceAllSpecialCharWithSpace(option.Name),
              };
            },
          )}
          customMenuClass="default-select-options-container"
          customMenuListClass="default-select-options-list"
          customValueContainerClass="default-select-value-container"
          customControlClass="default-select-control"
          customOptionClass="default-select-list-item"
        />
      </div>
      {formik.values.workflow?.Name && (
        <div className="wf-actions">
          <span>How would you like the Default Workflow To Run?</span>
          <div className="radio-group">
            <div className="radio-button">
              <div
                className={`icon ${
                  workflowRunType === enumWorkflowRunType.AUTOMATIC
                    ? "checked"
                    : ""
                }`}
                onClick={() =>
                  setWorkflowRunType(enumWorkflowRunType.AUTOMATIC)
                }
              ></div>
              <span className="label">
                Automatically Run at Incident creation
              </span>
            </div>
            <div className="radio-button">
              <div
                className={`icon ${
                  workflowRunType === enumWorkflowRunType.SCHEDULE
                    ? "checked"
                    : ""
                }`}
                onClick={() => setWorkflowRunType(enumWorkflowRunType.SCHEDULE)}
              ></div>
              <span className="label">Schedule Run</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateTaskDetails;
