import React, { Component } from "react";
import SetValueNow from "./SetValueNow";
import Api from "@lib/api";
import { isEmpty, toObject } from "@lib/utils";
import InputWithChoice from "./InputWithChoice";
import { updateConstantValueControl, getParamInput } from "./Helper";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateSnippetFieldOptions } from "@redux/actions/runbook.action";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { getShowPriorityError } from "../../utils/utils";

type InputRendererProps = {
  showForm: boolean;
  setShowForm: (flag) => void;
  snippetDef: any;
  parameterInputs: Array<any>;
  notifyRunbookUpdate: () => void;
  snippetFieldOptions: any;
  updateSnippetFieldOptions: (updatedFieldOptions) => void;
  snippetFields: Array<any>;
  showPriorityError: any;
  setShowPriorityError: (e) => void;
  setPriorityError: (e) => void;
};
type InputRendererStateProps = {
  fieldOptions: any;
  formData: any;
};
class InputRenderer extends Component<
  InputRendererProps,
  InputRendererStateProps
> {
  constructor(props) {
    super(props);
    this.state = {
      fieldOptions: {},
      formData: toObject(this.props.snippetFields, "name"),
    };
  }
  componentDidMount() {
    if (this.props.snippetFields.length > 0) {
      this.initialiseValues([...this.props.snippetFields]);
    }
    this.setShowPriorityError();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.fieldOptions !== this.state.fieldOptions) {
      this.props.updateSnippetFieldOptions({
        [this.props.snippetDef.name]: { ...this.state.fieldOptions },
      });
    }
  }

  setShowPriorityError = () => {
    if (
      this.props.snippetDef?.at_least_one ||
      this.props.snippetDef?.mutual_exclusion
    ) {
      let { showPriorityError, priorityError } = getShowPriorityError(
        this.props.parameterInputs,
        this.props.snippetDef?.at_least_one,
        this.props.snippetDef?.mutual_exclusion,
      );

      this.props.setShowPriorityError(showPriorityError);
      this.props.setPriorityError(priorityError);
    }
  };

  initialiseValues = async (inputs = []) => {
    const fieldOptions =
      {
        ...this.props.snippetFieldOptions[this.props.snippetDef.name],
      } || {};
    const formData = toObject(inputs, "name");
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      const fieldKey = input.name;
      const paramInput = getParamInput(fieldKey, this.props.parameterInputs);
      formData[fieldKey]["value"] =
        paramInput?.source?.sourceValue || input?.default || "";
      formData[fieldKey]["name"] = fieldKey;
      formData[fieldKey]["type"] = getParamInput(
        fieldKey,
        this.props.parameterInputs,
      )?.type;
      if (!fieldOptions.hasOwnProperty(fieldKey)) {
        /**
         * @case if static options are sent from the back-end
         */
        if (
          input.hasOwnProperty("allowed_values") &&
          input.allowed_values?.length &&
          (input.input_option === "dropdown" ||
            input.input_option === "singleselect" ||
            input.input_option === "multiselect")
        ) {
          fieldOptions[fieldKey] = input.allowed_values;
          continue;
        }
        const dependencies = input.dependencies;
        /**
         * @case Input values has ExternalAPI object
         */
        if (input.hasOwnProperty("external_api")) {
          const externalApi = input.external_api.endpoint;
          try {
            let response = await Api._getData("", {}, externalApi);
            response =
              typeof response === "string" ? JSON.parse(response) : response;
            fieldOptions[fieldKey] = response;
          } catch (error) {
            console.log(error);
          }
        }
        /**
         * @case Input values has API object
         */
        if (
          input.hasOwnProperty("api") &&
          this.shouldShowField(this.state.formData[fieldKey])
        ) {
          let { endpoint, key, params } = input.api;
          /**
           * @case Values are static and to be fetched from existing key-value pair.
           * Ex - SplunkOnCall Team-Policies
           */
          if (endpoint === "static") {
            //logic for preparing option list from parents api response in this.state.fieldOptions
            const dependency = dependencies[0];
            const dependentOptions = fieldOptions[dependency];
            fieldOptions[fieldKey] = dependentOptions;
            continue;
          }
          /**
           * @case Values are to be fetched from API endpoint
           */
          try {
            const finalEndPoint = this.prepareUrl(endpoint, params, formData);
            const response = await Api._getData(finalEndPoint);
            /**
             * handle one-off case for Alias field where response is MapList
             */

            let options = response?.body
              ? typeof response?.body === "string"
                ? JSON.parse(response.body)
                : response.body
              : typeof response === "string"
              ? JSON.parse(response)
              : response;

            // If key is present, assign the MapList assigned against key in response body
            if (key) {
              options = options[key];
            }
            fieldOptions[fieldKey] = options;
          } catch (error) {
            console.log(error);
          }
        }
      }
    }

    this.setState(
      {
        formData,
        fieldOptions,
      },
      () => {
        this.props.setShowForm(true);
      },
    );
  };

  prepareUrl = (endpoint, params, formData) => {
    let url = endpoint;
    let urlParams = "";
    /**
     * @case Params do not exist
     * Ex - Alias
     */
    if (!params) {
      return url;
    }
    Object.keys(params).forEach(p => {
      const value = formData[params[p]].value;
      if (value) {
        if (urlParams) {
          urlParams = `${urlParams}&${p}=${value}`;
        } else {
          urlParams = `${p}=${value}`;
        }
      }
    });

    if (urlParams) {
      return `${url}?${urlParams}`;
    }
    return url;
  };

  updateFormData = (input, newValue) => {
    const formData = { ...this.state.formData };
    formData[input.name]["value"] = newValue;
    const paramInput = getParamInput(input.name, this.props.parameterInputs);
    updateConstantValueControl(
      paramInput,
      newValue,
      this.props.notifyRunbookUpdate,
    );
    this.setShowPriorityError();
    this.setState({ formData });
  };

  fetchUpdatedOptions = async input => {
    const fieldOptions =
      {
        ...this.props.snippetFieldOptions[this.props.snippetDef.name],
      } || {};
    let { name, api } = input;
    const finalEndPoint = this.prepareUrl(
      api?.endpoint,
      api?.params,
      this.state.formData,
    );
    const response = await Api._getData(finalEndPoint);
    let options = response?.body
      ? typeof response?.body === "string"
        ? JSON.parse(response.body)
        : response.body
      : typeof response === "string"
      ? JSON.parse(response)
      : response;
    if (api?.key) {
      options = options[api?.key];
    }
    fieldOptions[name] = options;
    this.setState({
      fieldOptions,
    });
    const dropdownOptions = {};
    dropdownOptions[name] = this.getOptions(name, api);
    return dropdownOptions[name];
  };

  /**
   * @function getInputsWithoutChoice function to render input fields with dynamic values(fetched through API)
   * and shown without GEAR icon
   * @param input field to render
   * @param i index of the field to render
   * @returns DOM structure for input field
   */
  getInputsWithoutChoice = (input, i) => {
    if (!this.shouldShowField(input)) return null;
    const dropdownOptions = {};
    const dependency = input.dependencies?.[0] || "";
    dropdownOptions[input.name] = this.getOptions(
      input.name,
      input.api,
      dependency,
    );

    return (
      <div
        className="editor-detail-panel editor-detail-panel-new editor-detail-panel-column"
        key={i}
      >
        <SetValueNow
          parameterInputs={this.props.parameterInputs}
          input={input}
          updateValue={newValue => this.updateFormData(input, newValue)}
          dropdownOptions={dropdownOptions}
          isRequired={input.required}
          isDynamic={
            this.isDynamic(input) && !input.hasOwnProperty("allowed_values")
          }
          showPriorityError={
            this.props.snippetDef?.at_least_one?.includes(input.name) ||
            this.props.snippetDef?.mutual_exclusion?.includes(input.name)
              ? this.props.showPriorityError
              : null
          }
          fetchUpdatedOptions={this.fetchUpdatedOptions}
          updateDropdownSelection={async newValue => {
            /**
             * @param name Name of input
             * @param api API object in snippetDef
             */

            // update payload so that it can be used to prepare url for next dependent field API
            const paramInput = getParamInput(
              input.name,
              this.props.parameterInputs,
            );
            updateConstantValueControl(
              paramInput,
              newValue,
              this.props.notifyRunbookUpdate,
            );

            this.setShowPriorityError();

            this.updateFormData(input, newValue);
            if (input.input_option !== "multiselect") {
              const dI = Object.values(this.state.formData).filter(
                (val: any) =>
                  val.dependencies?.length === 1 &&
                  val.dependencies.includes(input.name),
              ) as any;
              const fieldOptions = { ...this.state.fieldOptions };
              let promises = [];
              dI.forEach(dependentInput => {
                if (dependentInput && !isEmpty(dependentInput?.api)) {
                  const { endpoint, params, selector } = dependentInput.api;
                  if (endpoint === "static") {
                    let options = this.state.fieldOptions[input.name];
                    if (
                      dependentInput.input_option === "dropdown" ||
                      dependentInput.input_option === "singleselect" ||
                      dependentInput.input_option === "multiselect"
                    ) {
                      this.updateFormData(dependentInput, "");
                      fieldOptions[dependentInput.name] = options;
                      this.setState({ fieldOptions });
                    } else {
                      const depFieldVal = options.find(
                        v => v[input.api["selector"]] === newValue,
                      )[selector];
                      this.updateFormData(dependentInput, depFieldVal);
                    }
                  } else {
                    this.updateFormData(dependentInput, "");
                    const finalEndPoint = this.prepareUrl(
                      endpoint,
                      params,
                      this.state.formData,
                    );
                    promises.push(Api._getData(finalEndPoint));
                  }
                }
              });
              try {
                let responseArr = await Promise.all(promises);

                responseArr.forEach((response, i) => {
                  const key = dI[i]["api"]["key"];
                  let options = response.body
                    ? typeof response?.body === "string"
                      ? JSON.parse(response.body)
                      : response.body
                    : response;
                  // If key is present, assign the MapList assigned against key in response body
                  if (key) {
                    options = options[key];
                  }
                  fieldOptions[dI[i].name] = options;
                });

                this.setState({ fieldOptions });
              } catch (e) {
                console.log(e);
              }
            }
          }}
        />
      </div>
    );
  };

  /**
   * Function to get options for dropdowns
   * @param field String field key
   * @param props connector schema recieved from the reducer
   */
  getOptions = (name, api, dependency = "") => {
    /**
     * @case if response is maplist, selector exists
     */
    let options = this.state.fieldOptions[name];
    /**
     * @case if options is dropdown options
     */
    const selector = api?.selector;
    if (options && options.constructor.name === "Array" && options.length) {
      if (selector) {
        /**
         * @todo Create unique options list before preparing field options
         */
        /**
         * @case - selector is a string
         */
        if (typeof selector === "string") {
          const uniqueOptions = [];
          options.forEach(val => {
            const isValueDuplicate = !!uniqueOptions.find(
              o => o.value === val[selector],
            );
            if (!isValueDuplicate)
              uniqueOptions.push({
                value: val[selector],
                label: val[selector],
              });
          });
          return uniqueOptions;
        } else {
          /**
           * @case - selector is an object
           */
          const uniqueOptions = [];
          const isValueDuplicate = !!uniqueOptions.find(
            val => val.value === val[selector],
          );
          options.forEach(val => {
            /**
             * @case - selector is an object
             */
            if (!isValueDuplicate)
              uniqueOptions.push({
                value: val[selector.value],
                label: val[selector.name],
              });
          });
          return uniqueOptions;
        }
      }
      /**
       * @case - no selector, when response is stringList
       */
      const uniqueOptions = new Set(options) as any;
      return [...uniqueOptions].map(val => ({
        value: val,
        label: val,
      }));
    } else if (
      options &&
      options.constructor.name === "Object" &&
      !isEmpty(options) &&
      selector
    ) {
      if (selector.constructor.name === "Object") {
        if (selector.is_value_list && dependency) {
          const dependencyValue =
            this.state.formData[dependency].value ||
            getParamInput(dependency, this.props.parameterInputs)?.source
              ?.sourceValue;
          return options[dependencyValue].map(o => ({ value: o, label: o }));
        }
        return Object.keys(options).map(val => {
          return {
            value: selector.value === "Key" ? val : options[val],
            label: selector.name === "Key" ? val : options[val],
          };
        });
      }
    }
  };
  isDynamic = input =>
    input.hasOwnProperty("api") ||
    input.hasOwnProperty("external_api") ||
    input.hasOwnProperty("allowed_values");

  shouldShowField = input => {
    const dependencies = input?.dependencies || [];
    if (!dependencies.length) return true;
    for (let i = 0; i < dependencies.length; i++) {
      const dependency = dependencies[i];
      const value =
        this.state.formData[dependency].value ||
        getParamInput(dependency, this.props.parameterInputs)?.source
          ?.sourceValue;
      if (!value) {
        return false;
      }
    }
    return true;
  };

  render() {
    const formData = { ...this.state.formData };
    const requiredInputs = Object.keys(formData).filter(
      input => !formData[input]["hidden"] && formData[input].required,
    );

    const optionalInputs = Object.keys(formData).filter(
      input => !formData[input]["hidden"] && !formData[input].required,
    );

    return (
      <>
        <Accordion isExpanded={true} useArrow={true}>
          <AccordionSection className="mb-1">
            {this.props.showForm ? (
              <>
                {requiredInputs.map((input, idx) =>
                  this.isDynamic(formData[input]) ? (
                    this.getInputsWithoutChoice(formData[input], idx)
                  ) : (
                    <React.Fragment key={idx}>
                      <InputWithChoice
                        inputField={formData[input]}
                        parameterInputs={this.props.parameterInputs}
                        required={formData[input].required}
                        showPriorityError={
                          this.props.snippetDef?.at_least_one?.includes(
                            formData[input].name,
                          ) ||
                          this.props.snippetDef?.mutual_exclusion?.includes(
                            formData[input].name,
                          )
                            ? this.props.showPriorityError
                            : null
                        }
                        setShowPriorityError={() => this.setShowPriorityError()}
                      />
                    </React.Fragment>
                  ),
                )}
              </>
            ) : (
              <div className="editor-detail-panel editor-detail-panel-new editor-detail-panel-column">
                <label>Loading...</label>
              </div>
            )}
          </AccordionSection>
        </Accordion>
        {optionalInputs.length > 0 && (
          <Accordion isExpanded={true} useArrow={true}>
            <AccordionLabel className="editor-accordion-label editor-right-panel-first-input">
              Optional Inputs
            </AccordionLabel>
            <AccordionSection className="mb-1">
              {this.props.showForm ? (
                <>
                  {optionalInputs.map((input, idx) =>
                    this.isDynamic(formData[input]) ? (
                      this.getInputsWithoutChoice(formData[input], idx)
                    ) : (
                      <React.Fragment key={idx}>
                        <InputWithChoice
                          inputField={formData[input]}
                          parameterInputs={this.props.parameterInputs}
                          required={formData[input].required}
                          showPriorityError={
                            this.props.snippetDef?.at_least_one?.includes(
                              formData[input].name,
                            ) ||
                            this.props.snippetDef?.mutual_exclusion?.includes(
                              formData[input].name,
                            )
                              ? this.props.showPriorityError
                              : null
                          }
                          setShowPriorityError={() =>
                            this.setShowPriorityError()
                          }
                        />
                      </React.Fragment>
                    ),
                  )}
                </>
              ) : (
                <div className="editor-detail-panel editor-detail-panel-new editor-detail-panel-column">
                  <label>Loading...</label>
                </div>
              )}
            </AccordionSection>
          </Accordion>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  snippetFieldOptions: state.runbookReducer.snippetFieldOptions,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateSnippetFieldOptions,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InputRenderer);
