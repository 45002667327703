import React, { useState } from "react";
import "./DefaultTextInput.scss";
import IconEye from "@components/ui/icons/icon-eye";
import IconEyeCancelled from "@components/ui/icons/icon-eye-cancelled";
import copyIcon from "@assets/images/icons/icon-copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Header from "@components/ui/InputTypes/Layouts/Header/Header";
import ReactTooltip from "react-tooltip";

const DefaultTextInput = ({
  isMasked = false,
  copyable = false,
  containerClass = "",
  fieldName = "Input",
  labelClassName = "",
  label = "Default Input",
  subText = "",
  placeholder = "",
  className = "",
  readOnly = false,
  fieldValue = "",
  showError,
  onChangeHandler,
  errorMsg = "",
  helpText,
}) => {
  const [inputType, setInputType] = useState(isMasked ? "password" : "text");
  return (
    <div className={`dti-item ${containerClass || ""}`}>
      <Header title={label} description={subText} helpText={helpText} />
      <div className="d-flex w-100 mt-2-px">
        <input
          type={isMasked ? inputType : "text"}
          value={fieldValue || ""}
          name={fieldName}
          placeholder={!!placeholder ? placeholder : ``}
          className={`${className || ""}  ${
            showError ? "dti-input-error" : ""
          } dti-input`}
          readOnly={readOnly}
          autoComplete={`new-password`}
          onChange={e => {
            onChangeHandler(e.target.value);
          }}
        />
        <div data-for="inputTip" data-tip>
          {copyable && (
            <CopyToClipboard text={fieldValue}>
              <button className="dti-icon-container">
                <img
                  src={copyIcon}
                  alt="invisibleIcon"
                  className="dti-icon-copy"
                />
              </button>
            </CopyToClipboard>
          )}
        </div>
        <ReactTooltip
          id="inputTip"
          place="bottom"
          effect="solid"
          type="light"
          event="click"
          isCapture={true}
          afterShow={() => {
            setTimeout(ReactTooltip.hide, 1000);
          }}
          className="ttDimension"
        >
          Copied!
        </ReactTooltip>
        {isMasked &&
          (inputType === "password" ? (
            <button
              type="button"
              className="dti-icon-container"
              onClick={() => setInputType("text")}
            >
              <IconEyeCancelled className="dti-icon-mask" />
            </button>
          ) : (
            <button
              type="button"
              className={`dti-icon-container`}
              onClick={() => setInputType("password")}
            >
              <IconEye className="dti-icon-mask" />
            </button>
          ))}
      </div>
      {showError && (
        <div className="input-feedback">
          {errorMsg ? errorMsg : label + " is Required"}
        </div>
      )}
    </div>
  );
};

export default DefaultTextInput;
