import React, { useState, useEffect } from "react";
import TaskManagementHeader from "./utils/TaskManagementHeader";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import "./TaskManagement.scss";
import { DataTable } from "@components/ui";
import { columns } from "./utils/TaskListColumns";
import SummaryCards from "./utils/SummaryCards";
import TaskListFilters from "./utils/TaskListFilters";
import CreateTask from "./utils/CreateTask/CreateTask";
import {
  fetchAllTaskList,
  setAllTaskList,
} from "@redux/actions/taskManagement.actions";
import {
  updateRecordsCurrentPage,
  updateRecordsPerPage,
  updateSortColumn,
} from "@redux/actions/common.actions";

type TaskManagementProps = {};

const TaskManagement: React.FC<TaskManagementProps> = () => {
  const dispatch = useDispatch();
  const [taskList, setTaskList] = useState([]);
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);
  const allTaskList = useSelector(
    (state: RootStateOrAny) => state.taskManagementReducer.allTaskList,
  );

  const taskManageCPage = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.currentPages?.taskManageCPage,
  );

  const taskManageRecords = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.recordsPerPage?.taskManageRecords,
  );

  const { sortField, sortFieldDirection } = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.sortingDetails?.taskManage || {},
  );

  useEffect(() => {
    dispatch(fetchAllTaskList());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setAllTaskList([]));
    };
  }, [dispatch]);

  const changeTaskManageCurrentPage = cPage => {
    dispatch(
      updateRecordsCurrentPage({
        tableName: "taskManageCPage",
        cPage: cPage,
      }),
    );
  };

  const changeTaskManageRowsPerPage = rows => {
    dispatch(
      updateRecordsPerPage({
        tableName: "taskManageRecords",
        cPageRecords: rows,
      }),
    );
  };

  const handleTaskManageSort = (column, sortDirection) => {
    dispatch(
      updateSortColumn({
        tableName: "taskManage",
        sortField: column?.selector,
        sortFieldDirection: sortDirection,
      }),
    );
  };

  return (
    <div className="tl-container">
      {isCreateTaskModalOpen && (
        <CreateTask
          setIsCreateTaskModalOpen={setIsCreateTaskModalOpen}
          isCreateTaskModalOpen={isCreateTaskModalOpen}
        />
      )}
      <TaskManagementHeader
        heading="All Incidents"
        breadCrumbText="Incidents"
        isBreadCrumb={true}
        setIsCreateTaskModalOpen={setIsCreateTaskModalOpen}
      />
      <TaskListFilters allTaskList={allTaskList} setTaskList={setTaskList} />
      <SummaryCards taskList={taskList} />
      <div className="data-table">
        <DataTable
          columns={columns}
          data={taskList}
          className="w-100"
          pagination={true}
          currentPage={taskManageCPage}
          recordsPerPage={taskManageRecords}
          onChangeCurrentPage={changeTaskManageCurrentPage}
          onChangeRowsPerPage={changeTaskManageRowsPerPage}
          defaultSortField={sortField}
          defaultSortAsc={sortFieldDirection === "asc" ? true : false}
          onSort={handleTaskManageSort}
        />
      </div>
    </div>
  );
};

export default TaskManagement;
