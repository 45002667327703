import React from "react";
import "./Snippets.scss";
import { Provider } from "./snippets-lib/snippets.context";
import * as utils from "@lib/utils";
import {
  SnippetsHeader,
  SnippetsTableContainer,
  SnippetsFilters,
} from "./snippets-components";

export default class Snippets extends React.Component {
  componentDidMount = () => {
    utils.setTitle("Fylamynt | Actions");
    this.props.fetchSnippets();
  };

  render() {
    const contextValue = {
      snippetsList: this.props.snippetsList,
      searchTerm: this.props.searchTerm,
      searchSnippets: this.props.searchSnippets,
      filterSnippetsByService: this.props.filterSnippetsByService,
      resetFilters: this.props.resetFilters,
      filteredServicesSet: this.props.filteredServicesSet,
      isFetchingSnippets: this.props.isFetchingSnippets,
      setSelectedSnippet: this.props.setSelectedSnippet,
      snippetsCPage: this.props.currentPages?.snippetsCPage,
      snippetsRecords: this.props.recordsPerPage?.snippetsRecords,
      snippetsSortingDetails: this.props.sortingDetails?.snippets,
      updateRecordsCurrentPage: this.props.updateRecordsCurrentPage,
      updateRecordsPerPage: this.props.updateRecordsPerPage,
      updateSortColumn: this.props.updateSortColumn,
    };
    return (
      <Provider value={contextValue}>
        <>
          <div className="d-flex flex-column">
            <SnippetsHeader />
            <div className="pl-2">
              <SnippetsFilters />
              <SnippetsTableContainer />
            </div>{" "}
          </div>
        </>
      </Provider>
    );
  }
}
