import React from "react";
import { OverflowMenu, Button } from "@components/ui";

export default (onDelete, onSetDefault, onEdit, onAliasClick) => [
  {
    name: "Account Alias",
    selector: "alias",
    cell: row => (
      <div className="d-flex">
        <Button
          onClick={() => onAliasClick(row)}
          type="link"
          buttonStyle="link"
        >
          {row.alias}
        </Button>
        {row.is_default ? <span className="default-tag">default</span> : ""}
      </div>
    ),
    grow: 2,
  },
  {
    name: "AssumeRole ARN",
    selector: "role_arn",
    grow: 3,
  },
  {
    name: "Preferred Region",
    selector: "preferred_region",
    //grow: 2,
  },

  {
    name: "",
    selector: "options",
    cell: row => (
      <OverflowMenu>
        {!row.is_default && (
          <div
            className="button-grey"
            onMouseDown={() => onSetDefault(row)}
            disabled
          >
            Set Default
          </div>
        )}
        <div className="button-grey" onMouseDown={() => onEdit(row)}>
          Edit
        </div>
        {!row.is_default && (
          <div className="button-delete" onMouseDown={() => onDelete(row)}>
            Delete
          </div>
        )}
      </OverflowMenu>
    ),
  },
];
