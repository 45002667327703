import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTime.scss";
import moment from "moment";
import Header from "../Layouts/Header/Header";

type DateTimeProps = {
  title: string;
  value: string;
  onChange: (date: string) => void;
  minDate?: Date;
  showTimeSelect?: boolean;
  timeFormat?: string;
  dateFormat?: string;
  className?: string;
  timeCaption?: string;
  description?: string;
  helpText?: string;
  isRequired?: boolean;
  showPriorityError?: any;
};
const DateTime: React.FC<DateTimeProps> = ({
  title,
  value = "",
  onChange,
  minDate,
  showTimeSelect,
  timeFormat,
  dateFormat,
  className,
  timeCaption,
  description = "",
  helpText,
  isRequired,
  showPriorityError,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(
    value && typeof value === "string" ? new Date(value) : "",
  );
  const setDate = date => {
    setSelectedDate(date);
    onChange(moment(date).toISOString());
  };
  const CustomTimeInput = props => {
    return (
      <input
        value={props.value || ""}
        onChange={e => props.onChange(e.target.value)}
        readOnly
      />
    );
  };

  return (
    <div className="dt">
      <Header title={title} description={description} helpText={helpText} />
      <DatePicker
        id="date-time-input"
        showPopperArrow={false}
        selected={selectedDate}
        onChange={(date: string) => setDate(date)}
        minDate={minDate}
        showTimeInput
        dateFormat={!!dateFormat ? dateFormat : "dd MMM yyyy HH:mm:ss"}
        className={`${className} ${
          showPriorityError !== null
            ? showPriorityError && "error"
            : isRequired && (selectedDate ? "" : "error")
        }`}
        placeholderText="Select Date/Time"
        timeClassName="mt-50-px"
        customTimeInput={<CustomTimeInput />}
        autoComplete="off"
      />
    </div>
  );
};
export default DateTime;
