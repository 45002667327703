import { SleepStep } from "../ssm/sleepstep";
import { StepTypes } from "./strings";

export class WaitStep extends SleepStep {
  static fromControlNodeDefinition(snippetDef) {
    const name = `${snippetDef.name}_${
      snippetDef.insertionOrder
        ? snippetDef.insertionOrder
        : Math.floor(1000 * Math.random())
    }`;
    const ssm = { ...snippetDef.content, name };
    // default 30 second wait
    ssm.inputs = { Duration: "PT30S" };
    return new WaitStep(ssm, snippetDef);
  }

  constructor(stepJSON, snippetDef) {
    super(stepJSON);
    this.stepType = StepTypes.WaitStep;
    this.snippetDef = snippetDef;
    this.editable = true;
  }

  setSeconds(seconds) {
    this.untilTimestamp = undefined;
    this.seconds = seconds;
  }

  setUntilTimestamp(iso8601DateTime) {
    this.iso8601Duration = undefined;
    this.untilTimestamp = iso8601DateTime;
  }

  toSSM() {
    const result = {
      ...this.ssm,
      nextStep: this.nextStep,
      isEnd: !this.nextStep,
    };
    result.inputs = {};
    if (this.iso8601Duration) {
      result.inputs.Duration = this.iso8601Duration.toString();
    } else if (this.untilTimestamp) {
      result.inoputs.Timestamp = this.untilTimestamp;
    }
    return result;
  }
}

export class NeurOpsStatusStep extends SleepStep {
  static fromControlNodeDefinition(snippetDef) {
    const name = `${snippetDef.name}_${
      snippetDef.insertionOrder
        ? snippetDef.insertionOrder
        : Math.floor(1000 * Math.random())
    }`;
    const ssm = { ...snippetDef.content, name };
    // default 1 second wait
    ssm.inputs = { Duration: "PT1S" };

    return new WaitStep(ssm, snippetDef);
  }

  constructor(stepJSON, snippetDef) {
    super(stepJSON);
    this.snippetDef = snippetDef;
    this.statusName = snippetDef?.name;
  }
}
