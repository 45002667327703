import "./ActionSnippetHeader.scss";
import React from "react";
import { getBackgroundImgSource } from "@lib/utils";
import ExternalIcon from "@assets/images/icons/icon-external.svg";
import ReactTooltip from "react-tooltip";

type ActionSnippetHeaderProps = {
  runbookNode: any;
  awsDocLink: string;
};

const ActionSnippetHeader: React.FC<ActionSnippetHeaderProps> = ({
  runbookNode,
  awsDocLink,
}) => {
  const getHeader = () => {
    return (
      <div className="sh-container">
        <div className="d-flex align-items-center">
          <div className="sh-icon">
            <img
              src={getBackgroundImgSource(
                runbookNode.actionNodeDef?.display_name,
                runbookNode.actionNodeDef?.icon_name,
              )}
              alt="inputIcon"
            />
          </div>
          <div className="d-flex flex-column">
            <div className="header-node-title">
              <span className="sh-label">
                {runbookNode.actionNodeDef?.display_name || ""}
              </span>
              <a
                href={awsDocLink}
                target="_blank"
                rel="noopener noreferrer"
                data-for="documentation"
                data-tip
              >
                <img
                  src={ExternalIcon}
                  alt="external-link-icon"
                  className="ext-link-icon"
                />
              </a>
              <ReactTooltip
                className="doc-tooltip"
                id="documentation"
                place="bottom"
                effect="solid"
                type="light"
              >
                Documentation
              </ReactTooltip>
            </div>
            <span className="sh-id">
              {runbookNode.operation
                ? `${runbookNode.service.toLowerCase()}_${
                    runbookNode.operation
                  }`
                : runbookNode.actionNodeDef?.name || ""}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return <>{getHeader()}</>;
};

export default ActionSnippetHeader;
