import produce from "immer";
import {
  API_KEYS_FETCHING,
  API_KEYS_FETCH_ERROR,
  API_KEYS_FETCH_SUCCESS,
  API_KEYS_CREATING,
  API_KEYS_CREATE_ERROR,
  API_KEYS_CREATE_SUCCESS,
  API_KEYS_DELETING,
  API_KEYS_DELETE_ERROR,
  API_KEYS_DELETE_SUCCESS,
  API_KEYS_TOGGLE_FIRST_LOGIN,
  API_KEYS_TOGGLE_DISABLE_SPLASH_SCREEN,
} from "@redux/types";

const INITIAL_STATE = {
  apiKeyList: [],
  isFetchingAPIKeys: false,
  isCreatingAPIKey: false,
  createAPIKeyError: null,
  createAPIKeySuccess: null,
  isDeletingAPIKey: false,
  firstLogin: true,
  isSplashScreenDisabled: false,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case API_KEYS_FETCHING:
      case API_KEYS_FETCH_ERROR:
        draft.isFetchingAPIKeys = action.payload;
        break;

      case API_KEYS_FETCH_SUCCESS:
        if (typeof action.payload?.body === "string") {
          action.payload = JSON.parse(action.payload?.body);
        }
        draft.apiKeyList = action.payload?.apikeys;
        draft.isFetchingAPIKeys = false;
        break;

      case API_KEYS_CREATING:
        draft.isCreatingAPIKey = false;
        break;

      case API_KEYS_CREATE_ERROR:
        if (typeof action.payload?.body === "string") {
          action.payload = JSON.parse(action.payload?.body);
        }
        draft.createAPIKeyError = action.payload;
        draft.isCreatingAPIKey = false;
        break;

      case API_KEYS_CREATE_SUCCESS:
        if (typeof action.payload?.body === "string") {
          action.payload = JSON.parse(action.payload?.body);
        }
        draft.createAPIKeySuccess = action.payload;
        draft.isCreatingAPIKey = false;
        break;

      case API_KEYS_DELETING:
      case API_KEYS_DELETE_ERROR:
        draft.isDeletingAPIKey = action.payload;
        break;

      case API_KEYS_DELETE_SUCCESS:
        draft.apiKeyList = draft.apiKeyList.filter(
          val => val.ApikeyName !== action.payload,
        );
        draft.isDeletingAPIKey = false;
        break;

      case API_KEYS_TOGGLE_FIRST_LOGIN:
        draft.firstLogin = action.payload;
        break;

      case API_KEYS_TOGGLE_DISABLE_SPLASH_SCREEN:
        draft.isSplashScreenDisabled = action.payload;
        break;

      default:
        break;
    }
  });
