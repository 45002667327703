import React from "react";
import { Consumer } from "../../executions-lib/executions.context";
import Options from "./options";
import { TimeSeries } from "@components/ui/visualizations";

class Chart extends React.Component {
  render() {
    return (
      <Consumer>
        {({ filterData, filteredData, defaultTimeFilter }) => (
          <div className="executions-chart">
            <div className="executions-chart-child">
              <Options
                onChange={filterData}
                defaultOption={defaultTimeFilter}
              />
            </div>
            <div className="executions-chart-child">
              <TimeSeries
                data={filteredData}
                dateKey={"ExecutionEndTime"}
                valueKey={"AutomationExecutionStatus"}
                groupBy={"hour"}
              />
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}

export default Chart;
