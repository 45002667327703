import { restrictedDomains } from "@lib/utils/constants";
import * as Yup from "yup";

export const getValidationSchema = (currentTab: string) => {
  switch (currentTab) {
    case "tab1":
      return Yup.object({
        username: Yup.string()
          .trim()
          .matches(/(\p{Alpha}|\p{N})/u, "Please enter a valid full name")
          .required("Please enter your full name")
          .min(1, "Please enter your full name"),
        email: Yup.string()
          .required("Please enter a valid email address")
          .test("email", "Email address must use a company domain", function (
            email,
          ) {
            return !restrictedDomains.some(value =>
              email?.includes("@" + value),
            );
          })
          .email("Please enter a valid email address"),
        company: Yup.string()
          .trim()
          .matches(
            /(\p{Alpha}|\p{N})/u,
            "The company name can not contain only special characters.",
          )
          .required("Please enter your full company name")
          .min(1, "Please enter your full company name"),
        agreeTerm: Yup.boolean()
          .required("You must accept the Terms and Privacy Policy")
          .oneOf([true], "You must accept the Terms and Privacy Policy"),
      });
    case "tab2":
      return Yup.object({
        password: Yup.string().required("Please enter a valid password"),
        confirmPassword: Yup.string()
          .required("Please enter confirm password")
          .oneOf([Yup.ref("password"), null], "Passwords do not match"),
      });
    case "tab3":
      return Yup.object({
        activationCode: Yup.string()
          .required("Please enter confirmation code")
          .matches(/^[0-9]+$/, "Invalid confirmation code")
          .min(6, "Invalid confirmation code")
          .max(6, "Invalid confirmation code"),
      });
  }
};
