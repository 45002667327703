import React, { useState } from "react";
import ConnectorForm from "./utils/ConnectorForm";
import GetStarted from "@components/shared/GetStarted/GetStartedForm";

const TwilioSetup = () => {
  const [showWarningModal, setShowWarningModal] = useState(false);

  return (
    <GetStarted
      connectorForm={ConnectorForm}
      showWarningModal={showWarningModal}
      showCustomForm
      connectorProps={{setShowWarningModal}}
    />
  );
};

export default TwilioSetup;
