import React from "react";
import "./TriggerSelection.scss";

type TriggerSelectionProps = {
  triggerList: Array<any>;
  localTrigger: object;
  setLocalTrigger: (trigger) => void;
};

const getBackgroundImg = name => {
  if (!name) {
    return {};
  }
  try {
    const imgURL = require(`@assets/images/snippets/icon-${name}.svg`);
    return {
      backgroundImage: `url(${imgURL})`,
    };
  } catch (e) {}
};

const TriggerSelection: React.FC<TriggerSelectionProps> = ({
  triggerList,
  localTrigger,
  setLocalTrigger,
}) => {
  return (
    <>
      <div className="node-container">
        {triggerList &&
          Array.isArray(triggerList) &&
          triggerList.map(trigger => {
            return (
              <div
                key={trigger.name}
                className={`node ${
                  trigger.name === localTrigger?.["name"] ? "active" : ""
                } ${!trigger.is_configured ? "disabled-node" : ""}`}
                onClick={() =>
                  trigger.is_configured ? setLocalTrigger(trigger) : null
                }
              >
                {!trigger.is_configured && <div className="info-icon"></div>}
                <div
                  style={getBackgroundImg(trigger.name)}
                  className="image"
                ></div>
                <span className="title">{trigger.display_name}</span>
              </div>
            );
          })}
      </div>
      {Array.isArray(triggerList) &&
        triggerList.some(trigger => !trigger.is_configured) && (
          <div className="info-section">
            <div className="info-icon"></div>
            <span className="text">Trigger not configured in Settings.</span>
          </div>
        )}
    </>
  );
};

export default TriggerSelection;
