import React from "react";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import Output from "./../output";
import Details from "./../details";
import moment from "moment";
import { store } from "@redux/store";
import Bugsnag from "@bugsnag/js";
import SnippetHeader from "@containers/RunbookEditor/runbook-editor-lib/SnippetDefNew/utils/SnippetHeader/SnippetHeader";

export default class WaitTimePanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      waitTime: {
        hours: 0,
        minutes: 0,
        seconds: 30,
      },
    };
    this.activeNode = null;
  }

  componentDidMount() {
    this.activeNode = store.getState().nodeReducer.activeNode.extras.runbookNode;
    let iso8601Duration = moment.duration(this.activeNode?.iso8601Duration);
    if (!iso8601Duration.isValid()) {
      const error = `${iso8601Duration} came back invalid for workflow ${this.activeNode.runbook.name}`;
      console.error(new Error(error));
      Bugsnag.notify(new Error(error));
    } else {
      this.setState({
        waitTime: {
          hours: iso8601Duration.hours(),
          minutes: iso8601Duration.minutes(),
          seconds: iso8601Duration.seconds(),
        },
      });
    }
  }

  changeTime = event => {
    this.setState(
      {
        waitTime: {
          ...this.state.waitTime,
          [event.target.name]: parseInt(event.target.value),
        },
      },
      () => {
        this.formatISOTime();
      },
    );
  };

  formatISOTime = activeNode => {
    let format = moment
      .duration({
        hours: this.state.waitTime.hours,
        minutes: this.state.waitTime.minutes,
        seconds: this.state.waitTime.seconds,
      })
      .toISOString();
    this.activeNode.iso8601Duration = format;
    this.props.notifyRunbookUpdate(true);
  };

  preventDefault = e => {
    e.cancelBubble = true;
    e.stopPropagation();
  };

  render() {
    return (
      <TabCollection
        active="input"
        activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
        inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
        contentTop={50}
        tabs={["input", "output", "details"]}
      >
        <Tab title="Input" name="input">
          <TabContent>
            <SnippetHeader snippetDef={this.activeNode?.snippetDef} />
            <Accordion isExpanded={true} useArrow={false}>
              <AccordionLabel className="editor-accordion-label">
                Time To Wait
              </AccordionLabel>
              <AccordionSection>
                <div className="editor-detail-panel editor-detail-panel-column">
                  <div className="editor-terraform-container">
                    <div className="wait-node-time-option">
                      <div>
                        Hours
                        <br />
                        <input
                          type="number"
                          min={0}
                          className="text-input-number"
                          value={this.state.waitTime["hours"]}
                          style={{ paddingLeft: "6px" }}
                          name="hours"
                          onChange={this.changeTime}
                          onKeyUp={e => this.preventDefault(e)}
                        />
                      </div>
                      <div>
                        Minutes
                        <br />
                        <input
                          type="number"
                          min={0}
                          max={60}
                          className="text-input-number"
                          value={this.state.waitTime["minutes"]}
                          style={{ paddingLeft: "6px" }}
                          name="minutes"
                          onChange={this.changeTime}
                          onKeyUp={e => this.preventDefault(e)}
                        />
                      </div>
                      <div>
                        Seconds
                        <br />
                        <input
                          type="number"
                          min={0}
                          max={60}
                          className="text-input-number"
                          value={this.state.waitTime["seconds"]}
                          style={{ paddingLeft: "6px" }}
                          name="seconds"
                          onChange={this.changeTime}
                          onKeyUp={e => this.preventDefault(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionSection>
            </Accordion>
            {/*
              <Accordion isExpanded={false} useArrow arrowSide="left">
                <AccordionLabel className="stepper-accordion-label">
                  (OR) Wait until date:
                </AccordionLabel>
                <AccordionSection>Calender here</AccordionSection>
              </Accordion>
              */}
          </TabContent>
        </Tab>
        <Tab title="Output" name="output">
          <TabContent>
            <Output />
          </TabContent>
        </Tab>
        <Tab title="Details" name="details">
          <TabContent>
            <Details />
          </TabContent>
        </Tab>
      </TabCollection>
    );
  }
}
