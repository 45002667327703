import React, { useEffect } from "react";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import Output from "../../output";
import Details from "../../details";
import WebhookInputs from "./Helpers/WebhookInputs";
import SetupInstructions from "../../../SetupInstructions/SetupInstructions";
import getInstructions from "./Helpers/Instructions";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { hideGlobalLoader } from "@redux/actions/globalLoader.actions";

type WebhookProps = {};

const Webhook: React.FC<WebhookProps> = () => {
  const loaderState = useSelector(
    (state: RootStateOrAny) => state.globalLoaderReducer,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaderState.loaderFlag) {
      dispatch(hideGlobalLoader());
    }
  }, [loaderState, dispatch]);

  return (
    <TabCollection
      active="input"
      activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
      inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
      contentTop={50}
      tabs={["input", "output", "details"]}
    >
      <Tab title="Input" name="input">
        <TabContent>
          <WebhookInputs />
          <SetupInstructions>{getInstructions()}</SetupInstructions>
        </TabContent>
      </Tab>
      <Tab title="Output" name="output">
        <TabContent>
          <Output />
        </TabContent>
      </Tab>
      <Tab title="Details" name="details">
        <TabContent>
          <Details />
        </TabContent>
      </Tab>
    </TabCollection>
  );
};

export default Webhook;
