import React, { useState, useEffect } from "react";

type taskObject = {
  status: string;
};
type SummaryCardsProps = {
  taskList: Array<taskObject>;
};

const SummaryCards: React.FC<SummaryCardsProps> = ({ taskList }) => {
  const [activeTasks, setActiveTasks] = useState(0);
  const [pendingTasks, setPendingTasks] = useState(0);
  const [closedTasks, setClosedTasks] = useState(0);
  const [totalTasks, setTotalTasks] = useState(0);

  useEffect(() => {
    let activeTasks = 0;
    let pendingTasks = 0;
    let closedTasks = 0;
    taskList.forEach(task => {
      if (task.status === "Active") activeTasks++;
      else if (task.status === "Pending") pendingTasks++;
      else closedTasks++;
    });
    setActiveTasks(activeTasks);
    setPendingTasks(pendingTasks);
    setClosedTasks(closedTasks);
    setTotalTasks(activeTasks + pendingTasks + closedTasks);
  }, [taskList]);

  return (
    <div className="summary-cards">
      <div className="item">
        <span className="title">Incidents Active</span>
        <div className="numbers">
          <span className="dividend">{activeTasks}</span>
          <span className="divisor">{`/ ${totalTasks}`}</span>
        </div>
      </div>
      <div className="item">
        <span className="title">Incidents Pending</span>
        <div className="numbers">
          <span className="dividend">{pendingTasks}</span>
          <span className="divisor">{`/ ${totalTasks}`}</span>
        </div>
      </div>
      <div className="item">
        <span className="title">Incidents Closed</span>
        <div className="numbers">
          <span className="dividend">{closedTasks}</span>
          <span className="divisor">{`/ ${totalTasks}`}</span>
        </div>
      </div>
    </div>
  );
};

export default SummaryCards;
